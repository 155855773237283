<template>
<div class="topNav">
  <head-top></head-top>
  <div class="nav">
      <div class="navlogo">
        <router-link :to="{path: '/'}" target="_blank"><img src="../assets/image/logo1.png" alt=""></router-link>
      </div>
      <div class="header-list">
          <ul>
            <li  @mouseover="list1Enter()" @mouseleave="list1leave()">
              <router-link :to="{path: '/softwareMarket/softwareList'}" :class="{'on':listContShow==true}" target="_blank">软件市场</router-link>
              <img :src="navUp" alt="" :class="{'iconshow':listContShow==false}">
              <img :src="navDown"  alt=""  :class="{'iconshow':listContShow==true}">
              <div class="nav-area" v-show="listContShow"  >
                   <ProductNav />
              </div>
            </li>
            <li  @mouseover="list2Enter()" @mouseleave="list2leave()">
              <a href="" :class="{'on':listCont2Show==true}">标识服务</a><img :src="navUp" alt="" :class="{'iconshow':listCont2Show==false}"><img :src="navDown" alt="" :class="{'iconshow':listCont2Show==true}">
              <div class="nav-area" v-show="listCont2Show" >
                <NavCode   @childCode="parentEvent2"/>
              </div>
            </li>
            <!-- <li  @mouseover="list3Enter()" @mouseleave="list3leave()">
              <router-link :to="{path: '/industrial'}" :class="{'on':listCont3Show==true}" target="_blank">工业供采</router-link>
              <img :src="navUp" alt="" :class="{'iconshow':listCont3Show==false}">
              <img :src="navDown" alt=""  :class="{'iconshow':listCont3Show==true}">
              <div class="nav-area" v-show="listCont3Show">
                  <NavIndustrial  @childIndustrial="parentEvent1"/>
              </div>
            </li> -->
            <li>
              <router-link :to="{path: '/mailianZhicai'}" target="_blank">迈链智采</router-link>
            </li>
            <li>
              <a href="" >云工厂</a>
            </li>
            <li>
              <a href="">工业学院</a>
            </li>
            <li>
              <a href="" >生态合作</a>
            </li>
            <li>
              <a href="" >工业知识图谱</a>
            </li>
            <li  @mouseover="list4Enter()" @mouseleave="list4leave()">
              <router-link :to="{path: '/aboutUs'}" :class="{'on':listCont4Show==true}" >走进沧州工业云</router-link>
              <img :src="navUp" alt="" :class="{'iconshow':listCont4Show==false}">
              <img :src="navDown" alt="" :class="{'iconshow':listCont4Show==true}">
               <div class="nav-area" v-show="listCont4Show" >
                    <NavInto  @childInto="parentEvent" />
              </div>
            </li>
          </ul>
      </div>
      <template v-if="!isCookie">
        <div class="navButton">
          <router-link :to="{path: '/login'}" class="login">登录</router-link>
          <router-link :to="{path: '/register'}" class="regin">注册</router-link>
        </div>
      </template>
      <template v-else>
        <div class="is-login">
          <img src="" alt="">
          <div>
          <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect" :router="true">
            <el-submenu index="/userCenter/control">
              <template slot="title">个人中心</template>
              <el-menu-item @click="outLogin">退出登录</el-menu-item>
              <el-menu-item index="/userCenter/control">应用管理</el-menu-item>
              <el-menu-item index="/userCenter/myBid">供需管理</el-menu-item>
              <el-menu-item index="/userCenter/myMessage">个人管理</el-menu-item>
            </el-submenu>
          </el-menu>
          </div>
        </div>
      </template>
  </div>
  <!-- <div style="background:#ccc;height:600px;">
  </div> -->
</div>
</template>
<script>
import Cookies from 'js-cookie'
import headTop from '@/components/head-top'
import NavInto from '../components/nav-bar-into.vue'
import NavCode from '../components/nav-bar-code.vue'
import NavIndustrial from '../components/nav-bar-industrial.vue'
import NavSctop from '../components/nav-bar-sctop.vue'
import ProductNav from '../components/nav-bar-product.vue'
export default {
 data(){
   return{
       isCookie: false,
       topTO:require('../assets/image/topTo.png'),
       navUp:require('../assets/image/navUp.png'),
       navDown:require('../assets/image/navDown.png'),
       seen:false,
       BFocused: false,
       listContShow:false,
       LFocusedNav:false,
       listCont2Show:false,
       listCont3Show:false,
       listCont4Show:false,
       closeE:true,
   }
 },
 components: { headTop,NavInto,NavCode,NavIndustrial,NavSctop,ProductNav},
 methods:{
   handleSelect(key, keyPath) {
    // console.log(key, keyPath);
  },

  // 退出登录
  outLogin() {
    var json = {
        Authorization: this.$cookie.get('token')
    }
    this.$api.login.outLogin({json}).then(res => {
        if(res.success=="true"){
            this.$cookie.remove('token')
            if(this.$route.path.indexOf('userCenter') == '-1'){
              this.$nextTick(function(){
                  this.isCookie = this.$cookie.get('token')
              })
            }else{
              this.$router.push({
                path: '/login'
              })
            }
        }else{
            this.$message.error(res.msg)
        }
    })
  },

   parentEvent(value){
     this.closeE=value;
     this.listCont4Show=value;
   },
   parentEvent1(value){
     this.listCont3Show=value;
   },
   parentEvent2(value){
     this.listCont2Show=value;
   },
  enter(){
    this.seen=true;
    this.BFocused=true
  },
  leave(){
    if (!this.BFocused) {
      this.seen = false;
    } else{
        this.seen = true;
    }
  },
  elementBMouseOver() {
    this.seen = true;
    this.BFocused = true;
  },
  elementBMouseLeave() {
    this.BFocused = false;
    this.seen = false;
  },
  list1Enter(){
    this.listContShow = true;
  },
  list1leave(){
    this.listContShow = false;
  },
  
  list2Enter(){
    this.listCont2Show = true;
  },
  list2leave(){
    this.listCont2Show = false;
  },
  list3Enter(){
    this.listCont3Show = true;
  },
  list3leave(){
    this.listCont3Show = false;
  },
  list4Enter(){
    this.listCont4Show = true;
  },
  list4leave(){
    this.listCont4Show = false;
  },
 },
 mounted(){
  this.isCookie = Cookies.get('token')? true: false
 }
}
</script>

<style lang="scss">
.is-login{
  .el-submenu__title{
    padding: 0;
  }
  .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border: 0 none;
  }
}

</style>

<style lang="scss"  scoped>
.topNav{
  width:100%;
  min-width: 1288px;
 .top{
   width:100%;
   height:25px;
   background:#0B1D30;
   .topContant{
     padding-right:80px;
     .top-right{
       float:right;
       .topUl{
          li{float:left;
              img{vertical-align: middle!important;margin-right:6px}
              .topTo{margin-left:5px;cursor: pointer;}
              a{
                color:#FFFFFF;
                font-size:12px;
              }
          }
       }
     }
   }
    .cbp-hrsub{position:fixed;min-height:340px;width:100%;background:#f5f5f5;top: 25px;left: 0;cursor:pointer;}
 }
  .nav{
    height:65px;
    background:#fff;
    padding-left:30px;
    @media screen and (min-width: 1460px) {
      padding-right:80px;
    }

    @media (max-width:1460px) and (min-width:1288px) {
      padding-right:30px;
    }
 
   .navlogo{
      float:left;
      width:160px;
      height:48px;
      @media screen and (min-width: 1460px) {
        margin-right:125px;
      }

      @media (max-width:1460px) and (min-width:1200px) {
        margin-right:85px;
      }
      img{
          width:155px;
          height:48px; 
          display:inline-block;
          margin: 8px 0px 9px 0px;
      }
   }
   .header-list{
     text-align:center;
     ul {
       >li{
        float:left;
        //  line-height:65px;
        padding: 22px 0;
        @media screen and (min-width: 1460px) {
          padding-right:40px;
        }

        @media (max-width:1460px) and (min-width:1200px) {
          padding-right:26px;
        }
         img{
           vertical-align: middle!important;
           margin-left:5px;
           display:none;
         }
        img.iconshow{display:inline-block;}
         a{font-size:14px;}
         a.on{color:#0070FF;}
         .nav-area{
           position:fixed;
           top:90px;
           left:0px;
           height:331px;
           background: #f5f5f5;
           width:100%;
           z-index: 999;
         }
        .v-enter-active,
        .v-leave-active{
          transition: all 0.8s ease;
        }
       }
      
     }
   }
   .navButton{
     float:right;
     line-height: 65px;
    a{
      display:inline-block;
      width:70px;
      height: 33px;
      line-height: 33px;
      vertical-align: middle;
      font-size:14px;
      text-align: center;
    }
    .regin{
      background:#006FFF;
      color:#fff;
    }
    .login{
      border:1px solid #006FFF;
      color:#006FFF;
      box-sizing: border-box;
      margin-right:20px;
    }
   }
   .is-login{
     float: right;
     display: flex;
     width: 100px;
     margin-top: 12px;
     img{
       width: 40px;
       height: 40px;
     }
   }
 }
}
</style>
