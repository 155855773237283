<template>
    <div class="softwareList">
        <div class="banner">
            <img src="" alt="">
        </div>

        <div class="product-list">
            <div class="list-one">
                <h2>明星产品</h2>
                <ul>
                    <li>
                        <router-link :to="{path: '/softwareMarket/details?id=' + '525' }"><h3>有赞分销直播卖货小程序</h3></router-link>
                        <p><span>√</span>支持微商城+小程序+直播卖货</p>
                        <p><span>√</span>分销、社区团购等百余种营销玩法</p>
                        <p><span>√</span>精细化管理会员，提升复购</p>
                        <div><span>0元试用</span></div>
                        <p class="price"><i>¥</i><b>0</b>/7日</p>
                        <div>
                            <a href="">联系买家</a>
                            <a href="">免费试用</a>
                        </div>
                    </li>
                    <li>
                        <h3>有赞分销直播卖货小程序</h3>
                        <p><span>√</span>支持微商城+小程序+直播卖货</p>
                        <p><span>√</span>分销、社区团购等百余种营销玩法</p>
                        <p><span>√</span>精细化管理会员，提升复购</p>
                        <div><span>0元试用</span></div>
                        <p class="price"><i>¥</i><b>0</b>/7日</p>
                        <div>
                            <a href="">联系买家</a>
                            <a href="">免费试用</a>
                        </div>
                    </li>
                    <li>
                        <h3>有赞分销直播卖货小程序</h3>
                        <p><span>√</span>支持微商城+小程序+直播卖货</p>
                        <p><span>√</span>分销、社区团购等百余种营销玩法</p>
                        <p><span>√</span>精细化管理会员，提升复购</p>
                        <div><span>0元试用</span></div>
                        <p class="price"><i>¥</i><b>0</b>/7日</p>
                        <div>
                            <a href="">联系买家</a>
                            <a href="">免费试用</a>
                        </div>
                    </li>
                    <li>
                        <h3>有赞分销直播卖货小程序</h3>
                        <p><span>√</span>支持微商城+小程序+直播卖货</p>
                        <p><span>√</span>分销、社区团购等百余种营销玩法</p>
                        <p><span>√</span>精细化管理会员，提升复购</p>
                        <div><span>0元试用</span></div>
                        <p class="price"><i>¥</i><b>0</b>/7日</p>
                        <div>
                            <a href="">联系买家</a>
                            <a href="">免费试用</a>
                        </div>
                    </li>
                    <li>
                        <h3>有赞分销直播卖货小程序</h3>
                        <p><span>√</span>支持微商城+小程序+直播卖货</p>
                        <p><span>√</span>分销、社区团购等百余种营销玩法</p>
                        <p><span>√</span>精细化管理会员，提升复购</p>
                        <div><span>0元试用</span></div>
                        <p class="price"><i>¥</i><b>0</b>/7日</p>
                        <div>
                            <a href="">联系买家</a>
                            <a href="">免费试用</a>
                        </div>
                    </li>
                    <li>
                        <h3>有赞分销直播卖货小程序</h3>
                        <p><span>√</span>支持微商城+小程序+直播卖货</p>
                        <p><span>√</span>分销、社区团购等百余种营销玩法</p>
                        <p><span>√</span>精细化管理会员，提升复购</p>
                        <div><span>0元试用</span></div>
                        <p class="price"><i>¥</i><b>0</b>/7日</p>
                        <div>
                            <a href="">联系买家</a>
                            <a href="">免费试用</a>
                        </div>
                    </li>
                    <li>
                        <h3>有赞分销直播卖货小程序</h3>
                        <p><span>√</span>支持微商城+小程序+直播卖货</p>
                        <p><span>√</span>分销、社区团购等百余种营销玩法</p>
                        <p><span>√</span>精细化管理会员，提升复购</p>
                        <div><span>0元试用</span></div>
                        <p class="price"><i>¥</i><b>0</b>/7日</p>
                        <div>
                            <a href="">联系买家</a>
                            <a href="">免费试用</a>
                        </div>
                    </li>
                    <li>
                        <h3>有赞分销直播卖货小程序</h3>
                        <p><span>√</span>支持微商城+小程序+直播卖货</p>
                        <p><span>√</span>分销、社区团购等百余种营销玩法</p>
                        <p><span>√</span>精细化管理会员，提升复购</p>
                        <div><span>0元试用</span></div>
                        <p class="price"><i>¥</i><b>0</b>/7日</p>
                        <div>
                            <a href="">联系买家</a>
                            <a href="">免费试用</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return{}
    },
    components: {},
    mounted() {},
    methods: {}
}
</script>

<style lang="scss" scoped>
.softwareList{
    .banner{
        height: 480px;
        margin-bottom: 80px;
    }
    .product-list{
        .list-one{
            h2{
                font-size: 28px;
                font-weight: 400;
                margin-bottom: 60px;
                text-align: center;
            }
            ul{
                width: 1200px;
                display: flex;
                justify-content: space-between;
                margin: 0 auto;
                flex-wrap: wrap;
                li{
                    width: 282px;
                    padding: 30px;
                    box-sizing: border-box;
                    box-shadow: 0px 0px 15px rgba(0, 111, 255, 0.08);
                    border-radius: 6px;
                    margin-bottom: 24px;
                    h3{
                        font-size: 18px;
                        font-weight: 550;
                        color: #0b1d30;
                        line-height: 45px;
                    }
                    h3:hover{
                        color: #006fff
                    }
                    p{
                        font-size: 12px !important;
                        color: #757F92;
                        line-height: 28px;
                        span{
                            color: #39BC73;
                            display: inline-block;
                            margin-right: 5px;
                        }
                        i,b{
                            color: #ff7934;
                        }
                        b{
                            font-size: 16px;
                        }
                    }
                    .price{
                        font-size: 14px;
                        margin-bottom: 20px;
                    }
                    div{
                        display: flex;
                        justify-content: space-between;
                        span{
                            font-size: 14px;
                            color: #006fff;
                            border: 1px solid #006fff;
                            display: inline-block;
                            padding: 0 6px;
                            margin: 12px 0;
                            line-height: 22px;
                        }
                        a{
                            width: 104px;
                            line-height: 38px;
                            font-size: 14px;
                        }
                        a:nth-child(1){
                            border: 1px solid #006fff;
                            color: #006fff;
                            text-align: center;
                        }
                        a:nth-child(1):hover{
                            background-color: #006fff;
                            color: #ffffff;
                            transition: color 0.2s;
                        }
                        a:nth-child(2){
                            border: 1px solid #006fff;
                            color: #ffffff;
                            background-color: #006fff;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }
}
</style>