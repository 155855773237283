<template>
<div class="head-top">
    <div class="topContant">
        <div class="top-right">
           <ul class="topUl">
             <li style="margin-right:38px;" @mouseover="enter()" @mouseleave="leave()"><img src="../assets/image/topIcon.png"  alt=""  class="lihover"><a href="">工业云子站</a>
                <img :src="topTO" class="topTo"  />
               
             </li>
             <li><img src="../assets/image/Tophelp.png"  alt="" ><a href="">帮助中心</a></li>
           </ul>
        </div>
    </div>
    <div class="cbp-hrsub" v-show="seen" @mouseover="elementBMouseOver" @mouseleave="elementBMouseLeave"><NavSctop/></div>
</div>
</template>

<script>
import NavSctop from '../components/nav-bar-sctop.vue'
export default {
    data() {
        return{
            seen:false,
            topTO:require('../assets/image/topTo.png'),
            BFocused: false,
        }
    },
    components: {
        NavSctop
    },
    mounted() {},
    methods: {
        enter(){
            this.seen=true;
            this.BFocused=true
        },
        leave(){
            if (!this.BFocused) {
            this.seen = false;
            } else{
                this.seen = true;
            }
        },
        elementBMouseOver() {
            this.seen = true;
            this.BFocused = true;
        },
        elementBMouseLeave() {
            this.BFocused = false;
            this.seen = false;
        },
    }
}
</script>

<style lang="scss" scoped>
.head-top{
   width:100%;
   height:25px;
   background:#0B1D30;
   .topContant{
     padding-right:80px;
     .top-right{
       float:right;
       .topUl{
          li{float:left;
              img{vertical-align: middle!important;margin-right:6px}
              .topTo{margin-left:5px;cursor: pointer;}
              a{
                color:#FFFFFF;
                font-size:12px;
              }
          }
       }
     }
   }
    .cbp-hrsub{position:fixed;min-height:340px;width:100%;background:#f5f5f5;top: 25px;left: 0;cursor:pointer;z-index: 9999;}
 }
</style>