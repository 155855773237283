<template>
    <div class="aboutUs">
        <nav-bar></nav-bar>
        <div class="w100">
            <div class="bannerCenter">
                <div class="top">
                    <h2>关于我们</h2>
                    <p>&nbsp;</p>
                    <p>&nbsp;</p>
                </div>
                <div class="bottom">
                    <el-menu :default-active="activeNav" class="el-menu-demo" mode="horizontal" @select="handleNav" router>
                        <el-menu-item index="/aboutUs/company">关于我们</el-menu-item>
                        <el-menu-item index="/joinUs">合作伙伴</el-menu-item>
                        <el-menu-item index="/contactUs">联系我们</el-menu-item>
                    </el-menu>
                </div>
            </div>
        </div>
        <div class="contentcenter clearfix">
            <div class="left">
                <h3>关于我们</h3>
                <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect" router>
                    <el-menu-item index="/aboutUs/company">公司简介</el-menu-item>
                    <el-menu-item index="/aboutUs/memorabilia">大事记</el-menu-item>
                    <el-menu-item index="/aboutUs/winAward">获奖资质</el-menu-item>
                </el-menu>
            </div>
            <div class="right">
                <router-view/>
            </div>
        </div>
        <template v-if="$route.path == '/aboutUs/company'">
            <corporate></corporate>
        </template>
        <!-- <p>{{$route.path == '/aboutUs/company'}}</p> -->

    </div>
</template>

<script>
import corporate from '../../components/corporateCulture'
// import navBar from '@/components/navBar'
export default {
    data() {
        return{
            activeNav: '/aboutUs/company',
            activeIndex: '/aboutUs/company',
        }
    },
    methods: {
        handleNav() {
            this.activeNav = this.$route.path
        },
        handleSelect() {
            this.$nextTick(() => {
                this.activeIndex = this.$route.path
            });
        },
    },
    components: {
        corporate,
        // navBar
    },
    mounted() {
        this.activeIndex = this.$route.path
    }
}
</script>

<style lang="scss" scoped>
.aboutUs{
    .w100{
        width: 100%;
        height: 335px;
        background: url('../../assets/image/aboutUsBg.png') center center no-repeat;
        .bannerCenter{
            width: 1062px;
            margin: 0 auto;
            text-align: center;
            .top{
                padding-top: 85px;
                h2{
                    color: #ffffff;
                    font-size: 36px;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
                p{
                    font-size: 18px;
                    color: #ffffff;
                    line-height: 36px;
                    font-weight: 300;
                }
            }
            .bottom{
                width: 636px;
                margin: 0 auto;
                margin-top: 51px;
                .el-menu{
                    background-color: transparent;
                }
                .el-menu--horizontal>.el-menu-item.is-active,
                .el-menu--horizontal>.el-menu-item{
                    color: #ffffff;
                    border-width: 4px;
                    font-size: 18px;
                }
                .el-menu-item:focus, .el-menu-item:hover{
                    background-color: transparent;
                }
                .el-menu-item{
                    margin: 0 50px;
                }
            }
        }
    }
    .contentcenter{
        width: 1210px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        .left{
            width: 160px;
            float: left;
            font-size: 14px;
            color: #757f92;
            text-align: left;
            h3{
                margin-bottom: 80px;
                margin-top: 50px;
            }
            .el-menu.el-menu--horizontal{
                border: 0 none;
            }
            .el-menu--horizontal>.el-menu-item{
                line-height: 42px;
                text-indent: 2em;
                margin-bottom: 20px;
                width: 160px;
                height: 44px;
            }
            .el-menu--horizontal>.el-menu-item.is-active{
                width: 160px;
                height: 44px;
                border: 1px solid #cccccc;
                color: #0070ff;
                background: url('../../assets/image/Frame.png') 130px center no-repeat;
            }
        }
        .right{
            width: 970px;
            float: right;
        }
    }
}
</style>

