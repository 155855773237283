<template>
  <div class="detail_wrapper">
    <top-banner></top-banner>
    <schema></schema>
    <fun></fun>
    <advantage></advantage>
    <scene></scene>
    <value></value>
    <demo></demo>
    <commend></commend>
    <more></more>
  </div>
</template>

<script>
import TopBanner from './components/topBanner'
import schema from './components/schema'
import fun from './components/fun'
import advantage from './components/advantage'
import scene from './components/scene'
import value from './components/value'
import demo from './components/demo'
import commend from "./components/commend";
import more from "./components/more";
export default {
  name: "detail",
  components: {more, commend, TopBanner, schema, fun, advantage, scene, value, demo},
}
</script>

<style scoped lang="sass">
.detail_wrapper
  background: #F6F8FB

</style>