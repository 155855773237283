<template>
    <div class="company-news">
        <!-- <nav-bar></nav-bar> -->

        <div class="banner">
            <el-carousel trigger="click" height="350px">
                <!-- <el-carousel-item v-for="item in bannerList" :key="item.id"> -->
                    <img :src="require('../../assets/news/banner.png')" alt="">
                    <!-- <h3 class="small">{{ item.matchImage }}</h3> -->
                <!-- </el-carousel-item> -->
            </el-carousel>
        </div>

        <div class="company-news-content">
            <div class="news-sort-list" v-for="(item,index) in mainNewsList" :key="index + 'mainNews'">
                <div class="title">
                    <h3>{{item.className}}</h3>
                    <router-link :to="{path: '/companyNewsList', query: {classId: item.id}}">查看全部&gt;</router-link>
                </div>
                <div class="news-sort-index">
                    <div class="news-sort-left">
                        <div class="top">
                            <ul>
                                <li v-for="(itemList,indexList) in item.newsDTOList.slice(1,3)" :key="indexList + 'detail'"><router-link :to="{path: '/companyNewsDetail', query: {classId: item.id, id: itemList.id,  newsClass: 1}}">
                                    <h6><b>{{itemList.newsName}}</b>&nbsp;&nbsp;<span>置顶</span></h6>
                                    <p>{{itemList.introduce}}</p>
                                    <div>{{itemList.origin?itemList.origin:'网络'}} &nbsp;&nbsp;{{itemList.createTime}}</div>
                                </router-link></li>
                            </ul>
                        </div>
                        <div class="bottom">
                            <ul>
                                <li v-for="(itemList,indexList) in item.newsDTOList.slice(3,11)" :key="indexList + 'detail'"><router-link :to="{path: '/companyNewsDetail', query: {classId: itemList.classId, id: itemList.id}}"><b>·</b>{{itemList.newsName}}</router-link></li>
                            </ul>
                        </div>
                    </div>
                    <div class="news-sort-right" v-for="(itemDet,indexDet) in item.newsDTOList.slice(0,1)" :key="indexDet + 'det'"><router-link :to="{path: '/companyNewsDetail', query: {classId: itemDet.classId, id: itemDet.id}}">
                        <p><img :src="itemDet.matchImage" alt=""></p>
                        <div>
                            <h4>{{itemDet.newsName}}</h4>
                            <p>{{itemDet.introduce}}</p>
                            <div>
                                {{itemDet.origin ? itemDet.origin: '网络'}} &nbsp;&nbsp;{{itemDet.createTime}}
                            </div>
                        </div>
                    </router-link></div>
                </div>
            </div>
            
            <div class="news-plain-list">
                <div class="top">
                    <div class="news-class">
                        <swiper class="swiper swiper-no-swiping" ref="swiper" :options="swiperOption">
                            <swiper-slide v-for="(item,index) in plainNewsClass" :key="index + 'plainNewsClass'">
                                <span @click="getNewsList(item.id)" :class="checkClassId == item.id? 'border': ''">{{item.className}}</span>
                            </swiper-slide>
                            <!-- <div class="swiper-button-prev" slot="button-prev"></div>
                            <div class="swiper-button-next" slot="button-next"></div> -->
                        </swiper>
                    </div>
                    <router-link :to="{path: '/companyNewsList', query: {classId: checkClassId}}">查看全部&gt;</router-link>
                </div>
                <div class="bottom">
                    <ul>
                        <li v-for="(item,index) in plainNewsList" :key="index + 'plainNewsList'"><router-link :to="{path: 'companyNewsDetail', query: {classId: item.classId, id: item.id}}">
                            <p><img :src="item.matchImage" alt=""></p>
                            <div>
                                <h4>{{item.newsName}}</h4>
                                <p>{{item.introduce}}</p>
                                <div>
                                    <p>{{item.origin}} &nbsp;&nbsp;{{item.createTime}}</p>
                                    <span v-show="item.isUp">推荐</span>
                                </div>
                            </div>
                        </router-link></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import navBar from '@/components/navBar'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default{
    data() {
        return{
            src: 'https://flbook.com.cn/c/0cpkryuzhD#page/4',
            swiperOption: {
                slidesPerView: 4,
                // loop: true,
                direction: 'horizontal',
                // navigation: {
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev'
                // }
            },
            bannerList: [],      // banner列表
            mainNewsClass: [],   // 主推新闻分类
            mainNewsList: [],    // 主推新闻列表
            plainNewsClass: [],  // 普通新闻分类
            plainNewsList: [],   // 普通新闻列表
            newsClass: [],
            checkClassId: 1
        }
    },
    components: {
       // navBar
    },
    comments: {
        Swiper,
        SwiperSlide
    },
    methods: {
        // 获取banner图
        getBanner(){
            this.$api.news.newsBannerList({}).then(res => {
                if(res.code == 200){
                    this.bannerList = res.data
                }else{
                    this.$message.error(res.msg)
                }
            })
        },

        // 选择新闻分类
        getNewsList(classId) {
            this.checkClassId = classId
            this.getNewsOneClassList(classId)
        },
        // 查询某一分类下新闻列表
        getNewsOneClassList(classId) {
            var json = {
                current: 1,
                size: 10,
                classId: classId
            }

            this.$api.news.newsOneClassList(json).then(res => {
                if(res.code == 200){
                    this.plainNewsList = res.data.records
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        // 获取主推新闻分类
        // getMainNewsClass() {
        //     this.$api.news.newsClassList({mainPush: 1}).then(res => {
        //         if(res.code == 200){
        //             this.mainNewsClass = res.rows
        //         }
        //     })
        // },
        // 获取普通新闻分类
        getplainNewsClass() {
            this.$api.news.newsClassList({mainPush: 0}).then(res => {
                if(res.code == 200){
                    this.plainNewsClass = res.data.records
                    this.checkClassId = res.data.records[0].id
                    this.getNewsOneClassList(this.checkClassId)
                }
            })
        },
        // 获取主推列表
        getNewsMainPushList() {
            this.$api.news.newsMainPushList({}).then(res => {
                if(res.code == 200){
                    this.mainNewsList = res.data
                }else{
                    this.$message.error(res.msg)
                }
            })
        }
    },
    mounted() {
        this.getBanner()
        // this.getMainNewsClass()
        this.getplainNewsClass()
        this.getNewsMainPushList()
    }
}
</script>

<style lang="scss">
.el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    margin: 0;
    text-align: center;
}

.el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
}
  
.el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
}
</style>

<style lang="scss" scoped>
.company-news{
    .banner{
        width: 100%;
        height: 350px;
        position: relative;
        img{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 350px;
        }
    }
    .company-news-content{
        width: 1200px;
        margin: 0 auto;
        padding: 80px 0;
        .news-sort-list{
            margin-bottom: 80px;
            .title{
                display: flex;
                justify-content: space-between;
                margin-bottom: 50px;
                padding: 0 10px;
                h3{
                    font-size: 24px;
                    color: #0B1D30;
                    font-size: 500;
                }
                a{
                    font-size: 14px;
                    color: #757F92;
                }
            }
            .news-sort-index{
                display: flex;
                justify-content: space-between;
                padding: 0 10px;
                .news-sort-left{
                    width: 840px;
                    .top{
                        h6{
                            
                            color: #0B1D30;
                            display: flex;
                            font-weight: 500;
                            align-items: center;
                            margin-bottom: 15px;
                            b{
                                font-size: 20px;
                                font-weight: 500;
                                display: inline-block;
                                max-width: 308px;
                                overflow: hidden;
                                white-space: nowrap;
                                // text-overflow:ellipsis;
                            }
                            span{
                                display: inline-block;
                                width: 45px;
                                height: 20px;
                                text-align: center;
                                line-height: 20px;
                                font-size: 12px;
                                color: #ffffff;
                                background-color: #FFA920;
                                border-radius: 3px;
                            }
                        }
                        h6:hover{
                            color: #006FFF;
                        }
                        p{
                            height: 78px;
                            font-size: 14px;
                            color: #757F92;
                            line-height: 26px;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 3;
                            overflow: hidden;
                            margin-bottom: 24px;
                        }
                        div{
                            font-size: 12px;
                            color: #757F92;
                            margin-bottom: 23px;
                        }
                    }
                    .bottom{
                        li{
                            height: 49px;
                            line-height: 49px;
                            border-bottom: 1px solid #eeeeee;
                            font-size: 14px;
                            a{
                                display: block;
                                overflow: hidden;
                                text-overflow:ellipsis;
                                white-space: nowrap;
                                b{
                                    color: #006FFF;
                                    margin-right: 5px;
                                }
                            }
                            a:hover{
                                color: #006fff;
                            }
                        }
                    }
                    ul{
                        display: flex;
                        flex-flow: wrap;
                        li{
                            width: 380px;
                            border-bottom: 1px solid #eeeeee;
                            margin-right: 40px;
                        }
                    }
                }
                .news-sort-right{
                    width: 320px;
                    a{
                        >p{
                            width: 320px;
                            height: 172px;
                            overflow: hidden;
                            position: relative;
                            img{
                                width: 100%;
                                height: auto;
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                            }
                        }
                        >div{
                            width: 100%;
                            height: 212px;
                            background-color: #f5f7fa;
                            padding: 25px 30px;
                            box-sizing: border-box;
                            h4{
                                font-size: 20px;
                                color: #0B1D30;
                                font-weight: 500;
                                padding-top: 17px;
                                height: 24px;
                                overflow: hidden;
                                text-overflow:ellipsis;
                                white-space: nowrap;
                            }
                            p{
                                height: 52px;
                                line-height: 26px;
                                font-size: 14px;
                                color: #757f92;
                                display: -webkit-box;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                overflow: hidden;
                                margin-top: 15px;
                            }
                            div{
                                font-size: 12px;
                                color: #757F92;
                                margin-top: 38px;
                            }
                        }
                    }
                }
                .news-sort-right:hover{
                    box-shadow: 0 8px 10px rgba(55,99,170,0.1);
                    transition: box-shadow 0.2s; 
                    h4{
                        color: #006fff;
                    }
                }
            }
        }
        .news-plain-list{
            .top{
                display: flex;
                justify-content: space-between;
                padding: 0 10px;
                >a{
                    font-size: 14px;
                    color: #757F92;
                }
                .news-class{
                    width: 600px;
                    height: 60px;
                    position: relative;
                    .swiper{
                        width: 600px;
                        height: 60px;
                        line-height: 60px;
                        text-align: center;
                        margin: 0 auto;
                        .swiper-slide{
                            text-align: left;
                            span{
                                display: inline-block;
                                height: 56px;
                                cursor: pointer;
                                font-size: 18px;
                                color: #757F92;
                            }
                            span:hover{
                                color: #006fff;
                            }
                            span.border{
                                border-bottom: 4px solid #006fff;
                                font-size: 24px;
                                color: #0B1D30;
                            }
                        }
                        .swiper-button-prev{
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            margin: 0;
                        }
                        .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{
                            font-size: 16px;
                            font-weight: 600;
                        }
                        .swiper-button-next{
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            margin: 0;
                        }
                        .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                }
            }
            .bottom{
                ul{
                    li{
                        padding: 31px 38px 31px 0;
                        border-bottom: 1px solid #eeeeee;
                        a{
                            display: flex;
                            justify-content: space-between;
                            >p{
                                width: 304px;
                                height: 158px;
                                overflow: hidden;
                                position: relative;
                                img{
                                    width: 304px;
                                    height: auto;
                                    position: absolute;
                                    top: 50%;
                                    left: 0;
                                    transform: translateY(-50%);
                                }
                            }
                            >div{
                                width: 834px;
                                h4{
                                    font-size: 16px;
                                    font-weight: 500;
                                    height: 35px;
                                    line-height: 35px;
                                    overflow: hidden;
                                    text-overflow:ellipsis;
                                    white-space: nowrap;
                                    margin-bottom: 10px;
                                }
                                >p{
                                    font-size: 14px;
                                    color: #757F92;
                                    line-height: 28px;
                                    height: 56px;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 2;
                                    overflow: hidden;
                                    margin-bottom: 20px;
                                }
                                div{
                                    display: flex;
                                    justify-content: space-between;
                                    font-size: 14px;
                                    color: #757F92;
                                    align-items: center;
                                    span{
                                        display: inline-block;
                                        width: 48px;
                                        height: 26px;
                                        text-align: center;
                                        line-height: 26px;
                                        color: #006fff;
                                        border: 1px solid #006fff;
                                        border-radius: 6px;
                                    }
                                }
                            }
                        }
                    }
                    li:hover{
                        background-color: #F5F7FA;
                    }
                }
            }
        }
    }
}
</style>
