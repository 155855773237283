<template>
  <div class="purchase_wrapper">
    <div class="group">
      <h5>套餐选择：</h5>
      <el-radio-group v-model="type">
        <el-radio-button label="普通版"></el-radio-button>
        <el-radio-button label="专业版"></el-radio-button>
        <el-radio-button label="高级版"></el-radio-button>
      </el-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  name: "purchase",
  data() {
    return {
      type:'普通版'
    }
  }
}
</script>

<style scoped lang="sass">
.purchase_wrapper
  margin: 50px 60px
  .group
    margin: 10px 0
    h5
      font: 300 14px/34px 'Microsoft YaHe'
      color: #6f6f6f
    /deep/.el-radio-button
      span
        width: 148px
        height: 32px
        margin-right: 10px
        background-color: #FFFFFF
        line-height: 32px
        color: #D5DEE7
        padding: 0
        border: 1px solid #D5DEE7
    /deep/.is-active
      span
        background-color: #1F51C8
        color: #FFFFFF

</style>