<template>
  <div class="about">
    <div class="wrapper">
      <div class="left" :style="boxHeight">
        <span v-show="!checkType" class="left_item" v-for="(item, index) in data" :key="index">
          {{ item }}
        </span>
        <el-checkbox-group v-show="checkType" v-model="checkedData">
          <el-checkbox class="left_item" v-for="(item,index) in data" :label="item" :key="index">{{item}}</el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="right">
        <button @click="showMore">更多</button>
        <button @click="showCheck">多选</button>
      </div>
    </div>
  </div>
</template>
<script>
import Qs from 'qs'
export default {
  data() {
    return {
      moreType: false,
      checkType: false,
      data: [
        "测试1",
        "测试2",
        "测试3",
        "测试4",
        "测试5",
        "测试6",
        "测试7",
        "测试8",
        "测试9",
        "测试11",
        "测试12",
        "测试13",
        "测试14",
        "测试15",
        "测试16",
        "测试17",
        "测试18",
        "测试19",
        "测试21",
        "测试22",
        "测试23",
        "测试24",
      ],
      checkedData: []
    };
  },
  computed: {
    boxHeight() {
      if (this.moreType) {
        return "height: 120px; overflow: auto";
      } else {
        return "height: 40px; overflow: hidden";
      }
    },
  },
  methods: {
    showMore() {
      this.moreType = !this.moreType;
    },
    showCheck() {
      this.checkType = !this.checkType;
    },
    ceshi() {
      var json = {
        pageNum: 1,
        pageSize: 10
      }
      this.$api.login.getIndustryList(json).then(res => {
        console.log(res)
      })
    }
  },
  mounted() {
    this.ceshi()
  }
};
</script>
<style scoped>
.wrapper {
  display: flex;
  width: 800px;
  background: #f4f4f4;
}
.left {
  width: 70%;
}
.left_item {
  display: inline-block;
  padding: 10px 20px;
}
.right {
  width: 30%;
}
</style>
