<template>
    <div class="applicationList">
        <div class="banner"></div>
        <div class="categorybox bg_F6F8FB">
            <div class="content-center">
                <div class="category">
                    <div class="flex m-b-15">
                        <div class="cate-type flex1">行业</div>
                        <div class="cate-all flex1">全部</div>
                        <div class="cate-item flex20">
                            <div
                                class="cate-item-name"
                                v-for="(item, index) in hangye"
                                :key="index"
                            >
                                {{ item.name }}
                            </div>
                        </div>
                    </div>
                    <div class="flex m-b-15">
                        <div class="cate-type flex1">应用</div>
                        <div class="cate-all flex1">全部</div>
                        <div class="cate-item flex20">
                            <div
                                class="cate-item-name"
                                v-for="(item, index) in yingyong"
                                :key="index"
                            >
                                {{ item.name }}
                            </div>
                        </div>
                    </div>
                    <div class="flex">
                        <div class="cate-type flex1">品牌</div>
                        <div class="cate-all flex1">全部</div>
                        <div class="cate-item flex20">
                            <div
                                class="cate-item-name m-b-8"
                                v-for="(item, index) in pinpai"
                                :key="index"
                            >
                                {{ item.name }}
                            </div>
                        </div>
                    </div>
                    <div class="flex m-t-7">
                        <div class="cate-type flex1">价格</div>
                        <div class="cate-all flex1">全部</div>
                        <div class="cate-item flex20">
                            <div
                                class="cate-item-name"
                                v-for="(item, index) in jiage"
                                :key="index"
                            >
                                {{ item.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="content-list">
            <div class="content-center border">
                <div class="app-filter">
                    <ul>
                        <li @click="changeFilter('')">
                            <p :class="filterValue == '' ? 'active' : ''">
                                综合排序
                            </p>
                        </li>
                        <li @click="changeFilter('time')">
                            <p :class="filterValue == 'time' ? 'active' : ''">
                                上架时间
                            </p>
                            <p>
                                <span class="filter-caret">
                                    <i
                                        class="el-icon-caret-top"
                                        :class="
                                            timesort == 'up' &&
                                            filterValue == 'time'
                                                ? 'active'
                                                : ''
                                        "
                                    ></i>
                                    <i
                                        class="el-icon-caret-bottom"
                                        :class="
                                            timesort == 'down' &&
                                            filterValue == 'time'
                                                ? 'active'
                                                : ''
                                        "
                                    ></i>
                                </span>
                            </p>
                        </li>
                        <li @click="changeFilter('price')">
                            <p :class="filterValue == 'price' ? 'active' : ''">
                                价格
                            </p>
                            <p>
                                <span class="filter-caret">
                                    <i
                                        class="el-icon-caret-top"
                                        :class="
                                            pricesort == 'up' &&
                                            filterValue == 'price'
                                                ? 'active'
                                                : ''
                                        "
                                    ></i>
                                    <i
                                        class="el-icon-caret-bottom"
                                        :class="
                                            pricesort == 'down' &&
                                            filterValue == 'price'
                                                ? 'active'
                                                : ''
                                        "
                                    ></i>
                                </span>
                            </p>
                        </li>
                        <li @click="changeFilter('hot')">
                            <p :class="filterValue == 'hot' ? 'active' : ''">
                                热销
                            </p>
                        </li>
                        <li @click="changeFilter('new')">
                            <p :class="filterValue == 'new' ? 'active' : ''">
                                新品
                            </p>
                        </li>
                    </ul>
                    <div class="filter-right">
                        <span>共 {{ count }} 件</span>
                        <i class="el-icon-minus"></i>
                        <i
                            @click="changeListStyle('menu')"
                            class="pointer el-icon-menu"
                            :class="listStyle == 'menu' ? 'active' : ''"
                        ></i>
                        <i
                            @click="changeListStyle('list')"
                            class="pointer el-icon-s-unfold"
                            :class="listStyle == 'list' ? 'active' : ''"
                        ></i>
                    </div>
                </div>
                <div class="app-list">
                    <div
                        class="app-item"
                        v-for="(item, index) in list"
                        :key="index"
                    >
                        <div class="app-item-image">
                            <img :src="item.match_image" alt="">
                        </div>
                        <div class="app-item-right">
                            <div class="app-item-content">
                                <div class="app-item-title">
                                    {{ item.title }}
                                </div>
                                <div class="app-item-desc">{{ item.synopsis }}</div>
                                <div class="app-item-line"></div>
                                <div class="app-item-company">
                                    <!-- <div class="app-item-companyname">
                                        {{ item.company }}
                                    </div> -->
                                    <div class="app-item-tag" v-for="(itemlabel, labelindex) in JSON.parse(item.label)" :key="labelindex + 'tag'">
                                        {{ itemlabel }}
                                    </div>
                                </div>
                            </div>
                            <div class="flex1"></div>
                            <div class="app-item-price">
                                <p>
                                    <!-- <span class="new-price-unit">￥</span> -->
                                    <span class="new-price">
                                        {{ item.price }}
                                    </span>
                                    <span class="new-time"> 元/年</span>
                                </p>
                                <p class="app-item-btn"><router-link :to="{path: '/softwareMarket/detals', query: {id: item.id}}">查看详情</router-link></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="app-page">
                    <el-pagination
                        background
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="total,prev, pager, next,jumper"
                        :total="total"
                    ></el-pagination>
                </div>
                <div class style="height: 65px"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Qs from 'qs'
export default {
    data() {
        return {
            count: 3389,
            listStyle: "list", // list menu
            filterValue: "",
            timesort: "up", //up down
            pricesort: "up", //up down
            list: [],
            hangye: [
                {
                    name: "基础工业",
                },
                {
                    name: "能源",
                },
                {
                    name: "电力",
                },
                {
                    name: "水利",
                },
                {
                    name: "轨道交通",
                },
                {
                    name: "医疗",
                },
                {
                    name: "教育",
                },
                {
                    name: "其他",
                },
            ],
            yingyong: [
                {
                    name: "生产制造",
                },
                {
                    name: "供应链",
                },
                {
                    name: "研发设计",
                },
                {
                    name: "赋能创新",
                },
                {
                    name: "市场营销",
                },
                {
                    name: "企业管理",
                },
                {
                    name: "其他工具",
                },
            ],
            pinpai: [
                {
                    name: "北京数码大方",
                },
                {
                    name: "畅捷通",
                },
                {
                    name: "山东万腾",
                },
                {
                    name: "北京蓝威",
                },
                {
                    name: "德客易采",
                },
                {
                    name: "洛中佳为",
                },
                {
                    name: "新迪数字",
                },
                {
                    name: "文泰世纪",
                },
                {
                    name: "云道智造",
                },
                {
                    name: "西安德云物联",
                },
                {
                    name: "大连东软",
                },
                {
                    name: "郑州金惠",
                },
                {
                    name: "华胜天成",
                },
                {
                    name: "华天软件",
                },

                {
                    name: "亿伦时代",
                },
            ],
            jiage: [
                {
                    name: "免费",
                },
                {
                    name: "100元以内",
                },
                {
                    name: "100-200元",
                },
                {
                    name: "200-300元",
                },
                {
                    name: "300元以上",
                },
            ],
            total: 0,
            currentPage: 1,
            pageSize: 6,
        };
    },
    components: {},
    mounted() {
        this.getProductList(1)
        this.getProductClass()
    },
    methods: {
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
            this.getProductList(val)
        },
        // 获取产品分类
        getProductClass(){
            this.$api.product.productClass({}).then(res => {
                if(res.code == 200){
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        // 获取产品列表
        getProductList(currentPage){
            var json = {
                current: currentPage,
                size: this.pageSize,
                type: 'goods'
            }

            this.$api.product.productList(json).then(res => {
                if(res.code == 200){
                    this.list = res.data.records
                    this.total = res.data.total
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        changeListStyle(e) {
            this.listStyle = e;
        },
        changeFilter(e) {
            this.filterValue = e;
            if (e == "time") {
                if (this.timesort == "up") {
                    this.timesort = "down";
                } else {
                    this.timesort = "up";
                }
            }
            if (e == "price") {
                if (this.pricesort == "up") {
                    this.pricesort = "down";
                } else {
                    this.pricesort = "up";
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.flex1 {
    flex: 1;
}
.flex20 {
    flex: 20;
}
.flex {
    display: flex;
}
.line-through {
    text-decoration: line-through;
}
.m-b-15 {
    margin-bottom: 15px;
}
.m-b-8 {
    margin-bottom: 8px;
}
.m-t-7 {
    margin-top: 7px;
}
.applicationList {
    .pointer {
        cursor: pointer;
    }
    div {
        box-sizing: border-box;
    }
    .categorybox {
        padding-top: 50px;
    }
    .bg_F6F8FB {
        background-color: #f6f8fb;
    }
    .banner {
        height: 480px;
        background-color: #e9f7fe;
    }
    // 内容居中
    .content-center {
        width: 1200px;
        margin: 0 auto;
    }
    .category {
        background-color: #fff;
        z-index: 1;
        padding: 35px;
        .cate-type {
            font-size: 14px;
            font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
            font-weight: 300;
            color: #0b1d30;
        }
        .cate-all {
            font-size: 14px;
            font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
            font-weight: 300;
            color: #006fff;
        }
        .cate-item {
            font-size: 14px;
            font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
            font-weight: 300;
            color: #757f92;
            overflow: hidden;
            margin: 0 -10px;
            .cate-item-name {
                float: left;
                padding: 0 10px;
                // margin-right: 20px;
                cursor: pointer;
            }
            .cate-item-name:hover {
                color: #006fff;
            }
        }
    }
    .border {
        border: 1px solid #eeeeee;
    }
    .content-list {
        padding-top: 20px;
        background: #f6f8fb;
        .content-center {
            padding: 0 35px;
            background-color: #ffffff;
        }
        // 列表筛选
        .app-filter {
            height: 68px;
            border-bottom: 1px solid #eeeeee;
            margin-bottom: 25px;
            display: flex;
            justify-content: space-between;
            user-select: none;
            ul {
                display: flex;
            }
            li {
                display: flex;
                line-height: 68px;
                font-size: 14px;
                margin-right: 25px;
                color: #0b1d30;
                cursor: pointer;
                .active {
                    color: #006fff;
                }
            }
            .filter-caret {
                display: inline-block;
                height: 11px;
                width: 16px;
                position: relative;
                font-size: 12px;
                .el-icon-caret-bottom {
                    position: absolute;
                    top: 3px;
                }
                .el-icon-caret-top {
                    position: absolute;
                    top: -3px;
                }
            }
            .filter-right {
                line-height: 68px;
                span {
                    font-size: 14px;
                    color: #757f92;
                }
                i {
                    color: #c4c4c4;
                    margin-left: 12px;
                    transition: all 0.3s;
                }
                .el-icon-minus {
                    transform: rotate(90deg);
                    margin: 0;
                }
                .active {
                    color: #006fff;
                }
                i:hover {
                    color: #006fff;
                }
            }
        }
        // 列表内容
        .app-list {
            .app-item {
                border: 1px solid #eeeeee;
                margin-bottom: 25px;
                display: flex;
                .app-item-image {
                    width: 290px;
                    height: 195px;
                    background: #eee;
                }
                .app-item-right {
                    flex: 1;
                    padding: 22px 22px 0;
                    display: flex;
                    .app-item-desc {
                        font-size: 12px;
                        font-family: Microsoft YaHei UI-Light,
                            Microsoft YaHei UI;
                        color: #757f92;
                        line-height: 20px;
                        margin-bottom: 20px;
                        width: 516px;
                        overflow: hidden;
                        text-overflow: ellipsis; //文本超出省略
                        display: -webkit-box;
                        -webkit-line-clamp: 2; //第几行省略就设置第几行
                        -webkit-box-orient: vertical;
                    }
                    .app-item-content {
                        padding: 8px 0 0 8px;
                    }
                    .app-item-title {
                        font-size: 14px;
                        color: #0b1d30;
                        line-height: 20px;
                        margin-bottom: 10px;
                    }
                    .app-item-line {
                        height: 1px;
                        background: #eeeeee;
                        margin-bottom: 25px;
                    }
                    .app-item-company {
                        font-size: 12px;
                        color: #0b1d30;
                        line-height: 20px;
                        display: flex;
                        justify-content: flex-start;
                        line-height: 26px;
                        .app-item-tag {
                            font-size: 12px;
                            color: #34a4fc;
                            width: 70px;
                            height: 22px;
                            line-height: 22px;
                            border: 1px solid #34a4fc;
                            text-align: center;
                            margin-right: 10px;
                        }
                    }
                    .app-item-price {
                        width: 225px;
                        border-left: 1px solid #eee;
                        height: 150px;
                        text-align: center;
                        padding-top: 30px;
                        p {
                            margin-bottom: 15px;
                        }
                        .new-price-unit {
                            font-size: 14px;
                            font-family: Microsoft YaHei UI-Light,
                                Microsoft YaHei UI;
                            color: #ff0027;
                            line-height: 16px;
                        }
                        .new-price {
                            font-size: 24px;
                            color: #ff0027;
                            line-height: 28px;
                        }
                        .del-price {
                            font-size: 14px;
                            color: #8b8b8b;
                            font-family: DIN Light-Regular, DIN Light;
                            font-weight: 400;
                            line-height: 16px;
                        }
                        .new-time {
                            font-size: 12px;
                            color: #757f92;
                            line-height: 14px;
                        }
                        .flex {
                            justify-content: center;
                        }
                        .app-item-comments {
                            font-size: 12px;
                            font-family: Microsoft YaHei UI-Light,
                                Microsoft YaHei UI;
                            color: #0b1d30;
                            line-height: 22px;
                        }
                        .app-item-btn {
                            width: 100px;
                            height: 32px;
                            border: 1px solid #006fff;
                            font-size: 14px;
                            line-height: 32px;
                            text-align: center;
                            margin: 0 auto;
                            a{
                                color: #006fff;
                            }
                        }
                        .app-item-people {
                            font-size: 12px;
                            font-family: DIN Light-Regular, DIN Light;
                            font-weight: 400;
                            color: #757f92;
                            line-height: 14px;
                        }
                        /deep/ .el-rate__icon {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
        // 分页
        .app-page {
            text-align: right;
        }
    }
}
</style>