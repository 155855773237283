<template>
    <div class="saas-list">
        <div class="banner">
            <div class="banner-center">
                <div class="title">
                    <h3>Saas云应用市场</h3>
                    <p>沧州工业云产品家族支撑企业上云业务各类场景</p>
                </div>
                <div class="search">
                    <div>
                        <input type="text" v-model="proName"  name="" id="" placeholder="搜索产品名称">
                        <p><span class="iconfont icon-search" @click="serchList1"></span></p>
                    </div>
                </div>
                <a @click="alertshow">服务商入驻</a>
            </div>
        </div>

        <div class="sort">
            <div>
                <p>行业分类</p>
                <div>
                    <p @click="productClassAll(0)">全部</p>
                    <ul>
                        <li v-for="(item, index) in industryClass" :key="index + 'i'" @click="industryCheck(index)">{{item.className}}</li>
                        <!-- <li>绿色化工</li>
                        <li>管道装备</li>
                        <li>服装服饰</li>
                        <li>轨道交通</li>
                        <li>清洁能源</li>
                        <li>生物医药</li>
                        <li>先进再造</li>
                        <li>智能装备</li>
                        <li>激光产业</li>
                        <li>物流</li>
                        <li>文化旅游</li>
                        <li>信息应用</li>
                        <li>药包材行业</li>
                        <li>线缆行业</li>
                        <li>模具行业</li>
                        <li>食品行业</li>
                        <li>汽车及零部件行业</li> -->
                    </ul>
                </div>
            </div>
            <div>
                <p>产品分类</p>
                <div>
                    <p @click="productClassAll(1)">全部</p>
                    <ul>
                        <li v-for="(item, index) in productClass" :key="index + 'p'" @click="getProductClassSon(index)">{{item.name}}</li>
                        <!-- <li>办公协同</li>
                        <li>供应链管理</li>
                        <li>生产制造</li>
                        <li>智能仓储</li>
                        <li>财务财税</li>
                        <li>数据安全分析</li>
                        <li>数字营销</li> -->
                    </ul>
                </div>
            </div>
            <div v-show="isClassSon">
                <p></p>
                <div>
                    <p @click="productClassAll(2)">全部</p>
                    <ul>
                        <li v-for="(item, index) in productClassSon" :key="index + 'ps'" @click="getProClassSonId(index)">{{item.name}}</li>
                        <!-- <li>办公协同</li>
                        <li>供应链管理</li>
                        <li>生产制造</li>
                        <li>智能仓储</li>
                        <li>财务财税</li>
                        <li>数据安全分析</li>
                        <li>数字营销</li> -->
                    </ul>
                </div>
            </div>
            <!-- <div>
                <p>价格</p>
                <div>
                    <p>全部</p>
                    <ul>
                        <li>免费</li>
                        <li>100以内</li>
                        <li>100-200</li>
                        <li>200-300</li>
                        <li>300以上</li>
                        <li>价格 <input type="text"> - <input type="text"></li>
                    </ul>
                </div>
            </div> -->
        </div>

        <div class="pro-list">
            <div class="left">
                <div class="left-t">
                    <div>
                        <p>综合排名</p>
                        <ul>
                            <li><span class="el-icon-caret-top"></span></li>
                            <li><span class="el-icon-caret-bottom"></span></li>
                        </ul>
                    </div>
                    <div>
                        <p>价格排序</p>
                        <ul>
                            <li><span class="el-icon-caret-top"></span></li>
                            <li><span class="el-icon-caret-bottom"></span></li>
                        </ul>
                    </div>
                    <div>
                        <p>发布时间</p>
                        <ul>
                            <li><span class="el-icon-caret-top"></span></li>
                            <li><span class="el-icon-caret-bottom"></span></li>
                        </ul>
                    </div>
                </div>
                <div class="left-c">
                    <!-- <child @search="getSearchList"></child> -->
                    <ul v-loading="loading">
                        <template v-if="productListArr.length > 0">
                        <li v-for="(item,index) in productListArr" :key="index + 'pro'"><router-link :to="{path: 'details', query: {id: item.id}}" target="_blank">
                            <p><img :src="item.list_image?item.list_image:require('../../assets/moren-icon.png')" alt=""></p>
                            <div>
                                <h4>{{item.name}}</h4>
                                <p>{{item.introduce}}</p>
                                <div>
                                    <p><span>服务商：</span>{{item.supp_user}}</p>
                                    <span>访问量：{{item.view_num}}</span>
                                    <span>发布时间：{{item.createTime.substring(0,10)}}</span>
                                </div>
                                <ul class="label">
                                    <li v-for="(itemLabel, indexLabel) in  JSON.parse(item.label)" :key="indexLabel + 'lab'">{{itemLabel.labelTag}}</li>

                                    <!-- <li>{{typeof JSON.parse(item.label)}}</li> -->
                                    <!-- <li>追踪溯源</li>
                                    <li>数据共享</li> -->
                                </ul>
                            </div>
                            <p><i>{{item.price == '-1'? '': item.price}}</i></p>
                        </router-link></li>
                        </template>

                        <template v-else>
                            <li><el-empty :image-size="200"></el-empty></li>
                        </template>
                        <!-- <li><a href="">
                            <img :src="require('../../assets/Saas/pic_1.png')" alt="">
                            <div>
                                <h4>标识解析应用</h4>
                                <p>打通企业生产制造各环节，信息互通，数据共享、防伪防窜</p>
                                <div>
                                    <p><span>服务商：</span>沧州工业云</p>
                                    <span>发布时间：2022-02-28</span>
                                </div>
                                <ul class="label">
                                    <li>防伪防窜</li>
                                    <li>追踪溯源</li>
                                    <li>数据共享</li>
                                </ul>
                            </div>
                            <p><i>面议</i></p>
                        </a></li>
                        <li><a href="">
                            <img :src="require('../../assets/Saas/pic_1.png')" alt="">
                            <div>
                                <h4>客户管理(云CRM)</h4>
                                <p>提供客户管理数字化、销售管理自动化、服务管理智能化、项目管理一体化的个性化解决方案。</p>
                                <div>
                                    <p><span>服务商：</span>沧州工业云</p>
                                    <span>发布时间：2022-02-28</span>
                                </div>
                                <ul class="label">
                                    <li>防伪防窜</li>
                                    <li>追踪溯源</li>
                                    <li>数据共享</li>
                                </ul>
                            </div>
                            <p><b>¥198,888.00</b><span> 元/年起</span></p>
                        </a></li>
                        <li><a href="">
                            <img :src="require('../../assets/Saas/pic_1.png')" alt="">
                            <div>
                                <h4>客户管理(云CRM)</h4>
                                <p>提供客户管理数字化、销售管理自动化、服务管理智能化、项目管理一体化的个性化解决方案。</p>
                                <div>
                                    <p><span>服务商：</span>沧州工业云</p>
                                    <span>发布时间：2022-02-28</span>
                                </div>
                                <ul class="label">
                                    <li>防伪防窜</li>
                                    <li>追踪溯源</li>
                                    <li>数据共享</li>
                                </ul>
                            </div>
                            <p><b>¥198,888.00</b><span> 元/年起</span></p>
                        </a></li> -->
                    </ul>
                </div>
                <div class="left-b">
                    <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :hide-on-single-page="true"
                    background
                    layout="total, prev, pager, next, jumper"
                    :page-size="pageSize"
                    :total="total">
                    </el-pagination>
                </div>
            </div>
            <div class="right">
                <h3>猜你喜欢</h3>
                <ul>
                    <li v-for="(item, index) in likeList" :key="index + 'l'"><router-link :to="{path: 'details', query: {id: item.id}}" target="_blank">
                        <img :src="item.list_image" alt="">
                        <h4>{{item.name}}</h4>
                        <p>{{item.introduce}}</p>
                        <p v-show="item.price != '-1'"><b>¥ {{item.price}}</b> 元/年起</p>
                    </router-link></li>
                    <!-- <li><a href="">
                        <img :src="require('../../assets/Saas/pic_1.png')" alt="">
                        <h4>企业级工业互联网</h4>
                        <p>数据驱动为核心的网络化、扁平化、平台化的经营管理</p>
                        <p><b>¥ 198,888.00</b> 元/年起</p>
                    </a></li>
                    <li><a href="">
                        <img :src="require('../../assets/Saas/pic_1.png')" alt="">
                        <h4>企业级工业互联网</h4>
                        <p>数据驱动为核心的网络化、扁平化、平台化的经营管理</p>
                        <p><b>¥ 198,888.00</b> 元/年起</p>
                    </a></li> -->
                </ul>
            </div>
        </div>

        <div class="adv">
            <serve></serve>
        </div>

        <contact-alert :showVisible="showVisible" @child-event="parentEvent"></contact-alert>
    </div>
</template>
<script>
import serve from '../../components/serve.vue'
import child from '../../components/home-nav.vue'
import contactAlert from '@/components/contactAlert'
import qs from 'qs'
export default {
    data() {
        return{
            showVisible: false,
            proName: '',
            checkIndId: '',
            checkClaId: '',
            industryClass: [],
            productClass: [],
            productClassSon: [],
            isClassSon: false,
            page: 1,
            total: 0,
            pageSize: 10,
            productListArr: [],
            loading: true,
            likeList: []
        }
    },
    
    components: {
        serve,
        child,
        contactAlert
    },
    mounted() {
        this.getIndustryClass()
        this.getProductClass()
        this.getYouLikeList()
        if(this.$route.query.name){
            this.productListArr = this.$store.state.searchList
            // this.total = this.$store.state.searchList.total
            this.proName = this.$route.query.name
            this.getProductList()
            this.loading = false
        }else{
            this.getProductList()
        }
    },
    methods: {
        alertshow() {
            this.showVisible = !this.showVisible
        },
        parentEvent(data){
            this.showVisible = data
        },
        handleSizeChange(val) {
        },
        handleCurrentChange(val) {
            this.page = val
            this.getProductList()
        },

        // getSearchList(mes) {
        //     this.productListArr = mes
        //     this.total = mes.total
        //     this.proName = this.$route.query.name
        // },

        // 搜索
        serchList1() {
            this.checkClaId = ''
            this.checkIndId = ''
            this.getProductList()
        },

        // 获取行业分类
        getIndustryClass() {
            this.$api.product.industryClass({
                parentId:1
            }).then(res => {
                this.industryClass = res.data.records
            })
        },

        // 选择行业分类
        industryCheck(index) {
            this.proName = ''
            $('.saas-list .sort > div:nth-child(1) > div ul li').eq(index).addClass('show')
            $('.saas-list .sort > div:nth-child(1) > div ul li').eq(index).siblings().removeClass('show')
            $('.saas-list .sort > div:nth-child(1) > div ul li').parent().parent().find('p').css({'color': '#757F92'})
            this.checkIndId = this.industryClass[index].id
            this.getProductList()
        },

        // 获取产品分类
        getProductClass(){
            this.$api.product.productClass({}).then(res => {
                if(res.code == 200){
                    this.productClass = res.data
                }else{
                    this.$message.error(res.msg)
                }
            })
        },

        // 获取子级产品分类
        getProductClassSon(classIndex) {
            this.proName = ''
            this.isClassSon = true
            this.productClassSon = this.productClass[classIndex].children
            $('.saas-list .sort > div:nth-child(2) > div ul li').eq(classIndex).addClass('show')
            $('.saas-list .sort > div:nth-child(2) > div ul li').eq(classIndex).siblings().removeClass('show')
            $('.saas-list .sort > div:nth-child(2) > div ul li').parent().parent().find('p').css({'color': '#757F92'})
            this.checkClaId = this.productClass[classIndex].id
            this.getProductList()
        },

        // 全部一级产品分类
        productClassAll(index) {
            this.proName = ''
            if(index == 1){
                this.isClassSon = false
                this.checkClaId = ''
            }else if(index == 2){
                this.checkClaId = ''
            }else{
                this.checkIndId = ''
            }
            this.getProductList()
            $('.saas-list .sort > div').eq(index).find('> div p').css({'color': '#1F51C8'})
            $('.saas-list .sort > div').eq(index).find('> div ul li').removeClass('show')
        },

        // 获取二级分类id
        getProClassSonId(index) {
            this.checkClaId = this.productClassSon[index].id
            $('.saas-list .sort > div:nth-child(3) > div ul li').eq(index).addClass('show')
            $('.saas-list .sort > div:nth-child(3) > div ul li').eq(index).siblings().removeClass('show')
            $('.saas-list .sort > div:nth-child(3) > div ul li').parent().parent().find('p').css({'color': '#757F92'})
            this.getProductList()
        },

        // 获取产品列表
        getProductList() {
            var json = {
                current: this.page,
                size: this.pageSize,
                class_id: this.checkClaId,
                solution: this.checkIndId,
                like_name: this.proName
            }
            let _this = this
            _this.loading = true
            _this.$api.product.productList(json).then(res => {
                _this.loading = false
                if(res.code == 200){
                    _this.total = res.data.total
                    _this.productListArr = res.data.records
                }else{
                    _this.total = 0
                    _this.productListArr = []
                }
            }).catch(()=>{
                _this.loading = false
            })
            // setTimeout(() => {
            //     _this.loading = false
            // }, 2000);
        },

        // 猜你喜欢
        getYouLikeList() {
            this.$api.product.proRecommend({}).then(res => {
                this.likeList = res.data.records
            })
        }
    },
}
</script>

<style  lang="scss">
.saas-list{
    .el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #1F51C8;
    }
    .el-pagination.is-background .btn-next, .el-pagination.is-background .btn-prev, .el-pagination.is-background .el-pager li{
        background-color: #ffffff;
        color: #D7D7D7;
        border: 1px solid #D7D7D7;
        font-weight: normal;
    }
}
</style>

<style lang="scss" scoped>
.saas-list{
    background-color: #F6F8FB;
    // margin-top: 62px;
    .banner{
        width: 100%;
        height: 540px;
        background: url('../../assets/Saas/banner.jpg') center no-repeat;
        background-size: auto 100%;
        margin-bottom: 20px;
        .banner-center{
            width: 1200px;
            height: 540px;
            margin: 0 auto;
            position: relative;
            .title{
                padding-top: 135px;
                h3{
                    font-size: 42px;
                    color: #0B1D30;
                    margin-bottom: 20px;
                }
                p{
                    font-size: 20px;
                    color: #757F92;
                }
            }
            .search{
                width: 476px;
                height: 43px;
                position: absolute;
                left: 0;
                bottom: 207px;
                line-height: 40px;
                box-shadow: 0 0 10px rgba(0,0,0,0.06);
                border: 2px solid #ffffff;
                background: #ffffff;
                div{
                    display: flex;
                    background: linear-gradient(180deg, #F3F5F8 0%, rgba(255, 255, 255, 0) 100%);
                
                    input{
                        width: 446px;
                        height: 43px;
                        line-height: 43px;
                        padding: 0  15px;
                        box-sizing: border-box;
                        background-color: rgba(255,255,255,0);
                    }
                    p{
                        line-height: 43px;
                        span{
                            font-size: 20px;
                            color: #1F51C8;
                            font-weight: 600;
                            cursor: pointer;
                        }
                    }
                }
            }
            a{
                width: 140px;
                height: 40px;
                text-align: center;
                font-size: 14px;
                line-height: 40px;
                background-color: #1F51C8;
                color: #ffffff;
                position: absolute;
                bottom: 77px;
                left: 0;
                cursor: pointer;
            }
        }
    }
    .sort{
        width: 1200px;
        margin: 0 auto;
        background-color: #ffffff;
        box-sizing: border-box;
        padding: 0 35px;
        box-shadow: 0 0 5px rgba(0,0,0,0.1);
        font-size: 14px;
        >div{
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #DFE1E6;
            padding: 20px 0;
            >p{
                width: 56px;
                color: #0B1D30;
                line-height: 34px;
            }
            >div{
                display: flex;
                align-content: space-around;
                p{
                    color: #1F51C8;
                    margin-right: 20px;
                    line-height: 34px;
                    cursor: pointer;
                }
                ul{
                    width: 964px;
                    display: flex;
                    align-content: space-around;
                    flex-wrap: wrap;
                    li{
                        line-height: 34px;
                        color: #757F92;
                        margin-right: 20px;
                        cursor: pointer;
                    }
                    li.show{
                        color: #1F51C8;
                    }
                }
            }
        }
    }
    .pro-list{
        width: 1200px;
        margin: 15px auto;
        display: flex;
        justify-content: space-between;
        .left{
            width: 900px;
            background-color: #ffffff;
            .left-t{
                height: 53px;
                border-bottom: 1px solid #DFE1E6;
                display: flex;
                justify-content: start;
                align-items: center;
                >div{
                    margin-left: 35px;
                    display: flex;
                    justify-self: start;
                    p{
                        font-size: 14px;
                        color: #252d3d;
                        height: 18px;
                        line-height: 18px;
                        
                    }
                    ul{
                        margin-top: -1px;
                        li{
                            width: 8px;
                            height: 6px;
                            line-height: 5px;
                            text-align: center;
                            span{
                                display: block;
                                width: 8px;
                                height: 6px;
                                color: #757F92;
                                font-size: 14px;
                            }
                        }
                    }
                }
            }
            .left-c{
                >ul{
                    >li{
                        padding: 30px 35px;
                        border-bottom: 1px solid #DFE1E6;
                        a{
                            display: flex;
                            justify-content: start;
                            align-items: center;
                            >p:nth-child(1){
                                width: 40px !important;
                                height: 40px;
                                margin-right: 55px;
                                background-color: #F6F8FB;
                                padding: 10px;
                                img{
                                    width: 40px;
                                    height: 40px;
                                }
                            }
                            >div{
                                width: 495px;
                                margin-right: 50px;
                                h4{
                                    font-size: 16px;
                                    color: #252D3D;
                                }
                                >p{
                                    height: 30px;
                                    display: flex;
                                    // align-items: center;
                                    font-size: 12px;
                                    color: #0B1D30;
                                    margin: 10px 0;
                                    overflow: hidden;
                                }
                                div{
                                    display: flex;
                                    justify-content: space-between;
                                    font-size: 12px;
                                    margin-bottom: 10px;
                                    p{
                                        color: #2042AC;
                                    }
                                    span{
                                        color: #7d899e;
                                    }
                                }
                                ul{
                                    display: flex;
                                    justify-content: start;
                                    li{
                                        line-height: 18px;
                                        padding: 0 7px;
                                        margin-right: 5px;
                                        font-size: 12px;
                                        color: #757F92;
                                        border: 1px solid #757F92;
                                    }
                                }
                            }
                            >p{
                                width: 155px;
                                text-align: right;
                                i{
                                    font-size: 14px;
                                    font-style: normal;
                                    color: #ff0027;
                                }
                                b{
                                    font-size: 18px;
                                    color: #ff0027;
                                }
                                span{
                                    font-size: 12px;
                                    color: #757F92;
                                }
                            }
                        }
                    }
                }
            }
            .left-b{
                margin: 15px 50px;
                text-align: right;
            }
        }
        .right{
            align-self: baseline;
            width: 285px;
            background-color: #ffffff;
            padding: 18px 20px;
            box-sizing: border-box;
            h3{
                font-weight: normal;
                font-size: 16px;
                color: #0B1D30;
                margin-bottom: 35px;
            }
            ul{
                li{
                    border-bottom: 1px solid #DFE1E6;
                    margin-bottom: 20px;
                    text-align: center;
                    a{
                        img{
                            width: 40px;
                            height: 40px;
                            margin: 0 auto;
                            margin-bottom: 20px;
                        }
                        h4{
                            font-size: 14px;
                            font-weight: normal;
                            color: #0B1D30;
                            margin-bottom: 5px;
                        }
                        p{
                            font-size: 12px;
                            color: #757F92;
                            line-height: 18px;
                            margin-bottom: 15px;
                            text-align: left;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                        }
                        p:last-child{
                            text-align: center;
                            b{
                                font-size: 18px;
                                color: #FF0027;
                            }
                        }
                    }
                }
                li:last-child{
                    border-bottom: 0 none;
                }
            }
        }
    }
    .adv{
        margin-top: 50px;
        position: relative;
        height: 90px;
        background-color: #ffffff;
        .serve-common{
            bottom: -25px;
        }

    }
}
</style>