
/**
 * 全局常量、方法封装模块
 * 通过原型挂载到Vue属性
 * 通过 this.Global 调用
 */

// 后台管理系统服务器地址

export const baseUrl = 'http://api.czsx.com:8909/'  // 服务器地址


// export const an = '/anApi'  // 服务器地址
// export const meng = '/api'  // 服务器地址
export const api = '/api'


export default {
    baseUrl,
    api,
}