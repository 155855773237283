<template>
    <div class="gov-news-list">
        <!-- <nav-bar></nav-bar> -->
        
        <div class="banner">
            <img :src="require('../../assets/news/gov-banner.jpg')" alt="">
        </div>

        <div class="gov-news-list-center">
            <div class="crumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item>首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{path: '/govHome'}">政策服务</el-breadcrumb-item>
                    <el-breadcrumb-item>{{checkClassName}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div class="gov-list">
                <div class="left">
                    <h3>政策资讯</h3>
                    <ul>
                        <li v-for="(item,index) in govNewsParentClassList" :key="index + 'parebtClass'" @click="getCurrentParentClass(item.id)" :class="item.id == checkParentClass ? 'show': ''">{{item.className}}</li>
                    </ul>
                </div>
                <div class="right">
                    <div class="search">
                        <input type="text" v-model.trim="keyWord" placeholder="请输入搜索内容">
                        <a @click="search">搜索</a>
                    </div>

                    <div class="sort">
                        <div class="sort-left">
                            <p>行业分类：{{currentClassName}}</p>
                        </div>
                        <div class="sort-right">
                            <p @click="getParentNewsList(checkParentClass)" :class="checkSonClass == checkParentClass ? 'show': ''">全部 &nbsp;&nbsp;</p>
                            <ul>
                                <li v-for="(item,index) in govNewsSonClassList" :key="index + 'soanClass'" @click="getCurrentSonClass(item.id)" :class="item.id == checkSonClass ? 'show': ''">{{item.className}}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="lists">
                        <ul>
                            <li v-for="(item, index) in govNewsList" :key="index + 'newsList'"><router-link :to="{path: '/govNewsDetails',  query: {id: item.id, classId: item.classId2, topClass: item.classId1}}">
                                <p><img :src="item.image" alt=""></p>
                                <div>
                                    <h5>{{item.name}}</h5>
                                    <p>{{item.introduce}}</p>
                                    <div>
                                        <p>{{item.orgin}} &nbsp;&nbsp;{{item.createTime}}</p>
                                        <span v-if="item.isUp == 1">推荐</span>
                                    </div>
                                </div>
                            </router-link></li>
                        </ul>
                    </div>

                    <div class="page">
                        <el-pagination
                            background
                            :hide-on-single-page="true"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page.sync="pageNum"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import navBar from '@/components/navBar'
export default {
    data() {
        return{
            keyWord: '',
            govNewsList: [],
            checkClassName: '',
            govNewsParentClassList: [], // 一级分类列表
            govNewsSonClassList: [],    // 当前选中一级下的二级分类列表
            checkParentClass: '',  // 当前选中一级分类
            checkSonClass: '',     // 当前选中二级分类
            currentClassName: '',  // 当前选中分类名称
            pageSize: 6,
            pageNum: 1,
            total: 0,
        }
    },
    components: {
       // navBar
    },
    methods: {
        handleSizeChange(val) {
        },
        handleCurrentChange(val) {
            this.pageNum = val
            this.getClassNewsList(this.checkSonClass,this.keyWord)
        },
        // 查询当前一级分类名称
        getGovClassName(id) {
            this.$api.news.govClassName({id}).then(res => {
                if(res.code == 200){
                    this.checkClassName = res.data.className
                }
            })
        },
        // 获取当前二级分类名称
        getGovSonClassName(id) {
            this.$api.news.govClassName({id}).then(res => {
                if(res.code == 200){
                    this.currentClassName = res.data.className
                }
            })
        },
        // 获取政策1级分类
        getGovNewsClass() {
            var json = {
                level: 1
            }
            this.$api.news.govNewsClassList(json).then(res => {
                if(res.code == 200){
                    this.govNewsParentClassList = res.data.records
                    // this.getGovNewsSonClass(this.$route.query.classId)
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        // 获取当前一级分类
        getCurrentParentClass(classId) {
            this.checkParentClass = classId
            this.checkSonClass = this.checkParentClass
            this.getGovClassName(classId)
            this.getClassNewsList(classId, this.keyWord)
            this.getGovNewsSonClass(classId)
        },

        // 获取政策2级分类列表
        getGovNewsSonClass(classId) {
            this.$api.news.govNewsClassList({parentId: classId}).then(res => {
                if(res.code == 200){
                    // this.checkClassName = res.data.className
                    this.govNewsSonClassList = res.data.records
                }else{
                    this.$message.error(res.msg)
                }
            })
        },

        // 获取一级分类列表
        getParentNewsList() {
            this.checkSonClass = this.checkParentClass
            this.currentClassName = '全部'
        },

        // 获取二级分类信息
        getCurrentSonClass(classId) {
            this.checkSonClass = classId
            this.getClassNewsList(classId, this.keyWord)
            this.getGovSonClassName(classId)
        },

        // 获取某一级分类下新闻
        getClassNewsList(classId, keyword) {
            var json = {
                size: this.pageSize,
                current: this.pageNum,
                classId: classId,
                name: keyword
            }
            this.$api.news.govNewsOneClassList(json).then(res => {
                if(res.code == 200){
                    this.total = res.data.total
                    this.govNewsList = res.data.records
                }else{
                    this.$message.error(res.msg)
                }
            })
        },

        // 搜索
        search() {
            if(this.keyWord){
                this.getClassNewsList(this.checkSonClass, this.keyWord)
            }else{
                this.$message.warning('请输入搜索内容')
            }
        }
    },
    mounted() {
        this.getGovClassName(this.$route.query.classId)
        this.getGovNewsClass()
        this.getCurrentParentClass(this.$route.query.classId)
        this.getParentNewsList(this.$route.query.classId)
    }
}
</script>

<style lang="scss">

.gov-news-list-center{
    .el-input__inner{
        width: 46px !important;
    }
    
}
.gov-news-list{
    .gov-news-list-center{
        .crumb{
            .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
                font-weight: 500;
                color: #0B1D30;
            }
            .el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
                color: #C7000B;
            }
            .el-breadcrumb__separator{
                font-weight: 500;
            }
        }
        .el-pagination.is-background .el-pager li:not(.disabled).active{
            background-color: #C7000B;
        }
    }
}
</style>

<style lang="scss" scoped>
.gov-news-list{
    .banner{
        width: 100%;
        height: 335px;
        overflow: hidden;;
        img{
            height: 335px;
        }
    }
    .gov-news-list-center{
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 80px;
        .crumb{
            margin: 30px 0;
        }
        .gov-list{
            display: flex;
            justify-content: space-between;
            .left{
                width: 253px;
                height: 460px;
                padding: 10px 0;
                box-shadow: 0px 0px 12px 1px rgba(0, 111, 255, 0.1);
                text-align: left;
                text-indent: 3em;
                h3{
                    font-size: 18px;
                    font-weight: 400;
                    color: #0B1D30;
                    margin: 20px 0;
                    border-left: 1px solid #C7000B;
                }
                ul{
                    padding: 0 10px;
                    li{
                        line-height: 54px;
                        border-top: 1px solid #EEEEEE;
                        font-size: 14px;
                        color: #757F92;
                        text-align: left;
                        text-indent: 4em;
                        cursor: pointer;
                    }
                    li.show{
                        color: #C7000B;
                    }
                }
            }
            .right{
                width: 917px;
                .search{
                    display: flex;
                    border: 1px solid #EEEEEE;
                    border-radius: 2px;
                    overflow: hidden;
                    input{
                        width: 821px;
                        line-height: 38px;
                        padding: 0 20px;
                        box-sizing: border-box;
                    }
                    a{
                        width: 94px;
                        height: 38px;
                        display: block;
                        background-color: #C7000B;
                        color: #ffffff;
                        text-align: center;
                        line-height: 40px;
                        cursor: pointer;
                    }
                }
                .sort{
                    display: flex;
                    justify-content: space-between;
                    margin: 25px 0;
                    .sort-left{
                        width: 200px;
                        font-size: 16px;
                        color: #757F92;
                    }
                    .sort-right{
                        display: flex;
                        font-size: 14px;
                        cursor: pointer;
                        color: #757F92;
                        >p.show{
                            color: #C7000B;
                        }
                        ul{
                            display: flex;
                            li{
                                padding: 0 10px;
                                border-left: 1px solid #757F92;
                            }
                            li.show{
                                color: #C7000B;
                            }
                        }
                    }
                }
                .lists{
                    margin-bottom: 60px;
                    ul{
                        li{
                            padding-bottom: 10px;
                            border-bottom: 1px solid #EEEEEE;
                            margin-top: 30px;
                            a{
                                display: flex;
                                justify-content: space-between;
                                >p{
                                    width: 290px;
                                    height: 150px;
                                    overflow: hidden;
                                    position: relative;
                                    img{
                                        width: 290px;
                                        height: auto;
                                        position: absolute;
                                        top: 50%;
                                        left: 0;
                                        transform: translateY(-50%);
                                    }
                                }
                                >div{
                                    width: 592px;
                                    h5{
                                        font-size: 16px;
                                        line-height: 30px;
                                        margin-bottom: 10px;
                                    }
                                    >p{
                                        color: #757F92;
                                        height: 50px;
                                        line-height: 25px;
                                        font-size: 14px;
                                        overflow : hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                    }
                                    div{
                                        margin-top: 20px;
                                        display: flex;
                                        justify-content: space-between;
                                        color: #8F99A1;
                                        font-size: 14px;
                                        line-height: 25px;
                                        span{
                                            display: block;
                                            width: 50px;
                                            height: 25px;
                                            border-radius: 6px;
                                            font-size: 12px;
                                            text-align: center;
                                            color: #C7000B;
                                            border: 1px solid #C7000B;
                                        }
                                    }
                                }
                            }           
                        }
                    }
                }
                .page{
                    text-align: right;
                }
            }
        }
    }
}
</style>