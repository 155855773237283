<template>
  <div class="banner_wrapper">
    <div class="prodInfo">
      <div class="info_content">
      <div class="btns">
        <el-button type="enquiry">立即咨询</el-button>
        <el-button type="other" @click="drawer = true">立即购买</el-button>
        <el-button type="other">免费试用</el-button>
<!--        <el-button type="text">使用指南</el-button>-->
      </div>
      </div>
    </div>
    <el-row class="cards" :gutter="20">
      <el-col :span="8" v-for="(item,index) in cardList" :key="'card'+ index">
        <el-card class="box-card" shadow="always">
          <el-image style="width: 100%; height: 208px" :src="item.imgUrl" :fit="'fit'"></el-image>
        </el-card>
      </el-col>
    </el-row>
    <el-drawer
        title="选择套餐"
        :visible.sync="drawer"
        :direction="'rtl'"
        size="600"
        :before-close="handleClose">
      <span>
        <purchase></purchase>
      </span>
    </el-drawer>
  </div>
</template>

<script>
import purchase from './purchase'

export default {
  name: "topBanner",
  data() {
    return {
      drawer: false,
      cardList: [
        {imgUrl: require('../assets/imgs/banner_tab1.png')},
        {imgUrl: require('../assets/imgs/banner_tab2.png')},
        {imgUrl: require('../assets/imgs/banner_tab3.png')}
      ],
    }
  },
  components: {purchase},
  methods: {
    handleClose(done) {
      done()
    }
  }

}
</script>

<style lang="sass" scoped>
.banner_wrapper
  margin-bottom: 30px
  .prodInfo
    width: 100%
    height: 450px
    background: url("../assets/imgs/banner-top.png")
    background-size: 100% 100%
    position: relative
    .info_content
      position: absolute
      width: 720px
      height: 255px
      left: 360px
      top: 107px
      .btns
        position: absolute
        bottom: 0
        left: 1px
        /deep/.el-button
          margin-right: 5px
          padding: 11px 32px
        .el-button--enquiry
          background-color: #1F51C8
          color: #FFFFFF
          border: 1px solid #0b5ffd
        .el-button--other
          background-color: #dcdce0
          color: #757F92
          border: 1px solid #757F92
          border-radius: 0
  .cards
    padding: 61px 370px
    background: #FFFFFF
  /deep/.el-drawer__wrapper
    .el-drawer__header
      background-color: #1F51C8
      height: 63px
      text-align: center
      font: 400 18px/63px 'Microsoft YaHei'
      color: #FFFFFF
      padding: 0
</style>