import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersist from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersist({
  storage: window.sessionStorage
})

export default new Vuex.Store({
  state: {
    orderDetail: {},
    userId: '',
    searchList: []
  },
  getters: {},
  mutations: {
    getOrderDetail(state, json){
      state.orderDetail = json
    },
    getUserId(state,userId){
      state.userId = userId
    },
  },
  actions: {
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
