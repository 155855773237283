<template>
    <div class="account-set">
        <!-- 密码 -->
        <div class="PASS">
            <div class="login_password item_bd">
                <p>登陆密码</p>
                <p style="color:#c0c4cc;">建议您定期更换密码，设置一个包含字母，数字或符号中至少两项且长度为6位的密码。</p>
                <div>
                    <span style="color:#54bc54;">
                        <svg
                            t="1619754079330"
                            style="vertical-align: middle;"
                            class="icon"
                            viewBox="0 0 1056 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="1995"
                            width="22"
                            height="22"
                        >
                            <path
                                d="M521.28 96.128c229.696 0 415.872 186.176 415.872 415.872 0 229.696-186.176 415.872-415.872 415.872-229.696 0-415.872-186.176-415.872-415.872 0-229.696 186.176-415.872 415.872-415.872z m0 64C326.944 160.128 169.408 317.664 169.408 512s157.536 351.872 351.872 351.872S873.152 706.336 873.152 512 715.616 160.128 521.28 160.128z m199.168 234.496a32 32 0 0 1 1.28 42.304l-2.752 2.944-232.64 217.984a32 32 0 0 1-41.728 1.728l-2.944-2.624-119.008-120.768a32 32 0 0 1 42.592-47.616l3.008 2.688 97.088 98.528 209.856-196.608a32 32 0 0 1 45.248 1.44z"
                                p-id="1996"
                                fill="#009a00"
                            />
                        </svg>已设置
                    </span>
                    <el-divider direction="vertical"></el-divider>
                    <span style="color:#1c7bd4;cursor: pointer;" @click="showPassForm">修改</span>
                </div>
            </div>
            <div class="phonePass" v-if="OneShow == true">
                <el-form
                    :model="ruleForm"
                    status-icon
                    :rules="rules"
                    ref="ruleForm"
                    label-width="140px"
                    class="demo-ruleForm"
                >
                    <el-form-item label="新密码：" prop="newpass">
                        <el-input
                            type="password"
                            v-model="ruleForm.newpass"
                            autocomplete="off"
                            placeholder="请设置您的新密码"
                            show-password
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="再次输入新密码：" prop="checkPass">
                        <el-input
                            type="password"
                            v-model="ruleForm.checkPass"
                            autocomplete="off"
                            placeholder="请再次输入您设置的新密码"
                            show-password
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="手机验证码：">
                        <div class="d-flex">
                            <el-input v-model="identify_code1" placeholder="请点击发送验证码"></el-input>
                            <el-button
                                style="background:#f7d708"
                                v-if="show1"
                                @click="sendCode"
                            >{{get_Code}}</el-button>
                            <el-button
                                style="background:#f7d708;color:#242424;"
                                v-if="!show1"
                                disabled
                            >{{timecount1}}s后获取</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            style="background:#f7d708;width:200px;height:47px;border:none"
                            @click="subUpdatePass('ruleForm')"
                        >确认修改</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <!-- 手机号 -->
        <div class="PHONE">
            <div class="phone_bd item_bd">
                <p>手机绑定</p>
                <p style="color:#c0c4cc;">
                    您已绑定了手机
                    <span style="color:#54bc54">
                        {{phonenumber.substr(0, 3)+ "****" +phonenumber.substr(7)}}
                    </span>
                    [您的手机为安全手机，可以找回密码，并用于登录]
                </p>
                <div>
                    <span style="color:#54bc54">
                        <svg
                            t="1619754079330"
                            style="vertical-align: middle;"
                            class="icon"
                            viewBox="0 0 1056 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="1995"
                            width="22"
                            height="22"
                        >
                            <path
                                d="M521.28 96.128c229.696 0 415.872 186.176 415.872 415.872 0 229.696-186.176 415.872-415.872 415.872-229.696 0-415.872-186.176-415.872-415.872 0-229.696 186.176-415.872 415.872-415.872z m0 64C326.944 160.128 169.408 317.664 169.408 512s157.536 351.872 351.872 351.872S873.152 706.336 873.152 512 715.616 160.128 521.28 160.128z m199.168 234.496a32 32 0 0 1 1.28 42.304l-2.752 2.944-232.64 217.984a32 32 0 0 1-41.728 1.728l-2.944-2.624-119.008-120.768a32 32 0 0 1 42.592-47.616l3.008 2.688 97.088 98.528 209.856-196.608a32 32 0 0 1 45.248 1.44z"
                                p-id="1996"
                                fill="#009a00"
                            />
                        </svg>已设置
                    </span>
                    <el-divider direction="vertical"></el-divider>
                    <span style="color:#1c7bd4;cursor: pointer;" @click="showPhoneForm">修改</span>
                </div>
            </div>
            <div class="chang_phone" v-if="TwoShow == true">
                <p style="margin:35px 0 10px 236px;display:block">
                    <span>当前手机号：</span>
                    <span>{{phonenumber}}</span>
                </p>
                <el-form
                    :model="ruleForm1"
                    status-icon
                    :rules="rules1"
                    ref="ruleForm1"
                    label-width="140px"
                    class="demo-ruleForm"
                >
                    <el-form-item label="新手机号：" prop="phone">
                        <el-input
                            v-model="ruleForm1.phone"
                            autocomplete="off"
                            placeholder="请设置您的新手机号"
                        ></el-input>
                    </el-form-item>

                    <el-form-item label="验证码：">
                        <div class="d-flex">
                            <el-input v-model="identify_code2" placeholder="请点击发送验证码"></el-input>
                            <el-button
                                style="background:#f7d708"
                                v-if="show2"
                                @click="sendCode"
                            >{{get_Code}}</el-button>
                            <el-button
                                style="background:#f7d708;color:#242424;"
                                v-if="!show2"
                                disabled
                            >{{timecount2}}s后获取</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            style="background:#f7d708;width:200px;height:47px;border:none"
                            @click="subUpdatePhone('ruleForm1')"
                        >确认修改</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>

        <!-- 邮箱 -->
        <div class="EMAIL">
            <div class="email_bd item_bd">
                <p>备用邮箱</p>
                <p style="color:#c0c4cc;">已绑邮箱 <span style="color:#54bc54">
                        {{bak_email}}
                    </span>[备用邮箱绑定后可用来接收各类系统、营销、服务通知]</p>
                <div>
                    <span v-if="this.bak_email !== '' " style="color:#54bc54">
                        <svg
                            t="1619754079330"
                            style="vertical-align: middle;"
                            class="icon"
                            viewBox="0 0 1056 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="1995"
                            width="22"
                            height="22"
                        >
                            <path
                                d="M521.28 96.128c229.696 0 415.872 186.176 415.872 415.872 0 229.696-186.176 415.872-415.872 415.872-229.696 0-415.872-186.176-415.872-415.872 0-229.696 186.176-415.872 415.872-415.872z m0 64C326.944 160.128 169.408 317.664 169.408 512s157.536 351.872 351.872 351.872S873.152 706.336 873.152 512 715.616 160.128 521.28 160.128z m199.168 234.496a32 32 0 0 1 1.28 42.304l-2.752 2.944-232.64 217.984a32 32 0 0 1-41.728 1.728l-2.944-2.624-119.008-120.768a32 32 0 0 1 42.592-47.616l3.008 2.688 97.088 98.528 209.856-196.608a32 32 0 0 1 45.248 1.44z"
                                p-id="1996"
                                fill="#009a00"
                            />
                        </svg>已设置
                    </span>
                    <span v-else style="color:#ffa81c">
                        <svg
                            t="1619754404806"
                            style="vertical-align: middle;"
                            class="icon"
                            viewBox="0 0 1024 1024"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                            p-id="3425"
                            width="20"
                            height="20"
                        >
                            <path
                                d="M512 56.888889C261.688889 56.888889 56.888889 261.688889 56.888889 512s204.8 455.111111 455.111111 455.111111 455.111111-204.8 455.111111-455.111111-204.8-455.111111-455.111111-455.111111m0 853.333333c-221.866667 0-398.222222-176.355556-398.222222-398.222222s176.355556-398.222222 398.222222-398.222222 398.222222 176.355556 398.222222 398.222222-176.355556 398.222222-398.222222 398.222222"
                                fill="#ffa71b"
                                p-id="3426"
                            />
                            <path
                                d="M512 682.666667c-17.066667 0-28.444444 5.688889-39.822222 17.066666-11.377778 11.377778-17.066667 22.755556-17.066667 39.822223 0 17.066667 5.688889 28.444444 17.066667 39.822222 11.377778 11.377778 22.755556 17.066667 39.822222 17.066666 17.066667 0 28.444444-5.688889 39.822222-17.066666 11.377778-11.377778 17.066667-22.755556 17.066667-39.822222 0-17.066667-5.688889-28.444444-17.066667-39.822223-11.377778-11.377778-22.755556-17.066667-39.822222-17.066666z m-51.2-455.111111l17.066667 409.6h62.577777L563.2 227.555556H460.8z"
                                fill="#ffa71b"
                                p-id="3427"
                            />
                        </svg>未设置
                    </span>
                    <el-divider direction="vertical"></el-divider>
                    <span style="color:#1c7bd4;cursor: pointer;" @click="showMailForm">修改</span>
                </div>
            </div>
            <div class="chang_email" v-if="ThreeShow == true">
                <el-form
                    :model="ruleForm2"
                    status-icon
                    ref="ruleForm2"
                    label-width="140px"
                    class="demo-ruleForm"
                >
                    <el-form-item
                        prop="email"
                        label="邮箱"
                        :rules="[
            { required: true, message: '请输入邮箱地址', trigger: 'blur' },
            { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
            ]"
                    >
                        <el-input v-model="ruleForm2.email" placeholder="请输入您的邮箱"></el-input>
                    </el-form-item>

                    <el-form-item label="手机验证码：">
                        <div class="d-flex">
                            <el-input v-model="identify_code3" placeholder="请点击发送验证码"></el-input>
                            <el-button
                                style="background:#f7d708"
                                v-if="show3"
                                @click="sendCode"
                            >{{get_Code}}</el-button>
                            <el-button
                                style="background:#f7d708;color:#242424;"
                                v-if="!show3"
                                disabled
                            >{{timecount3}}s后获取</el-button>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button
                            style="background:#f7d708;width:200px;height:47px;border:none"
                            @click="subUpdateMail('ruleForm2')"
                        >确认修改</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
var self;
import jsCookie from "js-cookie";
import { mapActions } from "vuex";
import md5 from 'js-md5'
export default {
    data() {
        //验证密码
        var validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入新密码"));
            } else if (/.*[\u4e00-\u9fa5]+.*$/.test(value)) {
                callback(new Error("密码不能包含文字"));
            } else if (
                !/(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{6,16}$/.test(value)
            ) {
                callback(new Error("密码过于简单"));
            } else {
                if (this.ruleForm.checkPass !== "") {
                    this.$refs.ruleForm.validateField("checkPass");
                }
                callback();
            }
        };
        //再次验证密码
        var validatePass2 = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"));
            } else if (/.*[\u4e00-\u9fa5]+.*$/.test(value)) {
                callback(new Error("密码不能包含文字"));
            } else if (value !== this.ruleForm.newpass) {
                callback(new Error("两次输入密码不一致!"));
            } else {
                callback();
            }
        };
        //手机号验证
        var validatePhone = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入新手机号"));
            } else if (!/^1[3456789]\d{9}$/.test(value)) {
                callback(new Error("请输入正确手机号"));
            } else {
                callback();
            }
        };
        return {
            userid: "",
            //密码
            ruleForm: {
                newpass: "",
                checkPass: "",
            },
            //密码
            rules: {
                newpass: [
                    {
                        validator: validatePass,
                        trigger: "blur",
                    },
                ],
                checkPass: [
                    {
                        validator: validatePass2,
                        trigger: "blur",
                    },
                ],
            },
            //手机号
            ruleForm1: {
                phone: "",
            },
            //手机号
            rules1: {
                phone: [
                    {
                        validator: validatePhone,
                        trigger: "blur",
                    },
                ],
            },
            //邮箱
            ruleForm2: {
                email: "",
            },

            OneShow: false,
            TwoShow: false,
            ThreeShow: false,
            phone: "", //手机号
            identify_code1: "", //验证码
            identify_code2: "", //验证码
            identify_code3: "", //验证码
            password: "", //密码
            Confirm_pass: "", //确认密码
            show1: true,
            show2: true,
            show3: true,
            timecount1: "",
            timecount2: "",
            timecount3: "",
            uuid: "",
            get_Code: "发送验证码",
            phonenumber: "",
            bak_email: "",
        };
    },
    mounted() {
        // this.$api.changeZh.user_Common().then(res => {
        //   this.phonenumber = res.data.phonenumber
        //   this.bak_email = res.data.bak_email
        // })
        self = this;
        this.getUserInfo();
    },

    methods: {
        ...mapActions({
            reqUserInfoAction: "reqUserInfoAction",
        }),
        // 获取用户信息
        getUserInfo() {
            var json = {
                Authorization: jsCookie.get("token"),
                mid: jsCookie.get("mid"),
            };
            this.$api.usercenter.getUserInfo(json).then((res) => {
                if (res.code == 200) {
                    this.phonenumber = res.data.phone;
                    this.userid = res.data.userid;
                    // this.bak_email = res.data.email;
                    if(res.data.email){
                        this.bak_email = this.encryptedmailbox(res.data.email)
                    }
                } else {
                    this.$message({
                        type: "error",
                        message: res.msg,
                        duration: 2000,
                        onClose: function () {
                            self.$router.push({
                                path: "/login",
                            });
                        },
                    });
                }
            });
        },
        encryptedmailbox(email) {

            if (String(email).indexOf('@') > 0) {

                var str = email.split('@');

                var _s = '';

                var new_email = "";

                if (str[0].length > 3 && str[0].length <= 6) { //@前面多于3位

                    new_email= str[0].substr(0, 1)+ "***" + str[0].substr(str[0].length-2, 2) + _s + '@' + str[1];

                }else if(str[0].length > 6){

                     new_email= str[0].substr(0, 2)+ "***" + str[0].substr(str[0].length-2, 2) + _s + '@' + str[1];
                }else{ //@前面小于等于于3位

                    for(var i = 1;i<str[0].length;i++){

                        _s+='*'

                    }

                    new_email = str[0].substr(0,1)+ _s + '@' + str[1]

                }

            }

            return new_email;

        },
        //修改密码提交
        subUpdatePass(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const json = {
                        newPassword:  md5(this.ruleForm.newpass),
                        newPassword1:  md5(this.ruleForm.checkPass),
                        phoneCode: this.identify_code1,
                    };
                    this.$api.login.modifyPass(json).then((res) => {
                        if (res.success) {

                            this.$message({
                                message: '密码修改成功,3秒后重新登陆',
                                type: 'success',
                                onClose(){
                                    jsCookie.remove("token")
                                    jsCookie.remove("user_id")
                                    jsCookie.remove("refresh_token")
                                    self.$router.push({
                                        path: "/login",
                                    });
                                }
                            });
                            // this.$message({
                            //     type: "success",
                            //     message: "密码修改成功",
                            //     duration: 2000,
                            //     onClose: function () {
                            //         // location.reload()
                            //         self.$api.login
                            //             .outLogin({
                            //                 Authorization:
                            //                     jsCookie.get("token"),
                            //             })
                            //             .then((res) => {
                            //                     console.log(res)
                            //                 if (res.success == "true") {
                            //                     jsCookie.remove("token")
                            //             jsCookie.remove("user_id")
                            //             jsCookie.remove("refresh_token")
                            //                     self.$router.push({
                            //                         path: "/login",
                            //                     });
                            //                 }
                            //             });
                            //     },
                            // });
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    return false;
                }
            });
        },

        //修改绑定手机号
        subUpdatePhone(formName) {
            
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const json = {
                        newPhone: this.ruleForm1.phone,
                        phoneCode: this.identify_code2,
                    };
                    this.$api.login.modifyTel(json).then((res) => {
                        if (res.success) {
                            this.$message({
                                message: '绑定手机号修改成功,3秒后重新登陆',
                                type: 'success',
                                onClose(){
                                    jsCookie.remove("token")
                                    jsCookie.remove("user_id")
                                    jsCookie.remove("refresh_token")
                                    self.$router.push({
                                        path: "/login",
                                    });
                                }
                            });
                            // this.phonenumber = this.ruleForm1.phone;
                            //   self.$api.login
                            //     .outLogin({
                            //         Authorization:
                            //             jsCookie.get("token"),
                            //     })
                            //     .then((res) => {
                            //         console.log(res)
                            //         if (res.success == "true") {
                                      
                            //         }
                            //     });
                        } else {
                            this.$message.error(res.msg);
                        }
                    }).catch(err=>{
                        console.log(err.response)
                        if(err.response.status == 400){
                            this.$message.error(err.response.data.msg)
                        }
                    });
                } else {
                    return false;
                }
            });
        },
        //绑定邮箱
        subUpdateMail(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    const json = {
                        email: this.ruleForm2.email,
                        phoneCode: this.identify_code3,
                    };
                    this.$api.login.modifyEmail(json).then((res) => {
                        if (res.success) {
                            this.$message.success("邮箱修改成功");
                            this.getUserInfo();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                } else {
                    return false;
                }
            });
        },

        showPassForm() {
            this.OneShow = !this.OneShow;
        },
        showPhoneForm() {
            this.TwoShow = !this.TwoShow;
        },
        showMailForm() {
            this.ThreeShow = !this.ThreeShow;
        },

        //点击发送验证码
        sendCode() {
            let phone = this.phonenumber
            if(this.TwoShow){
                phone = this.ruleForm1.phone
            }
            this.$api.login
                .sendPhoneCode({ phone })
                .then((res) => {
                    if (res.code == 200) {
                        this.$message.success("已发送");
                        this.show1 = false;
                        const TIME_COUNT = 60;
                        if (!this.timer1) {
                            this.timecount1 = TIME_COUNT;
                            this.show1 = false;
                            this.timer1 = setInterval(() => {
                                if (
                                    this.timecount1 > 0 &&
                                    this.timecount1 <= TIME_COUNT
                                ) {
                                    this.timecount1--;
                                } else {
                                    this.show1 = true;
                                    clearInterval(this.timer1);
                                    this.timer1 = null;
                                    this.get_Code = "重新发送验证码";
                                }
                            }, 1000);
                        }
                    } else {
                        this.$message.error(res.msg);
                        this.show1 = true;
                        clearInterval(this.timer1);
                    }
                });
        },
    },
};
</script>

<style lang="scss" scoped>
* {
    box-sizing: border-box;
}

.d-flex {
    display: flex;
    justify-content: space-between;
}
span {
    font-size: 14px;
}

.el-form-item {
    width: 550px;
    margin: 40px auto;
}

.border-bottom {
    width: 100%;
    height: 24px;
    border-bottom: 1px dashed rgb(214, 214, 214);
}
.item_bd {
    display: flex;
    justify-content: space-between;
    p:nth-child(2) {
        flex: 1;
        padding-left: 50px;
        font-size: 15px;
    }
}
.PASS {
    width: 100%;
    height: auto;
    padding-bottom: 24px;
    border-bottom: 1px dashed rgb(214, 214, 214);
    .phonePass {
        width: 100%;
        height: auto;
        // margin-top: 60px;

        .inputClear {
            outline: none;
            border: none;
            background: none;
            width: 100%;
            line-height: 38px;
            font-size: 18px;
            color: rgba(255, 255, 255, 0.65);
            border-bottom: 1px solid #6f6f6f;
        }

        .inp_ipone {
            margin-bottom: 50px;
        }

        .inp_code {
            margin-right: 20px;
        }

        input::placeholder {
            font-size: 18px;
            color: rgba(255, 255, 255, 0.65);
            letter-spacing: 1px;
        }

        .btn_code {
            width: 200px;
            height: 40px;
            background: none;
            border: 1px solid #e5e5e5;
            border-radius: 3px;
            text-align: center;
            line-height: 42px;
            color: rgba(255, 255, 255, 0.65);
            outline: none;
            font-size: 16px;

            img {
                width: 100%;
                height: 44px;
            }
        }

        .btn_login {
            width: 100%;
            line-height: 45px;
            font-size: 16px;
            background-color: #0b5ffd;
            color: #ffffff;
            border: none;
            outline: none;
            margin-top: 100px;
            font-weight: 700;
            letter-spacing: 2px;
        }
    }
}

.PHONE {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed rgb(214, 214, 214);
}

.EMAIL {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px dashed rgb(214, 214, 214);
}
</style>


<style lang="scss" scoped>
.el-button--default {
    background-color: #0b5ffd !important;
    color: #ffffff !important;
}
.account-set {
    .el-input__inner {
        width: 100% !important;
        height: 40px !important;
    }
    .d-flex {
        .el-input__inner {
            width: 296px !important;
        }
    }
}
</style>