<template>
<div class="fun_wrapper">
  <h4>产品功能</h4>
  <div class="content">
    <el-image style="width: 100%; height: 1000px" :src="require('../assets/imgs/fun.png')" :fit="'fit'"></el-image>
  </div>
</div>
</template>

<script>
export default {
  name: "fun"
}
</script>

<style scoped lang="sass">
.fun_wrapper
  background: #FFFFFF
  padding: 0 360px 30px
  h4
    text-align: center
    font: 400 34px/163px 'Microsoft YaHei'
</style>