<template>
    <div class="my-message" v-loading="loading">
        <div class="infoTop">
            <userCenterTitle title="待办事项"></userCenterTitle>
            <div class="infoItem-userinfo flex">
                <div class="info-avatar-box">
                    <div class="info-avatar" :style="{ backgroundImage: 'url(' + avatarURL + ')' }">
                        <!-- <div class="info-avatar-update" @click="editAvatar">修改</div> -->
                    </div>
                </div>
                <div class="info-user-box">
                    <div class="info-user-left">
                        <p>
                            <span class="info-user-text">登陆账号：</span>
                            <span class="info-user-value">{{ userInfo.account }}</span>
                        </p>
                        <p style="line-height: 40px">
                            <span class="info-user-text">用户昵称：</span>
                            <span class="info-user-value">
                                <el-input
                                    class="custom-class"
                                    v-model="userInfo.name"
                                    placeholder="请输入您的昵称"
                                ></el-input>
                            </span>
                        </p>
                        <p style="padding-top: 18px; box-sizing: border-box">
                            <span class="info-user-text">账号ID：</span>
                            <span class="info-user-value">{{ userInfo.id }}</span>
                        </p>
                    </div>
                    <div class="info-user-right">
                        <p>
                            <span class="info-user-text">注册时间：</span>
                            <span class="info-user-value">{{ userInfo.createTime }}</span>
                        </p>
                        <p style="line-height: 40px">
                            <span class="info-user-text">用户身份：</span>
                            <span class="info-user-value info-user-identity">
                                <span v-if="userInfo.isSupplier==1">
                                    <span v-if="userInfo.supplierType == 1">商家供应商</span>
                                    <span v-if="userInfo.supplierType == 2">个人供应商</span>
                                </span>
                                <span v-else>普通用户</span>
                            </span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="infoBottom">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px">
                <userCenterTitle title="基本资料"></userCenterTitle>
                <div class></div>
                <el-form-item label="真实姓名">
                    <el-input
                        class="custom-class"
                        v-model="ruleForm.realName"
                        placeholder="请输入您的真实姓名"
                    ></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-radio-group v-model="ruleForm.sex">
                        <el-radio label="1">男</el-radio>
                        <el-radio label="2">女</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item class="custom-class" label="生日" prop="birthday">
                    <el-col :span="11">
                        <el-date-picker
                            type="date"
                            placeholder="请选择日期"
                            v-model="ruleForm.birthday"
                            style="width: 100%"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                        ></el-date-picker>
                    </el-col>
                </el-form-item>
                <userCenterTitle title="业务信息"></userCenterTitle>
                <div class></div>
                <el-form-item label="企业名称" prop="firmName">
                    <el-input
                        class="custom-class"
                        v-model="ruleForm.firmName"
                        placeholder="请输入您的企业名称"
                    ></el-input>
                </el-form-item>
                <el-form-item label="行业" prop="hangyeId">
                    <el-row>
                        <el-col :span="6">
                            <el-select
                                class="custom-double-class"
                                v-model="ruleForm.hangyeId1"
                                placeholder="请选择"
                                @change="selectHangyeOne"
                            >
                                <el-option
                                    v-for="(item, i) in hangYeList"
                                    :key="i"
                                    :label="item.name"
                                    :value="item.id+''"
                                ></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="6">
                            <el-select
                                class="custom-double-class p-l-22"
                                placeholder="请选择"
                                v-model="ruleForm.hangyeId2"
                                @change="selectHangyeTwo"
                            >
                                <el-option
                                    v-for="(item, i) in hangYeListChild"
                                    :key="i"
                                    :label="item.name"
                                    :value="item.id+''"
                                ></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="6"></el-col>
                        <el-col :span="6"></el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="主营业务" prop="business">
                    <el-input class="custom-class" v-model="ruleForm.business"></el-input>
                </el-form-item>
                <el-form-item label="规模" prop="scaleId">
                    <el-select
                        class="custom-class"
                        v-model="ruleForm.scaleId"
                        placeholder="请选择从业人数"
                    >
                        <el-option
                            :label="item.description"
                            :value="item.id"
                            v-for="(item,index) in guimoList"
                            :key="index"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <userCenterTitle title="联系信息"></userCenterTitle>
                <div class></div>
                <el-form-item label="国家/地区" prop="country">
                    <el-input class="custom-class" v-model="country" disabled></el-input>
                </el-form-item>
                <el-form-item label="所在地区">
                    <el-row>
                        <el-col :span="6">
                            <el-select
                                class="custom-double-class"
                                v-model="ruleForm.provinceId"
                                placeholder="请选择"
                                @change="selectProvince"
                            >
                                <el-option
                                    v-for="(item, index) of provinceArr"
                                    :key="index"
                                    :label="item.name"
                                    :value="item.code+''"
                                ></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="6">
                            <el-select
                                class="custom-double-class p-l-22"
                                v-model="ruleForm.cityId"
                                placeholder="请选择"
                                @change="selectCity"
                            >
                                <el-option
                                    v-for="(item, index) of cityArr"
                                    :key="index"
                                    :label="item.name"
                                    :value="item.code+''"
                                ></el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="6"></el-col>
                        <el-col :span="6"></el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="街道地址1" prop="addr">
                    <el-input class="custom-class" v-model="ruleForm.addr"></el-input>
                </el-form-item>
                <el-form-item label="街道地址2" prop="addr2">
                    <el-input class="custom-class" v-model="ruleForm.addr2"></el-input>
                </el-form-item>
                <el-form-item label="联系电话" prop="officephone">
                    <el-input class="custom-class" v-model="ruleForm.officephone"></el-input>
                </el-form-item>
                <el-form-item label="传真" prop="fax">
                    <el-input
                        class="custom-class"
                        v-model="ruleForm.fax"
                        placeholder="例如：0317-8888888"
                    ></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button
                        class="custom-btn"
                        type="primary"
                        :disabled="showSubmit"
                        @click="submitForm('ruleForm')"
                    >保存</el-button>
                </el-form-item>
            </el-form>
        </div>
        <!-- 修改头像 -->
        <el-dialog title="修改头像" :visible.sync="editAvatarDialog" width="40%" :append-to-body="true">
            <el-row type="flex" justify="center">
                <div class="cropper">
                    <vueCropper
                        ref="cropper"
                        :img="option.img"
                        :outputSize="option.size"
                        :outputType="option.outputType"
                        :info="option.info"
                        :full="option.full"
                        :canMove="option.canMove"
                        :canMoveBox="option.canMoveBox"
                        :original="option.original"
                        :autoCrop="option.autoCrop"
                        :autoCropWidth="option.autoCropWidth"
                        :autoCropHeight="option.autoCropHeight"
                        :fixedBox="option.fixedBox"
                        @realTime="realTime"
                    ></vueCropper>
                </div>
                <div class="previewBox">
                    <div :style="previews.div" class="preview">
                        <img :src="previews.url" :style="previews.img" />
                    </div>
                    <el-row type="flex" justify="center">
                        <el-upload
                            action
                            :show-file-list="false"
                            :auto-upload="false"
                            :on-change="uploadImg"
                        >
                            <el-button size="mini" type="primary">更换头像</el-button>
                        </el-upload>
                    </el-row>
                    <br />
                    <el-row>
                        <el-button icon="el-icon-plus" circle size="mini" @click="changeScale(1)"></el-button>
                        <el-button icon="el-icon-minus" circle size="mini" @click="changeScale(-1)"></el-button>
                        <el-button icon="el-icon-download" circle size="mini" @click="down('blob')"></el-button>
                        <el-button
                            icon="el-icon-refresh-left"
                            circle
                            size="mini"
                            @click="rotateLeft"
                        ></el-button>
                        <el-button
                            icon="el-icon-refresh-right"
                            circle
                            size="mini"
                            @click="rotateRight"
                        ></el-button>
                    </el-row>
                </div>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="editAvatarDialog = false">取 消</el-button>
                <el-button type="primary" @click="saveEditAvatar">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Cookies from "js-cookie";
import navBar from "@/components/navBar";
import { province } from "../../static/js/area";
import { VueCropper } from "vue-cropper";
import userCenterTitle from "@/components/user-center-title";
import jsCookie from "js-cookie";
export default {
    data() {
        return {
            mid: 0, //44444461   44444458
            userInfo: {
                userid: "",
            },
            loading: false,
            provinceArr: [],
            provincearr: [],
            cityArr: [],
            showSubmit: false,
            hangYeList: [],
            hangYeListChild: [],
            country: "中国", //省份
            guimoList:[],//规模
            ruleForm: {
                realName: "", //真实姓名
                avatar: "", //头像
                sex: "", //性别
                firmName: "", //企业名称
                provinceId: "", //省份
                cityId: "", //城市id
                fax: "", //传真
                business: "", //主要业务
                hangyeId1: "", //1级行业id
                hangyeId2: "", // 2级行业id
                birthday: "", //生日
                officephone: "", //联系电话
                addr: "", //地址1
                addr2: "", //地址2
                scaleId: "", //规模id
            },
            rules: {
                firmName: [
                    {
                        required: true,
                        message: "请输入企业名称",
                        trigger: "blur",
                    },
                ],
                hangyeId2: [
                    {
                        required: true,
                        message: "请选择您所处行业",
                        trigger: "change",
                    },
                ],
            },
            avatarURL:require("../../assets/image/default_head.png"),
            editAvatarDialog: false,
            previews: {},
            option: {
                img: "", // 裁剪图片的地址
                info: true, // 裁剪框的大小信息
                outputSize: 1, // 剪切后的图片质量（0.1-1）
                full: true, // 输出原图比例截图 props名full
                outputType: "png", // 裁剪生成额图片的格式
                canMove: true, // 能否拖动图片
                original: false, // 上传图片是否显示原始宽高
                canMoveBox: true, // 能否拖动截图框
                autoCrop: true, // 是否默认生成截图框
                autoCropWidth: 150,
                autoCropHeight: 150,
                fixedBox: true, // 截图框固定大小
            },
        };
    },
    components: {
        navBar,
        VueCropper,
        userCenterTitle,
    },
    created() {
        this.provincearr = province;
    },
    methods: {
        // 选择一级行业
        selectHangyeOne(e) {
            let _this = this;
            _this.$api.usercenter.getHangYeList({ id: e }).then((res) => {
                if (res.success) {
                    _this.hangYeListChild = res.data;
                } else {
                    _this.$message.error(res.msg);
                }
            });
        },
        //选择二级行业
        selectHangyeTwo(e) {
        },
        // 选择省
        selectProvince(id) {
            let _this = this;
            _this.$api.usercenter.getAreaList({ code: id }).then((res) => {
                if (res.success) {
                    _this.cityArr = res.data;
                } else {
                    _this.$message.error(res.msg);
                }
            });
        },
        // 选择市
        selectCity(id) {},

        // 回显二级行业
        getHangyeChild(hangyeId1) {
            let _this = this;
            _this.$api.usercenter
                .getHangYeList({ id: hangyeId1 })
                .then((res) => {
                    if (res.success) {
                        _this.hangYeListChild = res.data;
                    } else {
                        _this.$message.error(res.msg);
                    }
                });
        },
        // 回显市区县
        getAreaChild(region1) {
            let _this = this;
            _this.$api.usercenter.getAreaList({ code: region1 }).then((res) => {
                if (res.success) {
                    _this.cityArr = res.data;
                } else {
                    _this.$message.error(res.msg);
                }
            });
        },
        // 获取规模列表
        getGuiMoList() {
            let _this = this;
            _this.$api.usercenter.getGuiMoList().then((res) => {
                if (res.success) {
                    this.guimoList = res.data.records;
                }
            });
        },
        // 修改头像
        editAvatar() {
            this.editAvatarDialog = true;
            this.option.img = this.avatarURL;
        },
        // 保存头像修改
        saveEditAvatar() {
            this.editAvatarDialog = false;
            this.finish("blob");
        },
        // 放大/缩小
        changeScale(num) {
            num = num || 1;
            this.$refs.cropper.changeScale(num);
        },
        // 左旋转
        rotateLeft() {
            this.$refs.cropper.rotateLeft();
        },
        // 右旋转
        rotateRight() {
            this.$refs.cropper.rotateRight();
        },
        // 保存上传图片
        finish(type) {
            if (type === "blob") {
                this.$refs.cropper.getCropBlob((data) => {
                    this.avatarURL = window.URL.createObjectURL(data);
                    //访问接口保存到数据库写这儿!
                    this.$api.usercenter.upLoadAvatar({}).then((res) => {
    
                    });
                });
            } else {
                this.$refs.cropper.getCropData((data) => {
                    //访问接口保存到数据库写这儿!
                    this.$api.usercenter.upLoadAvatar({}).then((res) => {
    
                    });
                });
            }
        },
        // 实时预览函数
        realTime(data) {
            this.previews = data;
        },
        // 下载图片
        down(type) {
            var aLink = document.createElement("a");
            aLink.download = "author-img";
            if (type === "blob") {
                this.$refs.cropper.getCropBlob((data) => {
                    aLink.href = window.URL.createObjectURL(data);
                    aLink.click();
                });
            } else {
                this.$refs.cropper.getCropData((data) => {
                    aLink.href = data;
                    aLink.click();
                });
            }
        },
        // 更换头像--上传本地图片
        uploadImg(file) {
            var _this = this;
            var reader = new FileReader();
            reader.onload = (e) => {
                let data;
                if (typeof e.target.result === "object") {
                    // 把Array Buffer转化为blob 如果是base64不需要
                    data = window.URL.createObjectURL(
                        new Blob([e.target.result])
                    );
                } else {
                    data = e.target.result;
                }
                _this.option.img = data;
            };
            // 转化为base64
            // reader.readAsDataURL(file.raw)
            // 转化为blob
            reader.readAsArrayBuffer(file.raw);
        },
        // 获取行业列表
        getHangYeList() {
            let _this = this;
            _this.$api.usercenter.getHangYeList().then((res) => {
                if (res.success) {

                    _this.hangYeList = res.data;
                    this.getAreaList();
                } else {
                    _this.$message.error(res.msg);
                }
            });
        },
        //获取地区列表
        getAreaList() {
            let _this = this;
            _this.$api.usercenter.getAreaList().then((res) => {
                if (res.success) {
                    _this.provinceArr = res.data;
                    this.getUserInfo();
                } else {
                    _this.$message.error(res.msg);
                }
            });
        },
        // 获取用户信息
        getUserInfo() {
            let _this = this;
            setTimeout(() => {
                _this.loading = false;
            }, 2000);
            _this.$api.usercenter.getUserInfo().then((res) => {
                if (!res.success) {
                    _this.$message.error(res.msg);
                    return;
                }
                _this.userInfo = res.data;

                // 数据回显
                _this.ruleForm = {
                    realName: res.data.realName, //真实姓名
                    avatar: res.data.avatar, //头像
                    sex: res.data.sex.toString(), //性别
                    firmName: res.data.firmName, //企业名称
                    fax: res.data.fax, //传真
                    business: res.data.business, //主营业务
                    hangyeId1: res.data.hangyeId1.toString(), //1级行业id
                    hangyeId2: res.data.hangyeId2.toString(), // 2级行业id
                    birthday: res.data.birthday, //生日
                    officephone: res.data.officephone, //联系电话
                    addr: res.data.addr, //地址1
                    addr2: res.data.addr2, //地址2
                    scaleId: res.data.scaleId.toString(), //规模id
                    provinceId: res.data.provinceId.toString(),
                    cityId: res.data.cityId.toString(),
                };
                // 回显二级行业
                _this.getHangyeChild(res.data.hangyeId1);
                // 回显二级地址
                _this.getAreaChild(res.data.provinceId);
                _this.showSubmit = false;
                _this.loading = false;
            });
        },
        // 提交表单
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let data = this.ruleForm;
                    data.id = this.userInfo.id;
                    // data.id = 1497138644115083266;
                    data.name = this.userInfo.name;
                    this.$api.usercenter.addInfo(data).then((res) => {
                        if (res.code != 200) {
                            this.$message.error(res.msg);
                            return;
                        }
                        this.$message.success(res.msg);
                    });
                } else {
                    return false;
                }
            });
        },
    },
    mounted() {
        this.getHangYeList();
        this.getGuiMoList();
        // let mid = Cookies.get('mid')
        // if(!mid){
        //    this.$message.error("登陆失效");
        //    return
        // }
        // this.mid = mid
    },
};
</script>


<style lang="scss" scoped>
.m-b-50 {
    margin-bottom: 50px;
}
.p-l-20 {
    padding-left: 20px;
}
.p-l-22 {
    padding-left: 22px;
}
.flex {
    display: flex;
}

.infoTop {
    margin-bottom: 20px;
    padding-top: 20px;
    padding-left: 20px;
    padding-bottom: 50px;
    .info-avatar-box {
        position: relative;
        width: 120px;
        height: 120px;
        overflow: hidden;
        .info-avatar {
            width: 120px;
            height: 120px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
            // background: #ccc;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
        }
        .info-avatar-update {
            text-align: center;
            font-size: 12px;
            font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
            font-weight: 300;
            color: #ffffff;
            line-height: 30px;
            width: 120px;
            height: 30px;
            background: rgba(12, 12, 12, 0.7);
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        .info-avatar-update:hover {
            cursor: pointer;
        }
    }
    .infoItem-userinfo {
        padding-left: 12px;
    }
    .info-user-box {
        padding-left: 40px;
        flex: 1;
        display: flex;

        .info-user-left,
        .info-user-right {
            flex: 1;
            p {
                height: 40px;
            }
            .info-user-text {
                font-size: 12px;
                font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
                font-weight: 300;
                color: #0b1d30;
            }
            .info-user-value {
                font-size: 12px;
                font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
                font-weight: 300;
                color: #757f92;
                /deep/ .el-input {
                    width: 180px;
                    .el-input__inner {
                        width: 180px !important;
                    }
                }
            }
            .info-user-identity {
                padding: 2px 5px;
                background: #006fff;
                border-radius: 15px 15px 15px 15px;
                opacity: 1;
                font-size: 12px;
                font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
                font-weight: 300;
                color: #ffffff;
                line-height: 14px;
            }
        }
    }
}
.infoBottom {
    padding-left: 20px;
}
.custom-class /deep/ .el-input__inner {
    width: 430px !important;
}
.custom-double-class /deep/ .el-input__inner {
    width: 200px !important;
}
.custom-btn {
    width: 118px;
    height: 40px;
    background: #006fff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
}

.previewBox {
    text-align: center;
    margin-left: 60px;
}

.preview {
    width: 150px;
    height: 150px;
    margin: 0px auto 20px auto;
    border-radius: 50%;
    border: 1px solid #ccc;
    background-color: #ccc;
    overflow: hidden;
}

.cropper {
    width: 260px;
    height: 260px;
}
.el-dialog .el-dialog__header {
    padding: 20px 20px 10px;
}
</style>