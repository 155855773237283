

import axios from '../http';
import {an,meng,api} from '../global'

// 首页获取推荐政策
export const getPolicyList = (params) => {
    return axios({
        // url: an+ '/blade-policy/policy/recommend',
        url: api+ '/blade-pageControl/moduleControl/homeNewsModule',
        method: 'get',
        params
    })
}

export const getNewsList = (params) => {
    return axios({
        url: api+ '/blade-approczgyy/news/upClass3',
        method: "get",
        params
    })
}


export const getCaseList = (params) => {
    return axios({
        url: api+ '/blade-caseCenter/caseDetail/selectedList',
        method: "get",
        params
    })
}
