<template>
    <div class="lightapplication">
        <div
            class="top-banner"
            :style="{'backgroundImage':'url('+banner+')'}"
            
        >
            <!-- <img :src="banner" alt /> -->
            <div class="module-center">
                <div class="banner-title">轻应用开发平台</div>
                <div
                    class="banner-detail"
                >提供多种开发工具，实现低成本、高便捷的应用搭建方式，适应场景广泛，高效稳定，快速交付 安全性高。</div>
                <div class="banner-btn" @click="urlToNewWindow('http://27.128.171.75:8081/login.html')">立即体验</div>
            </div>
        </div>
        <div class="app-module module-one">
            <div class="module-center">
                <div class="module-top">
                    <div class="module-title">轻应用开发平台</div>
                </div>
                <div class="module-content">
                    <div class="one-left">
                        <div class="one-left-title">轻应用开发平台</div>
                        <div
                            class="one-left-desc"
                        >轻应用开发平台，是针对工业场景提供的生产力工具，面向广大工业互联网领域开发者，提供强大的可视化组态设计器与专业的线上IDE开发环境。借助平台，用户可根据不同需求， 在可视化，低代码编程与专业IDE方式切换，快速创建，部署， 发布一个完整的应用，解决生产过程中的单点问题。</div>
                    </div>
                    <div class="one-right">
                        <div class="one-right-top">
                            <div class="one-left-title">数据可视化应用开发</div>
                        </div>
                        <div class="one-right-bot">
                            <div class="one-right-bot-left">
                                <div class="one-left-title">DevOps项目管理</div>
                            </div>
                            <div class="one-right-bot-right">
                                <div class="one-left-title">线上IDE</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-module module-two">
            <div class="module-center">
                <div class="module-top">
                    <div class="module-title">产品能力与服务</div>
                    <div class="module-desc">快速、灵活创造个性化系统，轻松实现多元业务场景数字化管理</div>
                </div>
                <div class="module-content">
                    <div class="two-left"></div>
                    <div class="two-right">
                        <ul>
                            <li>
                                <div class="two-icon">
                                    <img
                                        class="two-icon-one"
                                        src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_6_1.png"
                                        alt
                                    />
                                    <img
                                        class="two-icon-two"
                                        src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_6.png"
                                        alt
                                    />
                                </div>
                                <div class="two-text">
                                    <div class="two-title">数据可视化应用开发</div>
                                    <div
                                        class="two-desc"
                                    >云端拖拽式开发环境，所见即所得。预设多种数据组件和工业组件帮助用户快速实现可视化应用开发。</div>
                                </div>
                            </li>
                            <li>
                                <div class="two-icon">
                                    <img
                                        class="two-icon-one"
                                        src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_7_1.png"
                                        alt
                                    />
                                    <img
                                        class="two-icon-two"
                                        src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_7.png"
                                        alt
                                    />
                                </div>
                                <div class="two-text">
                                    <div class="two-title">DevOps 项目管理</div>
                                    <div
                                        class="two-desc"
                                    >基于主流的敏捷产品研发模式和DevOps方法，为开发团队提供“平台+人+流程的项目管理功能，帮助团队更好更快完成产品交付与发布</div>
                                </div>
                            </li>
                            <li>
                                <div class="two-icon">
                                    <img
                                        class="two-icon-one"
                                        src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_8_1.png"
                                        alt
                                    />
                                    <img
                                        class="two-icon-two"
                                        src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_8.png"
                                        alt
                                    />
                                </div>
                                <div class="two-text">
                                    <div class="two-title">线上IDE</div>
                                    <div class="two-desc">提供强大的云端编码环境，实时保存，告别繁琐的开发环境准备工作，快速在线开发</div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-module module-three">
            <div class="module-center">
                <div class="module-top">
                    <div class="module-title">产品能力与服务</div>
                </div>
                <div class="module-content">
                    <div class="flex three-item">
                        <div class="three-item-left three-item-text">
                            <img
                                class="three-item-icon"
                                src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_icon_01.png"
                                alt
                            />
                            <div class="three-item-title">丰富的组件与 API 接口</div>
                            <div
                                class="three-item-desc"
                            >无论是组态设计应用，还是线上开发应用，都提供了数量众多的组件和丰富的 API 接口，组件库随着平台的迭代升级会保持不断更新，大大提高开发效率</div>
                        </div>
                        <div class="three-item-right">
                            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_10.png" alt />
                        </div>
                    </div>
                    <div class="flex three-item">
                        <div class="three-item-left">
                            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_11.png" alt />
                        </div>
                        <div class="three-item-right three-item-text">
                            <img
                                class="three-item-icon"
                                src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_icon_02.png"
                                alt
                            />
                            <div class="three-item-title">可视化应用开发</div>
                            <div
                                class="three-item-desc"
                            >通过拖拽，配置的方式，即可完成设备数据监控相关的组态应用开发，开发者只需关注核心业务，无需关注传统开发中的种种繁琐细节，大大降低开发难度</div>
                        </div>
                    </div>
                    <div class="flex three-item">
                        <div class="three-item-left three-item-text">
                            <img
                                class="three-item-icon"
                                src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_icon_03.png"
                                alt
                            />
                            <div class="three-item-title">线上 IDE</div>
                            <div
                                class="three-item-desc"
                            >拥有不输于本地IDE的完整功能，并提供强大的云端特性：预设多语言环境，实时保存，快速部署，代码不落地等；告别繁琐的开发环境准备工作，可快速切换各种预置的开发环境。</div>
                        </div>
                        <div class="three-item-right">
                            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_12.png" alt />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="app-module module-four">
            <div class="module-center">
                <div class="module-top">
                    <div class="module-title">应用场景</div>
                    <div
                        class="module-desc"
                    >无论是组态设计应用，还是线上开发应用，都提供了数量众多的组件和丰富的API接口，组件库随着平台的迭代升级会保持不断更新，大大提高开发效率。 通过对作业、投产、备料等环节建模，最优资源调度以应对复杂的订单计划</div>
                </div>
                <div class="module-content">
                    <div class="my-swiper">
                        <swiper :options="swiperOptions" :navigation="true">
                            <swiper-slide v-for="(item,index) in imgs" :key="index">
                                <img :src="item.bgImg" alt />
                                <div class="content">
                                    <div>
                                        <img :src="item.iconImg" alt />
                                        <span></span>
                                    </div>
                                    <p>{{item.title}}</p>
                                </div>
                                <div class="bg"></div>
                            </swiper-slide>
                            <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                        </swiper>
                        <div class="swiper-button-prev"></div>
                        <!--左箭头。如果放置在swiper外面，需要自定义样式。-->
                        <div class="swiper-button-next"></div>
                        <!--右箭头。如果放置在swiper外面，需要自定义样式。-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
    data() {
        return {
            banner: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_1.jpg",
            twoIndex: 0,
            imgs: [
                {
                    bgImg:"https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/car.png",
                    title: "汽车制造",
                    iconImg:"https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/car.png",
                },
                {
                    bgImg:"https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/huagong.png",
                    title: "绿色化工",
                    iconImg:"https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/huagong.png",
                },
                {
                    bgImg:"https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/guandao.png",
                    title: "管道装备",
                    iconImg:"https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/guandao.png",
                },
                {
                    bgImg:"https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/fuzhuang.png",
                    title: "服装服饰",
                    iconImg:"https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/fuzhuang.png",
                },
                {
                    bgImg:"https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/qingjie.png",
                    title: "清洁能源",
                    iconImg:"https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/qingjie.png",
                },
            ],
            swiperOptions: {
                loop: true,
                slidesPerView: 4,
                autoplay: {
                    delay: 5000,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            },
        };
    },
    comments: {
        Swiper,
        SwiperSlide,
    },
    methods: {
        urlToNewWindow(url) {
            window.open(url, "_blank");
        },
    },
    mounted() {},
};
</script>

<style scoped lang="scss">
.lightapplication {
    width: 100%;
    .hover-content {
        cursor: pointer;
    }
    .flex {
        display: flex;
    }
    div {
        box-sizing: border-box;
    }
    .module-center {
        width: 1180px;
        margin: 0 auto;
    }
    .top-banner {
        width: 100%;
        height: 540px;
        cursor: pointer;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        padding-top: 100px;
        .banner-title {
            font-size: 42px;
            font-weight: bold;
            color: #000000;
            line-height: 49px;
            margin-bottom: 20px;
        }
        .banner-detail {
            width: 603px;
            font-size: 20px;
            font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
            font-weight: 400;
            color: #757f92;
            line-height: 35px;
            margin-bottom: 40px;
        }
        .banner-btn {
            width: 140px;
            height: 40px;
            background: #1f51c8;
            border-radius: 0px 0px 0px 0px;
            opacity: 1;
            font-size: 14px;
            color: #fff;
            line-height: 40px;
            text-align: center;
            cursor: pointer;
        }
    }
    .module-top {
        .module-title {
            font-size: 34px;
            font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
            font-weight: 400;
            color: #0b1d30;
            line-height: 40px;
            letter-spacing: 3px;
        }
        .module-desc {
            font-size: 16px;
            font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
            font-weight: 300;
            color: #757f92;
            line-height: 19px;
            margin-top: 30px;
        }
    }
    .module-one {
        background-color: #f6f8fb;
        padding-bottom: 175px;
        .module-top {
            padding: 115px 0 20px;
        }
        .module-content {
            display: flex;
            justify-content: space-between;
            .one-left {
                width: 682px;
                background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/pic_10.jpg") no-repeat;
                background-position: center;
                background-size: cover;
                height: 416px;
                padding: 208px 88px 60px 60px;
                .one-left-title {
                    font-size: 20px;
                    font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 26px;
                    margin-bottom: 20px;
                }
                .one-left-desc {
                    font-size: 16px;
                    font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 26px;
                }
            }
            .one-right {
                width: 478px;
                .one-left-title {
                    color: #fff;
                    text-align: center;
                    font-size: 18px;
                    font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                    font-weight: 400;
                    line-height: 26px;
                }
                .one-right-top {
                    width: 100%;
                    height: 196px;
                    background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_2.png")
                        no-repeat;
                    background-position: center;
                    background-size: cover;
                    margin-bottom: 20px;
                    transition: all 0.3s;
                    padding-top: 140px;
                }
                .one-right-top:hover {
                    background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_2_1.png")
                        no-repeat;
                }
                .one-right-bot {
                    display: flex;
                    justify-content: space-between;
                    .one-right-bot-left {
                        padding-top: 140px;
                        transition: all 0.3s;
                        width: 229px;
                        height: 200px;
                        background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_4.png")
                            no-repeat;
                        background-position: center;
                        background-size: cover;
                    }
                    .one-right-bot-left:hover {
                        background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_4_1.png")
                            no-repeat;
                    }
                    .one-right-bot-right {
                        padding-top: 140px;
                        transition: all 0.3s;
                        width: 229px;
                        height: 200px;
                        background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_5.png")
                            no-repeat;
                        background-position: center;
                        background-size: cover;
                    }
                    .one-right-bot-right:hover {
                        background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_5_1.png")
                            no-repeat;
                    }
                }
            }
        }
    }
    .module-two {
        padding-bottom: 90px;
        .module-top {
            padding: 90px 0 60px;
        }
        .module-content {
            display: flex;
            justify-content: space-between;
            .two-left {
                width: 446px;
                height: 540px;
                background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_9.png") no-repeat;
                background-position: center;
                background-size: cover;
            }
            .two-right {
                width: 704px;
                ul {
                    li {
                        border: 1px solid transparent;
                        box-sizing: border-box;
                        height: 160px;
                        margin-bottom: 30px;
                        position: relative;
                        padding: 30px;
                        padding-left: 155px;
                        transition: all 0.3s;
                        .two-icon {
                            position: absolute;
                            top: 45px;
                            left: 50px;
                            width: 70px;
                            height: 70px;
                            .two-icon-one {
                                transition: all 0.3s;
                                display: none;
                            }
                            .two-icon-two {
                                transition: all 0.3s;
                                display: block;
                            }
                            img {
                                width: 100%;
                            }
                        }
                        .two-text {
                            .two-title {
                                font-size: 22px;
                                font-family: Microsoft YaHei UI-Light,
                                    Microsoft YaHei UI;
                                font-weight: 300;
                                color: #0b1d30;
                                line-height: 26px;
                                margin-bottom: 15px;
                            }
                            .two-desc {
                                font-size: 16px;
                                font-family: Microsoft YaHei UI-Light,
                                    Microsoft YaHei UI;
                                font-weight: 300;
                                color: #757f92;
                                line-height: 28px;
                            }
                        }
                    }
                    li:hover {
                        border: 1px solid #2e5bff;
                        .two-icon {
                            .two-icon-one {
                                display: block;
                            }
                            .two-icon-two {
                                display: none;
                            }
                            img {
                                width: 100%;
                            }
                        }
                    }
                    li:last-child {
                        margin: 0;
                    }
                }
            }
        }
    }
    .module-three {
        background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/app_bg_01.png") no-repeat;
        background-position: center;
        background-size: cover;
        padding-bottom: 80px;
        .module-top {
            padding: 60px 0;
            .module-title {
                color: #fff;
            }
        }
        .module-content {
            .three-item {
                background: #fff;
                margin-bottom: 30px;
                .three-item-left {
                    width: 590px;
                    height: 380px;
                }
                .three-item-right {
                    width: 590px;
                    height: 380px;
                }
                .three-item-text {
                    padding: 50px 50px 0 50px;
                    .three-item-icon {
                        display: block;
                        width: 40px;
                        height: 40px;
                        margin-bottom: 20px;
                    }
                    .three-item-title {
                        font-size: 22px;
                        font-family: Microsoft YaHei UI-Regular,
                            Microsoft YaHei UI;
                        font-weight: 400;
                        color: #0b1d30;
                        line-height: 26px;
                        padding-bottom: 20px;
                        position: relative;
                        margin-bottom: 20px;
                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            background: #e5e5e5;
                            width: 100%;
                            height: 1px;
                            transform: scaleY(0.5);
                        }
                    }
                    .three-item-desc {
                        font-size: 16px;
                        font-family: Microsoft YaHei UI-Light,
                            Microsoft YaHei UI;
                        font-weight: 300;
                        color: #757f92;
                        line-height: 28px;
                    }
                }
            }
        }
    }
    .module-four {
        padding-bottom: 60px;
        .module-top {
            padding: 90px 0 60px;
        }
        .module-content {
            margin-top: 60px;
            .my-swiper {
                width: 1200px;
                height: 530px;
                // overflow: hidden;
                margin: 0 auto;
                position: relative;
                background-color: #eeeeee;
                img {
                    width: 300px;
                    height: auto;
                }
                .swiper-slide {
                    cursor: pointer;
                    > div.content {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        z-index: 9;
                        div {
                            position: relative;
                            width: 100px;
                            height: 100px;
                            margin-bottom: 20px;
                            text-align: center;
                            img {
                                width: 80px;
                                height: 80px;
                            }
                            span {
                                position: absolute;
                                bottom: 0;
                                left: 50%;
                                margin-left: -8px;
                                width: 16px;
                                height: 2px;
                                background-color: #ffffff;
                            }
                        }
                        p {
                            font-size: 24px;
                            color: #ffffff;
                        }
                    }
                    .bg {
                        width: 300px;
                        height: 530px;
                        background-color: rgba(40, 40, 40, 0.7);
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                .swiper-slide:hover {
                    .bg {
                        background-color: rgba(5, 62, 245, 0.8);
                    }
                }
                .swiper-button-prev {
                    position: absolute;
                    left: -50px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                .swiper-button-next {
                    position: absolute;
                    right: -50px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }
}
</style>