<template>
    <div class="com-new-details">
        <!-- <nav-bar></nav-bar> -->
        <div class="news-detail">
            <div class="news-detail-center">
                <div class="crumb">
                    <el-breadcrumb separator=">">
                        <el-breadcrumb-item>首页</el-breadcrumb-item>
                        <el-breadcrumb-item><router-link :to="{path: '/companyNews'}">新闻资讯</router-link></el-breadcrumb-item>
                        <el-breadcrumb-item><router-link :to="{path: '/companyNewsList', query: {classId: $route.query.classId}}">{{checkClassName}}</router-link></el-breadcrumb-item>
                        <el-breadcrumb-item :class="$route.query.newsClass == 1? '': 'gov'">{{newsDetails.newsName}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="news-detail-content">
                    <h4>{{newsDetails.newsName}}</h4>
                    <p>{{newsDetails.createTime}} &nbsp;&nbsp;&nbsp;来源：{{newsDetails.origin?newsDetails.origin: '网络'}}</p>
                    <div v-html="newsDetails.content"></div>
                </div>
            </div>
            <div class="article">
                <a @click="RouteJumpPrev(prevNews.newsId)">上一篇：{{prevNews.newsName}}</a>
                <a @click="RouteJumpNext(nextNews.newsId)">下一篇：{{nextNews.newsName}}</a>
                <!-- <template v-if="prevNews.newsId">
                    <router-link :to="{path: '/companyNewsDetail', query:{classId: $route.query.classId, id: prevNews.newsId}}">上一篇：{{prevNews.newsName}}</router-link>
                </template>

                <template v-else>
                    <p>上一篇：没有上一篇了</p>
                </template>
                
                <template v-if="nextNews.newsId">
                    <router-link :to="{path: '/companyNewsDetail', query:{classId: $route.query.classId, id: nextNews.newsId}}">下一篇：{{nextNews.newsName}}</router-link>
                </template>

                <template v-else>
                    <p>下一篇：没有下一篇了</p>
                </template> -->
            </div>
        </div>
    </div>
</template>

<script>
// import navBar from '@/components/navBar'
export default {
    data() {
        return{
            newsDetails: '',
            checkClassName: '',
            newsClass: [],
            prevNews: {
                newsName: '',
                newsId: ''
            },
            nextNews: {
                newsName: '',
                newsId: ''
            }
        }
    },
    components: {
       // navBar
    },
    methods: {
        getNewsDetail() {
            var json = {
                id: this.$route.query.id
            } 
            this.$api.news.newsDetails(json).then(res => {
                if(res.code == 200){
                    this.newsDetails = res.data
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        // 查询新闻分类
        getCompanyNewClass() {
            this.$api.news.newsClassList({}).then(res => {
                if(res.code == 200){
                    this.newsClass = res.data.records
                    this.getCheckClassName()
                }
            })
        },
        // 获取当前分类名称
        getCheckClassName() {
            try{
                this.newsClass.map((v, k) => {
                    if(this.$route.query.classId == v.id){
                        this.checkClassName = v.className
                        throw '';
                    }
                })
            } catch (e){}
        },
        // 查询上一篇文章
        getCompanyNewsPrev() {
            var json = {
                sign: 1,
                classId: this.$route.query.classId,
                id: this.$route.query.id
            }
            this.$api.news.companyNewsPrevNext(json).then(res => {
                if(res.code == 200){
                    this.prevNews = res.data
                }else{
                    //this.$message.error(res.msg)
                    this.prevNews.newsName = res.msg
                }
            })
        },

        // 查询下一篇文章
        getCompanyNewsNext() {
            var json = {
                sign: 2,
                classId: this.$route.query.classId,
                id: this.$route.query.id
            }
            this.$api.news.companyNewsPrevNext(json).then(res => {
                if(res.code == 200){
                    this.nextNews = res.data
                }else{ 
                    //this.$message.error(res.msg)
                    this.nextNews.newsName = res.msg
                }
            })
        },
        // 跳转上一篇
        RouteJumpPrev(id) {
            if(id != ''){
                this.$router.push({
                    path:'/companyNewsDetail',
                    query: {
                        classId: this.$route.query.classId,
                        id: id, 
                    }
                })
                this.getNewsDetail(id)
                this.getCompanyNewsPrev()
                this.getCompanyNewsNext()
            }
        },
        // 跳转下一篇
        RouteJumpNext(id) {
            if(id != ''){
                this.$router.push({
                    path:'/companyNewsDetail',
                    query: {
                        classId: this.$route.query.classId,
                        id: id, 
                    }
                })
                this.getNewsDetail(id)
                this.getCompanyNewsPrev()
                this.getCompanyNewsNext()
            }
        },
    }, 
    mounted() {
        this.getCompanyNewClass()
        this.getNewsDetail()
        this.getCheckClassName()
        this.getCompanyNewsPrev()
        this.getCompanyNewsNext()
    },
}
</script>

<style lang="scss">
.com-new-details{
    .crumb{
        .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
            font-weight: 500;
            color: #0B1D30;
        }
        .el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
            color: #006fff;
        }
    }
}
</style>

<style lang="scss" scoped>
.com-new-details{
    .news-detail{
        width: 100%;
        background-color: #eef0f5;
        .news-detail-center{
            width: 1200px;
            margin: 0 auto;
            padding-bottom: 40px;
            .crumb{
                padding: 30px 0;
            }
            .news-detail-content{
                background-color: #ffffff;
                padding: 80px;
                >h4{
                    font-size: 24px;
                }
                >h4, >p{
                    text-align: center;
                    margin-bottom: 10px;
                }
                >div{
                    font-size: 14px;
                    line-height: 28px;
                }
            }
        }
    }
    .article{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-bottom: 60px;
        >a{
            width: 500px;
            height: 21px;
            overflow: hidden;
        }
    }
}
</style>
