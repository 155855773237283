<template>
<div class="scene_wrapper">
  <h4>应用场景</h4>
  <div class="content">
    <el-row class="cards" :gutter="20">
      <el-col :span="8" v-for="(item,index) in cardList" :key="'card'+ index">
        <el-card class="box-card" shadow="always">
          <el-image style="width: 100%; height: 200px" :src="item.imgUrl" :fit="'fit'"></el-image>
        </el-card>
      </el-col>
    </el-row>
  </div>
</div>
</template>

<script>
export default {
  name: "scene",
  data() {
    return{
      cardList:[
        {imgUrl: require('../assets/imgs/scene1.png')},
        {imgUrl: require('../assets/imgs/scene2.png')},
        {imgUrl: require('../assets/imgs/scene3.png')}
      ]
    }
  }
}
</script>

<style scoped lang="sass">
.scene_wrapper
  background: #FFFFFF
  h4
    text-align: center
    font: 400 34px/163px 'Microsoft YaHei'
  .content
    margin: 30px 360px
</style>