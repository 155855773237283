import axios from '../http';
import {an,meng, api} from '../global'
/**
 * author  LLLL
 * date    2022-01-19
 * 
 */


//获取用户信息
export const getUserInfo = (params) => {
    return axios({
        url: api + '/blade-personalCenter/userDetail/info',
        method: 'get',
        params
    })
}

//添加或修改用户信息
export const addInfo = (data) => {
    return axios({
        url: api + '/blade-personalCenter/userDetail/updateUserInfo',
        method: 'post',
        data
    })
}

//头像保存
export const upLoadAvatar = (params) => {
    return axios({
        url: api + '/registerlogin/user/addInfo',
        method: 'post',
        params
    })
}

//获取行业列表
export const getHangYeList = (params) => {
    return axios({
        url: api + '/blade-system/hangye/select',
        method: 'get',
        params
    })
}

// 获取规模列表

export const getGuiMoList = (params) => {
    return axios({
        url: api + '/blade-personalCenter/scale/list',
        method: 'get',
        params
    })
}

//获取地区列表
export const getAreaList = (params) => {
    return axios({
        url: api + '/blade-system/region/select',
        method: 'get',
        params
    })
}

//获取下一级地区列表
export const getAreaNextList = (params) => {
    return axios({
        url: api + '/blade-system/region/select',
        method: 'get',
        params
    })
}

//获取我的待办
export const getMyToDoList = (params) => {
    return axios({
        url: api + '/blade-personalCenter/czgyySystems/backlog',
        method: 'get',
        params
    })
}

// 获取控制台应用

export const getMyApplication = (params) => {
    return axios({
        url: api + '/blade-personalCenter/console/totalConsole',
        method: 'get',
        params
    })
}

// 单点登录
export const getMyAppLoginUrl = (params) => {
    return axios({
        url: api + '/blade-personalCenter/console/ssoParams1',
        method: 'get',
        params
    })
}


//获取我的服务列表
export const getMyServerList = (params) => {
    return axios({
        url: api + '/blade-personalCenter/console/listFuzzy',
        method: 'get',
        data: params
    })
}

//获取应用服务列表
export const getServersList = (params) => {
    return axios({
        url: api + '/blade-personalCenter/czgyySystems/list',
        method: 'get',
        data: params
    })
}

// 获取收货列表
export const getAdressList = (params) => {
    return axios({
        url: api + '/blade-personalCenter/userAddr/page',
        method: 'get',
        params
    })
}

// 新增地址
export const getAdressAdd = (data) => {
    return axios({
        url: api + '/blade-personalCenter/userAddr/save',
        method: 'post',
        data
    })
}

// 修改地址
export const getAdressModify = (data) => {
    return axios({
        url: api + '/blade-personalCenter/userAddr/update',
        method: 'post',
        data
    })
}

// 删除地址
export const getAdressDel = (params) => {
    return axios({
        url: api + '/blade-personalCenter/userAddr/remove',
        method: 'post',
        params
    })
}

// 查询某条地址信息
export const getOneAdress = (params) => {
    return axios({
        url: api + '/blade-personalCenter/userAddr/detail',
        method: 'get',
        params
    })
}

// 协作管理列表
export const getCooperList = (params) => {
    return axios({
        url: api + '/blade-personalCenter/consoleUser/consolelist',
        method: 'get',
        params
    })
}

// 添加协作
export const addCooperation = (params) => {
    return axios({
        url: api + '/blade-personalCenter/consoleUser/add',
        method: 'post',
        data: params
    })
}

// 修改协作
export const editCooperation = (params) => {
    return axios({
        url: api + '/blade-personalCenter/consoleUser/change',
        method: 'post',
        data: params
    })
}


// 删除协作
export const removeCooperation = (params) => {
    return axios({
        url: api + '/blade-personalCenter/consoleUser/remove',
        method: 'post',
        params
    })
}

// 协作详情
export const infoCooperation = (data) => {
    return axios({
        url: api + '/blade-personalCenter/consoleUser/info',
        method: 'get',
        data
    })
}

// 更改用户状态 switch
export const changeUserStatus = (params) => {
    return axios({
        url: api + '',
        method: 'get',
        data: params
    })
}

// 获取用户投标列表
export const getUserTenderee = (params) => {
    return axios({
        url: api + '/registerlogin/tendereeFeign/myTenderee',
        method: 'post',
        data: params
    })
}

// 修改投标
export const editUserTenderee = (params) => {
    return axios({
        url: api + '/registerlogin/tendereeFeign/myTendereeEdit',
        method: 'put',
        data: params
    })
}

// 投标详情
export const infoUserTenderee = (params) => {
    return axios({
        url: api + '',
        method: 'get',
        data: params
    })
}

// 删除投标
export const deleteUserTenderee = (params) => {
    return axios({
        url: api + '',
        method: 'delete',
        data: params
    })
}


/**
 * 订单列表相关接口 - start
 * 
 * */

// 获取用户订单列表
export const getUserOrderList = (params) => {
    return axios({
        url: api + '/blade-personalCenter/order/list',
        method: 'get',
        params
    })
}
export const getOrderDetail = (params) => {
    return axios({
        url: api + '/blade-personalCenter/order/getInfo',
        method: 'get',
        params
    })
}
// 删除用户订单
export const delUserOrder = (params) => {
    return axios({
        url: api + '/blade-personalCenter/order/remove',
        method: 'delete',
        data: params
    })
}
// 取消用户订单
export const cancelUserOrder = (params) => {
    return axios({
        url: api + '/blade-personalCenter/order/giveUpPay',
        method: 'get',
       params
    })
}

/**
 * 订单列表相关接口 - end
 * 
 * */







/**
 * 供需管理相关接口 - start
 * 
 * */

// 获取用户招投标列表
export const getMyBid = (params) => {
    return axios({
        url: api + '/czgyy-tenderee/apitenderee/myTendereePurchaseBid',
        method: 'get',
        params
    })
}


// 个人中心  招标项目分类
export const getSelectTendereeTypeList = (params) => {
    return axios({
        url: api + '/czgyy-tenderee/tenderee/web/data/selectTendereeTypeList',
        method: "get",
        params
    })
}
// 个人中心 我的供需
export const getMyCollect = (params) => {
    return axios({
        url: api + '/czgyy-tenderee/apitenderee/collectionList',
        method: "get",
        params
    })
}

// 个人中心  新增收藏
export const addCollect = (params) => {
    return axios({
        url: api + '/czgyy-tenderee/apitenderee/collection',
        method: "get",
        params
    })
}
// 个人中心  取消收藏
export const cancelCollect = (params) => {
    return axios({
        url: api + '/czgyy-tenderee/apitenderee/collection',
        method: "get",
        params
    })
}
/**
 * 供需管理相关接口 - end
 * 
 * */




