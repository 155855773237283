<template>
    <!-- 快速采购 -->
    <div class="expeditPurchase">
        <industrial-nav-bar></industrial-nav-bar>
        
        <div class="indust_content">
            <div class="indust-content-center">
                <div class="one">
                    <ul class="clearfix">
                        <li>
                            <h6>STEP <b>01</b></h6>
                            <div class="clearfix">
                                <p>采购商发布快速求购</p>
                                <img src="" alt="">
                            </div>
                            <span></span>
                        </li>
                        <li>
                            <h6>STEP <b>02</b></h6>
                            <div class="clearfix">
                                <p>供应商报价</p>
                                <img src="" alt="">
                            </div>
                            <span></span>
                        </li>
                        <li>
                            <h6>STEP <b>03</b></h6>
                            <div class="clearfix">
                                <p>采购商比价并下单</p>
                                <img src="" alt="">
                            </div>
                            <span></span>
                        </li>
                        <li>
                            <h6>STEP <b>04</b></h6>
                            <div class="clearfix">
                                <p>生成订单</p>
                                <img src="" alt="">
                            </div>
                            <span></span>
                        </li>
                        <li>
                            <h6>STEP <b>05</b></h6>
                            <div class="clearfix">
                                <p>采购商支付</p>
                                <img src="" alt="">
                            </div>
                            <span></span>
                        </li>
                        <li>
                            <h6>STEP <b>06</b></h6>
                            <div class="clearfix">
                                <p>供应商发货</p>
                                <img src="" alt="">
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="two">
                    <ul class="clearfix">
                        <li>
                            <el-input v-model="input" placeholder="请输入物料名称"></el-input>
                        <li>
                            <el-select v-model="value" placeholder="请选择">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </li>
                        <li>
                            <el-select v-model="value" placeholder="请选择">
                                <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                                </el-option>
                            </el-select>
                        </li>
                        <li>
                            <el-input v-model="input" placeholder="请输入项目名称"></el-input>
                        </li>
                        <li>
                            <el-input v-model="input" placeholder="请输入采购方"></el-input>
                        </li>
                        <li>
                            <div class="block">
                                <span>发布时间 </span>
                                <el-date-picker
                                v-model="value1"
                                type="daterange"
                                range-separator="至"
                                start-placeholder="开始日期"
                                end-placeholder="结束日期">
                                </el-date-picker>
                                <el-button type="primary" @click="submitForm('ruleForm')">搜索</el-button>
                                <el-button @click="resetForm('ruleForm')">重置</el-button>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="three">
                    <div class="top">
                        <ul class="clearfix">
                            <li>项目信息</li>
                            <li>物料名称</li>
                            <li>采购数量</li>
                            <li>参与供应商数</li>
                            <li>物质状态</li>
                        </ul>
                    </div>
                    <div class="bottom">
                        <ul>
                            <li class="clearfix">
                                <div class="left">
                                    <h5>吕南大店中孚天然气开发利用有限公司...</h5>
                                    <h6>报价剩余时间<span>1</span>天<span>1</span>小时<span>1</span>分</h6>
                                    <p>采购方：<span>吕南大店中孚天然气开发利用有限公司</span></p>
                                    <p>发布时间：<span>2021-11-23 11:28:30</span></p>
                                </div>
                                <div class="right">
                                    <ol class="clearfix">
                                        <li>便携式燃气检测仪器_LEL/O2/CO/H25</li>
                                        <li>1台</li>
                                        <li>1</li>
                                        <li><span></span> 报价中</li>
                                    </ol>
                                </div>
                            </li>
                            <li class="clearfix">
                                <div class="left">
                                    <h5>吕南大店中孚天然气开发利用有限公司...</h5>
                                    <h6>报价剩余时间<span>1</span>天<span>1</span>小时<span>1</span>分</h6>
                                    <p>采购方：<span>吕南大店中孚天然气开发利用有限公司</span></p>
                                    <p>发布时间：<span>2021-11-23 11:28:30</span></p>
                                </div>
                                <div class="right">
                                    <ol class="clearfix">
                                        <li>便携式燃气检测仪器_LEL/O2/CO/H25</li>
                                        <li>1台</li>
                                        <li>1</li>
                                        <li><span></span> 报价中</li>
                                    </ol>
                                </div>
                            </li>
                            <li class="clearfix">
                                <div class="left">
                                    <h5>吕南大店中孚天然气开发利用有限公司...</h5>
                                    <h6>报价剩余时间<span>1</span>天<span>1</span>小时<span>1</span>分</h6>
                                    <p>采购方：<span>吕南大店中孚天然气开发利用有限公司</span></p>
                                    <p>发布时间：<span>2021-11-23 11:28:30</span></p>
                                </div>
                                <div class="right">
                                    <ol class="clearfix">
                                        <li>便携式燃气检测仪器_LEL/O2/CO/H25</li>
                                        <li>1台</li>
                                        <li>1</li>
                                        <li><span></span> 报价中</li>
                                    </ol>
                                    <ol class="clearfix">
                                        <li>便携式燃气检测仪器_LEL/O2/CO/H25</li>
                                        <li>1台</li>
                                        <li>1</li>
                                        <li><span></span> 报价中</li>
                                    </ol>
                                    <ol class="clearfix">
                                        <li>便携式燃气检测仪器_LEL/O2/CO/H25</li>
                                        <li>1台</li>
                                        <li>1</li>
                                        <li><span></span> 报价中</li>
                                    </ol>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import industrialNavBar from '../../components/industrial-nav-bar'
export default {
    data() {
        return{
            input: '',
            value: '',
            options: [],
            value1: ''
        }
    },
    components: {
        industrialNavBar
    }
}
</script>


<style lang="scss" scoped>
.expeditPurchase{
    .indust_content{
        background-color: #f5f7fa;
        padding-top: 10px;
        .indust-content-center{
            width: 1200px;
            margin: 0 auto;
            padding-bottom: 80px;
            .one{
                background-color: #3e91ff;
                ul{
                    li{
                        width: 166px;
                        height: 100px;
                        float: left;
                        border-right: 1px solid rgba(255,255,255,0.2);
                        position: relative;
                        box-sizing: border-box;
                        padding-left: 25px;
                        h6{
                            font-size: 12px;
                            color: rgba(255,255,255,0.7);
                            font-weight: normal;
                            text-align: left;
                            line-height: 40px;
                            margin-top: 10px;
                            b{
                                color: rgba(255,255,255,1);
                                font-weight: normal;
                            }
                        }
                        div{
                            p{
                                line-height: 18px;
                                font-size: 14px;
                                width: 75px;
                                float: left;
                                margin-right: 5px;
                                text-align: left;
                                color: #ffffff;
                            }
                            img{
                                float: left;
                                width: 40px;
                                height: 40px;
                                margin-top: -6px;
                            }
                        }
                        span{
                            height: 42px;
                            width: 2px;
                            position: absolute;
                            top: 29px;
                            right: -3px;
                            background-color: rgba(255,255,255,0.4);
                        }
                    }
                    li:last-child{
                        border: 0 none;
                    }
                }
            }
            .two{
                height: 50px;
                background-color: #ffffff;
                margin-top: 10px;
                ul{
                    padding-top: 10px;
                    li{
                        float: left;
                        margin-left: 12px;
                        height: 30px;
                        .el-input__inner{
                            width: 240px !important;
                        }
                    }
                    li:last-child{
                        span{
                            font-size: 14px;
                            color: #CCCCCC;
                        }
                        .el-button{
                            padding: 6px 10px;
                            margin-top: -2px;
                            border: 0 none;
                            border-radius: 0;
                        }
                    }
                }
            }
            .three{
                padding: 30px 40px;
                background-color: #ffffff;
                margin-top: 10px;
                .top{
                    ul{
                        >li{
                            float: left;
                            padding: 15px 30px;
                            font-size: 14px;
                            text-align: left;
                        }
                        li:nth-child(1){
                            width: 270px;
                            padding-right: 0;
                        }
                        li:nth-child(2){
                            width: 335px;
                        }
                        li:nth-child(3){
                            width: 60px;
                            margin-right: 35px;
                        }
                        li:nth-child(4){
                            width: 90px;
                        }
                        li:nth-child(5){
                            width: 60px;
                        }
                    }
                }
                .bottom{
                    ul{
                        li{
                            background-color: #f5f7fa;
                            padding: 25px 30px;
                            .left{
                                width: 272px;
                                padding-right: 18px;
                                box-sizing: border-box;
                                float: left;
                                border-right: 1px solid #e2e8f0;
                                h5,h6{
                                    font-size: 14px;
                                    font-weight: normal;
                                    color: #0B1D30;
                                    text-align: left;
                                    margin-bottom: 15px;
                                }
                                h6{
                                    span{
                                        color: #0070FF;
                                    }
                                }
                                p{
                                    font-size: 12px;
                                    color: #757F92;
                                    margin-bottom: 10px;
                                    text-align: left;
                                }
                            }
                            .right{
                                width: 788px;
                                float: left;
                                ol{
                                    li{
                                        float: left;
                                        padding: 0 30px;
                                        line-height: 28px;
                                        margin-bottom: 10px;
                                        font-size: 14px;
                                        text-align: right;
                                    }
                                    li:nth-child(1){
                                        width: 335px;
                                        text-align: left;
                                    }
                                    li:nth-child(2){
                                        width: 59px;
                                        margin-right: 30px;
                                    }
                                    li:nth-child(3){
                                        width: 89px;
                                    }
                                    li:nth-child(4){
                                        width: 60px;
                                        padding-right: 0;
                                        position: relative;
                                        span{
                                            position: absolute;
                                            right: 48px;
                                            top: 11px;
                                            width: 6px;
                                            height: 6px;
                                            border-radius: 3px;
                                            background-color: #006fff;
                                        }
                                    }
                                }
                            }
                        }
                        >li{
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
}
</style>