import axios from '../http';
import {an,meng, api} from '../global'
// 新闻资讯推荐 an
export const newsRecommend = (params) => {
    return axios({
        url: api+  '/blade-approczgyy/news/czgyyHome',
        method: 'get',
        params
    })
}

// 政策推荐
export const govRecommend = (params) => {
    return axios({
        url: api+  '/blade-policy/policy/czgyyHome',
        method: 'get',
        params
    })
}

// 获取公司banner新闻列表
export const newsBannerList = (params) => {
    return axios({
        url: api+  '/blade-approczgyy/news/bannerNews',
        method: 'get',
        params
    })
}

// 公司新闻分类列表
export const newsClassList = (params) => {
    return axios({
        url: api+  '/blade-approczgyy/newsClass/listFuzzy',
        method: 'get',
        params
    })
}

// 查询公司新闻下某一分类列表
export const newsOneClassList = (params) => {
    return axios({
        url: api+  '/blade-approczgyy/news/list4Front',
        method: 'get',
        params
    })
}

// 查询公司新闻主推列表
export const newsMainPushList = (params) => {
    return axios({
        url: api+  '/blade-approczgyy/news/mainPush',
        method: 'get',
        params
    })
}

// 查询新闻资讯的上一篇，下一篇
export const companyNewsPrevNext = (params) => {
    return axios({
        url: api+  '/blade-approczgyy/news/pageTurn',
        method: 'get',
        params
    })
}

// 获取新闻详情页
export const newsDetails = (params) => {
    return axios({
        url: api+  '/blade-approczgyy/news/detail',
        method: 'get',
        params
    })
}

// 政策服务下政府网址所有列表
export const govWebsiteIndex = (params) => {
    return axios({
        url: api+  '/blade-policy/policys/listFuzzy',
        method: 'get',
        params
    })
}

// 政策服务首页8个政府网址管理列表
export const govWebsiteList = (params) => {
    return axios({
        url: api+  '/blade-policy/policys/list4Home',
        method: 'get',
        params
    })
}

// 获取全部政府网站地址
export const govWebsiteAllList = (params) => {
    return axios({
        url: api+  '/blade-policy/policys/listFuzzy',
        method: 'get',
        params
    })
}

// 获取政策新闻banner
export const govNewsBannerList = (params) => {
    return axios({
        url: api+  '/blade-policy/policy/bannerList',
        method: 'get',
        params
    })
}

// 获取政策资讯分类
export const govNewsClassList = (params) => {
    return axios({
        url: api+  '/blade-policy/policyClass/list',
        method: 'get',
        params
    })
}

// 获取政策下某一类的列表
export const govNewsOneClassList = (params) => {
    return axios({
        url: api+  '/blade-policy/policy/listFuzzy',
        method: 'get',
        params
    })
}

// 根据政府分类id查询分类名称
export const govClassName = (params) => {
    return axios({
        url: api+  '/blade-policy/policyClass/detail',
        method: 'get',
        params
    })
}


// 政府详情页
export const govNewsDetails = (params) => {
    return axios({
        url: api+  '/blade-policy/policy/detail',
        method: 'get',
        params
    })
}

// 获取政府资讯上一篇，下一篇
export const govNewsPrevNext = (params) => {
    return axios({
        url: api+  '/blade-policy/policy/pageTurn',
        method: 'get',
        params
    })
}