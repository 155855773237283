<template>
    <div class="order-center">
        <div class="order-detail">
            <div class="order-detail-title">
                <p class="infoItem-title">
                    <router-link to="/userCenter/orderCenter">
                        <span class="infoItem-icon">
                        <i class="el-icon-arrow-left"></i>
                    </span>
                    <span class="infoItem-text">订单管理</span>
                    </router-link>
                    <!-- <span class="iconfont icon-Frame21323" style="color:#006fff;font-size:50px;"></span>
                    <span class="iconfont icon-Frame111" style="color:#006fff;font-size:50px;"></span>
                    <span class="iconfont icon-Frame222" style="color:#006fff;font-size:50px;"></span>-->
                </p>
            </div>
            <div class="order-detail-tips">
                <span class="el-icon-warning"></span>
                <p>请于2022-02-26 15:06:44前完成支付，若未及时付款，系统将自动取消订单。</p>
            </div>
            <div class="order-detail-s-title">订单概要</div>
            <div class="order-detail-profile">
                <el-row>
                    <el-col :span="12">
                        <div class="order-detail-profile-item">
                            <span>订单编号：</span>
                            <p>{{orderDetail.ordering}}</p>
                        </div>
                        <div class="order-detail-profile-item">
                            <span>创建时间：</span>
                            <p>{{orderDetail.create_time}}</p>
                        </div>
                        <!-- <div class="order-detail-profile-item">
                            <span>订单来源：</span>
                            <p>{{orderDetail.from}}</p>
                        </div> -->
                        <div class="order-detail-profile-item">
                            <span>支付状态：</span>
                            <!-- 1未付款2已付款3已完成(已到期)4已关闭 -->
                            <p>
                                <span
                                    v-if="orderDetail.state == 1"
                                    style="color:#ff0027;"
                                >未支付 ￥{{orderDetail.payable_price}}</span>
                                <span v-if="orderDetail.state == 2">已支付</span>
                                <span v-if="orderDetail.state == 4">已关闭</span>
                                <span v-if="orderDetail.state == 3">已完成</span>
                            </p>
                        </div>
                    </el-col>
                    <el-col :span="12">
                        <div class="order-detail-profile-item">
                            <span>订单类型：</span>
                            <p>新购</p>
                        </div>
                        <div class="order-detail-profile-item">
                            <span>支付时间：</span>
                            <p>{{orderDetail.payment_time}}</p>
                        </div>
                    </el-col>
                </el-row>
                <div class="order-detail-btns">
                    <el-button  v-if="orderDetail.state == 1" @click="payOrder" type="primary">立即支付</el-button>
                    <el-button  v-if="orderDetail.state == 1" plain @click="cancelOrder">取消订单</el-button>
                </div>
            </div>
            <div class="order-detail-s-title">订单详情</div>
            <el-table
                :data="productList"
                style="width: 100%"
                border
                :header-cell-style="{ background: '#F6F8FB', color: '#757F92' }"
                :cell-style="{ color: '#0B1D30' }"
            >
                <el-table-column align="center" prop="name" label="产品名称"></el-table-column>
                <el-table-column align="center" prop="className" label="商品类型"></el-table-column>
                <el-table-column align="center" prop="synopsis" label="商品描述"></el-table-column>
                <el-table-column align="center" prop="payment_id" label="付款方式">
                    支付宝
                </el-table-column>
                <el-table-column align="center" prop="price" label="金额">
                    <template slot-scope="scope">
                        <span style="color:#FF0027;">￥{{scope.row.price}}</span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="real-money-box">
                <span class="real-money-text">实付金额：</span>
                <span class="real-money-num">￥{{orderDetail.payable_price}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import userCenterTitle from "@/components/user-center-title";
export default {
    data() {
        return {
            orderid: 0,
            orderDetail: {
                // id: 1,
                // ordersn: "20220222145788688",
                // creattime: "2022-02-22 14:59",
                // paytime: "2022-02-22 14:59",
                // name: "CRM",
                // province: "上海",
                // city: "普陀区",
                // total: "5000.00",
                // status: 0,
                // from: 0,
                // table: [
                //     {
                //         name: "CRM",
                //         type: "",
                //         desc: "",
                //         paytype: "按年（1年）",
                //         total: 18200.0,
                //     },
                // ],
            },
            productList:[]
        };
    },
    components: {
        userCenterTitle,
    },
    mounted() {
        let _this = this;
        let orderid = this.$route.query.orderid;
        if (!orderid) {
            this.$message.error("订单不存在");
            return false;
        }
        this.orderid = orderid;
        _this.getOrderDetail();
    },
    methods: {
        hideLoading() {
            this.loading = false;
        },
        getOrderDetail() {
            let _this = this;
            setTimeout(() => {
                _this.hideLoading();
            }, 2000);
            _this.$api.usercenter
                .getOrderDetail({
                    id: _this.orderid,
                })
                .then((res) => {
                    if (res.success) {
                        this.orderDetail = res.data
                        this.productList = [
                            {
                                name:res.data.name,
                                price:res.data.price,
                                synopsis:res.data.synopsis,
                                payment_id:res.data.payment_id,
                                className:res.data.className,
                            }
                        ]
                    } else {
                        _this.$message.error(res.msg);
                    }
                });
        },
        payOrder(){
            let _this = this;
            let item = _this.orderDetail;
            if (!item) {
                return;
            }
            this.$router.push({
                path: "/softwareMarket/orderPay",
                query: {
                    order: item.ordering,
                },
            });
        },
        cancelOrder() {
            let _this = this;
            let item = _this.orderDetail;
            if (!item) {
                return;
            }

            if(!_this.$route.query.orderid){
                return
            }
            this.$confirm("确定取消此订单吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    _this.$api.usercenter
                        .cancelUserOrder({
                            id: _this.$route.query.orderid,
                        })
                        .then((res) => {
                            if (res.success) {
                                this.$message.success("订单取消成功");
                                this.getOrderDetail()
                            } else {
                                _this.$message.error(res.msg);
                            }
                        });
                })
                .catch(() => {});
        },
        deleteOrder(item) {
            let _this = this;
            if (!item) {
                return;
            }
            // _this.$api.usercenter
            // .delUserOrder({
            //     id: item.id,
            // })
            // .then((res) => {
            //     if (res.code == 200) {
            //         this.$message.success("订单删除成功");
            //     } else {
            //         _this.$message.error(res.msg);
            //     }
            // });
        },
    },
};
</script>


<style lang="scss" scoped>
.order-detail {
    .order-detail-title {
        .infoItem-title {
            display: flex;
            align-items: center;
            margin-bottom: 40px;
        }
        .infoItem-title .infoItem-icon {
        }
        .infoItem-text {
            width: 64px;
            height: 20px;
            font-size: 16px;
            font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
            font-weight: 300;
            color: #0b1d30;
            line-height: 19px;
        }
    }
    .order-detail-tips {
        width: 100%;
        height: 35px;
        background: #ffe1cb;
        font-size: 12px;
        color: #0b1d30;
        line-height: 35px;
        display: flex;
        padding-left: 25px;
        box-sizing: border-box;
        margin-bottom: 20px;
        span {
            margin-right: 10px;
            line-height: 35px;
            color: #ffa500;
            font-size: 15px;
        }
    }
    .order-detail-s-title {
        margin-bottom: 20px;
        font-size: 14px;
        color: #000000;
        height: 18px;
    }
    // 订单概要
    .order-detail-profile {
        font-size: 12px;
        color: #757f92;
        .order-detail-profile-item {
            display: flex;
            line-height: 2;
            span {
            }
            p {
                padding-left: 10px;
            }
        }
    }
    .order-detail-btns {
        margin-top: 40px;
        margin-bottom: 40px;
        .el-button--primary {
            background: #006fff;
            border: #006fff;
            width: 84px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            padding: 0;
            font-size: 12px;
        }
        .el-button--default {
            background: #fff !important;
            color: #757f92 !important;
            width: 84px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            padding: 0;
            font-size: 12px;
        }
    }
    .real-money-box {
        margin-top: 50px;
        text-align: right;
        margin-bottom: 50px;
        .real-money-text {
            font-size: 12px;
            color: #757f92;
        }
        .real-money-num {
            font-size: 22px;
            color: #ff0027;
            line-height: 26px;
        }
    }
}
</style>