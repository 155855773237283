<template>
    <div class="indust-iot">
        <div class="banner">
            <!-- <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false"><img src='https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/banner1.jpg')" alt=""></a> -->
            <div class="banner-center">
                <h3>工业物联网平台</h3>
                <p>一站式开发，覆盖物联网云、管、边、端各个<br/>环节，满足各类开发者产品开发需求。</p>
                <a href="https://console.hanclouds.com/login" target="_blank">立即体验</a>
                <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即咨询</a>
            </div>
        </div>

        <div class="iot-introduce">
            <div class="left">
                <h3>工业物联网平台</h3>
                <p>工业物联网平台面向制造业数字化、网络化、智能化需求，构建基于边缘计算、设备管理、数据采集的服务体系，支撑制造资源泛在连接、弹性供给、高效配置、包括边缘、平台、应用化三大核心层。</p>

                <div class="pho-introduce">
                    <h4>数字化<span></span></h4>
                    <p>数字化：工业物联网平台支持整个工业物联网生态系统，提供了设备资产的连接，集成和支持，帮助企业实现设备，信息的数字化。</p>
                </div>
            </div>
            <div class="right">
                <div class="pho-introduce">
                    <h4>网络化<span></span></h4>
                    <p>网络化：工业物联网平台帮助企业实现设备与设备，设备与人之间交互，提高生产数据在不同部门之间的流通效率，打破企业生产过程中的数据孤岛。</p>
                </div>
                <div class="pho-introduce transtion">
                    <h4>智能化<span></span></h4>
                    <p>智能化：工业物联网平台帮助企业快速完成生产智能化和提供智能服务，加快工业企业运营模式，生产模式，商业模式的创新。</p>
                </div>
            </div>
        </div>

        <div class="pro-introduce">
            <div class="pro-introduce-center">
                <h3>产品能力与服务</h3>
                <p>工业物联网平台除了作为中枢连接智能设备外，还拥有多项强大功能，全面服务物联网场景。</p>
                <ul>
                    <li v-for="item,index in introduceList" :key="index" @mouseenter="changeIntroduceIndex(index)" @mouseleave="changeIntroduceIndex(null)">
                        <p>
                            <img v-if="introduceIndex == index" :src='item.active_img' alt="">
                            <img v-else :src='item.img' alt="">
                        </p>
                        <div>
                            <h4>{{ item.h4 }}</h4>
                            <p>{{ item.p }}</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="terrace">
            <div class="terrace-center">
                <h3>平台特点</h3>
                <div class="content-box">
                    <div class="left">
                        <ul>
                            <li v-for="(item, index) in terrace" :key="index">
                                <img :src="item.img" alt="">
                            </li>
                        </ul>
                    </div>
                    <div class="right">
                        <div class="title">
                            <ul>
                                <li v-for="(item, index) in terrace" :key="index + 't'" @click="terTit(index)">{{item.title}}</li>
                            </ul>
                        </div>
                        <div class="content">
                            <div v-for="(item, index) in terrace" :key="index + 'in'">
                                <h4>{{item.title}}<span></span></h4>
                                <p>{{item.introduce}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="target-user">
            <h3>目标用户</h3>

            <!-- <ul>
                <li>
                    <img src="" alt="">
                    <div>
                        <div>
                            <img src="" alt="">
                            <span></span>
                        </div>
                        <p>汽车制造</p>
                    </div>
                </li>
                <li>
                    <img src="" alt="">
                    <div>
                        <div>
                            <img src="" alt="">
                            <span></span>
                        </div>
                        <p>汽车制造</p>
                    </div>
                </li>
                <li>
                    <img src="" alt="">
                    <div>
                        <div>
                            <img src="" alt="">
                            <span></span>
                        </div>
                        <p>汽车制造</p>
                    </div>
                </li>
                <li>
                    <img src="" alt="">
                    <div>
                        <div>
                            <img src="" alt="">
                            <span></span>
                        </div>
                        <p>汽车制造</p>
                    </div>
                </li>
            </ul> -->
            <div class="my-swiper">
                <swiper :options="swiperOptions" :navigation="true">
                    <swiper-slide v-for="(item,index) in imgs" :key="index">
                        <img :src="item.bgImg" alt="">
                        <div class="content">
                            <div>
                                <img :src="item.iconImg" alt="">
                                <span></span>
                            </div>
                            <p>{{item.title}}</p>
                        </div>
                        <div class="bg"></div>
                    </swiper-slide>
                    <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                    
                </swiper>
                <div class="swiper-button-prev"></div><!--左箭头。如果放置在swiper外面，需要自定义样式。-->
                <div class="swiper-button-next"></div><!--右箭头。如果放置在swiper外面，需要自定义样式。-->
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default{
    data() {
        return{
            introduceIndex:null,
            introduceList:[
                {
                    img:require('@/assets/industIot/IoTServe1.png'),
                    active_img:require('@/assets/industIot/IoTServe1_1.png'),
                    h4:"·设备接入·",
                    p:"支持多协议、多平台、多网格、多地域设备快速接入"
                },
                {
                    img:require('@/assets/industIot/IoTServe2.png'),
                    active_img:require('@/assets/industIot/IoTServe2_1.png'),
                    h4:"·设备管理·",
                    p:"为大规模部署设备提供高并发、低延时管理能力"
                },
                {
                    img:require('@/assets/industIot/IoTServe3.png'),
                    active_img:require('@/assets/industIot/IoTServe3_1.png'),
                    h4:"·组态设计·",
                    p:"为物联网设备提供快捷可拖拽的组态应用开发设计工具，实现定制化的组态设计应用与可视化数据看板"
                },
                {
                    img:require('@/assets/industIot/IoTServe4.png'),
                    active_img:require('@/assets/industIot/IoTServe4_1.png'),
                    h4:"·规则引擎·",
                    p:"为设备提供处理复杂逻辑的引擎、通过数据转发与设备联动完成设备服务需求"
                },
                {
                    img:require('@/assets/industIot/IoTServe5.png'),
                    active_img:require('@/assets/industIot/IoTServe5_1.png'),
                    h4:"·设备数据模拟·",
                    p:"快速对连接设备进行连通性测试与设备告警模拟"
                },
            ],
            imgs: [
                {
                    bgImg:'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/car.png',
                    title: '汽车制造',
                    iconImg:'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/car.png'
                },
                {
                    bgImg:'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/huagong.png',
                    title: '绿色化工',
                    iconImg:'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/huagong.png'
                },
                {
                    bgImg:'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/guandao.png',
                    title: '管道装备',
                    iconImg:'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/guandao.png'
                },
                {
                    bgImg:'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/fuzhuang.png',
                    title: '服装服饰',
                    iconImg:'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/fuzhuang.png'
                },
                {
                    bgImg:'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/qingjie.png',
                    title: '清洁能源',
                    iconImg:'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/qingjie.png'
                },
                // {
                //     bgImg: '',
                //     title: '生物医药',
                //     iconImg: ''
                // },
                // {
                //     bgImg: '',
                //     title: '先进再制造',
                //     iconImg: ''
                // },
                // {
                //     bgImg: '',
                //     title: '智能装备',
                //     iconImg: ''
                // },
                // {
                //     bgImg: '',
                //     title: '激光产业',
                //     iconImg: ''
                // },
                // {
                //     bgImg: '',
                //     title: '物流',
                //     iconImg: ''
                // },
                // {
                //     bgImg: '',
                //     title: '文化旅游',
                //     iconImg: ''
                // },
                // {
                //     bgImg: '',
                //     title: '信息应用',
                //     iconImg: ''
                // },
            ],
            swiperOptions: {
                loop: true,
                slidesPerView : 4,
                autoplay: {
                    delay: 5000
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
            terrace: [{
                title: '平台特点',
                introduce: '基于工业物联网平台，将工厂、设备和生产资料通过网络技术高度结合，连接产线设备，实现设备互联互通、设备数据采集，为生产设备提供设备连接基础。有效降低运营风险、提高产品质量和经营效益，扩展分析能力，实现新锐洞察，助力企业现代化智能制造产业升级。',
                img:require("@/assets/industIot/terrace1.png")
            },{
                title: '功能强大',
                introduce: '兼容异构网络接入、稳健消息通道、可视化监控、安全数据通信、固件升级工具等全套物联网平台层功能。可免去用户大部分自搭建部署、运维、扩容等繁琐工作。用户可根据具体的设备数据通讯连接方式，采用设备直接连接和通过网关设备连接平台，实现对物联网采集层的大量、各种设备的接入。',
                img:require("@/assets/industIot/terrace2.png")
            },{
                title: '配套服务',
                introduce: '可基于私有云/公有云方式部署，帮助企业打造自己的IOT云平台，在提供设备管理、数 据模型服务、生产管理、SaaS搭建等能力模块的同时，可提供定制化的系统部署、业务开发。',
                img:require("@/assets/industIot/terrace3.png")
            }]
        }
    },
    comments: {
        Swiper,
        SwiperSlide
    },
    methods: {
        handleScroll: function (e) {
            let _this = this;
            _this.scrollTop = document.documentElement.scrollTop;
            _this.getBottomOfWindow();
        },
        changeIntroduceIndex(i){
            this.introduceIndex = i
        },
        getBottomOfWindow: function () {
            // var winHeight = window.innerHeight;
            if (this.scrollTop > 500) {
                // this.isFixed = true;
                $('.iot-introduce .left .pho-introduce').css({'left': 0, 'transition': 'left 1s ease'})
                $('.iot-introduce .right .transtion').css({'right': 0, 'transition': 'right 1s ease'})
            }
            if(this.scrollTop > 1200){
                $('.pro-introduce .pro-introduce-center ul li:nth-child(2n+1)').css({'left': 0, 'transition': 'left 1s ease'})
                $('.pro-introduce .pro-introduce-center ul li:nth-child(2n)').css({'right': 0, 'transition': 'right 1s ease'})
            }
        },
        terTit: function(index) {
            $('.terrace-center .content >div').eq(index).show()
            $('.terrace-center .content >div').eq(index).siblings().hide()
            $('.terrace-center .right .title ul li').eq(index).addClass('show')
            $('.terrace-center .right .title ul li').eq(index).siblings().removeClass('show')
            $('.terrace-center .left ul li').eq(index).addClass('show')
            $('.terrace-center .left ul li').eq(index).siblings().removeClass('show')
        }
    },
    mounted() {
         window.addEventListener("scroll", this.handleScroll, true);
         this.terTit(0)
    }
}
</script>

<style scoped lang="scss">
.indust-iot{
    width: 100%;
    overflow: hidden;
    .banner{
        width: 100%;
        height: 540px;
        // background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/banner1.jpg') center no-repeat;
        // background: url('../../assets/fixpage/industlot/banner1.jpg') center no-repeat;
        background: url("../../assets/home/industInternet_banner.png") center no-repeat;

        background-size: auto 540px;
        margin-bottom: 90px;
        .banner-center{
            width: 1200px;
            margin: 0 auto;
            h3{
                font-size: 42px;
                margin-bottom: 20px;
                padding-top: 170px;
                color: #0B1D30;
            }
            p{
                font-size: 20px;
                line-height: 35px;
                margin-bottom: 80px;
                color: #757F92;
            }
            a{
                display: inline-block;
                width: 140px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                background-color: #1f51c8;
                font-size: 14px;
                color: #ffffff;
                margin-right: 15px;
            }
        }
    }

    .iot-introduce{
        width: 1200px;
        height: 680px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        position: relative;
        .pho-introduce{
            width: 580px;
            height: 300px;
            background-color: #e5e5e5;
            box-sizing: border-box;
            padding: 50px;
            color: #ffffff;
            h4{
                position: relative;
                font-size: 22px;
                font-weight: 400;
                margin-bottom: 45px;
                span{
                    width: 30px;
                    height: 3px;
                    background-color: #fff;
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                }
            }
            p{
                width: 320px;
                font-size: 14px;
                line-height: 24px;
            }
        }
        .left{
            width: 580px;
            h3{
                font-size: 36px;
                margin-bottom: 55px;
            }
            >p{
                width: 520px;
                font-size: 18px;
                line-height: 30px;
                margin-bottom: 55px;
            }
            .pho-introduce{
                position: absolute;
                top: 277px;
                left: -940px;
                background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/iot1.png') 0 0 no-repeat;
                cursor: pointer;
            }
            .pho-introduce:hover{
                background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/iot1_1.png') 0 0 no-repeat;
            }
        }
        .right{
            width: 580px;
            .pho-introduce{
                margin-bottom: 40px;
                cursor: pointer;
            }
            .pho-introduce:nth-child(1){
                background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/iot2.png') 0 0 no-repeat;
            }
            .pho-introduce:nth-child(2){
                background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/iot3.png') 0 0 no-repeat;
            }
            .pho-introduce:nth-child(1):hover{
                background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/iot2_1.png') 0 0 no-repeat;
            }
            .pho-introduce:nth-child(2):hover{
                background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/iot3_1.png') 0 0 no-repeat;
            }
            .transtion{
                position: absolute;
                bottom: 0;
                right: -940px;
            }
        }
    }

    .pro-introduce{
        background-color: #ededed;
        background: url("../../assets/industIot/IoTServeBg.png") no-repeat;
        height: 500px;
        .pro-introduce-center{
            width: 1200px;
            margin: 0 auto;
            padding: 30px 0;
            color: #181818;
            h3{
                font-size: 36px;
                padding: 60px 0 40px;
                font-family: Source Han Sans CN;
                font-weight: bold;
                color: #000000;
            }
            >p{
                font-size: 18px;
                margin-bottom: 40px;
            }
            ul{
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                position: relative;
                li{
                    // width: 580px;
                    // height: 140px;
                    // background-color: #ffffff;
                    // border: 1px solid #ffffff;
                    // display: flex;
                    // justify-content: space-around;
                    // margin-bottom: 42px;
                    // align-items: center;
                    // position: absolute;
                    width: 222px;
                    height: 189px;
                    background: #FFFFFF;
                    box-shadow: 0px 10px 10px 0px rgba(7,12,68,0.05);
                    opacity: 0.8;
                    border-radius: 10px;
                    text-align: center;
                    cursor: pointer;
                    border: 1px solid transparent;
                    img{
                        width: 60px;
                        height: 60px;
                        filter: grayscale(100%);
                        filter: gray;
                        display: block;
                        margin:  0 auto;
                    }
                    div{
                        // width: 400px;
                        padding: 0 25px;
                        h4{
                            margin-bottom: 14px;
                            font-size: 18px;
                            font-weight: 400;
                        }
                        p{
                            font-size: 12px;
                            font-family: Microsoft YaHei;
                            font-weight: 400;
                            color: #959CA6;
                            line-height: 20px;
                            text-align: justify;
                        }
                    }
                }
              
                li:hover{
                    border: 1px solid #0072FF;
                    box-shadow: 0 0 10px rgba(0,0,0,0.08);
                    img{
                        -webkit-filter: none;
                        -moz-filter: none;
                        -ms-filter: none;
                        -o-filter: none;
                        filter: none;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .terrace{
        width: 100%;
        padding: 90px 0;
        background-color: #444f79;
        .terrace-center{
            width: 1200px;
            margin: 0 auto;
            padding-bottom: 90px;
            h3{
                font-size: 36px;
                color: #fefefe;
                margin-bottom: 60px;
            }
            .content-box{
                display: flex;
                .left, .right{
                    width: 600px;
                    height: 480px;
                }
                .left{
                    background-color: #f6f8fc;
                    position: relative;
                    ul{
                        width: 600px;
                        display: flex;
                        li{
                            width: 600px;
                            height: 480px;
                            opacity: 0;
                            position: absolute;
                            top: 0; 
                            left: 0;
                        }
                        li.show{
                            opacity: 1;
                            transition: opacity 1s ease;
                            z-index: 9;
                        }
                    }
                }
                .right{
                    background-color: #ffffff;
                    color: #181818;
                    .title{
                        width: 484px;
                        margin: 0 auto;
                        margin-bottom: 100px;
                        ul{
                            display: flex;
                            justify-content: space-around;
                            li{
                                width: 150px;
                                height: 50px;
                                line-height: 50px;
                                text-align: center;
                                font-size: 14px;
                                box-shadow: 0 0 10px rgba(0,0,0,0.08);
                                cursor: pointer;
                            }
                            li.show{
                                background-color: #1748fd;
                                color: #ffffff;
                            }
                        }
                    }
                    .content{
                        width: 448px;
                        margin: 0 auto;
                        >div{
                            display: none;
                            h4{
                                font-size: 26px;
                                position: relative;
                                margin-bottom: 80px;
                                span{
                                    width: 30px;
                                    height: 2px;
                                    position: absolute;
                                    top: 70px;
                                    left: 0;
                                    background-color: #181818;
                                    margin-bottom: 80px;
                                }
                            }
                            p{
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }

    .target-user{
        padding: 120px 0;
        background-color: #ffffff;
        h3{
            width: 1200px;
            margin: 0 auto;
            margin-bottom: 60px;
            font-size: 32px;
            color: #181818;
        }
        img{
            width: 300px;
            height: auto;
        }
        ul{
            width: 1200px;
            margin: 0 auto;
            display: flex;
            li{
                width: 300px;
                height: 486px;
                position: relative;
                // background-color: #e5e5e5;
                box-sizing: border-box;
                border-right: 1px solid #ffffff;
                >div{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    div{
                        position: relative;
                        width: 100px;
                        height: 100px;
                        margin-bottom: 20px;
                        text-align: center;
                        img{
                            width: 80px;
                            height: 80px;
                        }
                        span{
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            margin-left: -8px;
                            width: 16px;
                            height: 2px;
                            background-color: #ffffff;
                        }
                    }
                    p{
                        font-size: 24px;
                        color: #ffffff;
                    }
                }
            }
        }
        .my-swiper{
            width: 1200px;
            height: 530px;
            // overflow: hidden;
            margin: 0 auto;
            position: relative;
            background-color: #eeeeee;
            .swiper-slide{
                cursor: pointer;
                >div.content{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 9;
                    div{
                        position: relative;
                        width: 100px;
                        height: 100px;
                        margin-bottom: 20px;
                        text-align: center;
                        img{
                            width: 80px;
                            height: 80px;
                        }
                        span{
                            position: absolute;
                            bottom: 0;
                            left: 50%;
                            margin-left: -8px;
                            width: 16px;
                            height: 2px;
                            background-color: #ffffff;
                        }
                    }
                    p{
                        font-size: 24px;
                        color: #ffffff;
                    }
                }
                .bg{
                    width: 300px;
                    height: 530px;
                    background-color: rgba(40, 40, 40, 0.7);
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            .swiper-slide:hover{
                .bg{
                    background-color: rgba(5, 62, 245, 0.8);
                }
            }
            .swiper-button-prev{
                position: absolute;
                left: -50px;
                top: 50%;
                transform: translateY(-50%);
            }
            .swiper-button-next{
                position: absolute;
                right: -50px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
</style>
