
import axios from '../http';
import {an,meng, api} from '../global'
/**
 * author  LLLL
 * date    2022-01-25
 * name    个人中心——供应商相关
 */

// 个人供应商入驻
export const postPersonalData = (params) => {
    return axios({
        url: api + '/blade-personalCenter/suppPersonal/save',
        method: 'post',
        data: params
    })
}

// 商家供应商入驻
export const postCompanyData = (params) => {
    return axios({
        url: api + '/blade-personalCenter/suppCompany/save',
        method: 'post',
        data: params
    })
}

// 获取地址列表
export const getRegion = (params) => {
    return axios({
        url: api + '/blade-system/region/select',
        method: 'get',
        params
    })
}

// 获取行业列表
export const getHangYeList = (params) => {
    return axios({
        url: api + '/blade-system/hangye/select',
        method: 'get',
        params
    })
}

// 获取  企业性质com_nature  资信等级 com_credit_level  企业类别 com_class
export const getDictionaryTree = (params) => {
    return axios({
        url: api + '/blade-system/dict-biz/dictionary-tree',
        method: 'get',
        params
    })
}

// 获取供应商详情  信息回显

export const getSupplierInfo = (params) => {
    return axios({
        url: api + '/blade-personalCenter/userDetail/authSupp',
        method: 'get',
        params
    })
}


export const uploadImage = (params) => {
    return axios({
        url: api + '/blade-resource/oss/endpoint/put-file',
        method: 'post',
        data:params
    })
}

export const uploadImageUrl = (params) => {
    return api + '/blade-resource/oss/endpoint/put-file';
}
