<template>
    <div class="news-index">
        <!-- <nav-bar></nav-bar> -->
        
        <div class="banner">
            <div class="banner-center">
                <div class="bottom">
                    <div class="thumb-example">
                        <!-- swiper1 -->
                        <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
                            <swiper-slide v-for="(item,index) in newBannerList" :key="index + 'bannernews'"><router-link :to="{path: '/govNewsDetails', query: {id: item.id,topClass: item.topClass, classId: item.classId}}">
                                <p><img :src="item.image" :alt="index"></p>
                            </router-link></swiper-slide>
                        </swiper>
                        <!-- swiper2 Thumbs -->
                        <swiper class="swiper gallery-thumbs" :options="swiperOptionThumbs" ref="swiperThumbs">
                            <swiper-slide v-for="(item,index) in newBannerList" :key="index + 'bannernewsbtn'">
                                <div class="news-btn"><router-link :to="{path: '/govNewsDetails', query: {id: item.id,topClass: item.topClass, classId: item.classId}}">
                                    <h3>{{item.name}}</h3>
                                    <div>
                                        <p>
                                            <span>{{item.orgin}}</span>
                                            <!-- <span>{{item.createTime.substring(11,16)}}</span> -->
                                        </p>
                                        <p>{{item.createTime.substring(0,10)}}</p>
                                    </div>
                                </router-link></div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
        </div>

        <div class="government-entry">
            <div class="government-center">
                <div class="title">
                    <h3>政府官网入口</h3>
                    <p>企业注册、日常管理、生产经营等全流程贴心服务</p>
                </div>
                <div class="entry">
                    <div class="left">
                        <div>
                            <p>政府网站入口</p>
                            <router-link :to="{path: '/govWeb'}">查看更多</router-link>
                        </div>
                        <ul>
                            <li v-for="(item,index) in govwebList.slice(0,8)" :key="index + 'gov'"><a :href="item.url" target="_black">
                                <h6>{{item.name}}</h6>
                                <p>点击进入</p>
                            </a></li>
                        </ul>
                    </div>
                    <div class="right"><a :href="govwebList[8].url" target="_black">
                        <img :src="govwebList[8].matching" alt="">
                        <h6>{{govwebList[8].name}}</h6>
                    </a></div>
                </div>
            </div>
        </div>

        <div class="content-list">
            <div class="top">
                <div class="top-center">
                    <div class="title">
                        <h3>资讯 <span>精选</span></h3>
                    </div>

                    <div class="news-class">
                        <swiper class="swiper swiper-no-swiping" ref="swiper" :options="swiperOption">
                            <swiper-slide v-for="(item,index) in newsClass" :key="index + 'newsClass'">
                                <span @click="getNewsList(item.id)" :class="checkClassId == item.id? 'border': ''">{{item.className}}</span>
                            </swiper-slide>
                            <!-- <div class="swiper-button-prev" slot="button-prev"></div>
                            <div class="swiper-button-next" slot="button-next"></div> -->
                        </swiper>
                    </div>

                    <div class="search">
                        <el-input v-model="searchKeyword" required placeholder="请输入内容"></el-input>
                        <el-button type="primary" @click="onSubmit">搜索</el-button>
                    </div>
                </div>
            </div>
            <div class="bottom">
                <div class="bottom-center">
                    <div class="display-mode">
                        <ul>
                            <li v-for="(item,index) in displayMode" :key="index + 'displayMode'" :class="checkDisplayMode == item.modeId? 'show': ''" @click="showMode(item.modeId)"><span></span>{{item.modeName}}</li>
                        </ul>
                        <router-link :to="{path: '/govNewsList', query:{classId: checkClassId}}">更多资讯&gt;&gt;</router-link>
                    </div>
                    <template v-if="checkDisplayMode == 1? isShowmode = true: isShowmode = false">
                    <div class="card-mode">
                        <ul>
                            <li v-for="(item,index) in newsOneClassList" :key="index + 'oneclassnewscard'"><router-link :to="{path: 'govNewsDetails', query: {id: item.id,topClass: item.classId1, classId: item.classId2}}">
                                <h6 class="img-box"><img :src="item.image" alt=""></h6>
                                <h5>{{item.name}}</h5>
                                <p>{{item.introduce}}</p>
                                <div>
                                    <p>
                                        <span>{{item.orgin ? item.orgin: '网络'}}</span>
                                        <span>{{item.createTime.substring(0,10)}}</span>
                                    </p>
                                    <span v-if="item.isUp == 1">推荐</span>
                                </div>
                            </router-link></li>
                        </ul>
                    </div>
                    </template>

                    <template v-else-if="checkDisplayMode == 2?isShowmode = true: isShowmode = false">
                    <div class="list-mode">
                        <ul>
                            <li v-for="(item,index) in newsOneClassList" :key="index + 'oneclassnewslist'" class="show"><router-link :to="{path: 'govNewsDetails', query: {id: item.id,topClass: item.classId1, classId: item.classId2}}">
                                <p><img :src="item.image" alt="新闻图片"></p>
                                <div>
                                    <h3>{{item.name}}</h3>
                                    <p>{{item.introduce}}</p>
                                    <div>
                                        <p>
                                            <span>{{item.orgin ? item.orgin: '网络'}}</span>
                                            <span>{{item.createTime.substring(0,10)}}</span>
                                        </p>
                                        <span v-if="item.isUp == 1">推荐</span>
                                    </div>
                                </div>
                            </router-link></li>
                        </ul>
                    </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import navBar from '@/components/navBar'

import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    data() {
        return{
            swiperOptionTop: {
                loop: true,
                autoplay: true,
                loopedSlides: 5, // looped slides should be the same
            },
            swiperOptionThumbs: {
                loop: true,
                autoplay: true,
                loopedSlides: 5, // looped slides should be the same
                centeredSlides: false,
                slidesPerView: 'auto',
                touchRatio: 0.2,
                direction: 'vertical',
                slideToClickedSlide: true,
            },
            
            swiperOption: {
                slidesPerView: 4,
                // loop: true,
                direction: 'horizontal',
                // navigation: {
                //     nextEl: '.swiper-button-next',
                //     prevEl: '.swiper-button-prev'
                // }
            },
            searchKeyword: '',    // 搜索的关键词
            checkClassId: '',     // 当前新闻分类
            checkDisplayMode: 1,  // 当前展示方式
            isShowMode: true,
            newBannerList: [],    // banner新闻列表
            newsClass: [],        // 新闻分类
            newsOneClassList: [], // 某一类新闻列表
            govwebList: [{
                matching: ''
            }],       // 政府网站入口列表
            displayMode: [{
                modeName: '卡片',
                modeId: 1
            },{
                modeName: '列表',
                modeId: 2
            }]
        }
    },
    comments: {
        Swiper,
        SwiperSlide
    },
    methods: {
        // 获取banner位新闻列表
        getGovNewsBannerList() {
            this.$api.news.govNewsBannerList({}).then(res => {
                if(res.code == 200){
                    this.newBannerList = res.data
                }else{
                    this.$message.error(res.msg)
                }
            })
        },

        // 获取政策一级分类
        getGovNewsClassList() {
            var json = {
                level: 1
            }
            this.$api.news.govNewsClassList(json).then(res => {
                if(res.code == 200){
                    this.newsClass = res.data.records
                    this.checkClassId = res.data.records[0].id
                    this.getGovNewsOneClassList(this.checkClassId)
                }else{
                    this.$message.error(res.msg)
                }
            })
        },

        // 查询某一分类下新闻列表
        getGovNewsOneClassList(classId) {
            var json = {
                size: 9,
                current: 1,
                classId: classId
            }
            this.$api.news.govNewsOneClassList(json).then(res => {
                if(res.code == 200){
                    this.newsOneClassList = res.data.records
                }else{
                    this.$message.error(res.msg)
                }
            })
        },

        // 选择新闻分类
        getNewsList(classId) {
            this.checkClassId = classId
            this.getGovNewsOneClassList(classId)
        },
        
        // 政府入口网址管理
        getGovList() {
            this.$api.news.govWebsiteList({}).then(res => {
                if(res.code == 200){
                    this.govwebList = res.data
                }else{
                    this.$message.error(res.msg)
                }
            })
        },

        // 选择展示方式
        showMode(modeId) {
            this.checkDisplayMode = modeId
        },

        onSubmit() {
            if(this.searchKeyword) {
                var json = {
                    classId: this.checkClassId,
                    name: this.searchKeyword,
                    size: 9,
                    current: 1
                }

                this.$api.news.govNewsOneClassList(json).then(res => {
                    if(res.code == 200){
                        this.newsOneClassList = res.data.records
                    }else{
                        this.$message.error(res.msg)
                    }
                })
            }else{
                this.$message.warning('请输入关键词')
            }
        },
    },
    components: {
       // navBar
    },
    mounted() {
        this.getGovNewsBannerList()
        this.getGovNewsClassList()
        this.getGovList()
        this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop.$swiper
            const swiperThumbs = this.$refs.swiperThumbs.$swiper
            swiperTop.controller.control = swiperThumbs
            swiperThumbs.controller.control = swiperTop
        })
    }
}
</script>

<style lang="scss">
.news-index .search{
    width: 412px;
    .el-input__inner{
        width: 312px !important;
        height: 40px !important;
        border-radius: 6px 0 0 6px;
    }
    .el-button--primary{
        width: 120px;
        border: 0 none;
        background-color: #C7000B;
        border-radius: 0 6px 6px 0;
        font-size: 16px;
    }
}
</style>

<style lang="scss" scoped>
.news-index{
    // margin-top: 62px;
    margin-bottom: 90px;
    .banner{
        height: 580px;
        background-color: #d6e9f8;
        .banner-center{
            width: 1200px;
            margin: 0 auto;
            padding-top: 40px;
            .bottom{
                // display: flex;
                height: 500px;
                overflow: hidden;
                .thumb-example {
                    height: 500px;
                    background-color: #ffffff;
                    display: flex;
                    justify-content: space-between;
                    a{
                        display: block;
                        p{
                            width: 100%;
                            height: 500px;
                            overflow: hidden;
                            position: relative;
                            img{
                                width: 100%;
                                height: auto;
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                            }
                        }
                    }
                }

                .swiper {
                    &.gallery-top {
                        width: 847px;
                        height: 100%;
                    }
                    &.gallery-thumbs {
                        width: 353px;
                        height: 100%;
                        box-sizing: border-box;
                    }
                    &.gallery-thumbs .swiper-slide {
                        width: 100%;
                        height: 20%;
                    }
                    &.gallery-thumbs .swiper-slide-active {
                        background-color: #F3F6F8;
                    }
                }
                .news-btn{
                    height: 99px;
                    border-bottom: 1px solid #F4F4F4;
                    padding: 0 30px;
                    h3{
                        height: 52px;
                        padding-top: 10px;
                        margin-bottom: 8px;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 26px;
                        text-overflow: -o-ellipsis-lastline;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                    div{
                        display: flex;
                        justify-content: space-between;
                        color: #8F99A1;
                        font-size: 12px;
                        p{
                            span{
                                margin-right: 10px;
                            }
                        }
                    }
                }
            }
        }
    }
    .government-entry{
        background-color: #F4F4F4;
        .government-center{
            width: 1200px;
            margin: 0 auto;
            padding-bottom: 100px;
            .title{
                text-align: center;
                padding: 76px 0;
                margin-bottom: 8px;
                h3{
                    font-size: 28px;
                    color: #0b1d30;
                    margin-bottom: 30px;
                    font-weight: 500;
                }
                p{
                    font-size: 16px;
                    color: #757f92;
                }
            }
            .entry{
                display: flex;
                justify-content: space-between;
                .left{
                    width: 774px;
                    height: 354px;
                    background-color: #ffffff;
                    overflow: hidden;
                    box-shadow: 0 0 15px rgba(0,0,0,0.15);
                    div{
                        display: flex;
                        justify-content: space-between;
                        padding: 0 30px;
                        line-height: 58px;
                        border-bottom: 1px solid #eeeeee;
                        p{
                            font-size: 16px;
                            color: #0b1d30;
                        }
                        a{
                            color: #757f92;
                            font-size: 14px;
                        }
                        a:hover{
                            color: #C7000B;
                        }
                    }
                    ul{
                        height: 208px;
                        overflow: hidden;
                        display: flex;
                        flex-wrap: wrap;
                        padding: 22px 0;
                        li{
                            width: 380px;
                            height: 52px;
                            box-sizing: border-box;
                            padding: 0 20px;
                            a{
                                display: flex;
                                justify-content: space-between;
                                line-height: 52px;
                                h6{
                                    width: 180px;
                                    height: 52px;
                                    font-weight: 500;
                                    font-size: 16px;
                                    color: #0b1d30;
                                    text-indent: 1em;
                                    overflow: hidden;
                                }
                                p{
                                    width: 56px;
                                    font-size: 14px;
                                    color: #ffffff;
                                }
                            }
                        }
                        li:hover{
                            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 172, 172, 0.2) 100%);
                            box-shadow: 0 0 10px rgba(0,0,0,0.1);
                            p{
                                color: #0B1D30;
                            }
                        }
                    }
                }
                .right{
                    width: 408px;
                    height: 356px;
                    position: relative;
                    img{
                        width: 408px;
                        height: 356px;
                    }
                    h6{
                        width: 100%;
                        font-weight: 500;
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        text-align: center;
                        line-height: 50px;
                        background-color: rgba(11,29,48,0.6);
                        color: #ffffff;
                        font-size: 16px;
                    }
                }
            }
        }
    }
    .content-list{
        background-color: #ffffff;
        .top{
            border-bottom: 1px solid #f4f4f4;
            margin-bottom: 40px;
            .top-center{
                width: 1200px;
                height: 118px;
                margin: 0 auto;
                display: flex;
                justify-content: space-between;
                align-items: center;
                .title{
                    h3{
                        font-size: 22px;
                        font-weight: 500;
                        span{
                            font-size: 20px;
                            color: #ffffff;
                            background-color: #fe4b53;
                            padding: 7px 15px;
                            border-radius: 6px;
                        }
                    }
                }
                .news-class{
                    width: 580px;
                    height: 118px;
                    position: relative;
                    .swiper{
                        width: 500px;
                        height: 118px;
                        line-height: 118px;
                        text-align: center;
                        margin: 0 auto;
                        .swiper-slide{
                            span{
                                display: inline-block;
                                height: 114px;
                                cursor: pointer;
                            }
                            span.border{
                                border-bottom: 4px solid #C7000B;
                            }
                        }
                        .swiper-button-prev{
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            margin: 0;
                        }
                        .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after{
                            font-size: 16px;
                            font-weight: 600;
                        }
                        .swiper-button-next{
                            width: 40px;
                            height: 40px;
                            position: absolute;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            margin: 0;
                        }
                        .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after{
                            font-size: 16px;
                            font-weight: 600;
                        }
                    }
                    // ul{
                    //     display: flex;
                    //     li{
                    //         height: 118px;
                    //         line-height: 118px;
                    //         margin: 0 25px;
                    //         font-size: 14px;
                    //         color: #0B1D30;
                    //         cursor: pointer;
                    //         span{
                    //             display: block;
                    //             height: 114px;
                    //         }
                    //         span.border{
                    //             border-bottom: 4px solid #006fff;
                    //         }
                    //     }
                    // }
                }
                .search{
                    display: flex;
                }
            }
        }
        .bottom{
            .bottom-center{
                width: 1200px;
                margin: 0 auto;
                .display-mode{
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 30px;
                    ul{
                        display: flex;
                        li{
                            margin-right: 5px;
                            width: 90px;
                            height: 40px;
                            color: #ffffff;
                            text-align: center;
                            line-height: 40px;
                            font-size: 18px;
                            background-color: #BBBBBB;
                        }
                        li.show{
                            background-color: #C3A16C;
                        }
                    }
                    a{
                        color: #757F92;
                        font-size: 14px;
                    }
                }
                .card-mode{
                    ul{
                        display: flex;
                        justify-content: flex-start;
                        flex-wrap: wrap;
                        li{
                            width: 378px;
                            height: 380px;
                            border: 1px solid #f0f0f0;
                            margin-bottom: 32px;
                            margin-right: 30px;
                            a{
                                .img-box{
                                    width: 378px;
                                    height: 196px;
                                    position: relative;
                                    overflow: hidden;
                                    img{
                                        width: 378px;
                                        height: auto;
                                        position: absolute;
                                        top: 50%;
                                        left: 0;
                                        transform: translateY(-50%);
                                    }
                                }
                                h5{
                                    font-weight: 500;
                                    margin-top: 25px;
                                    font-size: 16px;
                                    overflow: hidden;
                                    text-overflow:ellipsis;
                                    white-space: nowrap;
                                }
                                >p{
                                    height: 56px;
                                    color: #8F99A1;
                                    font-size: 14px;
                                    line-height: 28px;
                                    margin: 15px 0;
                                    display: -webkit-box;
                                    -webkit-box-orient: vertical;
                                    -webkit-line-clamp: 3;
                                    overflow: hidden;
                                }
                                div{
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    height: 50px;
                                    border-top: 1px solid #f0f0f0;
                                    font-size: 14px;
                                    color: #8F99A1;
                                    p{
                                        span{
                                            margin-right: 10px;
                                        }
                                    }
                                    >span{
                                        width: 48px;
                                        height: 24px;
                                        line-height: 24px;
                                        text-align: center;
                                        font-size: 12px;
                                        color: #C7000B;
                                        border: 1px solid #C7000B;
                                        border-radius: 6px;
                                    }
                                }
                                h5,>p,div{
                                    padding: 0 20px
                                }
                            }
                        }
                        :nth-child(3n){
                            margin-right: 0;
                        }
                        li:hover{
                            box-shadow: 0 0 10px rgba(0,0,0,0.15);
                        }
                    }
                }
                .list-mode{
                    ul{
                        li{
                            a{
                                display: flex;
                                justify-content: space-between;
                                padding: 31px 38px 31px 0;
                                margin-bottom: 31px;
                                border-bottom: 1px solid #eeeeee;
                                >p{
                                    position: relative;
                                    width: 304px;
                                    height: 158px;
                                    overflow: hidden;
                                    img{
                                        width: 304px;
                                        height: auto;
                                        position: absolute;
                                        top: 50%;
                                        left: 0;
                                        transform: translateY(-50%);
                                    }
                                }
                                >div{
                                    width: 834px;
                                    height: 158px;
                                    h3{
                                        font-weight: 500;
                                        font-size: 16px;
                                        overflow: hidden;
                                        white-space: nowrap;
                                        text-overflow: ellipsis;
                                        line-height: 30px;
                                        color: #0b1d30;
                                    }
                                    >p{
                                        font-size: 14px;
                                        color: #8F99A1;
                                        line-height: 28px;
                                        height: 56px;
                                        overflow : hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                        margin-top: 15px;
                                        margin-bottom: 30px;
                                    }
                                    div{
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        p{
                                            color: #8F99A1;
                                            font-size: 14px;
                                            span{
                                                margin-right: 10px;
                                            }
                                        }
                                        >span{
                                            font-size: 12px;
                                            color: #C7000B;
                                            width: 48px;
                                            line-height: 26px;
                                            border: 1px solid #C7000B;
                                            text-align: center;
                                            border-radius: 6px;
                                        }
                                    }
                                }
                            }
                        }
                        li:hover{
                            background-color: #F5F7FA;
                        }
                    }
                }
            }
        }
    }
}
</style>
