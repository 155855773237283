import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    orderList: [],
  },
  mutations: {
    getOrderList: function(state, orderList){
      state.orderList = orderList
      // console.log(state.orderList)
    }
  },
  actions: {
  },
  modules: {
  }
})
