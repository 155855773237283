<template>
    <!-- 集合竞价 -->
    <div class="callAuction">
        <industrial-nav-bar></industrial-nav-bar>

        <div class="callAuction-content">
            <div class="one">
                <ul class="clearfix">
                    <li>
                        <h6>STEP <b>01</b></h6>
                        <div class="clearfix">
                            <p>平台发布活动</p>
                            <img src="" alt="">
                        </div>
                        <span></span>
                    </li>
                    <li>
                        <h6>STEP <b>02</b></h6>
                        <div class="clearfix">
                            <p>采购商报价</p>
                            <img src="" alt="">
                        </div>
                        <span></span>
                    </li>
                    <li>
                        <h6>STEP <b>03</b></h6>
                        <div class="clearfix">
                            <p>供应商报名</p>
                            <img src="" alt="">
                        </div>
                        <span></span>
                    </li>
                    <li>
                        <h6>STEP <b>04</b></h6>
                        <div class="clearfix">
                            <p>数据云算法匹配</p>
                            <img src="" alt="">
                        </div>
                        <span></span>
                    </li>
                    <li>
                        <h6>STEP <b>05</b></h6>
                        <div class="clearfix">
                            <p>形成交易订单</p>
                            <img src="" alt="">
                        </div>
                        <span></span>
                    </li>
                    <li>
                        <p>活动规则</p>
                        <div>
                            <img src="" alt="">
                            <a href="">发布集合竞价</a>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="two">
                <div class="two-result">
                    <span>全部结果</span>
                    <p>共搜索到<b>0</b>个商品</p>
                </div>
                <div class="two-sort">
                    <p>分类</p>
                    <template v-if="moreCheck">
                        <div>
                            <ul class="long">
                                <li v-for="(item,index) in sortList" :key="index + 'sort'">{{item.name}}</li>
                            </ul>
                        </div>
                    </template>

                    <template v-if="moreMessage">
                        <div>
                            <ul class="long">
                                <li v-for="(item,index) in sortList" :key="index + 'sortCheck'">
                                    <el-checkbox :label="item.name" :value="item.id">{{item.name}}</el-checkbox>
                                </li>
                            </ul>
                        </div>
                    </template>

                    <template v-else>
                        <div>
                            <ul class="long">
                                <li v-for="(item,index) in sortList" :key="index + 'sortDefault'">
                                    {{item.name}}
                                </li>
                            </ul>
                        </div>
                    </template>
                    <div>
                        <ul class="check">
                            <li @click="moreChecked" class="more-checked">多选 +</li>
                            <li @click="moreMes" class="more-mes">更多</li>
                        </ul>
                    </div>
                </div>
                <div class="two-state">
                    <p>活动状态</p>
                    <div>
                        <ul>
                            <li>全部活动</li>
                            <li>即将开始</li>
                            <li>采购商报名</li>
                            <li>供应商报价</li>
                            <li>活动成功</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="three">
                <div class="list">
                    <ul>
                        <li>
                            <div class="top">
                                <div>
                                    <h5>麓谷体育公园站燃气管道改迁</h5>
                                    <img src="" alt="">
                                </div>
                                <div>
                                    <ul>
                                        <li>
                                            <p><b>984</b>个</p>
                                            <p>成交量</p>
                                        </li>
                                        <li>
                                            <p><b>104.66</b>万元</p>
                                            <p>成交金额</p>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <p><b>1</b>家</p>
                                            <p>采购商</p>
                                        </li>
                                        <li>
                                            <p><b>1</b>家</p>
                                            <p>供应商</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="center">
                                <div>
                                    <p>活动类目：普通金属管及其配件>钢管>直缝钢管>直缝钢管GB/T 9711</p>
                                    <div class="detailed"><p>直缝钢管GB/T 9711_L290M_355.6mm_12.</p> <p><span>984</span>米</p></div>
                                </div>
                                <h6>共计1种物料</h6>
                            </div>
                            <div class="bottom">
                                <div>
                                    <p>开标时间</p>
                                    <p>2021-11-14 09:58</p>
                                </div>
                                <a href="">查看详情</a>
                            </div>
                        </li>
                        <li>
                            <div class="top">
                                <div>
                                    <h5>麓谷体育公园站燃气管道改迁</h5>
                                    <img src="" alt="">
                                </div>
                                <div>
                                    <ul>
                                        <li>
                                            <p><b>984</b>个</p>
                                            <p>成交量</p>
                                        </li>
                                        <li>
                                            <p><b>104.66</b>万元</p>
                                            <p>成交金额</p>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <p><b>1</b>家</p>
                                            <p>采购商</p>
                                        </li>
                                        <li>
                                            <p><b>1</b>家</p>
                                            <p>供应商</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="center">
                                <div>
                                    <p>活动类目：普通金属管及其配件>钢管>直缝钢管>直缝钢管GB/T 9711</p>
                                    <div class="detailed"><p>直缝钢管GB/T 9711_L290M_355.6mm_12.</p> <p><span>984</span>米</p></div>
                                </div>
                                <h6>共计1种物料</h6>
                            </div>
                            <div class="bottom">
                                <div>
                                    <p>开标时间</p>
                                    <p>2021-11-14 09:58</p>
                                </div>
                                <a href="">查看详情</a>
                            </div>
                        </li>
                        <li>
                            <div class="top">
                                <div>
                                    <h5>麓谷体育公园站燃气管道改迁</h5>
                                    <img src="" alt="">
                                </div>
                                <div>
                                    <ul>
                                        <li>
                                            <p><b>984</b>个</p>
                                            <p>成交量</p>
                                        </li>
                                        <li>
                                            <p><b>104.66</b>万元</p>
                                            <p>成交金额</p>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <p><b>1</b>家</p>
                                            <p>采购商</p>
                                        </li>
                                        <li>
                                            <p><b>1</b>家</p>
                                            <p>供应商</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="center">
                                <div>
                                    <p>活动类目：普通金属管及其配件>钢管>直缝钢管>直缝钢管GB/T 9711</p>
                                    <div class="detailed"><p>直缝钢管GB/T 9711_L290M_355.6mm_12.</p> <p><span>984</span>米</p></div>
                                </div>
                                <h6>共计1种物料</h6>
                            </div>
                            <div class="bottom">
                                <div>
                                    <p>开标时间</p>
                                    <p>2021-11-14 09:58</p>
                                </div>
                                <a href="">查看详情</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="page">
                    <el-pagination
                    background
                    prev-text="上一页"
                    next-text="下一页"
                    layout="prev, pager, next, total, jumper"
                    :total="1000">
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import industrialNavBar from '../../components/industrial-nav-bar'
export default{
    data() {
        return{
            moreCheck: false,
            moreMessage: false,
            sortList: [{
                name: '热度锌钢管',
                id: 1
            },{
                name: '弯管式钢塑转换',
                id: 2
            },{
                name: 'PE100燃气管',
                id: 3
            },{
                name: 'PE球阀',
                id: 4
            },{
                name: '燃气自闭阀',
                id: 5
            },{
                name: '蒸汽输送用波纹软管',
                id: 6
            },{
                name: '无缝钢管GB/T 8163',
                id: 7
            },{
                name: '家用可燃气体报警器',
                id: 8
            }],
            allSortList: [{
                name: '热度锌钢管',
                id: 1
            },{
                name: '弯管式钢塑转换',
                id: 2
            },{
                name: 'PE100燃气管',
                id: 3
            },{
                name: 'PE球阀',
                id: 4
            },{
                name: '燃气自闭阀',
                id: 5
            },{
                name: '蒸汽输送用波纹软管',
                id: 6
            },{
                name: '无缝钢管GB/T 8163',
                id: 7
            },{
                name: '家用可燃气体报警器',
                id: 8
            },{
                name: '热度锌钢管',
                id: 9
            },{
                name: '弯管式钢塑转换',
                id: 10
            },{
                name: 'PE100燃气管',
                id: 11
            },{
                name: 'PE球阀',
                id: 12
            },{
                name: '燃气自闭阀',
                id: 13
            },{
                name: '蒸汽输送用波纹软管',
                id: 14
            },{
                name: '无缝钢管GB/T 8163',
                id: 15
            },{
                name: '家用可燃气体报警器',
                id: 16
            }]
        }
    },
    methods: {
        moreChecked(){},
        moreMes() {},
    },
    mounted() {},
    components: {
        industrialNavBar
    },
}
</script>

<style lang="scss" scoped>
.callAuction{
    width: 100%;
    background-color: #f5f7fa;
    .callAuction-content{
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 50px;
        .one{
            margin: 10px 0;
            background-color: #3e91ff;
            ul{
                li{
                    width: 166px;
                    height: 100px;
                    float: left;
                    border-right: 1px solid rgba(255,255,255,0.2);
                    position: relative;
                    box-sizing: border-box;
                    padding-left: 25px;
                    h6{
                        font-size: 12px;
                        color: rgba(255,255,255,0.7);
                        font-weight: normal;
                        text-align: left;
                        line-height: 40px;
                        margin-top: 10px;
                        b{
                            color: rgba(255,255,255,1);
                            font-weight: normal;
                        }
                    }
                    div{
                        p{
                            line-height: 18px;
                            font-size: 14px;
                            width: 75px;
                            float: left;
                            margin-right: 5px;
                            text-align: left;
                            color: #ffffff;
                        }
                        img{
                            float: left;
                            width: 40px;
                            height: 40px;
                            margin-top: -6px;
                        }
                    }
                    span{
                        height: 42px;
                        width: 2px;
                        position: absolute;
                        top: 29px;
                        right: -3px;
                        background-color: rgba(255,255,255,0.4);
                    }
                }
                li:last-child{
                    width: 370px;
                    display: flex;
                    border: 0 none;
                    justify-content: space-around;
                    align-items: center;
                    p{
                        color: #ffffff;
                        font-size: 14px;
                    }
                    div{
                        width: 132px;
                        height: 40px;
                        background-color: #ffffff;
                        border-radius: 20px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        img{
                            width: 34px;
                            height: 34px;
                            margin-right: 5px;
                            border-radius: 50%;
                            margin-top: 0;
                        }
                        a{
                            font-size: 12px;
                            color: #0070FF;
                        }
                    }
                }
            }
        }
        .two{
            font-size: 12px;
            line-height: 40px;
            >div{
                margin-bottom: 10px;
                display: flex;
                background-color: #ffffff;
                ul{
                    display: flex;
                    padding: 0 5px;
                    li{
                        cursor: pointer;
                        margin: 0 15px;
                        color: #757F92;
                    }
                }
            }
            .two-result{
                height: 40px;
                padding: 0 20px;
                justify-content: space-between;
                align-items: center;
                span{
                    font-size: 14px;
                    color: #0B1D30;
                    font-weight: 500;
                }
                p{
                    font-size: 12px;
                    color: #757F92;
                    b{
                        color: #0B1D30;
                    }
                }
            }
            .two-sort{
                padding-right: 16px;
                justify-content: space-between;
                ul.long{
                    width: 940px;
                }
                ul.check{
                    margin-top: 7px;
                    li{
                        width: 53px;
                        height: 25px;
                        text-align: center;
                        line-height: 25px;
                        border: 1px solid #757f92;
                        margin-left: 10px;
                        margin-right: 0;
                    }
                }
            }
            .two-sort, .two-state{
                p{
                    width: 53px;
                    padding: 0 20px;
                    background-color: #d5dee7;
                    color: #0B1D30;
                }
            }
        }
        .three{
            background-color: #ffffff;
            padding: 12px 0;
            .list{
                width: 1156px;
                margin: 0 auto;
                ul{
                    display: flex;
                    flex-flow: wrap;
                    justify-content: space-between;
                    li{
                        width: 373px;
                        border: 1px solid #e6e6e6;
                        margin: 8px 0;
                        .top{
                            background-color: #f7f7f7;
                            >div{
                                display: flex;
                                justify-content: space-between;
                                align-items: center;
                                padding: 23px 8px 0 23px;
                                h5{
                                    font-weight: 500;
                                    font-size: 16px;
                                    color: #0B1D30;
                                }
                                img{
                                    width: 68px;
                                    height: 58px;
                                }
                                ul:nth-child(1){
                                    width: 160px;
                                }
                                ul:nth-child(2){
                                    width: 100px;
                                }
                                ul{
                                    display: flex;
                                    flex-wrap: nowrap;
                                    text-align: left;
                                    li{
                                        border: 0 none;
                                        font-size: 12px;
                                        color: #757F92;
                                        b{
                                            font-size: 16px;
                                            color: #0B1D30;
                                            font-weight: 500;
                                        }
                                    }
                                }
                            }
                            >div:nth-child(2){
                                padding-top: 0;
                                padding-bottom: 10px;
                            }
                        }
                        .center{
                            height: 170px;
                            color: #757F92;
                            line-height: 24px;
                            font-size: 12px;
                            text-align: left;
                            padding: 16px 14px;
                            display: flex;
                            flex-wrap: wrap;
                            align-content: space-between;
                            div.detailed{
                                display: flex;
                                justify-content: space-between;
                                span{
                                    color: #0B1D30;
                                }
                            }
                            h6{
                                width: 100%;
                                font-weight: 500;
                                text-align: right;
                                color: #0B1D30;
                            }
                        }
                        .bottom{
                            height: 60px;
                            background-color: #f7f7f7;
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            padding: 0 23px;
                            font-size: 12px;
                            div{
                                color: #757F92;
                                text-align: left;
                            }
                            a{
                                color: #0070FF;
                            }
                        }
                    }
                }
            }
            .page{
                margin: 40px 0;
            }
        }
    }
}
</style>
