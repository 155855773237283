<template>
    <div class="company">
        <h2>公司简介</h2>
        <p>公司致力于云计算、工业物联网、大数据分析、区块链、标识解析应用、数字孪生等技术，自主研发多项软件著作权，及产业发展服务平台、产业链全景地图、园区数字孪生、标识解析应用、私域商城、智慧仓储等相关应用系统研发。</p>
        <p>专注于“产业数字化、数字产业化”的整体规划，通过大数据推进以数据支撑产业发展，以数据促营销、以数据促生产、以数据促管理，聚焦工业产业、聚合全域数据，助力产业数字化升级转型。</p>
        <h2>沧州工业云平台<span>河北省工业和信息化厅指定云应用服务商 工业互联网标识解析（沧州）综合型二级节点</span></h2>
        <p>在沧州市工业和信息化局指导下，搭建”沧州工业互联网综合服务平台“，列入河北省工信厅区域“1+21工业互联网平台”，基于沧州全市产业集群优势，实现Saas、paas、Iaas层平台搭建，基于基础底座，打造具有跨要素、跨领域、跨行业、跨区域、跨平台、覆盖全系统、全生命周期、全产业链的综合能力，实现资源融通、应用融通、数据融通，构筑自主可控的工业互联网技术体系，促进沧州产业资源共享、为产业链生态建设提供保障与支撑。</p>
        <p>以“平台+应用+数据三位一体”创新战略，围绕工业发展，打造创新“链路”新模式，聚焦产业数字化，推进制造业创新生产力的形成。</p>
    </div>
</template>

<script>

export default {

}
</script>

<style lang="scss" scoped>
.company{
    h2{
        text-align: left;
        font-size: 18px;
        color: #0B1D30;
        margin-top: 50px;
        margin-bottom: 20px;
        span{
            font-weight: normal;
            margin-left: 20px;
        }
    }
    p{
        width: 999px;
        font-size: 14px;
        font-family: Microsoft YaHei UI, Microsoft YaHei UI-Regular;
        font-weight: 400;
        text-align: LEFT;
        color: #757f92;
        line-height: 40px;
    }
}
</style>
