<template>
    <div class="identification">
        <div class="top-banner">
            <div class="top-banner-bg">
                <video autoplay loop muted src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/identification_banner.mp4"></video>
                <div class="banner-content">
                    <div class="max-content banner-text">
                        <div class="css-zsxmo6">
                            <div class="banner-title">数字未来 万物标识</div>
                        </div>
                        <div class="css-zsxmo6">
                            <div class="banner-desc">标识解析助力制造企业拥抱数字机遇</div>
                        </div>
                        <div class="css-zsxmo6">
                            <a class="hover-btn mt70 banner-btn" href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">
                                <span class="hover-content">立即咨询</span>
                                <span
                                    style="background-color: rgba(255, 255, 255, 0.1); left: 165px; top: 2.60938px;"
                                    class="hover-span"
                                ></span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="top-banner-tags">
                <div class="content-center">
                    <div class="banner-operation">
                        <div class="operation-item">
                            <div class="img-box">
                                <router-link :to="{path: '/identifyNode'}" target="_blank">
                                <img
                                    src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/banner-op1.png"
                                    class="operation-icon"
                                />
                                </router-link>
                            </div>
                            <div class="info-box"><router-link :to="{path: '/identifyNode'}" target="_blank">
                                <div class="info-title">标识解析</div>
                                <div class="info-desc">一码标识，全程溯源</div>
                            </router-link></div>
                        </div>
                        <div class="operation-item">
                            <div class="img-box">
                                <img
                                    src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/banner-op2.png"
                                    class="operation-icon"
                                />
                                <!-- <img
                                    class="scan-code"
                                    src="https://www.360yunxi.com/static/images/newImg/index/scan-code.png"
                                /> -->
                                <img
                                    class="scan-code"
                                    src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/biaoshi.png"
                                />
                            </div>
                            <div class="info-box">
                                <div class="info-title">扫码体验</div>
                                <div class="info-desc">微信扫码，免费体验</div>
                            </div>
                        </div>
                        <div class="operation-item">
                            <div class="img-box">
                                <a href="http://27.128.235.175/#/login" target="_blank">
                                <img
                                    src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/banner-op3.png"
                                    class="operation-icon"
                                />
                                </a>
                            </div>
                            <div class="info-box"><a href="http://27.128.235.175/#/login" target="_blank">
                                <div class="info-title">免费试用</div>
                                <div class="info-desc">海量产品，限时抢购</div>
                            </a></div>
                        </div>
                    </div>
                    <div class="banner-node">
                        <el-carousel
                            height="24px"
                            direction="vertical"
                            :autoplay="true"
                            indicator-position="none"
                        >
                            <el-carousel-item v-for="(item,j) in nodeList" :key="j">
                                <div class="banner-nodelist">
                                    <div
                                        class="banner-nodeitem"
                                        v-for="(node,index) in item.child"
                                        :key="index"
                                    >
                                        <img
                                            src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/banner-icon.png"
                                            class="node-img"
                                        />
                                        {{node}}
                                    </div>
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                </div>
            </div>
        </div>
        <div class="identify-plan">
            <div class="content-center">
                <div class="plan-title">
                    <div class="title-info">工业互联网标识解析体系 万物互联的神经枢纽</div>
                    <div class="title-desc">打造企业数字化转型的基石</div>
                </div>
                <div class="plan-type">
                    <div class="type-item type-item0" @mousemove="changePlan(0)">
                        <img
                            src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/plat1.png"
                            class="type-img"
                        />
                        <div class="type-title" :class="planIndex == 0?' type-title1':''">基础服务平台</div>
                        <div class="type-tri" v-if="planIndex == 0"></div>
                    </div>
                    <div class="type-item type-item1" @mousemove="changePlan(1)">
                        <img
                            src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/plat2.png"
                            class="type-img"
                        />
                        <div class="type-title" :class="planIndex == 1?' type-title1':''">应用服务平台</div>
                        <div class="type-tri" v-if="planIndex == 1"></div>
                    </div>
                    <div class="type-item type-item2" @mousemove="changePlan(2)">
                        <img
                            src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/plat3.png"
                            class="type-img"
                        />
                        <div class="type-title" :class="planIndex == 2?' type-title1':''">运营管理平台</div>
                        <div class="type-tri" v-if="planIndex == 2"></div>
                    </div>
                </div>
                <div class="plan-card">
                    <div class="card-title">{{planList[planIndex].title}}</div>
                    <div class="card-list">
                        <div class="prev-card" @click="prevPlan">
                            <span class="iconfont icon-arrow1"></span>
                        </div>
                        <div class="card-list-box">
                            <div class="card-list-ul">
                                <div
                                    class="card-list-item"
                                    v-for="item,index in planList[planIndex].child"
                                    :key="index"
                                >
                                    <div class="card-item" v-for="citem,j in item.citem" :key="j">
                                        <img :src="citem.icon" class="card-icon" />
                                        <div>{{citem.name}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="next-card pre-card-active" @click="nextPlan">
                            <span class="iconfont icon-arrow1"></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="identify-scene">
            <div class="scene-top">
                <div class="content-center">
                    <div class="scene-title">产业数字化打造数字经济新高地</div>
                    <div class="scene-desc">基于“标识解析+大数据+云计算+区块链+IOT” 可真正促进产业数字化转型升级</div>
                </div>
            </div>
            <div class="scene-tab">
                <div class="content-center">
                    <div class="ant-tabs ant-tabs-top tabs-title">
                        <div class="ant-tabs-nav">
                            <div class="ant-tabs-nav-wrap">
                                <div
                                    class="ant-tabs-nav-list"
                                    style="transform: translate(0px, 0px);"
                                >
                                    <div
                                        class="ant-tabs-tab"
                                        :class="sceneIndex == 0?' ant-tabs-tab-active':''"
                                        @click="changeSceneIndex(0)"
                                        style="margin-left:0;"
                                    >
                                        <div class="ant-tabs-tab-btn">生产制造</div>
                                    </div>
                                    <div
                                        class="ant-tabs-tab"
                                        :class="sceneIndex == 1?' ant-tabs-tab-active':''"
                                        @click="changeSceneIndex(1)"
                                    >
                                        <div class="ant-tabs-tab-btn">技术融合</div>
                                    </div>
                                    <div
                                        class="ant-tabs-tab"
                                        :class="sceneIndex == 2?' ant-tabs-tab-active':''"
                                        @click="changeSceneIndex(2)"
                                    >
                                        <div class="ant-tabs-tab-btn">企业数字化</div>
                                    </div>
                                    <div
                                        class="ant-tabs-ink-bar ant-tabs-ink-bar-animated"
                                        style="left: 0px; width: 98px;bottom:0;"
                                        :style="sceneLeft"
                                    ></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ant-tabs-content-holder">
                        <div class="ant-tabs-content ant-tabs-content-top">
                            <div class="ant-tabs-tabpane">
                                <div class="tabs-content">
                                    <div class="tabs-left">
                                        <div class="left-top">
                                            <div
                                                class="left-item left-item2"
                                                v-for="(item,i) in sceneTabs[sceneIndex].left.top"
                                                :key="i"
                                                @mouseover="hangyeAddAnimate2"
                                                @mouseout="hangyeRemoveAnimate2"
                                            >
                                                <img :src="item.image1" class="normal-img" alt />
                                                <img :src="item.image2" class="ex-img" alt />
                                                <div class="item-content">
                                                    <div class="item-title">
                                                        <span
                                                            class="title-icon iconfont iconweifeidianzicang20"
                                                        ></span>
                                                        <span
                                                            class="title-right iconfont icontuxing"
                                                        ></span>
                                                    </div>
                                                    <div class="item-information">
                                                        <div>{{item.name}}</div>
                                                        <div class="info-desc">{{item.desc}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="left-top">
                                            <div
                                                class="left-item left-item1"
                                                v-for="(item,j) in sceneTabs[sceneIndex].left.bottom"
                                                :key="j"
                                                @mouseover="hangyeAddAnimate1"
                                                @mouseout="hangyeRemoveAnimate1"
                                            >
                                                <img :src="item.image1" class="normal-img" alt />
                                                <img :src="item.image2" class="ex-img" alt />
                                                <div class="item-content">
                                                    <div class="item-title">
                                                        <span
                                                            class="title-icon iconfont iconweifeidianzicang20"
                                                        ></span>
                                                        <span
                                                            class="title-right iconfont icontuxing"
                                                        ></span>
                                                    </div>
                                                    <div class="item-information">
                                                        <div>{{item.name}}</div>
                                                        <div class="info-desc">{{item.desc}}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="tabs-right">
                                        <div
                                            class="right-title"
                                        >{{sceneTabs[sceneIndex].right.title}}</div>
                                        <div class="right-line"></div>
                                        <div
                                            class="right-content"
                                        >{{sceneTabs[sceneIndex].right.desc}}</div>
                                        <div class="right-btn" @click="showForm">了解详情</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="identify-page">
            <!-- 标识解析 -->
            <div class="indexPage1 indexModular">
                <div class="title">标识解析一站式为企业降本增效</div>
                <div class="explain">打通IOT层、平台层及应用层，为企业提供数字化转型服务</div>
                <div class="content">
                    <div class="contLeft">
                        <div
                            class="hoverActive"
                            v-for="(item,index) in pageTabs"
                            :key="index"
                            :class="['samllImg'+(index+1),pageIndex==index?'active':'']"
                        >
                            <div
                                class="mySprite"
                                @mousemove="onHoverSwiper(index)"
                                @mouseout="leaveHoverSwiper"
                            ></div>
                            <div
                                class="textBox"
                                @mousemove="stopHoverSwiper"
                                @mouseout="startHoverSwiper"
                            >
                                <div class="contenBox">
                                    <span>{{item.name}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contRight">
                        <div
                            class="contRightCont"
                            v-for="(item,index) in pageTabs"
                            :key="index"
                            :class="pageIndex==index?'active':''"
                        >
                            <div class="contTitle">
                                <div class="contText">{{item.name}}</div>
                                <div class="contTitleBs" v-if="item.tui">
                                    <i class="iconfont icontuijianfenxiang"></i>推荐服务
                                </div>
                            </div>
                            <div class="contBody">{{item.desc}}</div>
                            <a class="hover-btn details" target="_blank" @click="showForm">
                                <span class="hover-content">了解详情</span>
                                <span style="background-color:#2A67FF" class="hover-span"></span>
                            </a>
                            <div class="contRightFoot">
                                <div class="contRightList">
                                    <span>平台</span>
                                    <ul>
                                        <li v-for="(icon,j) in item.tags" :key="j">
                                            <img
                                                src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/application-spot.png"
                                            />
                                            {{icon}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 解决方案 -->
            <div class="indexPage2 indexModular">
                <div
                    class="indexPage2Bg"
                    v-for="(item,index) in pageBgList"
                    :key="index"
                    :class="['indexSolution'+index,index==page2Index?'pageIsshow':'']"
                    :style="{backgroundImage:'url('+item+')'}"
                ></div>
                <div class="indexPage2-fixe">
                    <div class="title">跨行业·跨领域全栈智能解决方案</div>
                    <div class="explain">全方位覆盖企业全生命周期业务场景</div>
                    <div class="application_tabs">
                        <div class="application_tabs_left">
                            <div
                                class="application-div"
                                :class="page2Index==0?'applicationActive':''"
                                @mousemove="changePage2Index(0)"
                            >汽车装备行业</div>
                            <div
                                class="application-div"
                                :class="page2Index==1?'applicationActive':''"
                                @mousemove="changePage2Index(1)"
                            >药包材行业</div>
                            <div
                                class="application-div"
                                :class="page2Index==2?'applicationActive':''"
                                @mousemove="changePage2Index(2)"
                            >食品行业</div>
                            <div
                                class="application-div"
                                :class="page2Index==3?'applicationActive':''"
                                @mousemove="changePage2Index(3)"
                            >线缆行业</div>
                            <div
                                class="application-div"
                                :class="page2Index==4?'applicationActive':''"
                                @mousemove="changePage2Index(4)"
                            >模具行业</div>
                            <div class="application-more"><router-link :to="{path: '/solutionList'}">
                                <label>更多行业解决方案获取</label>
                                <i class="iconfont iconjiantou3-20"></i>
                            </router-link></div>
                        </div>
                        <div class="application_tabs_line"></div>
                        <div class="application_tabs_right">
                            <div class="application_tabs_content">
                                <div class="appli_content_position appli_content_show">
                                    <div class="content_title">{{solution[page2Index].title}}</div>
                                    <div class="content_explain">{{solution[page2Index].desc}}</div>
                                    <router-link :to="{path: 'solutionDetail', query: {id: solution[page2Index].id}}" target="_blank"><div class="content_detail">
                                        了解详情
                                        <i class="iconfont iconjiantou3-20"></i>
                                    </div></router-link>
                                    <div class="content_case">
                                        <div class="plan-right-tabicon">
                                            <div class="plan-right-tab">
                                                <el-tabs
                                                    v-model="solutionTabsIndex"
                                                    @tab-click="changeSolutionTabItem"
                                                >
                                                    <el-tab-pane
                                                        :label="item.name"
                                                        :name="item.id"
                                                        v-for="(item,index) in solution[solutionTabIndex].tabs"
                                                        :key="index"
                                                    ></el-tab-pane>
                                                </el-tabs>
                                            </div>
                                            <div class="plan-right-icon">
                                                <div
                                                    class="plan-right-iconitem"
                                                    v-for="(item,index) in solutionTabChild"
                                                    :key="index"
                                                ><a>
                                                    <!-- <img src alt /> -->
                                                    <div>{{item.name}}</div>
                                                </a></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 马上预约 -->
            <div class="indexPage3 indexModular">
                <div class="title">
                    为
                    <span>10000+</span>中小企业提供数字化转型服务
                </div>
                <div class="explain">在线提交您的需求，数百名专家团队将在30分钟内为您提供企业数字化转型解决方案,搭建可行的数字化管理体系</div>
                <a class="hover-btn appointment-button" target="_blank">
                    <span class="hover-content" @click="showForm">马上预约</span>
                    <span
                        style="background-color: rgba(255, 255, 255, 0.1); left: 146.5px; top: 64.125px;"
                        class="hover-span"
                    ></span>
                </a>
            </div>
            <!-- 地图 -->
            <div class="indexPage4 indexModular">
                <div class="title">沧州县域化布局持续推进，深耕工业互联网标识解析</div>
                <div
                    class="explain"
                >业务布局覆盖沧州18个县域产业及7个特色产业、以沧州工业互联网综合服务平台为底座，对企业生产经营中的人、机、物进行数字化、网络化和智能化管理，实现多纵蓝图。</div>
                <div class="content-map">
                    <!-- <div class="content-analytical">
                        <ul>
                            <li>
                                <span class="num-warp">
                                    <span>1</span>
                                    <span>,</span>
                                    <span>3</span>
                                    <span>6</span>
                                    <span>8</span>
                                    <span>,</span>
                                    <span>4</span>
                                    <span>2</span>
                                    <span>2</span>
                                    <span>,</span>
                                    <span>8</span>
                                    <span>2</span>
                                    <span>0</span>
                                </span>
                                <label>注册总量</label>
                            </li>
                            <li>
                                <span class="num-warp">
                                    <span>4</span>
                                    <span>6</span>
                                    <span>8</span>
                                    <span>,</span>
                                    <span>2</span>
                                    <span>1</span>
                                    <span>1</span>
                                </span>
                                <label>日均注册量</label>
                            </li>
                            <li>
                                <span class="num-warp">
                                    <span>1</span>
                                    <span>,</span>
                                    <span>0</span>
                                    <span>5</span>
                                    <span>3</span>
                                    <span>,</span>
                                    <span>1</span>
                                    <span>1</span>
                                    <span>8</span>
                                    <span>,</span>
                                    <span>8</span>
                                    <span>7</span>
                                    <span>2</span>
                                </span>
                                <label>解析总量</label>
                            </li>
                            <li>
                                <span class="num-warp">
                                    <span>1</span>
                                    <span>7</span>
                                    <span>,</span>
                                    <span>2</span>
                                    <span>2</span>
                                    <span>2</span>
                                </span>
                                <label>日均解析量</label>
                            </li>
                        </ul>
                    </div>-->
                    <div class="content-map-img">
                        <div class="content-map-list">
                            <div class="mapRenqiu mapCity">
                                <label class="content-city">任丘</label>
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mapSuning mapCity content-city-cq">
                                <label class="content-city">肃宁</label>
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mapHejian mapCity">
                                <label class="content-city">河间</label>
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mapXianxian mapCity">
                                <label class="content-city">献县</label>
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mapBotou mapCity">
                                <label class="content-city">泊头</label>
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="mapQingxian mapCity">
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <label class="content-city">青县</label>
                            </div>
                            <div class="mapCangxian mapCity">
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <label class="content-city">沧县</label>
                            </div>
                            <div class="mapNanpi mapCity">
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <label class="content-city">南皮</label>
                            </div>
                            <div class="mapDongguang mapCity">
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <label class="content-city">东光</label>
                            </div>
                            <div class="mapWuqiao mapCity">
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <label class="content-city">吴桥</label>
                            </div>
                            <div class="mapHuanghua mapCity">
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <label class="content-city">黄骅</label>
                            </div>
                            <div class="mapHaixing mapCity">
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <label class="content-city">海兴</label>
                            </div>
                            <div class="mapMengcun mapCity">
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <label class="content-city">孟村</label>
                            </div>
                            <div class="mapYanshan mapCity">
                                <div class="content-site">
                                    <div class="circle-nav">
                                        <span class="circle3">
                                            <span class="circle2">
                                                <span class="circle1"></span>
                                            </span>
                                        </span>
                                    </div>
                                </div>
                                <label class="content-city">盐山</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 开发者中心 -->
            <!-- <div class="indexPage5 indexModular"></div> -->
        </div>
        
        <contact-alert :showVisible="showVisible" @child-event="parentEvent"></contact-alert>
    </div>
</template>

<script>
import contactAlert from '@/components/contactAlert'
export default {
    data() {
        return {
            showVisible: false,
            map_bg_new: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/image/map_bg_new.png",
            nodeList: [
                {
                    id: 1,
                    child: [
                        "国家级工业互联网标识解析二级节点运营基地",
                        "国家级工业互联网标识解析标准化白皮书编制单位之一",
                        "基于标识解析的产业大数据平台建设运营基地",
                    ],
                },
                {
                    id: 2,
                    child: [
                        "河北省工信厅指定云应用服务商",
                        "河北省工业诊所",
                        "河北省指定的企业上云服务商",
                    ],
                },
                {
                    id: 1,
                    child: [
                        "国家级工业互联网标识解析二级节点运营基地",
                        "国家级工业互联网标识解析标准化白皮书编制单位之一",
                        "基于标识解析的产业大数据平台建设运营基地",
                    ],
                },
                {
                    id: 2,
                    child: [
                        "河北省工信厅指定云应用服务商",
                        "河北省工业诊所",
                        "河北省指定的企业上云服务商",
                    ],
                },
            ],
            planIndex: 0,
            planSwiperIndex: 0,
            planList: [
                {
                    title: "帮助企业接入标识解析平台，对应用数据进行标识赋码，对标识数据进行管理，以便进行标识数据的追溯查询",
                    child: [
                        {
                            id: 1,
                            citem: [
                                {
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_01.png",
                                    name: "账号注册认证",
                                },
                                {
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_02.png",
                                    name: "前缀注册开通",
                                },
                                {
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_03.png",
                                    name: "基础信息管理",
                                },
                                {
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_04.png",
                                    name: "生码管理",
                                },
                                // {
                                //     icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                //     name: "标识注册",
                                // },
                                // {
                                //     icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                //     name: "标识注册",
                                // },
                            ],
                        },
                        {
                            id: 1,
                            citem: [
                                {
                                    icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                    name: "标识注2册",
                                },
                                {
                                    icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                    name: "标识注2册",
                                },
                                {
                                    icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                    name: "标识注2册",
                                },
                                {
                                    icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                    name: "标识注册",
                                },
                                {
                                    icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                    name: "标识注册",
                                },
                                {
                                    icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                    name: "标识注册",
                                },
                            ],
                        },
                        {
                            id: 1,
                            citem: [
                                {
                                    icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                    name: "标识注3册",
                                },
                                {
                                    icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                    name: "标3注册",
                                },
                                {
                                    icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                    name: "标识3注册",
                                },
                                {
                                    icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                    name: "标识注册",
                                },
                                {
                                    icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                    name: "标识注册",
                                },
                                {
                                    icon: "https://www.360yunxi.com/static/images/newImg/index/plat-card1.png",
                                    name: "标识注册",
                                },
                            ],
                        },
                    ],
                },
                {
                    title: "打造价值闭环的工业互联网标识解析一站式服务体系",
                    child: [
                        {
                            citem: [
                                {
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_05.png",
                                    name: "生产追溯",
                                },
                                {
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_06.png",
                                    name: "全生命周期管理",
                                },
                                {
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_07.png",
                                    name: "供应链管理",
                                },
                                {
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_08.png",
                                    name: "设备管理",
                                },
                            ]
                        },
                    ],
                },
                {
                    title: "在线化、高安全、可集成，全面满足企业内外部办公需求",
                    child: [
                        {   
                            citem: [
                                { 
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_09.png",
                                    name: "数字资产管理",
                                },
                                { 
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_10.png",
                                    name: "标识结算",
                                },
                                { 
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_11.png",
                                    name: "身份认证",
                                },
                                { 
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_12.png",
                                    name: "物流监控",
                                },
                                { 
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_13.png",
                                    name: "产品追溯",
                                },
                                { 
                                    icon: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/biaoshi/biaoshi_14.png",
                                    name: "防伪防窜货",
                                }
                            ]
                        },
                    ],
                },
            ],
            left: 0,
            sceneIndex: 0,
            sceneLeft: "0px",
            sceneTabs: [
                {
                    left: {
                        top: [
                            {
                                icon: "",
                                name: "一物一码身份证",
                                desc: "标识的核心就是识别物的身份。防止恶意复制和篡改，保证工业数据的合法性和安全性，服务数据流通。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/environment1.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/environment1-ex.png",
                            },
                            {
                                icon: "",
                                name: "数字资产共享与交付",
                                desc: "将企业内人员信息、设备的身份信息、设计图纸、工艺流程图、安装维修手册等文档，通过工业互联网标识，进行数字化交付，做信息的有效共享。通过电子化备案，缩短检索档案的时间，大大降低管理者的工作强度，并让档案管理者摆脱了传统手工式工作。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/environment2.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/environment2-ex.png",
                            },
                            {
                                icon: "",
                                name: "设备故障预测及健康管理",
                                desc: "对核心零部件赋予标识，将核心零部件与整机组设备信息相关联，实现设备故障主动预测维修。通过对设备工作参数、环境参数、产品质量数据全面采集，建立设备性能模型，进行设备状态分析和效能分析，提高设备利用率和生产产品质量，降本增效。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/environment3.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/environment3-ex.png",
                            },
                        ],
                        bottom: [
                            {
                                icon: "",
                                name: "智能化生产管理",
                                desc: "产品追溯涉及产品从设计、生产、制造、运输、服务到回收的整个生命周期，利用标识技术记录和查询产品信息，掌握产品的数据，促进企业所有信息数据互联互通，实现企业资源优化配置，提高产品质量、生产效率和企业的核心竞争力。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/environment4.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/environment4-ex.png",
                            },
                            {
                                icon: "",
                                name: "智能化仓储管理",
                                desc: "通过标识管理原材料，直接扫描标识码入库，系统根据扫描的物料标识自动匹配关联采购订单，便于后续扫码出库领用。有效的避免了人工录入的失误，实现数据无损传递和快速录入。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/environment5.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/environment5-ex.png",
                            },
                            {
                                icon: "",
                                name: "产品追溯+防伪防窜",
                                desc: "在产品流通环节中，通过标识码在各个环节自建追溯系统，上游企业可以获得产品的质量反馈信息，下游企业可以对产品质量进行追溯，终端用户通过扫码便可判断产品真伪，并获取企业信息、产品信息、扫描次数、销售区域等一系列追溯信息，提升企业品牌信任度。  ",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/environment6.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/environment6-ex.png",
                            },
                        ],
                    },
                    right: {
                        title: "工业制造应用场景",
                        desc: "帮助制造企业实现企业内部从设备、控制系统及信息系统的互联和数据融合，进一步可以实现企业间以及供应链上下游的互联和数据融合，帮助制造企业打造数字化转型的基础设施。",
                    },
                },
                {
                    left: {
                        top: [
                            {
                                icon: "",
                                name: "5G+区块链",
                                desc: "通过标识技术与5G高速、泛在和低延时的特点相结合，实现标识数据的快速读取和上传，大幅度提升标识解析的速度。通过标识，促进跨链或标识对象间的互操作，结合区块链的分布式信任管理机制，有助于建立数字空间信任链条，推动多边共管共治的标识治理体系。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/product1.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/product1-ex.png",
                            },
                            {
                                icon: "",
                                name: "大数据+边缘计算",
                                desc: "通过标识汇聚大量企业生产制造、商贸流通和市场营销等数据，进行数据挖掘和分析，为企业、行业及政府提供决策支持。边缘计算进行数据捕获和处理，可提供更广的标识数据面，同时为标识解析过程贡献分布式算力。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/product2.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/product2-ex.png",
                            },
                            {
                                icon: "",
                                name: "AR/VR+人工智能+物联网",
                                desc: "通过对虚拟物体的数字化，实现增强现实和虚拟仿真的体验效果。通过标识扫码关联，实现快速互联和数据采集上云，通过标识对人工智能语言进行标注，设计机器深度学习环境，推动人工智能与机器人技术的深度融合，提升智能机器人的技术与应用水平。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/product3.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/product3-ex.png",
                            },
                        ],
                        bottom: [
                            {
                                icon: "",
                                name: "搜索引擎",
                                desc: "国家工业互联网标识顶级节点和二级节点汇聚了大量的工业数据，将标识和工业搜索结合，类似工业制造行业的百度搜索引擎，能查询所有接入行业的标识信息。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/product4.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/product4-ex.png",
                            },
                            {
                                icon: "",
                                name: "标识+孪生制造",
                                desc: "利用数字孪生开发平台、数字孪生场景构建平台及数字运行中心，通过标识在工业互联网上实现各自孪生体的互联互通，从而以开放运营的模式促进产业协同发展，打造基于互联网标识的数字孪生生态。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/product5.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/product5-ex.png",
                            },
                            {
                                icon: "",
                                name: "标识+导航",
                                desc: "标识解析可以为北斗系统中的设备创建唯一身份ID，可拓展北斗应用领域和使用场景，加速北斗相关公共交通、运输管理、安全监管等关联产业的网络化运作。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/product6.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/product6-ex.png",
                            },
                        ],
                    },
                    right: {
                        title: "高新技术",
                        desc: "共同构筑新型基础设施，支撑了更大范围、更深程度、更高水平的数据开发与利用，使数据成为新的生产要素，支撑智能制造的发展",
                    },
                },
                {
                    left: {
                        top: [
                            {
                                icon: "",
                                name: "营销管理",
                                desc: "通过企业标识应用积累企业私域流量，增加客户粘度，实现市场占有数据分析、出入库、物流、经销商管理等数据采集及分析。精准查看每款产品扫码趋势、直观查看系统数据概况，实现精准营销。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/elec1.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/elec1-ex.png",
                            },
                            {
                                icon: "",
                                name: "质量追溯",
                                desc: "基于工业互联网标识解析体系，自主研发产品质量迈链追溯系统，围绕企业的产品品质管控、品牌建设、销售模式等打造云端营销生态体系系统，建立“一物一码 ”身份证。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/elec2.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/elec2-ex.png",
                            },
                            {
                                icon: "",
                                name: "数据管理",
                                desc: "通过数字化管理，生产智能排产、设备数据采集，可实时监控车间生产过程及设备运行状态，人、机、物的高效协同，实现车间数字化，便于工业企业管理层决策。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/elec3.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/elec3-ex.png",
                            },
                        ],
                        bottom: [
                            {
                                icon: "",
                                name: "系统集成",
                                desc: "通过工业软件供应商对现有的企业管理系统，进行标识编码，实现软件系统具备工业互联网标识功能，更有利于系统集成和应用推广。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/elec4.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/elec4-ex.png",
                            },
                            {
                                icon: "",
                                name: "金融服务",
                                desc: "通过标识收集企业生产、供应实时数据，提供给金融机构作为授信依据，解决中小企业融资难、融资贵问题， ",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/elec5.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/elec5-ex.png",
                            },
                            {
                                icon: "",
                                name: "安全监管",
                                desc: "可以实时追踪危化品的流向和最新动态，实现更有效、更及时的监管和预防。将区域性特色地标产品标识化、场景化、体验化，提升品牌形象和产品价值。",
                                image1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/elec6.png",
                                image2: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/elec6-ex.png",
                            },
                        ],
                    },
                    right: {
                        title: "数字化升级",
                        desc: "打破信息孤岛，促进工业互联网数据互通，实现数据和信息在各要素间、各系统间的无缝传递，实现“跨企业-跨行业-跨地区-跨国家”数据管理和共享。",
                    },
                },
            ],
            pageIndex: 0,
            pageTabs: [
                {
                    tui: 1,
                    name: "智能化仓储管理",
                    desc: "有效的避免了人工录入的失误，实现数据无损传递和快速录入。",
                    tags: [
                        "智能仓储",
                    ],
                },
                {
                    name: "一物一码身份证",
                    desc: "标识的核心就是识别物的身份。防止恶意复制和篡改，保证工业数据的合法性和安全性，服务数据流通。",
                    tags: ["标识解析"],
                },
                {
                    name: "数字资产管理",
                    desc: "将企业内人员信息、设备的身份信息、设计图纸、工艺流程图、安装维修手册等文档，通过标识，进行数字化交付，做信息的有效共享。",
                    tags: ["全生命周期管理（PLM）"],
                },
                
                {
                    tui: 1,
                    name: "设备管理",
                    desc: "通过对设备工作参数、环境参数、产品质量数据全面采集，建立设备性能模型，进行设备状态分析和效能分析，提高设备利用率和生产产品质量，降本增效。",
                    tags: ["设备管理"],
                },
                {
                    tui: 1,
                    name: "数据采集",
                    desc: "通过标识技术与5G高速、泛在和低延时的特点相结合，实现标识数据的快速读取和上传",
                    tags: ["数据采集"],
                },
                {
                    name: "人工智能",
                    desc: "通过标识对人工智能语言进行标注，设计机器深度学习环境，推动人工智能与机器人技术的深度融合，提升智能机器人的技术与应用水平",
                    tags: ["物联网"],
                },
                {
                    name: "企业上云",
                    desc: "通过工业软件供应商对现有的企业管理系统，进行标识编码，实现软件系统具备工业互联网标识功能，更有利于系统集成和应用推广。",
                    tags: ["SaaS应用"],
                },
            ],
            swiperTimer: null,
            page2Index: 0,
            pageBgList: [
                "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/solution-bg0.png",
                "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/solution-bg1.png",
                "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/solution-bg2.png",
                "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/solution-bg3.png",
            ],
            solutionTabChild: [
                {
                    name: "采购管理",
                    icon: "",
                },
                {
                    name: "生产管理",
                    icon: "",
                },
                {
                    name: "售后管理",
                    icon: "",
                },
                {
                    name: "VR可视化",
                    icon: "",
                },
                {
                    name: "数据决策大屏",
                    icon: "",
                },
                {
                    name: "企业级工业互联网",
                    icon: "",
                },
                {
                    name: "云MES",
                    icon: "",
                },
            ],
            solutionTabIndex: 0,
            solutionTabsIndex: "all",
            solution: [
                {
                    tabname: "汽车装备行业",
                    thumb: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/image/partner01.png",
                    title: "汽车装备行业解决方案",
                    desc: "基于工业互联网标识解析体系，集成MES系统，解决供应商原料标识不统一问题。数字化生产管控让生产过程透明化，优化生产流程，提升产能利用率，为决策提供数据化依据，提升客户满意度。",
                    tags: ["一物一码", "数据驱动", "协调共享", "智能引领"],
                    id: '1518759602629185538',
                    tabs: [
                        {
                            name: "全部",
                            id: "all",
                            child: [
                                {
                                    name: "生产管理（云MES）",
                                    icon: "",
                                },
                                {
                                    name: "VR可视化决策平台",
                                    icon: "",
                                },
                                {
                                    name: "数据管理大屏",
                                    icon: "",
                                },
                                {
                                    name: "企业级工业互联网",
                                    icon: "",
                                }
                            ],
                        },
                        {
                            name: "标识解析",
                            id: "jiexi",
                            child: [
                                {
                                    name: "生产管理（云MES）",
                                    icon: "",
                                },
                            ],
                        },
                        {
                            name: "大数据",
                            id: "shuju",
                            child: [
                                {
                                    name: "VR可视化决策平台",
                                    icon: "",
                                },
                                {
                                    name: "数据管理大屏",
                                    icon: "",
                                },
                            ],
                        },
                        {
                            name: "其他",
                            id: "other",
                            child: [
                                {
                                    name: "企业级工业互联网",
                                    icon: "",
                                },
                            ],
                        },
                    ],
                },
                {
                    tabname: "药包材行业",
                    thumb: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/image/partner02.png",
                    title: "药包材行业解决方案",
                    desc: "通过网络数据采集技术，在外部网站获取药企销售信息，进行数据整理，得出所需数据以支撑市场活动。通过数字化管理平台生产智能排产实现设备数据采集，实现车间人、机、物的高效协同，实现车间数字化。",
                    tags: ["一物一码", "数据驱动", "协调共享", "智能引领"],
                    id: '1518793130641264641',
                    tabs: [
                        {
                            name: "全部",
                            id: "all",
                            child: [
                                {
                                    name: "云ERP",
                                    icon: "",
                                },
                                {
                                    name: "生产管理",
                                    icon: "",
                                },
                                {
                                    name: "VR可视化决策平台",
                                    icon: "",
                                },
                                {
                                    name: "数据管理大屏",
                                    icon: "",
                                },
                                {
                                    name: "企业级工业互联网",
                                    icon: "",
                                },
                                {
                                    name: "工业物联网",
                                    icon: "",
                                },
                            ],
                        },
                        {
                            name: "标识解析",
                            id: "jiexi",
                            child: [
                                {
                                    name: "云ERP",
                                    icon: "",
                                },
                                {
                                    name: "生产管理",
                                    icon: "",
                                },
                            ],
                        },
                        {
                            name: "大数据",
                            id: "dashuju",
                            child: [
                                {
                                    name: "VR可视化决策平台",
                                    icon: "",
                                },
                                {
                                    name: "数据管理大屏",
                                    icon: "",
                                },
                            ],
                        },
                        {
                            name: "其他",
                            id: "qita",
                            child: [
                                {
                                    name: "企业级工业互联网",
                                    icon: "",
                                },
                                {
                                    name: "工业物联网",
                                    icon: "",
                                },
                            ],
                        }
                    ],
                },
                {
                    tabname: "食品行业",
                    thumb: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/image/partner03.png",
                    title: "食品行业解决方案",
                    desc: "为产品赋予唯一数字身份信息，实现从原材料、生产、仓储、物流、经销商、终端消费者各个环节的数据采集与跟踪监控，实现产品的全生命周期管理，精确掌控产品的流量、流向、流速等信息。",
                    tags: ["一物一码", "数据驱动", "协调共享", "智能引领"],
                    id: '1518767026144612353',
                    tabs: [
                        {
                            name: "全部",
                            id: "all",
                            child: [
                                {
                                    name: "云ERP",
                                    icon: "",
                                },
                                {
                                    name: "生产管理（云MES）",
                                    icon: "",
                                },
                                {
                                    name: "VR可视化决策平台",
                                    icon: "",
                                },
                                {
                                    name: "数据管理大屏",
                                    icon: "",
                                },
                                {
                                    name: "企业级工业互联网",
                                    icon: "",
                                },
                                {
                                    name: "工业物联网",
                                    icon: "",
                                },
                            ],
                        },
                        {
                            name: "标识解析",
                            id: "jiexi",
                            child: [
                                {
                                    name: "云ERP",
                                    icon: "",
                                },
                                {
                                    name: "生产管理（云MES）",
                                    icon: "",
                                },
                            ],
                        },
                        {
                            name: "大数据",
                            id: "dashuju",
                            child: [
                                {
                                    name: "VR可视化决策平台",
                                    icon: "",
                                },
                                {
                                    name: "数据管理大屏",
                                    icon: "",
                                },
                            ],
                        },
                        {
                            name: "其他",
                            id: "qita",
                            child: [
                                {
                                    name: "企业级工业互联网",
                                    icon: "",
                                },
                                {
                                    name: "工业物联网",
                                    icon: "",
                                },
                            ],
                        }
                    ],
                },
                {
                    tabname: "线缆行业",
                    thumb: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/image/partner04.png",
                    title: "线缆行业解决方案",
                    desc: "通过统一标识标准化供应原料，可有效识别供应商。上游供应商，其产品赋码加入标识解析，产品物流运输等行为同样加入标识解析。通过标识，将关联起企业信息、材料信息、型号规格、生产批次、运输批次等。",
                    tags: ["一物一码", "数据驱动", "协调共享", "智能引领"],
                    id: '1518791088505950209',
                    tabs: [
                        {
                            name: "全部",
                            id: "all",
                            child: [
                                {
                                    name: "客户管理（云CRM）",
                                    icon: "",
                                },
                                {
                                    name: "采购管理",
                                    icon: "",
                                },
                                {
                                    name: "生产管理（云MES）",
                                    icon: "",
                                },
                                {
                                    name: "设备管理",
                                    icon: "",
                                },
                                {
                                    name: "VR可视化决策平台",
                                    icon: "",
                                },
                                {
                                    name: "数据管理大屏",
                                    icon: "",
                                },
                                {
                                    name: "工业物联网",
                                    icon: "",
                                },
                            ],
                        },
                        {
                            name: "标识解析",
                            id: "jiexi",
                            child: [
                                {
                                    name: "客户管理（云CRM）",
                                    icon: "",
                                },
                                {
                                    name: "采购管理",
                                    icon: "",
                                },
                                {
                                    name: "生产管理（云MES）",
                                    icon: "",
                                },
                                {
                                    name: "设备管理",
                                    icon: "",
                                },
                            ],
                        },
                        {
                            name: "大数据",
                            id: "dashuju",
                            child: [
                                {
                                    name: "VR可视化决策平台",
                                    icon: "",
                                },
                                {
                                    name: "数据管理大屏",
                                    icon: "",
                                },
                            ],
                        },
                        {
                            name: "其他",
                            id: "qita",
                            child: [
                                {
                                    name: "工业物联网",
                                    icon: "",
                                },
                            ],
                        },
                    ],
                },
                {
                    tabname: "模具行业",
                    thumb: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/image/partner05.png",
                    title: "模具行业解决方案",
                    desc: "以“标识”+“企业级互联网平台”+“应用系统”模式，集成了企业MES、CRM等，解决企业多系统孤岛问题。根据企业需求可定制化快速开发，统一标识体系建设，企业数字产品管理、设备管理可实现互联互通。",
                    tags: ["一物一码", "数据驱动", "协调共享", "智能引领"],
                    id: '1518489698722127874',
                    tabs: [
                        {
                            name: "全部",
                            id: 'all',
                            child: [
                                {
                                    name: "云ERP",
                                    icon: "",
                                },
                                {
                                    name: "生产管理（云MES）",
                                    icon: "",
                                },
                                {
                                    name: "VR可视化决策平台",
                                    icon: "",
                                },
                                {
                                    name: "数据管理大屏",
                                    icon: "",
                                },
                                {
                                    name: "企业级工业互联网",
                                    icon: "",
                                },
                                {
                                    name: "工业物联网",
                                    icon: "",
                                },
                            ]
                        },
                        {
                            name: "标识解析",
                            id: "jiexi",
                            child: [
                                {
                                    name: "云ERP",
                                    icon: "",
                                },
                                {
                                    name: "生产管理（云MES）",
                                    icon: "",
                                },
                            ],
                        },
                        {
                            name: "大数据",
                            id: "dashuju",
                            child: [
                                {
                                    name: "VR可视化决策平台",
                                    icon: "",
                                },
                                {
                                    name: "数据管理大屏",
                                    icon: "",
                                },
                            ],
                        },
                        {
                            name: "其他",
                            id: "qita",
                            child: [
                                {
                                    name: "企业级工业互联网",
                                    icon: "",
                                },
                                {
                                    name: "工业物联网",
                                    icon: "",
                                },
                            ],
                        },
                    ],
                },
            ],
            // showVisible: false,
            // title: "感谢联系沧州工业云",
            // subTitle: "请填写以下信息，我们将会在1个工作日内与您联系",
            // form: {
            //     industry: "",
            //     content: "",
            //     name: "",
            //     phone: "",
            //     company: "",
            // },
            checked: false,
            // rules: {
            //     name: [
            //         {
            //             required: true,
            //             message: "请输入联系人姓名",
            //             trigger: "blur",
            //         },
            //         {
            //             min: 3,
            //             max: 5,
            //             message: "长度在 3 到 5 个字符",
            //             trigger: "blur",
            //         },
            //     ],
            //     phone: [
            //         {
            //             required: true,
            //             message: "请输入联系电话",
            //             trigger: "blur",
            //         },
            //     ],
            //     content: [
            //         {
            //             required: true,
            //             message: "请填写咨询内容",
            //             trigger: "blur",
            //         },
            //     ],
            //     company: [
            //         {
            //             required: true,
            //             message: "请填写公司名称",
            //             trigger: "blur",
            //         },
            //     ],
            //     industry: [
            //         {
            //             required: true,
            //             message: "请选择行业",
            //             trigger: "change",
            //         },
            //     ],
            // },
            formLabelWidth: "80px",
        };
    },
    components: {
       contactAlert
    },
    methods: {
        parentEvent(data){
            this.showVisible = data
        },
        onSubmit(formName) {
            let _this = this;
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (!_this.checked) {
                        _this.$message.error("请阅读并同意用户协议和隐私声明");
                        return false;
                    }
                    _this.$api.fixedpage.postCantactUs().then().catch();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        showForm() {
            this.showVisible = true;
        },
        hangyeAddAnimate1() {
            $(".left-item1").css({ "max-width": "214.5px", width: "214.5px" });
        },
        hangyeRemoveAnimate1() {
            $(".left-item1").css({ "max-width": "287px", width: "287px" });
        },
        hangyeAddAnimate2() {
            $(".left-item2").css({ "max-width": "214.5px", width: "214.5px" });
        },
        hangyeRemoveAnimate2() {
            $(".left-item2").css({ "max-width": "287px", width: "287px" });
        },
        changePlan(i) {
            this.planIndex = i;
        },
        changePage2Index(i) {
            this.page2Index = i;
        },
        onHoverSwiper(i) {
            let _this = this;
            _this.pageIndex = i;
            clearInterval(_this.swiperTimer);
        },
        leaveHoverSwiper() {
            let _this = this;
            clearInterval(_this.swiperTimer);
            this.startSwiper();
        },
        stopHoverSwiper() {
            let _this = this;
            clearInterval(_this.swiperTimer);
        },
        startHoverSwiper() {
            let _this = this;
            clearInterval(_this.swiperTimer);
            this.startSwiper();
        },
        startSwiper() {
            let _this = this;
            _this.swiperTimer = setInterval(() => {
                if (_this.pageIndex == 6) {
                    _this.pageIndex = 0;
                } else {
                    _this.pageIndex++;
                }
            }, 5000);
        },
        changeSceneIndex(i) {
            this.sceneIndex = i;
            if (i == 0) {
                this.sceneLeft = "left: 0px;";
            }
            if (i == 1) {
                this.sceneLeft = "left: 162px;";
            }
            if (i == 2) {
                this.sceneLeft = "left: 325px;";
            }
        },
        changeSolutionTab(i) {
            this.solutionTabIndex = i;
            let tabs = this.solution[i].tabs;
            // solutionTabsIndex//all
            this.solutionTabChild = tabs[0].child;
        },
        changeSolutionTabItem(tab, event) {
            // this.solutionTabsIndex = i;
            let tabs = this.solution[this.solutionTabIndex].tabs;
            // solutionTabsIndex//all
            tabs.forEach((el) => {
                if (el.id == tab.name) {
                    this.solutionTabChild = el.child;
                }
            });
        },
        prevPlan(e) {
            let planList = this.planList;
            let childLen = planList[this.planIndex].child.length;
            if (childLen == 1) {
                return;
            } else {
                if (this.planSwiperIndex == 0) {
                    return;
                } else {
                    this.planSwiperIndex--;
                    this.left = $(".card-list-ul").animate({
                        left: "-" + this.planSwiperIndex * 1016 + "px",
                    });
                }
            }
        },
        nextPlan(e) {
            let planList = this.planList;
            let childLen = planList[this.planIndex].child.length;
            if (childLen == 1) {
                return;
            } else {
                if (this.planSwiperIndex == childLen - 1) {
                    return;
                } else {
                    this.planSwiperIndex++;
                    this.left = $(".card-list-ul").animate({
                        left: "-" + this.planSwiperIndex * 1016 + "px",
                    });
                }
            }
        },
    },
    mounted() {
        this.startSwiper();
    },
};
</script>

<style scoped lang="scss">
.identification {
    width: 100%;
    .hover-content {
        cursor: pointer;
    }
    .contactUs {
        .model-title {
            padding-left: 4px;
            padding-bottom: 30px;
            .title {
                font-size: 30px;
                font-weight: 400;
                line-height: 42px;
                color: #000;
                margin-bottom: 4px;
            }
            .sub-title {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #666;
            }
        }
        .el-input {
            width: 430px;
        }
        .el-textarea {
            width: 430px;
        }
        .el-select {
            width: 430px;
        }
        /deep/ .el-dialog {
            width: 750px;
        }
        /deep/ .el-form-item__content {
            width: 430px;
            text-align: center;
        }
        /deep/ .el-checkbox__label {
            font-size: 12px;
        }
        /deep/ .el-dialog__headerbtn {
            right: 25px;
            font-size: 32px;
            z-index: 9;
        }
        /deep/ .el-dialog__header {
            padding: 0;
        }
        /deep/ .el-dialog__body {
            width: 746px;
            max-height: 740px;
            padding: 40px 100px;
            background-color: #fff;
            border-radius: 8px;
            position: relative;
            box-sizing: border-box;
        }

        .sub_btn {
            width: 430px;
            margin-left: 70px;
        }
        .model-info-sub-tips {
            display: flex;
            font-size: 12px;
            color: #999;
            line-height: 20px;
            margin-bottom: 29px;
            text-align: left;
            .title {
                font-size: 14px;
                width: 75px;
            }
            .sub-title {
                flex: 1;
            }
        }
    }
    div {
        box-sizing: border-box;
    }
    .content-center {
        width: 1200px;
        margin: 0 auto;
    }
    .top-banner {
        .top-banner-bg {
            width: 100%;
            // height: 540px;
            position: relative;
            .max-content {
                width: 1200px;
                margin: 0 auto;
            }
            .banner-content {
                width: 100%;
                position: absolute;
                top: 0;
                bottom: 0;
                .banner-title {
                    font-size: 56px;
                    color: #32385d;
                }
                .banner-desc {
                    font-size: 22px;
                    color: #4c5565;
                    padding-top: 15px;
                }
                .banner-btn {
                    background: #2a67ff;
                    color: #fff;
                    font-size: 18px;
                    width: 160px;
                    height: 48px;
                    line-height: 48px;
                    text-align: center;
                    display: block;
                    margin-top: 70px;
                    cursor: pointer;
                    &:hover {
                        .hover-span {
                            width: 225%;
                            padding-top: 225%;
                        }
                    }
                    .hover-content {
                        z-index: 5;
                        transition: all 0.5s;
                    }
                    .hover-span {
                        position: absolute;
                        display: block;
                        content: "";
                        z-index: 0;
                        width: 0;
                        height: 0;
                        border-radius: 100%;
                        background-color: hsla(0, 0%, 100%, 0.1);
                        transform: translate(-50%, -50%);
                        transition: width 0.5s, padding-top 0.5s;
                    }
                }
                .banner-text {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 50%;
                    margin-top: -134px;
                }
            }
        }
        .top-banner-tags {
            padding-bottom: 30px;
            position: relative;
            .banner-operation {
                width: 100%;
                height: 144px;
                background: #fff;
                border-radius: 8px;
                box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                display: flex;
                align-items: center;
                justify-content: space-around;
                margin-top: -26px;
                .operation-item {
                    display: flex;
                    width: 304px;
                    height: 128px;
                    align-items: center;
                    cursor: pointer;
                    position: relative;
                    transition: all 0.8s;
                    padding: 0 16px;
                    .img-box {
                        width: 96px;
                        height: 96px;
                        border-radius: 8px;
                        background: #f3f8ff;
                        text-align: center;
                        line-height: 96px;
                        img {
                            vertical-align: middle;
                            border-style: none;
                        }
                        .operation-icon {
                            width: 64px;
                            height: 64px;
                            cursor: pointer;
                        }
                        .scan-code {
                            width: 96px;
                            height: 96px;
                            display: none;
                            position: absolute;
                            left: 0;
                            top: 0;
                        }
                    }
                    .info-box {
                        padding-left: 24px;
                        .info-title {
                            color: #333;
                            font-size: 24px;
                            font-weight: 700;
                            padding-top: 12px;
                        }
                        .info-desc {
                            padding-top: 8px;
                            color: #666;
                            font-size: 16px;
                        }
                    }
                }
                .operation-item:hover {
                    background: #f3f8ff;
                    border-radius: 8px;
                    .img-box {
                        background: #fff;
                        position: relative;
                    }
                    .scan-code {
                        display: block;
                    }
                }
            }
            .banner-node {
                margin-top: 34px;
                height: 24px;
                position: relative;
                .banner-nodelist {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    height: 24px;
                    margin-bottom: 10px;
                    .banner-nodeitem {
                        display: flex;
                        width: 33%;
                        color: #333;
                        font-size: 14px;
                        line-height: 24px;
                        .node-img {
                            width: 21px;
                            height: 24px;
                            margin-right: 8px;
                        }
                    }
                }
                /deep/ .el-carousel__item {
                    background-color: #fff;
                }
            }
        }
    }

    .identify-plan {
        width: 100%;
        height: 735px;
        background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/palt-bg.png")
            50% 50% no-repeat;
        .plan-title {
            text-align: center;
            padding-top: 48px;
            .title-info {
                font-size: 36px;
                color: #000;
            }
            .title-desc {
                font-size: 16px;
                color: #666;
                padding-top: 8px;
            }
        }
        .plan-type {
            font-size: 24px;
            color: #333;
            display: flex;
            margin-top: 40px;
            justify-content: center;
            .type-item {
                width: 30%;
                display: flex;
                flex-direction: column;
                align-items: center;
                cursor: pointer;
                position: relative;
                .type-img {
                    width: 160px;
                    height: 160px;
                    position: relative;
                }
                .type-title {
                    position: relative;
                }
                .type-tri {
                    margin-top: 30px;
                    width: 0;
                    height: 0;
                    border-left: 16px solid transparent;
                    border-right: 16px solid transparent;
                    border-bottom: 16px solid #fff;
                }
                .type-title1 {
                    color: #2a67ff;
                }
            }
            .type-item:hover {
                .type-img {
                    top: -10px;
                }
                .type-title {
                    top: -10px;
                }
            }
        }
        .plan-card {
            background: #fff;
            height: 270px;
            width: 100%;
            border-radius: 6px;
            text-align: center;
            box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.1);
            .card-title {
                font-size: 16px;
                color: #333;
                padding-top: 32px;
            }
            .card-list {
                margin: 0 auto;
                padding: 40px 24px 0;
                display: flex;
                align-items: center;
                position: relative;
                .el-carousel__item:nth-child(2n) {
                    background-color: #fff;
                }

                .el-carousel__item:nth-child(2n + 1) {
                    background-color: #fff;
                }
                .prev-card {
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    line-height: 40px;
                    cursor: pointer;
                    background-color: #fff;
                    box-shadow: 0 0 16px rgba(0, 110, 255, 0.32);
                    transform: rotate(180deg);

                    span {
                        color: #2a67ff;
                    }
                }
                .next-card {
                    width: 40px;
                    height: 40px;
                    border-radius: 20px;
                    line-height: 40px;
                    cursor: pointer;
                    background-color: #fff;
                    box-shadow: 0 0 16px rgba(0, 110, 255, 0.32);
                    span {
                        color: #2a67ff;
                    }
                }
                .prev-card:hover,
                .next-card:hover {
                    background: #2a67ff;
                    span {
                        color: #fff;
                    }
                }
                .card-list-box {
                    width: 1016px;
                    margin: 0 auto;
                    overflow: hidden;
                    position: relative;
                    height: 142px;
                    .card-list-ul {
                        overflow: hidden;
                        width: 9999px;
                        position: absolute;
                        top: 0;
                        left: 0;
                    }
                }
                .card-list-item {
                    display: flex;
                    justify-content: space-around;
                    height: 142px;
                    width: 1016px;
                    float: left;
                    .card-item {
                        width: 136px;
                        height: 136px;
                        border-radius: 4px;
                        box-shadow: 2px 0 10px 1px rgba(0, 112, 255, 0.1);
                        background: #fff;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        color: #666;
                        font-size: 18px;
                        cursor: pointer;
                        transition: all 0.5s;
                        position: relative;
                        .card-icon {
                            width: 43px;
                            height: 43px;
                            margin-top: 31px;
                            margin-bottom: 21px;
                        }
                    }
                }
            }
        }
    }

    .identify-scene {
        width: 100%;
        height: 755px;
        .scene-top {
            width: 100%;
            height: 400px;
            background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/scene-bg.png")
                50% 50% no-repeat;
            text-align: center;
            .scene-title {
                font-size: 36px;
                color: #000;
                padding-top: 48px;
            }
            .scene-desc {
                font-size: 16px;
                color: #666;
                padding-top: 8px;
            }
        }
        .scene-tab {
            position: relative;
            top: -235px;
            .ant-tabs {
                box-sizing: border-box;
                margin: 0;
                padding: 0;
                color: rgba(0, 0, 0, 0.85);
                font-size: 14px;
                font-variant: tabular-nums;
                line-height: 1.5715;
                list-style: none;
                font-feature-settings: "tnum", "tnum";
                display: flex;
                overflow: hidden;
                .ant-tabs-nav {
                    height: 81px;
                    margin: 0 auto;
                    position: relative;
                    display: flex;
                    flex: none;
                    align-items: center;
                    .ant-tabs-nav-wrap {
                        position: relative;
                        display: inline-block;
                        display: flex;
                        flex: auto;
                        align-self: stretch;
                        overflow: hidden;
                        white-space: nowrap;
                        transform: translate(0);
                        .ant-tabs-nav-list {
                            position: relative;
                            display: flex;
                            transition: transform 0.3s;
                        }
                        .ant-tabs-tab {
                            color: #333;
                            font-size: 20px;
                            margin-right: 48px;
                            margin-left: 32px;
                            padding: 22px 0;
                            position: relative;
                            display: inline-flex;
                            align-items: center;
                            background: transparent;
                            border: 0;
                            outline: none;
                            cursor: pointer;
                            .ant-tabs-tab-btn,
                            .ant-tabs-tab-remove {
                                outline: none;
                                transition: all 0.3s;
                            }
                        }
                        .ant-tabs-tab-active {
                            font-size: 24px;
                            color: #2a67ff;
                            width: 98px;
                            text-align: center;
                            font-weight: 400;
                        }
                    }
                }
            }
            .ant-tabs-ink-bar {
                background: #2a67ff;
                height: 3px;
                position: absolute;
                transition: width 0.3s, left 0.3s, right 0.3s;
            }
            .ant-tabs-content-holder {
                flex: auto;
                min-width: 0;
                min-height: 0;
                .ant-tabs-content {
                    display: flex;
                    width: 100%;
                    .ant-tabs-tabpane {
                        flex: none;
                        width: 100%;
                        outline: none;
                        .tabs-content {
                            display: flex;
                            padding-top: 32px;
                            justify-content: space-between;
                            border-top: 1px solid #b9c9de;
                            height: 480px;
                            .tabs-left {
                                width: 861px;
                                height: 440px;
                                border-radius: 6px;
                                background: #fff;
                                box-shadow: 3px 1px 18px #f1f1f1;
                                overflow: hidden;
                                .left-top {
                                    display: flex;
                                }
                                .left-item {
                                    height: 220px;
                                    max-width: 287px;
                                    position: relative;
                                    flex: 1;
                                    transition: all ease-in-out 0.3s;
                                    img {
                                        vertical-align: middle;
                                        border-style: none;
                                        width: 100%;
                                        height: 100%;
                                    }
                                    .normal-img {
                                        display: block;
                                    }
                                    .ex-img {
                                        display: none;
                                    }
                                    .item-content {
                                        position: absolute;
                                        top: 0;
                                        width: 100%;
                                        height: 100%;
                                        padding: 0 32px;
                                        cursor: pointer;
                                        .item-title {
                                            height: 88px;
                                            font-size: 24px;
                                            border-bottom: 1px solid #eee;
                                            display: flex;
                                            justify-content: space-between;
                                            align-items: flex-end;
                                            padding-bottom: 15px;
                                        }
                                        .item-information {
                                            font-size: 20px;
                                            color: #000;
                                            margin: 16px 0 30px;
                                            .info-desc {
                                                display: none;
                                            }
                                        }
                                    }
                                }
                                .left-item:hover {
                                    width: 432px !important;
                                    max-width: 432px !important;
                                    animation: identifier 0.4s;
                                    flex: auto;
                                    transition: all ease-in-out 0.3s;
                                    .normal-img {
                                        display: none;
                                    }
                                    .ex-img {
                                        display: block;
                                    }
                                    .item-content {
                                        background: rgba(0, 0, 0, 0.63922);
                                    }
                                    .item-information {
                                        font-size: 20px;
                                        color: #fff;
                                        margin: 16px 0 30px;
                                        .info-desc {
                                            font-size: 14px;
                                            padding-top: 10px;
                                            display: block;
                                        }
                                    }
                                }
                            }
                            .tabs-right {
                                width: 304px;
                                height: 440px;
                                border-radius: 6px;
                                background: #2a67ff;
                                box-shadow: 3px 1px 6px #d8def1;
                                padding: 48px 40px;
                                .right-title {
                                    font-size: 24px;
                                    color: #fff;
                                }
                                .right-line {
                                    width: 44px;
                                    height: 4px;
                                    background: #fa0;
                                    margin: 40px 0 24px;
                                }
                                .right-content {
                                    font-size: 14px;
                                    color: #fff;
                                    max-height: 132px;
                                    line-height: 28px;
                                }
                                .right-btn {
                                    width: 104px;
                                    height: 40px;
                                    text-align: center;
                                    line-height: 40px;
                                    margin-top: 48px;
                                    cursor: pointer;
                                    color: #fff;
                                    border: 1px solid #fff;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .identify-page {
        .indexModular {
            .title {
                color: #000;
                font-size: 36px;
                text-align: center;
                padding-top: 51px;
            }
            .explain {
                color: #666;
                font-size: 16px;
                text-align: center;
                margin-top: 25px;
            }
        }
        .indexPage1 {
            width: 100%;
            height: 705px;
            background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/application-bg.png")
                100% / cover no-repeat;
            .content {
                width: 1220px;
                margin: 80px auto 0;
                position: relative;
                height: 430px;
                .contLeft {
                    width: 640px;
                    height: 340px;
                    float: left;
                    background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/server.png")
                        100% / cover no-repeat;
                    position: relative;
                    margin-top: 94px;
                    .hoverActive {
                        position: absolute;
                        z-index: 0;
                        .mySprite {
                            width: 100px;
                            height: 100px;
                            z-index: 3;
                            animation: myAnimation 1.6s steps(1) infinite;
                            background-image: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/dotAnima.92291af4.png");
                            cursor: pointer;
                        }
                        .textBox {
                            position: absolute;
                            z-index: 5;
                            width: 212px;
                            height: 135px;
                            opacity: 0;
                            visibility: hidden;
                            background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/application-top.png")
                                no-repeat 50% / cover;
                            top: calc(50% - 5px);
                            left: calc(50% - 96px);
                            span {
                                font-size: 18px;
                                color: #2a67ff;
                                display: block;
                                font-weight: 700;
                                text-align: center;
                                margin-top: 27px;
                            }
                        }
                    }
                    .active {
                        .textBox {
                            width: 212px;
                            z-index: 5;
                            top: calc(50% - 135px);
                            visibility: visible;
                            transition: all 0.6s linear 0.5s;
                            opacity: 1;
                        }
                    }
                    .samllImg1 {
                        top: -38px;
                        left: 235px;
                    }
                    .samllImg5 {
                        bottom: 48px;
                        left: 248px;
                    }
                    .samllImg6 {
                        top: 52px;
                        left: 138px;
                    }
                    .samllImg7 {
                        top: 82px;
                        left: 306px;
                    }
                    .samllImg4 {
                        top: 167px;
                        left: 366px;
                    }
                    .samllImg2 {
                        top: 35px;
                        left: 424px;
                    }
                    .samllImg3 {
                        top: -8px;
                        left: 478px;
                    }
                }
                .contRight {
                    .contRightCont {
                        display: none;
                        width: 570px;
                        background-color: #fff;
                        padding-top: 32px;
                        box-shadow: 0 3px 16px #d8def1;
                        border-radius: 6px;
                        opacity: 0;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(7%, -50%);
                        .contTitle {
                            .contText {
                                color: #333;
                                font-size: 24px;
                                display: inline-block;
                                padding-left: 24px;
                                border-left: 4px solid #2a67ff;
                                height: 24px;
                                line-height: 20px;
                            }
                            .contTitleBs {
                                display: inline-block;
                                width: 104px;
                                height: 32px;
                                background: #fa0;
                                box-shadow: 0 3px 6px rgba(255, 170, 0, 0.24);
                                border-radius: 16px;
                                color: #fff;
                                text-align: center;
                                line-height: 30px;
                                margin-left: 16px;
                                position: relative;
                                top: -2px;
                                font-size: 14px;
                            }
                        }
                        .contBody {
                            color: #666;
                            padding: 16px 30px;
                            font-size: 14px;
                        }
                        .details {
                            width: 104px;
                            height: 40px;
                            background: #fff;
                            border: 1px solid #ddd;
                            color: #333;
                            display: inline-block;
                            line-height: 40px;
                            text-align: center;
                            margin: 14px 0 0 31px;
                        }
                        .contRightFoot {
                            background-color: #f2faff;
                            margin-top: 32px;
                            padding: 5px 0;
                            .contRightList:last-child {
                                border-bottom: 0;
                            }
                            .contRightList {
                                width: 510px;
                                margin: auto;
                                border-bottom: 1px solid #e3e8f3;
                                padding: 18px 0 10px;
                                overflow: hidden;
                                span {
                                    font-weight: 700;
                                    float: left;
                                    margin-top: 6px;
                                }
                                ul {
                                    margin-bottom: 0;
                                    overflow: hidden;
                                    box-sizing: border-box;
                                    li {
                                        height: 32px;
                                        background-color: #fff;
                                        border-radius: 16px;
                                        box-shadow: 0 3px 6px
                                            rgba(216, 222, 241, 0.24);
                                        float: left;
                                        margin-left: 15px;
                                        text-align: center;
                                        line-height: 32px;
                                        padding: 0 12px;
                                        margin-bottom: 10px;
                                        font-size: 14px;
                                        box-sizing: border-box;

                                        img {
                                            vertical-align: middle;
                                            width: 16px;
                                            height: 16px;
                                            position: relative;
                                            top: -2px;
                                            margin-right: 4px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .active {
                        display: inline-block;
                        opacity: 1;
                        animation: showcity1 0.8s linear;
                    }
                }
            }
        }
        .indexPage2 {
            width: 100%;
            height: 836px;
            position: relative;
            overflow: hidden;
            .indexSolution0,
            .indexSolution1,
            .indexSolution2,
            .indexSolution3 {
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }

            .indexPage2Bg {
                width: 100%;
                height: 100%;
                z-index: 1;
                position: absolute;
            }
            .pageIsshow {
                display: inline-block;
                opacity: 1;
                z-index: 2;
                animation: showcity3 0.6s linear;
            }
            .indexPage2-fixe {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: auto;
                z-index: 10;
                .title {
                    color: #fff;
                    font-size: 36px;
                    text-align: center;
                    padding-top: 51px;
                }
                .explain {
                    color: #fff;
                    font-size: 16px;
                    text-align: center;
                    margin-top: 25px;
                }
                .application_tabs {
                    width: 1150px;
                    padding-top: 78px;
                    margin: auto;
                    display: flex;
                    .application_tabs_left {
                        width: 306px;
                    }
                    .application_tabs_line {
                        width: 2px;
                        height: 548px;
                        background: hsla(0, 0%, 100%, 0.16);
                    }
                    .application-more {
                        margin-top: 86px;
                        overflow: hidden;
                        cursor: pointer;
                        label {
                            color: #fff;
                            font-size: 18px;
                            margin-right: 10px;
                            padding-left: 62px;
                            cursor: pointer;
                        }
                    }
                    .application-div {
                        color: #fff;
                        font-size: 20px;
                        height: 88px;
                        line-height: 88px;
                        padding-left: 60px;
                        label {
                            display: inline-block;
                            width: 40px;
                        }
                    }
                    .applicationActive {
                        transition: all 0.8s;
                        font-size: 24px;
                        background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/applicationActive.png")
                            100% / cover no-repeat;
                        cursor: pointer;
                        width: 308px;
                    }
                    .application_tabs_right {
                        flex: 1;
                        position: relative;
                        .application_tabs_content {
                            width: 780px;
                            height: 550px;
                            position: absolute;
                            right: 0;
                            overflow: hidden;
                            .appli_content_position {
                                z-index: 2;
                                // display: none;
                                transition: all 0.8s;
                                .content_title {
                                    font-size: 26px;
                                    font-family: Microsoft YaHei UI-Regular,
                                        Microsoft YaHei UI;
                                    font-weight: 400;
                                    color: #ffffff;
                                    line-height: 30px;
                                }
                                .content_explain {
                                    color: #fff;
                                    margin-top: 40px;
                                    font-size: 16px;
                                    font-family: Microsoft YaHei UI-Light,
                                        Microsoft YaHei UI;
                                    font-weight: 300;
                                    color: #ffffff;
                                    line-height: 30px;
                                }
                                .content_detail {
                                    font-size: 18px;
                                    font-family: Microsoft YaHei UI-Light,
                                        Microsoft YaHei UI;
                                    font-weight: 300;
                                    color: #ffffff;
                                    line-height: 30px;
                                    margin-top: 45px;
                                    margin-bottom: 50px;
                                }
                                .content_case {
                                    .plan-right-tabicon {
                                        .plan-right-tab {
                                            overflow: hidden;
                                            /deep/ .el-tabs__item {
                                                user-select: none;
                                                line-height: 44px;
                                                height: 44px;
                                                color: #fff;
                                            }
                                            /deep/ .el-tabs__nav-wrap::after {
                                                height: 1px;
                                                background-color: #f0f0f0;
                                            }
                                            /deep/ .el-tabs__item.is-active {
                                                color: #fff;
                                            }
                                            /deep/ .el-tabs__active-bar {
                                                background-color: #fff;
                                            }
                                        }
                                        .plan-right-icon {
                                            margin-left: -5px;
                                            margin-top: 5px;
                                            overflow: hidden;
                                            .plan-right-iconitem {
                                                margin-bottom: 5px;
                                                float: left;
                                                display: flex;
                                                padding-left: 22px;
                                                box-sizing: border-box;
                                                height: 60px;
                                                width: 190px;
                                                margin-left: 5px;
                                                transition: all 0.3s;
                                                cursor: pointer;
                                                background: hsla(
                                                    0,
                                                    0%,
                                                    100%,
                                                    0.16
                                                );
                                                border: 1px solid
                                                    hsla(0, 0%, 100%, 0.4);
                                                img {
                                                    display: block;
                                                    width: 28px;
                                                    height: 28px;
                                                    margin-top: 16px;
                                                    margin-right: 14px;
                                                }
                                                div {
                                                    font-size: 14px;
                                                    color: #fff;
                                                    line-height: 60px;
                                                    // width: 115px;
                                                    width: 145px;
                                                    overflow: hidden;
                                                    white-space: nowrap;
                                                    text-overflow: ellipsis;
                                                    transition: all 0.3s;
                                                }
                                            }
                                            .plan-right-iconitem:hover {
                                                background-color: #3460f2;
                                                border: 1px solid #2a67ff;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .indexPage3 {
            width: 100%;
            height: 278px;
            background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/appointment-bg.png")
                100% / cover no-repeat;
            .appointment-button {
                background-color: #2a67ff;
                font-size: 18px;
                width: 168px;
                height: 54px;
                text-align: center;
                line-height: 54px;
                display: block;
                margin: 30px auto;
                color: #fff;
            }
        }
        .indexPage4 {
            width: 100%;
            height: 950px;
            .content-map {
                width: 1010px;
                height: 800px;
                margin: auto;
                padding-top: 48px;
                .content-analytical {
                    margin: auto;
                    position: relative;
                    z-index: 1;
                    text-align: center;
                }
                .content-map-img {
                    width: 1011px;
                    height: 717px;
                    background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/image/map_bg_new.png") 50% /
                        cover no-repeat;
                    position: relative;
                    .content-map-list {
                        width: 1011px;
                        height: 717px;
                        .mapCity {
                            position: absolute;
                            .content-site {
                                display: inline-block;
                                position: relative;
                                .content-site-list {
                                    opacity: 0;
                                    width: 0;
                                    position: absolute;
                                    bottom: 32px;
                                    left: -82px;
                                    display: none;
                                    .content-site-div {
                                        background-color: #fff;
                                        padding: 0 10px;
                                        width: 200px;
                                        box-shadow: 0 3px 6px
                                            rgba(125, 139, 185, 48%);
                                        border-radius: 4px;
                                        ul {
                                            li {
                                                border-bottom: 1px solid #eee;
                                                height: 44px;
                                                line-height: 44px;
                                                overflow: hidden;
                                                span {
                                                    color: #666;
                                                    border-left: 2px solid
                                                        #3460f2;
                                                    height: 12px;
                                                    line-height: 12px;
                                                    display: inline-block;
                                                    padding-left: 8px;
                                                    font-weight: 700;
                                                    a {
                                                        color: #bbb;
                                                        font-size: 12px;
                                                        float: right;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                                .circle-nav {
                                    cursor: pointer;
                                    .circle3 {
                                        width: 36px;
                                        height: 36px;
                                        background: rgba(52, 96, 242, 0.4);
                                        border-radius: 50%;
                                        display: inline-block;
                                        text-align: center;
                                        animation: circle 2s linear 0.8s
                                            infinite;
                                        .circle2 {
                                            animation: circle 2s linear 0.8s
                                                infinite;
                                            width: 20px;
                                            height: 20px;
                                            background: hsla(0, 0%, 100%, 0.9);
                                            border-radius: 50%;
                                            display: inline-block;
                                            margin-top: 8px;
                                            .circle1 {
                                                width: 8px;
                                                height: 8px;
                                                background: rgba(
                                                    52,
                                                    96,
                                                    242,
                                                    0.8
                                                );
                                                border-radius: 50%;
                                                display: block;
                                                margin: 6px auto;
                                                animation: circle 2s linear 0.8s
                                                    infinite;
                                            }
                                        }
                                    }
                                }
                            }
                            .content-city {
                                font-size: 18px;
                                margin: 0 12px;
                                position: relative;
                                top: -4px;
                            }
                        }
                        .mapRenqiu {
                            top: 85px;
                            left: 65px;
                        }
                        .mapHejian {
                            top: 204px;
                            left: 128px;
                        }
                        .mapSuning {
                            top: 248px;
                            left: -47px;
                        }
                        .mapXianxian {
                            top: 328px;
                            left: 117px;
                        }
                        .mapBotou {
                            top: 387px;
                            left: 226px;
                        }
                        .mapQingxian {
                            top: 149px;
                            left: 456px;
                        }
                        .mapCangxian {
                            top: 270px;
                            left: 478px;
                        }
                        .mapNanpi {
                            top: 400px;
                            left: 508px;
                        }
                        .mapDongguang {
                            top: 487px;
                            left: 422px;
                        }
                        .mapWuqiao {
                            top: 613px;
                            left: 357px;
                        }
                        .mapHuanghua {
                            top: 210px;
                            right: 155px;
                        }
                        .mapHaixing {
                            top: 334px;
                            right: 63px;
                        }
                        .mapMengcun {
                            top: 378px;
                            right: 260px;
                        }
                        .mapYanshan {
                            top: 440px;
                            right: 151px;
                        }
                        .content-city-cq {
                            .circle3 {
                                width: 48px !important;
                                height: 48px !important;
                                background: rgba(52, 96, 242, 0.7) !important;
                                .circle2 {
                                    width: 30px !important;
                                    height: 30px !important;
                                    margin-top: 9px !important;
                                    background: hsla(
                                        0,
                                        0%,
                                        100%,
                                        0.95
                                    ) !important;
                                    .circle1 {
                                        width: 14px !important;
                                        height: 14px !important;
                                        margin-top: 8px !important;
                                        background: rgba(
                                            52,
                                            96,
                                            242,
                                            0.9
                                        ) !important;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .indexPage5 {
            width: 100%;
            height: 207px;
            background: url("https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/identifacation/indexPage3.png")
                50% / cover no-repeat;
        }
    }
}

@keyframes identifier {
    // 0% {
    //     max-width: 287px;
    // }

    // to {
    //     max-width: 432px;
    // }
}
@keyframes circle {
    0% {
        transform: scale(0.5);
        opacity: 1;
    }

    to {
        transform: scale(1.2);
        opacity: 0.8;
    }
}
@keyframes showcity1 {
    0% {
        display: none;
        opacity: 0;
        top: 70%;
    }

    to {
        display: inline-block;
        opacity: 1;
        top: 50%;
    }
}

@keyframes showcity3 {
    0% {
        opacity: 0.8;
    }

    to {
        opacity: 1;
    }
}

@keyframes myAnimation {
    0% {
        background-position: 0 0;
    }

    2.56% {
        background-position: -120px 0;
    }

    5.13% {
        background-position: -240px 0;
    }

    7.69% {
        background-position: -360px 0;
    }

    10.26% {
        background-position: -480px 0;
    }

    12.82% {
        background-position: -600px 0;
    }

    15.38% {
        background-position: -720px 0;
    }

    17.95% {
        background-position: -840px 0;
    }

    20.51% {
        background-position: -960px 0;
    }

    23.08% {
        background-position: -1080px 0;
    }

    25.64% {
        background-position: -1200px 0;
    }

    28.21% {
        background-position: -1320px 0;
    }

    30.77% {
        background-position: -1440px 0;
    }

    33.33% {
        background-position: -1560px 0;
    }

    35.90% {
        background-position: -1680px 0;
    }

    38.46% {
        background-position: -1800px 0;
    }

    41.03% {
        background-position: -1920px 0;
    }

    43.59% {
        background-position: -2040px 0;
    }

    46.15% {
        background-position: -2160px 0;
    }

    48.72% {
        background-position: -2280px 0;
    }

    51.28% {
        background-position: -2400px 0;
    }

    53.85% {
        background-position: -2520px 0;
    }

    56.41% {
        background-position: -2640px 0;
    }

    58.97% {
        background-position: -2760px 0;
    }

    61.54% {
        background-position: -2880px 0;
    }

    64.10% {
        background-position: -3000px 0;
    }

    66.67% {
        background-position: -3120px 0;
    }

    69.23% {
        background-position: -3240px 0;
    }

    71.79% {
        background-position: -3360px 0;
    }

    74.36% {
        background-position: -3480px 0;
    }

    76.92% {
        background-position: -3600px 0;
    }

    79.49% {
        background-position: -3720px 0;
    }

    82.05% {
        background-position: -3840px 0;
    }

    84.62% {
        background-position: -3960px 0;
    }

    87.18% {
        background-position: -4080px 0;
    }

    89.74% {
        background-position: -4200px 0;
    }

    92.31% {
        background-position: -4320px 0;
    }

    94.87% {
        background-position: -4440px 0;
    }

    97.44% {
        background-position: -4560px 0;
    }

    100.00% {
        background-position: -4680px 0;
    }
}
</style>

