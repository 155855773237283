import axios from '../http';
import {an,meng ,api} from '../global'
/**
 * author  LLLL
 * date    2022-01-19
 * 
 */


//获取用户信息
export const getUserInfo = (params) => {
    return axios({
        url: api + '/registerlogin/user/getInfo',
        method: 'get',
        params
    })
}

//获取解决方案详情页
export const getDetail = (params) => {
    return axios({
        url: api + '/blade-solution/solution/detail',
        method: 'get',
        params
    })
}

// 热门行业解决方案
export const getHotSolution = (params) => {
    return axios({
        url: api + '/blade-solution/solution/hot_solution',
        method: 'get',
        params
    })
}

// 解决方案详情页   其他解决方案
export const getOtherSolution = (params) => {
    return axios({
        // url: api + '/blade-solution/solutionClass/others',
        url: api + '/blade-solution/solution/others',
        method: 'get',
        params
    })
}

// 解决方案详情页   相关产品
export const getOtherProduct = (params) => {
    return axios({
        url: api + '/blade-solution/solution/relatedPro',
        method: 'get',
        params
    })
}

// 解决方案列表页  
export const getSolutionList = (params) => {
    return axios({
        url: api + '/blade-solution/solution/listFuzzy',
        method: 'get',
        params
    })
}

// 获取解决方案分类
export const getSolutionCategory = (params) => {
    return axios({
        url: api + '/blade-solution/solutionClass/lazy-list',
        method: 'get',
        params
    })
}

// 获取精选案例
export const getSelectSolution = (params) => {
    return axios({
        url: api + '/blade-caseCenter/caseDetail/selectedList',
        method: 'get',
        params
    })
}






/**
 * 案例列表 详情接口
 * 
*/

// 行业分类列表
export const getCaseHyCategory = (params) => {
    return axios({
        url: api + '/blade-solution/solutionClass/list',
        method: 'get',
        params
    })
}

// 产品分类列表
export const getCaseCpCategory = (params) => {
    return axios({
        url: api + '/blade-caseCenter/caseClass/list',
        method: 'get',
        params
    })
}

// 获取案例列表
export const getCaseList = (params) => {
    return axios({
        url: api + '/blade-caseCenter/caseDetail/listFuzzy',
        method: 'get',
        params
    })
}

// 获取案例推荐
export const getSelectCaseList = (params) => {
    return axios({
        url: api + '/blade-caseCenter/caseDetail/recommend',
        method: 'get',
        params
    })
}

// 获取案例详情
export const getCaseDetail = (params) => {
    return axios({
        url: api + '/blade-caseCenter/caseDetail/detail',
        method: 'get',
        params
    })
}

// 案例详情 产品推荐
export const getCaseSelectProduct = (params) => {
    return axios({
        // url: api + '/blade-caseCenter/caseDetail/relatedPro',
        url: api + '/blade-caseCenter/caseDetail/relatedPro',
        method: 'get',
        params
    })
}



