<template>
    <div class="details">
        <div class="banner">
            <img :src="productDetail.banner_image" alt="">
            <div class="banner-content">
                <div class="top">
                    <div>
                        <h3>{{ productDetail.name }}</h3>
                        <p v-show="JSON.parse(productDetail.operation_video).length > 0"><span class="iconfont icon-video"
                                @click="showVideo"></span>产品视频</p>
                    </div>
                    <!-- <p>构建全生命周期的数字化管理体系<br/>提升企业管理效益、客户满意度，实现可持续业绩增长</p>
                    <h4>低至<span>¥599</span>元/年起</h4> -->
                    <div class="pro-intro" v-html="productDetail.banner_text"></div>
                </div>
                <div class="bottom">
                    <ul>
                        <li class="btn1" v-show="productDetail.is_sell == 1" @click="buy">立即购买</li>
                        <li class="btn2"><a
                                href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id="
                                onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即咨询</a>
                        </li>
                        <li class="btn3" v-show="productDetail.trial == 1" @click="trial">
                            <a style="cursor: pointer;">免费试用</a>
                            <!-- <template v-if="isLgoin" >
                                <a :href="productDetail.url? productDetail.url: 'https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id='" target="_blank">免费试用</a>
                            </template>

                            <template  v-if="!isLgoin">
                                <a :href="'/trial'" target="_blank">免费试用</a>
                            </template> -->

                        </li>
                    </ul>
                    <div v-show="productDetail.document_flag == 1">
                        <p><span class="iconfont icon-a-Frame22" @click="isGuide"></span>使用指南</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="pro-details">
            <div class="show-content" v-html="productDetail.content"></div>
        </div>

        <div class="case" v-show="productDetail.frame_image != ''">
            <h3>应用案例</h3>
            <img :src="productDetail.frame_image" alt="">
            <div>
                <!-- <img src="" alt=""> -->
                <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id="
                    onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即咨询</a>
            </div>
        </div>

        <div class="pro-tjian">
            <h3>产品推荐</h3>
            <ul>
                <li v-for="(item, index) in proRecom.slice(0, 4)" :key="index"><router-link
                        :to="{ path: 'details', query: { id: item.id } }" target="_blank">
                        <img :src="item.recommended" alt="">
                    </router-link></li>
            </ul>
        </div>

        <div class="adv">
            <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id="
                onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false"><img
                    :src="require('../../assets/pro-family/pic_8.jpg')" alt=""></a>
            <serve></serve>
        </div>

        <el-drawer title="选择套餐" :visible.sync="drawer" direction="rtl" :append-to-body="true" :before-close="handleClose">
            <div class="synopsis">
                <span class="iconfont icon-gonggao"></span>
                <p>内部数据、外部数据、市场数据的有效结合与分析应用，利用现代化营销手段，根据数据分析，<span>更科学合理的制度营销政策，减少无效市场行为，解决企业营销难题。</span></p>
            </div>
            <div class="alter-box">
                <el-form label-width="0" :model="proSpecs">
                    <el-form-item v-for="(item, index) in proSpecs.specs" :key="index + 'sp'">
                        <p>{{ item.spec_name }}</p>
                        <el-radio-group v-model="item.check_spec_val.spec_value" size="small"
                            @change="checkSpecEvent(item.check_spec_val.spec_value, index)">
                            <el-radio-button v-for="(itemv, indexk) in item.spec_values" :key="indexk + 'v'"
                                :label="itemv.id">{{ itemv.spec_value }}</el-radio-button>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item>
                        <div class="table">
                            <p>套餐规格说明</p>
                            <img :src="proSpecs.set_meal_info" alt="">
                        </div>
                    </el-form-item>
                </el-form>

                <div class="configure">
                    <h3>当前配置</h3>
                    <ul>
                        <li v-for="(item, index) in proSpecs.specs" :key="index + 'explain'">
                            <p><span>{{ item.spec_name }}：</span>{{ proSpecs.checkNow[index].spec_value }}</p>
                        </li>
                    </ul>
                </div>


                <div slot="footer" class="dialog-footer">
                    <div class="price">
                        <p>配置费用 <b>{{ checkProMes.price }}</b></p>
                    </div>
                    <el-button type="primary" @click="buyNow">立即购买</el-button>
                </div>
            </div>
        </el-drawer>
        <div v-show="isShowGuide">
            <video> </video>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
import child from '../../components/home-nav.vue'
import serve from '../../components/serve.vue'
import Cookie from 'js-cookie'
export default {
    data() {
        return {

            drawer: false,
            isShowGuide: false,
            bannerImg: '',
            productDetail: {},
            proRecom: [],
            buyPackageForm: {
                region: 'inland',
                packageSelect: 'ordinary',
                networkType: 'proper',
                duration: 'aYear',
                num: 1,
            },
            proSpecs: {},
            checkSpecsId: '',
            checkProMes: {
                price: '',
                id: ''
            },
            userId: '',
            allocation: ''
        }
    },
    components: {
        serve,
        child
    },
    methods: {
        // 试用
        trial() {
            if (Cookie.get('token')) {
                if (this.productDetail.url) {
                    this.$router.push({
                        path: this.productDetail.url
                    })
                } else {
                    window.open('https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=', '_blank', 'width=820,height=610,top=200,left=550')
                }
            } else {
                this.$router.push({
                    path: '/trial'
                })
            }
        },

        // 购买弹框
        buy() {
            this.drawer = true
        },

        // 使用指南
        isGuide() {
            this.isShowGuide = true
        },

        // 弹框关闭
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        // 计数器
        handleChange() { },

        // 获取产品详情
        getProductDetail() {
            var json = {
                id: this.$route.query.id
            }
            this.$api.product.productDetail(json).then(res => {
                if (res.code == 200) {
                    this.productDetail = res.data
                    this.bannerImg = res.data.banner_image
                    // this.htmlContent = res.data.synopsis
                    this.proSpecs = res.data
                    this.proSpecs.checkNow = [{ spec_value: '' }, { spec_value: '' }]
                } else {
                    this.$message.error(res.msg)
                }
            })
        },

        // 产品推荐
        getProRecordList() {
            this.$api.product.proRecommend({}).then(res => {
                this.proRecom = res.data.records
            })
        },

        // 获取选中的规格
        checkSpecEvent(e, index) {
            this.$forceUpdate()
            this.proSpecs.specs[index].spec_values.map((v, k) => {
                if (e == v.id) {
                    this.proSpecs.checkNow.splice(index, 1, v)
                    this.proSpecs.checkNow[index].spec_name = this.proSpecs.specs[index].spec_name
                }
            })

            if (this.proSpecs.checkNow[0].spec_value != '' && this.proSpecs.checkNow[1].spec_value != 0) {
                this.checkSpecsId = this.proSpecs.checkNow[0].id + '_' + this.proSpecs.checkNow[1].id

                try {
                    this.proSpecs.skus.map((v, k) => {
                        if (v.value_ids == this.checkSpecsId) {
                            this.checkProMes.price = v.sku_price
                            this.checkProMes.id = v.id
                            // this.
                            throw new Error("false")
                        }
                    })
                } catch (e) {
                    console.log(e)
                }

            }
        },

        // getUserIdNow(userid) {
        //     this.userId = userid
        //     // Cookies.set({'userId': userid})
        // },

        // 立即购买
        buyNow() {
            if (Cookies.get('token')) {
                if (this.checkProMes.id != '') {
                    this.allocation = ''

                    this.proSpecs.checkNow.map(v => {
                        this.allocation = this.allocation + v.spec_name + ':' + v.spec_value + ';'
                    })

                    var json = {
                        user_id: this.$store.state.userId,
                        proName: this.productDetail.name,
                        allocation: this.allocation,
                        spec_product_id: this.checkProMes.id,
                        price: this.checkProMes.price
                    }

                    this.$store.commit('getOrderDetail', json)
                    this.$router.push({ path: '/softwareMarket/orderConfirm' })
                } else {
                    this.$message.warning('请选择规格')
                }
            } else {
                this.$message.warning('请登录')
            }
        },

        // 视频展示
        showVideo() {

        }
    },
    computed: {
        orderList() {
            return this.$store.state.orderList
        }
    },
    mounted() {
        this.getProductDetail()
        this.getProRecordList()
    },
}
</script>
<style lang="scss" scoped>
.el-dialog {
    width: 960px;
    margin-top: 100px !important;

    .el-dialog__header {
        padding: 20px 0;
        font-weight: 600;
    }
}

.el-dialog__body {
    height: 600px;
    overflow-y: auto;
    padding: 0 30px;
    margin-bottom: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
}

.el-drawer {
    width: 600px !important;
}

.el-drawer__body {
    height: 600px;
    overflow-y: auto;
    padding: 0 30px;
    margin-bottom: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
    position: relative;
}

.dialog-footer {
    width: 100%;
    height: 90px;
    box-shadow: 0px -2px 15px 1px rgba(55, 99, 170, 0.15);
    background: #f6f8f8;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: 0;

    b {
        font-family: 'Roboto Mono-Regular';
    }
}

.el-dialog__footer {
    padding-bottom: 10px !important;
}

.el-form-item__label {
    text-align: left;
}

.el-input__inner {
    height: 32px !important;
    width: 180px !important;
}

.el-input-number {
    line-height: 30px !important;
}

.el-radio-button:last-child .el-radio-button__inner {
    border-radius: 0;
}

.el-radio-button:first-child .el-radio-button__inner {
    border-radius: 0;
}

.el-radio-button--small .el-radio-button__inner {
    margin-right: 10px;
    border-left: 1px solid #DCDFE6;
    width: 148px;
    height: 32px;
}

.el-radio-button__orig-radio:checked+.el-radio-button__inner {
    background-color: #006DFF;
    border-color: #006DFF;
}

.alter-box {
    .el-button--primary {
        // margin-top: 8px;
        width: 140px;
        height: 40px;
        background-color: #1F51C8;
        border-color: #1F51C8;
        margin-right: 50px;
    }

    .el-button--primary:hover {
        background-color: #006DFF;
        border-color: #006DFF;
    }
}


.details {}
</style>

<style lang="scss" scoped>
.details {
    width: 100%;

    // margin-top: 62px;
    .banner {
        width: auto;
        height: 450px;
        position: relative;
        overflow: hidden;

        >img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .banner-content {
            width: 1200px;
            position: absolute;
            left: 50%;
            top: 0;
            margin-left: -600px;
            ;

            .top {
                width: 720px;
                padding-top: 100px;

                div {
                    display: flex;
                    align-items: center;

                    h3 {
                        font-size: 42px;
                        color: #0b1d30;
                        margin-right: 13px;
                    }

                    p {
                        width: 100px;
                        height: 35px;
                        line-height: 35px;
                        text-align: center;
                        color: #ffffff;
                        background-color: #a5afb6;

                        span {
                            margin-right: 3px;
                        }
                    }
                }

                .pro-intro {
                    width: 720px;
                    height: 96px;
                    overflow: hidden;
                    margin-top: 15px;
                    margin-bottom: 50px;
                    line-height: 30px;
                }

                // >p{
                //     color: #757F92;
                //     line-height: 30px;
                //     font-size: 16px;
                //     margin-top: 15px;
                // }
                // h4{
                //     margin-top: 10px;
                //     margin-bottom: 50px;
                //     font-size: 14px;
                //     color: #757F92;
                //     font-weight: 500;
                //     span{
                //         font-size: 18px;
                //         color: #FF0000;
                //         font-weight: 600;
                //     }
                // }
            }

            .bottom {
                display: flex;
                font-size: 14px;
                color: #757F92;

                ul {
                    display: flex;
                    height: 40px;

                    li {
                        width: 122px;
                        height: 40px;
                        text-align: center;
                        line-height: 40px;
                        margin-right: 15px;
                        cursor: pointer;
                        color: #fff;
                        box-sizing: border-box;

                        a {
                            color: #ffffff;
                        }
                    }

                    .btn1 {
                        background-color: #1F51C8;
                    }

                    .btn2 {
                        background-color: #00AFF0;
                    }

                    .btn3 {
                        background-color: #fff;

                        a {
                            color: #000;
                        }
                    }

                    // li:hover{
                    //     a{
                    //         color: #ffffff;
                    //     }
                    //     background-color: #1F51C8;
                    //     color: #ffffff;
                    // }
                }

                div {
                    margin-left: 40px;
                    display: flex;
                    padding-top: 10px;

                    img {
                        width: 16px;
                        height: 16px;
                    }

                    p {
                        color: #757F92;
                        margin-left: 5px;

                        span {
                            margin-right: 3px;
                        }
                    }
                }
            }
        }
    }

    // .tuijian{
    //     padding: 60px 0 90px 0;
    //     >ul{
    //         width: 1200px;
    //         height: 208px;
    //         display: flex;
    //         justify-content: space-between;
    //         margin: 0 auto;
    //         >li{
    //             width: 380px;
    //             height: 208px;
    //             box-shadow: 0px 4px 15px 1px rgba(55, 99, 170, 0.15);
    //             h3{
    //                 height: 60px;
    //                 line-height: 60px;
    //                 padding: 0 30px;
    //                 font-size: 18px;
    //                 color: #ffffff;
    //                 background-color: #1662BC;
    //                 font-weight: 500;
    //             }
    //             div{
    //                 padding: 20px 30px;
    //                 ul{
    //                     li{
    //                         display: flex;
    //                         margin-bottom: 10px;
    //                         font-size: 14px;
    //                         p{
    //                             display: flex;
    //                             width: 70px;
    //                             justify-content: space-between;
    //                             span{
    //                                 color: #0B1D30;
    //                             }
    //                         }
    //                         span{
    //                             font-size: 14px;
    //                             color: #757F92;
    //                         }
    //                     }
    //                 }
    //                 >p{
    //                     color: #757F92;
    //                     font-size: 12px;
    //                     margin-top: 15px;
    //                     b{
    //                         font-size: 18px;
    //                         color: #FF0027;
    //                     }
    //                 }
    //             }
    //         }

    //     }
    // }
    .pro-details {
        width: 100%;
        margin-top: 80px;
        margin-bottom: 60px;
        overflow: hidden;
        display: flex;
        justify-content: center;

        .show-content {
            min-width: 1200px;
            max-width: 1920px;
            display: flex;
            justify-content: center;
        }
    }

    .case {
        height: 767px;
        position: relative;
        overflow: hidden;

        h3 {
            padding-top: 90px;
            font-size: 34px;
            text-align: center;
            color: #0b1d30;
        }

        >img {
            width: auto;
            height: 500px;
            position: absolute;
            left: 50%;
            bottom: 74px;
            transform: translateX(-50%);
        }

        div {
            width: 1100px;
            height: 440px;
            position: absolute;
            bottom: 104px;
            left: 50%;
            margin-left: -550px;

            img {
                width: 1100px;
                height: 440px;
            }

            a {
                position: absolute;
                top: 60px;
                right: 60px;
                width: 150px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                background-color: #1F51C8;
                font-size: 16px;
                color: #ffffff;
            }
        }
    }

    .pro-tjian {
        padding: 16px 0;

        h3 {
            font-size: 34px;
            color: #0b1d30;
            font-weight: 500;
            margin-bottom: 60px;
            text-align: center;
        }

        ul {
            width: 1200px;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            overflow: hidden;

            li {
                img {
                    width: 285px;
                    height: 200px;
                }
            }
        }
    }

    .adv {
        width: 100%;
        padding-top: 74px;
        height: 360px;
        position: relative;
        overflow: hidden;

        a {
            >img {
                height: 220px;
                position: absolute;
                top: 74px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }



}

.synopsis {
    width: 516px;
    box-sizing: border-box;
    background-color: rgba(230, 248, 255, 0.6);
    color: #21282D;
    padding: 12px 20px;
    line-height: 20px;
    margin: 0 auto;
    margin-bottom: 45px;
    font-size: 12px;
    display: flex;
    justify-content: space-between;

    >span {
        font-size: 16px;
        color: #FF8A05;
    }

    p {
        margin-left: 10px;

        span {
            color: #1662BC;
        }
    }
}

.alter-box {
    padding: 0 30px;

    .configure {
        padding: 23px 25px 30px 33px;
        background-color: #FAFAFA;

        h3 {
            color: #061D33;
            font-size: 14px;
            padding-bottom: 20px;
            border-bottom: 1px solid #919BAB;
            margin-bottom: 20px;
        }

        ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            li {
                line-height: 38px;

                p {
                    span {
                        display: inline-block;
                        width: 126px;
                    }
                }
            }

            li:nth-child(2n+1) {
                width: 300px;
            }

            li:nth-child(2n) {
                width: 260px;
            }
        }
    }

    .table {
        color: #061D33;
        font-size: 12px;

        td,
        th {
            border-color: #E3E3E3;
            text-align: center;
            line-height: 32px;
        }

        td {
            color: #758296;
        }

        .title {
            background-color: #D9EAF6;
            border-color: #e3e3e3;

            td {
                color: #333333;
            }
        }

        tr {
            .dist {
                color: #061D33;
            }

            td:nth-child(1) {
                width: 115px;
            }

            td:nth-child(2) {
                width: 200px;
            }

            td:nth-child(3) {
                width: 500px;
            }
        }
    }
}

.price {
    margin-right: 90px;

    p {
        color: #333333;
        font-size: 14px;

        b {
            color: #FF0027;
            font-size: 24px;
            font-weight: 500;
            margin-left: 5px;
            font-family: 'Roboto Mono-Regular';
        }
    }

    h6 {
        font-weight: 500;
        color: #5FB064;
        display: flex;
        justify-content: space-between;

        span {
            font-size: 12px;
        }

        b {
            font-weight: 500;
            background-color: rgba(95, 176, 10, 0.15);
            display: inline-block;
            padding: 2px 6px;
        }
    }
}
</style>