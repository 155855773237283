<template>
<div class="joinUs">
    <!-- <nav-bar></nav-bar> -->
    <div class="w100">
        <div class="bannerCenter">
            <div class="top">
                <h2>坚持合作共赢的生态理念</h2>
                <p>携手生态伙伴联合创新，持续为合作伙伴和客户创造价值</p>
                <p>&nbsp;</p>
            </div>
            <div class="bottom">
                <el-menu :default-active="activeNav" class="el-menu-demo" mode="horizontal" @select="handleSelect" router>
                    <el-menu-item index="/aboutUs/company">关于我们</el-menu-item>
                    <el-menu-item index="/joinUs">合作伙伴</el-menu-item>
                    <el-menu-item index="/contactUs">联系我们</el-menu-item>
                </el-menu>
            </div>
        </div>
    </div>
    <div class="cooperat">
        <div class="one">
            <div class="title">
                <h3>我们的优势</h3>
                <p>OUR ADVANTAGE</p>
            </div>
            <ul class="clearfix">
                <li>
                    <img src="../../assets/image/合作伙伴icon1.png" alt="">
                    <p>工业聚合平台</p>
                    <i>LEADING</i>
                </li>
                <li>
                    <img src="../../assets/image/合作伙伴icon2.png" alt="">
                    <p>海量工业相关企业用户</p>
                    <i>THE USER</i>
                </li>
                <li>
                    <img src="../../assets/image/合作伙伴icon3.png" alt="">
                    <p>生态支持战略</p>
                    <i>THE USER</i>
                </li>
                <li>
                    <img src="../../assets/image/合作伙伴icon4.png" alt="">
                    <p>补贴政策</p>
                    <i>POLICY</i>
                </li>
            </ul>
        </div>
        <div class="two">
            <div class="title">
                <h3>合作类型</h3>
                <p>YTPE OF COOPERATION</p>
            </div>
            <ul class="clearfix">
                <li>
                    <img src="../../assets/image/partnerRuanSer.png" alt="">
                    <div>
                        <h4>软件服务商</h4>
                        <p>开发和销售软件的公司，为特定行业提供软件支持。</p>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/partnerYingSer.png" alt="">
                    <div>
                        <h4>硬件服务商</h4>
                        <p>生产和销售专业硬件的公司，侧重于智能制造方面，应用行业以装备制造、电子信息、半导体、医疗、新能源、汽摩行业为主。</p>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/partnerJisuSer.png" alt="">
                    <div>
                        <h4>解决方案服务商</h4>
                        <p>提供方案、服务、技术，能独立完成售前、售中 售后全套服务的团队。</p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="three">
            <div class="title">
                <h3>专属权益</h3>
                <p>EXCLUSIVE RIGHTS</p>
            </div>
            <ul class="clearfix">
                <li>
                    <h4>
                        <b></b>
                        <span>技术支持</span>
                    </h4>
                    <p>专业技术团队支撑合作伙伴构建解决方案，提供专属技术咨询及售前、方案、交付等支持，为生态伙伴重要项目保驾护航。</p>
                </li>
                <li>
                    <h4>
                        <b></b>
                        <span>市场支持</span>
                    </h4>
                    <p>定期举办行业峰会、技术沙龙、论坛、展会，向生态伙伴提供线下活动曝光机会，与合作伙伴共享资源、共拓市场。</p>
                </li>
                <li>
                    <h4>
                        <b></b>
                        <span>资源共享</span>
                    </h4>
                    <p>生态伙伴展示位，共享平台资源，为生态伙伴带来商机、并为加盟的合作伙伴提供各类线上媒体推广，资源倾斜。</p>
                </li>
                <li>
                    <h4>
                        <b></b>
                        <span>开放生态</span>
                    </h4>
                    <p>提供集成与被集成的开放生态合作模式，与生态伙伴优势互补、联合创新，共享商机、共生共赢。</p>
                </li>
            </ul>
        </div>
        <div class="four">
            <div class="title">
                <h3>合作门槛</h3>
                <p>COOPERATION THRSESHOLD</p>
            </div>
            <ul>
                <li>
                    <img src="../../assets/image/partnerHezuobg1.png" alt="">
                    <div>
                        <h4>技术服务提供商</h4>
                        <p><span>√</span>有成熟或标准化、SaaS化产品</p>
                        <p><span>√</span>有长期稳定的研发技术团队</p>
                        <p><span>√</span>具备客户成功案例和技术支持能力，可承接产品的实施落地服务</p>
                        <a @click="alertshow">我要加入</a>
                    </div>
                </li>
                <li>
                    <img src="../../assets/image/partnerHezuobg2.png" alt="">
                    <div>
                        <h4>渠道合作代理商</h4>
                        <p><span>√</span>具备一般纳税人资质</p>
                        <p><span>√</span>拥有5人以上销售团队</p>
                        <p><span>√</span>具备TO B 的获客渠道和生态资源</p>
                        <a @click="alertshow">我要加入</a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="five">
            <div class="title">
                <h3>合作流程</h3>
                <p>COOPERATION PROCESS</p>
            </div>
            <img src="../../assets/image/partnerprocess.png" alt="">
        </div>
    </div>

    <contact-alert :showVisible="showVisible" @child-event="parentEvent"></contact-alert>
    
</div>
</template>

<script>
// import navBar from '@/components/navBar'

import contactAlert from '@/components/contactAlert'
export default {
    data() {
        return{
            activeNav: '/jointUs',
            showVisible: false,
        }
    },
    components: {
       // navBar
       contactAlert
    },
    methods: {
        handleSelect(){
            this.activeNav = this.$route.path
        },
        onSubmit(){},
        alertshow() {
            this.showVisible = !this.showVisible
        },
        parentEvent(data){
            this.showVisible = data
        }
    },
    mounted() {
        this.activeNav = this.$route.path
    }
}
</script>

<style lang="scss">
.el-form-item__content{
    text-align: left;
}
</style>

<style lang="scss" scoped>
.joinUs{
    .w100{
        width: 100%;
        height: 335px;
        background: url('../../assets/image/aboutUsBg.png') center center no-repeat;
        .bannerCenter{
            width: 1062px;
            margin: 0 auto;
            text-align: center;
            .top{
                padding-top: 85px;
                h2{
                    color: #ffffff;
                    font-size: 36px;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
                p{
                    font-size: 18px;
                    color: #ffffff;
                    line-height: 36px;
                    font-weight: 300;
                }
            }
            .bottom{
                width: 636px;
                margin: 0 auto;
                margin-top: 51px;
                .el-menu{
                    background-color: transparent;
                }
                .el-menu--horizontal>.el-menu-item.is-active, 
                .el-menu--horizontal>.el-menu-item{
                    color: #ffffff;
                    border-width: 4px;
                    font-size: 18px;
                }
                .el-menu-item:focus, .el-menu-item:hover{
                    background-color: transparent;
                }
                .el-menu-item{
                    margin: 0 50px;
                }
            }
        }
    }
    .cooperat{
        width: 1200px;
        margin: 0 auto;
        .one{
            ul{
                margin-top: 20px;
                li{
                    float: left;
                    width: 285px;
                    height: 300px;
                    margin-right: 20px;
                    box-shadow: 0px 3px 15px 0px rgba(0,111,255,0.18);
                    text-align: left;
                    img{
                        width: 72px;
                        height: 72px;
                        margin: 40px;
                    }
                    p{
                        font-size: 18px;
                        color: #0B1D30;
                        margin-left: 40px;
                        margin-bottom: 50px;
                    }
                    i{
                        color: #006FFF;
                        margin-left: 40px;
                        font-size: 36px;
                    }
                }
                li:nth-child(4){
                    margin-right: 0;
                }
            }
        }
        .two{
            ul{
                margin-top: 20px;
                li{
                    width: 400px;
                    height: 484px;
                    float: left;
                    position: relative;
                    img{
                        width: 400px;
                        height: 484px;
                    }
                    div{
                        width: 386px;
                        height: 178px;
                        box-sizing: border-box;
                        padding-left: 40px;
                        padding-right: 50px;
                        position: absolute;
                        bottom: 10px;
                        h4{
                            font-size: 32px;
                            color: #ffffff;
                        }
                        p{
                            font-size: 14px;
                            color: #ffffff;
                            line-height: 28px;
                            margin-top: 10px;
                            text-align: left;
                        }
                    }
                }
            }
        }
        .three{
            padding-bottom: 60px;
            ul{
                width: 1200px;
                height: 600px;
                margin-top: 20px;
                box-shadow: 0 0 20px rgba(0,0,0,0.08);
                background: url('../../assets/image/partnerZhuansuQuan.png') center center no-repeat;
                li{
                    width: 270px;
                    float: left;
                    font-size: 14px;
                    line-height: 28px;
                    color: #757F92;
                    text-align: left;
                    margin-top: 65px;
                    h4{
                        span{
                            font-size: 24px;
                            margin-left: -86px;
                            color: #0B1D30;
                            font-weight: 500;
                        }
                    }
                    p{
                        margin-top: 20px;
                    }
                }
                li b::before{
                    font-size: 80px;
                    font-weight: 400;
                    color: #eef3ff;
                    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif
                }
                li:nth-child(1) b::before{
                    content: '01';
                }
                li:nth-child(2) b::before{
                    content: '02';
                }
                li:nth-child(3) b::before{
                    content: '03';
                }
                li:nth-child(4) b::before{
                    content: '04';
                }
                li:nth-child(3), li:nth-child(4){
                    margin-top: 120px;
                }
                li:nth-child(2n+1){
                    margin-left: 60px;
                    margin-right: 270px;
                }
                li:nth-child(2n+2){
                    margin-left: 270px;
                    margin-right: 60px;
                }
            }
        }
        .four{
            ul{
                li{
                    height: 400px;
                    margin-top: 30px;
                    position: relative;
                    div{
                        width: 510px;
                        height: 316px;
                        text-align: left;
                        padding-left: 50px;
                        position: absolute;
                        top: 42px;
                        left: 0;
                        h4{
                            margin-top: 60px;
                            margin-bottom: 20px;
                            font-size: 24px;
                        }
                        p{
                            font-size: 14px;
                            line-height: 30px;
                            color: #757F92;
                            position: relative;
                            padding-left: 20px;
                            span{
                                position: absolute;
                                top: 8px;
                                left: 0;
                                width: 15px;
                                line-height: 15px;
                                text-align: center;
                                height: 15px;
                                border-radius: 50px;
                                background-color: #026bff;
                                color: #ffffff;
                            }
                        }
                        a{
                            width: 120px;
                            height: 40px;
                            display: inline-block;
                            line-height: 40px;
                            text-align: center;
                            font-size: 14px;
                            margin-top: 30px;
                            background-color: #006FFF;
                            color: #ffffff;
                            cursor: pointer;
                        }
                    }
                }
                li:nth-child(2){
                    div{
                        left: 680px;
                    }
                }
            }
        }
        .five{
            height: 312px;
            img{
                margin: 35px 0;
            }
        }
        .title{
            margin-top: 80px;
            text-align: left;
            h3{
                font-size: 28px;
                color: #0B1D30; 
                font-weight: normal;
            }
            p{
                font-size: 16px;
                color: #757F92;
                font-weight: 500;
            }
        }
    }
    .sub_btn{
        width: 500px;
        margin-left: 70px;
    }
}
</style>
