<template>
    <div class="user-center">
        <!--        <nav-bar></nav-bar>-->
        <div class="center clearfix">
            <nav-user-center></nav-user-center>
            <div class="right">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script>
// import navBar from '@/components/navBar'
import navUserCenter from "@/components/nav-user-center";
export default {
    components: {
        // navBar,
        navUserCenter,
    },
};
</script>

<style>
.clearfix {
    zoom: 1;
}
.clearfix:after {
    display: block;
    content: "";
    clear: both;
}
</style>

<style lang="scss" scoped>

.user-center {
    padding-bottom: 60px;
    background: #f6f8fb;
  .center{

        width: 1200px;
        margin: 0 auto;
        padding-top: 40px;
        .right {
            float: right;
            width: 898px;
            height: auto;
            padding: 20px 28px;
            border: 1px solid #f5f5f5;
            background: #ffffff;
            // -webkit-box-shadow: #f5f5f5 0px 2px 2px 3px;
            // box-shadow: #f5f5f5 0px 2px 2px 3px;
        }
    }
}
</style>