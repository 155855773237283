<template>
    <div class="home-nav" :class="navFixed?'fixed':''">
        <div class="left">
            <div class="logo-img"><router-link :to="{path: '/'}"><img :src="require('../assets/logo.png')" alt=""></router-link></div>

            <div class="region">
                <p>沧州市</p>
                <div>
                    <h3>区域站</h3>
                        <p>用信息技术为县域经济赋能，打造基于工业云服务支持的产业要素协同和互动融合的生态布局。</p>
                        <ul class="right-one-ul">
                            <li>高新区</li>
                            <li>孟村县</li>
                            <li>新华区</li>
                            <li>海兴县</li>
                            <li>经济开发区</li>
                            <li>盐山县</li>
                            <li>渤海新区</li>
                            <li>吴桥县</li>
                            <li>任丘市</li>
                            <li>肃宁县</li>
                            <li>泊头市</li>
                            <li>南皮县</li>
                            <li>沧县</li>
                            <li>东光县</li>
                            <li>青县</li>
                            <li>黄骅市</li>
                        </ul>
                        <h3 class="right-two-h3">行业站</h3>
                        <ul class="right-two-ul">
                            <li>工业设计研究院</li>
                        </ul>
                </div>
            </div>
            
            <ul>
                <li><router-link :to="{path: '/industInternet'}">工业互联网平台</router-link></li>
                <li>
                    <router-link :to="{path: '/softwareMarket/softwareHome'}">云产品家族</router-link>
                    <div class="product-nav">
                        <div class="p-left">
                            <router-link :to="{path: '/SoftwareMarket/saasList'}"><img :src="require('../assets/nav-img/proFam/icon_1.png')">查看全部产品</router-link>
                            <ul>
                                <li @mouseenter="hover(0)" ><a></a>产品推荐 <img :src="require('../assets/nav-img/proFam/hot.png')"></li>
                                <li @mouseenter="hover(1)"><a></a>工业SaaS</li>
                                <li @mouseenter="hover(2)"><a></a>工业PaaS</li>
                                <li @mouseenter="hover(3)" ><a></a>边缘层</li>
                                <li @mouseenter="hover(4)"><a></a>信息物理层</li>
                            </ul>
                        </div>
                        <div class="p-right">
                            <div class="p-r-top">
                                <div>
                                    <p>
                                        <input type="text" v-model="mainWord" placeholder="搜索产品名称" name="" id="">
                                        <span class="iconfont icon-search" @click="search"></span>
                                    </p>
                                    <ul>
                                        <li>暂无信息</li>
                                    </ul>
                                </div>
                                <ul>
                                    <li><a href="/identifyNode" target="_blank">标识解析</a></li>
                                    <li><a href="/SoftwareMarket/details?id=718" target="_blank">企业级工业互联网</a></li>
                                    <li><a href="/SoftwareMarket/details?id=742" target="_blank">迈链追溯</a></li>
                                    <li><a href="/SoftwareMarket/details?id=704" target="_blank">工业物联网</a></li>
                                </ul>
                            </div>
                            <div class="p-r-bottom">
                                <div class="main-product">
                                    <p>热门产品</p>
                                    <ul>
                                        <li><a href="/SoftwareMarket/details?id=703" target="_blank">
                                            <!-- <h3>标识应用</h3>
                                            <p>数据信息互联互通</p> -->
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=704" target="_blank">
                                            <!-- <h3>工业物联网</h3>
                                            <p>人机物互联互通</p> -->
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=718" target="_blank">
                                            <!-- <h3>企业级平台</h3>
                                            <p>数据驱动决策管理</p> -->
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=679" target="_blank">
                                            <!-- <h3>业财一体化</h3>
                                            <p>一站式经营管理</p> -->
                                        </a></li>
                                        <li><a href="/trial" target="_blank">
                                            <!-- <h3>标识申领</h3>
                                            <p>抢占工业身份证</p> -->
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=716" target="_blank">
                                            <!-- <h3>快速开发</h3>
                                            <p>无需代码灵活配置</p> -->
                                        </a></li>
                                    </ul>
                                </div>
                                <div class="or-product">
                                    <p>工业SaaS</p>
                                    <ul>
                                        <li><a href="/SoftwareMarket/details?id=703" target="_blank">
                                            <img :src="require('../assets/Saas/gongyeSaaS/icon1.png')" alt="">
                                            <div>
                                                <h3>标识解析应用</h3>
                                                <p>实现工业全周期、各环节信息互联互通</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=678" target="_blank">
                                            <img :src="require('../assets/Saas/gongyeSaaS/icon2.png')" alt="">
                                            <div>
                                                <h3>协同办公（云OA）</h3>
                                                <p>实现员工，信息、流程、应用多维度协同</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=765" target="_blank">
                                            <img :src="require('../assets/Saas/gongyeSaaS/icon11.png')" alt="">
                                            <div>
                                                <h3>云ERP</h3>
                                                <p>实现员工，信息、流程、应用多维度协同</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=683" target="_blank">
                                            <img :src="require('../assets/Saas/gongyeSaaS/icon3.png')" alt="">
                                            <div>
                                                <h3>生产管理（云MES）</h3>
                                                <p>助力企业打造智能工厂，实现精益生产、精细管理，降本增效。</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=740" target="_blank">
                                            <img :src="require('../assets/Saas/gongyeSaaS/icon4.png')" alt="">
                                            <div>
                                                <h3>客户管理（云CRM）</h3>
                                                <p>提升企业管理效益、客户满意度，实现可持续业绩增长</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=154" target="_blank">
                                            <img :src="require('../assets/Saas/gongyeSaaS/icon5.png')" alt="">
                                            <div>
                                                <h3>智能仓储（云WMS）</h3>
                                                <p>智能自动化货位路线、推荐拣货路径管理，提高拣配货效率</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=215" target="_blank">
                                            <img :src="require('../assets/Saas/gongyeSaaS/icon6.png')" alt="">
                                            <div>
                                                <h3>设备管理（点检保养）</h3>
                                                <p>设备互联、数据采集、智能分析、实时监控</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=677" target="_blank">
                                            <img :src="require('../assets/Saas/gongyeSaaS/icon7.png')" alt="">
                                            <div>
                                                <h3>人员定位管理</h3>
                                                <p>创新融合定位，低成本、高精度</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=701" target="_blank">
                                            <img :src="require('../assets/Saas/gongyeSaaS/icon8.png')" alt="">
                                            <div>
                                                <h3>好会计</h3>
                                                <p>为小微企业量身打造的专业云财务应用</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=679" target="_blank">
                                            <img :src="require('../assets/Saas/gongyeSaaS/icon9.png')" alt="">
                                            <div>
                                                <h3>业财一体化</h3>
                                                <p>按项目统计收入、成本、费用和回款情况</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=724" target="_blank">
                                            <img :src="require('../assets/Saas/gongyeSaaS/icon10.png')" alt="">
                                            <div>
                                                <h3>产品全生命周期管理系统PLM</h3>
                                                <p>为企业提供从研发到制造一体化解决方案，助力企业数字化转型 。</p>
                                            </div>
                                        </a></li>
                                    </ul>
                                </div>
                                <div class="or-product">
                                    <p>工业PaaS</p>
                                    <ul>
                                        <li><a href="/SoftwareMarket/details?id=704" target="_blank">
                                            <img :src="require('../assets/Saas/pass/icon1.png')" alt="">
                                            <div>
                                                <h3>工业物联网平台</h3>
                                                <p>一站式开发，覆盖物联网云、管、边、端各个环节。</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=703" target="_blank">
                                            <img :src="require('../assets/Saas/pass/icon2.png')" alt="">
                                            <div>
                                                <h3>标识解析应用平台</h3>
                                                <p>打造内外部及供应链环节的信息流通、提升企业精细化管理水平。</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=716" target="_blank">
                                            <img :src="require('../assets/Saas/pass/icon3.png')" alt="">
                                            <div>
                                                <h3>轻应用开发平台</h3>
                                                <p>降低IT开发运维成本，提升业务效率及满意度</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=719" target="_blank">
                                            <img :src="require('../assets/Saas/pass/icon4.png')" alt="">
                                            <div>
                                                <h3>大数据开发平台</h3>
                                                <p>提高软件开发效率、降低成本，加速数字化转型。</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=718" target="_blank">
                                            <img :src="require('../assets/Saas/pass/icon5.png')" alt="">
                                            <div>
                                                <h3>企业级工业互联网平台</h3>
                                                <p>数据驱动为核心的网络化、扁平化、平台化的经营管理</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=715" target="_blank">
                                            <img :src="require('../assets/Saas/pass/icon6.png')" alt="">
                                            <div>
                                                <h3>数据管理大屏</h3>
                                                <p>实时采集系统数据，整合数据资源，统一展示分析处理</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=717" target="_blank">
                                            <img :src="require('../assets/Saas/pass/icon7.png')" alt="">
                                            <div>
                                                <h3>企业诊断测评</h3>
                                                <p>针对企业核心业务能力、数据资产、人员组织全方位诊断。</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=713" target="_blank">
                                            <img :src="require('../assets/Saas/pass/icon8.png')" alt="">
                                            <div>
                                                <h3>VR可视化决策平台</h3>
                                                <p>一比一场景还原，对生产设备，人员管理、车间管理、环境数据监测</p>
                                            </div>
                                        </a></li>
                                    </ul>
                                </div>
                                <!-- <div class="or-product">
                                    <p>工业IaaS</p>
                                    <ul>
                                        <li><a href="" target="_blank">
                                            <img src="" alt="">
                                            <div>
                                                <h3>云存储</h3>
                                                <p>提供图片处理,音视频转码,内容加速分发,归档服务等多种数据服务</p>
                                            </div>
                                        </a></li>
                                        <li><a href="" target="_blank">
                                            <img src="" alt="">
                                            <div>
                                                <h3>云服务器</h3>
                                                <p>升级方便、简单高效、安全可靠、处理能力可弹性伸缩</p>
                                            </div>
                                        </a></li>
                                        <li><a href="" target="_blank">
                                            <img src="" alt="">
                                            <div>
                                                <h3>蓝光数据存储</h3>
                                                <p>专属企业数据存储，提供冷热温数据存储的一体化解决方案</p>
                                            </div>
                                        </a></li>
                                        <li><a href="" target="_blank">
                                            <img src="" alt="">
                                            <div>
                                                <h3>智能魔盒</h3>
                                                <p>提升企业生产效率，给用户即时、直接价值收益</p>
                                            </div>
                                        </a></li>
                                        <li><a href="" target="_blank">
                                            <img src="" alt="">
                                            <div>
                                                <h3>服务器</h3>
                                                <p>高速的CPU运算能力、长时间的可靠运行、强大的1V0外部数据吞吐能力以及更好的扩展性</p>
                                            </div>
                                        </a></li>
                                    </ul>
                                </div> -->
                                <div class="or-product">
                                    <p>边缘层</p>
                                    <ul>
                                        <li><a href="/SoftwareMarket/details?id=723" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon1.png')" alt="">
                                            <div>
                                                <h3>边缘计算-数据分析应用</h3>
                                                <p>数据挖掘、逻辑判断、数据汇聚，提高通讯效率</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=720" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon2.png')" alt="">
                                            <div>
                                                <h3>工控安全评估系统</h3>
                                                <p>加强和完善企业自身工控安全建设</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=722" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon3.png')" alt="">
                                            <div>
                                                <h3>工控等保检查工具箱</h3>
                                                <p>助力工控系统安全等级保护建设</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=721" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon4.png')" alt="">
                                            <div>
                                                <h3>安全运维管理平台</h3>
                                                <p>管理流程更加规范统一</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=689" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon5.png')" alt="">
                                            <div>
                                                <h3>统一安全管理平台</h3>
                                                <p>统一管理，提高运维效率</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=692" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon6.png')" alt="">
                                            <div>
                                                <h3>日志审计系统</h3>
                                                <p>整体提升企业和组织的安全防护水平</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=711" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon7.png')" alt="">
                                            <div>
                                                <h3>工业监测审计系统</h3>
                                                <p>有效的防范控制网络安全风险</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=696" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon8.png')" alt="">
                                            <div>
                                                <h3>工控主机卫士</h3>
                                                <p>提高操作系统的安全保护能力</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=698" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon9.png')" alt="">
                                            <div>
                                                <h3>安全隔离与信息单向导入</h3>
                                                <p>高规格安全防护，硬件保证单向传输</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=694" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon10.png')" alt="">
                                            <div>
                                                <h3>工业网闸</h3>
                                                <p>安全物理隔离，有效阻断攻击</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=686" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon11.png')" alt="">
                                            <div>
                                                <h3>入侵防御系统</h3>
                                                <p>融合多种安全功能，保障工控网络安全</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=695" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon12.png')" alt="">
                                            <div>
                                                <h3>工业防火墙</h3>
                                                <p>融合多种安全功能，保障工控网络安全</p>
                                            </div>
                                        </a></li>

                                        <li><a href="/SoftwareMarket/details?id=691" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon13.png')" alt="">
                                            <div>
                                                <h3>终端杀毒</h3>
                                                <p>为企事业单位提供终端病毒、漏洞管控能力。</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=693" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon14.png')" alt="">
                                            <div>
                                                <h3>漏洞扫描</h3>
                                                <p>对主机安全进行检查和分析，及时修复漏洞、提高系统安全防护能力。</p>
                                            </div>
                                        </a></li>

                                        <li><a href="/SoftwareMarket/details?id=688" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon15.png')" alt="">
                                            <div>
                                                <h3>数据库安全</h3>
                                                <p>为用户提供云上数据加密服务提供旁路模式的数据库安全审计服务功能</p>
                                            </div>
                                        </a></li>

                                        <li><a href="/SoftwareMarket/details?id=687" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon16.png')" alt="">
                                            <div>
                                                <h3>数据加密</h3>
                                                <p>为用户提供云上数据加密服务</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=690" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon17.png')" alt="">
                                            <div>
                                                <h3>网页防篡改</h3>
                                                <p>为防止黑客、病毒等对文件进行非法篡改和破坏提供解决方案。</p>
                                            </div>
                                        </a></li>

                                        <li><a href="/SoftwareMarket/details?id=685" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon18.png')" alt="">
                                            <div>
                                                <h3>渗透测试</h3>
                                                <p>对系统任何弱点、技术缺陷或漏洞主动分析</p>
                                            </div>
                                        </a></li>

                                        <li><a href="/SoftwareMarket/details?id=697" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon19.png')" alt="">
                                            <div>
                                                <h3>服务器安全卫士</h3>
                                                <p>快速精准地发现安全威胁和入侵事件</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=699" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon20.png')" alt="">
                                            <div>
                                                <h3>web应用防火墙</h3>
                                                <p>多维度防御策略，为网站拦截隔离Web类型的攻击</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=684" target="_blank">
                                            <img :src="require('../assets/Saas/edgeB/icon21.png')" alt="">
                                            <div>
                                                <h3>Anti-DDoS流量清洗</h3>
                                                <p>可监控攻防状态，实时保证业务安全运行</p>
                                            </div>
                                        </a></li>
                                    </ul>
                                </div>
                                <div class="or-product">
                                    <p>信息物理层</p>
                                    <ul>
                                        <li><a href="/SoftwareMarket/details?id=709" target="_blank">
                                            <img :src="require('../assets/Saas/mes/icon1.png')" alt="">
                                            <div>
                                                <h3>电子标签FRID</h3>
                                                <p>柔性抗金属标签，适用范围广泛。</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=710" target="_blank">
                                            <img :src="require('../assets/Saas/mes/icon2.png')" alt="">
                                            <div>
                                                <h3>工业级边缘计算网关</h3>
                                                <p>LTE全网通|容器化边缘计算</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=706" target="_blank">
                                            <img :src="require('../assets/Saas/mes/icon3.png')" alt="">
                                            <div>
                                                <h3>智能赋码机</h3>
                                                <p>满足高速生产线产品标识需求。</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=705" target="_blank">
                                            <img :src="require('../assets/Saas/mes/icon4.png')" alt="">
                                            <div>
                                                <h3>智能机械臂</h3>
                                                <p>360°可旋转机臂，便携式操作，提高效率。</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=707" target="_blank">
                                            <img :src="require('../assets/Saas/mes/icon5.png')" alt="">
                                            <div>
                                                <h3>智能FRID手持机</h3>
                                                <p>R8超高频RFID手持终端。</p>
                                            </div>
                                        </a></li>
                                        <li><a href="/SoftwareMarket/details?id=708" target="_blank">
                                            <img :src="require('../assets/Saas/mes/icon6.png')" alt="">
                                            <div>
                                                <h3>PDA移动智能终端</h3>
                                                <p>M3超轻便大屏智能手持终端。</p>
                                            </div>
                                        </a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
                <li><router-link :to="{path: '/mailianZhicai'}">迈链智采</router-link></li>
                <li><router-link :to="{path: '/industIot'}">工业物联网</router-link></li>
                <li><router-link :to="{path: '/solutionList'}">解决方案</router-link></li>
                <li>
                    <!-- <router-link :to="{path: '/finance'}" >金融信息</router-link> -->
                    <a href="https://www.czjrkg.cn/fhc/front/index" target="_blank">金融信息</a>
                </li>
                <!-- <li>
                    <router-link :to="{path: '/identification'}">标识应用</router-link>
                    <div class="identify-nav">
                        <div class="i-left">
                            <h3>标识应用</h3>
                            <p>数字未来 万物标识<br/>工业领域“身份证”  <br/>工业数字化  智能化 <br/>标识解析助力制造企业拥抱数字机遇</p>
                        </div>
                        <div class="i-right">
                            <ul>
                                <li><router-link :to="{path: '/identification'}">
                                    <img src="" alt="">
                                    <p>工业互联网标识服务平台</p>
                                </router-link></li>
                                <li><a href="" target="_blank">
                                    <img src="" alt="">
                                    <p>标识企业节点及应用建设</p>
                                </a></li>
                            </ul>
                        </div>
                    </div>
                </li> -->
                <!-- <li><a href="" target="_blank">快速开发</a></li> -->
                <!-- <li><a href="" target="_blank">云工厂</a></li> -->
                <li>
                    <a href="javascript:;">走进沧州工业云</a>
                    <div class="about-us">
                        <div class="a-left">
                            <h3>走进沧州工业云</h3>
                            <p>在政府指导下由中科物联主导搭建，专为地方企业提供信息化综合性服务，支撑沧州工业云的建设与运营工作。</p>
                        </div>
                        <div class="a-right">
                            <router-link :to="{path: '/aboutUs/company'}"><img :src="require('../assets/nav-img/aboutUs/pic_1.png')" alt="公司介绍"></router-link>
                            <ul>
                                <li><router-link :to="{path: '/aboutUs/memorabilia'}">
                                    <img :src="require('../assets/nav-img/aboutUs/icon_1.png')" alt="icon">
                                    <h3>发展历程</h3>
                                    <p>不断迭代，快速发展</p>
                                </router-link></li>
                                <li><router-link :to="{path: '/aboutUs/winAward'}">
                                    <img :src="require('../assets/nav-img/aboutUs/icon_2.png')" alt="">
                                    <h3>荣誉资质</h3>
                                    <p>权威发布及行业认可</p>
                                </router-link></li>
                                <li><router-link :to="{path: '/joinUs'}">
                                    <img :src="require('../assets/nav-img/aboutUs/icon_3.png')" alt="">
                                    <h3>加入我们</h3>
                                    <p>携手创建未来</p>
                                </router-link></li>
                                <li><router-link :to="{path: '/contactUs'}">
                                    <img :src="require('../assets/nav-img/aboutUs/icon_4.png')" alt="">
                                    <h3>联系我们</h3>
                                    <p>企业数字化转型的践行者</p>
                                </router-link></li>
                            </ul>
                            <div>
                                <h4><router-link :to="{path: '/companyNews'}">新闻资讯</router-link></h4>
                                <p><router-link :to="{path: '/companyNewsDetail', query: {id: news.id, classId: news.classId}}">{{news.newsName}}</router-link></p>
                                <span>{{news.updateTime}}</span>
                                <h4><router-link :to="{path: '/govHome'}">政策服务</router-link></h4>
                                <p><router-link :to="{path: '/govNewsDetails', query: {id: gov.id, topClass: gov.classId1, classId: gov.classId2}}">{{gov.policyName}}</router-link></p>
                                <span>{{gov.updateTime}}</span>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
        <div class="right">
            <el-dropdown trigger="hover">
                <a href="http://27.128.231.193:8000/snms/ui/index" target="_blank"><p><span class="el-dropdown-link">
                    标识解析二级节点(沧州)<i class="el-icon-arrow-down el-icon--right"></i>
                </span></p></a>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item  command="a"><router-link :to="{path: '/identifyNode'}">工业互联网标识解析</router-link></el-dropdown-item>
                    <el-dropdown-item command="b"><router-link :to="{path: '/identification'}">工业互联网标识应用</router-link></el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <!-- <p><a target="_blank" href="http://27.128.231.193:8000/snms/ui/index">标识解析二级节点(沧州)</a></p> -->
            <div>
                <router-link :to="{path: '/trial'}"><img v-show="!isLogin" :src="require('../assets/cznum.gif')" alt=""></router-link>
                <div class="isLogin" v-if="!isLogin">
                    <router-link :to="{path: '/register'}">注册</router-link> / 
                    <router-link :to="{path: '/login'}">登录</router-link>
                </div>
                <div class="notLogin" v-else>
                    <el-dropdown>
                        <span class="el-dropdown-link">
                            {{usename}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown" trigger="hover">
                            <el-dropdown-item command="a"><router-link :to="{path: '/userCenter/control'}">个人中心</router-link></el-dropdown-item>
                            <el-dropdown-item command="b" @click.native="outLogin">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
    // props:{
    //     fixed: {
    //         type: Boolean,
    //         default: true,
    //     },
    // },
    data() {
        return{
            mainWord: '',
            usename: '',
            userId: '',
            isLogin: false,
            news: {},
            gov: {},
            searchList: [],
            navFixed: false,
            scrollTop: 0,
        }
    },
    computed: {
        
    },
    methods: {
        handleScroll: function (e) {
            let _this = this;
            _this.scrollTop = document.documentElement.scrollTop;
            _this.getBottomOfWindow();
        },
        getBottomOfWindow: function () {
            var winHeight = window.innerHeight;
            if (this.scrollTop > 62) {
                this.navFixed = true;
                $(".home-nav").addClass("nav-shadow")
            } else {
                this.navFixed = false;
                $(".home-nav").removeClass("nav-shadow")
            }
        },
        // 搜索产品
        search() {
            var json = {
                like_name: this.mainWord
            }
            this.$api.product.productList(json).then(res => {
                if(res.code == 200){
                    this.$store.commit('getSearchList', res.data)

                    this.$router.push({
                        path: '/SoftwareMarket/saasList',
                        query: {
                            name: this.mainWord
                        }
                    })
                }
            })
        },
        hover(item) {
            var event = $('.home-nav .left > ul > li > div .p-left li')
            var event_son = $('.home-nav .left > ul > li > div .p-right .p-r-bottom')
            event.eq(item).find('a').css({width: '70px'})
            event.eq(item).css({color: '#1F51C8'})
            event.eq(item).siblings().css({color: '#0B1D30'})
            event.eq(item).siblings().find('a').css({width: 0, transition: 'width 1s ease',borderBottom: '1px solid #ffffff'})
            event_son.find('>div').eq(item).show()
            event_son.find('>div').eq(item).siblings().hide()
        },
        // 新闻推荐
        getRecommendNews() {
            this.$api.news.newsRecommend({}).then(res => {
                if(res.code == 200){
                    this.news = res.data
                }
            })
        },

        // 政策推荐
        getRecommendGov() {
            this.$api.news.govRecommend({}).then(res => {
                if(res.code == 200){
                    this.gov = res.data
                }
            })
        },

        // 获取登录状态
        loginState() {
            if(Cookies.get('token')){
                this.isLogin = true
                this.$api.login.queryIngo({'Blade-Auth': Cookies.get('token')}).then(res => {
                    if(res.code == 200){
                        this.usename = res.data.name
                        this.userId = res.data.userId
                        this.$store.commit('getUserId', res.data.userId)
                    }else{
                        this.$message.error(res.msg)
                    }
                })
            }
        },

        // 退出登录
        outLogin() {
            this.$api.login.outLogin({'Blade-Auth': Cookies.get('token')}).then(res => {
                if(res.success){
                    this.isLogin = false
                    Cookies.remove('token')
                    this.$router.push({path:'/newHome'})
                }
            })
        }
    },
    mounted() {
        this.getRecommendNews()
        this.getRecommendGov()
        this.loginState()
        window.addEventListener("scroll", this.handleScroll, true);
    },
}
</script>

<style lang="scss" scoped>
.nav-shadow{
    box-shadow: 0 0 20px rgba(8,46,174,0.15);
}
.home-nav{
    width: 100%;
    height: 62px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    color: #0B1D30;
    min-width: 1280px;
    position: relative;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 98;
    .left{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 62px;
        @media screen and (min-width: 1550px) {
            width: 1000px;
        }
        @media (max-width:1549px) and (min-width:1280px) {
            min-width: 930px;
        }
        >div.logo-img{
            // max-width: 230px;
            // min-width: 178px;
            text-align: right;
            width: 178px;
            img{
                width: 156px;
                height: 45px;
            }
        }
        >ul{
            height: 62px;
            margin-left: 30px;
            display: flex;
            justify-content: space-between;
            flex: 1;
            min-width: 632px;
            >li{
                line-height: 62px;
                box-sizing: border-box;
                >div{
                    top: -630px;
                    box-shadow: 0 0 5px rgba(0,0,0,0.08);
                }
                // @media screen and (min-width: 1650px) {
                //     margin-right: 42px;
                // }
                // @media (max-width:1649px) and (min-width:1400px) {
                //     margin-right: 20px;
                // }
                >div.product-nav{
                    width: 100%;
                    height: 630px;
                    position: absolute;
                    top: 62px;
                    left: 0;
                    background-color: #ffffff;
                    // display: flex;
                    box-sizing: border-box;
                    // opacity: 0;
                    display: none;
                    .p-left{
                        // width: 444px;
                        height: 630px;
                        background: url('../assets/nav-img/proFam/leftbg.png') right bottom no-repeat;
                        background-color: #c2c2c2;
                        padding-top: 30px;
                        float: left;
                        box-sizing: border-box;
                        @media screen and (min-width: 1530px) {
                            width: 444px;
                        }
                        @media (max-width:1529px) and (min-width:1329px) {
                            min-width: 244px;
                        }
                        >a{
                            margin-left: 230px;
                            img{
                                margin-top: 20px;
                                margin-right: 4px;
                            }
                        }
                        >a:hover{
                            color: #1F51C8;
                        }
                        li{
                            width: 102px;
                            margin-left: 230px;
                            text-align: left;
                            line-height: 38px;
                            border-bottom-width: 0;
                            position: relative;
                            cursor: pointer;
                            a{
                                width: 0;
                                height: 6px;
                                position: absolute;
                                top: 28px;
                                left: 0;
                            }
                        }
                        li:first-child{
                            margin-top: 15px;
                            img{
                                margin-top: 3px;
                            }
                        }
                    }
                    .p-right{
                        width: 1080px;
                        height: 630px;
                        float: left;
                        .p-r-top{
                            display: flex;
                            margin-top: 40px;
                            div{
                                position: relative;
                                p{
                                    width: 340px;
                                    border: 1px solid #2468F2;
                                    margin-left: 60px;
                                    padding: 0 15px;
                                    line-height: 30px;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    input{
                                        width: 310px;
                                        line-height: 30px;
                                        padding: 0;
                                    }
                                    span{
                                        width: 18px;
                                        height: 30px;
                                        // background-color: #cccccc;
                                        text-align: center;
                                        line-height: 30px;
                                        color: #2468F2;
                                        font-weight: 800;
                                        font-style: 18px;
                                        cursor: pointer;
                                    }
                                }
                                ul{
                                    width: 340px;
                                    background-color: #ffffff;
                                    position: absolute;
                                    top: 32px;
                                    left: 60px;
                                    padding: 0 15px;
                                    box-shadow: 0 0 5px #cccccc;
                                    display: none;
                                }
                            }
                            >ul{
                                display: flex;
                                line-height: 32px;
                                li{
                                    margin-left: 25px;
                                    padding: 0 8px;
                                    background-color: #DFE5ED;
                                }
                                li:hover{
                                    background-color: #1F51C8;
                                    a{
                                        color: #ffffff;
                                    }
                                }
                            }
                        }
                        .p-r-bottom{
                            padding-left: 60px;
                            margin-top: 46px;
                            >div{
                                display: none;
                            }
                            .main-product{
                                display: block;
                                >p{
                                    font-size: 14px;
                                    color: #1F51C8;
                                }
                                >ul{
                                    display: flex;
                                    justify-self: start;
                                    flex-wrap: wrap;
                                    li{
                                        width: 240px;
                                        height: 90px;
                                        margin-bottom: 20px;
                                        margin-right: 20px;
                                        background-color: #cccccc;
                                        transition: background-size 1s ease;
                                        h3{
                                            font-weight: normal;
                                            font-size: 16px;
                                            color: #0B1D30;
                                            line-height: 20px;
                                            padding-top: 30px;
                                            margin-left: 30px;
                                            margin-bottom: 3px;
                                        }
                                        p{
                                            font-size: 12px;
                                            color: #757F92;
                                            line-height: 15px;
                                            margin-left: 30px;
                                        }
                                    }
                                    li:nth-child(4n){
                                        @media screen and (min-width: 1524px) {
                                            margin-right: 0;
                                        }
                                        @media (max-width:1523px) and (min-width:1200px) {
                                            margin-right: 18px;
                                        }
                                    }
                                    li:nth-child(3n){
                                        @media screen and (min-width: 1524px) {
                                            margin-right: 18px;
                                        }
                                        @media (max-width:1523px) and (min-width:1200px) {
                                            margin-right: 0;
                                        }
                                    }
                                    li:hover{
                                        background-size: 250px 100px !important;
                                    }
                                    li:nth-child(1){
                                        background: url('../assets/nav-img/proFam/biaoshi.png') right bottom no-repeat;
                                        background-size: 240px 90px;
                                    }
                                    li:nth-child(2){
                                        background: url('../assets/nav-img/proFam/wulianw.png') right bottom no-repeat;
                                        background-size: 240px 90px;
                                    }
                                    li:nth-child(3){
                                        background: url('../assets/nav-img/proFam/iot.png') right bottom no-repeat;
                                        background-size: 240px 90px;
                                    }
                                    li:nth-child(4){
                                        background: url('../assets/nav-img/proFam/caiwu.png') right bottom no-repeat;
                                        background-size: 240px 90px;
                                    }
                                    // li:nth-child(5){
                                    //     background: url('../assets/nav-img/proFam/gongzhang.jpg') right bottom no-repeat;
                                    //     background-size: 240px 90px;
                                    // }
                                    li:nth-child(5){
                                        background: url('../assets/nav-img/proFam/id.png') right bottom no-repeat;
                                        background-size: 240px 90px;
                                    }
                                    li:nth-child(6){
                                        background: url('../assets/nav-img/proFam/kaifa.png') right bottom no-repeat;
                                        background-size: 240px 90px;
                                    }
                                }
                            }
                            .or-product{
                                p{
                                    color: #1F51C8;
                                    font-size: 14px;
                                }
                                ul{
                                    max-height: 420px;
                                    display: flex;
                                    justify-self: start;
                                    flex-wrap: wrap;
                                    overflow-y: auto;
                                    li{
                                        width: 240px; 
                                        height: 85px;
                                        margin-right: 18px;
                                        margin-bottom: 25px;
                                        background-color: #f4f5f7;
                                        box-sizing: border-box;
                                        padding: 18px 24px;
                                        a{
                                            display: flex;
                                            justify-content: space-between;
                                            img{
                                                width: 30px;
                                                height: 30px;
                                            }
                                            div{
                                                width: 152px;
                                                h3{
                                                    height: 18px;
                                                    line-height: 18px;
                                                    font-size: 14px;
                                                    color: #0b1d30;
                                                    overflow: hidden;
                                                }
                                                p{
                                                    font-size: 12px;
                                                    color: #757f92;
                                                    height: 30px;
                                                    line-height: 15px;
                                                    overflow: hidden;
                                                }
                                            }
                                        }
                                    }

                                    li:nth-child(4n){
                                        @media screen and (min-width: 1524px) {
                                            margin-right: 0;
                                        }
                                        @media (max-width:1523px) and (min-width:1200px) {
                                            margin-right: 18px;
                                        }
                                    }
                                }
                            }
                        }
                    } 
                }
                >div.identify-nav{
                    width: 100%;
                    height: 302px;
                    position: absolute;
                    left: 0;
                    background-color: #ffffff;
                    display: flex;
                    .i-left{
                        width: 444px;
                        background-color: #cccccc;
                        h3, p{
                            width: 184px;
                            margin-left: 222px;
                        }
                        h3{
                            font-size: 16px;
                            font-weight: normal;
                            height: 20px;
                            line-height: 20px;
                            color: #0b1d30;
                            margin-top: 50px;
                            margin-bottom: 16px;
                        }
                        p{
                            font-size: 14px;
                            color: #757f92;
                            line-height: 25px;
                        }
                    }
                    .i-right{
                        flex: 1;
                        ul{
                            display: flex;
                            margin-left: 62px;
                            align-items: center;
                            flex-wrap: wrap;
                            margin-top: 40px;
                            li{
                                width: 302px;
                                height: 90px;
                                margin-right: 20px;
                                box-sizing: border-box;
                                padding: 25px 30px;
                                margin-bottom: 20px;
                                background-color: #F4F5F7;
                                a{
                                    display: flex;
                                    img{
                                        width: 40px;
                                        height: 40px;
                                        margin-right: 10px;
                                    }
                                    p{
                                        max-width: 292px;
                                        font-size: 16px;
                                        color: #0b1d30;
                                        line-height: 40px;
                                    }
                                }
                            }
                        }
                    }
                }
                >div.about-us{
                    width: 100%;
                    height: 290px;
                    position: absolute;
                    top: 62px;
                    left: 0;
                    background-color: #ffffff;
                    // display: flex;
                    display: none;
                    min-width: 1350px;
                    .a-left{
                        height: 290px;
                        background: url('../assets/nav-img/aboutUs/bg.png') right bottom no-repeat;
                        background-color: #cccccc;
                        float: left;
                        @media screen and (min-width: 1550px) {
                            width: 444px;
                        }
                        @media (max-width:1549px) and (min-width:1350px) {
                            width: 244px;
                        }
                        h3, p{
                            width: 185px;
                            @media screen and (min-width: 1550px) {
                                margin-left: 222px;
                            }
                            @media (max-width:1549px) and (min-width:1350px) {
                                margin-left: 22px;
                            }
                        }
                        h3{
                            font-weight: normal;
                            font-size: 16px;
                            height: 20px;
                            line-height: 20px;
                            margin-top: 50px;
                            margin-bottom: 16px;
                            color: #0b1d30;
                        }
                        p{
                            font-size: 14px;
                            line-height: 25px;
                            color: #757f92;
                        }
                    }
                    .a-right{
                        float: left;
                        padding: 40px 0 40px 62px;
                        display: flex;
                        >a{
                            width: 266px;
                            height: 210px;
                            img{
                                width: 266px;
                                height: 210px;
                            }
                        }
                        ul{
                            width: 466px;
                            display: flex;
                            margin: 0 12px;
                            flex-wrap: wrap;
                            li{
                                width: 210px;
                                height: 102px;
                                line-height: 0;
                                background-color: #f4f5f7;
                                margin-right: 6px;
                                margin-bottom: 5px;
                                box-sizing: border-box;
                                padding: 18px;
                                a{
                                    display: block;
                                }
                                img{
                                    // width: 20px;
                                    // height: 20px;
                                    line-height: 20px;
                                    margin-bottom: 9px;
                                }
                                h3{
                                    font-size: 16px;
                                    font-weight: normal;
                                    margin-bottom: 5px;
                                    height: 19px;
                                    line-height: 19px;
                                    color: #0b1d30;
                                }
                                p{
                                    height: 15px;
                                    font-size: 12px;
                                    color: #757F92;
                                    line-height: 15px;
                                }
                            }
                        }
                        div{
                            width: 240px;
                            overflow: hidden;
                            font-size: 14px;
                            line-height: 18px;
                            h4{
                                font-weight: normal;
                                margin-top: 13px;
                                margin-bottom: 15px;
                                a{
                                    color: #1F51C8;
                                }
                            }
                            p{
                                margin-bottom: 10px;
                                a{
                                    color: #0B1D30;
                                }
                            }
                            span{
                                display: block;
                                font-size: 12px;
                                color: #757F92;
                                // margin-bottom: 30px;
                            }
                        }
                    }
                }
            }
            >li:hover{
                >div{
                    // opacity: 1;
                    display: block;
                    // transition: bottom 0.5s ease;
                }
                >a{
                    color: #1F51C8;
                }
            }
        }
        .region{
            height: 62px;
            line-height: 62px;
            cursor: pointer;
            width: 60px;
            background: url('../assets/image/navUp.png') right center no-repeat;
            position: relative;
            margin-left: 30px;
            div{
                display: none;
                width: 240px;
                padding: 13px 29px;
                box-sizing: border-box;
                position: absolute;
                left: 0;
                top: 62px;
                z-index: 999;
                background-color: #ffffff;
                box-shadow: 0px 6px 10px 1px rgba(55, 99, 170, 0.1);
                h3{
                    height: 34px;
                    line-height: 34px;
                    font-size: 16px;
                    color: #1f51c8;
                    margin-bottom: 8px;
                }
                p{
                    font-size: 12px;
                    line-height: 20px;
                    color: #757f92;
                    padding-bottom: 24px;
                    border-bottom: dashed 1px #c2c2c2;
                }
                ul{
                    display: flex;
                    justify-content: space-between;
                    flex-flow: wrap;
                    li{
                        width: 75px;
                        line-height: 38px;
                        font-size: 12px;
                        color: #757f92;
                    }
                    li:hover{
                        color: #1F51C8;
                    }
                }
                >ul.right-one-ul{
                    border-bottom: 1px dashed #c2c2c2;
                    padding: 8px 0;
                }
                >ul.right-two-ul{
                    li{
                        width: 100%;
                    }
                }
                h3.right-two-h3{
                    padding-top: 12px;
                    margin-bottom: 0;
                }
            }
        }
        .region:hover{
            background: url('../assets/image/navDown.png') right center no-repeat;
            >p{
                color: #1F51C8;
            }
            div{
                display: block;
            }
        }
    }
    .right{
        // min-width: 512px;
        display: flex;
        align-items: center;
        height: 62px;
        justify-content: flex-end;
        margin-right: 20px;
        @media screen and (min-width: 1550px) {
            width: 512px;
        }
        @media (max-width:1549px) and (min-width:1480px) {
            min-width: 500px;
        }
        @media (max-width:1479px) and (min-width:1200px) {
            width: 300px;
            flex-wrap: wrap-reverse;
            img{
                display: none;
            }
        }
        p{
            margin-right: 20px;
        }
        >div{
            height: 62px;
            display: flex;
            align-items: center;
            img{
                width: 192px;
                margin-right: 30px;
            }
            div.isLogin{
                height: 18px;
                padding: 10px 13px;
                border: 1px solid #1F51C8;
                a{
                    font-size: 14px;
                    color: #1F51C8;
                }
            }
            div.notLogin{
                .el-dropdown-link {
                    cursor: pointer;
                }
                .el-icon-arrow-down {
                    font-size: 12px;
                }
                .demonstration {
                    display: block;
                    color: #8492a6;
                    font-size: 14px;
                    margin-bottom: 20px;
                }
            }
        }
    }
}
.fixed{
    position: fixed;
}
</style>
