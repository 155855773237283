import axios from '../http';
import {an,meng,api} from '../global'
// 联系我们表单提交 meng
export const postCantactUs = (data) => {
    return axios({
        url: api + '/product/contact',
        method: 'post',
        data
    })
}
