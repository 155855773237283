<template>
<!-- 工业供采 -->
  <div class="l_wrapper">
    <div class="l_content">
      <div class="l_title_text">{{titleText}}</div>
      <img class="l_close" @click="close" src="../assets/img/关闭.png" alt="">
      <div class="l_main">
        <div class="l_main_left">
          <div class="l_company">
            <img
              class="l_company_img"
              src="../assets/img/云采中心.png"
              alt=""
            />
            <div class="l_company_text">云采中心</div>
          </div>
          <div class="l_main_group">
            <div class="l_main_item">
              <img src="../assets/img/现货中心.png" alt="" />
              <div class="l_item_title">现货中心</div>
            </div>
            <div class="l_main_item">
              <img src="../assets/img/设备闲置.png" alt="" />
              <div class="l_item_title">设备闲置</div>
            </div>
            <div class="l_main_item">
              <img src="../assets/img/新增采购.png" alt="" />
              <div class="l_item_title">新增采购</div>
            </div>
            <div class="l_main_item">
              <img src="../assets/img/专题采购.png" alt="" />
              <div class="l_item_title">专题采购</div>
            </div>
          </div>
        </div>
        <div class="l_main_right">
          <!-- <img src="../assets/img/工业供采banner.png" alt="" /> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 1,
       closeE:true,
      newsList: [
        {
          title: "新闻资讯",
          content:
            "台积电决定在日本建立第一家芯片工厂，亚马逊云：年化收入打540亿美元",
          createTime: "2021-09-27 16:31:50",
        },
        {
          title: "新闻资讯",
          content:
            "台积电决定在日本建立第一家芯片工厂，亚马逊云：年化收入打540亿美元",
          createTime: "2021-09-27 16:31:50",
        },
      ],
    };
  },
  computed: {
    titleText() {
      if(this.type == 0 || this.type == 1) {
        return '工业互联网生态品牌'
      } else if(this.type == 2) {
        return '工业互联标识先行'
      }
    }
  },
  methods: {
      close() {
        this.closeE=false;
        this.$emit("childIn", this.closeE);
     },
  }
};
</script>

<style lang="scss" scoped>
.l_wrapper {
  width: 100%;
  background-color: #f5f5f5;
  color: #0b1d30;
  .l_content {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    height: 331px;
    padding-top: 30px;
    .l_close {
      position: absolute;
      top: 35px;
      right: 6px;
      cursor: pointer;
    }
    .l_title_text {
      width: 168px;
      height: 44px;
      font-size: 14px;
      line-height: 44px;
      background-color: #e7e7e7;
      color: #757f92;
    }
    .l_main {
      display: flex;
      margin: 20px 0 27px 0;
      .l_main_left {
        display: flex;
        width: 60.5%;
        height: 210px;
        margin-right: 100px;
        .l_company {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 210px;
          margin-right: 10px;
          background: url("../assets/img/沧州工业云bj.png");
          background-size: 100% 100%;
          .l_company_text {
            margin-top: 10px;
            font-size: 18px;
          }
        }
        .l_main_group {
          display: grid;
          grid-template-columns: 48% 48%;
          grid-template-rows: 48% 48%;
          gap: 6px;
          flex-wrap: wrap;
          width: 50%;
          .l_main_item {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            padding: 18px;
            background-color: #eeeeee;
            box-sizing: border-box;
            .l_item_title {
              font-size: 16px;
              // font-weight: 500;
            }
            .l_item_content {
              font-size: 12px;
              color: #757f92;
            }
          }
        }
      }
      .l_main_right {
        width: 32%;
        height: 210px;
        background: url('../assets/img/工业供采banner.png');
      }
    }
  }
}
</style>