<template>
  <div class="userCenterTitle">
    <p class="infoItem-title">
      <span class="infoItem-icon"></span>
      <span class="infoItem-text">{{title}}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: "userCenterTitle",
  props: {
    title: {
      type: String,
      default: '',
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.infoItem-title {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  .infoItem-icon {
    width: 3px;
    height: 15px;
    background: #006fff;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
    display: block;
    margin-right: 12px;
  }
  .infoItem-text {
    width: 64px;
    height: 20px;
    font-size: 16px;
    font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
    font-weight: 300;
    color: #0b1d30;
    line-height: 19px;
  }
}
</style>
