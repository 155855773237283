<template>
    <div class="product-nav">
        <div class="nav-top">
            <div class="t-left">
                <img src="" alt="">
            </div>
            <div class="t-right">
                <area-nav></area-nav>
            </div>
        </div>
        <div class="nav-bottom">
            <div class="t-img">
                <img src="" alt="">
            </div>
            <div class="t-nav">
                <ul>
                    <li><a href="">应用市场</a></li>
                    <li><a href="">解决方案</a></li>
                    <li><a href="">快速开发</a></li>
                    <li><a href="">工业物联网平台</a></li>
                    <li><a href="">企业级互联网平台</a></li>
                    <li><a href="">成功案例</a></li>
                </ul>
            </div>
            <div class="t-search">
                <input type="text" name="">
                <span>搜</span>
            </div>
        </div>
    </div>
</template>

<script>
import areaNav from './area-common'
export default {
    data() {
        return{}
    },
    components: {
        areaNav
    },
}
</script>

<style lang="scss" scoped>
.product-nav{
    .nav-top{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eeeeee;
        .t-left{
            margin-left: 20px;
            img{
                width: 118px;
                height: 35px;
            }
        }
    }
    .nav-bottom{
        width: 1200px;
        height: 64px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .t-img{
            img{
                width: 222px;
                height: 36px;
            }
        }
        .t-nav{
            width: 584px;
            ul{
                display: flex;
                justify-content: space-between;
                li{
                    font-size: 14px;
                    a{
                        color: #0B1D30;
                    }
                }
                li:hover{
                    a{
                        color: #1F51C8;
                    }
                }
            }
        }
        .t-search{
            width: 280px;
            height: 30px;
            border: 3px solid #006EFF;
            display: flex;
            input{
                width: 230px;
                height: 30px;
                line-height: 30px;
                padding: 0 10px;
            }
            span{
                display: block;
                width: 30px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background-color: #006EFF;
                color: #ffffff;
            }
        }
    }
    .banner{
        width: 100%;
        height: 480px;
    }
}
</style>