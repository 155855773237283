<template>
<div class="software-market">
    <!-- <software-market-nav></software-market-nav> -->

    <!-- <product-family-nav></product-family-nav> -->

    
    <router-view/>
</div>
</template>

<script>
import softwareMarketNav from '@/components/software-market-nav'
import productFamilyNav from '@/components/product-nav'
export default {
    data() {
        return{}
    },
    components: {
        softwareMarketNav,
        productFamilyNav
    },
    methods: {
    }
}
</script>

<style lang="scss" scoped>

</style>