<template>
    <div class="enterprise">
        <div class="banner">
            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/banner.jpg" alt="">
        </div>

        <div class="data-introduce">
            <div class="iot-introduce">
                <h3>企业级互联网平台</h3>
                <ul>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/pic_1.jpg" alt="">
                        <div>
                            <b>01</b>
                            <p>统一系统平台</p>
                        </div>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/pic_2.jpg" alt="">
                        <div>
                            <b>02</b>
                            <p>统一门户入口</p>
                        </div>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/pic_3.jpg" alt="">
                        <div>
                            <b>03</b>
                            <p>统一权限管理</p>
                        </div>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/pic_4.jpg" alt="">
                        <div>
                            <b>04</b>
                            <p>统一数据模型</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="pro-framework">
            <div class="pro-framework-center">
                <h3>产品架构</h3>
                <img :src="proPho" alt="">
            </div>
        </div>

        <div class="pro-introduce">
            <div class="pro-introduce-center">
                <h3>产品能力与服务</h3>
                <ul>
                    <li>
                        <p><img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_1.png" alt=""></p>
                        <div>
                            <h4>数字营销</h4>
                            <p>涵盖了营销、销售和客户服务</p>
                        </div>
                    </li>
                    <li>
                        <p><img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_2.png" alt=""></p>
                        <div>
                            <h4>企业协同</h4>
                            <p>个人级、公司级、集团级、企业之间到企业内外部的协同</p>
                        </div>
                    </li>
                    <li>
                        <p><img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_3.png" alt=""></p>
                        <div>
                            <h4>共享服务</h4>
                            <p>企业财务和人力资源等共享服务</p>
                        </div>
                    </li>
                    <li>
                        <p><img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_4.png" alt=""></p>
                        <div>
                            <h4>智能制造</h4>
                            <p>将制造企业后端跟前端营销对接，包括供应链、采购，甚至包括制造环节。</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="platform-adv">
            <div class="platform-adv-center">
                <h3>平台优势</h3>
                <ul>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/icon_1.png" alt="">
                        <h4>精细管理</h4>
                        <p>提供产品制造过程控制、制造执行、企业资源计划、供应链管理等应用统一协同管控，实现快速响应与处理</p>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/icon_2.png" alt="">
                        <h4>降本增效</h4>
                        <p>通过平台的统一化管理管控、成本精准分析、环节控制，实现成本有效控制及生产效率的提升</p>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/icon_3.png" alt="">
                        <h4>精细管理</h4>
                        <p>提供产品制造过程控制、制造执行、企业资源计划、供应链管理等应用统一协同管控，实现快速响应与处理</p>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/icon_4.png" alt="">
                        <h4>降本增效</h4>
                        <p>通过平台的统一化管理管控、成本精准分析、环节控制，实现成本有效控制及生产效率的提升</p>
                    </li>
                </ul>
            </div>
        </div>

        <div class="target-user">
            <h3>目标用户</h3>

            <div class="my-swiper">
                <swiper :options="swiperOptions" :navigation="true">
                    <swiper-slide v-for="(item,index) in imgs" :key="index">
                        <img :src="item.bgImg" alt="">
                        <div class="content">
                            <div>
                                <img :src="item.iconImg" alt="">
                                <span></span>
                            </div>
                            <p>{{item.title}}</p>
                        </div>
                        <div class="bg"></div>
                    </swiper-slide>
                    <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                    
                </swiper>
                <div class="swiper-button-prev"></div>  <!-- 左箭头。如果放置在swiper外面，需要自定义样式。 -->
                <div class="swiper-button-next"></div>  <!-- 右箭头。如果放置在swiper外面，需要自定义样式。 -->
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default{
    data() {
        return{
            proPho: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/pic_5.png",
            imgs: [
                {
                    bgImg: 'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/car.png',
                    title: '汽车制造',
                    iconImg: 'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/car.png'
                },
                {
                    bgImg: 'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/huagong.png',
                    title: '绿色化工',
                    iconImg: 'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/huagong.png'
                },
                {
                    bgImg: 'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/guandao.png',
                    title: '管道装备',
                    iconImg: 'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/guandao.png'
                },
                {
                    bgImg: 'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/fuzhuang.png',
                    title: '服装服饰',
                    iconImg: 'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/fuzhuang.png'
                },
                {
                    bgImg: 'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/bg/qingjie.png',
                    title: '清洁能源',
                    iconImg: 'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/industIot/icon/qingjie.png'
                },
            ],
            swiperOptions: {
                loop: true,
                slidesPerView : 4,
                autoplay: {
                    delay: 2500
                },
                spaceBetween:0,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
        }
    },
    comments: {
        Swiper,
        SwiperSlide
    },
    methods: {
        handleScroll: function (e) {
            let _this = this;
            _this.scrollTop = document.documentElement.scrollTop;
            _this.getBottomOfWindow();
        },
        getBottomOfWindow: function () {
            // var winHeight = window.innerHeight;
            
            if(this.scrollTop > 1400){
                $('.pro-introduce .pro-introduce-center ul li:nth-child(2n+1)').css({'left': 0, 'transition': 'left 1s ease'})
                $('.pro-introduce .pro-introduce-center ul li:nth-child(2n)').css({'right': 0, 'transition': 'right 1s ease'})
            }
        },
        
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll, true);
    }
}
</script>

<style scoped lang="scss">
.enterprise{
    // margin-top: 62px;
    .banner{
        width: 100%;
        height: 540px;
        position: relative;
        img{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .data-introduce{
        padding: 90px 0;
        background-color: #F6F8FB;
        .iot-introduce{
            width: 1200px;
            height: 580px;
            margin: 0 auto;
            background-color: #F6F8FB;
            h3{
                font-size: 34px;
                font-weight: 400;
                margin-bottom: 60px;
            }
            ul{
                display: flex;
                li{
                    cursor: pointer;
                    img{
                        width: 296px;
                        height: 336px;
                    }
                    div{
                        width: 296px;
                        height: 144px;
                        box-sizing: border-box;
                        padding: 30px 40px;
                        b{
                            font-size: 40px;
                        }
                        p{
                            font-size: 20px;
                            margin-top: 8px;
                        }
                    }
                }
                li:nth-child(2n+1){
                    div{
                        background-color: #282828;
                        color: #ffffff;
                    }
                }
                
                li:nth-child(2n){
                    div{
                        background-color: #ffffff;
                        color: #0B1D30;
                    }
                }
                li:hover{
                    div{
                        background-color: #053EF5;
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .pro-framework{
        .pro-framework-center{
            width: 1184px;
            margin: 0 auto;
            padding: 30px 0;
            color: #181818;
            h3{
                font-size: 34px;
                padding-top: 60px;
                margin-bottom: 40px;
                font-weight: normal;
            }
            img{
                width: 1172px;
            }
        }
    }

    .pro-introduce{
        .pro-introduce-center{
            width: 1200px;
            margin: 0 auto;
            padding: 30px 0;
            color: #181818;
            h3{
                font-size: 36px;
                padding-top: 60px;
                margin-bottom: 40px;
            }
            >p{
                font-size: 18px;
                margin-bottom: 60px;
            }
            ul{
                height: 320px;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                position: relative;
                padding-bottom: 50px;
                li{
                    width: 545px;
                    height: 140px;
                    background-color: #ffffff;
                    border: 1px solid #ffffff;
                    display: flex;
                    justify-self: start;
                    margin-bottom: 40px;
                    align-items: center;
                    position: absolute;
                    padding-left: 25px;
                    cursor: pointer;
                    >p{
                        width: 70px;
                        width: 70px;
                        margin-right: 35px;
                        img{
                            width: 70px;
                            height: 70px;
                            -webkit-filter: grayscale(100%);
                            -moz-filter: grayscale(100%);
                            -ms-filter: grayscale(100%);
                            -o-filter: grayscale(100%);
                            filter: grayscale(100%);
                            filter: gray;
                            opacity: 0.5;
                        }
                    }
                    div{
                        width: 370px;
                        h4{
                            margin-bottom: 15px;
                            font-size: 18px;
                            font-weight: 400;
                        }
                        p{
                            font-size: 14px;
                            line-height: 26px;
                            color: #2d2d2d;
                        }
                    }
                }
                li:nth-child(1)， li:nth-child(2){
                    top: 0;
                }
                li:nth-child(3), li:nth-child(4){
                    top: 182px;
                }
                li:nth-child(2n+1){
                    left: -940px;
                }
                li:nth-child(2n){
                    right: -940px;
                }
                li:hover{
                    border: 1px solid #a3b3ef;
                    box-shadow: 0 0 10px rgba(0,0,0,0.1);
                    img{
                        -webkit-filter: none;
                        -moz-filter: none;
                        -ms-filter: none;
                        -o-filter: none;
                        filter: none;
                        opacity: 1;
                    }
                }
            }
        }
    }

    .platform-adv{
        background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/qiyepingtai/bg_1.jpg') center no-repeat;
        padding: 62px 0;
        .platform-adv-center{
            width: 1184px;
            margin: 0 auto;
            h3{
                font-size: 34px;
                color: #0B1D30;
                margin-bottom: 60px;
                font-weight: 400;
            }
            ul{
                display: flex;
                li{
                    width: 296px;
                    height: 430px;
                    box-sizing: border-box;
                    padding: 62px 30px; 
                    cursor: pointer;
                    img{
                        width: 66px;
                        height: 66px;
                        margin-bottom: 25px;
                    }
                    h4{
                        font-size: 22px;
                        font-weight: 400;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 16px;
                        line-height: 30px;
                    }
                }
                li:nth-child(2n+1){
                    color: #ffffff;
                    background-color: #282828;
                }
                li:nth-child(2n){
                    color: #0B1D30;
                    background-color: #ffffff;
                    p{
                        color: #757F92;
                    }
                }
                li:hover{
                    background-color: #053EF5;
                    color: #ffffff;
                    p{
                        color: #ffffff;
                    }
                }
            }
        }
    }

    .target-user{
        padding: 90px 0;
        background-color: #ffffff;
        h3{
            width: 1184px;
            margin: 0 auto;
            margin-bottom: 60px;
            font-size: 34px;
            color: #181818;
            font-weight: 400;
        }
        img{
            width: 296px;
            height: 500px;
        }
        .my-swiper{
            width: 1184px;
            height: 500px;
            // overflow: hidden;
            margin: 0 auto;
            position: relative;
            background-color: #eeeeee;
            .swiper-slide{
                cursor: pointer;
                >div.content{
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    z-index: 9;
                    div{
                        position: relative;
                        width: 100px;
                        height: 100px;
                        margin-bottom: 15px;
                        text-align: center;
                        img{
                            width: 80px;
                            height: 80px;
                        }
                    }
                    p{
                        font-size: 24px;
                        color: #ffffff;
                        text-align: center;
                    }
                }
                .bg{
                    width: 296px;
                    height: 500px;
                    background-color: rgba(40, 40, 40, 0.7);
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
            .swiper-slide:hover{
                .bg{
                    background-color: rgba(5, 62, 245, 0.8);
                }
            }
            .swiper-button-prev{
                position: absolute;
                left: -50px;
                top: 50%;
                transform: translateY(-50%);
            }
            .swiper-button-next{
                position: absolute;
                right: -50px;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
}
</style>

