<template>
  <!-- 工业云子站 -->
  <div class="bigBoxNav w100">
    <div class="centerArea clearfix">
      <div class="left">
        <ul>
          <template v-for="(item,index) in oneClass">
          <li :key="index + 'oneCat'" @mouseover="checkedStyle(index)">{{item.name}}<span></span></li>
          </template>
        </ul>
      </div>
      <div class="right">
        <template v-for="(item,index) in oneClass">
        <ul class="clearfix" :key="index + 'oneCatSon'">
          <template v-for="(itemSon,indexSon) in item.son">
          <li :key="indexSon + 'twoCatSon'"><a href="javascript:;">
            <div>
              <img :src="itemSon.img" alt="">
              <b>{{itemSon.title}}</b>
              <img :src="itemSon.img2" alt="">
            </div>
            <p>{{itemSon.synopsis}}</p>
          </a></li>
          </template>
        </ul>
        </template>
        <!-- <ul class="clearfix">
          <li><a href="javascript:;">
            <div>
              <b>高新区</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>新华区</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>任丘市</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>青县</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>肃宁县</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>泊头市</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>沧县</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>沧州经济开发区</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>渤海新区</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>南皮县</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>东光县</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>黄骅市</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>孟村县</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>海兴县</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>盐山县</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
          <li><a href="javascript:;">
            <div>
              <b>吴桥县</b>
            </div>
            <p>海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能</p>
          </a></li>
        </ul> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      oneClass: [
        {
          name: '行业',
          son: [{
            title: '行业模具',
            img: require('../assets/image/ico.png'),
            img2: require('../assets/image/ico1.png'),
            synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
          },{
            title: '行业模具',
            img: require('../assets/image/ico.png'),
            img2: require('../assets/image/ico1.png'),
            synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
          },{
            title: '行业模具',
            img2: require('../assets/image/ico1.png'),
            img: require('../assets/image/ico.png'),
            synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
          },{
            title: '行业模具',
            img2: require('../assets/image/ico1.png'),
            img: require('../assets/image/ico.png'),
            synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
          },{
            title: '行业模具',
            img: require('../assets/image/ico.png'),
            img2: require('../assets/image/ico1.png'),
            synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
          },{
            title: '行业模具',
            img: require('../assets/image/ico.png'),
            img2: require('../assets/image/ico1.png'),
            synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
          },{
            title: '行业模具',
            img: require('../assets/image/ico.png'),
            img2: require('../assets/image/ico1.png'),
            synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
          },{
            title: '行业模具',
            img: require('../assets/image/ico.png'),
            img2: require('../assets/image/ico1.png'),
            synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
          },{
            title: '行业模具',
            img: require('../assets/image/ico.png'),
            img2: require('../assets/image/ico1.png'),
            synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
          }]
        }, {
          name: '区域',
          son: [{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          },{
            title: '高新区',
            img: '',
            img2: '',
            synopsis: '沧州高新区以高端装备双创平台为依托，规划建设了人工智能装备产业园；以华为云计算大数据中心、苏州纳米所为依托，规划建设了信息技术产业园'
          }]
        }
      ],

    }
  },
  methods: {
    defaultDisplay() {
      $('.bigBoxNav .centerArea .left ul').find('li').eq(0).addClass('show')
      $('.bigBoxNav .centerArea .right').find('ul').eq(0).addClass('show')
    },
    checkedStyle(index) {
      $('.bigBoxNav .centerArea .left ul').find('li').eq(index).addClass('show')
      $('.bigBoxNav .centerArea .left ul').find('li').eq(index).siblings().removeClass('show')
      $('.bigBoxNav .centerArea .right').find('ul').eq(index).addClass('show')
      $('.bigBoxNav .centerArea .right').find('ul').eq(index).siblings().removeClass('show')
    },
  },
  mounted() {
    this.defaultDisplay()
  }
}
</script>

<style lang="scss">
  html,body {padding: 0px;margin: 0px;height: 100%; }
  .clearfix:after { content: "";clear: both;display: block; }
  .clearfix { *zoom: 1; }
  .fl { float: left; }
  .fr { float: right; }
  ul,li,p, h1,h2,h3,h4,h5,h6 { margin: 0;padding: 0; }
  li { list-style: none; }
  .w100{width:100%;}
  a{ text-decoration: none; color: #333333;}

  
  /* 定义滚动条样式 */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #f5f5f5;
  }
  
  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    // box-shadow: inset 0 0 0px rgba(0, 0, 0, 0.5);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  
  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 0px rgba(100, 100, 100, .3);
    background-color: rgba(100, 100, 100, .3);
  }

  .bigBoxNav{
    height: 340px;
    background-color: #f5f5f5;
    text-align: left;
    .centerArea{
      padding: 20px 0;
      margin: 0 auto;
      @media screen and (min-width: 1440px) {
        width: 1440px;
      }
      @media (max-width: 1439px) and (min-width: 1200px) {
        width: 1200px;
      }
      .left{
        width: 149px;
        height: 300px;
        border-right: 1px solid #dcdfe6;
        float: left;
        ul{
          li{
            margin-top: 50px;
            line-height: 50px;
            position: relative;
            font-size: 22px;
            color: #757F92;
            text-indent: 20px;
            cursor: pointer;
            span{
              // position: absolute;
              // left: 18px;
              // bottom: 0;
              width: 0;
              border: 0 none;
            }
            span::before{
              content: "";
              position: absolute;
              bottom: 0;
              left: 18px;
              width: 0;
              height: 100%;
              border-bottom: 2px solid #0b1d30;
              transition: all 1s ease;
            }
          }
        }
        ul li.show{
          color: #0b1d30;
          span::before{
            content: "";
            position: absolute;
            bottom: 0;
            left: 18px;
            width: 48px;
            height: 100%;
            border-bottom: 2px solid #0b1d30;
            transition: all 1s ease;
          }
        }
      }
      .right{
        height: 300px;
        float: left;
        overflow-y: auto;
        @media screen and (min-width: 1440px) {
          width: 1290px;
        }
        @media (max-width: 1439px) and (min-width: 1200px) {
          width: 1050px;
        }
        ul{ 
          display: none;
          li{
            float: left;
            width: 204px;
            margin: 0 58px 36px 58px;
            &:hover{
              div b{
                color: #1989fa !important;
              }
            }
            div{
              margin-top: 16px;
              margin-bottom: 6px;
              display: flex;
              align-items: center;
              b{
                display: inline-block;
                margin-left: 10px;
                margin-right: 20px;
                font-size: 14px;
                color: #0b1d30;
              }
            }
            p{
              color: #757f92;
              font-size: 12px;
              line-height: 18px;
            }
          }
        }
        ul.show{
          display: block;
        }
        ul:nth-child(2){
          li div b{
            margin-left: 0;
          }
        }
      }
    }
  }

</style>