<template>
    <div class="new_content">
        <div class="view-main" v-show="newGuideIndex == 0">
            <div class="view-content">
                <div class="view-title">欢迎访问沧州工业互联网综合服务平台</div>
                <div class="view-desc">为企业赋能 筑数字沧州</div>
                <div class="view-stitle">您可以在这里</div>
                <div class="view-arrow"></div>
                <div class="view-icon">
                    <div class="view-icon-item"  @click="openNewGuide(1)">
                        <div class="view-icon-bg">
                            <img src="../assets/newguide/icon1.png" alt />
                        </div>
                        <div class="view-icon-text">查询或购买产品＆解决方案</div>
                    </div>
                    <div class="view-empty"></div>
                    <div class="view-icon-item" @click="openNewGuide(3)">
                        <div class="view-icon-bg">
                            <img src="../assets/newguide/icon2.png" alt />
                        </div>
                        <div class="view-icon-text">发布需求和服务</div>
                    </div>
                    <div class="view-empty"></div>
                    <div class="view-icon-item" @click="openNewGuide(6)">
                        <div class="view-icon-bg">
                            <img src="../assets/newguide/icon3.png" alt />
                        </div>
                        <div class="view-icon-text">成为合作伙伴</div>
                    </div>
                </div>
                <div class="view-btn">
                    <div class="view-btn-liaojie" @click="openNewGuide(2)">我想了解沧州工业云</div>
                    <div class="view-btn-guang" @click="closeNewGuide">随便逛逛</div>
                </div>
            </div>
            <div class="view-close" @click="closeNewGuide">
                <span class="el-icon-close"></span>
            </div>
        </div>
        <div class="view-product" v-show="newGuideIndex==1">
            <div class="pro_nav">
                <div class="pro_nav1">
                    <img src="../assets/newguide/pronav1.png" alt />
                </div>
                <div class="pro_nav2">
                    <img src="../assets/newguide/pronav2.png" alt />
                </div>
            </div>
            <div class="view-content">
                <div class="pro_left">
                    <img src="../assets/newguide/protab.png" alt />
                </div>
                <div class="pro_center">
                    <img src="../assets/newguide/proarrow.png" alt />
                </div>
                <div class="pro_right">
                    <img src="../assets/newguide/prosearch.png" alt />
                </div>
                <div class="pro_text">
                    <p class="pro_title">您可以在这里寻找产品</p>
                    <p class="pro_desc">您可以在左侧导航栏浏览、或者在搜索框搜索您感兴趣 的产品和服务</p>
                    <div class="pro_btn">
                        <div class="pro_btn_know" @click="openNewGuide(0)">我知道了</div>
                        <div class="pro_btn_how" @click="openNewGuidePage(4,'/SoftwareMarket/details?id=703')">如何购买产品</div>
                    </div>
                </div>
            </div>
            <div class="view-close" @click="closeNewGuide">
                <span class="el-icon-close"></span>
            </div>
        </div>
        <div class="view-liaojie" v-show="newGuideIndex==2">
            <div class="liaojie_nav">
                <img class="liaojie_nav1" src="../assets/newguide/liaojie_tab1.png" alt />
                <img class="liaojie_nav2" src="../assets/newguide/liaojie_tab2.png" alt />
            </div>
            <div class="view-content">
                <div class="liaojie_arrow1">
                    <img src="../assets/newguide/liaojie_arrow1.png" alt />
                    <p>您可以从这里开始了解沧州工业云</p>
                </div>
                <div class="liaojie_arrow2">
                    <img src="../assets/newguide/liaojie_arrow2.png" alt />
                    <p>您可以点击这里了解沧州工业云的公司介绍，发展历程，荣誉奖项</p>
                </div>
            </div>
            <div class="liaojie_btn" @click="openNewGuide(0)">我知道了</div>
            <div class="view-close" @click="closeNewGuide">
                <span class="el-icon-close"></span>
            </div>
        </div>
        <div class="view-xuqiu" v-show="newGuideIndex==3">
            <div class="xuqiu_nav">
                <img class="xuqiu_nav1" src="../assets/newguide/xuqiu_nav.png" alt />
            </div>
            <div class="view-content">
                <div class="xuqiu_arrow">
                    <img src="../assets/newguide/xuqiu_arrow.png" alt />
                    <p class="xuqiu_title">您可以在这里发布需求</p>
                    <p class="xuqiu_desc">您可以点击这里发布您的需求，如招标采购，闲置物资交易，海量的信息自由匹配，满足您企业采购需求</p>
                </div>
            </div>
            <div class="xuqiu_btn"  @click="openNewGuide(0)">我知道了</div>
            <div class="view-close" @click="closeNewGuide">
                <span class="el-icon-close"></span>
            </div>
        </div>
        <div class="view-buy" v-show="newGuideIndex==4">
            <div class="view-content">
                <img class="buy_arrow1" src="../assets/newguide/buy_arrow1.png" alt />
                <img class="buy_arrow2" src="../assets/newguide/buy_arrow2.png" alt />
                <img class="buy_arrow3" src="../assets/newguide/buy_arrow3.png" alt />
                <p class="buy_text buy_text1">如果产品能满足您的需求，可以直接下单购买，我们会有专人协助您部署或者指导您使用！</p>
                <p class="buy_text buy_text2">如果您对产品或咨询，可点击联系客服，会帮您进行解答或安排专业人员为您解决问题</p>
                <p class="buy_text buy_text3">您可以点击免费试用，我们会安排技术人员帮您配置软件免费试用体验</p>
                <div class="buy_btn buy_btn1">立即购买</div>
                <div class="buy_btn buy_btn2">服务咨询</div>
                <div class="buy_btn buy_btn3">免费试用</div>
            </div>
            <div class="buy_btn">我知道了</div>
            <div class="view-close" @click="closeNewGuide">
                <span class="el-icon-close"></span>
            </div>
        </div>
        <div class="view-gongye" v-show="newGuideIndex==5">
            <div class="gongye_nav">
                <img class="gongye_nav1" src="../assets/newguide/gongye_nav.png" alt />
            </div>
            <div class="view-content">
                <div class="gongye_arrow">
                    <img src="../assets/newguide/gongye_arrow.png" alt />
                    <p class="gongye_title">您可以在这里了解工业物联网相关内容</p>
                    <p class="gongye_desc">实现人、机、物互联互通，方便识别、管理和控制。</p>
                </div>
            </div>
            <div class="gongye_btn">我知道了</div>
            <div class="view-close" @click="closeNewGuide">
                <span class="el-icon-close"></span>
            </div>
        </div>
        <div class="view-czcloud" v-show="newGuideIndex==6">
            <div class="cloud_nav">
                <img src="../assets/newguide/czcloud_tab.png" alt />
            </div>
            <div class="view-content">
                <div class="cloud_img">
                    <img src="../assets/newguide/czcloud_img.png" alt />
                </div>
                <div class="cloud_arrow">
                    <img src="../assets/newguide/czcloud_arrow.png" alt />
                </div>
                <div class="cloud_text">选择您想要合作的项目</div>
                <div class="cloud_btn"  @click="openNewGuide(0)">我知道了</div>
            </div>
            <div class="view-close" @click="closeNewGuide">
                <span class="el-icon-close"></span>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "newGuidelines",
    data() {
        return {
            newGuideIndex: 0,
        };
    },
    methods: {
        closeNewGuide() {
            this.$emit("hideNewGuide", "close");
        },
        openNewGuide(i){
            this.newGuideIndex = i
        },
        openNewGuidePage(i,url){
            this.newGuideIndex = i
            this.$router.push({
                path: url,
            })
        },
    },
};
</script>
<style scoped lang="scss">
* {
    box-sizing: border-box;
}
.new_content {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba($color: #000000, $alpha: 0.6);
    z-index: 9999;
    .view-close {
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        font-size: 40px;
        cursor: pointer;
    }
    .view-product {
        position: relative;
        background: url("../assets/newguide/probg.png") no-repeat;
        width: 100%;
        height: 100%;
        .view-close {
            top: 114px;
            left: 1525px;
        }
        .pro_nav {
            width: 100%;
            height: 62px;
            display: flex;
            .pro_nav1 {
                margin-left: 430px;
                cursor: pointer;
            }
            .pro_nav2 {
                margin-left: 255px;
                cursor: pointer;
            }
        }
        .view-content {
            display: flex;
            .pro_left {
                margin-left: 190px;
                margin-top: 30px;
            }
            .pro_center {
                margin-left: 5px;
                margin-top: -36px;
            }
            .pro_right {
                margin-left: -90px;
                margin-top: 30px;
            }
            .pro_text {
                margin-top: 210px;
                margin-left: -300px;
                .pro_title {
                    font-size: 32px;
                    font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 38px;
                    letter-spacing: 3px;
                    margin-bottom: 25px;
                }
                .pro_desc {
                    width: 488px;
                    font-size: 18px;
                    font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 21px;
                    letter-spacing: 1px;
                    margin-bottom: 100px;
                }
                .pro_btn {
                    font-size: 16px;
                    line-height: 50px;
                    text-align: center;
                    height: 50px;
                    display: flex;
                    color: #fff;
                    cursor: pointer;
                    .pro_btn_know {
                        width: 109px;
                        background: #004baa;
                        margin-right: 30px;
                    }
                    .pro_btn_how {
                        width: 144px;
                        border: 1px solid #ffffff;
                    }
                }
            }
        }
    }
    .view-xuqiu {
        position: relative;
        background: url("../assets/newguide/czcloud_bg.png") no-repeat;
        width: 100%;
        height: 100%;
        color: #fff;
        .view-close {
            top: 114px;
            left: 1525px;
        }
        .xuqiu_nav {
            .xuqiu_nav1 {
                margin-left: 537px;
            }
        }
        .xuqiu_btn {
            width: 109px;
            height: 50px;
            background: #004baa;
            text-align: center;
            line-height: 50px;
            color: #fff;
            font-size: 16px;
            font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
            font-weight: 400;
            letter-spacing: 1px;
            margin-left: 905px;
            margin-top: 128px;
            cursor: pointer;
        }
        .view-content {
            margin-bottom: 80px;
            .xuqiu_arrow {
                margin-left: 640px;
                margin-top: -36px;
                margin-bottom: 16px;
                .xuqiu_title {
                    font-size: 32px;
                    font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 38px;
                    letter-spacing: 3px;
                    margin-bottom: 22px;
                }
                .xuqiu_desc {
                    width: 496px;
                    font-size: 18px;
                    font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 21px;
                    letter-spacing: 1px;
                }
            }
        }
    }
    .view-buy {
        position: relative;
        background: url("../assets/newguide/buy_bg.png") no-repeat;
        width: 100%;
        height: 100%;
        color: #fff;
        .view-close {
            top: 114px;
            left: 1525px;
        }
        .view-content {
            width: 100%;
            height: 100%;
            position: relative;

            * {
                position: absolute;
            }
            .buy_arrow1 {
                top: 436px;
                left: 704px;
            }
            .buy_arrow2 {
                top: 280px;
                left: 566px;
            }
            .buy_arrow3 {
                top: 188px;
                left: 430px;
            }
            .buy_text {
                width: 496px;
                font-size: 18px;
                font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                font-weight: 400;
                color: #ffffff;
                line-height: 21px;
                letter-spacing: 1px;
            }
            .buy_text1 {
                top: 165px;
                left: 692px;
            }
            .buy_text2 {
                top: 256px;
                left: 692px;
            }
            .buy_text3 {
                top: 468px;
                left: 778px;
            }
            .buy_btn {
                text-align: center;
                line-height: 40px;
                cursor: pointer;
                top: 377px;
                width: 122px;
                height: 40px;
            }
            .buy_btn1 {
                left: 368px;
                background: #1f51c8;
                color: #fff;
            }
            .buy_btn2 {
                left: 505px;
                background: #00aff0;
            }
            .buy_btn3 {
                left: 642px;
                background: #fff;
                color: #000;
            }
        }
    }
    .view-gongye {
        position: relative;
        background: url("../assets/newguide/czcloud_bg.png") no-repeat;
        width: 100%;
        height: 100%;
        color: #fff;
        .view-close {
            top: 114px;
            left: 1525px;
        }
        .gongye_nav {
            .gongye_nav1 {
                margin-left: 672px;
            }
        }
        .gongye_btn {
            width: 109px;
            height: 50px;
            background: #004baa;
            text-align: center;
            line-height: 50px;
            color: #fff;
            font-size: 16px;
            font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
            font-weight: 400;
            letter-spacing: 1px;
            margin-left: 905px;
            margin-top: 128px;
            cursor: pointer;
        }
        .view-content {
            margin-bottom: 80px;
            .gongye_arrow {
                margin-left: 727px;
                margin-top: 10px;
                margin-bottom: 16px;
                img {
                    margin-bottom: 16px;
                }
                .gongye_title {
                    font-size: 32px;
                    font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 38px;
                    letter-spacing: 3px;
                    margin-bottom: 22px;
                    margin-left: -220px;
                }
                .gongye_desc {
                    width: 496px;
                    font-size: 18px;
                    font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 21px;
                    letter-spacing: 1px;
                    margin-left: -218px;
                }
            }
        }
    }
    .view-liaojie {
        position: relative;
        background: url("../assets/newguide/czcloud_bg.png") no-repeat;
        width: 100%;
        height: 100%;
        color: #fff;
        .view-close {
            top: 114px;
            left: 1525px;
        }
        .liaojie_nav {
            .liaojie_nav1 {
                margin-left: 288px;
            }
            .liaojie_nav2 {
                margin-left: 608px;
            }
        }
        .liaojie_btn {
            width: 109px;
            height: 50px;
            background: #004baa;
            text-align: center;
            line-height: 50px;
            color: #fff;
            font-size: 16px;
            font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
            font-weight: 400;
            letter-spacing: 1px;
            margin-left: 905px;
            margin-top: 128px;
            cursor: pointer;
        }
        .view-content {
            overflow: hidden;
            > div {
                float: left;
            }
            .liaojie_arrow1 {
                margin-left: 360px;
                font-size: 18px;
                font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                font-weight: 400;
                line-height: 21px;
                letter-spacing: 1px;
                p {
                    margin-left: 84px;
                    margin-top: -12px;
                }
            }
            .liaojie_arrow2 {
                width: 496px;
                text-align: center;
                font-size: 18px;
                font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                font-weight: 400;
                color: #ffffff;
                line-height: 21px;
                letter-spacing: 1px;
                margin-left: 132px;
                margin-top: 5px;
                p {
                    margin-top: 8px;
                    text-align: left;
                }
            }
        }
    }
    .view-czcloud {
        position: relative;
        background: url("../assets/newguide/czcloud_bg.png") no-repeat;
        width: 100%;
        height: 100%;
        .view-close {
            top: 114px;
            left: 1525px;
        }
        .cloud_nav {
            margin-left: 1034px;
            display: flex;
        }
        .cloud_img {
            margin-top: 150px;
            margin-left: 780px;
            display: flex;
        }
        .cloud_arrow {
            margin-top: -242px;
            margin-left: 1000px;
            display: flex;
            margin-bottom: 118px;
        }
        .cloud_text {
            font-size: 18px;
            font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
            font-weight: 400;
            color: #ffffff;
            line-height: 21px;
            letter-spacing: 1px;
            margin-left: 1093px;
            margin-top: -135px;
            margin-bottom: 160px;
        }
        .cloud_btn {
            width: 109px;
            height: 50px;
            background: #004baa;
            color: #fff;
            text-align: center;
            line-height: 50px;
            margin-left: 905px;
            cursor: pointer;
        }
    }
    .view-main {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        width: 1200px;
        margin: 0 auto;
        position: relative;

        .view-content {
            margin-top: 110px;
            color: #fff;
            // display: flex;
            // flex-direction: column;
            // align-items: flex-start;

            .view-title {
                font-size: 34px;
                font-family: Microsoft YaHei UI-Bold, Microsoft YaHei UI;
                font-weight: bold;
                color: #ffffff;
                line-height: 40px;
                letter-spacing: 3px;
                text-align: center;
                margin-bottom: 30px;
            }
            .view-desc {
                font-size: 24px;
                font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                font-weight: 400;
                color: #ffffff;
                line-height: 28px;
                letter-spacing: 2px;
                text-align: center;
                margin-bottom: 60px;
            }
            .view-stitle {
                font-size: 32px;
                font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
                font-weight: 400;
                color: #ffffff;
                line-height: 38px;
                letter-spacing: 3px;
                margin-bottom: 44px;
                padding-left: 84px;
            }
            .view-arrow {
                background: url("../assets/newguide/arrow.png") no-repeat;
                width: 760px;
                height: 101px;
                margin: 0 auto;
                background-position: center;
                background-size: cover;
                margin-bottom: 15px;
            }
            .view-icon {
                display: flex;
                justify-content: center;
                margin-bottom: 90px;
                .view-icon-item {
                    width: 280px;
                    text-align: center;
                    background: url("../assets/newguide/bg.png") no-repeat;
                    cursor: pointer;
                    .view-icon-bg {
                        height: 108px;
                        padding-top: 18px;
                        img {
                            width: 70px;
                            height: 70px;
                            display: block;
                            margin: 0 auto;
                        }
                    }
                    .view-icon-text {
                        background: #ffffff;
                        height: 82px;
                        line-height: 82px;
                        font-size: 18px;
                        font-family: Microsoft YaHei UI-Regular,
                            Microsoft YaHei UI;
                        font-weight: 400;
                        color: #757f92;
                        letter-spacing: 1px;
                    }
                }
                .view-empty {
                    width: 94px;
                }
            }
            .view-btn {
                padding-left: 84px;
                text-align: center;
                line-height: 50px;
                display: flex;
                cursor: pointer;
                .view-btn-liaojie {
                    width: 197px;
                    height: 50px;
                    border: 1px solid #ffffff;
                    margin-right: 60px;
                }
                .view-btn-guang {
                    width: 109px;
                    height: 50px;
                    border: 1px solid #ffffff;
                }
            }
        }
    }
}
</style>