<template>
    <div class="control">
        <div class="p-b-40">
            <userCenterTitle title="我的待办"></userCenterTitle>
            <div class>
                <el-row :gutter="5">
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <p class="grid-title">待付款订单</p>
                            <p class="grid-number">{{countToPayOrders}}</p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <p class="grid-title">企业认证</p>
                            <p class="grid-text" v-if="isAuthed">已认证</p>
                            <p class="grid-text" v-else>待认证</p>
                            <p class="grid-success"></p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <p class="grid-title">待续费</p>
                            <div class="grid-text" v-if="countToPaySystems < 0">
                                <p class="flex1">未开通</p>
                                <p class="grid-href">
                                    <!-- <span><router-link to="">立即续费</router-link></span> -->
                                </p>
                            </div>
                            <div class="grid-text" v-else>
                                <p class="grid-number grid-number2">{{countToPaySystems}}</p>
                                <p class="grid-href">
                                    <!-- <span><router-link to="">立即续费</router-link></span> -->
                                </p>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content bg-purple">
                            <p class="grid-title">个人资料</p>
                            <div class="grid-text" v-if="isFinished">
                                <p class="flex1">已完善</p>
                                <p class="grid-href">
                                    <span><router-link to="/userCenter/myMessage">修改资料</router-link></span>
                                </p>
                            </div>
                            <div class="grid-text" v-else>
                                <p class="flex1">未完善</p>
                                <p class="grid-href">
                                    <span><router-link to="/userCenter/myMessage">完善资料</router-link></span>
                                </p>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="p-b-40">
            <userCenterTitle title="控制台"></userCenterTitle>
            <div class="control-content">
                <ul class="clearfix" v-if="consoleApp.length > 0">
                    <li
                        v-for="item,index in consoleApp"
                        :key="index"
                        @click="consoleAppClick(item)"
                    >
                        <a href="javascript:;">
                            <img :src="item.icon" />
                            <p>{{item.systemName}}</p>
                        </a>
                    </li>
                </ul>
                <p class="empty-tips" v-else>
                    暂无产品，请点击
                    <a href="/softwareMarket/softwareHome" target="_blank">云产品家族</a> 选购产品
                </p>
            </div>
        </div>
        <div class="p-b-40">
            <userCenterTitle title="我的服务"></userCenterTitle>
            <div class>
                <el-table
                    v-loading="loading"
                    :data="tableData"
                    style="width: 100%"
                    :header-cell-style="{ background: '#f2f5fc' }"
                >
                    <el-table-column prop="systemsName" label="产品名称"></el-table-column>
                    <el-table-column prop="startTime" label="开始时间"></el-table-column>
                    <el-table-column prop="dueTime" label="到期时间"></el-table-column>
                    <el-table-column label="操作" width="100" align="center">
                        <template slot-scope="scope">
                            <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pageBox">
                    <el-pagination
                        background
                        layout="prev, pager, next"
                        :page-size="pageSize"
                        :total="total"
                        @current-change="changePage"
                        :hide-on-single-page="hideOnSinglePage"
                    ></el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import userCenterTitle from "@/components/user-center-title";
var chars = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
];

export default {
    data() {
        return {
            total: 20,
            pageNum: 1,
            pageSize: 10,
            loading: true,
            hideOnSinglePage: true,
            tableData: [],
            consoleApp: [],
            countToPayOrders: 0, //待付款订单数
            countToPaySystems: -1, //待续费应用数量 -1：未开通
            isAuthed: false, //是否供应商认证
            isFinished: false, //个人信息是否已完善
        };
    },
    components: {
        userCenterTitle,
    },
    mounted() {
        this.getTableData();
        this.getMyToDoList();
        this.getMyApplication();
    },
    methods: {
        hideLoading() {
            this.loading = false;
        },
        consoleAppClick(item) {
            let _this = this;
            if(!item.available){
                this.$message.warning({
                    dangerouslyUseHTMLString: true,
                    message: '<div style="line-height:20px;">服务暂无法使用，原因：<br/>1.正在为您开通服务<br/>2.服务已到期<br/>如有疑问请联系客服~<div>'
                })
                return
            }
            _this.$api.usercenter
                .getMyAppLoginUrl({
                    id: item.consoleUserId,
                })
                .then((res) => {
                    if (res.success) {
                        if (res.data.landingMode == 1) {
                            window.open(res.data.systemsUrl, "_blank");
                        } else {
                            this.loadcrm(res.data)
                        }
                    }
                })
                .catch((err) => {
                    let response = err.response;
                    // if (response.status == 401) {
                    //     this.$message.error("请重新登陆");
                    //     setTimeout(() => {
                    //         this.$router.replace({
                    //             path: "/login",
                    //         });
                    //     }, 1500);
                    // }
                });
        },
        //根据用户名称的长度生成随机数
        generateMixed(n) {
            var res = "";
            for (var i = 0; i < n; i++) {
                var id = Math.ceil(Math.random() * 15);
                res += chars[id];
            }
            return res;
        },

        //name:表示登录用户名称
        createTokenByName(name) {
            var strlen = name.length;
            return this.generateMixed(strlen);
        },
        //转换用户名称或token循环遍历str,替换每个字符串
        convert_str(str) {
            var strlen = str.length;
            var digit = "0123456789";
            var loweralpha = "abcdefghijklmnopqrstuvwxyz";
            var upperalpha = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            var ret_name = "";
            for (var i = 0; i < strlen; i++) {
                var ch = str.substr(i, 1);
                if (digit.indexOf(ch) >= 0) {
                    //,如果是数字
                    //10:变量digit的字符长度
                    ret_name += digit.substr(9-digit.indexOf(ch), 1); //对调
                } else if (loweralpha.indexOf(ch) >= 0) {
                    ret_name += loweralpha.substr(25-loweralpha.indexOf(ch),1); //对调
                } else if (upperalpha.indexOf(ch) >= 0) {
                    ret_name += upperalpha.substr(25-upperalpha.indexOf(ch),1); //对调
                } else {
                    ret_name += ch;
                }
            }
            return ret_name;
        },
        createName(token, name) {
            var token_name = name + token;
            token_name = token_name.split("").reverse().join(""); //逆序name
            return token_name;
        },
        loadcrm(obj) {
            let detailParams = JSON.parse(obj.detailParams)
         
            //tokenkey：设置->系统设置->参数设置->系统参数设置->业务参数中的“API接口授权码”
            //在系统正式启用之后，请修改默认的API接口授权
            var str = detailParams[0].value; //修改为需要单点登录的用户账号
            var after_str = this.convert_str(str);
            var token = this.createTokenByName(after_str);
            var user_name = this.createName(this.convert_str(token), after_str);

            let url = ""
            detailParams.forEach((el,i)=>{
                if(i==0){
                    url += "&" + el.name + "=" + user_name + "&token=" + token
                }else{
                    url += "&" + el.name + "=" + el.value
                }
            })
            url = obj.systemsUrl + url

            // if(obj.type == 1){
            //     url = obj.systemsUrl + "user_name=" + user_name + "&token=" + token + "&tokenKey=" + obj.tokenKey;
            // }else{
            //     url = obj.systemsUrl + "name=" + user_name + "&token=" + token + "&tokenkey=" + obj.tokenKey;
            // }

            // crm   tokenKey =>  tokenkey  大写改小写
            window.open(url, "_blank");
                
        },
        getMyApplication() {
            let _this = this;
            _this.$api.usercenter
                .getMyApplication()
                .then((res) => {
                    if (res.success) {
                        _this.consoleApp = res.data;
                    }
                })
                .catch((err) => {
                    let response = err.response;
                    if (response.status == 401) {
                        this.$message.error("请重新登陆");
                        setTimeout(() => {
                            this.$router.replace({
                                path: "/login",
                            });
                        }, 1500);
                    }
                });
        },
        getMyToDoList() {
            let _this = this;
            _this.$api.usercenter
                .getMyToDoList()
                .then((res) => {
                    if (res.success) {
                        _this.countToPayOrders = res.data.countToPayOrders;
                        _this.countToPaySystems = res.data.countToPaySystems;
                        _this.isAuthed = res.data.isAuthed;
                        _this.isFinished = res.data.isFinished;
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    let response = err.response;
                    if (response.status == 401) {
                        this.$message.error("请重新登陆");
                        setTimeout(() => {
                            this.$router.replace({
                                path: "/login",
                            });
                        }, 1500);
                    }
                });
        },
        changePage(e) {;
            _this.pageNum = e;
            _this.getTableData();
        },
        getTableData() {
            let _this = this;
            _this.$api.usercenter
                .getMyServerList()
                .then((res) => {
                    this.hideLoading();
                    if (res.code == 200) {
                        _this.tableData = res.data.records;
                        _this.total = res.data.total;
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    let response = err.response;
                    if (response.status == 401) {
                        this.$message.error("请重新登陆");
                        setTimeout(() => {
                            this.$router.replace({
                                path: "/login",
                            });
                        }, 1500);
                    }
                });
            // _this.$api.usercenter.getServersList({}).then((res) => {
            //     if (res.code == 200) {
            //         _this.tableData = res.rows;
            //         _this.total = res.total;
            //     }
            // });
        },
        handleClick(id) {
        },
    },
};
</script>


<style lang="scss" scoped>
.clearfix {
    zoom: 1;
}
.clearfix:after {
    display: block;
    content: "";
    clear: both;
}
.flex1 {
    flex: 1;
}
.p-b-40 {
    padding-bottom: 40px;
}
.el-row {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}
.grid-content {
    border-radius: 1px;
    padding: 12px 15px 0;
    background: #f2f5fc;
    height: 68px;
    box-sizing: border-box;
    position: relative;
    .grid-title {
        font-size: 12px;
        font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
        color: #757f92;
        line-height: 14px;
    }
    .grid-number {
        font-size: 24px;
        font-family: Arial-Regular, Arial;
        color: #0b1d30;
        line-height: 28px;
        margin-top: 6px;
    }
    .grid-number2 {
        margin-top: -6px;
    }
    .grid-success {
        // background: rgba(35, 187, 130, 0.3);
        width: 45px;
        height: 45px;
        border-radius: 50%;
        position: absolute;
        right: 15px;
        top: 12px;
        background: url("../../assets/image/renzheng.png") no-repeat;
        background-position: center;
        background-size: cover;
    }
    .grid-text {
        font-size: 14px;
        font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
        color: #0b1d30;
        line-height: 16px;
        margin-top: 12px;
        display: flex;
        .grid-href {
            flex: 1;
            text-align: right;
            font-size: 12px;
            font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
            color: #006fff;
            line-height: 14px;
            a{
                color: #006fff;
                cursor: pointer;
            }
        }
    }
}
.control-content {
    .empty-tips {
        text-align: center;
        font-size: 14px;
        color: #757f92;
        a {
            color: #006fff;
        }
    }
    ul {
        li {
            width: 186px;
            height: 46px;
            padding: 21px 0;
            margin-right: 35px;
            float: left;
            img {
                width: 46px;
                height: 46px;
                margin: 0 18px;
            }
            p {
                display: inline-block;
                width: 85px;
                line-height: 46px;

                font-size: 14px;
                font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
                font-weight: 300;
                color: #0B1D30;


                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}
.row-bg {
    padding: 10px 0;
    background-color: #f2f5fc;
}
/deep/ .el-table th.el-table__cell > .cell {
    font-size: 14px;
    font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
    color: #0b1d30;
    line-height: 16px;
}
/deep/ .el-table .cell {
    font-size: 12px;
    font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
    color: #757f92;
    line-height: 14px;
}
/deep/ .el-table thead {
    height: 46px;
    background: #f2f5fc;
    border-radius: 0px 0px 0px 0px;
    opacity: 1;
}
.el-table {
    margin-bottom: 30px;
}
.pageBox {
    overflow: hidden;
    .el-pagination {
        float: right;
    }
    /deep/ .el-pager li,
    /deep/ .el-pagination button {
        width: 35px;
        height: 26px;
        line-height: 26px;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #d7d7d7;
        background-color: #fff !important;

        font-size: 14px;
        font-family: DIN-Medium, DIN;
        font-weight: 500;
    }
    /deep/ .el-pager .active {
        width: 35px;
        height: 26px;
        line-height: 26px;
        background: #006fff !important;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #006fff;
    }
}
</style>