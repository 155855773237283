<template>
    <div class="winAward">
        <h3><img src="../../assets/image/honor1.png" alt="">重要荣誉</h3>
        <ul class="clearfix one">
	        <li><img src="../../assets/image/honor/zizhi240411.jpg" alt=""></li>
            <li><img src="../../assets/image/honor/zizhi2.png" alt=""></li>
            <li><img src="../../assets/image/honor/zizhi3.png" alt=""></li>
            <li><img src="../../assets/image/honor/zizhi4.png" alt=""></li>
            <li><img src="../../assets/image/honor/zizhi1.png" alt=""></li>
        </ul>

        <h3><img src="../../assets/image/honor2.png" alt="">重要资质</h3>

        <!-- <div class=" two">
            <ul class="clearfix">
                <li><img src="../../assets/image/honor/zizhi5.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi6.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi7.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi8.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi9.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi10.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi11.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi12.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi13.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi14.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi15.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi16.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi17.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi18.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi19.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi20.png" alt=""></li>
            </ul>
        </div> -->

        <div class="my-swiper">
            <swiper :options="swiperOptions">
                <swiper-slide v-for="(item,index) in imgs" :key="index"><img :src="item" alt=""></swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>

        <div class="three">
            <h3><img src="../../assets/image/honor3.png" alt="">领导关怀</h3>
            <p class="date">2022年04月14日</p>
            <p class="intro">沧州市政府副市长尹卫江，在高新区、市工信局、市科技局相关领导的陪同下，到河北中科物联信息技术有限公司指导工作。</p>
            <ul class="clearfix">
                <li><img src="../../assets/image/honor/czleader1.png" alt=""></li>
                <li><img src="../../assets/image/honor/czleader2.png" alt=""></li>
                <li><img src="../../assets/image/honor/czleader3.png" alt=""></li>
            </ul>
            <p class="date">2022年01月13日</p>
            <p class="intro">沧州市工业和信息化局领导一行莅临我司进行工作指导。</p>
            <ul class="clearfix">
                <li><img src="../../assets/image/honor/czleader4.png" alt=""></li>
                <li><img src="../../assets/image/honor/czleader5.png" alt=""></li>
                <li><img src="../../assets/image/honor/czleader6.png" alt=""></li>
            </ul>
            <p class="date">2021年12月16日</p>
            <p class="intro">沧州市通管局领导一行莅临我司进行工作指导。</p>
            <ul class="clearfix">
                <li><img src="../../assets/image/honor/czleader7.png" alt=""></li>
                <li><img src="../../assets/image/honor/czleader8.png" alt=""></li>
                <li><img src="../../assets/image/honor/czleader9.png" alt=""></li>
            </ul>
            <p class="date">2021年12月16日</p>
            <p class="intro">沧州市工业和信息化局一行3位领导莅临我司进行工作指导。</p>
            <ul class="clearfix">
                <li><img src="../../assets/image/honor/czleader10.png" alt=""></li>
                <li><img src="../../assets/image/honor/czleader11.png" alt=""></li>
                <li><img src="../../assets/image/honor/czleader12.png" alt=""></li>
            </ul>
            <p class="date">2021年11月19日</p>
            <p class="intro">承德市工业和信息化局局长刘俊一行8人专程来沧，在沧州市工业和信息化局、沧州高新区管委会领导陪同下，参观考察学习"沧州工业云平台"。</p>
            <ul class="clearfix">
                <li><img src="../../assets/image/honor/cleader1.png" alt=""></li>
                <li><img src="../../assets/image/honor/cleader2.png" alt=""></li>
                <li><img src="../../assets/image/honor/cleader3.png" alt=""></li>
            </ul>
            <p class="date">2020年11月</p>
            <p class="intro">河北省工业和信息化厅副厅长及沧州市工信局局长等人莅临公司考察指导</p>
            <ul class="clearfix">
                <!-- <li><img src="../../assets/image/honor/zizhi21.png" alt=""></li> -->
                <li><img src="../../assets/image/honor/zizhi22.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi23.png" alt=""></li>
            </ul>
            <p class="date">2020年10月28日</p>
            <p class="intro">河北省农业农村厅、沧州市长等一行领导莅临河北农博会“中科物联”展厅指导</p>
            <ul class="clearfix">
                <li><img src="../../assets/image/honor/zizhi24.png" alt=""></li>
                <li><img src="../../assets/image/honor/zizhi25.png" alt=""></li>
            </ul>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
    data() {
        return{
            imgs: [
                require('../../assets/image/honor/zizhi5.png'),
                require('../../assets/image/honor/zizhi6.png'),
                require('../../assets/image/honor/zizhi7.png'),
                require('../../assets/image/honor/zizhi8.png'),
                require('../../assets/image/honor/zizhi9.png'),
                require('../../assets/image/honor/zizhi10.png'),
                require('../../assets/image/honor/zizhi11.png'),
                require('../../assets/image/honor/zizhi12.png'),
                require('../../assets/image/honor/zizhi13.png'),
                require('../../assets/image/honor/zizhi14.png'),
                require('../../assets/image/honor/zizhi15.png'),
                require('../../assets/image/honor/zizhi16.png'),
                //require('../../assets/image/honor/zizhi17.png'),
                require('../../assets/image/honor/zizhi18.png'),
                //require('../../assets/image/honor/zizhi19.png'),
                require('../../assets/image/honor/zizhi20.png'),
            ],
            swiperOptions: {
                // pagination: '.swiper-pagination',
                // uniqueNavElements: false,
                // loop: true,
                // direction: 'horizontal',
                // notNextTick: true,
                // loopedSlides: 16,
                autoplay: {
                    delay: 3000,
                    speed: 5000,
                    disableOnInteraction: true
                },
                slidesPerView: 3,
                slidesPerColumn: 2,
                slidesPerColumnFill: 'row',
                // observer: true,
                // observerParents: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            }
        }
    },
    components: {
    },
    comments: {
        Swiper,
        SwiperSlide
    },
    methods: {
        scroll() {
            var $number = Math.ceil($('.two ul li').length/6); //获取滚动几屏个数
            var margin = 77; //设置图片间距
            var $w = $('.two li').width() + margin; // 一屏图片的宽度

            var $width = $w*Math.ceil($('.two ul li').length/2); //设置ul宽度
            if($number ==1){pre.hide();next.hide();}
            $('.two ul').width($width);
            var num = 0;
            function autoscroll(){
                num++;
                if($number ==1){return false}
                if(num == $number){
                    num = 0;
                }
                var distance = -3*$w * num;
                $('.two ul').stop().animate({left:distance});
            }
            var scrollChange = setInterval(autoscroll,5000);
        },
        initSwiper() {
            setTimeout(() => {
                this.swiperOptions
            }, 26000)
        }
    },
    mounted(){
        this.scroll();
        this.initSwiper();
    }
}
</script>

<style lang="scss">
.swiper-pagination{
    .swiper-pagination-bullet{
        width: 12px;
        height: 12px;
    }
}
</style>

<style lang="scss" scoped>
.swiper {
  height: 300px;
  width: 100%;

  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
  }
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets{
    bottom: -30px;
}
.swiper-pagination-bullets >span:nth-child(7){
    display: none;
}

.my-swiper{
    height: 600px;
    font-size: 30px;
    line-height: 100px;
    width: 960px;
    overflow: hidden;
    .swiper-slide{
        width: 272px !important;
        height: 254px !important;
        margin: 0 24px 48px;
        //background: url('../../assets/image/honorbg.png') bottom center no-repeat;
        text-align: center;
        img{
            height: 216px;
            width: auto;
        }
    }
}
.winAward{
    h3{
        text-align: left;
        margin-top: 50px;
        margin-bottom: 50px;
        img{
            margin-top: 3px;
            margin-right: 10px;
        }
    }
    ul {
        li{
            width: 272px;
            float: left;
            margin-right: 77px;
            text-align: center;
            margin-bottom: 80px;
            //background: url('../../assets/image/honorbg.png') 0 bottom no-repeat;
        }
        li:nth-child(3n+3){
            margin-right: 0;
        }
    }

    .one{
        li{
            height: 193px;
            img{
                height: 154px;
                width: auto;
            }
        }
    }
    .two{
        overflow: hidden;
        position: relative;
        height: 668px;
        ul{
            height: 588px;
            overflow: hidden;
            position: absolute;
            left: 0;
            top: 0;
            li{
                height: 254px;
                margin-right: 77px;
                img{
                    height: 216px;
                    width: auto;
                }
            }
        }
        .turn-page-btn{
            width: 60px;
            height: 20px;
            margin: 0 auto;
            position: absolute;
            bottom: 65px;
            left: 50%;

            li{
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: #757F92;
                float: left;
                margin: 45px 5px;
            }
            .show{
                background-color: #006FFF;
            }
        }
    }
    .three{
        text-align: left;
        h3{
            margin-bottom: 20px;
        }
        .date{
            color: #757F92;
            font-size: 12px;
            line-height: 32px;
            border-bottom: 1px solid #cccccc;
            margin-left: 30px;
        }
        .intro{
            color: #0B1D30;
            margin-top: 15px;
            font-size: 12px;
            margin-bottom: 20px;
            margin-left: 30px;
        }
        ul{
            margin-left: 30px;
            li{
                width: 293px;
                margin-right: 10px;
                margin-bottom: 30px;
                img{
                    width: 293px;
                    height: 200px;
                }
            }
        }
    }
}
</style>
