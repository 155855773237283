export default{
    // 检查手机号
    isCellPhone(val){
        if(!/^1(3|4|5|6|7|8|9)\d{9}$/.test(val)){
            return false;
        }else{
            return true
        }
    },

    
}
