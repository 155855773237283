<template>
    <div class="cooperation">
        <userCenterTitle title="协作管理"></userCenterTitle>
        <div class="project">
            <div
                class="projectItem"
                :class="activeIndex == index ? 'active' : ''"
                @click="changeProject(item, index)"
                v-for="(item, index) in projectList"
                :key="index"
            >{{ item.systemsName }}</div>
        </div>
        <div class="xiezuo_list mes">
            <h3 class="clearfix">
                <span :data-id="consoleId">{{ consoleName }}</span>
                <a @click="addCooperation">添加协作</a>
            </h3>
            <table>
                <thead>
                    <tr>
                        <th>协作用户</th>
                        <th>用户手机号</th>
                        <th>绑定应用账号</th>
                        <th>启用状态</th>
                        <th>操作</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in list" :key="index">
                        <td>{{ item.account }}</td>
                        <td>{{ item.account }}</td>
                        <td>{{ item.applyAccount }}</td>
                        <td class="layui-form">
                            <el-switch
                                v-model="item.status"
                                active-text
                                inactive-text
                                @change="changeSwitch($event,index)"
                            ></el-switch>
                        </td>
                        <td>
                            <a
                                :console_id="item.id"
                                :data-userid="item.id"
                                @click="showDialogVisible(item,index)"
                            >修改</a>
                            |
                            <a
                                :console_id="item.id"
                                :data-userid="item.id"
                                @click="removeCooperation(item,index)"
                            >删除</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>

        <el-dialog
            :title="formType=='add' ? '添加协作应用' : '修改协作应用'"
            :visible.sync="dialogFormVisible"
            width="400px"
        >
            <el-form :model="form">
                <el-form-item label="协作应用" :label-width="formLabelWidth">
                    <el-input v-model="consoleName" autocomplete="off" disabled></el-input>
                </el-form-item>
                <el-form-item label="工业云账号" :label-width="formLabelWidth">
                    <el-input v-model="form.account" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="用户名" :label-width="formLabelWidth">
                    <el-input v-model="form.shareName" autocomplete="off"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="cancelDialog">取 消</el-button>
                <el-button type="primary" @click="confimDialog">确 定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="提示" :visible.sync="dialogVisible" width="270px">
            <span>确认删除该账号吗？</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confimRemove">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import userCenterTitle from "@/components/user-center-title";
export default {
    data() {
        return {
            projectList: [],
            list: [],
            activeIndex: 0,
            consoleId: 0,
            consoleName: "",
            dialogFormVisible: false,
            form: {
                account: "",
                shareName: "",
            },
            formLabelWidth: "92px",
            formType: "add",
            dialogVisible: false,
            listId: [],
        };
    },
    components: {
        userCenterTitle,
    },
    methods: {
        changeProject(item, index) {
            this.activeIndex = index;
            this.consoleId = item.id;
            this.consoleName = item.systemsName;
            this.getCooperList(item.systemsId);
        },
        getCooperList(id) {
            this.$api.usercenter
                .getCooperList({
                    systemsId: id,
                })
                .then((res) => {
                    if(res.success){
                        let list = res.data.records
                        if(res.data.total > 0){
                            list.forEach(el => {
                                if(el.status == 1){
                                    el.status = true
                                }else{
                                    el.status = false
                                }
                            });
                        }
                        this.list = list
                    }
                });
        },
        // 弹出修改框
        showDialogVisible(item, index) {
            this.formType = "edit";
            this.form = {
                account: item.account,
                shareName: item.applyAccount,
                id:item.id
            };
            this.dialogFormVisible = true;
        },
        addCooperation(e) {
            this.formType = "add";
            this.dialogFormVisible = true;
        },
        removeCooperation(item, index) {
            this.dialogVisible = true;
            this.listId = [item.id];
        },
        changeSwitch($e, index) {
            this.list[index].status = $e;
            this.$api.usercenter.changeUserStatus({}).then((res) => {
                if (res.code == 200) {
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        confimDialog() {
            let type = this.formType;
            let postData = this.form;
            if (type == "add") {
                // 添加协作
                postData.consoleId = this.consoleId;
                this.$api.usercenter
                    .addCooperation(postData)
                    .then((res) => {
                        if (res.success) {
                            this.$message.success(res.msg);
                            this.dialogFormVisible = false;
                        } else {
                            this.$message.error(res.msg);
                        }
                    })
                    .catch((err) => {
                        let response = err.response;
                        if (response.status) {
                            this.$message.error(response.data.msg);
                        }
                    });
            } else {
                // 修改协作
                this.$api.usercenter.editCooperation(postData).then((res) => {
                    if (res.success) {
                        this.$message.success(res.msg);
                        this.dialogFormVisible = false;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            }
        },
        cancelDialog() {
            this.dialogFormVisible = false;
            this.form = {
                account: "",
                shareName: "",
            };
        },
        confimRemove() {
            this.dialogVisible = false;
            this.$api.usercenter
                .removeCooperation({
                    ids: this.listId.join(","),
                })
                .then((res) => {
                    if (res.success) {
                        this.$message.success(res.msg);
                        this.dialogFormVisible = false;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        getInfoCooperation() {
            // this.$api.usercenter
            //     .infoCooperation({
            //         id: this.listId,
            //     })
            //     .then((res) => {
            //         if (res.code == 200) {
            //         } else {
            //             this.$message.error(res.msg);
            //         }
            //     });
        },
        getProjectList() {
            this.$api.usercenter.getMyServerList().then((res) => {
                if (res.success) {
                    let list = res.data.records;
                    if (list.length > 0) {
                        this.consoleName = list[0].systemsName;
                        this.consoleId = list[0].id;
                        this.getCooperList(list[0].systemsId);
                    }
                    this.projectList = list;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
    },
    mounted() {
        this.getInfoCooperation();
        this.getProjectList();
    },
};
</script>


<style lang="scss" scoped>
// 项目列表
.project {
    width: 100%;
    overflow: hidden;

    .projectItem {
        width: 102px;
        height: 26px;
        text-align: center;
        line-height: 26px;
        font-size: 12px;
        color: #333333;
        margin: 25px 4px;
        border: 1px solid #e3e3e3;
        float: left;
        cursor: pointer;
    }
    .active {
        background-color: #00be85;
        border: 1px solid #00be85;
        color: #ffffff;
    }
}
// 协作用户列表
.xiezuo_list {
    border-top: 1px solid #dddddd;
    h3 {
        font-size: 16px;
        padding-left: 22px;
        font-weight: normal;
        margin: 25px 0;
        span {
            float: left;
        }
        a {
            width: 90px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            font-size: 12px;
            color: #ffffff;
            background-color: #409eff;
            float: right;
            border-radius: 2px;
            cursor: pointer;
        }
    }
    table {
        border-collapse: collapse;
        border-spacing: 0;
        width: 100%;
        font-size: 14px;
        tr {
            th:nth-child(1) {
                width: 206px;
            }
            th:nth-child(1) {
                width: 208px;
            }
            th {
                line-height: 46px;
                background-color: #f2f2f2;
                border: 1px solid #dcdcdc;
            }
            td {
                text-align: center;
                line-height: 42px;
                border: 1px solid #dcdcdc;
                a:nth-child(1) {
                    color: #409eff;
                    cursor: pointer;
                }
                a:nth-child(2) {
                    color: #f56c6c;
                    cursor: pointer;
                }
            }
        }
    }
}
.el-button--default {
    background-color: #fff !important;
    color: #606266 !important;
}
/deep/ .el-dialog__body {
    padding: 20px !important;
}
/deep/ .el-dialog__footer {
    padding: 30px;
}
/deep/ .dialog-footer {
    bottom: 10px;
    right: 20px;
}
</style>