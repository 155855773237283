<template>
  <!-- 走进沧州工业云 -->
  <div class="l_wrapper">
    <div class="l_content">
      <div class="l_title_text">{{ titleText }}</div>
      <img
        class="l_close"
        @click="close"
        src="../assets/img/关闭.png"
        alt=""
      />
      <div class="l_main">
        <div class="l_main_left">
          <div class="l_company"><router-link :to="{path: '/aboutUs/company'}" @click="close">
            <img
              class="l_company_img"
              src="../assets/img/logo 2.png"
              alt=""
              width="253"
              height="79"
            />
            <div class="l_company_text">公司介绍</div>
          </router-link></div>
          <div class="l_main_group">
            <div class="l_main_item"><router-link :to="{path: '/aboutUs/company'}" @click="close">
              <img src="../assets/img/平台介绍.png" alt="" />
              <div class="l_item_title">平台介绍</div>
              <div class="l_item_content">企业数字化转型的践行者</div>
            </router-link></div>
            <div class="l_main_item"><router-link :to="{path: '/aboutUs/memorabilia'}" @click="close">
              <img src="../assets/img/发展历程.png" alt="" />
              <div class="l_item_title">发展历程</div>
              <div class="l_item_content">不断迭代，快速发展</div>
            </router-link></div>
            <div class="l_main_item"><router-link :to="{path: '/aboutUs/winAward'}" @click="close">
              <img src="../assets/img/荣誉认可.png" alt="" />
              <div class="l_item_title">荣誉认可</div>
              <div class="l_item_content">权威发布及行业认可</div>
            </router-link></div>
            <div class="l_main_item"><router-link :to="{path: '/joinUs'}">
              <img src="../assets/img/加入我们.png" alt="" />
              <div class="l_item_title">加入我们</div>
              <div class="l_item_content">携手创建未来</div>
            </router-link></div>
          </div>
        </div>
        <div class="l_main_right">
          <!-- <img src="../assets/img/沧州工业云banner.png" alt="" /> -->
          <div class="l_right_img"></div>
          <el-carousel
            height="100px"
            direction="vertical"
            :autoplay="true"
            indicator-position="none"
          >
            <el-carousel-item v-for="item in newsList" :key="item.id" @click="goNews(item.id)">
              <div class="l_news"><router-link :to="{path: ''}">
                <div class="l_news_title">{{ item.title }}</div>
                <div class="l_news_content">
                  {{ item.content }}
                </div>
                <div class="l_news_createTime">{{ item.createTime }}</div>
              </router-link></div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      type: 1,
      closeE:true,
      newsList: [
        {
          id: 0,
          title: "新闻资讯",
          content:
            "台积电决定在日本建立第一家芯片工厂，亚马逊云：年化收入打540亿美元年化收入打540亿美元",
          createTime: "2021-09-27 16:31:50",
        },
        {
          id: 1,
          title: "新闻资讯",
          content:
            "台积电决定在日本建立第一家芯片工厂，亚马逊云：年化收入打540亿美元年化收入打540亿美元",
          createTime: "2021-09-27 16:31:50",
        },
      ],
    };
  },
  computed: {
    titleText() {
      if (this.type == 0 || this.type == 1) {
        return "工业互联网生态品牌";
      } else if (this.type == 2) {
        return "工业互联标识先行";
      }
    },
  },
  methods: {
    close() {
        this.closeE=false;
        this.$emit("childInto", this.closeE);
     },
    goNews(id) {
      // console.log(id)
    }
  },
};
</script>

<style lang="scss" scoped>
.l_wrapper {
  width: 100%;
  background-color: #f5f5f5;
  color: #0b1d30;
  .l_content {
    position: relative;
    max-width: 1440px;
    margin: 0 auto;
    height: 331px;
    padding-top: 30px;
    .l_close {
      position: absolute;
      top: 35px;
      right: 6px;
      cursor: pointer;
    }
    .l_title_text {
      width: 168px;
      height: 44px;
      font-size: 14px;
      line-height: 44px;
      background-color: #e7e7e7;
      color: #757f92;
    }
    .l_main {
      display: flex;
      margin: 20px 0 27px 0;
      .l_main_left {
        display: flex;
        width: 60.5%;
        height: 210px;
        margin-right: 100px;
        .l_company {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50%;
          height: 210px;
          margin-right: 10px;
          background: url("../assets/img/沧州工业云bj.png");
          background-size: 100% 100%;
          .l_company_text {
            width: 253px;
            position: absolute;
            top: 140px;
            font-size: 18px;
          }
        }
        .l_main_group {
          display: grid;
          grid-template-columns: 48% 48%;
          grid-template-rows: 48% 48%;
          gap: 6px;
          flex-wrap: wrap;
          width: 50%;
          .l_main_item {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
            padding: 18px;
            background-color: #eeeeee;
            box-sizing: border-box;
            a{
              text-align: left;
            }
            .l_item_title {
              font-size: 16px;
              // font-weight: 500;
              margin: 3px 0;
            }
            .l_item_content {
              font-size: 12px;
              color: #757f92;
            }
          }
        }
      }
      .l_main_right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 32%;
        height: 210px;
        .l_right_img {
          width: 100%;
          height: 90px;
          background: url("../assets/img/沧州工业云banner.png");
          background-size: 100% 100%;
        }
        .l_news {
          a{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-around;
            width: 100%;
            height: 100px;
            .l_news_title {
              font-size: 16px;
            }
            .l_news_content {
              width: 463px;
              font-size: 14px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .l_news_createTime {
              font-size: 12px;
              color: #757f92;
            }
          }
        }
      }
    }
  }
}
</style>