<template>
    <div class="finance">
        <div class="banner">
            <div class="top-nav">
                <div>
                    <router-link :to="{path: '/'}"><img class="logo" src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/logo1.png" alt=""></router-link>
                    <p><router-link :to="{path: '/register'}">注册</router-link> / <router-link :to="{path: '/login'}">登录</router-link></p>
                </div>
                <div>
                    <ul>
                        <li><router-link :to="{path: '/'}">首页</router-link></li>
                        <li><router-link :to="{path: '/industInternet'}">工业互联网平台</router-link></li>
                        <li><router-link :to="{path: '/softwareMarket/softwareHome'}">云产品家族</router-link></li>
                        <li><router-link :to="{path: '/mailianZhicai'}">迈链智采</router-link></li>
                        <li><router-link :to="{path: '/industIot'}">工业物联网</router-link></li>
                        <li><router-link :to="{path: '/solutionList'}">解决方案</router-link></li>
                        <li><router-link :to="{path: '/aboutUs/company'}">关于沧州工业云</router-link></li>
                    </ul>
                </div>
            </div>
            <div class="center-int">
                <h3>一站式工业金融服务</h3>
                <span></span>
                <p>解决工业企业资金链的问题</p>
                <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">了解详情</a>
            </div>
            <div class="bot-sup">
                <ul>
                    <li>
                        <div>
                            <h4>安全</h4>
                            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon1_1.png" alt="">
                        </div>
                        <p>
                            与专业银行联合研发针对制造企业信用模型
                        </p>
                    </li>
                    <li>
                        <div>
                            <h4>专业</h4>
                            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon1_2.png" alt="">
                        </div>
                        <p>
                            合作方均来自大型金融机构及专业银行，提供供应链资金一条龙服务
                        </p>
                    </li>
                    <li>
                        <div>
                            <h4>灵活</h4>
                            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon1_3.png" alt="">
                        </div>
                        <p>
                            为企业及个人量身定制，灵活选择贷款方式
                        </p>
                    </li>
                    <li>
                        <div>
                            <h4>便捷</h4>
                            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon1_4.png" alt="">
                        </div>
                        <p>
                            使用电子合同在线签约，最快可当前放款
                        </p>
                    </li>
                </ul>
            </div>
            <div class="last-gua">
                <ul>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/pic1.png" alt="">
                        <div>
                            <h5>企业开户</h5>
                            <p>开户无费用，高效不等待</p>
                            <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                        </div>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/pic2.png" alt="">
                        <div>
                            <h5>代采融资</h5>
                            <p>“合同先行”，让采购更高效</p>
                            <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                        </div>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/pic3.png" alt=""/>
                        <div>
                            <h5>票据贴现</h5>
                            <p>票据“有价”，让贸易更简单</p>
                            <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="credit-loan box-one">
            <div class="credit-loan-center">
                <div class="title">
                    <h3>信用贷款<span> / 无需任何抵押，信用可以当钱花！</span></h3>
                    <p>Credit lengding</p>
                </div>
                <div class="credit-con one">
                    <div class="left">
                        <ul>
                            <li class="show" @mouseover="mouseOver(0)">税务贷</li>
                            <li @mouseover="mouseOver(1)">善新贷</li>
                            <li @mouseover="mouseOver(2)">普惠E贷</li>
                            <li @mouseover="mouseOver(3)">信用易贷</li>
                            <li @mouseover="mouseOver(4)">代采融资</li>
                        </ul>
                    </div>
                    <div class="right">
                        <div class="show">
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/shuiwu.png" alt="">
                                <div>
                                    <h4>税务贷</h4>
                                    <p>税务贷是经营快贷的一个场景，是基于企业经营及纳税数据，为小微客户提供的信用贷款业务。</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>融资额度</h5>
                                            <p>0~300万元</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon20.png" alt="">
                                        <div>
                                            <h5>利率</h5>
                                            <p>4.15~4.75%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon5.png" alt="">
                                        <div>
                                            <h5>还款期限</h5>
                                            <p>0~12个月</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon16.png" alt="">
                                        <div>
                                            <h5>还款方式</h5>
                                            <p>先息后本</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/dai01.png" alt="">
                                <div>
                                    <h4>善新贷</h4>
                                    <p>面向“专精特新”小微企业的专项信用贷款</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>融资额度</h5>
                                            <p>0~1000万元</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon5.png" alt="">
                                        <div>
                                            <h5>利率</h5>
                                            <p>0~4%</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon16.png" alt="">
                                        <div>
                                            <h5>还款期限</h5>
                                            <p>0~12个月</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon4.png" alt="">
                                        <div>
                                            <h5>还款方式</h5>
                                            <p>先息后本</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/dai02.png" alt="">
                                <div>
                                    <h4>普惠E贷</h4>
                                    <p>以公司为借款主体，根据纳税信息给予信用额度，信用额度最高200万元。如还有抵押物可进行信用+抵押综合额度，组合额度不超过1000万元。</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>融资额度</h5>
                                            <p>10~1000万元</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon6.png" alt="">
                                        <div>
                                            <h5>利率</h5>
                                            <p>3.6~4.5％</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon16.png" alt="">
                                        <div>
                                            <h5>还款期限</h5>
                                            <p>1~36个月</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon3.png" alt="">
                                        <div>
                                            <h5>还款方式</h5>
                                            <p>先息后本</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/dai06.png" alt="">
                                <div>
                                    <h4>信用易贷</h4>
                                    <p>信用易贷是邮储银行面向符合《中小企业划型标准规定》规定的小型、微型企业发放的、单户授信总额不超过人民币300万元、以“小额分散、快速便利”为主要特征的短期流动资金贷款业务。</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>融资额度</h5>
                                            <p>0~300万元</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon4.png" alt="">
                                        <div>
                                            <h5>利率</h5>
                                            <p>4~7％</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon3.png" alt="">
                                        <div>
                                            <h5>还款期限</h5>
                                            <p>1~12个月</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/dai04.png" alt="">
                                <div>
                                    <h4>代采融资</h4>
                                    <p>适合对象：短期资金紧张，有贸易合同为支撑并缴纳一定保证金的企业</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon1.png" alt="">
                                        <div>
                                            <h5>省心</h5>
                                            <p>支付保证金即进行全额采购</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon4.png" alt="">
                                        <div>
                                            <h5>放款快</h5>
                                            <p>最快20天融资到账</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="box-bg"></div>
                </div>
            </div>
        </div>

        <div class="credit-loan box-two">
            <div class="credit-loan-center">
                <div class="title">
                    <h3>抵押贷款<span> / 房车均可，有抵押，更信任。</span></h3>
                    <p>Mortgage</p>
                </div>
                <div class="credit-con one">
                    <div class="left">
                        <ul>
                            <li class="show" @mouseover="mouseOver2(0)">展业通</li>
                            <li @mouseover="mouseOver2(1)">快捷贷</li>
                            <li @mouseover="mouseOver2(2)">e抵快贷</li>
                            <li @mouseover="mouseOver2(3)">e企快贷</li>
                            <li @mouseover="mouseOver2(4)">商贷宝</li>
                            <li @mouseover="mouseOver2(5)">小企业房产按揭贷款</li>
                        </ul>
                    </div>
                    <div class="right">
                        <div class="show">
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>融资额度</h5>
                                            <p>100~2000万元</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon20.png" alt="">
                                        <div>
                                            <h5>利率</h5>
                                            <p>4.35~5.22％</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon5.png" alt="">
                                        <div>
                                            <h5>还款期限</h5>
                                            <p>1~12个月</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon16.png" alt="">
                                        <div>
                                            <h5>还款方式</h5>
                                            <p>其他、先息后本</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/E-kuaidi.png" alt="">
                                <div>
                                    <h4>展业通</h4>
                                    <p>展业通是指对资产总额在4000万元以下或年主营业务收入在3000万元以内、员工人数300人以内（三者满足其一）的小微企业，在2000万元（含）额度内办理授信业务。 具有流程短、审批快等特点。</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/dy03.png" alt="">
                                <div>
                                    <h4>快捷贷</h4>
                                    <p>针对划型分类为小型、微型的企业，无需提供财务报表及税费缴纳凭证等资料，在落实有效的的抵（质）押、保证担保等担保方式后，直接进行授信的标准化循环信贷产品。</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>融资额度</h5>
                                            <p>0~500万元</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon5.png" alt="">
                                        <div>
                                            <h5>利率</h5>
                                            <p>4.25~6％</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon4.png" alt="">
                                        <div>
                                            <h5>还款期限</h5>
                                            <p>1~24个月</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/dy05.png" alt="">
                                <div>
                                    <h4>e抵快贷</h4>
                                    <p>e抵快贷是工商银行面向小微企业、小微企业主及个体工商户提供的以特定区域住房抵押的线上融资产品，贷款资金仅用于生产经营活动。</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>融资额度</h5>
                                            <p>0~500万元</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon6.png" alt="">
                                        <div>
                                            <h5>利率</h5>
                                            <p>3.65~4％</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon16.png" alt="">
                                        <div>
                                            <h5>还款期限</h5>
                                            <p>0.2~120个月</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/dy06.png" alt="">
                                <div>
                                    <h4>e企快贷</h4>
                                    <p>“e企快贷”是指借款人在我行以居住用房、商业用房、工业用房、办公用房、建筑用地使用权抵押，采用线上线下相结合方式办理的，单户授信不超过3000万元的“网贷通”业务。</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>融资额度</h5>
                                            <p>0~3000万元</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon4.png" alt="">
                                        <div>
                                            <h5>利率</h5>
                                            <p>4.1~5％</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon3.png" alt="">
                                        <div>
                                            <h5>还款期限</h5>
                                            <p>0~36个月</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon6.png" alt="">
                                        <div>
                                            <h5>还款方式</h5>
                                            <p>先息后本</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/dy07.png" alt="">
                                <div>
                                    <h4>商贷宝</h4>
                                    <p>面向辖内市场商户、个体工商户、小微企业主及其他从事生产经营活动的个人客户发放，在授信额度、期限内循环使用和归还信贷资金的一种个人贷款业务。商贷宝贷款实行“一次核定、随用随贷、周转使用、余额控制”的原则。</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon1.png" alt="">
                                        <div>
                                            <h5>融资额度</h5>
                                            <p>1~200万元</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon4.png" alt="">
                                        <div>
                                            <h5>利率</h5>
                                            <p>3.7~16％</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon16.png" alt="">
                                        <div>
                                            <h5>还款期限</h5>
                                            <p>1~36个月</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon6.png" alt="">
                                        <div>
                                            <h5>还款方式</h5>
                                            <p>先息后本</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/dy01.png" alt="">
                                <div>
                                    <h4>小企业房产按揭贷款</h4>
                                    <p>为小微企业及个体工商户购买办公楼、厂房时资金不足部分，提供房产按揭贷款。</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon1.png" alt="">
                                        <div>
                                            <h5>融资额度</h5>
                                            <p>1~1500万元</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon4.png" alt="">
                                        <div>
                                            <h5>利率</h5>
                                            <p>3.7~6％</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon16.png" alt="">
                                        <div>
                                            <h5>还款期限</h5>
                                            <p>1~120个月</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="box-bg"></div>
                </div>
            </div>
        </div>

        <div class="credit-loan box-three">
            <div class="credit-loan-center">
                <div class="title">
                    <h3>票据贴现<span> / 支付安全，流程高效，让票据更有价值。</span></h3>
                    <p>Discounted bills</p>
                </div>
                <div class="credit-con one">
                    <div class="left">
                        <ul>
                            <li class="show" @mouseover="mouseOver3(0)">收单保理</li>
                            <li @mouseover="mouseOver3(1)">商票保理</li>
                            <li @mouseover="mouseOver3(2)">在线贴现</li>
                        </ul>
                    </div>
                    <div class="right">
                        <div class="show">
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/pj01.png" alt="">
                                <div>
                                    <h4>收单保理</h4>
                                    <p>大额短期信用贷款</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>融资额度</h5>
                                            <p>200~3000万元</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon20.png" alt="">
                                        <div>
                                            <h5>利率</h5>
                                            <p>12~18％</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon5.png" alt="">
                                        <div>
                                            <h5>还款期限</h5>
                                            <p>1~3个月</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/pj02.png" alt="">
                                <div>
                                    <h4>商票保理</h4>
                                    <p>应收账款保理融资，商票背书质押，到期托收。</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>融资额度</h5>
                                            <p>100~3000万元</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon5.png" alt="">
                                        <div>
                                            <h5>利率</h5>
                                            <p>12~18％</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon4.png" alt="">
                                        <div>
                                            <h5>还款期限</h5>
                                            <p>1~12个月</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/pj03.png" alt="">
                                <div>
                                    <h4>在线贴现</h4>
                                    <p>在线询价、在线贴现、系统自动审核及放款于一体的综合服务。</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>融资额度</h5>
                                            <p>5~10000万元</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon6.png" alt="">
                                        <div>
                                            <h5>利率</h5>
                                            <p>3~4％</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon16.png" alt="">
                                        <div>
                                            <h5>还款期限</h5>
                                            <p>1~12个月</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="box-bg"></div>
                </div>
            </div>
        </div>

        <div class="credit-loan box-four">
            <div class="credit-loan-center">
                <div class="title">
                    <h3>融资租赁<span> / 盘活企业资产，助力版图扩张！</span></h3>
                    <p>Finance lease</p>
                </div>
                <div class="credit-con one">
                    <div class="left">
                        <ul>
                            <li class="show" @mouseover="mouseOver4(0)">直接租赁</li>
                            <li @mouseover="mouseOver4(1)">售后回租</li>
                            <li @mouseover="mouseOver4(2)">厂商租赁</li>
                        </ul>
                    </div>
                    <div class="right">
                        <div class="show">
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/lease01.png" alt="">
                                <div>
                                    <h4>直接租赁</h4>
                                    <p>直接租赁是指企业自主选定所需供应商的设备，由宗申租赁出资购买该设备。企业自身仅需支付少量资金，便可快速获取设备使用权，后续再分期向宗申租赁支付设备租金的融资业务。<br/>适合对象：有技改需求且经营良好的企业</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>更高效</h5>
                                            <p>快速获取心仪设备，迅速扩大生产规模，升级工艺</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon20.png" alt="">
                                        <div>
                                            <h5>低利率</h5>
                                            <p>边使用边交租，利率低于行业平均水平</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon5.png" alt="">
                                        <div>
                                            <h5>验后交租</h5>
                                            <p>精细化实施资金投放与回笼，验收后才交租，免除资金闲置付息之忧大幅降低实际财务费用</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon16.png" alt="">
                                        <div>
                                            <h5>期限长</h5>
                                            <p>满足中长期融资需求，无需频繁转贷</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/lease02.png" alt="">
                                <div>
                                    <h4>售后回租</h4>
                                    <p>售后回租是指企业将现有核心生产设备出售给宗申租赁，再租回使用的融资业务。该业务能有效盘活企业存量资产，增强资金流动性，改善财务状况。<br/>适合对象：有流动资金需求且经营良好的企业</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>估值高</h5>
                                            <p>设备估值高于传统金融机构，同样的资产，更大的融资额，最大限度盘活企业存量资产</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon5.png" alt="">
                                        <div>
                                            <h5>低价租</h5>
                                            <p>售后低价租用，边生产边交租，不影响企业发展</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon4.png" alt="">
                                        <div>
                                            <h5>利率低</h5>
                                            <p>利率低于行业平均水平</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon16.png" alt="">
                                        <div>
                                            <h5>到账快</h5>
                                            <p>流程简单，资金最短一周到账，迅速改善流动性</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="right_l">
                                <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/lease03.png" alt="">
                                <div>
                                    <h4>厂商租赁</h4>
                                    <p>厂商租赁是设备厂商与我司结成战略合作关系，为客户提供融资租赁服务，以提高客户购买力，从而达到促进销售的目的，并避免因自行承办按揭而产生不必要的财务负担或风险。<br/>适合对象：有设备销售需求且经营良好的企业</p>
                                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即申请</a>
                                </div>
                            </div>
                            <div class="right_r">
                                <ul>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon8.png" alt="">
                                        <div>
                                            <h5>平台支持</h5>
                                            <p>提供平台，协助供应商（厂商）提高成交量，及满足客户资金需求</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon4.png" alt="">
                                        <div>
                                            <h5>资质评估</h5>
                                            <p>协助供应商迅速评估客户资质、提高成交率</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon6.png" alt="">
                                        <div>
                                            <h5>规避风险</h5>
                                            <p>帮助供应商汇整及更新市场动态，避免因自行承办而产生负债比例过高或应收账款等风险</p>
                                        </div>
                                    </li>
                                    <li>
                                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon16.png" alt="">
                                        <div>
                                            <h5>供需对接</h5>
                                            <p>定期回馈供应商客户状态变化信息，帮助供应商回应客户除购买设备外的其它资金需求</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="box-bg"></div>
                </div>
            </div>
        </div>

        <div class="adv">
            <div class="adv-center">
                <ul>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon1 (1).png" alt="">
                        <p>7*24小时服务支持</p>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon2.png" alt="">
                        <p>工業生态全链路服务</p>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon3(1).png" alt="">
                        <p>专业人员 专属顾问</p>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/icon4 (1).png" alt="">
                        <p>交易资金托管服务</p>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return{}
    },
    methods: {
        mouseOver(index) {
            $('.box-one .one').find('.left ul li').eq(index).addClass('show')
            $('.box-one .one').find('.left ul li').eq(index).siblings().removeClass('show')
            $('.box-one .one').find('.right >div').eq(index).addClass('show')
            $('.box-one .one').find('.right >div').eq(index).siblings().removeClass('show')
        },
        mouseOver2(index) {
            $('.box-two .one').find('.left ul li').eq(index).addClass('show')
            $('.box-two .one').find('.left ul li').eq(index).siblings().removeClass('show')
            $('.box-two .one').find('.right >div').eq(index).addClass('show')
            $('.box-two .one').find('.right >div').eq(index).siblings().removeClass('show')
        },
        mouseOver3(index) {
            $('.box-three .one').find('.left ul li').eq(index).addClass('show')
            $('.box-three .one').find('.left ul li').eq(index).siblings().removeClass('show')
            $('.box-three .one').find('.right >div').eq(index).addClass('show')
            $('.box-three .one').find('.right >div').eq(index).siblings().removeClass('show')
        },
        mouseOver4(index){
            $('.box-four .one').find('.left ul li').eq(index).addClass('show')
            $('.box-four .one').find('.left ul li').eq(index).siblings().removeClass('show')
            $('.box-four .one').find('.right >div').eq(index).addClass('show')
            $('.box-four .one').find('.right >div').eq(index).siblings().removeClass('show')
        }
    }
}
</script>

<style scoped lang="scss">
.finance{
    .title{
        color: #ffffff;
        h3{
            font-size: 44px;
            font-weight: 500;
            span{
                font-size: 16px;
            }
        }
        p{
            margin-top: 20px;
            margin-bottom: 30px;
            font-size: 30px;
        }
    }
    .banner{
        background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/banner.png') center top no-repeat;
        background-color: #151417;
        .top-nav{
            width: 1200px;
            margin: 0 auto;
            padding: 15px 0;
            >div{
                display: flex;
                color: #ffffff;
                a{
                    color: #ffffff;
                }
                ul{
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                }
            }
            >div:nth-child(1){
                justify-content: space-between;
                margin-bottom: 20px;
                p{
                    line-height: 45px;
                }
            }
            >div:nth-child(2){
                width: 796px;
            }
        }
        .center-int{
            width: 1200px;
            margin: 82px auto;
            h3{
                font-size: 54px;
                color: #ffffff;
                margin-bottom: 20px;
            }
            span{
                display: block;
                width: 75px;
                height: 5px;
                background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
                margin-bottom: 20px;
            }
            p{
                font-size: 18px;
                color: #ffffff;
                margin-bottom: 45px;
            }
            a{
                display: block;
                width: 143px;
                height: 48px;
                text-align: center;
                line-height: 48px;
                background: linear-gradient(90deg, #EDCEAB 0%, #D2B184 100%);
                font-size: 16px;
                color: #0B1D30;
            }
        }
        .bot-sup{
            width: 1200px;
            height: 180px;
            margin: 0 auto;
            background: linear-gradient(180deg, #F2DCBD 0%, #BDA581 100%);
            box-sizing: border-box;
            padding: 0 30px;
            color: #000000;
            margin-bottom: 70px;
            ul{
                height: 180px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-right: 43px;
                li{
                    width: 212px;
                    div{
                        display: flex;
                        align-items: flex-end;
                        h4{
                            margin-right: 10px;
                            font-size: 26px;
                            font-weight: 500;
                        }
                    }
                    p{
                        margin-top: 7px;
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
        }
        .last-gua{
            width: 1200px;
            margin: 0 auto;
            padding-bottom: 90px;
            ul{
                display: flex;
                justify-content: space-between;
                li{
                    width: 380px;
                    height: 236px;
                    position: relative;
                    div{
                        width: 380px;
                        height: 236px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        text-align: center;
                        color: #FFFFFF;
                        box-sizing: border-box;
                        padding: 30px 0;
                        h5{
                            font-size: 22px;
                            font-weight: 500;
                            margin-bottom: 30px;
                        }
                        p{
                            font-size: 16px;
                            margin-bottom: 60px;
                        }
                        a{
                            width: 140px;
                            height: 40px;
                            padding: 10px 36px;
                            background: linear-gradient(90deg, #ECCDA9 0%, #D3B285 100%);
                        }
                    }
                }
            }
        }
    }
    
    .credit-loan{
        padding: 70px 0 32px 0;
        .credit-loan-center{
            width: 1200px;
            margin: 0 auto;
            color: #ffffff;
            .credit-con{
                display: flex;
                position: relative;
                height: 396px;
                .left{
                    padding-top: 30px;
                    width: 224px;
                    ul{
                        li{
                            line-height: 60px;
                            cursor: pointer;
                        }
                        li.show{
                            color: #D2B284;
                        }
                    }
                }
                .right{
                    width: 976px;
                    // display: flex;
                    position: relative;
                    >div{
                        display: none;
                        .right_l{
                            width: 302px;
                            height: 396px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            >img{
                                width: 302px;
                                height: 396px;
                            }
                            div{
                                width: 232px;
                                height: 336px;
                                padding: 30px 40px 30px 30px;
                                position: absolute;
                                top: 0;
                                left: 0;
                                h4{
                                    margin-bottom: 30px;
                                    font-size: 20px;
                                    font-weight: 400;
                                }
                                p{
                                    font-size: 16px;
                                    height: 165px;
                                    overflow: hidden;
                                    line-height: 24px;
                                    margin-bottom: 76px;
                                }
                                a{
                                    padding: 10px 36px;
                                    background: linear-gradient(90deg, #ECCDA9 0%, #D3B285 100%);
                                    margin-left: 48px;
                                }
                            }
                        }
                        .right_r{
                            width: 674px;
                            height: 350px;
                            background-color: #24252A;
                            position: absolute;
                            top: 46px;
                            right: 0;
                            ul{
                                display: flex;
                                justify-content: space-between;
                                padding: 8px 55px 0 35px;
                                flex-wrap: wrap;
                                li{
                                    width: 234px;
                                    margin-top: 57px;
                                    display: flex;
                                    >img{
                                        width: 40px;
                                        height: 40px;
                                        margin-right: 20px;
                                    }
                                    div{
                                        h5{
                                            font-size: 24px;
                                            color: #D2B284;
                                            margin-bottom: 10px;
                                            font-weight: 400;
                                        }
                                        p{
                                            color: #ffffff;
                                            font-size: 14px;
                                            height: 38px;
                                            line-height: 19px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    >div.show{
                        display: block;
                    }
                }
                .box-bg{
                    width: 922px;
                    height: 50px;
                    position: absolute;
                    bottom: -50px;
                    right: 0;
                    background-color: #24252a;
                }
            }
        }
    }

    .credit-loan:nth-child(2n+1){
        .right_l{
            left: 674px !important;
        }
        .right_r{
            right: 302px !important;
        }
        .box-bg{
            right: 54px !important;
        }
    }

    .box-one{
        background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/bg3.png') center no-repeat;
    }
    .box-two{
        background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/bg1.png') center no-repeat;
    }
    .box-three{
        background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/bg2.png') center no-repeat;
    }
    .box-four{
        background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/jinrongMes/bg4.png') center no-repeat;
    }

    .adv{
        height: 150px;
        background-color: #131315;
        .adv-center{
            width: 1100px;
            margin: 0 auto;
            padding: 0 50px;
            ul{
                height: 150px;
                display: flex;
                justify-content: space-between;
                li{
                    display: flex;
                    align-items: center;
                    color: #ffffff;
                    img{
                        margin-right: 15px;
                    }
                }
            }
        }
    }
}
</style>
