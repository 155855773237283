<template>
    <div class="area-common">
        <ul>
            <li>
                <p>沧州市</p>
                <div>
                    <h3>区域站</h3>
                    <p>用信息技术为县域经济赋能，打造基于工业云服务支持的产业要素协同和互动融合的生态布局。</p>
                    <ul class="right-one-ul">
                        <li>高新区</li>
                        <li>孟村县</li>
                        <li>新华区</li>
                        <li>海兴县</li>
                        <li>经济开发区</li>
                        <li>盐山县</li>
                        <li>渤海新区</li>
                        <li>吴桥县</li>
                        <li>任丘市</li>
                        <li>肃宁县</li>
                        <li>泊头市</li>
                        <li>南皮县</li>
                        <li>沧县</li>
                        <li>东光县</li>
                        <li>青县</li>
                        <li>黄骅市</li>
                    </ul>
                    <h3 class="right-two-h3">行业站</h3>
                    <ul class="right-two-ul">
                        <li>工业设计研究院</li>
                    </ul>
                </div>
            </li>
            <li @click="control">控制台</li>
            <li>
                <template v-if="isLogin == false">
                <a href="">登录</a>
                </template>
                <template v-else>
                    <p>用户名</p>
                </template>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    data() {
        return{
            isLogin: true,
        }
    },
    methods: {
        control() {
            if(this.isLogin){
                this.$router.push({
                    path:'/userCenter/control'
                })
            }else{
                this.$router.push({
                    path:'/login'
                })
            }
        },
    }
}
</script>

<style lang="scss" scoped>
.area-common{
    min-width: 294px;
    >ul{
        display: flex;
        justify-content: space-between;
        align-items: center;
        >li{
            height: 62px;
            line-height: 62px;
            cursor: pointer;
        }
        >li:first-child{
            width: 60px;
            background: url('../assets/image/navUp.png') right center no-repeat;
            position: relative;
            div{
                display: none;
                width: 240px;
                padding: 13px 29px;
                box-sizing: border-box;
                position: absolute;
                left: 0;
                top: 62px;
                z-index: 999;
                background-color: #ffffff;
                box-shadow: 0px 6px 10px 1px rgba(55, 99, 170, 0.1);
                h3{
                    height: 34px;
                    line-height: 34px;
                    font-size: 16px;
                    color: #1f51c8;
                    margin-bottom: 8px;
                }
                p{
                    font-size: 12px;
                    line-height: 20px;
                    color: #757f92;
                    padding-bottom: 24px;
                    border-bottom: dashed 1px #c2c2c2;
                }
                ul{
                    display: flex;
                    justify-content: space-between;
                    flex-flow: wrap;
                    li{
                        width: 75px;
                        line-height: 38px;
                        font-size: 12px;
                        color: #757f92;
                    }
                    li:hover{
                        color: #1F51C8;
                    }
                }
                >ul.right-one-ul{
                    border-bottom: 1px dashed #c2c2c2;
                    padding: 8px 0;
                }
                >ul.right-two-ul{
                    li{
                        width: 100%;
                    }
                }
                h3.right-two-h3{
                    padding-top: 12px;
                    margin-bottom: 0;
                }
            }
        }
        >li:first-child:hover{
            background: url('../assets/image/navDown.png') right center no-repeat;
            >p{
                color: #1F51C8;
            }
            div{
                display: block;
            }
        }
        >li:last-child{
            width: 80px;
            text-align: center;
            overflow: hidden;
            background-color: #1F51C8;
            padding: 0 20px;
            color: #ffffff;
        }
        >li:last-child:hover{
            background-color: #205ff3;
        }
    }
}
</style>