<template>
    <div class="trial">
        <div class="bg-box">
            <div class="bg">
                <img class="bg_img fadein" :src="require('../../assets/image/trial/bg1.jpg')" alt="">
                <img class="bg_img active" :src="require('../../assets/image/trial/bg2.jpg')" alt="">
            </div>
            <img class="topImg" :src="require('../../assets/image/trial/bg3.png')" alt="">
        </div>
        <div class="trial-center">
            <div class="flex">
                <h3>注册试用</h3>
                <p>请完善以下信息，我们的顾问会在1个工作日内与您联系 为您安排产品试用服务。</p>
            </div>
            <el-form ref="registerFrom" :rules="register_rules" :model="registerFrom">
                <el-form-item prop="firmName">
                    <el-input v-model="registerFrom.firmName" placeholder="公司名称"></el-input>
                </el-form-item>

                <el-form-item prop="realName">
                    <el-input v-model="registerFrom.realName" placeholder="姓名"></el-input>
                </el-form-item>

                <el-form-item prop="phone">
                    <el-input v-model="registerFrom.phone" placeholder="请输入手机号"></el-input>
                </el-form-item>

                <el-form-item prop="pwd">
                    <el-input type="password" v-model="registerFrom.pwd" placeholder="请输入密码"></el-input>
                </el-form-item>

                <el-form-item prop="code">
                    <el-input class="code" v-model="registerFrom.code" placeholder="请输入验证码"></el-input>
                    <span
                        class="phone-code"
                        @click="sendCode(registerFrom.phone)"
                    >{{text=='发送验证码'? '发送验证码': text }}</span>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSubmit('registerFrom')"
                        v-loading.fullscreen.lock="fullscreenLoading"
                    >提交</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
var self;
export default {
    data() {
        var checkphone = (rule, value, callback) => {
            if (value == "") {
                callback(new Error("请输入手机号"));
            } else if (!this.common.isCellPhone(value)) {
                callback(new Error("请输入正确的手机号！"));
            } else {
                callback();
            }
        };
        return {
            timerB: null,
            fullscreenLoading: false,
            timer: null,
            text: "发送验证码",
            timeCount: 60,
            registerFrom: {
                firmName: "",
                realName: "",
                phone: "",
                pwd: "",
                code: "",
            },
            register_rules: {
                phone: {
                    required: true,
                    validator: checkphone,
                    trigger: "blur",
                },
                pwd: { required: true, message: "请输入密码", trigger: "blur" },
                firmName: { required: true, message: "请输入公司名称", trigger: "blur" },
                realName: { required: true, message: "请输入姓名", trigger: "blur" },
                code: {
                    required: true,
                    message: "请输入验证码",
                    trigger: "blur",
                },
            },
        };
    },
    mounted() {
        self = this;
        this.toggleClass()
    },
    methods: {
        toggleClass() {
            if (this.timer) {
            window.clearInterval(this.timer);
            this.timer = null;
            }
            this.timer = window.setInterval(function () {
            $(".bg_img").toggleClass("fadein active");
            }, 36000);
        },

        // 倒计时
        timeCountdown() {
            let _this = this;
            let count = _this.timeCount; // 60
            _this.text = count + "S";
            _this.timer = setInterval(() => {
                _this.timeCount--;
                if (_this.timeCount == 0) {
                    _this.text = "重新发送验证码";
                    _this.timeCount = 60;
                    clearInterval(_this.timer);
                } else {
                    _this.text = _this.timeCount + "S";
                }
            }, 1000);
        },
        // 发送验证码
        sendCode(phoneid) {
            if (this.timeCount > 0 && this.timeCount < 60) {
                return false;
            }
            this.timeCount--;
            if (phoneid) {
                var json = { phone: phoneid };
                this.$api.login.sendPhoneCode(json).then((res) => {
                    if (res.code == 200) {
                        this.timeCountdown();
                        this.$message.success("已发送");
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.$message.warning("请输入手机号");
            }
        },
        // 注册
        onSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    this.fullscreenLoading = true;
                    
                    var json = {
                        firmName: this.registerFrom.firmName,
                        realName: this.registerFrom.realName,
                        phone: this.registerFrom.phone,
                        pwd: this.registerFrom.pwd,
                        phoneCode: this.registerFrom.code,
                    };
                    this.$api.login
                        .register(json)
                        .then((res) => {
                            this.fullscreenLoading = false;
                            if (res.success) {
                                self.$router.push({
                                    path: "/trialSucces",
                                });
                            } else {
                                self.$message.error(res.msg);
                            }
                        })
                        .catch((err) => {
                            this.fullscreenLoading = false;
                            let response = err.response;
                            if (response.data.msg) {
                                this.$message.error(response.data.msg);
                            }
                        });
                    
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss">
.trial {
    // background: url("../../assets/image/login_bg.jpg") no-repeat;
    // background-position: center;
    // background-size: cover;
    .el-tabs__item.is-active {
        color: #1848fd;
    }
    .el-tabs__active-bar {
        background-color: #1848fd;
    }
    .el-tabs__nav {
        width: 100%;
        height: 65px;
        line-height: 66px;
        border-bottom: 1px solid #eeeeee;
        .el-tabs__item {
            width: 50%;
            padding: 0;
        }
    }
    .el-form-item {
        margin-bottom: 25px;
    }
    .el-input__inner {
        width: 100% !important;
        height: 40px !important;
    }
    .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
    .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
        text-align: center;
        padding: 0 !important;
    }
    .el-tabs--bottom .el-tabs__item.is-bottom:last-child,
    .el-tabs--bottom .el-tabs__item.is-top:last-child,
    .el-tabs--top .el-tabs__item.is-bottom:last-child,
    .el-tabs--top .el-tabs__item.is-top:last-child {
        text-align: center;
        padding: 0 !important;
    }
    .el-checkbox__inner {
        margin-right: 4px;
        border: 2px solid #757f92;
        border-radius: 50%;
        width: 14px;
        height: 14px;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #1848fd;
        border-color: #1848fd;
    }
    .el-form-item__content {
        width: 100%;
        .el-button--primary {
            width: 100%;
            background-color: #1848fd;
            color: #ffffff;
        }
    }
}
</style>

<style lang="scss" scoped>
.trial {
    background-color: #cccccc;
    height: 100%;
    position: relative;
    .bg-box{
        width: 100%;
        max-height: 1018px;
        overflow: hidden;
        
        @keyframes scale {
            0% {
                transform: scale(1.2)
            }

            50% {
                transform: scale(1)
            }

            100% {
                transform: scale(1.2)
            }
        }

        @keyframes fadein {
            0% {
                opacity: 0
            }

            50% {
                opacity: 1
            }

            100% {
                opacity: 0
            }
        }
        .bg{
            img.bg_img{
                width: 100%;
                height: auto;
                position: absolute;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%,-50%);
                transform: scale(1.2, 1.2);
            }
            img.fadein{
                animation: fadein 36s linear infinite;
                z-index: 9;
            }
            img.active{
                animation: scale 36s linear infinite;
            }
        }
        img.topImg{
            width: 600px;
            height: auto;
            position: absolute;
            top: 50%;
            left: 360px;
            transform: translateY(-50%);
            z-index: 9;
        }
    }
    .trial-center {
        width: 460px;
        height: 590px;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        right: 300px;
        margin-top: -300px;
        padding: 35px 48px;
        box-sizing: border-box;
        z-index: 9;
        .flex {
            margin-bottom: 40px;
            text-align: center;
            h3{
                font-size: 24px;
                color: #0b1d30;
                margin-bottom: 24px;
            }
            p{
                font-size: 14px;
                color: #757F92;
                line-height: 22px;
            }
        }
        .code {
            width: 245px;
            margin-right: 10px;
        }
        img {
            width: 106px;
            height: 40px;
        }
        .phone-code {
            display: inline-block;
            width: 106px;
            height: 40px;
            color: #ffffff;
            background-color: #1848fd;
            text-align: center;
            cursor: pointer;
        }
    }
}
</style>