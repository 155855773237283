<template>
    <!-- 个人中心左侧导航 -->
    <div class="left_aside">
        <h3>用户中心</h3>
        <div class="nav">
            <ul v-for="(item,index) in leftNavData" :key="item.name">
                <h5
                    :class="[item.isActive ? 'is_active': '',item.children ? '': 'no_children']"
                    @click="toUrl(item,index)"
                >
                    <i :class="item.icon"></i>
                    <span v-if="item.children">{{item.name}}</span>
                    <span v-else>{{item.name}}</span>
                    <!-- <router-link v-else :to="{path: item.path}">{{item.name}}</router-link> -->
                    <i
                        v-if="item.children"
                        :class="['arrow',item.isCollapse? 'el-icon-arrow-down': 'el-icon-arrow-up']"
                        @click="arrowClick(index)"
                    ></i>
                </h5>
                <template v-if="item.children && !item.isCollapse">
                    <li
                        v-for="(child,i) in item.children"
                        :key="child.name"
                        :class="child.isActive ? 'active': ''"
                        @click="childClick(index,i)"
                    >
                        <router-link :to="{path: child.path}">{{child.name}}</router-link>
                    </li>
                </template>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            leftNavData: [
                {
                    path: "/userCenter/control",
                    name: "控制台",
                    icon: "el-icon-s-platform",
                    isCollapse: false,
                },
                {
                    name: "应用管理",
                    icon: "el-icon-menu",
                    isCollapse: false,
                    children: [
                        {
                            path: "/userCenter/orderCenter",
                            name: "订单中心",
                        },
                        {
                            path: "/userCenter/cooperation",
                            name: "协作管理",
                        },
                    ],
                },
                {
                    name: "供需管理",
                    icon: "el-icon-document",
                    isCollapse: false,
                    children: [
                        {
                            path: "/userCenter/myBid",
                            name: "我的投标",
                        },
                        {
                            path: "/userCenter/collect",
                            name: "供需收藏",
                        },
                        {
                            path: "/userCenter/supplierTrue",
                            name: "供应商认证",
                        },
                    ],
                },
                {
                    name: "个人管理",
                    icon: "el-icon-user",
                    isCollapse: false,
                    children: [
                        {
                            path: "/userCenter/myMessage",
                            name: "个人信息",
                        },
                        {
                            path: "/userCenter/addressManage",
                            name: "地址管理",
                        },
                        {
                            path: "/userCenter/accountSet",
                            name: "账号设置",
                        },
                    ],
                },
            ],

            user_avatar: "",
            name: "",
        };
    },
    methods: {
        childClick(n, i) {
            this.leftNavData.forEach((item) => {
                if (item.isActive) {
                    this.$delete(item, "isActive");
                }
                if (item.children) {
                    item.children.forEach((el) => {
                        this.$delete(el, "isActive");
                    });
                }
            });
            this.$set(this.leftNavData[n].children[i], "isActive", true);
            this.$set(this.leftNavData[n], "isActive", true);
        },
        arrowClick(i) {
            this.leftNavData[i].isCollapse = !this.leftNavData[i].isCollapse;
        },
        toUrl(item, index) {
            if (!item.children) {
                this.$router.replace({
                    path: item.path,
                });
                this.leftNavData.forEach((item) => {
                    if (item.isActive) {
                        this.$delete(item, "isActive");
                    }
                    if (item.children) {
                        item.children.forEach((el) => {
                            this.$delete(el, "isActive");
                        });
                    }
                });
                this.$set(this.leftNavData[index], "isActive", true);
            }
        },
    },
    mounted() {},
};
</script>

<style lang="sass" scoped>
.left_aside
  width: 230px
  float: left
  background: #FFFFFF
  // max-height: 705px
  // overflow: scroll
  h3
    font: 400 18px/86px 'Microsoft YaHei'
    text-indent: 31px
  .nav
    width: calc(100% - 24px)
    padding: 20px 12px 20px 14px
    border-top: 3px solid #F6F8FB
    ul
      width: 100%
      h5
        font: 300 14px/56px 'Microsoft YaHei'
        padding: 0 21px 0 28px
        i
          margin-right: 13px
        .arrow
          float: right
          font: 300 14px/56px 'Microsoft YaHei'
          cursor: pointer
          margin-right: 0
      .is_active
        color: #1F51C8
      .no_children
        cursor: pointer
      li
        background: #FAFAFA
        font: 300 14px/40px 'Microsoft YaHei'
        color: #757F92
        cursor: pointer
        a
          display: block
          width: 100%
          padding-left: 51px
          box-sizing: border-box;
      .active
        background-color: #E6EFFF
        color: #1F51C8
        border-right: 2px solid #1F51C8
</style>
