<template>
<!-- 软件市场 -->
  <div class="boxProductNav w100">
    <div class="lab">
        <p>以丰富的产品，为企业数字化提供助力</p>
    </div>
    <div class="centerArea clearfix">
      <div class="left">
        <template v-for="(item,index) in productClass">
            <div :key="index + 'sys'">
                <p>{{item.name}}</p>
                <ul>
                    <template v-for="(itemSon,indexSon) in item.sonclass">
                    <li :key="indexSon + 'sonClass'" @click="checkedStyle(index, indexSon)">{{itemSon.classname}}<span></span></li>
                    </template>
                </ul>
            </div>
        </template>
      </div>
      <div class="right">
        <template v-for="(item,index) in productClass">
            <div :key="index + 'int'">
                <template v-for="(itemSon,indexSon) in item.sonclass">
                <ul :key="indexSon + 'detbox'">
                    <template v-for="(itemDet,indexDet) in itemSon.son">
                    <li :key="indexDet + 'det'">
                        <div class="clearfix">
                            <h4>{{itemDet.title}}</h4>
                            <img :src="itemDet.img2" alt="">
                        </div>
                        <p>{{itemDet.synopsis}}</p>
                    </li>
                    </template>
                </ul>
                </template>
            </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      productClass: [
        {
          name: '',
          son: [],
          sonclass: [{
              classname: '热门产品',
              son: [{
                title: '行业模具',
                img: require('../assets/image/ico.png'),
                img2: require('../assets/image/ico1.png'),
                synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
            },{
                title: '行业模具',
                img: require('../assets/image/ico.png'),
                img2: require('../assets/image/ico1.png'),
                synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
            },{
                title: '行业模具',
                img2: require('../assets/image/ico1.png'),
                img: require('../assets/image/ico.png'),
                synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
            },{
                title: '行业模具',
                img2: require('../assets/image/ico1.png'),
                img: require('../assets/image/ico.png'),
                synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
            },{
                title: '行业模具',
                img: require('../assets/image/ico.png'),
                img2: require('../assets/image/ico1.png'),
                synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
            },{
                title: '行业模具',
                img: require('../assets/image/ico.png'),
                img2: require('../assets/image/ico1.png'),
                synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
            },{
                title: '行业模具',
                img: require('../assets/image/ico.png'),
                img2: require('../assets/image/ico1.png'),
                synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
            },{
                title: '行业模具',
                img: require('../assets/image/ico.png'),
                img2: require('../assets/image/ico1.png'),
                synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
            },{
                title: '行业模具',
                img: require('../assets/image/ico.png'),
                img2: require('../assets/image/ico1.png'),
                synopsis: '海模智云平台，聚合模具产业上下游关 键资源，采用线上线下集群生态共建模式，提供模具全流程模具节点赋能'
            }]
          }],
        }, {
          name: '场景',
          son: [],
          sonclass: [{
            classname: '生产制造',
            son: [{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            }],
          },{
            classname: '协同采购',
            son: [{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            }],
          },{
            classname: '仓储物流',
            son: [{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            }],
          },{
            classname: '仓储物流',
            son: [{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            }],
          },{
            classname: '信息技术服务',
            son: [{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            }],
          },{
            classname: '经营管理',
            son: [{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            }],
          },{
            classname: '制造咨询与服务',
            son: [{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            },{
                title: '智能制造能力成熟度评估服务',
                img2: require('../assets/image/ico1.png'),
                synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
            }],
          }]
        },{
            name: '行业',
            son: [],
            sonclass: [{
                classname: '制造行业',
                son: [{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                }],
            },{
                classname: '更多行业',
                son: [{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                },{
                    title: '智能制造能力成熟度评估服务',
                    img2: require('../assets/image/ico1.png'),
                    synopsis: '评估企业智能制造成熟度登记，持续提升企业 智能制造能力'
                }],
            }]
        }
      ],
    }
  },
  methods: {
    defaultDisplay() {
      $('.boxProductNav .centerArea .left').find('div').eq(0).find('li').eq(0).addClass('show')
      $('.boxProductNav .centerArea .right').find('>div').eq(0).addClass('show')
      $('.boxProductNav .centerArea .right').find('>div').eq(0).find('ul').eq(0).addClass('show')
    },
    checkedStyle(index,indexSon) {
      $('.boxProductNav .centerArea .left').find('>div').eq(index).find('li').eq(indexSon).addClass('show');
      $('.boxProductNav .centerArea .left').find('>div').eq(index).find('li').eq(indexSon).parents().siblings().find('li').removeClass('show')
      $('.boxProductNav .centerArea .left').find('>div').eq(index).find('li').eq(indexSon).siblings().removeClass('show')

      var str = $('.boxProductNav .centerArea .left').find('>div').eq(index).find('li').eq(indexSon).text()

      $('.boxProductNav .centerArea .left').find('>div').eq(index).find('li').eq(indexSon).find('span').css('width', 20 * str.length + 'px')

      $('.boxProductNav .centerArea .right').find('>div').eq(index).addClass('show')
      $('.boxProductNav .centerArea .right').find('>div').eq(index).siblings().removeClass('show')
      $('.boxProductNav .centerArea .right').find('>div').eq(index).find('ul').eq(indexSon).addClass('show')
      $('.boxProductNav .centerArea .right').find('>div').eq(index).find('ul').eq(indexSon).siblings().removeClass('show')
    },
  },
  mounted() {
    this.defaultDisplay()
  }
}
</script>

<style lang="scss">
  html,body {padding: 0px;margin: 0px;height: 100%; }
  .clearfix:after { content: "";clear: both;display: block; }
  .clearfix { *zoom: 1; }
  .fl { float: left; }
  .fr { float: right; }
  ul,li,p, h1,h2,h3,h4,h5,h6 { margin: 0;padding: 0; }
  li { list-style: none; }
  .w100{width:100%;}
  a{ text-decoration: none; color: #333333;}

  
  /* 定义滚动条样式 */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #f5f5f5;
  }
  
  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  
  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 0px rgba(100, 100, 100, .3);
    background-color: rgba(100, 100, 100, .3);
  }

  .boxProductNav{
      background-color: #f5f5f5;
      height: 540px;
      .lab{
          width: 1440px;
          margin: 0 auto;
          padding-top: 30px;
          p{
              width: 280px;
              height: 44px;
              line-height: 44px;
              background: #e7e7e7;
              color: #757F92;
              padding: 0 18px;
          }
      }
      .centerArea{
          width: 1400px;
          margin: 0 auto;
          margin-top: 25px;
          .left{
              width: 245px;
              height: 394px;
              float: left;
            //   border-right: 2px solid #757f92;
              text-align: left;
              overflow-y: auto;
              p{
                  margin-top: 20px;
                  font-size: 18px;
                  margin-bottom: 10px;
              }
              ul li{
                  line-height: 32px;
                  color: #0B1D30;
                  font-size: 14px;
                  padding-left: 32px;
                  cursor: pointer;
                  position: relative;
                  span{
                      width: 0;
                      position: absolute;
                      bottom: 0;
                      left: 32px;
                  }
              }
              ul li.show{
                  font-size: 20px;
                  span{
                      width: 100px;
                      line-height: 40px;
                      border-bottom: 1px solid #0B1D30;
                      transition: all 1s ease;
                  }
              }
          }
          .right{
              width: 1155px;
              height: 394px;
              float: left;
              text-align: left;
              overflow-y: auto;
              >div{
                  display: none;
                  ul{
                      display: none;
                  }
              }
              >div.show{
                  display: block;
                  ul.show{
                      display: block;
                  }
              }
              ul li{
                  width: 290px;
                  float: left;
                  margin-left: 70px;
                  margin-top: 25px;
                  margin-bottom: 30px;;
                  div{
                      h4{
                          float: left;
                          font-size: 18px;
                          font-weight: 400;
                          margin-bottom: 12px;
                      }
                      img{
                          float: right;
                          margin-top: 3px;
                      }
                  }
                  p{
                      line-height: 22px;
                      font-size: 14px;
                      height: 44px;
                      overflow: hidden;
                  }
              }
          }
      }
  }

</style>