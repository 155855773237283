<template>
    <div class="identify-node">
        <div class="banner">
            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/b-banner .png" alt="">
        </div>

        <div class="node-content">
            <h3><img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/floor1-title.png" alt=""></h3>
            <p>工业互联网标识解析体系，是实现工业全要素、各环节信息互通的关键枢纽。通过给每一个对象赋予标识，并借助工业互联网标识解析系统，实现跨地域、跨行业、跨企业的信息查询和共享。</p>
            <p>工业互联网标识解析体系通过被动标识、主动标识、虚拟标识等方式赋予物品唯一身份。</p>
            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/floor1-content1.png" alt="">
            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/floor1-content2.png" style="margin-top: 26px;" alt="">
        </div>

        <div class="node-content2">
            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/floor2-title.png" alt="">
            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/floor2-content.png" alt="">
        </div>
        <div class="node-content3">
            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/floor3-title.png" alt="">
            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/floor3-content1.png" alt="">
            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/floor3-content2.png" alt="">
            
        </div>
        <div class="node-content4">
            <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/floor4-title.png" alt="">
            <div class="node-content4-bottom">
                <div><img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/floor4-content.png" alt=""></div>
                <ul>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/node1.png" alt="">
                        <div>
                            <h4>一码一物</h4>
                            <p>一个标识码对应一个产品，扫码解析获得产品全信息。</p>
                        </div>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/node2.png" alt="">
                        <div>
                            <h4>一码上云</h4>
                            <p>一个标识码连接设备上云，实现设备生命周期全监控。</p>
                        </div>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/node3.png" alt="">
                        <div>
                            <h4>一码溯源</h4>
                            <p>一个标识码查看所有环节，快速了解产品质量全追溯。</p>
                        </div>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/node4.png" alt="">
                        <div>
                            <h4>一码协同</h4>
                            <p>一个标识码实现协同到底，帮助企业数据共享全打通。</p>
                        </div>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/node5.png" alt="">
                        <div>
                            <h4>一码分权</h4>
                            <p>一个标识码实现多种识别，帮助企业不同对象全管控。</p>
                        </div>
                    </li>
                    <li>
                        <img src="https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/node6.png" alt="">
                        <div>
                            <h4>一码万能</h4>
                            <p>一个标识码实现万个功能，帮助企业提供产品全服务。</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default{
    data() {
        return{
            
        }
    }
}
</script>

<style scoped lang="scss">
.identify-node{
    .banner{
        width: 100%;
        height: 540px;
        position: relative;
        img{
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }
    .node-content{
        width: 100%;
        height: 1218px;
        text-align: center;
        background: linear-gradient(180deg,#dfeaff,#fff);
        h3{
            padding: 80px 0 40px 0;
        }
        p{
            width: 1200px;
            margin: 0 auto;
            font-size: 16px;
            color: #666666;
            line-height: 28px;
            margin-bottom: 8px;
        }
    }
    .node-content2{
        padding-top: 80px;
        height: 898px;
        width: 100%;
        text-align: center;
        background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/floor2-bg.png') center no-repeat;
        background-size: 1200px auto;
    }
    .node-content3{
        width: 100%;
        text-align: center;
        padding-top: 80px;
        padding-bottom: 26px;
        background: #f7f9fb;
        img:nth-child(1){
            margin-bottom: 26px;
        }
    }
    .node-content4{
        width: 100%;
        text-align: center;
        padding: 78px 0;
        background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/fixpage/floor4-bg.png') center no-repeat;
        >img{
            margin-bottom: 64px;
        }
        .node-content4-bottom{
            width: 1240px;
            height: 564px;
            display: flex;
            justify-content: space-between;
            margin: 0 auto;
            >div{
                padding-top: 50px;
                width: 699px;
            }
            ul{
                width: 502px;
                li{
                    height: 94px;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                    box-shadow: 0 -10px 16px rgba(235,238,245,0.72);
                    text-align: left;
                    img{
                        width: 30px;
                        height: 30px;
                    }
                    div{
                        width: 374px;
                        h4{
                            font-size: 18px;
                            font-weight: normal;
                            color: #282b45;
                        }
                        p{
                            font-size: 15px;
                            color: #999999;
                            margin-top: 8px;
                        }
                    }
                }
                li:hover{
                    box-shadow: 0 -10px 16px rgba(215,225,248,0.72);
                    h4{
                        color: #4467e7;
                    }
                }
            }
        }
    }
}
</style>
