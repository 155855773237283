<template>
    <div class="order-confirm">
        <div class="nav"></div>
        <div class="order-box">
            <div class="step">
                <h3 class="title"><span> </span>收银台</h3>
                <p style="text-align:center;"><img :src="require('../../assets/true_oder.png')" alt=""></p>
                <!-- <el-steps :active="1">
                    <el-step title="确认订单" icon="iconfont icon-Frame111"></el-step>
                    <el-step title="支付方式" icon="el-icon-upload"></el-step>
                    <el-step title="支付成功" icon="el-icon-picture"></el-step>
                </el-steps> -->
            </div>
            <div class="step">
                <h3 class="title"><span> </span>收货信息</h3>
                <div class="table" v-show="display">
                    <el-table
                    :data="checkAdsData"
                    :border="true"
                    style="width: 100%">
                        <el-table-column
                            prop="userName"
                            label="收货人"
                            width="120">
                        </el-table-column>
                        <el-table-column
                            prop="phone"
                            label="联系电话"
                            width="150">
                        </el-table-column>
                        <el-table-column
                            prop="mername"
                            label="收货地址"
                            width="230">
                        </el-table-column>
                        <el-table-column
                            prop="addrName"
                            label="详细地址"
                            width="420">
                        </el-table-column>
                        <el-table-column
                            prop="defAddr"
                            label="地址说明">
                            <span>当前默认地址</span>
                        </el-table-column>
                    </el-table>
                </div>
                <p @click="getAllAdd" v-show="display" style="font-size: 14px;margin-top: 30px;padding-right: 36px; text-align: right; border-radius: 3px;color: #1F51C8; cursor: pointer">+使用其他地址</p>
                <router-link style="color: #1F51C8;font-size: 14px;padding-left: 36px;" :to="{path: '/userCenter/addressManage'}"  v-show="!display" target="_blank">点击跳转地址管理</router-link>
            </div>
            <div class="step">
                <h3 class="title"><span> </span>我的订单</h3>
                <div class="table">
                <el-table
                :data="tableData"
                :border="true"
                style="width: 100%">
                    <el-table-column
                        prop="proName"
                        label="产品名称"
                        width="260">
                    </el-table-column>
                    <el-table-column
                        prop="allocation"
                        label="已选配置"
                        width="600">
                        <template slot-scope="scope">
                            {{scope.row.allocation}}
                            <el-button type="text" @click="dialogVisible = true">查看详情</el-button>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="price"
                        label="资费">
                    </el-table-column>
                </el-table>
                </div>
            </div>
            <div class="step">
                <h3 class="title"><span> </span>备注</h3>
                <div class="ts">
                    <el-input type="textarea" v-model="form.desc"></el-input>
                </div>
            </div>
            <div class="step">
                <h3 class="title"><span> </span>温馨提示</h3>
                <div class="ts">
                    <p>1）订单支付成功后我们会在1个工作日内与您联系（工作日周一至周五9：00-18：00）。</p>
                    <p>2）紧急问题请拨打联系电话15733264845。</p>
                    <p>3）需要开具发票的商品，发票金额&lt;=200元(RMB)，将默认到付快递。</p>
                </div>
            </div>
            <div class="step">
                <div class="ts">
                    <div class="bg">
                        <el-checkbox-group v-model="form.type">
                            <el-checkbox label="false" name="type">我已仔细阅读并同意《沧州工业云平台》协议服务</el-checkbox>
                        </el-checkbox-group>
                    </div>
                    <div class="price">
                        <span>应付金额：</span><b>{{tableData[0].price}}</b>
                        <el-button type="primary" @click="onSubmit">提交订单</el-button>
                    </div>
                </div>
            </div>
        </div>

        <el-dialog
        title="提示"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
            <div class="alert-box">
                <div class="introduce">
                    <h3 class="title"><span> </span>产品名称</h3>
                    <p>{{tableData[0].proName}}</p>
                </div>
                <div class="introduce">
                    <h3 class="title"><span> </span>配置</h3>
                    <ul>
                        <li>{{tableData[0].allocation}}</li>
                    </ul>
                </div>
                <div class="introduce">
                    <h3 class="title"><span> </span>价格</h3>
                    <p>总额：（<span>¥{{tableData[0].price}}</span>）</p>
                </div>
            </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
        </span>
        </el-dialog>

        <el-dialog title="选择收货地址" 
        :close-on-click-modal="false"
        :visible.sync="isAddListShow">
            <el-table 
            :border="true"
            :data="addressList"
            style="width: 960px">
                <el-table-column
                    prop="userName"
                    label="收货人"
                    width="100">
                </el-table-column>
                <el-table-column
                    prop="phone"
                    label="联系电话"
                    width="140">
                </el-table-column>
                <el-table-column
                    prop="mername"
                    label="收货地址"
                    width="200">
                </el-table-column>
                <el-table-column
                    prop="addrName"
                    label="详细地址"
                    width="349">
                </el-table-column>
                <el-table-column
                label="操作"
                width="130">
                <template slot-scope="scope">
                    <el-button
                    @click.native.prevent="checkAddress(scope.row)"
                    type="text"
                    size="small">
                    设为收货地址
                    </el-button>
                </template>
                </el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>

<script>
// import {mapState} from '../store'
export default {
    data() {
        return{
            dialogVisible: false,
            form: {
               desc: '',
               type: []
            },
            tableData: [],
            checkAdsData: [],
            addressList: [],
            display: false,
            isAddListShow: false,
            subData: {}
        }
    },
    computed: {
        // ...mapState([orderDetail])
    },
    methods: {
        // 设为收货地址
        checkAddress(rows) {
            this.isAddListShow = false
            this.checkAdsData = []
            this.checkAdsData.push(rows)
            this.subData.mername = rows.mername
            this.subData.addrName = rows.addrName
            this.subData.phone = rows.phone
            this.subData.userName = rows.userName
            this.subData.countyId = rows.countyId
        },

        handleClose(done) {
            this.$confirm('确认关闭？')
            .then(_ => {
                done();
            })
            .catch(_ => {});
        },
        onSubmit() {
            if(this.form.type.length > 0){
                this.subData.spec_product_id = this.tableData[0].spec_product_id,
                this.subData.user_id = this.tableData[0].user_id,
                this.subData.remark = this.form.desc

                if(this.subData.mername ){
                    this.$api.product.subOrder(this.subData).then(res => {
                        if(res.code == 200){
                            this.$router.push({path: '/softwareMarket/orderPay', query: {order: res.data.ordering}})
                        }
                    })
                }else{
                    this.$message.warning('请添加收货信息！')
                }
            }else{
                this.$message.warning('请勾选同意协议！')
            }
        },

        // 获取地址
        getAdd(json) {
            if(JSON.stringify(json) != '{}'){
                this.$api.usercenter.getAdressList(json).then(res => {
                    if(res.code == 200 && res.data.records.length > 0){
                        this.display = true
                        this.checkAdsData.push(res.data.records[0])
                        this.subData.mername = res.data.records[0].mername
                        this.subData.addrName = res.data.records[0].addrName
                        this.subData.phone = res.data.records[0].phone
                        this.subData.userName = res.data.records[0].userName
                        this.subData.countyId = res.data.records[0].countyId
                    }else{
                        json = {}
                        this.$api.usercenter.getAdressList(json).then(res => {
                            if(res.code == 200 && res.data.records.length > 0){
                                this.display = true
                                this.checkAdsData.push(res.data.records[0])
                                this.subData.mername = res.data.records[0].mername
                                this.subData.addrName = res.data.records[0].addrName
                                this.subData.phone = res.data.records[0].phone
                                this.subData.userName = res.data.records[0].userName
                                this.subData.countyId = res.data.records[0].countyId
                            }else{
                                this.display = false
                            }
                        })
                    }
                })
            }else{
                this.$api.usercenter.getAdressList(json).then(res => {
                    if(res.code == 200 && res.data.records.length > 0){
                        this.addressList = res.data.records
                    }else{
                        this.display = false
                    }
                })
            }



            // this.$api.usercenter.getAdressList(json).then(res => {
            //     if(res.code == 200 && res.data.records.length > 0 && JSON.stringify(json) != '{}'){
            //         this.display = true
            //         this.checkAdsData.push(res.data.records[0])
            //         this.subData.mername = res.data.records[0].mername
            //         this.subData.addrName = res.data.records[0].addrName
            //         this.subData.phone = res.data.records[0].phone
            //         this.subData.userName = res.data.records[0].userName
            //         this.subData.countyId = res.data.records[0].countyId
            //     console.log(res,1)
            //     }else if(res.code == 200 && JSON.stringify(json) == '{}'){
                    
            //     console.log(res,0)
            //         this.addressList = res.data.records
            //     }else{
            //         this.display = false
            //     }
            // })
        },

        // 获取默认地址
        getDefAdd() {
            var json = {
                isDefault: 1
            }
            this.getAdd(json)
        },

        // 获取地址列表
        getAllAdd() {
            var json = {}
            this.isAddListShow = true
            this.getAdd(json)
        },

    },
    mounted() {
        this.tableData.push(this.$store.state.orderDetail)
        this.getDefAdd()
    }
}
</script>

<style lang="scss">
.order-confirm{
    .is-finish{
        color: #1F51C8;
        .el-step__icon{
            background-color: #1F51C8;
            color: #ffffff ;
            border: 1px solid #1F51C8;
        }
    }
    .el-step__title.is-finish{
        color: #1F51C8 !important;
    }
    .el-step__icon{
        width: 50px !important;
        height: 50px;
        border: 1px solid #E1E1E1;
        border-radius: 50%;
        color: #E1E1E1;
        font-weight: bold;
    }
    .el-step__line{
        margin-top: 13px;
    }
    .cell{
        text-align: center !important;
    }
    .el-table th.el-table__cell{
        background-color: #F6F8FB;
    }
    .el-table .cell{
        padding: 0 25px;
    }
    .el-button--primary{
        width: 136px;
        height: 45px;
        background-color: #006FFF;
        border-color: #006FFF;
    }
}
</style>

<style lang="scss" scoped>
.order-confirm{
    .order-box{
        background-color: #F6F8FB;
        padding: 40px 0;
        .step{
            width: 1200px;
            margin: 0 auto;
            box-sizing: border-box;
            padding: 30px;
            background-color: #ffffff;
            margin-bottom: 15px;
            h3{
                font-weight: normal;
                font-size: 14px;
                color: #0B1D30;
                padding-top: 2px;
                height: 16px;
                line-height: 16px;
                margin-bottom: 30px;
                span{
                    display: inline-block;
                    width: 3px;
                    height: 14px;
                    background-color: #1F51C8;
                    margin-right: 6px;
                }
            }
            .el-steps{
                padding: 0 36px;
            }
            .table{
                padding: 0 36px;
            }
            .ts{
                padding: 0 36px;
                font-size: 14px;
                p{
                    color: #757F92;
                    line-height: 28px;
                }
            }
            .bg{
                padding: 0 15px;
                background-color: #F6F8FB;
                height: 30px;
                line-height: 30px;
            }
            .price{
                margin-top: 40px;
                text-align: right;
                span{
                    font-size: 12px;
                    color: #757F92;
                }
                b{
                    font-size: 22px;
                    color: #FF0027;
                }
                button{
                    margin-left: 35px;
                }
            }
        }
    }
    .alert-box{
        .introduce:nth-child(1){
            margin-bottom: 50px;
        }
        .introduce{
            margin-bottom: 40px;
            font-size: 14px;
            color: #0B1D30;
            h3{
                height: 18px;
                line-height: 18px;
                font-weight: normal;
                display: flex;
                align-items: center;
                margin-bottom: 20px;
                span{
                    display: inline-block;
                    height: 14px;
                    width: 3px;
                    background-color: #1F51C8;
                    margin-right: 6px;
                }
            }
            ul{
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                li{
                    margin-right: 60px;
                    margin-bottom: 10px;
                }
            }
        }
    }
}
</style>