<template>
    <div class="pay-success">
        <div class="order-box">
            <div class="step">
                <h3 class="title"><span> </span>收银台</h3>
                
                <p style="text-align:center;"><img :src="require('../../assets/pay_wancheng.png')" alt=""></p>

                <div class="pay-img">
                    <img :src="require('../../assets/pay-succes.png')" alt="">
                    <p>恭喜您，订单支付成功！</p>
                    <router-link :to="{path: '/userCenter/orderCenter'}">查看订单</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    inject: ['reload'],
    data() {
        return{}
    },
    methods: {},
    mounted(){
        console.log("reload");
        // this.reload()
    },
}
</script>

<style lang="scss">
.pay-success{
    .is-finish{
        color: #1F51C8;
        .el-step__icon{
            background-color: #1F51C8;
            color: #ffffff ;
            border: 1px solid #1F51C8;
        }
    }
    .el-step__title.is-finish{
        color: #1F51C8 !important;
    }
    .el-step__icon{
        width: 50px !important;
        height: 50px;
        border: 1px solid #E1E1E1;
        border-radius: 50%;
        color: #E1E1E1;
        font-weight: bold;
    }
    .el-step__line{
        margin-top: 13px;
    }
}
</style>

<style scoped lang="scss">
.pay-success{
    .order-box{
        background-color: #F6F8FB;
        padding: 40px 0;
        .step{
            width: 1200px;
            margin: 0 auto;
            box-sizing: border-box;
            padding: 30px;
            background-color: #ffffff;
            margin-bottom: 15px;
            h3{
                font-weight: normal;
                font-size: 14px;
                color: #0B1D30;
                padding-top: 2px;
                height: 16px;
                line-height: 16px;
                margin-bottom: 30px;
                span{
                    display: inline-block;
                    width: 3px;
                    height: 14px;
                    background-color: #1F51C8;
                    margin-right: 6px;
                }
            }
            .el-steps{
                padding: 0 36px;
            }
            .table{
                padding: 0 36px;
            }
            .ts{
                padding: 0 36px;
                font-size: 14px;
                p{
                    color: #757F92;
                    line-height: 28px;
                }
            }
            .bg{
                padding: 0 15px;
                background-color: #F6F8FB;
                height: 30px;
                line-height: 30px;
            }
            .price{
                margin-top: 40px;
                text-align: right;
                span{
                    font-size: 12px;
                    color: #757F92;
                }
                b{
                    font-size: 22px;
                    color: #FF0027;
                }
                button{
                    margin-left: 35px;
                }
            }
            .zfb{
                width: 230px;
                height: 60px;
                background-color: #ECF5FC;
                display: flex;
                box-sizing: border-box;
                padding: 0 20px;
                margin-left: 36px;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                color: #0B1D30;
                img{
                    width: 30px;
                    height: 30px;
                }
            }
            .pay-img{
                padding: 90px 0;
                text-align: center;
                p{
                    margin-top: 15px;
                    margin-bottom: 45px;
                    font-size: 16px;
                    color: #0B1D30;
                }
                a{
                    padding: 13px 36px;
                    color: #ffffff;
                    background-color: #006FFF;
                }
            }
        }
    }
}
</style>
