<template>
    <div class="contact">
        <nav-bar></nav-bar>
        <div class="w100">
            <div class="bannerCenter">
                <div class="top">
                    <h2>坚持合作共赢的生态理念</h2>
                    <p>携手生态伙伴联合创新，持续为合作伙伴和客户创造价值</p>
                    <p>&nbsp;</p>
                </div>
                <div class="bottom">
                    <el-menu :default-active="activeNav" class="el-menu-demo" mode="horizontal" @select="handleSelect" router>
                        <el-menu-item index="/aboutUs/company">关于我们</el-menu-item>
                        <el-menu-item index="/joinUs">合作伙伴</el-menu-item>
                        <el-menu-item index="/contactUs">联系我们</el-menu-item>
                    </el-menu>
                </div>
            </div>
        </div>

        <div class="center">
            <div class="center_top clearfix">
                <div class="center_top_left">
                    <h4>我有紧急诉求</h4>
                    <p>如您的诉求较为紧急，任何问题请直接拨打电话与我们联系</p>
                </div>
                <div class="center_top_right">
                    <a class="tel">+86 15531757775</a>
                </div>
            </div>
            <div class="center_top clearfix">
                <div class="center_top_left">
                    <h4>我需要咨询购买、技术等问题</h4>
                    <p>例如技术支持，产品疑惑，我会尽快接入您的回复</p>
                </div>
                <div class="center_top_right">
                    <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false" class="kefu">在线客服咨询</a>
                </div>
            </div>
            <div class="center_bot clearfix">
                <div class="center_bot_left">
                    <p>联系我们</p>
                </div>
                <ul class="center_bot_right clearfix">
                    <li>
                        <p>邮箱</p>
                        <p>
                            <img src="../../assets/image/联系我们邮箱icon.png" alt="">
                            <span>czicloud@163.com</span>
                        </p>
                    </li>
                    <li>
                        <p>电话</p>
                        <p>
                            <img src="../../assets/image/联系我们电话icon.png" alt="">
                            <span>15531757775</span>
                        </p>
                    </li>
                </ul>
            </div>
            <div class="center_last clearfix">
                <div class="center_last_left">
                    <p>办公地址</p>
                </div>
                <div class="center_last_right">
                    <p>沧州市高新区河工大科技园</p>
                    <baidu-map class="map" :center="center" :zoom="zoom" @ready="handler">
                    <bm-marker :position="{lng: 116.811453, lat: 38.35189}" :dragging="true" @click="infoWindowOpen"
                        animation="BMAP_ANIMATION_BOUNCE">
                        <bm-info-window class="mapinfo" :show="mapshow" @close="infoWindowClose" :width="0" :height="0">
                        <p style="margin-top:0px;font-size:18px;font-weight:600;">河北中科物联信息技术有限公司</p>
                        <p style="margin-top:8px;letter-spacing: 1px;">地址：沧州市高新区河工大科技园</p>
                        <p style="margin-top:8px;letter-spacing: 1px;">电话：15531757775</p>
                        </bm-info-window>
                    </bm-marker>
                    <bm-scale anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-scale>
                    </baidu-map>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import navBar from '@/components/navBar'
import Vue from 'vue'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {
    ak: 'BvGvZdSNUGorRcazSdi11COlAg73Px89'
  })
export default {
    data() {
        return{
            activeNav: '/contactUs',
            mapshow: false,
            center: {
                lng: 0,
                lat: 0
            },
            zoom: 15,
        }
    },
    methods: {
        handleSelect(){

        },
    },
    components: {
        // navBar
    },
    mounted() {
        this.activeNav = '/contactUs'
    },
    methods: {
        handler({
            BMap,
            map
        }) {
            // map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
            this.center.lng = 116.811453
            this.center.lat = 38.35189
            // this.center.lng = 116.135775
            // this.center.lat = 38.757687
            this.zoom = 18
        },
        // 关闭窗体
        infoWindowClose() {
            this.mapshow = false
        },
        // 打开窗体
        infoWindowOpen() {
            this.mapshow = true
        }
    }
}
</script>

<style lang="scss" scoped>
.contact{
    .w100{
        width: 100%;
        height: 335px;
        background: url('../../assets/image/aboutUsBg.png') center center no-repeat;
        .bannerCenter{
            width: 1062px;
            margin: 0 auto;
            text-align: center;
            .top{
                padding-top: 85px;
                h2{
                    color: #ffffff;
                    font-size: 36px;
                    font-weight: bold;
                    margin-bottom: 20px;
                }
                p{
                    font-size: 18px;
                    color: #ffffff;
                    line-height: 36px;
                    font-weight: 300;
                }
            }
            .bottom{
                width: 636px;
                margin: 0 auto;
                margin-top: 51px;
                .el-menu{
                    background-color: transparent;
                }
                .el-menu--horizontal>.el-menu-item.is-active, 
                .el-menu--horizontal>.el-menu-item{
                    color: #ffffff;
                    border-width: 4px;
                    font-size: 18px;
                }
                .el-menu-item:focus, .el-menu-item:hover{
                    background-color: transparent;
                }
                .el-menu-item{
                    margin: 0 50px;
                }
            }
        }
    }
    .center{
        width: 1200px;
        margin: 0 auto;
        .center_top{
            height: 148px;
            background-color: #F7F9FC;
            margin-top: 30px;
            .center_top_left{
                width: 380px;
                float: left;
                margin-left: 62px;
                text-align: left;
                h4{
                    color: #0B1D30;
                    font-size: 20px;
                    font-weight: normal;
                    margin-top: 45px;
                }
                p{
                    color: #757F92;
                    font-size: 14px;
                    margin-top: 10px;
                }
            }
            .center_top_right{
                float: right;
                margin-right: 100px;
                margin-top: 60px;
                .tel{
                    font-size: 26px;
                    color: #006FFF;
                }
                .kefu{
                    width: 154px;
                    height: 34px;
                    display: block;
                    background-color: #006FFF;
                    color: #ffffff;
                    font-size: 14px;
                    line-height: 34px;
                    text-align: center;
                }
            }
        }
        .center_bot{
            margin-top: 80px;
            .center_bot_left{
                width: 335px;
                float: left;
                text-indent: -4em;
                p{
                    font-size: 18px;
                    color: #0B1D30;
                }
            }
            .center_bot_right{
                width: 766px;
                float: left;
                border-bottom: 1px solid #cccccc;
                padding-bottom: 5px;
                li{
                    float: left;
                    width: 50%;
                    color: #757f92;
                    text-align: left;
                    text-indent: 1em;
                    p:nth-child(1){
                        font-size: 16px;
                    }
                    p:nth-child(2){
                        font-size: 14px;
                        line-height: 50px;
                        img{
                            margin-top: 20px;
                            margin-right: 5px;
                        }
                    }
                }
            }
        }
        .center_last{
            margin-top: 80px;
            margin-bottom: 30px;
            .center_last_left{
                width: 335px;
                float: left;
                text-indent: -4em;
                p{
                    font-size: 18px;
                    color: #0B1D30;
                }
            }
            .center_last_right{
                width: 700px;
                float: left;
                text-align: left;
                color: #757f92;
                div{
                    width: 590px;
                    height: 435px;
                    margin-top: 20px;
                    .mapinfo{
                        width: 500px;
                        height: 100px;
                    }
                }
            }
        }
    }
}
</style>
