import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './views/store'
import './static/css/reset.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/assets/scss/media.scss'
import animated from 'animate.css'
import './assets/icons/icon.css'


// 全局调用js-cookie
import Cookies from 'js-cookie'
Vue.prototype.$cookie = Cookies

import api from './request/api'

// 引用公共js
import common from './static/js/global'
Vue.prototype.common = common

// Vue.use(Cookies)
Vue.prototype.$api = api

import VueAwesomeSwiper from 'vue-awesome-swiper'
// import 'swiper/dist/css/swiper.css'
Vue.use(VueAwesomeSwiper)
Vue.use(animated)
Vue.config.productionTip = false
Vue.use(ElementUI);  // 注册使用Element
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
