<template>
    <div class="gov-web">
        <!-- <nav-bar></nav-bar> -->
        <div class="banner">
            <img src="" alt="">
        </div>
        <div class="gov-web-center">
            <div class="crumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{path: '/govHome'}">政策服务</el-breadcrumb-item>
                    <el-breadcrumb-item>政府网站</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div class="crumb-list">
                <ul>
                    <li v-for="(item,index) in govWebsiteAllList" :key="index + 'govList'"><a :href="item.url" target="_blank">
                        <div>
                            <h6>{{item.name}}</h6>
                            <p>{{item.url}}</p>
                        </div>
                        <span>进入</span>
                    </a></li>
                </ul>
            </div>

            <div class="page">
                <el-pagination
                    background
                    :hide-on-single-page="true"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="currentPage1"
                    :page-size="10"
                    layout="total, prev, pager, next, jumper"
                    :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
// import navBar from '@/components/navBar'
export default{
    data() {
        return{
            govWebsiteAllList: [],
            currentPage1: 1,
            total: 0
        }
    },
    components: {
    //    navBar
    },
    methods: {
        getGovWebList() {
            this.$api.news.govWebsiteAllList({}).then(res => {
                if(res.code == 200){
                    this.govWebsiteAllList = res.data.records
                    this.total = res.data.total
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        handleSizeChange(val) {
        },
        handleCurrentChange(val) {
        }
    },
    mounted() {
        this.getGovWebList()
    }
}
</script>

<style lang="scss">
.gov-web{
    .el-input__inner{
        width: 46px;
    }
}

.gov-web-center{
    .crumb{
        .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
            font-weight: 500;
            color: #0B1D30;
        }
        .el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
            color: #C7000B;
        }
    }
}
</style>

<style lang="scss" scoped>
.gov-web{
    width: 100%;
    .banner{
        width: 100%;
        img{
            width: 100%;
            height: 335px;
        }
    }
    .gov-web-center{
        width: 1200px;
        margin: 0 auto;
        .crumb{
            margin: 32px 0;
        }
        .crumb-list{
            margin-bottom: 60px;
            ul{
                li{
                    height: 117px;
                    border-bottom: 1px solid #EEEEEE;
                    a{
                        display: flex;
                        height: 117px;
                        justify-content: space-between;
                        align-items: center;
                        h6{
                            font-size: 16px;
                            color: #0B1D30;
                            font-weight: 500;
                            margin-bottom: 10px;
                        }
                        p{
                            font-size: 16px; 
                            color: #0b1d30;
                        }
                        span{
                            display: block;
                            width: 48px;
                            height: 25px;
                            border: 1px solid #C7000B;
                            font-size: 12px;
                            color: #C7000B;
                            line-height: 25px;
                            text-align: center;
                            border-radius: 6px;
                        }
                    }
                }
            }
        }
        .page{
            margin-bottom: 80px;
            text-align: center;
        }
    }
}
</style>
