<template>
    <div class="collect">
        <div class="query">
            <div class="header_title">
                <ul class="clearFix">
                    <li @click="changeStatus(0)" :class="statusIndex==0?'active':'' ">全部</li>
                    <li style="width: 10px;">
                        <div class="el-divider el-divider--vertical"></div>
                    </li>
                    <li @click="changeStatus(1)" :class="statusIndex==1?'active':'' ">进行中</li>
                    <li style="width: 10px;">
                        <div class="el-divider el-divider--vertical"></div>
                    </li>
                    <li @click="changeStatus(2)" :class="statusIndex==2?'active':'' ">已结束</li>
                </ul>
            </div>
            <div>
                <label>选择时间：</label>
                <el-button
                    :type="item.is_selected? 'primary':'text'"
                    size="mini"
                    v-for="(item,index) in timeChoices"
                    :key="item.title"
                    @click="selectoChange(index,'timeChoices')"
                >{{ item.title }}</el-button>
            </div>
        </div>
        <div class="result">
            <el-table
                v-loading="loading"
                :data="tableData"
                style="width: 100%"
                ref="multipleTable"
                @selection-change="handleSelectionChange"
            >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column prop="purchasePublishTime" label="日期" width="180"></el-table-column>
                <el-table-column prop="typeName" label="项目类型" width="120"></el-table-column>
                <el-table-column prop="statusName" label="信息类型" width="110"></el-table-column>
                <el-table-column prop="purchaseTitle" label="招标采购标题">
                    <template slot-scope="scope">
                        <router-link :to="{path: '/seeFile',query:{id:scope.row.purchaseId}}" target="_blank"  tag="a">
                            <span class="purchaseTitle">{{scope.row.purchaseTitle}}</span>
                        </router-link>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="opts">
            <div class="check">
                <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <el-link :underline="false" @click="delAll()">删除</el-link>
                <el-link :underline="false" @click="addTOollect()">收藏</el-link>
            </div>
            <div class="pager">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.current"
                    :page-size="page.size"
                    layout="total, prev, pager, next, jumper"
                    :total="page.total"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            statusIndex: 0,
            loading: false,
            statusList: [{ value: "" }, { value: 0 }, { value: 1 }],
            checkAll: false,
            multipleSelection: [],
            timeChoicesIndex: 0,
            timeChoices: [
                // 1:前一星期|2:前一个月|3:前三个月|4:前六个月|5:前一年
                { title: "不限", value: "", is_selected: true },
                { title: "近一周", value: 1 },
                { title: "近一月", value: 2 },
                { title: "近三月", value: 3 },
                { title: "近半年", value: 4 },
                { title: "近一年", value: 5 },
            ],
            tableData: [
                // {
                //     date: "2021-03-04",
                //     projectType: "原材料采购",
                //     infoType: "招标",
                //     unit: "某某某xxxx",
                //     title: "xxxxxxxxxxxxxxxxxxxxx",
                //     is_collected: false,
                // },
                // {
                //     date: "2021-03-04",
                //     projectType: "原材料采购",
                //     infoType: "招标",
                //     unit: "某某某xxxx",
                //     title: "xxxxxxxxxxxxxxxxxxxxx",
                //     is_collected: true,
                // },
            ],
            page: {
                current: 1,
                size: 10,
                total: 0,
            },
        };
    },
    mounted() {
        this.getMyBid();
    },
    methods: {
        getMyBid() {
            let _this = this;
            _this.loading = true;
            _this.$api.usercenter
                .getMyBid({
                    time: this.timeChoices[this.timeChoicesIndex].value,
                    status: this.statusList[this.statusIndex].value,
                    pageSize: this.page.size,
                    pageNum: this.page.current,
                })
                .then((res) => {
                    _this.loading = false;
                    if (res.success) {
                        this.tableData = res.data.list;
                        this.page.total = res.data.total;
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((er) => {
                    _this.loading = false;
                });
        },
        changeStatus(i) {
            this.statusIndex = i;
            this.getMyBid();
        },
        // loadData() {
        //     let status = this.statusList[this.statusIndex].value;
        //     let time = this.timeChoices[this.timeChoicesIndex].value;
        //     let data = {};
        //     data.status = status;
        //     data.time = time;
        //     this.$api.usercenter.getMyBid(data).then((res) => {
        //         if (res.success) {
        //         } else {
        //             _this.$message.error(res.msg);
        //         }
        //     });
        // },
        selectoChange(i, type) {
            this.timeChoicesIndex = i;
            this[type].forEach((item) => {
                if (item.is_selected) {
                    this.$delete(item, "is_selected");
                }
            });
            this.$set(this[type][i], "is_selected", true);
            this.getMyBid();
        },
        handleCheckAllChange(selectBottom) {
            selectBottom
                ? this.$refs.multipleTable.toggleAllSelection()
                : this.$refs.multipleTable.clearSelection();
            this.multipleSelection = selectBottom;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        delAll() {
            this.tableData = [];
            this.checkAll = false;
        },
        addTOollect() {
            if (this.checkAll) {
                this.tableData.forEach((item) => {
                    item.is_collected = true;
                });
                this.checkAll = false;
            }
        },
        handleSizeChange(val) {
        },
        handleCurrentChange(val) {
            this.page.current = val;
            this.getMyBid();
        },
    },
};
</script>


<style lang="sass" scoped>
.clearFix
    zoom: 1
.collect
.query
    > div
        margin: 16px 0

    .header_title
        width: 100%
        height: 50px
        line-height: 50px
        border-bottom: 1px solid #e0dfdf
        ul
            li
                font-weight: 700
                float: left
                width: 130px
                text-align: center
                cursor: pointer
                margin-top: -3px
            .active
                color: #409EFF
                border-bottom: 3px solid #409EFF

    .el-button--text
        padding: 7px 12px
.opts
    width: 100%
    margin: 16px 0
    .check
        float: left
    .el-link
        margin: 0 12px
    .pager
        float: right

.el-table
    .cell
        cursor: pointer
        .purchaseTitle:hover
            color: #409EFF
</style>