<template>
<div class="commend_wrapper">
  <h4>产品推荐</h4>
  <div class="content">
    <el-row class="cards" :gutter="20">
      <el-col :span="6" v-for="(item,index) in cardList" :key="'card'+ index">
        <el-card class="box-card" shadow="always">
          <el-image style="width: 100%; height: 200px" :src="item.imgUrl" :fit="'fit'"></el-image>
        </el-card>
      </el-col>
    </el-row>
  </div>
</div>
</template>

<script>
export default {
  name: "commend",
  data() {
    return{
      cardList:[
        {imgUrl: require('../assets/imgs/commend1.png')},
        {imgUrl: require('../assets/imgs/commend2.png')},
        {imgUrl: require('../assets/imgs/commend2.png')},
        {imgUrl: require('../assets/imgs/commend3.png')}
      ]
    }
  }
}
</script>

<style scoped lang="sass">
.commend_wrapper
  background: #FFFFFF
  padding: 0 360px 30px
  h4
    text-align: center
    font: 400 34px/163px 'Microsoft YaHei'
</style>