<template>
    <div class="contactUs">
        <el-dialog :visible.sync="showVisible" :close-on-click-modal="false" :before-close="handleClose"
            :append-to-body="true">
            <div class="content">
                <div class="model-title">
                    <div class="title">{{ title }}</div>
                    <div class="sub-title">{{ subTitle }}</div>
                </div>

                <el-form :model="form" :rules="rules" ref="ruleForm">
                    <el-form-item label="应用行业" :label-width="formLabelWidth" style="text-align: left;"
                        prop="industry_content">
                        <el-select v-model="form.industry_content" placeholder="请选择行业">
                            <el-option label="模具" value="模具"></el-option>
                            <el-option label="五金" value="五金"></el-option>
                            <el-option label="钢管" value="钢管"></el-option>
                            <el-option label="法兰" value="法兰"></el-option>
                            <el-option label="玻璃" value="玻璃"></el-option>
                            <el-option label="体育用品" value="体育用品"></el-option>
                            <el-option label="塑料制品" value="塑料制品"></el-option>
                            <el-option label="包装制品" value="包装制品"></el-option>
                            <el-option label="医药行业" value="医药行业"></el-option>
                            <el-option label="服装行业" value="服装行业"></el-option>
                            <el-option label="食品加工" value="食品加工"></el-option>
                            <el-option label="日用品加工" value="日用品加工"></el-option>
                            <el-option label="线缆" value="线缆"></el-option>
                            <el-option label="石油化工" value="石油化工"></el-option>
                            <el-option label="装备制造" value="装备制造"></el-option>
                            <el-option label="物流通运" value="物流通运"></el-option>
                            <el-option label="电力机箱" value="电力机箱"></el-option>
                            <el-option label="汽车装备" value="汽车装备"></el-option>
                            <el-option label="装修建材" value="装修建材"></el-option>
                            <el-option label="文化旅游" value="文化旅游"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="咨询内容" prop="consulting_content" placeholder="请输入咨询内容"
                        :label-width="formLabelWidth">
                        <el-input type="textarea" :rows="4" v-model="form.consulting_content" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="联系人" prop="linkman" placeholder="请输入联系人" :label-width="formLabelWidth">
                        <el-input v-model="form.linkman" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" prop="phone" placeholder="请输入联系人电话" :label-width="formLabelWidth">
                        <el-input v-model="form.phone" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="公司名称" prop="company" placeholder="请输入公司名称" :label-width="formLabelWidth">
                        <el-input v-model="form.company" autocomplete="off"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label-width="80px" style="margin-bottom: 0;">
                        <el-checkbox
                            v-model="checked"
                            label="我已阅读并同意《沧州工业云平台用户协议》及《隐私政策声明》"
                            name="type"
                        ></el-checkbox>
                    </el-form-item>-->
                    <el-form-item label-width="80px" style="margin-bottom:0;">
                        <div class="model-info-sub-tips">
                            <span class="title">温馨提示：</span>
                            <span
                                class="sub-title">提交后我们会在1个工作日内与您联系(工作日周一至周五9:00-18:00)&nbsp;&nbsp;紧急问题请拨打联系电话15531757775</span>
                        </div>
                    </el-form-item>
                    <el-form-item>
                        <el-button class="sub_btn" type="primary" @click="onSubmit('ruleForm')">提交</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </el-dialog>

        <el-dialog title="" :visible.sync="tipsBox">
            <el-result icon="success" title="您已提交成功！" subTitle="工作时间我们软件顾问会在24小时内联系您，请保持电话畅通。"></el-result>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                industry_content: "",
                consulting_content: "",
                linkman: "",
                phone: "",
                company: "",
            },
            title: '感谢联系沧州工业云',
            subTitle: '请填写以下信息，我们将会在1个工作日内与您联系',
            rules: {
                linkman: [
                    {
                        required: true,
                        message: "请输入联系人姓名",
                        trigger: "blur",
                    },
                    {
                        min: 2,
                        max: 5,
                        message: "长度在 2 到 5 个字符",
                        trigger: "blur",
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "请输入联系电话",
                        trigger: "blur",
                    },
                ],
                consulting_content: [
                    {
                        required: true,
                        message: "请填写咨询内容",
                        trigger: "blur",
                    },
                ],
                company: [
                    {
                        required: true,
                        message: "请填写公司名称",
                        trigger: "blur",
                    },
                ],
                industry_content: [
                    {
                        required: true,
                        message: "请选择行业",
                        trigger: "change",
                    },
                ],
            },
            formLabelWidth: '80px',
            tipsBox: false
        };
    },
    props: ['showVisible'],
    methods: {
        onSubmit(formName) {
            let _this = this;
            _this.$refs[formName].validate((valid) => {
                if (valid) {
                    // if (!_this.checked) {
                    //     _this.$message.error("请阅读并同意用户协议和隐私声明");
                    //     return false;
                    // }

                    _this.$api.fixedpage.postCantactUs(_this.form).then(res => {
                        if (res.code == 200) {
                            this.$emit('child-event', false)
                            this.tipsBox = true
                        } else {
                            this.$message.error(res.msg)
                        }
                    }).catch();
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    this.$emit('child-event', false)
                    done();
                })
                .catch(_ => { });
        },
    },
    mounted() {

    }
}
</script>

<style scoped lang="scss">
/deep/ .el-dialog {
    width: 750px;

    .el-dialog__header {
        padding: 0;
    }

    .el-dialog__body {
        width: 746px;
        max-height: 740px;
        padding: 40px 100px;
        background-color: #fff;
        border-radius: 8px;
        position: relative;
        box-sizing: border-box;
        overflow-y:unset;
        height: 650px;
    }
}

/deep/ .el-dialog__headerbtn {
    right: 25px;
    font-size: 32px;
    z-index: 9;
}

/deep/ .el-input {
    width: 430px;
}

/deep/ .el-input__inner {
    height: 40px !important;
    width: 100% !important;
}

.el-textarea {
    width: 430px;
}

.el-select {
    width: 430px;
}

/deep/ .el-form-item__content {
    width: 430px;
    text-align: center;
}

/deep/ .el-checkbox__label {
    font-size: 12px;
}

.content {
    .model-title {
        padding-left: 4px;
        padding-bottom: 30px;

        .title {
            font-size: 30px;
            font-weight: 400;
            line-height: 42px;
            color: #000;
            margin-bottom: 4px;
        }

        .sub-title {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: #666;
        }
    }





    .sub_btn {
        width: 430px;
        margin-left: 70px;
    }

    .model-info-sub-tips {
        display: flex;
        font-size: 12px;
        color: #999;
        line-height: 20px;
        margin-bottom: 29px;
        text-align: left;

        .title {
            font-size: 14px;
            width: 75px;
        }

        .sub-title {
            flex: 1;
        }
    }
}
</style>
