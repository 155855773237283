<template>
  <div class="schema_wrapper">
    <h4>产品优势</h4>
    <div class="content">
      <el-image style="width: 100%; height: 350px" :src="require('../assets/imgs/advantage.png')" :fit="'fit'"></el-image>
    </div>
  </div>
</template>

<script>
export default {
  name: "schema"
}
</script>

<style scoped lang="sass">
.schema_wrapper
  margin: 0 360px 30px
  h4
    text-align: center
    font: 400 34px/163px 'Microsoft YaHei'
</style>