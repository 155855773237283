<template>
    <div class="gov-news-list">
        <!-- <nav-bar></nav-bar> -->
        
        <div class="banner">
            <img :src="require('../../assets/news/news-banner.jpg')" alt="">
        </div>

        <div class="gov-news-list-center">
            <div class="crumb">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item>首页</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{path: '/companyNews'}">新闻资讯</el-breadcrumb-item>
                    <el-breadcrumb-item @click="getOneClassNewsList($route.query.classId)">{{checkClassName}}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>

            <div class="gov-list">
                <div class="left">
                    <h3>新闻资讯</h3>
                    <ul>
                        <li v-for="(item,index) in newsClass" :key="index + 'newsClass'" @click="getOneClassNewsList(item.id,pageNum)" :class="item.id == checkClassId? 'show': ''">{{item.className}}</li>
                    </ul>
                </div>
                <div class="right">
                    <div class="lists">
                        <ul>
                            <li v-for="(item,index) in checkNewsList" :key="index + 'newsList'"><router-link :to="{path: '/companyNewsDetail', query: {classId: item.classId, id: item.id}}">
                                <p><img :src="item.matchImage" alt=""></p>
                                <div>
                                    <h5>{{item.newsName}}</h5>
                                    <p>{{item.introduce}}</p>
                                    <div>
                                        <p>{{item.origin}} &nbsp;&nbsp;{{item.createTime}}</p>
                                        <span v-if="item.isUp">推荐</span>
                                    </div>
                                </div>
                            </router-link></li>
                        </ul>
                    </div>

                    <div class="page">
                        <el-pagination
                            background
                            :hide-on-single-page="false"
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page.sync="pageNum"
                            :page-size="pageSize"
                            layout="total, prev, pager, next, jumper"
                            :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import navBar from '@/components/navBar'
export default {
    data() {
        return{
            newsClass: [],
            checkClassName: '',
            checkClassId: '',
            checkNewsList: [],
            total: 0,
            pageNum: 1,
            pageSize: 5
        }
    },
    components: {
       // navBar
    },
    methods: {
        handleSizeChange(val) {
            this.pageNum = val
            this.getOneClassNewsList(this.checkClassId,val)
        },
        // 直接跳转某页
        handleCurrentChange(val) {
            this.pageNum = val
            this.getOneClassNewsList(this.checkClassId,val)
        },
        // 查询新闻分类
        getCompanyNewClass() {
            this.$api.news.newsClassList({}).then(res => {
                if(res.code == 200){
                    this.newsClass = res.data.records
                    this.getCheckClassName()
                }
            })
        },
        // 获取某一类新闻
        getOneClassNewsList(classId,pageNum) {
            var json = {
                classId: classId,
                current: pageNum,
                size: this.pageSize

            }
            this.checkClassId = classId
            this.$route.query.classId = classId
            this.getCheckClassName()
            this.$api.news.newsOneClassList(json).then(res => {

                this.checkNewsList = res.data.records
                this.total = res.data.total
            })
        },
        // 获取当前分类名称
        getCheckClassName() {
            this.checkClassId = this.$route.query.classId
            try{
                this.newsClass.map((v, k) => {
                    if(this.$route.query.classId == v.id){
                        this.checkClassName = v.className
                        throw '';
                    }
                })
            } catch (e){}
        }
    },
    mounted() {
        this.getCompanyNewClass()
        this.getOneClassNewsList(this.$route.query.classId,1)
    }
}
</script>

<style lang="scss">
.gov-news-list .gov-news-list-center .crumb .el-breadcrumb__item:last-child .el-breadcrumb__inner, .gov-news-list .gov-news-list-center .crumb .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .gov-news-list .gov-news-list-center .crumb .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .gov-news-list .gov-news-list-center .crumb .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
    color: #006fff;
}

.gov-news-list .gov-news-list-center .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #006fff;
}

.gov-news-list-center{
    .el-input__inner{
        width: 46px !important;
    }
}
.gov-news-list-center{
    .crumb{
        .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
            font-weight: 500;
            color: #0B1D30;
        }
        .el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
            color: #006fff;
        }
        .el-breadcrumb__separator{
            font-weight: 500;
        }
    }
    .el-pagination.is-background .el-pager li:not(.disabled).active{
        background-color: #006fff;
    }
}
</style>

<style lang="scss" scoped>
.gov-news-list{
    .banner{
        width: 100%;
        height: 335px;
        overflow: hidden;;
        img{
            height: 335px;
        }
    }
    .gov-news-list-center{
        width: 1200px;
        margin: 0 auto;
        margin-bottom: 80px;
        .crumb{
            margin: 30px 0;
        }
        .gov-list{
            display: flex;
            justify-content: space-between;
            .left{
                width: 253px;
                min-height: 460px;
                padding: 10px 0;
                box-shadow: 0px 0px 12px 1px rgba(0, 111, 255, 0.1);
                text-align: left;
                text-indent: 3em;
                h3{
                    font-size: 18px;
                    font-weight: 400;
                    color: #0B1D30;
                    margin: 20px 0;
                    border-left: 1px solid #006fff;
                }
                ul{
                    padding: 0 10px;
                    li{
                        line-height: 54px;
                        border-top: 1px solid #EEEEEE;
                        font-size: 14px;
                        color: #757F92;
                        text-align: left;
                        text-indent: 4em;
                        cursor: pointer;
                    }
                    li.show{
                        color: #006fff;
                    }
                }
            }
            .right{
                width: 917px;
                .lists{
                    margin-bottom: 60px;
                    ul{
                        li{
                            padding-bottom: 10px;
                            border-bottom: 1px solid #EEEEEE;
                            margin-bottom: 30px;
                            a{
                                display: flex;
                                justify-content: space-between;
                                >p{
                                    width: 290px;
                                    height: 150px;
                                    position: relative;
                                    overflow: hidden;
                                    img{
                                        width: 290px;
                                        height: auto;
                                        position: absolute;
                                        top: 50%;
                                        left: 0;
                                        transform: translateY(-50%);
                                    }
                                }
                                >div{
                                    width: 592px;
                                    h5{
                                        font-size: 16px;
                                        line-height: 30px;
                                        margin-bottom: 10px;
                                        overflow: hidden;
                                        text-overflow:ellipsis;
                                        white-space: nowrap;
                                    }
                                    >p{
                                        color: #757F92;
                                        height: 50px;
                                        line-height: 25px;
                                        font-size: 14px;
                                        overflow : hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-line-clamp: 2;
                                        -webkit-box-orient: vertical;
                                    }
                                    div{
                                        margin-top: 20px;
                                        display: flex;
                                        justify-content: space-between;
                                        color: #8F99A1;
                                        font-size: 14px;
                                        line-height: 25px;
                                        span{
                                            display: block;
                                            width: 50px;
                                            height: 25px;
                                            border-radius: 6px;
                                            font-size: 12px;
                                            text-align: center;
                                            color: #006fff;
                                            border: 1px solid #006fff;
                                        }
                                    }
                                }
                            }           
                        }
                    }
                }
                .page{
                    text-align: right;
                }
            }
        }
    }
}
</style>