<template>
    <div class="see-file">
        <!-- <nav-bar></nav-bar> -->
        <div class="detailsContent">
            <div class="subject">
                <div class="sub_name">
                    <p>{{purchaseTitle}}</p>
                </div>
                <div class="section_time_coll clearFix">
                    <div class="section_time fl">
                        <p>发布时间：2021-08-29 09:58</p>
                    </div>
                    <div class="section_coll fr" @click="addCollect(queryId)">
                        <p style="cursor: pointer;">
                            <svg
                                t="1621059994080"
                                viewBox="0 0 1024 1024"
                                version="1.1"
                                xmlns="http://www.w3.org/2000/svg"
                                p-id="1871"
                                width="20"
                                height="20"
                                class="icon"
                                style="vertical-align: middle;"
                            >
                                <path
                                    d="M544 105.6l118.4 240L928 384c32 3.2 41.6 41.6 19.2 64l-192 185.6L800 896c6.4 28.8-25.6 54.4-54.4 38.4L512 809.6l-236.8 124.8c-25.6 16-57.6-6.4-54.4-38.4l44.8-262.4-192-185.6c-19.2-22.4-9.6-60.8 22.4-64l265.6-38.4L480 105.6c12.8-28.8 51.2-28.8 64 0z"
                                    p-id="1872"
                                    fill="#424242"
                                />
                            </svg>
                            <span style="vertical-align: middle; margin-left: 5px;">收藏需求</span>
                        </p>
                    </div>
                </div>
                <div class="pur_file">
                    <div
                        class="img"
                        style="margin-top: 20px;"
                        v-for="(item,index) in purchaseContent"
                        :key="index"
                    >
                        <iframe :src="item.value" width="850px" height="1030px" frameborder></iframe>
                        <!-- <iframe src="http://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/ruianda/fc422419-92ff-489f-826d-cbd2143ebd26.pdf" width="850px" height="1030px" frameborder></iframe> -->
                    </div>
                </div>
            </div>

            <div class="right_signup" v-if="this.right_shoucang == '招标'">
                <div class="up_box">
                    <div class="up_box_title" @click="signUp">
                        <span
                            style="color: #fff;display: none;"
                            :style="{display: 'block'}"
                        >{{baomingStatus}}</span>
                        <el-input class="inp" ></el-input>
                    </div>
                    <div class="up_box_time">
                        <div class="images1">
                            <img
                                src="../../assets/mailianzhicai/Snipaste_18.png"
                                alt
                            />
                        </div>
                        <el-timeline>
                            <el-timeline-item style="padding-bottom:18px; box-sizing: border-box;">
                                <p style="font-size:16px;">时间汇总</p>
                            </el-timeline-item>
                            <el-timeline-item :color="color">
                                <p style="color:#0075E2">报名时间</p>
                                <p>
                                    <span
                                        v-if="purchaseBidTime.purchaseEnlistStartTime"
                                    >开始时间：{{purchaseBidTime.purchaseEnlistStartTime.slice("0","16")}}</span>
                                    <br />
                                    <span
                                        v-if="purchaseBidTime.purchaseEnlistEndTime"
                                    >结束时间：{{purchaseBidTime.purchaseEnlistEndTime.slice("0","16")}}</span>
                                </p>
                            </el-timeline-item>
                            <el-timeline-item :color="color">
                                <p style="color:#0075E2">文件下载时间</p>
                                <p>
                                    <span
                                        v-if="purchaseBidTime.purchaseDownStartTime"
                                    >开始时间：{{purchaseBidTime.purchaseDownStartTime.slice("0","16")}}</span>
                                    <br />
                                    <span
                                        v-if="purchaseBidTime.purchaseDownEndTime"
                                    >结束时间：{{purchaseBidTime.purchaseDownEndTime.slice("0","16")}}</span>
                                </p>
                            </el-timeline-item>
                            <el-timeline-item :color="color">
                                <p style="color:#0075E2">投标时间</p>
                                <p>
                                    <span
                                        v-if="purchaseBidTime.purchaseBidStartTime"
                                    >开始时间：{{purchaseBidTime.purchaseBidStartTime.slice("0","16")}}</span>
                                    <br />
                                    <span
                                        v-if="purchaseBidTime.purchaseBidEndTime"
                                    >结束时间：{{purchaseBidTime.purchaseBidEndTime.slice("0","16")}}</span>
                                </p>
                            </el-timeline-item>
                            <el-timeline-item :color="color">
                                <p>开标时间</p>
                                <p>
                                    <span
                                        v-if="purchaseBidTime.purchaseOpenStartTime"
                                    >开始时间：{{purchaseBidTime.purchaseOpenStartTime.slice("0","16")}}</span>
                                    <br />
                                    <span
                                        v-if="purchaseBidTime.purchaseOpenEndTime"
                                    >结束时间：{{purchaseBidTime.purchaseOpenEndTime.slice("0","16")}}</span>
                                </p>
                            </el-timeline-item>
                        </el-timeline>
                    </div>
                </div>

                <div class="foot_box" v-if="enlist_view.user_view">
                    <span>仅允许普通用户报名</span>
                </div>
                <div class="foot_box" v-if="enlist_view.supp_view">
                    <span>仅允许供应商报名</span>
                </div>
                <div class="foot_box1" v-if="enlist_view.user_total_view">
                    <p>普通用户报名需缴纳</p>
                    <p
                        style="font-size: 25px;color: #FF9000;font-weight: 800;"
                    >{{enlist_view.user_enlistCostTotal}}元</p>
                    <p>报名费用（含购买文件费用）</p>
                </div>
                <div class="foot_box1" v-if="enlist_view.supp_total_view">
                    <p>供应商报名需缴纳</p>
                    <p
                        style="font-size: 25px;color: #FF9000;font-weight: 800;"
                    >{{enlist_view.supp_enlistCostTotal}}元</p>
                    <p>报名费用（含购买文件费用）</p>
                </div>
            </div>

            <el-dialog :visible.sync="dialogVisible" width="940px" :before-close="handleClose">
                <div class="all_box clearFix">
                    <div class="left_list_box fl">
                        <div class="left_list">
                            <div class="header_text">
                                <p></p>
                                <p>需求详情</p>
                            </div>
                            <div class="table_list">
                                <ul>
                                    <li
                                        v-for="(item,index) in purchaseList"
                                        :key="index"
                                        @click="lookdetails(item.purchaseId,item.statusName)"
                                    >
                                        <span>{{item.statusName}}</span>
                                        <span>{{item.purchaseTitle}}</span>
                                        <span>{{item.purchasePublishTime.slice("0","10")}}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="left_list_foot">
                            <img
                                src="../../assets/mailianzhicai/Snipaste_54.png"
                                alt
                            />
                        </div>
                    </div>
                    <!-- 立即登录注册 -->
                    <!-- <div class="right_stauts fl" v-if="!this.$store.state.userInfo.token"> -->
                    <!-- <div class="right_stauts fl">
                        <div class="login" @click="login">
                            <p><span>还未登录</span> <span style="font-size:18px;">立即登录</span></p>
                        </div>
                        <div class="login_zhuce" @click="login_up">
                            <p><span>没有账号？</span> <span style="font-size:18px;">立即注册</span></p>
                        </div>
                        <div class="kefu">
                            <p>
                                <svg t="1620970069521" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                    xmlns="http://www.w3.org/2000/svg" p-id="1941" width="26" height="26">
                                    <path
                                        d="M634.5216 698.8288c1.024-5.0176-4.864-8.448-8.7552-5.12-18.5344 15.9232-58.7776 45.2096-104.2432 45.2096-46.7968 0-92.5184-17.3056-112.4352-25.9072-4.3008-1.8944-8.8064 2.3552-7.168 6.7584 8.1408 22.2208 35.3792 68.864 119.9616 68.864 84.5312-0.0512 106.8032-60.7744 112.64-89.8048z"
                                        fill="#F8A608" p-id="1942"></path>
                                    <path
                                        d="M920.832 366.6944C876.9024 181.3504 710.144 43.008 511.5392 43.008c-198.5536 0-365.3632 138.3424-409.2928 323.6864-37.376 6.7072-65.7408 39.2704-65.7408 78.592v145.1008c0 44.1344 35.7888 79.9232 79.9232 79.9232 26.1632 0 49.2544-12.5952 63.8464-32 34.6112 86.272 103.6288 155.4432 190.4128 191.1296 5.9904 2.4576 10.5472-5.632 5.376-9.5232-58.2656-43.9808-114.9952-114.9952-111.104-223.7952 0-23.552 23.552-61.7472 71.5776-68.608 48.0256-6.8608 266.7008-2.9184 294.144-118.6304 5.888-31.3856 3.1232-54.7328 23.1936-38.5536 8.8064 7.68 76.8 84.6336 85.9648 210.1248 5.4784 75.4688-4.9152 157.696-61.3888 218.112-4.3008 4.608 1.3824 11.7248 6.7584 8.4992 69.2736-40.96 122.88-104.8576 150.016-180.992 8.96 17.8688 24.3712 31.8976 43.2128 39.0656-9.8304 39.936-31.3344 94.1568-78.4384 140.0832-58.0096 56.6272-141.3632 87.808-248.0128 92.928-10.1888-23.0912-33.2288-39.2704-60.1088-39.2704-36.2496 0-65.6896 29.3888-65.6896 65.6896 0 36.2496 29.3888 65.6896 65.6896 65.6896 27.4944 0 51.0464-16.9472 60.8256-40.9088 120.3712-5.5296 215.4496-41.5232 283.0336-107.4688 59.8528-58.4192 84.6336-127.2832 94.9248-175.2064 32.3584-10.1888 55.8592-40.448 55.8592-76.2368V445.2864c0.0512-39.2704-28.3136-71.8848-65.6896-78.592zM505.4464 168.3968c-150.6304 0-278.9888 93.7984-328.0896 225.2288a80.50176 80.50176 0 0 0-24.32-19.3536c40.0896-160.6656 185.6-280.064 358.5024-280.064s318.4128 119.3984 358.5024 280.064a80.05632 80.05632 0 0 0-32.512 31.0272C791.8592 267.776 660.48 168.3968 505.4464 168.3968z"
                                        fill="#F8A608" p-id="1943"></path>
                                </svg>
                                <span>&nbsp;&nbsp;不会操作？请<a href="javascript:;" style="color: #0075E2;">在线客服</a> 帮帮忙！</span>
                            </p>
                        </div>
                    </div>-->
                    <!-- 立即报名 -->
                    <div
                        class="right_stauts fl"
                        v-if="success_info =='SUPPLIER_ENLIST_VIEW' || success_info =='USER_ENLIST_VIEW' "
                    >
                        <div class="online">
                            <p>在线报名</p>
                            <span v-if="enlist.enlistCostStatus==0">您的身份为{{username}}，可免费报名</span>
                            <span v-if="enlist.enlistCostStatus==1">
                                您的身份为{{username}}，报名费用为：
                                <span
                                    style="color:#F59E2A;font-size:24px;"
                                >{{enlist.enlistCostTotal}}元</span>
                            </span>
                        </div>
                        <div class="immediately" @click="immediately">
                            <p>立即报名</p>
                        </div>
                        <div class="zhifuFs" v-if="enlist.enlistCostStatus==1">
                            <span>
                                支付方式：
                                <img
                                    src="../../assets/mailianzhicai/Snipaste_44.png"
                                    alt
                                />
                            </span>
                        </div>
                        <div class="contact" v-if="enlist.enlistCostStatus==1">
                            <p>
                                支付完成后即可完成报名，如遇支付失败
                                或支付完成后未报名成功请
                                <a
                                    href="javascript:;"
                                    style="color: #0075E2;"
                                >联系客服</a>
                            </p>
                        </div>
                        <div class="contact1" v-if="enlist.enlistCostStatus==0">
                            <p>
                                支付完成后即可完成报名，如遇支付失败
                                或支付完成后未报名成功请
                                <a
                                    href="javascript:;"
                                    style="color: #0075E2;"
                                >联系客服</a>
                            </p>
                        </div>
                    </div>
                    <!-- 报名成功 -->
                    <div
                        class="right_stauts fl"
                        v-if="this.success_info =='ENLIST_SUCCESS_VIEW'"
                        style="overflow-y: auto;"
                    >
                        <div class="success">
                            <p>报名成功</p>
                        </div>

                        <div class="table_list">
                            <div class="download clearFix">
                                <p class="fl">下载招标文件</p>
                                <!-- <el-button class="fr" plain size="small" type="primary">全部下载</el-button> -->
                            </div>
                            <ul>
                                <li v-for="(item, index) in purchaseFileUrl" :key="index">
                                    <!-- <span>{{item.name.substring(item.name.lastIndexOf("/") + 1, item.name.length)}}</span> -->
                                    <span
                                        class="purchaseFileUrlList"
                                        style="width:88%"
                                    >{{item.label}}</span>
                                    <span
                                        style="line-height:42px;cursor: pointer;"
                                        class="el-icon-download"
                                        @click="DownLoad_Byone(item.value)"
                                    >下载</span>
                                </li>
                            </ul>
                        </div>
                        <div class="table_list" style="margin-top:10px;">
                            <div class="download clearFix">
                                <p class="fl" style="margin-right:236px">上传投标文件</p>
                                <el-upload
                                    v-show="disabled_show ==true"
                                    class="upload-demo"
                                    :action="uploadFileUrl"
                                    name="file"
                                    :before-upload="handleBeforeUpload"
                                    :on-success="handleUploadSuccess"
                                    :headers="headers"
                                    :file-list="fileList"
                                    :show-file-list="false"
                                    multiple
                                >
                                    <el-button
                                        plain
                                        size="small"
                                        type="primary"
                                        id="uploadFocus"
                                    >点击上传</el-button>
                                </el-upload>
                            </div>
                            <ul>
                                <li v-for="(item, index) in bidFileUrl_List" :key="index">
                                    <span
                                        class="purchaseFileUrlList"
                                        style="width:88%"
                                    >{{item.name}}</span>
                                    <!-- <span>{{item.substring(item.lastIndexOf("/") + 1, item.length)}}</span> -->
                                    <span
                                        style="line-height:42px;cursor: pointer;"
                                        class="el-icon-delete"
                                        @click="del_Byone(index, item)"
                                        v-show="disabled_show ==true"
                                    >删除</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </el-dialog>

            <div class="alipayWap" v-html="alipayWap" ref="alipayWap"></div>
        </div>
    </div>
</template>

<script>
// import navBar from '@/components/navBar'
import Cookies from 'js-cookie';
export default {
    data() {
        return {
            http: "http://tem2.0.cz-icloud.com:8080/",
            alipayWap: null,
            baomingStatus: null,
            enlist_view: {
                user_enlistCostTotal: null,
                supp_enlistCostTotal: null,
                user_view: false,
                supp_view: false,
                user_total_view: false,
                supp_total_view: false,
            },
            show: false,
            success_info: "SUPPLIER_ENLIST_VIEW",
            uploadFileUrl: "//27.128.115.201:82/blade-resource/oss/endpoint/put-file", //上传图片api
            headers: {
                'Blade-Auth':Cookies.get('token'),
                Authorization:
                    "eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6IjIzOWZjYmJmLTZiNzgtNDExMC1iZTYyLTczM2ViZDAwNjNlNyJ9.ik66tqPVQllRzfFRDzKJWVpXlpf04jiK2cTBL9Rf0G7Qen-Q50Pfkj_rCcaIGzCHqY8FHUzw3-OXxKdR8vlzVg", //请求头
            },
            fileList: [],
            dialogVisible: false,
            purchaseContent:"",
            //file
            purchaseFileUrl: [],
            //右侧时间线
            color: "#D3E5FF",
            purchaseTitle: "",
            collection1: "",
            purchaseBidTime: [],
            username: "",
            enlist: [],
            purchaseList: [],
            right_shoucang: "招标",
            disabled_show: true,
            bidId: null,
            bidFileUrl_List: [],
            purchasePublishTime: "",
            queryId: "",
        };
    },
    components: {
        // navBar
    },
    mounted() {
        this.getId();
        this.bidFileUrl_List = null;
        this.enlist = [];
        this.dialogVisible = false;
        var id = parseInt(this.queryId);
        // this.right_shoucang = this.$route.query.name == 0 ? '招标' : this.$route.query.name
        //查询采购标书详情：通过采购标书ID+一条
        this.purchaseById(id);
    },
    methods: {
        //获取id
        getId() {
            this.queryId = this.$route.query.id;
            this.bidId = this.$route.query.id;
        },

        immediately() {
          console.log("immediately 立即报名")
            let _this = this
             _this.$api.bidding
                .immediatelySignUp({ purchaseId:_this.queryId,payType:"ALIPAY_PC",returnUrl:window.location.href })
                .then((res) => {
                    console.log(res)
                    if (res.success) {
                        if(res.msg === "ENLIST_SUCCESS_VIEW"){
                            _this.success_info = "ENLIST_SUCCESS_VIEW"
                        }else{
                            if(res.msg == "报名成功"){
                                _this.$message.success(res.msg);
                            }
                            _this.alipayWap = res.msg
                            setTimeout(()=>{
                                $(".alipayWap form").attr("target","_blank")
                                document.forms[0].submit();
                            },300)
                        }
                         this.dialogVisible = false;
                    }else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    let response = err.response;
                    if (response.status == 401) {
                        this.$message.error("请先登录");
                    }
                });
        },
        addCollect(id) {
            let _this = this;
            _this.$api.usercenter
                .addCollect({ pId: id, collectionValue: true })
                .then((res) => {
                    if (res.success) {
                        _this.$message.success(res.msg);
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    let response = err.response;
                    if (response.status == 401) {
                        this.$message.error("请先登录");
                    }
                });
        },
        //我要报名
        signUp() {
            console.log("signUp 我要报名")
            this.enlist = [];
            var id = this.$route.query.id
            if(this.purchaseBidTime.statusId != 0){
                this.$message.error("暂时无法报名");
                return
            }
            this.$api.bidding
                .meSignUp({ purchaseId: id })
                .then((res) => {
                    if(res.success){
                        this.success_info = res.msg
                        this.dialogVisible = true;
                        this.enlist = res.data
                        this.bidId = res.data.bidId
                        if(res.data.bidFileUrl){
                            this.bidFileUrl_List = JSON.parse(res.data.bidFileUrl)
                        }
                        if(res.msg == "SUPPLIER_ENLIST_VIEW"){
                            this.username = "供应商"
                        }else{
                            this.username = "个人"
                        }
                    }else{
                         this.$message.error(res.msg)
                    }
                }).catch(err=>{
                    let response = err.response
                    if(response.status == 401){
                        this.$message.error("请登录后操作")
                        this.login()
                    }
                });
            this.$api.bidding
                .getBidList({ purchaseParentId: id })
                .then((res) => {
                    if (res.success) {
                        this.purchaseList = res.data.records;
                    }
                });
        },
        //立即登录
        login() {
            this.$router.push({
                path: 'login',
                query:{
                    redirect_uri:encodeURIComponent(this.$route.fullPath)
                }
            })
        },
        //立即注册
        login_up() {
            // this.$router.push({
            //     path: 'zhuCe'
            // })
        },
        //弹框×号
        handleClose() {
            this.dialogVisible = false;
        },
        //上传之前
        handleBeforeUpload(file) {
            // 校检文件类型
            if (this.fileType) {
                let fileExtension = "";
                if (file.name.lastIndexOf(".") > -1) {
                    fileExtension = file.name.slice(file.name.lastIndexOf(".") + 1);
                }
                const isTypeOk = this.fileType.some((type) => {
                    if (file.type.indexOf(type) > -1) return true;
                    if (fileExtension && fileExtension.indexOf(type) > -1) return true;
                    return false;
                });
                if (!isTypeOk) {
                    this.$message.error(`文件格式不正确, 请上传${this.fileType.join("/")}格式文件!`);
                    return false;
                }
            }
            return true;
        },

        // 上传成功回调
        handleUploadSuccess(res, file) {
            var bidFileUrlStr = {
                name: file.name,
                url: file.response.data.link,
            };

            const succFile = {
                bId: this.bidId,
                bidFileUrl: JSON.stringify(bidFileUrlStr),
                type: 'upload',
                index: 0
            }
            this.$api.bidding.enlist_upload_delete(succFile).then(res => {
                if (res.code === 500) {
                    this.$message.error('上传文件时间已过')
                    return false
                }
                this.bidFileUrl_List = JSON.parse(res.data.bidFileUrl);
                this.$message.success(res.msg)
            }).catch(err=>{
                let response = err.response
                if(response.status == 500){
                    this.$message.error(response.data.msg)
                }
            });
        },
        //删除列表 上传文件
        del_Byone(i, url) {
            this.$confirm('确认删除嘛?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                const succFile2 = {
                    bId: this.bidId,
                    bidFileUrl: url,
                    type: 'delete',
                    index: i
                }
                this.$api.bidding.enlist_upload_delete(succFile2).then(res => {
                    this.$message.success(res.msg)
                    this.bidFileUrl_List = JSON.parse(res.data.bidFileUrl);
                    if (this.bidFileUrl_List == "") {
                        this.bidFileUrl_List = null
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消'
                });
            });
        },
        //查看详情  弹框左侧列表
        lookdetails(id, value) {
            this.purchaseById(id);
            this.right_shoucang = value;
            this.dialogVisible = false;
        },

        //下载
        DownLoad_Byone(url) {
            // download(url);
            let _this = this
            const Db = {
                purchaseId: parseInt(this.queryId),
                type: 'down'
            }
            this.$api.bidding.enlist_upload_down(Db).then(res => {
                if(res.code == 200){
                    _this.download(url)
                }else{
                    _this.$message.error(res.msg)
                    return false
                }
            }).catch(() => {
                let response = err.response
                this.$message.error(response.data.msg)
            });
        },
        download(url){
            window.open(url, "_blank");
        },
        //查询采购标书详情：通过采购标书ID+一条
        purchaseById(id) {
            this.$api.bidding.getBidDetail({ purchaseId: id }).then((res) => {
                if (res.code === 200) {
                    this.baomingStatus = "我要报名";
                    this.purchaseFileUrl = JSON.parse(res.data.purchaseFileUrl);
                    this.purchaseContent = JSON.parse(res.data.purchaseContent);
                    this.purchaseTitle = res.data.purchaseTitle;
                    this.purchaseBidTime = res.data;
                    this.purchasePublishTime = res.data.purchasePublishTime;
                    var oEnlistArr = res.data.enlist;
                    oEnlistArr.map((item) => {
                        let enlistStatus = item.enlistStatus;
                        let enlistStatusBoolean = item.enlistStatusBoolean;
                        let enlistCostTotal = item.enlistCostTotal;
                        if (
                            enlistStatus == "0" &&
                            enlistStatusBoolean == true
                        ) {
                            this.enlist_view.user_view = true;
                            this.enlist_view.user_total_view = true;
                            this.enlist_view.user_enlistCostTotal =
                                enlistCostTotal;
                        } else if (
                            enlistStatus == "1" &&
                            enlistStatusBoolean == true
                        ) {
                            this.enlist_view.supp_view = true;
                            this.enlist_view.supp_total_view = true;
                            this.enlist_view.supp_enlistCostTotal =
                                enlistCostTotal;
                        }
                    });
                    if (
                        this.enlist_view.user_view == true &&
                        this.enlist_view.supp_view == true
                    ) {
                        this.enlist_view.supp_view = false;
                        this.enlist_view.user_view = false;
                        this.enlist_view.user_total_view = true;
                        this.enlist_view.supp_total_view = true;
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss">
.inp .el-input__inner {
    height: 50px;
    opacity: 0;
    cursor: pointer;
}
.see-file {
    .el-dialog {
        box-sizing: border-box;
        .el-dialog__body {
            padding: 0;
        }
        .el-dialog__header {
            padding: 0;
        }
    }
}
</style>

<style lang="scss" scoped>
[v-cloak] {
    display: none;
}

.detailsContent {
    position: relative;
    width: 1200px;
    margin: 50px auto 0;
    height: auto;
    margin-bottom: 100px;
    .details_nav {
        width: 100%;
        height: 50px;
        line-height: 50px;
    }
    .right_signup {
        position: absolute;
        right: -278px;
        top: 65px;
        width: 250px;
        height: auto;
        .up_box {
            width: 250px;
            height: 420px;
            cursor: pointer;
            .up_box_title {
                position: relative;
                width: 100%;
                height: 50px;
                text-align: center;
                line-height: 50px;
                background-color: #273398;
                border-radius: 5px 5px 0px 0px;
                cursor: pointer;
                .el-input {
                    position: absolute;
                    top: 0;
                    left: 0;
                    cursor: pointer;
                }
            }
            .up_box_time {
                box-sizing: border-box;
                position: relative;
                width: 100%;
                height: auto;
                padding: 30px 0px 0 20px;
                border: 1px solid #e9e8e8;
                span {
                    font-size: 14px;
                    color: #929292;
                }
                .images1 {
                    position: absolute;
                    top: 10px;
                    left: 8px;
                    width: 35px;
                    height: 44px;
                    z-index: 333;
                    img {
                        width: 100%;
                        height: auto;
                    }
                }
                .el-timeline-item {
                    box-sizing: border-box;
                    padding-bottom: 5px;
                }
            }
        }
        .foot_box {
            width: 100%;
            height: 99px;
            background: #f7fbff;
            border: 1px solid #dbecff;
            text-align: center;
            line-height: 99px;
            font-size: 16px;
            margin: 10px 0;
        }
        .foot_box1 {
            box-sizing: border-box;
            width: 100%;
            height: 99px;
            background: #f7fbff;
            border: 1px solid #dbecff;
            text-align: center;
            font-size: 16px;
            margin: 10px 0;
            padding-top: 10px;
        }
    }
    .subject {
        box-sizing: border-box;
        width: 100%;
        height: auto;
        border: 1px solid #e5e5e5;
        .sub_name {
            box-sizing: border-box;
            width: 100%;
            height: 60px;
            line-height: 60px;
            padding-left: 20px;
            background-color: #e5e5e5;
            p {
                font-size: 18px;
            }
        }
        .section_time_coll {
            box-sizing: border-box;
            width: 100%;
            height: 80px;
            line-height: 80px;
            padding: 0 20px;
            .section_time,
            .section_coll {
                font-size: 14px;
                color: #9c9b9b;
            }
        }
        .pur_file {
            width: 850px;
            height: auto;
            margin: 0 auto;
        }
    }
}

.el-dialog .all_box {
    width: 100%;
    height: auto;
    background: #fff;
    overflow: hidden;
    .left_list_box {
        width: 479px;
        height: 523px;
        background: #ffffff;
        border-radius: 5px;
        box-shadow: 0px 0px 12px 0px rgba(131, 131, 131, 0.21);
        .left_list {
            box-sizing: border-box;
            width: 100%;
            height: 260px;
            padding: 36px 36px 0 36px;
            overflow-y: auto;
            .header_text {
                width: 100%;
                height: 40px;
                line-height: 40px;
                p:first-child {
                    width: 4px;
                    height: 16px;
                    background: #f8d708;
                    border-radius: 1px;
                    vertical-align: middle;
                }
                p {
                    font-size: 16px;
                    display: inline-block;
                    margin-left: 6px;
                    vertical-align: middle;
                }
            }
            .table_list {
                width: 100%;
                height: auto;
                ul {
                    li {
                        box-sizing: border-box;
                        display: flex;
                        justify-content: space-between;
                        padding-left: 6px;
                        width: 100%;
                        height: 40px;
                        line-height: 40px;
                        cursor: pointer;
                        border-bottom: 1px dashed #d6d6d6;
                    }
                    li:hover {
                        background-color: #e4e7ed;
                    }
                }
            }
        }
        .left_list_foot {
            width: 100%;
            height: 263px;
            img {
                width: 100%;
                height: 100%;
            }
        }
    }
    .right_stauts {
        width: 460px;
        height: 523px;
        .login {
            background: #273398;
            border-radius: 5px;
            margin: 170px auto 0px;
            color: #fff;
        }
        .login_zhuce {
            background: #ffffff;
            border: 1px solid #273398;
            border-radius: 5px;
            margin: 50px auto 0px;
        }
        .kefu {
            background: #ffffff;
            text-align: center;
            line-height: 50px;
            margin: 50px auto 0px;
        }
        .login,
        .login_zhuce,
        .kefu {
            width: 300px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            cursor: pointer;
        }
        .online,
        .immediately,
        .contact,
        .zhifuFs,
        .contact1 {
            width: 300px;
            height: 50px;
            text-align: center;
            margin: 93px auto 0px;
        }
        .online p {
            margin-bottom: 6px;
            font-size: 22px;
            color: #1d1d1d;
            font-weight: bold;
        }
        .immediately {
            font-size: 18px;
            line-height: 50px;
            background: #273398;
            border-radius: 5px;
            cursor: pointer;
            color: #fff;
        }
        .zhifuFs {
            margin: 30px auto 0px;
            line-height: 50px;
            img {
                border: 1px solid #f59d2a;
            }
        }
        .contact {
            text-align: left;
            width: 260px;
            margin: 100px auto 0px;
        }
        .contact1 {
            text-align: left;
            width: 260px;
            margin: 175px auto 0px;
        }
        .success {
            width: 300px;
            height: 50px;
            text-align: center;
            margin: 30px auto 0px;
            p {
                font-size: 22px;
                font-weight: 700;
            }
        }
        .table_list {
            box-sizing: border-box;
            width: 100%;
            height: auto;
            padding: 0 30px;
            .el-icon-download:hover {
                color: red;
            }
            .download {
                width: 100%;
                height: 30px;
                line-height: 30px;
            }
            ul {
                margin-top: 5px;
                li {
                    box-sizing: border-box;
                    display: flex;
                    justify-content: space-between;
                    padding-left: 6px;
                    width: 100%;
                    height: 40px;
                    line-height: 40px;
                    border-bottom: 1px dashed #d6d6d6;
                    .purchaseFileUrlList {
                        color: #000;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        /*设置方向*/
                        -webkit-line-clamp: 1;
                        /*设置超过为省略号的行数*/
                        overflow: hidden;
                    }
                }
                li:hover {
                    background-color: #e4e7ed;
                }
            }
        }
    }
}

.el-dialog .all_box .right_stauts::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
}

.el-dialog .all_box .right_stauts {
    scrollbar-width: none;
    /* firefox */
    -ms-overflow-style: none;
    /* IE 10+ */
    overflow-x: hidden;
    overflow-y: auto;
}
</style>
