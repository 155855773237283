<template>
  <div class="memorabilia">
    <p></p>
    <ul class="clearfix">
      <li class="left">
        <div>
          <img class="time-ico" src="../../assets/image/time-ico.png" alt="">
          <img class="intro-left" src="../../assets/image/大事记ico2.png" alt="">
        </div>
      </li>
      <li class="right text">
        <div>
          <span>2022</span>
          <p>· 沧州工业云3.0平台迭代升级上线</p>
          <p>· 工业互联网标识解析(沧州)综合二级节点上线</p>
          <p>· 自主研发孜米云码系统正式上线</p>
          <p>· 承办沧州市工业企业数字化转型专项巡诊活动</p>
        </div>
      </li>


      <li class="left text">
        <div>
          <img class="time-ico" src="../../assets/image/time-ico.png" alt="">
          <p>· 承接“工业互联网标识解析（沧州）综合型二级节点建设</p>
          <p>&nbsp;&nbsp;河北省工信厅企业上云指定服务商</p>
          <p>&nbsp;&nbsp;沧州工业互联网联盟发起单位</p>
        </div>
      </li>
      <li class="right">
        <div>
          <span>2021</span>
          <img src="../../assets/image/大事记ico4.png" alt="">
        </div>
      </li>


      <li class="left">
        <div>
          <img class="time-ico" src="../../assets/image/time-ico.png" alt="">
          <img class="intro-left" src="../../assets/image/大事记ico2.png" alt="">
        </div>
      </li>
      <li class="right text">
        <div>
          <span>2020</span>
          <p>· 评定为河北省工业诊所</p>
          <p>&nbsp;&nbsp;河北省工信厅总裁带货直播发起单位</p>
        </div>
      </li>


      <li class="left text">
        <div>
          <img class="time-ico" src="../../assets/image/time-ico.png" alt="">
          <p>· 评为国家级高新技术企业 河北省企业上云政策推进培训活动</p>
          <p>&nbsp;&nbsp;受邀参加第二三届中国国际数字经济博览会，</p>
          <p>&nbsp;&nbsp;沧州市中小企业协会会员单位</p>
        </div>
      </li>
      <li class="right">
        <div>
          <span>2019</span>
          <img class="intro-left" src="../../assets/image/大事记ico2.png" alt="">
        </div>
      </li>


      <li class="left">
        <div>
          <img class="time-ico" src="../../assets/image/time-ico.png" alt="">
          <img class="intro-left" src="../../assets/image/大事记ico3.png" alt="">
        </div>
      </li>
      <li class="right">
        <div>
          <span>2018</span>
          <b>同年</b>
          <p>· 获得河北省科技技术中小企业荣誉 承接“沧州工业云”平台搭建,12月上线运行</p>
          <p>· 河北省工信厅指定企业上云服务商</p>
        </div>
      </li>


      <li class="left">
        <div>
          <b>12月/</b>
          <img class="time-ico" src="../../assets/image/time-ico.png" alt="">
          <p>· 承接“沧州工业云”平台搭建，12月上线试运行</p>
        </div>
      </li>
      <li class="right">
        <div>
          <img src="../../assets/image/大事记logo2.png" alt="">
        </div>
      </li>


      <li class="left">
        <div>
          <img class="time-ico" src="../../assets/image/time-ico.png" alt="">
          <img class="intro-left" src="../../assets/image/大事记ico2.png" alt="">
        </div>
      </li>
      <li class="right">
        <div>
          <b>5月/</b>
          <p>· 获得河北省科技技术中小企业称号</p>
        </div>
      </li>


      <li class="left">
        <div>
          <b>5月/</b>
          <img class="time-ico" src="../../assets/image/time-ico.png" alt="">
          <p>· 河北中科物联信息技术有限公司成立 定位“工业互联网”发展</p>
        </div>
      </li>
      <li class="right">
        <div>
          <span>2017</span>
          <img src="../../assets/image/大事记logo1.png" alt="">
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

export default {}
</script>

<style lang="scss">
.el-menu--horizontal > .el-menu-item.is-active {
  border: 0 none;
}
</style>

<style lang="scss" scoped>

.memorabilia {
  margin-top: 50px;
  margin-bottom: 50px;

  > p {
    width: 960px;
    height: 50px;
    background: url('../../assets/dian.jpg') center 0 repeat-y;
  }

  ul {
    width: 960px;
    background: url('../../assets/dian.jpg') center 0 repeat-y;
    font-size: 14px;
    line-height: 26px;
    color: #757F92;
    display: grid;
    grid-column: 2;
    grid-template-columns: repeat(2, 1fr);

    li {
      position: relative;
      width: 450px;
      height: auto;
      margin-bottom: 50px;
      margin-top: 50px;
      text-align: left;
      border-top: 1px solid #ffffff;

      p:nth-child(1) {
        text-indent: 0;
      }

      span {
        position: absolute;
        top: 36px;
        left: 30px;
        margin-left: -30px;
        width: 60px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        color: #ffffff;
        background-color: #006FFF;
      }

      b {
        color: #006FFF;
        font-weight: normal;
        display: block;
        border-bottom: 1px solid #cccccc;
      }
    }

    li.left {
      margin-right: 20px;

      div {
        margin-top: 10px;
      }

      .time-ico {
        position: absolute;
        top: 15px;
        right: -38px;
      }

      .intro-left {
        margin-left: 380px;
      }
    }

    li.right {
      margin-left: 40px;

      .time-ico {
        position: absolute;
        top: 36px;
        left: 0;
      }

      span {
        position: absolute;
        top: -65px;
        left: -30px;
      }
    }

    li.text {
      div {
        margin-top: 22px;
        border-top: 1px solid #cccccc;
      }
    }

  }
}
</style>
