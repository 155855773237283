<template>
    <div class="order-center">
        <div class="order-list">
            <userCenterTitle title="订单列表"></userCenterTitle>

            <el-form ref="form" :model="form" label-width="80px">
                <el-form-item label="订单状态">
                    <el-select v-model="form.region" @change="changeStatus">
                        <el-option label="全部" value></el-option>
                        <el-option label="未支付" value="1"></el-option>
                        <el-option label="已支付" value="2"></el-option>
                        <el-option label="已完成" value="3"></el-option>
                        <el-option label="已关闭" value="4"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <el-table
                v-loading="loading"
                :data="tableData"
                style="width: 100%"
                :header-cell-style="{ background: '#F6F8FB', color: '#0B1D30' }"
                :cell-style="{ color: '#757F92' }"
            >
                <el-table-column fixed prop="ordering" label="订单号" width="180"></el-table-column>
                <el-table-column prop="proName" label="产品名称" width="180"></el-table-column>
                <el-table-column prop="create_time" label="开始时间" width="160"></el-table-column>
                <el-table-column prop="state" label="状态" width="70">
                    <template slot-scope="scope">
                        <span v-if="scope.row.state == 1">未支付</span>
                        <span v-if="scope.row.state == 2">已付款</span>
                        <span v-if="scope.row.state == 3">已完成</span>
                        <span v-if="scope.row.state == 4">已关闭</span>
                    </template>
                </el-table-column>
                <el-table-column prop="payable_price" label="合计金额" width="140">
                    <template slot-scope="scope">
                        <span v-if="scope.row.payable_price > 0">¥{{scope.row.payable_price}}</span>
                        <span v-else>{{scope.row.payable_price}}</span>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作">
                    <template slot-scope="scope">
                        <div class="orderBtnBox">
                            <span class="orderBtnItem" @click="handleClick(scope.row, 'detail')">详情</span>
                            <span
                                class="orderBtnItem"
                                @click="handleClick(scope.row, 'pay')"
                                v-if="scope.row.state == 1"
                            >支付</span>
                            <span
                                class="orderBtnItem"
                                @click="handleClick(scope.row, 'cancel')"
                                v-if="scope.row.state == 1"
                            >取消</span>
                        </div>
                        <!-- <el-button
                            @click="handleClick(scope.row, 'delete')"
                            v-if="scope.row.status == 2"
                            type="text"
                            size="small"
                        >删除</el-button>-->
                    </template>
                </el-table-column>
            </el-table>

            <div class="pageBox">
                <el-pagination
                    background
                    layout="prev, pager, next"
                    :page-size="pageSize"
                    :total="total"
                    @current-change="changePage"
                    :hide-on-single-page="hideOnSinglePage"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import userCenterTitle from "@/components/user-center-title";
export default {
    data() {
        return {
            form: {
                region: "",
            },
            total: 0,
            pageNum: 1,
            pageSize: 10,
            loading: false,
            hideOnSinglePage: true,
            tableData: [
                // {
                //     id: 1,
                //     ordersn: "20220222145788688",
                //     creattime: "2022-02-22 14:59",
                //     name: "CRM",
                //     province: "上海",
                //     city: "普陀区",
                //     total: "5000.00",
                //     status: 0,
                // },
                // {
                //     id: 2,
                //     creattime: "2022-02-22 14:59",
                //     ordersn: "20220222145788688",
                //     name: "迈链",
                //     province: "上海",
                //     city: "普陀区",
                //     total: "5000.00",
                //     status: 0,
                // },
            ],
        };
    },
    components: {
        userCenterTitle,
    },
    mounted() {
        let _this = this;
        _this.getTableData();
    },
    methods: {
        changeStatus(e) {
            this.form.region = e;
            this.getTableData();
        },
        changePage(e) {
            let _this = this
            _this.pageNum = e;
            _this.getTableData();
        },
        getTableData() {
            let _this = this;
            this.loading = true;
            _this.$api.usercenter
                .getUserOrderList({
                    state: _this.form.region,
                    current: _this.pageNum,
                    size: _this.pageSize,
                })
                .then((res) => {
                    _this.loading = false;
                    if (res.success) {
                        _this.total = res.data.total;
                        _this.tableData = res.data.list;
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    _this.loading = false;
                });
        },
        handleClick(row, type) {
            switch (type) {
                case "detail":
                    // this.$message({
                    //     message: "详情",
                    //     type: "success",
                    // });
                    this.$router.push({
                        path: "orderDetail",
                        query: {
                            orderid: row.id,
                        },
                    });
                    break;
                case "pay":
                    // this.$message({
                    //     message: "支付",
                    //     type: "success",
                    // });
                    // ?order=512752881187684352
                     this.$router.push({
                        path: "/softwareMarket/orderPay",
                        query: {
                            order: row.ordering,
                        },
                    });
                    break;
                case "delete":
                    this.$message({
                        message: "删除",
                        type: "success",
                    });
                    this.deleteOrder(row);
                    break;
                case "cancel":
                    // this.$message({
                    //     message: "取消",
                    //     type: "success",
                    // });
                    this.cancelOrder(row);
                    break;
            }
        },
        cancelOrder(item) {
            let _this = this;

            if (!item) {
                return;
            }

            this.$confirm("确定取消此订单吗?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    _this.$api.usercenter
                        .cancelUserOrder({
                            id: item.id,
                        })
                        .then((res) => {
                            if (res.success) {
                                this.$message.success("订单取消成功");
                                this.getTableData()
                            } else {
                                _this.$message.error(res.msg);
                            }
                        });
                })
                .catch(() => {});
        },
        deleteOrder(item) {
            let _this = this;
            if (!item) {
                return;
            }

            this.$confirm("确定删除此订单吗？此操作不可恢复", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    // _this.$api.usercenter
                    // .delUserOrder({
                    //     id: item.id,
                    // })
                    // .then((res) => {
                    //     if (res.code == 200) {
                    //         this.$message.success("订单删除成功");
                    //     } else {
                    //         _this.$message.error(res.msg);
                    //     }
                    // });
                })
                .catch(() => {});
        },
    },
};
</script>


<style lang="scss" scoped>
.pageBox {
    overflow: hidden;
    padding: 30px 0;
    .el-pagination {
        float: right;
    }
    /deep/ .el-pager li,
    /deep/ .el-pagination button {
        width: 35px;
        height: 26px;
        line-height: 26px;
        border-radius: 2px 2px 2px 2px;
        border: 1px solid #d7d7d7;
        background-color: #fff !important;

        font-size: 14px;
        font-family: DIN-Medium, DIN;
        font-weight: 500;
    }
    /deep/ .el-pager .active {
        width: 35px;
        height: 26px;
        line-height: 26px;
        background: #006fff !important;
        border-radius: 2px 2px 2px 2px;
        opacity: 1;
        border: 1px solid #006fff;
    }
}
.orderBtnBox {
    margin-left: -8px;
    .orderBtnItem {
        font-size: 12px;
        color: #1f51c8;
        line-height: 14px;
        padding: 0 8px;
        border-right: 1px solid #1f51c8;
        cursor: pointer;
    }
    :last-child {
        border-right: none;
    }
}
</style>