<template>
<div class="more_wrapper">
  <div class="content">
    <el-image style="width: 100%; height: 335px" :src="require('../assets/imgs/more.png')" :fit="'fit'"></el-image>
  </div>
</div>
</template>

<script>
export default {
  name: "more"
}
</script>

<style scoped lang="sass">
.more_wrapper
  background: #FFFFFF
</style>