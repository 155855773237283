<template>
    <div class="case-list">
        <div class="banner">
            <img :src="require('../../assets/case/banner.jpg')" alt />
        </div>

        <div class="case-content">
            <div class="case-search">
                <div>
                    <input type="text" v-model="name" placeholder="请输入关键字" />
                    <p @click="searchKeyword">
                        <span class="iconfont icon-search"></span>搜索
                    </p>
                </div>
            </div>

            <div class="case-sort">
                <div class="hangye-sort">
                    <h4>行业分类</h4>
                    <!-- <p>
                        <span>全部</span>
                    </p>-->
                    <ul>
                        <li
                            :class="hangyeIndex==index?'checkColor':''"
                            v-for="(item ,index) in hangye"
                            :key="index"
                            @click="changeHangyeIndex(item,index)"
                        >{{item.className}}</li>
                    </ul>
                </div>

                <div class="pro-sort">
                    <h4>产品分类</h4>
                    <!-- <p>
                        <span>全部</span>
                    </p>-->
                    <ul>
                        <li
                            :class="chanpinIndex==index?'checkColor':''"
                            v-for="(item ,index) in chanpin"
                            :key="index"
                            @click="changeChanpinIndex(item,index)"
                        >{{item.className}}</li>
                    </ul>
                </div>
            </div>

            <div class="case-list-cont">
                <ul :class="hiddenClass">
                    <li v-for="(item,index) in caseList" :key="index" @click="toUrl(item)">
                        <a href="javascript:;">
                            <p><img :src="item.matchImage" alt /></p>
                            <div class="bg">
                                <div>
                                    <h3>
                                        {{item.name}}
                                        <span></span>
                                    </h3>
                                    <div>
                                        <p v-for="(hy,j) in item.hangyeNameArr.slice(0,3)" :key="j">{{hy}}</p>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>

            <div class="page">
                <el-pagination
                    background
                    :hide-on-single-page="false"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page.sync="pageNum"
                    :page-size="pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="total"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            total: 0,
            pageNum: 1,
            pageSize: 12,
            name: "",
            caseList: [],
            hangyeIndex: 0,
            chanpinIndex: 0,
            hangye: [
                {
                    id: 0,
                    className: "全部",
                },
            ],
            chanpin: [
                {
                    id: 0,
                    className: "全部",
                },
            ],
            hiddenClass: "",
        };
    },
    components: {},
    mounted() {
        let id = this.$route.query.id;
        this.id = id;
        this.getCaseHyCategory();
        this.getCaseCpCategory();
        this.loadData(0, 0, "");
    },
    methods: {
        // 获取行业分类
        getCaseHyCategory() {
            let _this = this;
            _this.$api.solution
                .getCaseHyCategory({
                    parentId: 1,
                })
                .then((res) => {
                    if (!res.success || res.data.total == 0) {
                        return false;
                    }
                    let list = res.data.records;
                    list.forEach((element) => {
                        _this.hangye.push(element);
                    });
                })
                .catch((err) => {});
        },
        // 获取产品分类
        getCaseCpCategory() {
            let _this = this;
            _this.$api.solution
                .getCaseCpCategory({
                    size: 99,
                })
                .then((res) => {
                    if (!res.success || res.data.total == 0) {
                        return false;
                    }
                    let list = res.data.records;
                    list.forEach((element) => {
                        _this.chanpin.push(element);
                    });
                })
                .catch((err) => {});
        },
        changeChanpinIndex(item, index) {
            this.chanpinIndex = index;
            let _this = this;
            let hangyeId = _this.hangye[_this.hangyeIndex].id;
            let proClassId = _this.chanpin[index].id;
            let name = _this.name;
            _this.pageNum = 1;
            _this.loadData(hangyeId, proClassId, name);
        },
        changeHangyeIndex(item, index) {
            this.hangyeIndex = index;
            let _this = this;
            let hangyeId = _this.hangye[index].id;
            let proClassId = _this.chanpin[_this.chanpinIndex].id;
            let name = _this.name;
            _this.pageNum = 1;
            _this.loadData(hangyeId, proClassId, name);
        },
        loadData(hangyeId, proClassId, name) {
            let _this = this;
            let params = {
                current: _this.pageNum,
                size: _this.pageSize,
            };
            if (hangyeId > 0) {
                params.hangyeId = hangyeId;
            }
            if (hangyeId > 0) {
                params.hangyeId = hangyeId;
            }
            if (proClassId > 0) {
                params.proClassId = proClassId;
            }
            if (name != "") {
                params.name = name;
            }
            _this.$api.solution
                .getCaseList(params)
                .then((res) => {
                    if (!res.success) {
                        return false;
                    }
                    if (res.data.total == 0) {
                        _this.caseList = [];
                        return false;
                    }
                    _this.total = res.data.total;
                    let list = res.data.records;
                    if (res.data.total % 3 == 1) {
                        _this.hiddenClass = "hidden2";
                    }
                    if (res.data.total % 3 == 2) {
                        _this.hiddenClass = "hidden1";
                    }
                    _this.caseList = list;
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        handleSizeChange(val) {
            let _this = this;
            _this.pageNum = val;
            let hangyeId = _this.hangye[_this.hangyeIndex].id;
            let proClassId = _this.chanpin[_this.chanpinIndex].id;
            let name = _this.name;
            _this.loadData(hangyeId, proClassId, name);
        },
        // 直接跳转某页
        handleCurrentChange(val) {
            let _this = this;
            _this.pageNum = val;
            let hangyeId = _this.hangye[_this.hangyeIndex].id;
            let proClassId = _this.chanpin[_this.chanpinIndex].id;
            let name = _this.name;
            _this.loadData(hangyeId, proClassId, name);
        },
        searchKeyword() {
            let _this = this;
            let hangyeId = _this.hangye[_this.hangyeIndex].id;
            let proClassId = _this.chanpin[_this.chanpinIndex].id;
            let name = _this.name;
            _this.pageNum = 1;
            _this.loadData(hangyeId, proClassId, name);
        },
        toUrl(item) {
            this.$router.push({
                path: "caseDetail",
                query: {
                    id: item.id,
                },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.case-list {
    width: 100%;
    .banner {
        width: 100%;
        height: 540px;
        position: relative;
        img {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .case-content {
        width: 1200px;
        margin: 0 auto;
        .case-search {
            height: 46px;
            background-color: #f3f4f6;
            margin-top: 30px;
            margin-bottom: 20px;
            div {
                display: flex;
                padding: 7px 30px;
                justify-content: flex-end;
                input {
                    width: 443px;
                    height: 30px;
                    background-color: #ffffff;
                    box-sizing: border-box;
                    padding: 0 10px;
                }
                p {
                    width: 75px;
                    height: 32px;
                    background-color: #0b6cdb;
                    color: #ffffff;
                    font-size: 14px;
                    line-height: 22px;
                    padding: 5px 10px;
                    cursor: pointer;
                    box-sizing: border-box;
                    span {
                        margin-right: 5px;
                    }
                }
            }
        }
        .case-sort {
            border: 1px solid #e5e5e5;
            .hangye-sort {
                border-bottom: 1px solid #e5e5e5;
            }
            .hangye-sort,
            .pro-sort {
                padding: 0 35px;
                font-size: 14px;
                display: flex;
                h4 {
                    width: 56px;
                    font-size: 14px;
                    font-weight: normal;
                    color: #0b1d30;
                    margin-top: 20px;
                    display: flex;
                }
                p {
                    margin: 20px 10px 10px 30px;
                    color: #1f51c8;
                }
                ul {
                    padding: 10px 0;
                    width: 995px;
                    display: flex;
                    flex-wrap: wrap;
                    padding-left: 15px;
                    li {
                        flex-wrap: nowrap;
                        margin: 10px;
                        color: #757f92;
                        cursor: pointer;
                        user-select: none;
                    }
                    li.checkColor {
                        color: #1f51c8;
                    }
                    li:hover {
                        color: #1f51c8;
                    }
                }
            }
        }
        .case-list-cont {
            margin-top: 30px;
            ul {
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                li {
                    width: 380px;
                    height: 270px;
                    position: relative;
                    margin-bottom: 30px;
                    background-color: #cccccc;
                    overflow: hidden;
                    a {
                        >p {
                            width: 380px;
                            height: 270px;
                            position: relative;
                            img{
                                width: auto;
                                height: 270px;
                                position: absolute;
                                top: 0;
                                left: 50%;
                                transform: translateX(-50%);
                            }
                        }
                        div.bg{
                            width: 380px;
                            height: 270px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            background-color: rgba(0,0,0,0);
                            > div {
                                width: 310px;
                                height: 130px;
                                position: absolute;
                                top: 190px;
                                left: 35px;
                                transition: top 0.5s ease;
                                h3 {
                                    height: 46px;
                                    font-size: 18px;
                                    font-weight: 400;
                                    color: #ffffff;
                                    position: relative;
                                    margin-bottom: 51px;
                                    span {
                                        width: 33px;
                                        height: 3px;
                                        position: absolute;
                                        bottom: -13px;
                                        left: 0;
                                        background-color: #ffffff;
                                    }
                                }
                                div {
                                    display: flex;
                                    p {
                                        padding: 0 6px;
                                        line-height: 20px;
                                        border: 1px solid #ffffff;
                                        margin-right: 5px;
                                        font-size: 12px;
                                        color: #ffffff;
                                    }
                                }
                            }
                        }
                    }
                }
                li:hover div.bg{
                    background-color: rgba(0,0,0,0.4);
                }
                li:hover a div.bg > div {
                    top: 138px;
                }
            }
            ul.hidden1::after {
                content: "";
                width: 380px;
            }
            ul.hidden2::after {
                content: "";
                width: 790px;
            }
        }
        .page {
            margin-top: 30px;
            margin-bottom: 60px;
            text-align: right;
        }
    }
}
</style>

