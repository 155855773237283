<template>
    <div class="mailian-zhicai">
        <!-- <nav-bar></nav-bar> -->

        <div class="block">
            <!-- <el-carousel trigger="click" height="580px">
                <el-carousel-item v-for="item in 4" :key="item">
                    <h3 class="small">{{ item }}</h3>
                </el-carousel-item>
            </el-carousel> -->
            <img :src="banner" alt="">
        </div>

        <div class="purchasing_center">
            <!-- banner -->
            <!-- <div class="top_images"></div> -->

            <!-- 招标信息 -->
            <div class="purchasing_Fl">
                <!-- 招标图标 -->
                <div class="pur_header clearFix">
                    <div class="top_images fl">
                        <p></p>
                        <p>招标采购中心</p>
                    </div>
                    <div class="pur_inp fr clearFix">
                        <input type="text" placeholder="请输入关键词" class="fl" v-model="purchaseTitle" />
                        <div class="glass fl" @click="search">
                            <i class="el-icon-search"></i>
                        </div>
                    </div>
                </div>
                <!-- 招标筛选 -->
                <div class="pur_content">
                    <div class="pur_con_top clearFix">
                        <div class="title1">
                            <ul class="clearFix">
                                <li style="color:#424242;">信息类型：</li>
                                <li
                                    v-for="(item ,index) in arr1"
                                    :key="index"
                                    @click="setCenter(index,item.numZ)"
                                    :class="{selectedColor:selectIndex === index}"
                                >{{item.text}}</li>
                            </ul>
                        </div>
                        <div class="title1">
                            <ul class="clearFix">
                                <li style="color:#424242;">项目类型：</li>
                                <li
                                    v-for="(item ,index) in arr2"
                                    :key="index"
                                    @click="setCenter1(index ,item.typeId)"
                                    :class="{selectedColor:selectIndex1 === index}"
                                >{{item.typeName}}</li>
                            </ul>
                        </div>
                        <div class="title1">
                            <ul class="clearFix">
                                <li style="color:#424242;">选择时间：</li>
                                <li
                                    v-for="(item,index) in arr3"
                                    :key="index"
                                    @click="setCenter2(index,item.time)"
                                    :class="{selectedColor:selectIndex2 === index}"
                                >{{item.timeText}}</li>
                            </ul>
                        </div>

                        <div class="pur_query fr" @click="purquery">
                            <span>查询</span>
                        </div>
                    </div>
                </div>
                <!-- 招标提醒更新条 -->
                <div class="pur_remind">
                    <svg
                        t="1620883584462"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="1924"
                        width="16"
                        height="16"
                    >
                        <path
                            d="M867.744 202.784A383.456 383.456 0 0 1 1024 512c0 126.4-61.088 238.56-155.36 308.544l-38.016-51.488A319.52 319.52 0 0 0 960 512a319.552 319.552 0 0 0-130.304-257.728l38.048-51.488z m-95.072 128.704A223.68 223.68 0 0 1 864 512a223.68 223.68 0 0 1-90.432 179.84l-38.048-51.488A159.776 159.776 0 0 0 800 512a159.776 159.776 0 0 0-65.376-129.024l38.048-51.488zM64 256h199.68a64 64 0 0 0 42.496-16.16l216.576-192.512A32 32 0 0 1 576 71.264v875.488a32 32 0 0 1-54.624 22.624l-214.624-214.624A64 64 0 0 0 261.504 736H64a64 64 0 0 1-64-64V320a64 64 0 0 1 64-64z"
                            p-id="1925"
                            fill="#3e7fce"
                        />
                    </svg>
                    <span>共计更新了{{total}}条数据</span>
                </div>
                <!-- 招标列表 -->
                <div class="pur_list">
                    <el-table
                        :data="list"
                        style="width: 100%;"
                        :header-cell-style="{textAlign: 'center'}"
                        :cell-style="{ textAlign: 'center' }"
                        v-loading="loading"
                    >
                        <el-table-column label="日期" width="200">
                            <template slot-scope="socpe">
                                <!-- <span>{{socpe.row.purchasePublishTime.slice('0','10')}}</span> -->
                                <span>{{socpe.row.purchasePublishTime}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="typeName" label="项目类型" width="200"></el-table-column>
                        <el-table-column prop="statusName" label="信息类型" width="200">
                            <template slot-scope="scope">
                                <img
                                    src="../../assets/image/招标.png"
                                    v-if="scope.row.statusName =='招标'"
                                />
                                <img
                                    src="../../assets/image/拟标.png"
                                    v-if="scope.row.statusName =='项目拟建'"
                                />
                                <img
                                    src="../../assets/image/废标.png"
                                    v-if="scope.row.statusName =='废标'"
                                />
                                <img
                                    src="../../assets/image/变更.png"
                                    v-if="scope.row.statusName =='变更'"
                                />
                                <img
                                    src="../../assets/image/中标.png"
                                    v-if="scope.row.statusName =='中标'"
                                />
                                {{scope.row.statusName}}
                            </template>
                        </el-table-column>
                        <el-table-column prop="purchaseTitle" label="招标采购标题"></el-table-column>
                        <el-table-column label="操作" width="80">
                            <template slot-scope="scope">
                                <el-button round size="mini">
                                    <!--                                    @click="handleEdit(scope.row.purchaseId,scope.row.statusName)">-->
                                    <router-link
                                        :to="{path: '/seeFile?id='+ scope.row.purchaseId}"
                                    >查看</router-link>
                                </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="order_pages">
                        <el-pagination
                            background
                            v-if="pageshow"
                            @current-change="changePage"
                            :current-page="current"
                            :page-size="size"
                            layout="total, prev, pager, next, jumper"
                            :total="total"
                        ></el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import navBar from '@/components/navBar'
export default {
    data() {
        return {
            banner:require("../../assets/image/mailian_banner.jpg"),
            list: [],
            pageshow: true,
            selectIndex: 0,
            selectIndex1: 0,
            selectIndex2: 0,
            num1: "",
            num2: "",
            num3: "",
            loading: false,
            current: 1,
            size: 10,
            total: 0,

            purchaseTitle: "",
            //信息详情
            arr1: [
                {
                    numZ: null,
                    text: "全部",
                },
                {
                    numZ: 0,
                    text: "招标",
                },
                {
                    numZ: 1,
                    text: "中标",
                },
                {
                    numZ: 4,
                    text: "拟建项目",
                },
                {
                    numZ: 2,
                    text: "变更",
                },
                {
                    numZ: 3,
                    text: "废标",
                },
            ],
            //项目类型
            arr2: [
                {
                    id: 0,
                    typeName: "全部",
                },
            ],
            //选择时间
            arr3: [
                {
                    time: null,
                    timeText: "不限",
                },
                {
                    time: 1,
                    timeText: "近一周",
                },
                {
                    time: 2,
                    timeText: "近一个月",
                },
                {
                    time: 3,
                    timeText: "近三个月",
                },
                {
                    time: 4,
                    timeText: "近半年",
                },
                {
                    time: 5,
                    timeText: "近一年",
                },
            ],
        };
    },
    components: {
        // navBar
    },
    methods: {
        setCenter(index, a) {
            this.num1 = a;
            this.selectIndex = index;
        },
        setCenter1(index, b) {
            this.num2 = b;
            this.selectIndex1 = index;
        },
        setCenter2(index, c) {
            this.num3 = c;
            this.selectIndex2 = index;
        },
        //招标列表
        allListzb(data) {
            this.loading = true;
            this.$api.bidding
                .getBidList(data)
                .then((res) => {
                    this.loading = false;

                    if (res.code === 200) {
                        this.list = res.data.records;
                        this.total = res.data.total;
                    }
                    if (res.code == 400) {
                        this.list = [];
                        this.total = 0;
                    }
                })
                .catch((err) => {
                    this.loading = false;
                    console.log(err.response);
                });
        },
        //全部分类
        alltitletop() {
            this.$api.bidding.getBidTypeList().then((res) => {
                if (res.code === 200) {
                    res.data.records.forEach((element) => {
                        this.arr2.push(element);
                    });
                }
            });
        },
        //分页
        changePage(e) {
            const json = {
                statusId: this.num1,
                typeId: this.num2,
                time: this.num3,
                current: e,
                size: 10,
            };
            this.allListzb(json);
        },
        //搜索
        search() {
            this.pageshow = false;
            const json1 = {
                current: 1,
                size: 10,
                purchaseTitle: this.purchaseTitle,
            };
            this.allListzb(json1);
            this.$nextTick(() => {
                this.pageshow = true;
            });
        },
        //查询
        purquery() {
            this.pageshow = false;
            const json = {
                statusId: this.num1,
                typeId: this.num2,
                time: this.num3,
                current: 1,
                size: 10,
                purchaseParentId: "",
                purchaseTitle: "",
            };
            this.allListzb(json);
            this.$nextTick(() => {
                this.pageshow = true;
            });
        },
        //点击某一行触发
        handleEdit(id, name) {
            url = "./purdetails.html?id=" + id + "&name=" + name; //此处跳转到b并且传递参数label
            window.location.href = url;
        },
    },
    mounted() {
        const data = {
            pageNum: 1,
            pageSize: 10,
        };
        this.allListzb(data);
        this.alltitletop();
    },
};
</script>

<style lang="scss">
.mailian-zhicai {
    .el-carousel__item h3 {
        height: 580px;
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        line-height: 580px;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }
}
</style>
<style lang="scss" scoped>
.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #1d48bf;
}

.purchasing_center {
    width: 100%;
    height: auto;
    .top_images {
        width: 300px;
        height: 40px;
        line-height: 40px;
        p:first-child {
            width: 4px;
            height: 20px;
            background: #273398;
            border-radius: 1px;
            vertical-align: middle;
        }
        p {
            font-size: 20px;
            display: inline-block;
            margin-left: 6px;
            vertical-align: middle;
        }
    }
    .purchasing_Fl {
        width: 1200px;
        height: auto;
        margin: 0 auto;
        padding-bottom: 49px;
        .pur_header {
            width: 100%;
            height: 50px;
            padding-top: 30px;
            .pur_logo {
                width: 163px;
                height: 43px;
                /* background: url('../../assets/images/logoZb.png') no-repeat; */
            }
            .pur_inp {
                width: 222px;
                height: 32px;
                input {
                    width: 200px;
                    height: 30px;
                    outline: none;
                    border: none;
                    box-sizing: border-box;
                    padding-left: 15px;
                    border-radius: 3px 0 0 3px;
                    background-color: #f0f0f0;
                }
                .glass {
                    width: 22px;
                    height: 30px;
                    background-color: #f0f0f0;
                    text-align: center;
                    line-height: 30px;
                    border-radius: 0 3px 3px 0;
                    cursor: pointer;
                }
            }
        }
    }
    .pur_content {
        width: 1200px;
        height: auto;
        margin: 0 auto;
        .pur_con_top {
            width: 100%;
            height: 203px;
            box-shadow: 0px 0px 10px 0px rgba(168, 168, 168, 0.15);
            box-sizing: border-box;
            padding: 10px 100px 0 20px;
            background: #ffffff;
            .title1 {
                display: flex;
                align-items: center;
                width: 100%;
                height: 49px;
                ul {
                    height: 26px;
                    line-height: 26px;
                    li {
                        float: left;
                        box-sizing: border-box;
                        padding: 0 3px;
                        margin-right: 20px;
                        color: #828282;
                        font-size: 14px;
                        cursor: pointer;
                    }
                    .selectedColor {
                        border: 1px solid #f3701d;
                        height: 26px;
                        line-height: 19px;
                        background-color: #fff;
                        border-radius: 2px;
                        box-sizing: border-box;
                        padding: 2px 10px;
                        color: #f3701d;
                    }
                }
            }
            .pur_query {
                width: 65px;
                height: 30px;
                text-align: center;
                line-height: 30px;
                background-color: #f3701d;
                border-radius: 3px;
                cursor: pointer;
                span {
                    color: #fff;
                }
            }
        }
    }
    .pur_remind {
        width: 1200px;
        height: 40px;
        background-color: #eaf0f9;
        text-align: center;
        line-height: 40px;
        margin: 10px auto;
        font-size: 14px;
        svg {
            vertical-align: middle;
            color: #3e7fce;
        }
        span {
            font-family: "Source Han Sans CN Regular";
            vertical-align: middle;
            margin-left: 10px;
            color: #3e7fce;
        }
    }
    .pur_list {
        width: 100%;
        height: auto;
        box-shadow: 0px 0px 10px 0px rgba(168, 168, 168, 0.15);
        box-sizing: border-box;
        background: #ffffff;
        padding: 10px 30px;
        .order_pages {
            width: 100%;
            height: 90px;
            text-align: right;
            box-sizing: border-box;
            padding: 40px 0 0 0;
        }
    }
}
</style>
