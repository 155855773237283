<template>
  <div class="software-home">
    <div class="banner">
      <img :src="require('../../assets/pro-family/banner3.jpg')" alt="">
    </div>
    <div class="horse-race">
      <!-- <ul>
        <li><a href="">
          <img src="" alt="">
          <div>
            <h3>标识应用</h3>
            <p>数字未来 万物标识</p>
          </div>
        </a></li>
        <li><a href="">
          <img src="" alt="">
          <div>
            <h3>工业物联网</h3>
            <p>一站式连接工业自动化设备、工业网关、企业信息化设备</p>
          </div>
        </a></li>
        <li><a href="">
          <img src="" alt="">
          <div>
            <h3>企业级平台</h3>
            <p>信息共享、信息汇总、内部交流、系统闭环</p>
          </div>
        </a></li>
        <li><a href="">
          <img src="" alt="">
          <div>
            <h3>业财一体化</h3>
            <p>全方位智能扩展服务，根据业务变更快速迭代</p>
          </div>
        </a></li>
        <li><a href="">
          <img src="" alt="">
          <div>
            <h3>电子签章</h3>
            <p>与纸质合同具有同等效力</p>
          </div>
        </a></li>
        <li><a href="">
          <img src="" alt="">
          <div>
            <h3>标识身份申领</h3>
            <p>工业互联网的神经枢纽</p>
          </div>
        </a></li>
        <li><a href="">
          <img src="" alt="">
          <div>
            <h3>快速开发</h3>
            <p>快速制作表单/工作流/可视化数据大屏等</p>
          </div>
        </a></li>
      </ul> -->
    <swiper class="swiper" :options="swiperOption">
      <swiper-slide v-for="item,index in swiperData" :key="index">
        <a :href="item.href" target="_blank">
          <img :src="item.thumb" alt="">
          <!-- <div>
            <h3>{{ item.h3 }}</h3>
            <p>{{ item.p }}</p>
          </div> -->
        </a>
      </swiper-slide>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    </swiper>
    </div>

    <div class="product-list">
      <div class="one">
        <h3>热门推荐</h3>
        <ul>
          <li :style="{backgroundImage: 'url(' + bg1 + ')'}">
            <h4>T+专属云</h4>
            <p>专注中小型企业，使企业更好运用资源。库存下降、采购提前期缩短、降低制造成本、减少延迟交货期、提高管理水平、减少管理人员、提高生产能力、减少停工待料。</p>
            <a href="/SoftwareMarket/details?id=765" target="_blank"><span>了解详情</span></a>
          </li>
          <li :style="{backgroundImage: 'url(' + bg2 + ')'}">
            <h4>设备管理系统（点检保养）</h4>
            <p>
              以全生命周期为主线，预防性维护为中心，兼顾设备档案、备品备件的管理，引入物联技术实现设备状态的实时监控与故障预警，帮助企业实现设备的规范化、科学化、智能化管理，降低设备故障率，保持设备稳定性，实现企业资产效益的全面提升。</p>
            <a href="/SoftwareMarket/details?id=714" target="_blank"><span>了解详情</span></a>
          </li>
          <li :style="{backgroundImage: 'url(' + bg3 + ')'}">
            <h4>智能仓储（云WMS）-智能一体化仓库管理</h4>
            <p>智能自动化货位路线、推荐拣货路径管理，提高拣配货效率，对仓库库位、作业单元、作业单据、指令等管理，实现实物流、单据流、数据流同步统一，库存更准确，物品可追溯。实现订单库存可视化、可根据运输安排提前备货，缓解仓库压力，提升配送时效</p>
            <a href="/SoftwareMarket/details?id=154" target="_blank"><span>了解详情</span></a>
          </li>
          <li :style="{backgroundImage: 'url(' + bg4 + ')'}">
            <h4>生产制造（云MES）</h4>
            <p>生产全过程控制与追踪监管，借助基于物联网的解决方案让企业全面升级，实现制造设备数字化、生产信息数字化、质检数字化，最终实现透明化车间，解决智能制造周期，效益，成本，质量等问题，创造有效价值。</p>
            <a href="/SoftwareMarket/details?id=683" target="_blank"><span>了解详情</span></a>
          </li>
        </ul>
      </div>

      <div class="two">
        <div>
          <h3>SaaS云应用</h3> 
          <router-link :to="{path: '/SoftwareMarket/saasList'}">查看更多 <span class="iconfont icon-icon-arrow_11"></span></router-link>
        </div>
        <ul>
          <li><a href="/SoftwareMarket/details?id=703" target="_blank">
            <img :src="require('../../assets/pro-family/icon_12.png')" alt="">
            <div>
              <h4>标识应用</h4>
              <p class="introduce">打造内外部及供应链环节的整体统一的信息流通、提升企业精细化管理水平。</p>
              <div>
                <span>防伪防窜</span>
                <span>数据营销</span>
                <span>信息共享</span>
              </div>
              <p class="price"><i>8500</i><span> 元起</span></p>
            </div>
          </a>
            <img :src="require('../../assets/pro-family/new.png')" alt="">
          </li>
          <li><a href="/SoftwareMarket/details?id=712" target="_blank">
            <img :src="require('../../assets/pro-family/icon_11.png')" alt="">
            <div>
              <h4>T100</h4>
              <p class="introduce">智能、互联的集团运营管理</p>
              <div>
                <span>流程审批</span>
                <span>绩效管理</span>
                <span>信息共享</span>
              </div>
              <p class="price"><i style="font-size: 14px;">面议</i></p>
            </div>
          </a>
            <img :src="require('../../assets/pro-family/new.png')" alt="">
          </li>
          <li><a href="/SoftwareMarket/details?id=718" target="_blank">
            <img :src="require('../../assets/pro-family/icon_8.png')" alt="">
            <div>
              <h4>企业级互联网平台</h4>
              <p class="introduce">数据驱动为核心的网络化、扁平化、平台化的经营管理</p>
              <div>
                <span>统一标准化</span>
                <span>多维度管理</span>
              </div>
              <p class="price"><i>20</i><span> 万元起</span></p>
            </div>
          </a>
            <img :src="require('../../assets/pro-family/hot.png')" alt="">
          </li>
          <li><a href="/SoftwareMarket/details?id=740" target="_blank">
            <img :src="require('../../assets/pro-family/icon_9.png')" alt="">
            <div>
              <h4>客户管理（云CRM）</h4>
              <p class="introduce">提升企业管理效益、客户满意度，实现可持续业绩增长</p>
              <div>
                <span>项目协作</span>
                <span>高效协同</span>
              </div>
              <p class="price"><i>200</i><span>/人/年</span></p>
            </div>
          </a>
            <img :src="require('../../assets/pro-family/new.png')" alt="">
          </li>
          <li><a href="/SoftwareMarket/details?id=742" target="_blank">
            <img :src="require('../../assets/pro-family/icon_10.png')" alt="">
            <div>
              <h4>迈链数智</h4>
              <p class="introduce">升级品控管理，助力营销迭代。</p>
              <div>
                <span>创新营销</span>
                <span>互联互通</span>
                <span>追根溯源</span>
              </div>
              <p class="price"><i>8500</i><span> 元起</span></p>
            </div>
          </a>
            <img :src="require('../../assets/pro-family/new.png')" alt="">
          </li>
          <li><a href="/SoftwareMarket/details?id=704" target="_blank">
            <img :src="require('../../assets/pro-family/icon_11.png')" alt="">
            <div>
              <h4>工业物联网平台</h4>
              <p class="introduce">覆盖物联网云、管、边、端各个环节，满足各类开发者产品开发需求。</p>
              <div>
                <span>3D工厂</span>
                <span>数据采集</span>
                <span>数据决策</span>
              </div>
              <p class="price"><i>20</i><span> 万元起</span></p>
            </div>
          </a>
            <img :src="require('../../assets/pro-family/hot.png')" alt="">
          </li>
        </ul>
      </div>

      <div class="three" :style="{backgroundImage: 'url(' + bg5 + ')'}">
        <div class="left">
          <h3>更多软件应用<span></span></h3>
        </div>
        <div class="right">
          <p>软件应用是以遵循软件工程的相关规范为基础，以CMM相关质量控制方法为核心思想,对定制开发平台的全过程控制与管理。而不是常规式的“瀑布式”开发模式，以帮助公司实现管理提升和满足业务快速发展扩张的需要。</p>
          <ul>
            <li><a href="/SoftwareMarket/details?id=703" target="_blank">
              <img :src="require('../../assets/pro-family/icon1.png')" alt="">
              <div>
                <h4>标识解析应用</h4>
                <p>标识+”创新服务模式，搭建为企业统一标识体系的标识应用服务平台</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=716" target="_blank">
              <img :src="require('../../assets/pro-family/icon6.png')" alt="">
              <div>
                <h4>轻应用开发平台</h4>
                <p>帮助企业快速搭建云原生应用的低代码平台</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=720" target="_blank">
              <img :src="require('../../assets/pro-family/icon7.png')" alt="">
              <div>
                <h4>工控安全评估系统</h4>
                <p>加强和完善企业自身工控安全建设。</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=713" target="_blank">
              <img :src="require('../../assets/pro-family/icon8.png')" alt="">
              <div>
                <h4>VR可视化决策平台</h4>
                <p>虚拟现实工厂，沉浸式数据检测把控管理</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=717" target="_blank">
              <img :src="require('../../assets/pro-family/icon9.png')" alt="">
              <div>
                <h4>企业诊断测评</h4>
                <p>针对企业核心业务能力、数据资产、人员组织全方位诊断。</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=719" target="_blank">
              <img :src="require('../../assets/pro-family/icon10.png')" alt="">
              <div>
                <h4>大数据开发平台</h4>
                <p>通过数据驱动，构建数用一体化应用系统</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=715" target="_blank">
              <img :src="require('../../assets/pro-family/icon12.png')" alt="">
              <div>
                <h4>数据管理大屏</h4>
                <p>实时采集系统数据，整合数据资源，统一展示分析处理</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=723" target="_blank">
              <img :src="require('../../assets/pro-family/icon11.png')" alt="">
              <div>
                <h4>边缘计算-数据分析应用</h4>
                <p>一站式连接工业自动化设备、工业网关、企业信息化设备</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=724" target="_blank">
              <img :src="require('../../assets/pro-family/icon4.png')" alt="">
              <div>
                <h4>产品全生命周期管理系统PLM</h4>
                <p>有效调整经营手段和管理方法，以建立企业的竞争优势</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=677" target="_blank">
              <img :src="require('../../assets/pro-family/icon2.png')" alt="">
              <div>
                <h4>人员定位管理</h4>
                <p>创新融合定位，低成本、高精度</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=679" target="_blank">
              <img :src="require('../../assets/pro-family/icon5.png')" alt="">
              <div>
                <h4>业财一体化</h4>
                <p>全过程管理，解决决策数据不及时的问题</p>
              </div>
            </a></li>
          </ul>
        </div>
      </div>

      <div class="three" :style="{backgroundImage: 'url(' + bg6 + ')'}">
        <div class="left">
          <h3>数据采集<span></span></h3>
        </div>
        <div class="right">
          <p>数据采集可支持LTE/WiFi/Ethernet等多种网络， 拥有强大的边缘数据采集能力，在物联网边缘节点实现数据优化、实时响应、敏捷连接、智能分析;
            显著减少现场与中心端的数据流量，并避免云端运算能力遇到瓶颈，优化网络架构，更安全、更快响应，同时低成本智能...</p>
          <ul>
            <li><a href="/SoftwareMarket/details?id=708" target="_blank">
              <!-- <img :src="require('../../assets/Saas/mes/b-icon6.png')" alt=""> -->
              <img :src="require('../../assets/pro-family/icon21.png')" alt="">
              <div>
                <h4>PDA移动智能终端机</h4>
                <p>M3超轻便大屏智能手持终端。</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=706" target="_blank">
              <img :src="require('../../assets/pro-family/icon22.png')" alt="">
              <div>
                <h4>智能赋码机</h4>
                <p>满足高速生产线产品标识需求。</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=709" target="_blank">
              <img :src="require('../../assets/pro-family/icon23.png')" alt="">
              <div>
                <h4>电子标签FRID</h4>
                <p>柔性抗金属标签，适用范围广泛。</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=707" target="_blank">
              <img :src="require('../../assets/pro-family/icon24.png')" alt="">
              <div>
                <h4>智能FRID手持机</h4>
                <p>R8超高频RFID手持终端。</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=710" target="_blank">
              <img :src="require('../../assets/pro-family/icon25.png')" alt="">
              <div>
                <h4>工业级边缘计算网关</h4>
                <p>LTE全网通|容器化边缘计算</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=705" target="_blank">
              <img :src="require('../../assets/pro-family/icon26.png')" alt="">
              <div>
                <h4>智能机械臂</h4>
                <p>360°可旋转机臂，便携式操作，提高效率。</p>
              </div>
            </a></li>
          </ul>
        </div>
      </div>

      <div class="three" :style="{backgroundImage: 'url(' + bg7 + ')'}">
        <div class="left">
          <h3>网络安全<span></span></h3>
        </div>
        <div class="right">
          <p>
            网络安全是指计算机网络系统的硬件、软件及其系统中的数据受到保护，不因偶然的或者恶意的原因而遭受到破坏、更改、泄露，系统连续可靠正常地运行，网络服务不中断。凡是涉及到网络上信息的保密性、完整性、实用性、真实性和可控性的相关技术和理论都是网络安全的研...</p>
          <ul>
            <li><a href="/SoftwareMarket/details?id=699" target="_blank">
              <img :src="require('../../assets/pro-family/icon14.png')" alt="">
              <div>
                <h4>Web应用防火墙</h4>
                <p>多维度防御策略，为网站拦截隔离Web类型的攻击</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=697" target="_blank">
              <img :src="require('../../assets/pro-family/icon15.png')" alt="">
              <div>
                <h4>服务器安全卫士</h4>
                <p>快速精准地发现安全威胁和入侵事件</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=685" target="_blank">
              <img :src="require('../../assets/pro-family/icon16.png')" alt="">
              <div>
                <h4>渗透测试</h4>
                <p>对系统任何弱点、技术缺陷或漏洞主动分析</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=690" target="_blank">
              <img :src="require('../../assets/pro-family/icon17.png')" alt="">
              <div>
                <h4>网页防篡改</h4>
                <p>为防止黑客、病毒等对目任何类型的文件进行非法篡改和破坏提供...</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=687" target="_blank">
              <img :src="require('../../assets/pro-family/icon18.png')" alt="">
              <div>
                <h4>数据加密</h4>
                <p>为用户提供云上数据加密服务</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=688" target="_blank">
              <img :src="require('../../assets/pro-family/icon19.png')" alt="">
              <div>
                <h4>数据库安全</h4>
                <p>提供旁路模式的数据库安全审计服务功能</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=693" target="_blank">
              <img :src="require('../../assets/pro-family/icon20.png')" alt="">
              <div>
                <h4>漏洞扫描</h4>
                <p>对主机安全进行检查和分析，及时修复漏洞提高系统安全防护能力。</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=684" target="_blank">
              <img :src="require('../../assets/pro-family/icon30.png')" alt="">
              <div>
                <h4>Anti-DDoS流量清洗</h4>
                <p>可监控攻防状态，实时保证业务安全运行。</p>
              </div>
            </a></li>
            <li><a href="/SoftwareMarket/details?id=691" target="_blank">
              <img :src="require('../../assets/pro-family/icon31.png')" alt="">
              <div>
                <h4>终端杀毒</h4>
                <p>为企事业单位提供终端病毒、漏洞管控能力。</p>
              </div>
            </a></li>
          </ul>
        </div>
      </div>

      <div class="four">
        <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">
          <img :src="require('../../assets/pro-family/pic_8.png')" alt="">
        </a>
      </div>
      <!-- <iframe :src="url" width="100%" height="1080" border="0"></iframe> -->
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  data() {
    return {
      bg1: require("../../assets/pro-family/pic_1.jpg"),
      bg2: require("../../assets/pro-family/pic_2.jpg"),
      bg3: require("../../assets/pro-family/pic_3.jpg"),
      bg4: require("../../assets/pro-family/pic_4.jpg"),

      bg5: require("../../assets/pro-family/pic_5.png"),
      bg6: require("../../assets/pro-family/pic_6.png"),
      bg7: require("../../assets/pro-family/pic_7.png"),
      url: 'http://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/ruianda/fc422419-92ff-489f-826d-cbd2143ebd26.pdf',
      fileList: [{
        name: 'food.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }, {
        name: 'food2.jpeg',
        url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'
      }],
      swiperOption: {
          slidesPerView: 6.18,
          spaceBetween: 5,
          speed: 8000,
          autoplay: {
            delay: 0,
            stopOnLastSlide: false,
            disableOnInteraction: true,
          },
          loop: true,
          // pagination: {
          //   el: '.swiper-pagination',
          //   clickable: true
          // },
      },
      swiperData:[
        {
          id:703,
          href:"/SoftwareMarket/details?id=703",
          thumb:require('../../assets/pro-family/newswiper_1.png'),
          h3:"标识解析应用",
          p:"打通企业生产制造各环节，信息互通，数据共享、防伪防窜"
        },
        {
          id:716,
          href:"/SoftwareMarket/details?id=716",
          thumb:require('../../assets/pro-family/newswiper_2.png'),
          h3:"轻应用开发",
          p:"帮助企业快速搭建云原生应用的低代码平台"
        },
        {
          id:683,
          href:"/SoftwareMarket/details?id=683",
          thumb:require('../../assets/pro-family/newswiper_3.png'),
          h3:"生产管理",
          p:"对产品生产全过程控制与追踪，监管产品质量与制造过程细节"
        },
        {
          id:713,
          href:"/SoftwareMarket/details?id=713",
          thumb:require('../../assets/pro-family/newswiper_4.png'),
          h3:"VR可视化决策平台",
          p:"虚拟现实工厂，沉浸式数据检测把控管理"
        },
        {
          id:720,
          href:"/SoftwareMarket/details?id=720",
          thumb:require('../../assets/pro-family/newswiper_5.png'),
          h3:"工控安全评估系统",
          p:"加强和完善企业自身工控安全建设。"
        },
        {
          id:215,
          href:"/SoftwareMarket/details?id=215",
          thumb:require('../../assets/pro-family/newswiper_6.png'),
          h3:"设备管理",
          p:"设备信息化管理、保障设备良好运行、降低设备故障率和事故率、节能降耗"
        },
        {
          id:715,
          href:"/SoftwareMarket/details?id=715",
          thumb:require('../../assets/pro-family/newswiper_7.png'),
          h3:"数字化管理大屏",
          p:"实时采集系统数据，整合数据资源，统一展示分析处理"
        },
        {
          id:717,
          href:"/SoftwareMarket/details?id=717",
          thumb:require('../../assets/pro-family/newswiper_8.png'),
          h3:"企业诊断测评",
          p:"针对企业核心业务能力、数据资产、人员组织全方位诊断。"
        },
        
      ]
    }
  },
  components: {
    Swiper,
    SwiperSlide
  },
  methods: {
    handleChange(file, fileList) {
      this.fileList = fileList.slice(-3);
    }
  }
}
</script>

<style lang="scss" scoped>
.software-market {
  .banner {
    width: 100%;
    position: relative;
    // height: 540px;
    height: 680px;
    // margin-top: 60px;
    overflow: hidden;
    img {
      width: auto;
      height: 680px;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .horse-race {
    width: 100%;
    overflow: hidden;
    margin-top: 15px;

    .swiper {
      width: 2170px;
      height: 100px;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      .swiper-slide {
        width: 300px;
        height: 100px;
        margin: 0 5px;
        background-color: #F4F5F7;

        a {
          display: flex;
          // padding: 20px 10px 20px 15px;
          align-items: center;

          img {
            // width: 60px;
            // height: 60px;
            // margin-right: 10px;
            width: 100%;
          }

          div {
            h3 {
              font-weight: normal;
              font-size: 16px;
              height: 20px;
              line-height: 20px;
              margin-bottom: 5px;
            }

            p {
              font-size: 12px;
              height: 36px;
              line-height: 18px;
              color: #757F92;
            }
          }
        }
      }
    }

    // ul {
    //   width: 2170px;
    //   height: 100px;
    //   display: flex;
    //   flex-wrap: wrap;
    //   overflow: hidden;

    //   li {
    //     width: 300px;
    //     height: 100px;
    //     margin: 0 5px;
    //     background-color: #F4F5F7;

    //     a {
    //       display: flex;
    //       padding: 20px 10px 20px 15px;
    //       align-items: center;

    //       img {
    //         width: 60px;
    //         height: 60px;
    //         margin-right: 10px;
    //       }

    //       div {
    //         h3 {
    //           font-weight: normal;
    //           font-size: 16px;
    //           height: 20px;
    //           line-height: 20px;
    //           margin-bottom: 5px;
    //         }

    //         p {
    //           font-size: 12px;
    //           height: 15px;
    //           line-height: 15px;
    //           color: #757F92;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  .product-list {
    width: 100%;

    h3 {
      font-weight: normal;
      font-size: 34px;
      color: #0b1d30;
      margin-bottom: 60px;
    }

    > div {
      width: 1200px;
    }

    .one {
      margin: 100px auto;

      ul {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        li {
          width: 590px;
          height: 287px;
          margin-bottom: 20px;
          box-sizing: border-box;
          padding: 30px 0 33px 180px;
          background-color: #cccccc;
          color: #ffffff;
          h4 {
            font-size: 18px;
            font-weight: normal;
            margin-bottom: 7px;
          }

          p {
            width: 360px;
            height: 120px;
            font-size: 13px;
            line-height: 24px;
            overflow: hidden;
            margin-bottom: 42px;
          }

          a {
            color: #ffffff;
            text-align: center;
            padding: 8px 27px;
            font-size: 14px;
            background-color: #1F51C8;

            span {
              font-size: 14px;
            }
          }
          a:hover{
            background-color: #3261d1;
          }
        }
      }
    }

    .two {
      margin: 0 auto;
      margin-bottom: 80px;
      >div{
        display: flex;
        justify-content: space-between;
        font-family: 'Microsoft YaHei UI-Light, Microsoft YaHei UI';
        a{
          color: #757F92;
          font-size: 16px;
          height: 20px;
        }
      }
      ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;

        li {
          width: 380px;
          height: 180px;
          position: relative;
          box-sizing: border-box;
          padding: 20px 30px;
          box-shadow: 0 0 5px rgba(0, 0, 0, 0.08);
          transition: all 0.75s ease;
          margin-bottom: 20px;
          cursor: pointer;
          > img {
            width: 54px;
            height: 56px;
            position: absolute;
            top: -3px;
            left: -3px;
          }

          a {
            width: 320px;
            display: flex;
            justify-content: space-between;

            > div {
              width: 212px;

              h4 {
                font-weight: normal;
                font-size: 14px;
                color: #0B1D30;
              }

              .introduce {
                height: 36px;
                line-height: 18px;
                font-size: 12px;
                color: #757f92;
                margin-top: 10px;
              }
              div{
                margin-top: 8px;
                margin-bottom: 20px;
                span{
                  color: #3977F3;
                  font-size: 12px;
                  padding: 4px;
                  margin-right: 5px;
                  border: 1px solid #3977F3;
                }
              }
              .price{
                color: #FF0027;
                span{
                  font-size: 12px;
                  color: #757F92;
                }
                i{
                  font-size: 24px;
                  font-style: normal;
                }
              }
            }

            img {
              width: 80px;
              height: 80px;
            }
          }
        }
        li:hover{
          box-shadow: 0 0 15px rgba(68, 54, 54, 0.2);
        }
      }
    }

    .three {
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      padding: 50px 38px 50px 30px;
      background-color: #cccccc;
      margin-bottom: 60px;
      box-sizing: border-box;

      background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;

      .left {
        width: 192px;

        h3 {
          position: relative;
          font-size: 32px;
          color: #0B1D30;
          font-weight: 400;
          line-height: 41px;

          span {
            position: absolute;
            bottom: -20px;
            left: 0;
            width: 30px;
            height: 3px;
            background-color: #1F51C8;
          }
        }
      }

      .right {
        width: 885px;

        > p {
          height: 48px;
          font-size: 14px;
          color: #757F92;
          line-height: 24px;
          overflow: hidden;
          margin-bottom: 20px;
        }

        ul {
          height: 327px;
          display: flex;
          justify-content: flex-start;
          align-content: flex-start;
          flex-wrap: wrap;
          overflow-y: auto;

          li {
            width: 283px;
            height: 94px;
            margin-right: 15px;
            margin-bottom: 15px;
            padding: 13px 20px 14px 20px;
            box-sizing: border-box;
            background-color: #ffffff;

            a {
              display: flex;
              justify-content: space-between;

              img {
                width: 40px;
                height: 40px;
                margin-top: 8px;
              }

              div {
                width: 190px;

                h4 {
                  font-size: 14px;
                  color: #0B1D30;
                  font-weight: normal;
                }

                p {
                  font-size: 12px;
                  color: #757f92;
                  line-height: 18px;
                  height: 36px;
                  overflow: hidden;
                }
              }
            }
          }

          li:nth-child(3n) {
            margin-right: 0;
          }
          li:hover{
            box-shadow: 0 0 15px rgba(68, 54, 54, 0.15);
          }
        }
      }
    }

    .four {
      width: 100%;
      // height: 200px;
      overflow: hidden;
      position: relative;
      margin-top: 80px;
      overflow: hidden;
      img {
        width: 100%;
        // height: 200px;
        // position: absolute;
        // top: 0;
        // left: 50%;
        // transform: translateX(-50%);
      }
    }
  }
}
</style>