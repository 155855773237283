<template>
    <div class="case-details">
        <!-- banner -->
        <div class="banner">
            <div class="ban-center">
                <div class="left">
                    <p><img :src="detail.matchImage" alt /></p>
                </div>
                <div class="right">
                    <h3>{{detail.name}}</h3>
                    <div class="sort">
                        <p>案例分类：</p>
                        <ul>
                            <li v-for="item in detail.proClassNameArr" :key="item">{{item}}</li>
                        </ul>
                    </div>
                    <div class="label">
                        <p>能力标签：</p>
                        <ul>
                            <li v-for="item in detail.abilityTagArr" :key="item">{{item}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="case-det-main">
            <div class="left">
                <h3>案例详情</h3>
                <div class="left-detail">
                    <div v-html="detail.content"></div>
                </div>
            </div>
            <div class="right">
                <h3>案例推荐</h3>
                <ul>
                    <li v-for="(item,index) in selectCase" :key="index" @click="toDetail(item)">
                        <a href>
                            <!-- <img :src="item.matchImage" alt /> -->
                            <div class="caseImage" :style="{backgroundImage:'url('+item.matchImage+')'}">

                            </div>
                            <h4>{{item.name}}</h4>
                            <div>
                                <p v-for="(hy,j) in item.hangyeNameArr" :key="j">{{hy}}</p>
                            </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>

        <div class="pro-recom" v-if="selectProduct.length > 0">
            <div class="pro-recom-center">
                <h3>产品推荐</h3>
                <ul>
                    <li
                        v-for="(item,index) in selectProduct"
                        :key="index"
                        @click="toProDetail(item)"
                    >
                        <a href>
                            <img :src="item.recommended" alt />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            selectCase: [],
            selectProduct: [],
            detail: {
                matchImage: "",
                name: "",
            },
        };
    },
    components: {},
    mounted() {
        let id = this.$route.query.id;
        this.id = id;
        this.loadDetail(id);
        this.loadCaseList(id);
        this.loadCaseSelectProduct(id);
    },
    methods: {
        loadDetail(id) {
            if (!id) {
                this.$message.error("加载失败，请返回重试");
                return false;
            }
            let _this = this;
            _this.$api.solution
                .getCaseDetail({
                    id,
                })
                .then((res) => {
                    this.detail = res.data;
                })
                .catch((err) => {
                    console.log(err);
                    let response = err.response;
                    if (response.status == 400) {
                        this.$message.error(response.data.error_description);
                    }
                    if (response.status == 500) {
                        this.$message.error(response.data.msg);
                    }
                });
        },
        loadCaseList(id) {
            let _this = this;
            _this.$api.solution
                .getSelectCaseList({
                    id,
                })
                .then((res) => {
                    _this.selectCase = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        loadCaseSelectProduct(id) {
            let _this = this;
            _this.$api.solution
                .getCaseSelectProduct({
                    id,
                })
                .then((res) => {
                    _this.selectProduct = res.data;
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        loadProductLis() {
            this.selectProduct = [];
        },
        toDetail(item) {
            if (!item) {
                return false;
            }
            this.$router.replace({
                query: {
                    id: item.id,
                },
            });
        },
        toProDetail(item) {
            if (!item) {
                return false;
            }
            this.$router.replace({
                path:"/SoftwareMarket/details",
                query: {
                    id: item.id,
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.case-details {
    .banner {
        height: 540px;
        margin-bottom: 90px;
        background: url('../../assets/case/caseDetails/bg.jpg') center no-repeat;
        .ban-center {
            width: 1200px;
            margin: 0 auto;
            display: flex;
            padding-top: 60px;
            .left {
                margin-right: 60px;
                p {
                    width: 600px;
                    height: 380px;
                    position: relative;
                    img{
                        width: auto;
                        height: 380px;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                }
            }
            .right {
                width: 492px;
                h3 {
                    height: 82px;
                    line-height: 41px;
                    font-size: 32px;
                    color: #0b1d30;
                    font-size: 400;
                    margin-bottom: 46px;
                    padding-top: 88px;
                }
                .sort,
                .label {
                    display: flex;
                    p{
                        width: 84px;
                    }
                    
                    ul {
                        display: flex;
                        flex-wrap: wrap;
                        width: calc(100% - 84px);
                        li {
                            margin-right: 5px;
                        }
                    }
                }
                .label {
                    margin-top: 32px;
                    ul {
                        li {
                            width: 88px;
                            height: 20px;
                            text-align: center;
                            line-height: 20px;
                            border: 1px solid #0b1d30;
                            font-size: 12px;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
    .case-det-main {
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        margin-bottom: 70px;
        h3 {
            border-left: 3px solid #1f51c8;
            padding-left: 13px;
            font-size: 22px;
            font-weight: 400;
            margin-bottom: 30px;
        }
        .left {
            width: 840px;
            background-color: #ffffff;
            margin-right: 40px;
            .left-detail{
                >div{
                    font-size: 16px;
                    color: #000000;
                    line-height: 32px;
                }
            }
        }
        .caseImage{
            width: 282px;
            height: 158px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            margin-bottom: 20px;
        }
        .right {
            align-self: baseline;
            width: 320px;
            background-color: #ffffff;
            box-sizing: border-box;
            ul {
                li {
                    padding: 20px 18px;
                    margin-bottom: 20px;
                    border: 1px solid #e5e5e5;
                    a {
                        img {
                            width: 282px;
                            height: 126px;
                            margin-bottom: 20px;
                        }
                        h4 {
                            font-size: 14px;
                            height: 16px;
                            font-weight: normal;
                            color: #0B1D30;
                            margin-bottom: 15px;
                            overflow: hidden;
                        }
                        div {
                            display: flex;
                            flex-wrap: wrap;
                            p {
                                padding: 0 5px;
                                font-size: 12px;
                                color: #757f92;
                                line-height: 15px;
                                border: 1px solid #757f92;
                                margin-right: 5px;
                                margin-bottom: 8px;
                            }
                        }
                    }
                }
                li:hover {
                    a h4{
                        color: #1f51c8;
                    }
                }
            }
        }
    }
    .pro-recom {
        padding: 90px 0;
        background-color: #f6f8fb;
        .pro-recom-center {
            width: 1200px;
            margin: 0 auto;
            h3 {
                font-size: 34px;
                font-weight: 400;
                margin-bottom: 60px;
            }
            ul {
                display: flex;
                justify-content: space-around;
                li {
                    box-shadow: 0 0 3px rgba(0, 0, 0, 0.05);
                    img {
                        width: 285px;
                        height: 200px;
                    }
                }
            }
        }
    }
}
</style>
