<template>
    <div class="address clearFix">
        <!-- <center-index></center-index> -->
        <div class="right">
            <p>共{{ totalAdress }}个地址，最终保存10个</p>

            <div class="table">
                <el-table :data="tableData"
                    :header-cell-style="{ background: '#f5f5f5', color: '#333333', fontWeight: 'normal' }" style="width: 100%"
                    empty-text="暂无数据">
                    <el-table-column prop="userName" label="收货人姓名" width="110"></el-table-column>
                    <el-table-column prop="phone" label="电话号码" width="110"></el-table-column>
                    <el-table-column prop="addrName" label="地址" width="331"></el-table-column>
                    <el-table-column prop="yzcode" label="邮编" width="110"></el-table-column>
                    <el-table-column prop="isDefault" label="是否默认" style="color: #FF6A00;" width="110"></el-table-column>
                    <el-table-column fixed="right" label="操作" width="110">
                        <template slot-scope="scope">
                            <el-button @click="modifyAddress(scope.row)" type="text" size="small">修改</el-button>
                            <el-button @click="deleteAddress(scope.row)" type="text" size="small">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <h6 @click="AddressAdd">
                <span>+新增地址</span>（最多添加10个）
            </h6>
        </div>

        <el-dialog width="940px" :visible.sync="addAddress" :close-on-click-modal="false" title="收货地址"
            :append-to-body="true">
            <div class="address_es">
                <el-form :model="form" ref="form" :rules="rules">
                    <el-form-item label="收件人" :label-width="formLabelWidth" required prop="userName">
                        <el-input v-model="form.userName"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-col :span="8">
                            <el-form-item label="区域地址" :label-width="formLabelWidth" required prop="check_province">
                                <el-select v-model="form.check_province" placeholder="请选择省" @change="onSelectedProvince">
                                    <el-option v-for="item in form.province" :key="item.code" :label="item.name"
                                        :value="item.code"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="1" style="text-align: center;">-</el-col>
                        <el-col :span="7">
                            <el-form-item prop="check_city">
                                <el-select v-model="form.check_city" placeholder="请选择市" @change="onSelectedCity">
                                    <el-option v-for="item in form.city" :key="item.code" :label="item.name"
                                        :value="item.code"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="1" style="text-align: center;">-</el-col>
                        <el-col :span="7">
                            <el-form-item prop="check_county">
                                <el-select v-model="form.check_county" placeholder="请选择区/县" @change="onSelectedCounty">
                                    <el-option v-for="item in form.county" :key="item.code" :label="item.name"
                                        :value="item.code"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-form-item>

                    <el-form-item label="详细地址" :label-width="formLabelWidth" required prop="addrName">
                        <el-input v-model="form.addrName" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话" :label-width="formLabelWidth" required prop="phone">
                        <el-input v-model="form.phone" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="邮政编码" :label-width="formLabelWidth" required prop="yzcode">
                        <el-input v-model="form.yzcode" autocomplete="off" minlength="6" maxlength="6"></el-input>
                    </el-form-item>
                    <el-form-item label>
                        <el-checkbox-group v-model="form.isDefault">
                            <el-checkbox label="1" name="isDefault">设为默认地址</el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="addAddress = false">取 消</el-button>
                    <el-button type="primary" @click="submitAddress">确 定</el-button>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
// import centerIndex from "@/components/centerIndex"
import Qs from "qs";
import jsCookie from "js-cookie";

export default {
    data() {
        return {
            formLabelWidth: "80px",
            addAddress: false,
            isAddOrmodify: "",
            form: {
                userName: "", // 收件人
                check_province: "", // 选中省
                province: [], // 省
                check_city: "", // 选中市
                city: [], // 城市
                check_county: "", // 选中县/区
                county: [], // 县/区
                addrName: "", // 详细地址
                phone: "", // 电话
                yzcode: "", // 邮编
                regionId: "",
                isDefault: [], // 是否默认地址
            },
            tableData: [
                {
                    userName: "",
                    phone: "",
                    addrName: "",
                    yzcode: "",
                    areaname: "",
                    isDefault: "",
                },
            ],
            totalAdress: 0,
            receiverId: "",
            rules: {
                userName: {
                    required: true,
                    message: "请输入收件人姓名",
                    trigger: "blur",
                },
                check_province: {
                    required: true,
                    message: "请选择省区域地址",
                    trigger: "blur",
                },
                check_city: {
                    required: true,
                    message: "请选择市区域地址",
                    trigger: "blur",
                },
                addrName: {
                    required: true,
                    message: "请输入详细地址",
                    trigger: "blur",
                },
                phone: [
                    {
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur",
                    },
                    {
                        pattern: /^1\d{10}$/,
                        message: "手机号格式不对",
                        trigger: "blur",
                    },
                ],
                yzcode: [
                    {
                        required: true,
                        message: "邮政编码为必填项，且必须是6位纯数字",
                        trigger: "blur",
                    },
                    // { min: 6, max: 6, message: '长度为6个字符', trigger: 'blur'}
                ],
            },
        };
    },
    components: {
        // centerIndex
    },
    methods: {
        // 获取省信息
        get_pro_mes: function () {
            this.$api.usercenter.getAreaNextList({}).then((res) => {
                if (res.success) {
                    this.form.province = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },

        // 获取市信息
        get_city_mes: function (parentId) {
            var json = {
                code: parentId,
            };
            this.$api.usercenter.getAreaNextList(json).then((res) => {
                if (res.success) {
                    this.form.city = res.data;
                    if (res.data.length == 0) {
                        this.rules.check_city.required = false
                    } else {
                        this.rules.check_city.required = true
                    }
                } else {
                    this.$message.error(res.msg);
                }
            });
        },

        // 获取区县信息
        get_county_mes: function (parentId) {
            var json = {
                code: parentId,
            };
            this.$api.usercenter.getAreaNextList(json).then((res) => {
                if (res.success) {
                    this.form.county = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },

        // 请选择省
        onSelectedProvince: function (item) {
            this.form.check_province = item;
            this.form.check_city = "";
            this.form.check_county = "";
            this.get_city_mes(item);
        },

        // 请选择市
        onSelectedCity: function (item) {
            this.form.regionId = item;
            this.form.check_city = item;
            this.form.check_county = "";
            this.get_county_mes(item);
        },

        // 请选择区/县
        onSelectedCounty: function (item) {
            this.form.regionId = item;
            this.form.check_county = item;
        },

        // 提交新增/修改收货地址
        submitAddress: function () {
            this.$refs["form"].validate((valid) => {
                if (valid) {
                    if (this.isAddOrmodify == "add") {
                        // 新增
                        var json = this.form;
                        json.isDefault = json.isDefault.length > 0 ? 1 : 0;

                        let postData = {
                            userName: json.userName, //联系人
                            provinceId: json.check_province, //省id
                            cityId: json.check_city, //市id
                            countyId: json.check_county, //县id
                            addrName: json.addrName, //具体地址
                            phone: json.phone, //联系方式
                            yzcode: json.yzcode, //邮编
                            isDefault: json.isDefault, //是否默认 0：否 1：是
                        };

                        this.$api.usercenter
                            .getAdressAdd(postData)
                            .then((res) => {
                                if (res.success) {
                                    this.$message.success("地址新增成功");
                                    this.addAddress = false;
                                    this.receipt_address();
                                    this.$refs.form.resetFields();
                                } else {
                                    this.$message.error(res.msg);
                                }
                            });
                    } else {
                        // 修改
                        var json = {
                            userName: this.form.userName,
                            regionId: this.form.regionId,
                            addrName: this.form.addrName,
                            provinceId: this.form.check_province, //省id
                            cityId: this.form.check_city, //市id
                            countyId: this.form.check_county, //县id
                            phone: this.form.phone,
                            yzcode: this.form.yzcode,
                            isDefault: this.form.isDefault.length > 0 ? 1 : 0,
                            id: this.receiverId,
                        };

                        this.$api.usercenter
                            .getAdressModify(json)
                            .then((res) => {
                                if (res.success) {
                                    this.$message.success("修改成功");
                                    this.addAddress = false;
                                    this.receipt_address();
                                    this.$refs["form"].resetFields();
                                } else {
                                    this.$message.error(res.msg);
                                }
                            });
                    }
                }
            });
        },

        // 获取收货地址
        receipt_address: function () {
            // { Authorization: jsCookie.get("token") }
            this.$api.usercenter
                .getAdressList({
                    current: 1,
                    size: 10,
                })
                .then((res) => {
                    if (res.success) {
                        this.totalAdress = res.data.total;
                        if (res.data.records.length > 0) {
                            this.tableData = res.data.records;
                            this.tableData.forEach((ele) => {
                                ele.addrName =
                                    ele.province +
                                    ele.city +
                                    ele.county +
                                    ele.addrName;
                                ele.isDefault =
                                    ele.isDefault == 0 ? "否" : "是";
                            });
                        } else {
                            this.tableData = [];
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    let response = err.response;
                    if (response.status == 401) {
                        this.$message.error("登陆失效，请重新登录");
                        setTimeout(() => {

                            this.$router.replace({
                                path:"/login",
                                query:{
                                    url:"/userCenter/addressManage"
                                }
                            });
                        }, 1500);
                    }
                });
        },

        // 重置表单
        // resetForm(form){
        //     this.$refs[form].resetFields()
        // },

        // 新增地址
        AddressAdd: function () {
            this.isAddOrmodify = "add";

            // this.form.consignee = ''       // 收件人
            // this.form.check_province= ''  // 选中省
            // this.form.check_city = '' // 选中市
            // this.form.address = ''  // 详细地址
            // this.form.phone = ''    // 电话
            // this.form.zipcode = ''  // 邮编
            // this.form.city = []

            this.addAddress = true;
        },

        // 修改地址
        modifyAddress: function (row) {
            this.isAddOrmodify = "modify";
            this.receiverId = row.id;
            this.addAddress = true;
            var json = {
                id: this.receiverId,
            };
            this.$api.usercenter.getOneAdress(json).then((res) => {
                if (res.success) {
                    this.get_city_mes(res.data.provinceId);
                    this.get_county_mes(res.data.cityId);
                    this.form.userName = res.data.userName; // 收件人
                    this.form.check_province = res.data.provinceId.toString(); // 选中省
                    this.form.check_city = res.data.cityId.toString(); // 选中市
                    this.form.check_county = res.data.countyId.toString(); // 选中县区
                    this.form.addrName = res.data.addrName; // 详细地址
                    this.form.phone = res.data.phone; // 电话
                    this.form.yzcode = res.data.yzcode; // 邮编
                    this.form.isDefault =
                        res.data.isDefault == "0" ? [] : ["设为默认地址"]; // 是否默认地址
                } else {
                    this.$message.error(res.msg);
                }
            });
        },

        // 删除地址
        deleteAddress(row) {
            var json = {
                ids: row.id,
            };
            this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.$api.usercenter.getAdressDel(json).then((res) => {
                        if (res.success) {
                            this.$message.success("删除成功");
                            this.receipt_address();
                        } else {
                            this.$message.error(res.msg);
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                });
        },
    },
    mounted() {
        this.receipt_address();
        this.get_pro_mes();
    },
};
</script>

<style lang="scss" scoped>
.address {

    // width: 1200px;
    // margin: 0 auto;
    .right {

        // width: 926px;
        // padding: 30px;
        // min-height: 600px;
        // float: right;
        // box-shadow: 0px 0px 10px 0px rgba(140,140,140,0.21);
        p {
            color: #000000;
            margin-bottom: 15px;
        }

        .el-table_1_column_5 {
            color: #ff6a00;
        }

        h6 {
            width: 200px;
            font-size: 14px;
            font-weight: normal;
            margin-top: 20px;
            color: #8a8a8a;

            span {
                color: #256fc8;
                cursor: pointer;
            }
        }
    }

    .el-dialog__headerbtn {
        z-index: 100000;
    }

}

.address_es {
    /deep/ .el-button--primary {
        background-color: #0b5ffd;
        color: #ffffff;
        border: 1px solid #0b5ffd;
    }
    
    .dialog-footer {
        position: absolute;
        bottom: 30px;
        right: 30px;
    }

    .el-button:focus,
    .el-button:hover {
        color: #0b5ffd;
        border-color: #0b5ffd;
        background-color: #e8f0f0;
    }
}
</style>
