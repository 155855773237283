import axios from '../http';
import {an,meng,api} from '../global'

export const getBidList = (params) => {
    return axios({
        url: api+ '/czgyy-tenderee/tenderee/web/data/selectTendereePurchaseList',
        method: 'get',
        params
    })
}

// 查询招标分类列表 
export const getBidTypeList = (params) => {
    return axios({
        url: api+ '/czgyy-tenderee/tenderee/web/data/selectTendereeTypeList',
        method: 'get',
        params
    })
}


// 查询招标详情
export const getBidDetail = (params) => {
    return axios({
        url: api+ '/czgyy-tenderee/tenderee/web/data/selectTendereePurchaseById',
        // url:an+ '/czgyy-tenderee/tenderee/web/data/selectTendereePurchaseById',
        method: 'get',
        params
    })
}


// 我要报名
export const meSignUp = (params) => {
    return axios({
        url: api+ '/czgyy-tenderee/apitenderee/enlist',
        method: 'get',
        params
    })
}

// 立即报名
export const immediatelySignUp = (params) => {
    return axios({
        url: api+ '/czgyy-tenderee/apitenderee/enlist_send',
        method: 'get',
        params
    })
}


// 投标和下载
export const enlist_upload_down = (params) => {
    return axios({
        url: api+ '/czgyy-tenderee/apitenderee/enlist_upload_down',
        method: 'get',
        params
    })
}


// 投标和下载
export const enlist_upload_delete = (params) => {
    return axios({
        url: api+ '/czgyy-tenderee/apitenderee/enlist_upload_delete',
        method: 'post',
        params
    })
}