<template>
  <div class="home">
<!--    <NavBar />-->
  </div>
</template>

<script>
// @ is an alias to /src
// import NavBar from '@/components/navBar.vue'
export default {
  name: 'Home',
  components: {
    // NavBar
  }
}
</script>
