<template>
    <div class="software-market">
        <head-top></head-top>
        <div class="software-nav">
            <div class="software-left">
                <div class="navlogo">
                    <router-link :to="{path: '/'}" target="_blank"><img src="../assets/image/logo1.png" alt=""></router-link>
                    <strong>企业应用市场</strong>
                </div>
                <div class="header-list">
                    <ul>
                        <li>
                            <a href="" >应用市场</a>
                        </li>
                        <li>
                            <!-- <a href="">解决方案</a> -->
                            <router-link :to="{path: '/solutionList'}" >解决方案</router-link>
                        </li>
                        <li>
                            <a href="" >快速开发</a>
                        </li>
                        <li>
                            <a href="" >物联网平台</a>
                        </li>
                        <li>
                            <a href="" >企业级互联网平台搭建</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="nav-button">
                <div class="search">
                    <input type="text" placeholder="搜产品/搜案例/搜解决方案">
                    <input type="submit" value="搜索">
                </div>
               <template v-if="!isCookie">
                    <div class="login">
                        <router-link :to="{path: '/login'}" class="login">登录</router-link>
                        <router-link :to="{path: '/register'}" class="regin">注册</router-link>
                    </div>
                </template>
                <template v-else>
                    <div class="is-login">
                        <img src="" alt="">
                        <div>
                        <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect" :router="true">
                            <el-submenu index="/userCenter/control">
                                <template slot="title">个人中心</template>
                                <el-menu-item @click="outLogin">退出登录</el-menu-item>
                                <el-menu-item index="/userCenter/control">应用管理</el-menu-item>
                                <el-menu-item index="/userCenter/myBid">供需管理</el-menu-item>
                                <el-menu-item index="/userCenter/myMessage">个人管理</el-menu-item>
                            </el-submenu>
                        </el-menu>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
var self;
import headTop from '@/components/head-top'
export default {
    data() {
        return{
            isCookie: false
        }
    },
    components: {
        headTop
    },
    mounted() {
        self = this
        this.isCookie = this.$cookie.get('token') ? true: false
    },
    methods: {
        handleSelect(key, keyPath) {
            // console.log(key, keyPath);
        },
        // 退出登录
        outLogin() {
            var json = {
                Authorization: this.$cookie.get('token')
            }
            this.$api.login.outLogin({json}).then(res => {
                if(res.success == "true"){
                    this.$cookie.remove('token')
                    this.$nextTick(function(){
                        this.isCookie = this.$cookie.get('token')
                    })
                }else{
                    this.$message.error(res.msg)
                }
            })
        }
    }
}
</script>

<style lang="scss">
.is-login{
  .el-submenu__title{
    padding: 0;
  }
  .el-menu--horizontal>.el-submenu.is-active .el-submenu__title{
    border: 0 none;
  }
}
</style>

<style lang="scss" scoped>
.software-market{
    width: 100%;
    min-width: 1288px;
    .software-nav{
        height: 65px;
        display: flex;
        padding-left: 30px;
        align-items: center;
        justify-content: space-between;
        @media screen and (min-width: 1575px) {
            padding-right: 60px;
        }
        @media (max-width: 1574px) and (min-width: 1288px) {
            padding-right: 30px;
        }
        .software-left{
            display: flex;
            align-items: center;
            .navlogo{
                a{
                    img{
                        width: 155px;
                        height: 48px;
                        margin-right: 5px;
                    }
                }
                strong{
                    height: 28px;
                    margin-top: 10px;
                    display: inline-block;
                    color: #0B1D30;
                    padding-left: 9px;
                    font-size: 18px;
                    border-left: 1px solid #A2A2A2;
                }
            }
            .header-list{
                @media screen and (min-width: 1575px) {
                    margin-left: 44px;
                }
                @media (max-width: 1574px) and (min-width: 1288px) {
                    margin-left: 17px;
                }
                ul{
                    display: flex;
                    li{
                        @media screen and (min-width: 1575px) {
                            padding: 0 22px;
                        }
                        @media (max-width: 1574px) and (min-width: 1288px) {
                            padding: 0 10px;
                        }
                        a{
                            font-size: 14px;
                            color: #0B1D30;
                        }
                    }
                }
            }
        }
        .nav-button{
            display: flex;
            .search{
                height: 35px;
                margin-right: 20px;
                input:nth-child(1){
                    border: 1px solid #006fff;
                    line-height: 35px;
                    padding: 0 12px;
                    @media screen and (min-width: 1575px) {
                        width: 224px;
                    }
                    @media (max-width: 1574px) and (min-width: 1288px) {
                        width: 154px;
                    }
                }
                input:nth-child(2){
                    width: 70px;
                    background-color: #006fff;
                    color: #ffffff;
                    text-align: center;
                    line-height: 35px;
                    cursor: pointer;
                }
            }
            .login{
                @media screen and (min-width: 1575px) {
                    margin-left: 50px;
                }
                @media (max-width: 1574px) and (min-width: 1288px) {
                    margin-left: 0;
                }
                a{
                    width: 70px;
                    line-height: 35px;
                    display: inline-block;
                    margin-left: 20px;
                    text-align: center;
                    font-size: 14px;
                }
                a:nth-child(1){
                    border: 1px solid #006fff;
                    color: #006fff;
                }
                a:nth-child(2){
                    background-color: #006fff;
                    color: #ffffff;
                }
            }
            .is-login{
                display: flex;
                width: 100px;
                img{
                width: 40px;
                height: 40px;
                }
            }
        }
    }
}
</style>