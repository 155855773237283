<template>
    <div class="login">
        <div class="login-center" :class="showComLoginInt?'h565px' : 'h521px'">
            <el-tabs v-model="activeName" @tab-click="handleClick">
                <el-tab-pane label="账号密码登录" name="first">
                    <div class="account">
                        <div class="flex">
                            <p class="big-size">账号密码登录</p>
                            <router-link :to="{path: '/register'}">注册账号</router-link>
                        </div>
                        <el-form ref="accountForm" :model="accountForm" :rules="account_form">
                            <el-form-item prop="tenantId" v-if="showComLoginInt">
                                <el-input v-model="accountForm.tenantId" placeholder="请输入租户ID"></el-input>
                            </el-form-item>
                            <el-form-item prop="phone">
                                <el-input v-model="accountForm.phone" :placeholder="showComLoginInt?'请输入账号':'请输入手机号'"></el-input>
                            </el-form-item>

                            <el-form-item prop="pwd">
                                <el-input
                                    type="password"
                                    v-model="accountForm.pwd"
                                    placeholder="请输入密码"
                                ></el-input>
                            </el-form-item>

                            <el-form-item prop="code">
                                <el-input
                                    class="code"
                                    v-model="accountForm.code"
                                    placeholder="请输入验证码"
                                ></el-input>
                                <img :src="photoImg" alt @click="getPhotoCode" />
                            </el-form-item>
                            <div class="flex">
                                <router-link :to="{path: '/forgetPwd'}">忘记密码?</router-link>
                                <div class="com-login-btn" v-if="!showComLoginInt" @click="showComLoginInt = true">
                                    企业登录
                                </div>
                                <div class="com-login-btn" v-else @click="showComLoginInt = false">
                                    用户登录
                                </div>
                            </div>
                            <el-form-item>
                                <el-button type="primary" @click="accountSubmit('accountForm')">登录</el-button>
                                <!-- v-loading.fullscreen.lock="fullscreenLoading" -->
                            </el-form-item>
                        </el-form>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="手机验证码登录" name="second">
                    <div class="phone">
                        <div class="flex">
                            <p class="big-size">手机验证码登录</p>
                            <router-link :to="{path: '/register'}">注册账号</router-link>
                        </div>
                        <el-form ref="phoneForm" :model="phoneForm" :rules="phone_form">
                            <el-form-item prop="phone">
                                <el-input v-model="phoneForm.phone" placeholder="请输入手机号"></el-input>
                            </el-form-item>

                            <el-form-item prop="code_img">
                                <el-input
                                    class="code"
                                    v-model="phoneForm.code_img"
                                    placeholder="人机验证"
                                ></el-input>
                                <img :src="photoImg" alt @click="getPhotoCode" />
                            </el-form-item>

                            <el-form-item prop="code_phone">
                                <el-input
                                    class="code"
                                    v-model="phoneForm.code_phone"
                                    placeholder="请输入验证码"
                                ></el-input>
                                <el-button class="phone-code" :class="disabled?'disabledClass':''" :disabled="disabled" type="primary" @click="sendCode(phoneForm.phone)">{{text>0? text + 's': text}}</el-button>
                                
                            </el-form-item>
                            <div class="flex">
                                <router-link :to="{path: '/forgetPwd'}">忘记密码?</router-link>
                            </div>
                            <el-form-item>
                                <el-button
                                    type="primary"
                                    @click="phoneSubmit('phoneForm')"
                                    v-loading.fullscreen.lock="fullscreenLoading"
                                >登录</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-tab-pane>
            </el-tabs>
        </div>
        <!-- <span
                                    class="phone-code"
                                    @click="sendCode(phoneForm.phone)"
                                >{{text=='发送验证码'? '发送验证码': text}}</span> -->
    </div>
</template>

<script>
import md5 from "js-md5";
export default {
    data() {
        var checkphone = (rule, value, callback) => {
            if(!this.showComLoginInt){
                if (value == "") {
                    callback(new Error("请输入手机号"));
                } else if (!this.common.isCellPhone(value)) {
                    callback(new Error("请输入正确的手机号！"));
                } else {
                    callback();
                }
            }else{
                if (value == "") {
                    callback(new Error("请输入账号"));
                }else {
                    callback();
                }
            }
           
        };
        return {
            redirect_uri:"",//重定向地址
            fullscreenLoading: false,
            text: "发送验证码",
            timeCount: 60,
            activeName: "first",
            photoImg: "",
            uuid: "",
            showComLoginInt:false,
            disabled:false,
            accountForm: {
                phone: "",
                pwd: "",
                code: "",
                tenantId:""
            },
            phoneForm: {
                phone: "",
                code_img: "",
                code_phone: "",
            },
            account_form: {
                phone: {
                    required: true,
                    validator: checkphone,
                    trigger: "blur",
                },
                pwd: { required: true, message: "请输入密码", trigger: "blur" },
                code: {
                    required: true,
                    message: "请输入验证码",
                    trigger: "blur",
                },
            },
            phone_form: {
                phone: {
                    required: true,
                    validator: checkphone,
                    trigger: "blur",
                },
                code_img: {
                    required: true,
                    message: "请输入人机验证码",
                    trigger: "blur",
                },
                code_phone: {
                    required: true,
                    message: "请输入手机验证码",
                    trigger: "blur",
                },
            },
        };
    },
    mounted() {
        this.getPhotoCode();
        if(this.$route.query.redirect_uri){
            this.redirect_uri = decodeURIComponent(this.$route.query.redirect_uri)
        }
    },
    inject: ["reload"],
    methods: {
        handleClick(tab, event) {
            // console.log(tab, event);
        },
        // 倒计时
        timeCountdown() {
            const TIME_COUNT = 60;
            this.disabled = true
            if (!this.timer) {
                this.text = TIME_COUNT;
                this.timer = setInterval(() => {
                    if (this.text > 1 && this.text <= TIME_COUNT) {
                        this.text--;
                    } else {
                        clearInterval(this.timer);
                        this.timer = null;
                        this.text = "重新发送"
                        this.disabled = false
                    }
                }, 1000)
            }
        },

        // 获取验证图片
        getPhotoCode() {
            this.$api.login.getPhotoCode({}).then((res) => {
                this.photoImg = res.image;
                this.uuid = res.key;
            });
        },
        // 发送手机验证码
        sendCode(phoneid) {
            // if (this.timeCount > 0 && this.timeCount < 60) {
            //     return false;
            // }
            // this.timeCount--
            if (phoneid) {
                var json = { phone: phoneid };
                this.$api.login.sendPhoneCode(json).then((res) => {
                    if (res.code == 200) {
                        this.timeCountdown()
                        this.$message.success("已发送");
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.$message.warning("请输入手机号");
            }
        },
        // 账号登录
        accountSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    this.fullscreenLoading = true;
                    var json = {
                        grant_type: "captcha",
                        username: this.accountForm.phone,
                        password: md5(this.accountForm.pwd),
                        scope: "all",
                        type: "account",
                    };
                    let headers = {
                        "Captcha-Key": this.uuid,
                        "Captcha-Code": this.accountForm.code,
                        "Content-Type": "application/x-www-form-urlencoded",
                    };

                    headers.tenantId = this.accountForm.tenantId
                    console.log("headers=>",headers);
                    this.$api.login
                        .login(json, headers)
                        .then((res) => {
                            if (res.access_token) {
                                var tokenOver = new Date(
                                    new Date().getTime() + 12 * 60 * 60 * 1000
                                );
                                this.$cookie.set(
                                    "token",
                                    res.token_type + " " + res.access_token,
                                    { expires: tokenOver }
                                );
                                this.$cookie.set(
                                    "refresh_token",
                                    res.refresh_token
                                );
                                this.$cookie.set("user_id", res.user_id, {
                                    expires: tokenOver,
                                });
                                this.fullscreenLoading = false;

                                this.reload();
                                // this.$router.go(-1);
                                if(this.redirect_uri != ""){
                                    this.$router.push({
                                        path: this.redirect_uri,
                                    });
                                }else{
                                     this.$router.push({
                                        path: "/",
                                    });
                                }
                               
                            } else {
                                this.$message.error(res.msg);
                                this.fullscreenLoading = false;
                            }
                        })
                        .catch((err) => {
                            console.log(err.response);
                            let response = err.response;
                            if (response.status == 400) {
                                this.getPhotoCode()
                                this.$message.error(
                                    response.data.error_description
                                );
                            }

                            this.fullscreenLoading = false;
                        });
                } else {
                    return false;
                }
            });
        },
        
        // 手机验证登录
        phoneSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    this.fullscreenLoading = true;
                    var json = {
                        scope: "all",
                        username: this.phoneForm.phone,
                        password: this.phoneForm.code_phone,
                        grant_type: "phone_captcha",
                    };
                    let headers = {
                        "Captcha-Key": this.uuid,
                        "Captcha-Code": this.phoneForm.code_img,
                        "Content-Type": "application/x-www-form-urlencoded",
                    };
                    this.$api.login
                        .login(json, headers)
                        .then((res) => {
                            if (res.access_token) {
                                this.$message.success("登陆成功");
                                var tokenOver = new Date(
                                    new Date().getTime() + 12 * 60 * 60 * 1000
                                );
                                this.$cookie.set(
                                    "token",
                                    res.token_type + " " + res.access_token,
                                    { expires: tokenOver }
                                );
                                this.$cookie.set(
                                    "refresh_token",
                                    res.refresh_token
                                );
                                this.$cookie.set("user_id", res.user_id, {
                                    expires: tokenOver,
                                });
                                this.fullscreenLoading = false;
                                this.$router.push({
                                    path: "/",
                                });
                                this.reload();
                            } else {
                                this.$message.error(res.msg);
                                this.fullscreenLoading = false;
                            }
                        })
                        .catch((err) => {
                            let response = err.response;
                            this.fullscreenLoading = false;
                            if (response.status == 400) {
                                this.getPhotoCode()
                                if (response.data.error_description) {
                                    this.$message.error(
                                        response.data.error_description
                                    );
                                    return;
                                }
                                if (response.data.msg) {
                                    this.$message.error(response.data.msg);
                                    return;
                                }
                            }
                        });
                } else {
                    return false;
                }
            });
        },
    },
};
</script>

<style lang="scss">
.login {
    .el-tabs__item.is-active {
        color: #1848fd;
    }
    .el-tabs__active-bar {
        background-color: #1848fd;
    }
    .el-tabs__nav {
        width: 100%;
        height: 65px;
        line-height: 66px;
        border-bottom: 1px solid #eeeeee;
        .el-tabs__item {
            width: 50%;
            padding: 0;
        }
    }
    .el-form-item {
        margin-bottom: 25px;
    }
    .el-input__inner {
        width: 100% !important;
        height: 40px !important;
    }
    .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
    .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
        text-align: center;
        padding: 0 !important;
    }
    .el-tabs--bottom .el-tabs__item.is-bottom:last-child,
    .el-tabs--bottom .el-tabs__item.is-top:last-child,
    .el-tabs--top .el-tabs__item.is-bottom:last-child,
    .el-tabs--top .el-tabs__item.is-top:last-child {
        text-align: center;
        padding: 0 !important;
    }
    .el-checkbox__inner {
        margin-right: 4px;
        border: 2px solid #757f92;
        border-radius: 50%;
        width: 14px;
        height: 14px;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #1848fd;
        border-color: #1848fd;
    }
    .el-form-item__content {
        width: 100%;
        .el-button--primary {
            width: 100%;
            background-color: #1848fd;
            color: #ffffff;
        }
    }
}
</style>

<style lang="scss" scoped>
.com-login-btn{
    color: #1848fd;
    cursor: pointer;
}
.login {
    background-color: #cccccc;
    height: 100%;
    position: relative;
    background: url("../../assets/image/login_bg.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    .login-center {
        width: 460px;
        height: 521px;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        right: 300px;
        margin-top: -260px;
        .account,
        .phone {
            padding: 30px 48px;
            .flex {
                display: flex;
                justify-content: space-between;
                margin-bottom: 40px;
                font-size: 14px;
                a {
                    color: #1848fd;
                }
                .big-size {
                    font-size: 16px;
                }
            }
            .code {
                width: 245px;
                margin-right: 10px;
            }
            img {
                width: 106px;
                height: 40px;
            }
            .phone-code {
                display: inline-block;
                width: 106px;
                height: 40px;
                color: #ffffff;
                background-color: #1848fd;
                text-align: center;
                cursor: pointer;
            }
            .disabledClass{
                background: #a0cfff;
            }
        }
    }
    .h521px{
        height: 521px;

    }
    .h565px{
        height: 565px;

    }
}
</style>