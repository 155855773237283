<template>
    <div id="app app_wrapper" :style="wrapperStyle">
        <div v-if="!onlyView" id="app_home" :style="homeStyle">
            <new-guidelines v-if="showNewGuidelines" @hideNewGuide="hideNewGuideFn"></new-guidelines>
            <div class="container" :class="showNewGuidelines ? 'filter' : ''">
                <!-- <div class="adv topAdv" v-if="showTopAdv && (this.$route.path == '/' || this.$route.path == '/newhome')"
                    :style="{ backgroundImage: 'url(' + topAdv.src + ')' }">
                    <span class="closeAdv el-icon-close" @click="closeAdv"></span>
                </div>
                <div class v-show="navFixed" style="height:100px;"></div> -->

                <home-nav
                    v-show="this.$route.path != '/finance' && this.$route.path != '/login' && this.$route.path != '/register' && this.$route.path != '/forgetPwd'"
                    :key="new Date().getTime()"></home-nav>

                <!-- <router-link to="/">home</router-link>  -->
                <!-- <router-link to="/about">About</router-link> -->
                <router-view v-if="isRouterAlive"></router-view>

                <template
                    v-if="this.$route.path != '/login' && this.$route.path != '/register' && this.$route.path != '/trial' && this.$route.path != '/trialSucces'">
                    <new-Foot></new-Foot>
                </template>

                <service-box></service-box>
            </div>
        </div>
	    <template v-else>
		    <router-view v-if="isRouterAlive"></router-view>
	    </template>
    </div>
</template>

<script>
import serviceBox from "@/components/serviceBox";
import newGuidelines from "@/components/new-guidelines";
// import foot from '@/components/foot'
import newFoot from "@/components/newFoot";
import HomeNav from "@/components/home-nav";
// import func from 'vue-editor-bridge';
export default {
    provide() {
        return {
            reload: this.reload,
        };
    },
    data() {
        return {
            showTopAdv: true,
            topAdv: {
                src: require("./assets/image/topadv_02.jpg"),
                link: "",
            },
            navFixed: false,
            scrollTop: 0,
            isRouterAlive: true,
            isShowNewGuide: true,

            wrapperStyle: "",
            homeStyle: "",

	        onlyView:this.$route.query.onlyView
        };
    },
    components: {
        newFoot,
        HomeNav,
        serviceBox,
        newGuidelines,
    },
    computed: {
        showNewGuidelines() {
            if (!this.isShowNewGuide) {
                return false;
            }
            if (this.$route.path == "/" || this.$route.path == "/newHome") {
                return true;
            }
            if (this.$route.path == "/SoftwareMarket/details") {
                return true;
            }
            return false;
        },
    },
    mounted() {


        let _this = this;
        console.log(window.screen)
        // let height = window.screen.height;
        // let width = window.screen.width;
        let height = document.body.clientHeight;
        let width = document.body.clientWidth;
        _this.wrapperStyle = {
            width: width + "px",
            height: height + "px",
        };
        console.log(width)
        console.log(width / 1920)
        _this.homeStyle = {
            transform: "scale(" + width / 1920 + "," + width / 1920 + ")",
            // transform: "scale(0.6, 0.6)",
        };

        let isShowNewGuide = localStorage.getItem("isShowNewGuide");

        if (isShowNewGuide != null) {
            if (isShowNewGuide == "false") {
                this.isShowNewGuide = false;
            } else {
                this.isShowNewGuide = true;
            }
        }
        $(".topAdv").animate({ height: "100px" }, 800);
        // window.addEventListener("scroll", this.handleScroll, true);

        // var _script = document.createElement('script')
        // _script.setAttribute('charset', 'utf-8')
        // _script.setAttribute('type', 'text/javascript')
        // _script.setAttribute('src', 'https://pv.sohu.com/cityjson?ie=utf-8')
        // document.getElementsByTagName('head')[0].appendChild(_script)
        // _script.onload = function() {
        //     console.log(returnCitySN['cip'])
        // }
    },
    methods: {
        // 隐藏弹出层
        hideNewGuideFn(e) {
            this.isShowNewGuide = false;
            localStorage.setItem("isShowNewGuide", false);
        },
        closeAdv() {
            let _this = this;
            $(".closeAdv").hide();
            $(".topAdv").animate({ height: "0" }, 800, function () {
                _this.showTopAdv = false;
            });
        },
        reload: function () {
            this.isRouterAlive = false;
            this.$nextTick(function () {
                this.isRouterAlive = true;
            });
        },
        // handleScroll: function (e) {
        //     let _this = this;
        //     _this.scrollTop = document.documentElement.scrollTop;
        //     _this.getBottomOfWindow();
        // },
        // getBottomOfWindow: function () {
        //     var winHeight = window.innerHeight;
        //     if (this.scrollTop > 62) {
        //         this.navFixed = true;
        //         $(".home-nav").addClass("nav-shadow")
        //     } else {
        //         this.navFixed = false;
        //         $(".home-nav").removeClass("nav-shadow")
        //     }
        // },
    },
};
</script>

<style lang="scss" scoped>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* text-align: center; */
    color: #2c3e50;
    height: 100%;

    i {
        font-style: normal;
    }
}

#app_home {
    width: 1920px;
    height: 1080px;
    transform-origin: 0 0;
    position: relative;
}

.filter {
    filter: blur(6px);
}

.topAdv {
    width: 100%;
    // height: 62px;
    height: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    // background-size: cover;
    cursor: pointer;
    position: relative;

    z-index: 99;

    .closeAdv {
        position: absolute;
        top: 32px;
        width: 35px;
        height: 35px;
        right: 20px;
        text-align: center;
        line-height: 35px;
        font-size: 24px;
        color: #fff;
    }
}

.container {
    width: 100%;
    height: 100%;
}

.nav-shadow {
    box-shadow: 0 0 20px rgba(8, 46, 174, 0.15);
}
</style>
