<template>
    <div class="order-confirm">
        <div class="nav"></div>
        <div v-html="alipayWap" ref="alipayWap"></div>
        <div class="order-box">
            <div class="step">
                <h3 class="title"><span> </span>收银台</h3>
                <!-- <el-steps :active="2">
                    <el-step title="确认订单" icon="el-icon-edit"></el-step>
                    <el-step title="支付方式" icon="el-icon-upload"></el-step>
                    <el-step title="支付成功" icon="el-icon-picture"></el-step>
                </el-steps> -->
                <p style="text-align:center;"><img :src="require('../../assets/sub_order.png')" alt=""></p>
            </div>
            <div class="step">
                <h3 class="title"><span> </span>我的订单</h3>
                <div class="table">
                    <el-table :data="tableData" :border="true" style="width: 100%">
                        <el-table-column prop="order.ordering" label="订单号" width="282">
                        </el-table-column>
                        <el-table-column prop="pro.name" label="产品名称" width="208">
                        </el-table-column>
                        <el-table-column prop="pro.specs.spec_product_value" label="已选配置" width="412">
                            <template slot-scope="scope">
                                <!-- <p>{{scope.row}}</p> -->
                                {{ scope.row.pro.specs.spec_product_value }}
                                <el-button type="text" @click="dialogVisible = true">查看详情</el-button>
                            </template>
                        </el-table-column>
                        <el-table-column prop="order.payable_price" label="资费">
                        </el-table-column>
                    </el-table>
                </div>
            </div>

            <div class="step">
                <h3 class="title"><span> </span>支付方式</h3>
                <div class="pay-mode">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="支付宝" name="first">
                            <div class="zfb">
                                <el-radio-group v-model="form.resource">
                                    <el-radio label="ALIPAY_PC">支付宝支付通</el-radio>
                                </el-radio-group>
                                <img :src="require('../../assets/zhifubao.png')" alt="">
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="price">
                    <span>应付金额：</span><b>{{ tableData[0].order.payable_price }}</b>
                    <el-button type="primary" @click="onSubmit">支付</el-button>
                </div>
            </div>
        </div>

        <el-dialog :visible.sync="dialogVisible" width="30%" :before-close="handleClose" :append-to-body="true">
            <div class="alert-box">
                <div class="introduce">
                    <h3 class="title"><span> </span>产品名称</h3>
                    <p>{{ tableData[0].pro.name }}</p>
                </div>
                <div class="introduce">
                    <h3 class="title"><span> </span>订单号</h3>
                    <ul>
                        <li>{{ tableData[0].order.ordering }}</li>
                    </ul>
                </div>
                <div class="introduce">
                    <h3 class="title"><span> </span>配置</h3>
                    <ul>
                        <li>{{ tableData[0].pro.specs.spec_product_value }}</li>
                    </ul>
                </div>
                <div class="introduce">
                    <h3 class="title"><span> </span>价格</h3>
                    <p>总额：（<span>{{ tableData[0].order.payable_price }}</span>）</p>
                </div>
            </div>
        </el-dialog>
        <!-- <form name="punchout_form" method="post"
            action="https://openapi.alipay.com/gateway.do?charset=utf-8&method=alipay.trade.page.pay&sign=blk5e9t1fc7mTQEJqRum34fydKWbOFSqbU8YJh5HhO4E%2BvjHDhAjJsij7Q61jmOReXbKxS%2FOnFLZx0ewONzgDHWjER6bsmf5m9RmTFcQhCaYSTbO6gS5K7f3SIlJ%2FQmaHj%2BEV2za0Lokz6BhT1mSUosLSGlCQQz15VVk9N%2FRUdymBUxnoA0hJAryX1b5U0sONJR7vZ8wRpzmd8JMN2NUOSQntY%2BihHNrnbOgVc2ugFYESMjezGvjZ8jvrI9Y7ygZDTRLJtsdcLZZ%2BEZ4DAu9WZw0%2F%2BzKPOUmf6CP9p%2Bwe2DgP48pg3XlUWOGWOxVTAZWTKNUMUBlGq8oHAnPmPjw5g%3D%3D&return_url=https%3A%2F%2Fwww.baidu.com%2F&notify_url=http%3A%2F%2F192.168.10.196%3A83%2Fczgyy-product%2Fpay%2FpayCallback&version=1.0&app_id=2018102961901218&sign_type=RSA2&timestamp=2023-04-28+10%3A46%3A15&alipay_sdk=alipay-sdk-java-dynamicVersionNo&format=json">
            <input type="hidden" name="biz_content"
                value="{&quot;out_trade_no&quot;:&quot;97afac2d1cd7438c838a0a9384288436&quot;,&quot;total_amount&quot;:&quot;0.01&quot;,&quot;subject&quot;:&quot;测试支付宝PC支付&quot;,&quot;body&quot;:&quot;我是最棒的商品,下单下单&quot;,&quot;timeout_express&quot;:&quot;30m&quot;,&quot;product_code&quot;:&quot;FAST_INSTANT_TRADE_PAY&quot;}"><input
                type="submit" value="立即支付" style="display:none"></form>
        <script>
            document.forms[0].submit();
        </script> -->
    </div>
</template>

<script>
// import {baseUrl} from '../../request/http'
import Qs from 'qs'
export default {
    data() {
        return {
            dialogVisible: false,
            activeName: 'first',
            form: {
                desc: '',
                type: [],
                resource: 'ALIPAY_PC'
            },
            tableData: [],
            webUrl: 'http://tem3.0.cz-icloud.com:6888/',
            alipayWap: '',
        }
    },
    mounted() {
        this.getOrderMes()
    },
    methods: {
        handleClose(done) {
            this.$confirm('确认关闭？')
                .then(_ => {
                    done();
                })
                .catch(_ => { });
        },
        handleClick(tab, event) {
        },

        // 支付
        onSubmit() {
            var json = {
                userid: this.tableData[0].order.user_id,
                ordersId: this.$route.query.order,
                payType: this.form.resource,
                returnUrl: this.webUrl + '/softwareMarket/paySuccess',
                payable_price: this.tableData[0].order.payable_price,
            }
            this.$api.product.pay(Qs.stringify(json)).then(res => {
                console.log(res);
                console.log(res.msg);
                if (res.code == 200) {
                    this.alipayWap = res.msg;
                    this.$nextTick(() => {
                        this.$refs.alipayWap.children[0].submit()
                    })
                } else {
                    this.$message.error(res.msg)
                }
            })
        },

        // 获取订单信息
        getOrderMes() {
            var json = {
                ordering: this.$route.query.order,
            }

            this.$api.product.orderDetail(json).then(res => {
                if (res.code == 200) {
                    this.tableData.push(res.data)
                }
            })
        },
    }
}
</script>

<style lang="scss">
.order-confirm {
    .is-finish {
        color: #1F51C8;

        .el-step__icon {
            background-color: #1F51C8;
            color: #ffffff;
            border: 1px solid #1F51C8;
        }
    }

    .el-step__title.is-finish {
        color: #1F51C8 !important;
    }

    .el-step__icon {
        width: 50px !important;
        height: 50px;
        border: 1px solid #E1E1E1;
        border-radius: 50%;
        color: #E1E1E1;
        font-weight: bold;
    }

    .el-step__line {
        margin-top: 13px;
    }

    .cell {
        text-align: center !important;
    }

    .el-table th.el-table__cell {
        background-color: #F6F8FB;
    }

    .el-table .cell {
        padding: 0 25px;
    }

    .el-button--primary {
        width: 136px;
        height: 45px;
        background-color: #006FFF;
        border-color: #006FFF;
    }

    .el-tabs__nav-wrap {
        border-bottom: 1px solid #EEEEEE !important;
        margin-bottom: 25px;
    }

    .el-tabs__item.is-active {
        color: #1F51C8;
        border-color: #1F51C8;
    }

    .el-tabs--bottom .el-tabs__item.is-bottom:last-child,
    .el-tabs--bottom .el-tabs__item.is-top:last-child,
    .el-tabs--top .el-tabs__item.is-bottom:last-child,
    .el-tabs--top .el-tabs__item.is-top:last-child {
        padding-left: 36px !important;
    }

    .el-tabs__active-bar {
        width: 42px !important;
        transform: translateX(36px) !important;
        background-color: #1F51C8;
    }
}
</style>

<style lang="scss" scoped>
.order-confirm {
    .order-box {
        background-color: #F6F8FB;
        padding: 40px 0;

        .step {
            width: 1200px;
            margin: 0 auto;
            box-sizing: border-box;
            padding: 30px;
            background-color: #ffffff;
            margin-bottom: 15px;

            h3 {
                font-weight: normal;
                font-size: 14px;
                color: #0B1D30;
                padding-top: 2px;
                height: 16px;
                line-height: 16px;
                margin-bottom: 30px;

                span {
                    display: inline-block;
                    width: 3px;
                    height: 14px;
                    background-color: #1F51C8;
                    margin-right: 6px;
                }
            }

            .el-steps {
                padding: 0 36px;
            }

            .table {
                padding: 0 36px;
            }

            .ts {
                padding: 0 36px;
                font-size: 14px;

                p {
                    color: #757F92;
                    line-height: 28px;
                }
            }

            .bg {
                padding: 0 15px;
                background-color: #F6F8FB;
                height: 30px;
                line-height: 30px;
            }

            .price {
                margin-top: 40px;
                text-align: right;

                span {
                    font-size: 12px;
                    color: #757F92;
                }

                b {
                    font-size: 22px;
                    color: #FF0027;
                }

                button {
                    margin-left: 35px;
                }
            }

            .zfb {
                width: 230px;
                height: 60px;
                background-color: #ECF5FC;
                display: flex;
                box-sizing: border-box;
                padding: 0 20px;
                margin-left: 36px;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                color: #0B1D30;

                img {
                    width: 30px;
                    height: 30px;
                }
            }
        }
    }

}

.alert-box {
    .introduce:nth-child(1) {
        margin-bottom: 50px;
    }

    .introduce {
        margin-bottom: 40px;
        font-size: 14px;
        color: #0B1D30;

        h3 {
            height: 18px;
            line-height: 18px;
            font-weight: normal;
            display: flex;
            align-items: center;
            margin-bottom: 20px;

            span {
                display: inline-block;
                height: 14px;
                width: 3px;
                background-color: #1F51C8;
                margin-right: 6px;
            }
        }

        ul {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;

            li {
                margin-right: 60px;
                margin-bottom: 10px;
            }
        }
    }
}</style>