import Vue from 'vue'
import VueRouter from 'vue-router'
import newHome from '../views/newHome.vue'                  // 新首页
import Home from '../views/Home.vue'                  // 首页
import AboutUs from '../views/aboutUs/Index'          // 关于我们
import Company from '../views/aboutUs/CompanyProfile' // 公司简介
import Memorabilia from '../views/aboutUs/Memorabilia'// 大事记
import WinAward from '../views/aboutUs/WinAward'    // 荣誉资质
import joinUs from '../views/joinUs'                 // 加入我们
import contactUs from '../views/contactUs'           // 控制台联系我们

import ceshi from '../views/aboutUs/About' // 测试

import mailianZhicai from '../views/mailianZhicai'    // 迈链智采
import seeFile from '../views/mailianZhicai/seeFile'  // 查看项目详情

// 个人中心 start
import userCenter from '../views/userCenter'
import control from '../views/userCenter/control'            // 控制台
import orderCenter from '../views/userCenter/orderCenter'    // 订单中心
import orderDetail from '../views/userCenter/orderDetail'    // 订单详情
import cooperation from '../views/userCenter/cooperation'    // 协作管理
import refundOrder from '../views/userCenter/refundOrder'    // 退款订单
import myBid from '../views/userCenter/myBid'                // 我的投标
import collect from '../views/userCenter/collect'            // 供需收藏
import supplierTrue from '../views/userCenter/supplierTrue'  // 供应商认证
import myMessage from '../views/userCenter/myMessage'        // 个人信息
import addressManage from '../views/userCenter/addressManage'// 地址管理
import accountSet from '../views/userCenter/accountSet'      // 账号设置
// 个人中心 end

import login from '../views/login'                            // 登录
import register from '../views/login/register'                // 注册
import forgetPwd from '../views/login/forgetPwd'              // 忘记密码
import trial from '../views/login/trial'                      // 注册试用
import trialSucces from '../views/login/trialSucces'          // 注册试用成功页

import softwareMarket from '../views/softwareMarket'          // 软件市场
import softwareHome from '../views/softwareMarket/home'       // 软件市场首页
import details from '../views/softwareMarket/details'         // 详情页
import softwareList from '../views/softwareMarket/softwareList' // 产品家族主页  软件市场
import applicationList from '../views/softwareMarket/applicationList' //应用市场列表页
import solutionList from '../views/solution/solutionList'       // 解决方案列表
import solutionDetail from '../views/solution/solutionDetail'   // 解决方案详情
import orderConfirm from '../views/softwareMarket/orderConfirm' // 确认订单
import orderPay from '../views/softwareMarket/orderPay'         // 订单支付
import saasList from '../views/softwareMarket/saasList'         // Saas列表页
import paySuccess from '../views/softwareMarket/paySuccess'     // 支付成功页面

import caseList from '../views/case/caseList'               // 案例详情页
import caseDetail from '../views/case/caseDetail'               // 案例详情页

// 固定页 
import industInternet from '../views/fixedPage/IndustInternet' // 工业互联网
import industIot from '../views/fixedPage/industIot' // 工业物联网
import industData from '../views/fixedPage/industData' // 工业大数据中心
import enterpriseInternet from '../views/fixedPage/enterpriseInternet' // 企业级互联网平台
import identifyNode from '../views/fixedPage/identifyNode' // 表示二级节点
import identification from '../views/fixedPage/identification' // 标识页
import lightapplication from '../views/fixedPage/lightapplication' // 轻应用开发
import finance from '../views/fixedPage/finance'                   // 金融信息


import govHome from '../views/news/govHome'                     // 政府新闻中心
import govWeb from '../views/news/govWeb'                       // 政府网站地址列表
import govNewsDetails from '../views/news/govNewsDetails'       // 政府新闻详情 
import govNewsList from '../views/news/govNewsList'             // 政府新闻列表 
import companyNews from '../views/news/companyNews'             // 公司新闻中心
import companyNewsList from '../views/news/companyNewsList'     // 公司新闻列表
import companyNewsDetail from '../views/news/companyNewsDetail' // 公司新闻详情 

import industrial from '../views/industrialProcure'          // 工业供采
import rapidProcure from '../views/industrialProcure/rapidProcure' // 快速求购
import callAuction from '../views/industrialProcure/callAuction' // 集合竞价
import saasIndex from '../views/SaaS/index'          // 新首页
import saasDetail from '../views/SaaS/detail' // 新首页详情



Vue.use(VueRouter)

const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'newHome',
    component: newHome,
  },
  {
    path: '/newHome',
    name: 'newHome',
    component: newHome,
  },  {
    path: '/oldHome',
    name: 'Home',
    component: Home,
  },
  {
    path: '/register',
    name: 'Register',
    component: register,
  },
  {
    path: '/login',
    name: 'Login',
    component: login,
  },
  {
    path: '/forgetPwd',
    name: 'ForgetPwd',
    component: forgetPwd,
  },
  {
    path: '/trial',
    name: 'Trial',
    component: trial,
  },
  {
    path: '/trialSucces',
    name: 'TrialSucces',
    component: trialSucces,
  },
  {
    path: '/govHome',
    name: 'GovHome',
    component: govHome,
  },
  {
    path: '/companyNews',
    name: 'CompanyNews',
    component: companyNews,
  },
  {
    path: '/companyNewsList',
    name: 'CompanyNewsList',
    component: companyNewsList,
  },
  {
    path: '/govWeb',
    name: 'GovWeb',
    component: govWeb,
  },
  {
    path: '/govNewsList',
    name: 'GovNewsList',
    component: govNewsList,
  },
  {
    path: '/govNewsDetails',
    name: 'GovNewsDetails',
    component: govNewsDetails,
  },
  {
    path: '/companyNewsDetail',
    name: 'CompanyNewsDetail',
    component: companyNewsDetail,
  },
  {
    path: '/softwareMarket',
    name: 'SoftwareMarket',
    component: softwareMarket,
    children: [{
      path: 'softwareHome',
      name: 'SoftwareHome',
      component: softwareHome,
    }, {
      path: 'details',
      name: 'Details',
      component: details,
    }, {
      path: 'softwareList',
      name: 'SoftwareList',
      component: softwareList,
    }, {
      path: 'applicationList',
      name: 'ApplicationList',
      component: applicationList,
    }, {
      path: 'orderConfirm',
      name: 'OrderConfirm',
      component: orderConfirm,
    }, {
      path: 'orderPay',
      name: 'OrderPay',
      component: orderPay,
    }, {
      path: 'saasList',
      name: 'SaasList',
      component: saasList,
    }, {
      path: 'paySuccess',
      name: 'PaySuccess',
      component: paySuccess,
    }]
  },
  {
    path: '/solutionList',
    name: 'SolutionList',
    component: solutionList,
  },
  {
    path: '/solutionDetail',
    name: 'SolutionDetail',
    component: solutionDetail,
  },
  {
    path: '/identification',
    name: 'Identification',
    component: identification,
  },
  {
    path:'/lightapplication',
    name:'Lightapplication',
    component:lightapplication
  },
  {
    path: '/caseDetail',
    name: 'CaseDetail',
    component: caseDetail,
  },
  {
    path: '/caseList',
    name: 'CaseList',
    component: caseList,
  },
  {
    path: '/userCenter',
    name: 'UserCenter',
    component: userCenter,
    children: [{
      path: 'control',
      name: 'Control',
      component: control
    },
    {
      path: 'orderCenter',
      name: 'OrderCenter',
      component: orderCenter
    },
    {
      path: 'orderDetail',
      name: 'orderDetail',
      component: orderDetail
    },
    {
      path: 'cooperation',
      name: 'Cooperation',
      component: cooperation
    }, {
      path: 'refundOrder',
      name: 'RefundOrder',
      component: refundOrder
    }, {
      path: 'myBid',
      name: 'MyBid',
      component: myBid
    }, {
      path: 'collect',
      name: 'Collect',
      component: collect
    }, {
      path: 'supplierTrue',
      name: 'SupplierTrue',
      component: supplierTrue
    }, {
      path: 'myMessage',
      name: 'MyMessage',
      component: myMessage
    }, {
      path: 'addressManage',
      name: 'AddressManage',
      component: addressManage
    }, {
      path: 'accountSet',
      name: 'AccountSet',
      component: accountSet
    }]
  },
  {
    path: '/aboutUs',
    name: 'AboutUs',
    component: AboutUs,
    // redirect: '/aboutUs/company',
    children: [{
      path: 'ceshi',
      name: 'Ceshi',
      component: ceshi
    }, {
      path: 'company',
      name: 'Company',
      component: Company
    }, {
      path: 'memorabilia',
      name: 'Memorabilia',
      component: Memorabilia
    }, {
      path: 'winAward',
      name: 'WinAward',
      component: WinAward
    }]
  },
  {
    path: '/joinUs',
    name: 'JoinUs',
    component: joinUs,
  },
  {
    path: '/contactUs',
    name: 'ContactUs',
    component: contactUs,
  },
  {
    path: '/industrial',   // 供采
    name: 'Industrial',
    component: industrial
  },
  {
    path: '/mailianZhicai',   // 迈链智采
    name: 'MailianZhicai',
    component: mailianZhicai
  },
  {
    path: '/seeFile',   // 查看公告
    name: 'SeeFile',
    component: seeFile
  },
  {
    path: '/rapidProcure',   // 快速求购
    name: 'RapidProcure',
    component: rapidProcure,
  },
  {
    path: '/callAuction',   // 集合竞价
    name: 'CallAuction',
    component: callAuction,
  },
  {
    path: '/SaaS',
    name: 'SaaS',
    component: saasIndex,
    children: [{
      path: 'detail',
      name: 'Detail',
      component: saasDetail,
    }]
  },
  {
    path: '/industInternet',   // 互联网
    name: 'IndustInternet',
    component: industInternet,
  },
  {
    path: '/industIot',   // 物联网
    name: 'IndustIot',
    component: industIot,
  },
  {
    path: '/industData',   // 大数据
    name: 'IndustData',
    component: industData,
  },
  {
    path: '/enterpriseInternet',   // 企业级互联网
    name: 'EnterpriseInternet',
    component: enterpriseInternet,
  },
  {
    path: '/identifyNode',   // 标识二级节点
    name: 'IdentifyNode',
    component: identifyNode,
  },
  {
    path: '/finance',        // 金融信息
    name: 'Finance',
    component: finance,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 路由前置钩子(路由守卫)
router.beforeEach((to, from, next) => {
  // 路由跳转后，让页面回到顶部
  // chrome
  document.body.scrollTop = 0; // firefox
  document.documentElement.scrollTop = 0; // safari
  window.pageYOffset = 0; // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  next();
  //to 即将进入的路由
  //from 即将离开的路由
  //next 放行
});

export default router
