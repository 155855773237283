import axios from '../http';
import Cookies from 'js-cookie'
import {an,meng,api} from '../global'

// 搜索产品 meng
export const productSearch = (params) => {
    return axios({
        url: api + '/czgyy-product/product/search',
        method: 'get',
        params
    })
}

// 获取产品列表 meng
export const productList = (params) => {
    return axios({
        url: api + '/czgyy-product/product/list',
        method: 'get',
        params
    })
}
 
// 产品详情 m
export const productDetail = (params) => {
    return axios({
        url: api + '/czgyy-product/product/read',
        method: 'get',
        params
    })
}

// 获取产品分类 m
export const productClass = (params) => {
    return axios({
        url: api + '/czgyy-product/productclass/list',
        method: 'get',
        params
    })
}

// 获取行业分类 an
export const industryClass = (params) => {
    return axios({
        url: api + '/blade-solution/solutionClass/list',
        method: 'get',
        params
    })
}

// 产品推荐 m
export const proRecommend = (params) => {
    return axios({
        url: api + '/czgyy-product/product/recommended',
        method: 'get',
        params
    })
}

// 提交订单 m
export const subOrder = (data) => {
    return axios({
        url: api + '/czgyy-product/order/add',
        method: 'post',
        data
    })
}

// 获取订单详情 m
export const orderDetail = (params) => {
    return axios({
        url: api + '/czgyy-product/order/read',
        method: 'get',
        params
    })
}

// 支付 m
export const pay = (data) => {
    return axios({
        // url: api + '/czgyy-product/order/toPay',
        url:api + '/czgyy-product/Alipay/payPCOrder',
        method: 'POST',
        data
    })
}