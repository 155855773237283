<template>
    <!-- <div class="supplier-true">
    <p>供应商认证</p>
    </div>-->
    <div class="certificat clearFix">
        <!-- <center-index></center-index> -->
        <div class="right">
            <h3>
                供应商认证
                <span></span>
            </h3>
            <p class="clearFix" v-if="isexamine">
                <b>i</b>
                <span>请完善一下信息，方便我们更好的为您服务</span>
            </p>

            <div class="massage">
                <div class="base_mes">
                    <h4>基本信息</h4>
                    <el-form ref="form" :model="form" :rules="companyrules">
                        <div class="choice clearFix">
                            <el-form-item label="注册类型：" label-width="100px">
                                <el-radio-group v-model="form.mechanism">
                                    <el-radio label="2">机构</el-radio>
                                    <el-radio label="1">个人</el-radio>
                                </el-radio-group>
                                <span class="color">注：如果是以个人名义参与项目报名投标，注册类型请选自然人</span>
                            </el-form-item>
                        </div>
                        <div class="mechanism" v-if="form.mechanism==2">
                            <table class="tab1">
                                <tr>
                                    <td>
                                        <span>*</span> 机构名称：
                                    </td>
                                    <td>
                                        <el-form-item prop="institutionname">
                                            <el-input v-model="form.institutionname"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td>
                                        <span>*</span> 企业性质：
                                    </td>
                                    <td>
                                        <el-form-item prop="enterprisenature">
                                            <el-select
                                                v-model="form.enterprisenature"
                                                placeholder="---请选择---"
                                            >
                                                <el-option
                                                    v-for="item in EnterpriseNature"
                                                    :key="item.id"
                                                    :label="item.dictValue"
                                                    :value="item.id"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 交易主体类型：
                                    </td>
                                    <td>
                                        <el-form-item>
                                            <el-radio-group v-model="form.bidder">
                                                <el-radio label="0">投标人</el-radio>
                                            </el-radio-group>
                                        </el-form-item>
                                    </td>
                                    <td>
                                        <span></span> 企业人数：
                                    </td>
                                    <td>
                                        <el-form-item>
                                            <el-input v-model="form.peoplenumber"></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 所属行业：
                                    </td>
                                    <td>
                                        <el-form-item prop="industry">
                                            <el-select
                                                v-model="form.industry"
                                                placeholder="---请选择---"
                                            >
                                                <el-option
                                                    v-for="item in IndustryClass"
                                                    :key="item.id"
                                                    :label="item.name"
                                                    :value="item.id"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </td>
                                    <td>
                                        <span>*</span> 资信等级：
                                    </td>
                                    <td>
                                        <el-form-item prop="creditrating">
                                            <el-select
                                                v-model="form.creditrating"
                                                placeholder="---请选择---"
                                            >
                                                <el-option
                                                    v-for="item in EnterpriseQualification"
                                                    :key="item.id"
                                                    :label="item.dictValue"
                                                    :value="item.id"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 法定代表人：
                                    </td>
                                    <td>
                                        <el-form-item prop="legalperson">
                                            <el-input
                                                v-model="form.legalperson"
                                                placeholder="请输入法定代表人"
                                            ></el-input>
                                        </el-form-item>
                                    </td>
                                    <td>
                                        <span>*</span> 企业类别：
                                    </td>
                                    <td>
                                        <el-form-item prop="businesscategory">
                                            <el-select
                                                v-model="form.businesscategory"
                                                placeholder="---请选择---"
                                            >
                                                <el-option
                                                    v-for="item in EnterpriseCategory"
                                                    :key="item.id"
                                                    :label="item.dictValue"
                                                    :value="item.id"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 区域：
                                    </td>
                                    <td colspan="3">
                                        <el-form-item class="threeSelect" prop="countyId">
                                            <el-select
                                                v-model="form.provinceId"
                                                placeholder="---请选择---"
                                                @change="onSelectedProvince"
                                            >
                                                <el-option
                                                    v-for="item in province"
                                                    :key="item.code"
                                                    :label="item.name"
                                                    :value="item.code"
                                                ></el-option>
                                            </el-select>

                                            <el-select
                                                v-model="form.cityId"
                                                placeholder="---请选择---"
                                                @change="onSelectedCity"
                                            >
                                                <el-option
                                                    v-for="item in city"
                                                    :key="item.code"
                                                    :label="item.name"
                                                    :value="item.code"
                                                ></el-option>
                                            </el-select>

                                            <el-select
                                                v-model="form.countyId"
                                                placeholder="---请选择---"
                                                @change="onSelectedCountry"
                                            >
                                                <el-option
                                                    v-for="item in county"
                                                    :key="item.code"
                                                    :label="item.name"
                                                    :value="item.code"
                                                ></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 成立时间：
                                    </td>
                                    <td colspan="3">
                                        <el-form-item prop="inittime">
                                            <el-date-picker
                                                v-model="form.inittime"
                                                type="date"
                                                placeholder="选择日期"
                                                format="yyyy-MM-dd"
                                                value-format="yyyy-MM-dd"
                                            ></el-date-picker>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 核发机关机构：
                                    </td>
                                    <td colspan="3">
                                        <el-form-item prop="issuingauthority">
                                            <el-input v-model="form.issuingauthority"></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 公司简介：
                                    </td>
                                    <td colspan="3">
                                        <el-form-item prop="businessoutline">
                                            <el-input
                                                type="textarea"
                                                v-model="form.businessoutline"
                                            ></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 经营范围：
                                    </td>
                                    <td colspan="3">
                                        <el-form-item prop="businessscope">
                                            <el-input type="textarea" v-model="form.businessscope"></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                            </table>
                            <h4>联系信息</h4>
                            <table class="tab2">
                                <tr>
                                    <td>
                                        <span>*</span> 联系人：
                                    </td>
                                    <td>
                                        <el-form-item prop="linkperson">
                                            <el-input v-model="form.linkperson"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td>
                                        <span>*</span> 联系电话：
                                    </td>
                                    <td>
                                        <el-form-item prop="linkphone">
                                            <el-input
                                                v-model="form.linkphone"
                                                placeholder="请输入联系电话"
                                            ></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 身份证：
                                    </td>
                                    <td>
                                        <el-form-item prop="idcard">
                                            <el-input v-model="form.idcard" placeholder="请输入身份证号码"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td>
                                        <span>*</span> 联系地址：
                                    </td>
                                    <td>
                                        <el-form-item prop="linkaddr">
                                            <el-input
                                                type="textarea"
                                                v-model="form.linkaddr"
                                                placeholder="请输入联系地址"
                                            ></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 电子邮箱：
                                    </td>
                                    <td>
                                        <el-form-item prop="linkemail">
                                            <el-input v-model="form.linkemail" placeholder="请输入邮箱"></el-input>
                                        </el-form-item>
                                    </td>
                                    <td>
                                        <span></span> 邮政编码：
                                    </td>
                                    <td>
                                        <el-form-item>
                                            <el-input v-model="form.postcode" placeholder="请填入邮编"></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                            </table>
                            <h4>机构资质信息</h4>
                            <div class="choice clearFix">
                                <el-form-item label="是否三证合一：" label-width="168px">
                                    <el-radio-group v-model="form.threeSyndromes">
                                        <el-radio label="0">是</el-radio>
                                        <el-radio label="1">否</el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                            <div class="iscertificate">
                                <table class="tab3" v-if="threeSyndromes">
                                    <tr>
                                        <td>
                                            <span>*</span> 统一社会信用代码：
                                        </td>
                                        <td colspan="2">
                                            <el-form-item prop="uscc">
                                                <el-input v-model="form.uscc"></el-input>
                                            </el-form-item>
                                        </td>
                                    </tr>
                                    <tr class="height">
                                        <td>
                                            <span>*</span> 营业执照扫描件：
                                        </td>
                                        <td @click="uploadOneImg">
                                            <img-upload
                                                @getSonUrl="getSonUrl(arguments)"
                                                :child="'form.businessscan'"
                                            />
                                        </td>
                                        <td>
                                            <img :src="form.businessscan" style="max-width:350px;" />
                                        </td>
                                    </tr>
                                    <tr class="height">
                                        <td>
                                            <span></span> 其他资质扫描件：
                                        </td>
                                        <td colspan="2" @click="uploadMoreImg">
                                            <img-uploads
                                                @getMoreSonUrl="getMoreSonUrl(arguments)"
                                                :childMore="'form.otherscan'"
                                                :fileList="form.otherscan"
                                            />
                                        </td>
                                    </tr>
                                    <tr class="height">
                                        <td>
                                            <span></span> 企业logo：
                                        </td>
                                        <td @click="uploadOneImg">
                                            <img-upload
                                                @getSonUrl="getSonUrl(arguments)"
                                                :child="'form.logo'"
                                            />
                                        </td>
                                        <td>
                                            <img :src="form.logo" style="max-width:350px;" />
                                        </td>
                                    </tr>
                                </table>
                                <table class="tab4" v-if="!threeSyndromes">
                                    <tr>
                                        <td>
                                            <span>*</span> 营业执照号码：
                                        </td>
                                        <td colspan="2">
                                            <el-form-item prop="businesscode">
                                                <el-input v-model="form.businesscode"></el-input>
                                            </el-form-item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span>*</span> 税务登记号：
                                        </td>
                                        <td colspan="2">
                                            <el-form-item prop="taxationcode">
                                                <el-input v-model="form.taxationcode"></el-input>
                                            </el-form-item>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span>*</span> 组织结构代码：
                                        </td>
                                        <td colspan="2">
                                            <el-form-item prop="organizationcode">
                                                <el-input v-model="form.organizationcode"></el-input>
                                            </el-form-item>
                                        </td>
                                    </tr>
                                    <tr class="height">
                                        <td>
                                            <span>*</span> 营业执照扫描件：
                                        </td>
                                        <td @click="uploadOneImg">
                                            <img-upload
                                                @getSonUrl="getSonUrl(arguments)"
                                                :child="'form.businessscan'"
                                            />
                                        </td>
                                        <td>
                                            <img :src="form.businessscan" />
                                        </td>
                                    </tr>
                                    <tr class="height">
                                        <td>
                                            <span>*</span> 税务登记扫描件：
                                        </td>
                                        <td @click="uploadOneImg">
                                            <img-upload
                                                @getSonUrl="getSonUrl(arguments)"
                                                :child="'form.taxationscan'"
                                            />
                                        </td>
                                        <td>
                                            <img :src="form.taxationscan" />
                                        </td>
                                    </tr>
                                    <tr class="height">
                                        <td>
                                            <span>*</span> 组织机构代码证扫描件：
                                        </td>
                                        <td @click="uploadOneImg">
                                            <img-upload
                                                @getSonUrl="getSonUrl(arguments)"
                                                :child="'form.organizationscan'"
                                            />
                                        </td>
                                        <td>
                                            <img :src="form.organizationscan" />
                                        </td>
                                    </tr>
                                    <tr class="height">
                                        <td>
                                            <span></span> 其他资质扫描件：
                                        </td>
                                        <td colspan="2" @click="uploadMoreImg">
                                            <img-uploads
                                                @getMoreSonUrl="getMoreSonUrl(arguments)"
                                                :childMore="'form.otherscan'"
                                                :fileList="form.otherscan"
                                            />
                                        </td>
                                    </tr>
                                    <tr class="height">
                                        <td>
                                            <span></span> 企业logo：
                                        </td>
                                        <td @click="uploadOneImg">
                                            <img-upload
                                                @getSonUrl="getSonUrl(arguments)"
                                                :child="'form.logo'"
                                            />
                                        </td>
                                        <td>
                                            <img :src="form.logo" />
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </el-form>

                    <el-form ref="peopleForm" :model="peopleForm" :rules="personalrules">
                        <div class="personal" v-if="form.mechanism==1">
                            <table class="tab1">
                                <tr>
                                    <td>
                                        <span>*</span> 姓名：
                                    </td>
                                    <td colspan="3">
                                        <el-form-item prop="linkperson">
                                            <el-input v-model="peopleForm.linkperson"></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 交易主体类型：
                                    </td>
                                    <td colspan="3">
                                        <el-radio-group v-model="peopleForm.transactiontype">
                                            <el-radio label="1">投标人</el-radio>
                                        </el-radio-group>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 身份证：
                                    </td>
                                    <td>
                                        <el-form-item prop="idcard">
                                            <el-input
                                                v-model="peopleForm.idcard"
                                                placeholder="请输入身份证号码"
                                            ></el-input>
                                        </el-form-item>
                                    </td>
                                    <td>
                                        <span>*</span> 联系地址：
                                    </td>
                                    <td>
                                        <el-form-item prop="linkaddr">
                                            <el-input
                                                type="textarea"
                                                v-model="peopleForm.linkaddr"
                                                placeholder="请输入联系地址"
                                            ></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 联系电话：
                                    </td>
                                    <td>
                                        <el-form-item prop="linkphone">
                                            <el-input
                                                v-model="peopleForm.linkphone"
                                                placeholder="请输入联系电话"
                                            ></el-input>
                                        </el-form-item>
                                    </td>
                                    <td>
                                        <span></span> 邮政编码：
                                    </td>
                                    <td>
                                        <el-form-item>
                                            <el-input
                                                v-model="peopleForm.postcode"
                                                placeholder="请填入邮编"
                                            ></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 电子邮箱：
                                    </td>
                                    <td>
                                        <el-form-item prop="linkemail">
                                            <el-input
                                                v-model="peopleForm.linkemail"
                                                placeholder="请输入电子邮箱"
                                            ></el-input>
                                        </el-form-item>
                                    </td>
                                    <td>
                                        <span>*</span> 基础账户账号：
                                    </td>
                                    <td>
                                        <el-form-item prop="bankuser">
                                            <el-input
                                                v-model="peopleForm.bankuser"
                                                placeholder="请填入基础账户账号"
                                            ></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>*</span> 开户银行：
                                    </td>
                                    <td colspan="3">
                                        <el-form-item prop="bankname">
                                            <el-input
                                                v-model="peopleForm.bankname"
                                                placeholder="请输入银行全称+开户支行名称"
                                            ></el-input>
                                        </el-form-item>
                                    </td>
                                </tr>
                                <tr class="height">
                                    <td>
                                        <span>*</span> 身份证扫描件：(请上传身份证正反面扫描件)
                                    </td>
                                    <td colspan="3" @click="uploadMoreImg">
                                        <img-uploads
                                            @getMoreSonUrl="getMoreSonUrl(arguments)"
                                            :childMore="'peopleForm.useridscan'"
                                            :fileList="peopleForm.useridscan"
                                        />
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </el-form>
                </div>
            </div>
            <div class="butbox">
                <el-button @click="onSubmit" v-if="isexamine">立即认证</el-button>
            </div>

            <div class="examinestute" v-if="!isexamine">
                <div class="top">
                    <p>审核信息</p>
                </div>
                <div class="bottom">
                    <table>
                        <tr>
                            <th>审核时间</th>
                            <th>审核状态</th>
                            <th>审核意见</th>
                        </tr>
                        <tr>
                            <td>{{ UditStatus.audittime }}</td>
                            <td>{{ UditStatus.auditstatus }}</td>
                            <td>{{ UditStatus.auditopinion }}</td>
                        </tr>
                    </table>
                </div>

                <div class="butbox">
                    <el-button @click="recertification" v-if="isrecertification">重新认证</el-button>
                </div>
            </div>
        </div>
        <!-- 供应商认证 -->
    </div>
</template>

<script>
import Cookies from "js-cookie";
import ImgUpload from "@/components/UploadImg";
import ImgUploads from "@/components/UploadImgs";
export default {
    data() {
        // 手机号或电话号验证
        var checkPhone = (rule, value, callback) => {
            let regPone = null;
            let mobile = /^((13|14|15|17|18)[0-9]{1}\d{8})$/; // 最新16手机正则
            let tel = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/; // 座机
            if (value !== null && value.charAt(0) === "0") {
                // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
                regPone = tel;
            } else if (value !== null && value.charAt(0) !== "0") {
                regPone = mobile;
            }
            if (regPone === null) {
                return callback(new Error("请输入电话"));
            } else if (!regPone.test(value)) {
                return callback(
                    new Error(
                        "请输入正确的电话格式,其中座机格式'区号-座机号码'"
                    )
                );
            } else {
                callback();
            }
        };
        // 身份证号验证
        var idCardPass = (rule, value, callback) => {
            let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (!reg.test(value)) {
                return callback(new Error("请输入正确的身份证号"));
            } else {
                callback();
            }
        };
        // 邮箱验证
        var email = (rule, value, callback) => {
            let reg =
                /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
            if (!reg.test(value)) {
                return callback(new Error("邮箱格式不正确"));
            } else {
                callback();
            }
        };
        return {
            mid: 0,
            isrecertification: false,
            isexamine: true,
            UditStatus: {
                auditstatus: "",
                audittime: "",
                auditopinion: "",
                suppliertype: "",
            },
            child: "",
            showmechanism: true, // 机构是否展示
            threeSyndromes: true, // 是否三证合一
            EnterpriseNature: [
                {
                    dictLabel: "",
                    dictValue: "",
                },
            ], // 企业性质
            IndustryClass: [
                {
                    dictLabel: "",
                    dictCode: "",
                },
            ], // 获取所属行业
            EnterpriseQualification: [
                {
                    dictLabel: "",
                    dictValue: "",
                },
            ], // 企业资质
            EnterpriseCategory: [
                {
                    dictLabel: "",
                    dictValue: "", // 1制造商2代理商
                },
            ], // 企业类别
            productPicPathList: [],
            dialogVisible: false,
            dialogImageUrl: "",
            formDate: "",
            province: [
                {
                    name: "",
                    areaId: "",
                },
            ], // 省
            city: [
                {
                    name: "",
                    areaId: "",
                },
            ], // 市
            county: [
                {
                    name: "",
                    areaId: "",
                },
            ], // 县
            // 企业提交表
            form: {
                mechanism: "2", // 2机构，1个人
                threeSyndromes: "0", // 0三证合一，1不是三证合一
                bidder: "0", // 投标人
                enterprisenature: "", // 选中的企业性质
                industry: "", // 选中的所属行业
                businesscategory: "", // 选中的企业类别
                // EnterpriseQualification: '',  // 选中企业资质
                businesscode: "", // 营业执照号
                uscc: "", // 统一社会信用代码
                businessoutline: "", // 公司简介
                creditrating: "", // 资信等级
                idcard: "", // 身份证
                inittime: "", // 成立时间
                issuingauthority: "", // 核发机关机构
                provinceId: "",
                cityId: "",
                countyId: "",
                peoplenumber: "", // 企业人数
                businessscope: "", // 经营范围
                institutionname: "", // 机构名称
                linkaddr: "", // 联系地址
                linkemail: "", // 电子邮箱
                postcode: "", //邮政编码
                linkperson: "", // 联系人
                linkphone: "", // 联系电话
                legalperson: "", // 法人代表
                organizationcode: "", // 组织机构代码
                businessscan: "", // 营业执照扫描件
                organizationscan: "", // 组织机构扫描件
                otherscan: [], // 其他资质扫描件
                taxationcode: "", // 税务登记号
                taxationscan: "", // 税务登记扫描件
                logo: "", // 企业logo
                transactionType: 1, //交易主体类型 1：投标人
                idcardtype: 1, //证件类型 1：中国居民身份证
                threeinone: 0, //是否三证合一  0：否 1：是   注：已经写在提交逻辑中，此处只是备注说明一下
            },
            // 个人提交表
            peopleForm: {
                // linkperson: "", // 姓名 张三
                // transactiontype: "1", // 交易主体类型  1：投标人
                // idcard: "", // 身份证 130222222222222222
                // linkaddr: "", // 联系地址 运河区北京路
                // linkphone: "", // 联系电话 17777777777
                // linkemail: "", // 电子邮箱 88888888@qq.com
                // postcode: "", // 邮政编码 061000
                // bankuser: "", // 基础账户账号 1013212020
                // bankname: "", // 开户行 农业银行
                // useridscan: ["card1.png", "card2.png"], // 身份证扫描件
                // idcardtype: 1, //证件类型 1：中国居民身份证

                bankname: "",
                bankuser: "",
                idcard: "",
                idcardtype: 1,
                linkaddr: "",
                linkemail: "",
                linkperson: "",
                linkphone: "",
                postcode: "",
                transactiontype: "",
                useridscan: [],
            },
            // 企业表单验证
            companyrules: {
                institutionname: {
                    required: true,
                    message: "请输入机构名称",
                    trigger: "blur",
                },
                enterprisenature: {
                    required: true,
                    message: "请选择企业性质",
                    trigger: "blur",
                },
                industry: {
                    required: true,
                    message: "请选择所属行业",
                    trigger: "blur",
                },
                creditrating: {
                    required: true,
                    message: "请选择资信等级",
                    trigger: "blur",
                },
                legalperson: {
                    required: true,
                    message: "请输入法定代表人",
                    trigger: "blur",
                },
                businesscategory: {
                    required: true,
                    message: "请选择企业类别",
                    trigger: "blur",
                },
                provinceId: {
                    required: true,
                    message: "请选择省份",
                    trigger: "blur",
                },
                cityId: {
                    required: true,
                    message: "请选择城市",
                    trigger: "blur",
                },
                countyId: {
                    required: true,
                    message: "请选择区县",
                    trigger: "blur",
                },
                inittime: {
                    required: true,
                    message: "请选择企业成立时间",
                    trigger: "blur",
                },
                issuingauthority: {
                    required: true,
                    message: "请输入核发机关机构",
                    trigger: "blur",
                },
                businessoutline: {
                    required: true,
                    message: "请输入公司简介",
                    trigger: "blur",
                },
                businessscope: {
                    required: true,
                    message: "请输入经营范围",
                    trigger: "blur",
                },
                linkperson: {
                    required: true,
                    message: "请输入联系人",
                    trigger: "blur",
                },
                linkphone: {
                    required: true,
                    validator: checkPhone,
                    trigger: "blur",
                },
                idcard: [
                    {
                        required: true,
                        message: "请输入身份证号",
                        trigger: "blur",
                    },
                    { validator: idCardPass, trigger: "blur" },
                ],
                linkaddr: {
                    required: true,
                    message: "请输入联系地址",
                    trigger: "blur",
                },
                linkemail: [
                    {
                        required: true,
                        message: "请输入电子邮箱",
                        trigger: "blur",
                    },
                    { validator: email, trigger: "blur" },
                ],
                uscc: [
                    {
                        required: true,
                        message: "请输入统一社会信用代码",
                        trigger: "blur",
                    },
                ],
                businessscan: {
                    required: true,
                    message: "请上传营业执照扫描件",
                    trigger: "blur",
                },
                businesscode: {
                    required: true,
                    message: "请输入营业执照号",
                    trigger: "blur",
                },
                taxationcode: {
                    required: true,
                    message: "请输入税务登记号",
                    trigger: "blur",
                },
                organizationcode: {
                    required: true,
                    message: "请输入机构组织代码",
                    trigger: "blur",
                },
                taxationscan: {
                    required: true,
                    message: "请上传税务登记扫描件",
                    trigger: "blur",
                },
                organizationscan: {
                    required: true,
                    message: "请上传组织机构代码证扫描件",
                    trigger: "blur",
                },
            },
            // 个人表单验证
            personalrules: {
                linkperson: {
                    required: true,
                    message: "请输入姓名",
                    trigger: "blur",
                },
                idcard: [
                    {
                        required: true,
                        message: "请输入身份证号",
                        trigger: "blur",
                    },
                    { validator: idCardPass, trigger: "blur" },
                ],
                linkaddr: {
                    required: true,
                    message: "请输入联系地址",
                    trigger: "blur",
                },
                linkphone: {
                    required: true,
                    validator: checkPhone,
                    trigger: "blur",
                },
                bankuser: {
                    required: true,
                    message: "请输入基础账号",
                    trigger: "blur",
                },
                bankname: {
                    required: true,
                    message: "请输入开户银行",
                    trigger: "blur",
                },
                linkemail: [
                    {
                        required: true,
                        message: "请输入电子邮箱",
                        trigger: "blur",
                    },
                    { validator: email, trigger: "blur" },
                ],
            },
        };
    },
    components: {
        // centerIndex,
        ImgUpload,
        ImgUploads,
    },
    watch: {
        "form.mechanism": {
            handler: function () {
                if (this.form.mechanism == "1") {
                    this.showmechanism = true;
                    this.peopleForm = {
                        linkperson: "", // 姓名
                        transactiontype: "1", // 交易主体类型
                        idcard: "", // 身份证
                        linkaddr: "", // 联系地址
                        linkphone: "", // 联系电话
                        postcode: "", // 邮政编码
                        bankuser: "", // 基础账户账号
                        bankname: "", // 开户行
                        useridscan: [], // 身份证扫描件
                    };
                } else {
                    this.showmechanism = false;
                    this.form = {
                        mechanism: "2", // 0机构，1个人
                        threeSyndromes: "0", // 0三证合一，1不是三证合一
                        bidder: "0", // 投标人
                        enterprisenature: "", // 选中的企业性质
                        industry: "", // 选中的所属行业
                        businesscategory: "", // 选中的企业类别
                        // EnterpriseQualification: '',  // 选中企业资质
                        businesscode: "", // 营业执照号
                        uscc: "", // 统一社会信用代码
                        businessoutline: "", // 公司简介
                        creditrating: "", // 资信等级
                        idcard: "", // 身份证
                        inittime: "", // 成立时间
                        issuingauthority: "", // 核发机关机构
                        provinceId: "",
                        cityId: "",
                        countyId: "",
                        peoplenumber: "", // 企业人数
                        businessscope: "", // 经营范围
                        institutionname: "", // 机构名称
                        linkaddr: "", // 联系地址
                        linkemail: "", // 电子邮箱
                        postcode: "", //邮政编码
                        linkperson: "", // 联系人
                        linkphone: "", // 联系电话
                        legalperson: "", // 法人代表
                        organizationcode: "", // 组织机构代码
                        businessscan: "", // 营业执照扫描件
                        organizationscan: "", // 组织机构扫描件
                        otherscan: [], // 其他资质扫描件
                        taxationcode: "", // 税务登记号
                        taxationscan: "", // 税务登记扫描件
                        logo: "", // 企业logo
                    };
                }
            },
        },
        "form.threeSyndromes": {
            handler: function () {
                if (this.form.threeSyndromes == "0") {
                    this.threeSyndromes = true;
                    this.form.uscc = "";
                } else {
                    this.threeSyndromes = false;
                    this.form.businesscode = ""; // 营业执照号
                    this.form.taxationcode = ""; // 税务登记号
                    this.form.taxationscan = ""; // 税务登记扫描件
                    this.form.organizationcode = ""; // 组织机构代码
                    this.form.organizationscan = ""; // 组织机构扫描件
                }
            },
        },
    },
    methods: {
        // 单图上传
        uploadOneImg: function () {},

        // 获取子组件的值（单图）
        getSonUrl: async function (imgSrc) {
            if (imgSrc[1] == "form.businessscan") {
                this.form.businessscan = imgSrc[0].link;
            } else if (imgSrc[1] == "form.logo") {
                this.form.logo = imgSrc[0].link;
            } else if (imgSrc[1] == "form.taxationscan") {
                this.form.taxationscan = imgSrc[0].link;
            } else if (imgSrc[1] == "form.organizationscan") {
                this.form.organizationscan = imgSrc[0].link;
            }
        },

        // 多图上传
        uploadMoreImg: function () {},

        // 获取子组件的值（多图）
        getMoreSonUrl: function (imgSrcMore) {
            if (imgSrcMore[1] == "form.otherscan") {
                this.form.otherscan = imgSrcMore[0];
            } else if (imgSrcMore[1] == "peopleForm.useridscan") {
                this.peopleForm.useridscan = imgSrcMore[0];
            }
        },

        // 获取企业性质 com_nature
        enterpriseNature: function () {
            this.$api.supplier
                .getDictionaryTree({
                    code: "com_nature",
                })
                .then((res) => {
                    if (res.success) {
                        this.EnterpriseNature = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },

        // 获取所属行业
        industryClass: function () {
            this.$api.supplier.getHangYeList({ id: 0 }).then((res) => {
                if (res.success) {
                    this.IndustryClass = res.data;
                } else {
                    this.$message.error(res.msg);
                }
            });
        },

        // 企业资质
        enterpriseQualification: function () {
            this.$api.supplier
                .getDictionaryTree({
                    code: "com_credit_level",
                })
                .then((res) => {
                    if (res.success) {
                        this.EnterpriseQualification = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },

        // 获取企业类别 com_class
        enterpriseCategory: function () {
            this.$api.supplier
                .getDictionaryTree({
                    code: "com_class",
                })
                .then((res) => {
                    if (res.success) {
                        this.EnterpriseCategory = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        // 回显个人详情
        //  回显供应商详情
        getSupplierInfo() {
            this.$api.supplier
                .getSupplierInfo({
                    // userId: Cookies.get("user_id"),
                })
                .then((res) => {
                    if (res.success) {
                        let data = res.data;
                        this.form.mechanism = data.regtype
                        if (data.regtype == 1) {
                            // 自然人
                            let useridscan = [];
                            if (
                                data.userIdScanList &&
                                data.userIdScanList.length > 0
                            ) {
                                data.userIdScanList.forEach((e) => {
                                    useridscan.push({ name: "", url: e });
                                });
                            }
                            let peopleForm ={
                                linkperson: data.linkperson, // 姓名
                                transactiontype: "1", // 交易主体类型
                                idcard: data.idcard, // 身份证
                                linkaddr: data.linkaddr, // 联系地址
                                linkphone: data.linkphone, // 联系电话
                                postcode: data.postcode, // 邮政编码
                                bankuser: data.bankuser, // 基础账户账号
                                bankname: data.bankname, // 开户行
                                linkemail:data.linkemail,
                                useridscan: useridscan, // 身份证扫描件
                            };
                            setTimeout(() => {
                                this.peopleForm = peopleForm
                            }, 300);
                            this.uditStatus(data)
                        } else {
                            // 公司
                            let otherscan = [];
                            if (
                                data.otherscanArr &&
                                data.otherscanArr.length > 0
                            ) {
                                data.otherscanArr.forEach((e) => {
                                    otherscan.push({ name: "", url: e });
                                });
                            }
                            this.uditStatus(data);
                            this.form = {
                                threeSyndromes: "0", // 0三证合一，1不是三证合一
                                bidder: "0", // 投标人
                                enterprisenature: data.enterprisenature, // 选中的企业性质
                                industry: data.industry, // 选中的所属行业
                                businesscategory: data.businesscategory, // 选中的企业类别
                                // EnterpriseQualification: '',  // 选中企业资质
                                businesscode: data.businesscode, // 营业执照号
                                uscc: data.uscc, // 统一社会信用代码
                                businessoutline: data.businessoutline, // 公司简介
                                creditrating: data.creditrating, // 资信等级
                                idcard: data.idcard, // 身份证
                                inittime: data.inittime, // 成立时间
                                issuingauthority: data.issuingauthority, // 核发机关机构
                                provinceId: data.provinceId + "",
                                cityId: data.cityId + "",
                                countyId: data.countyId + "",
                                peoplenumber: data.peoplenumber, // 企业人数
                                businessscope: data.businessscope, // 经营范围
                                institutionname: data.institutionname, // 机构名称
                                linkaddr: data.linkaddr, // 联系地址
                                linkemail: data.linkemail, // 电子邮箱
                                postcode: data.postcode, //邮政编码
                                linkperson: data.linkperson, // 联系人
                                linkphone: data.linkphone, // 联系电话
                                legalperson: data.legalperson, // 法人代表
                                organizationcode: data.organizationcode, // 组织机构代码
                                businessscan: data.businessscan, // 营业执照扫描件
                                organizationscan: data.organizationscan, // 组织机构扫描件
                                otherscan: otherscan, // 其他资质扫描件
                                taxationcode: data.taxationcode, // 税务登记号
                                taxationscan: data.taxationscan, // 税务登记扫描件
                                logo: data.logo, // 企业logo
                            };
                            this.getCity(data.provinceId);
                            this.getCounty(data.cityId);
                        }
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },
        // 获取省
        getProvince: function () {
            this.$api.supplier
                .getRegion({
                    code: "00",
                })
                .then((res) => {
                    if (res.success) {
                        this.province = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },

        // 改变省
        onSelectedProvince: function (pid) {
            this.form.cityId = "";
            this.form.countyId = "";
            this.getCity(pid);
        },

        // 获取市
        getCity: function (parentId) {
            this.$api.supplier
                .getRegion({
                    code: parentId,
                })
                .then((res) => {
                    if (res.success) {
                        this.city = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },

        // 改变市
        onSelectedCity: function (pid) {
            this.form.county = "";
            this.getCounty(pid);
        },

        // 获取县
        getCounty: function (parentId) {
            this.$api.supplier
                .getRegion({
                    code: parentId,
                })
                .then((res) => {
                    if (res.success) {
                        this.county = res.data;
                    } else {
                        this.$message.error(res.msg);
                    }
                });
        },

        // 改变县
        onSelectedCountry: function (item) {
        },

        // 提交认证信息
        onSubmit: function () {
            // debugger
            if (this.form.mechanism == "2") {
                this.$refs["form"].validate((valid) => {
                    if (valid) {
                        // "threeinone": 0, //是否三证合一  0：否 1：是
                        if (this.threeSyndromes) {
                            // 请去掉下面的注释
                            if (this.form.businessscan) {
                                var json = this.form;
                                let otherscan = [];

                                this.form.otherscan.forEach((el) => {
                                    otherscan.push(el.url);
                                });
                                json.otherscan = otherscan.join(",");
                                this.$api.supplier
                                    .postCompanyData(json)
                                    .then((res) => {
                                        if (res.code == 200) {
                                            this.$message.success(
                                                "提交认证成功"
                                            );
                                            this.isexamine = false;
                                            // this.uditStatus(res.data);
                                        } else {
                                            this.$message.error(res.msg);
                                        }
                                    })
                                    .catch((err) => {
                                        let response = err.response;
                                        if (response.status == 400) {
                                            this.$message.error(
                                                response.data.msg
                                            );
                                        }
                                    });
                            } else {
                                this.$message.error("请上传营业执照扫描件！");
                            }
                        } else {
                            if (
                                this.form.businessscan &&
                                this.form.taxationscan &&
                                this.form.organizationscan
                            ) {
                                var json = this.form;
                                json.otherscan = this.form.otherscan.join(",");
                                this.$api.supplier
                                    .postCompanyData(json)
                                    .then((res) => {
                                        if (res.code == 200) {
                                            this.$message.success(
                                                "提交认证成功"
                                            );
                                            this.isexamine = false;
                                            // this.uditStatus();
                                        } else {
                                            this.$message.error(res.msg);
                                        }
                                    }).catch(err=>{
                                        let response = err.response
                                        if(response.error == 400){
                                            this.$message.error(response.msg)
                                        }
                                    });
                            } else {
                                this.$message.error(
                                    "请检查营业执照扫描件，税务登记扫描件，组织结构代码证扫描件是否都已上传"
                                );
                            }
                        }
                    }
                });
            } else {
                this.$refs["peopleForm"].validate((valid) => {
                    if (valid) {
                        if (this.peopleForm.useridscan.length == 2) {
                            var json = this.peopleForm;
                            let useridscan = [];
                            this.peopleForm.useridscan.forEach((el) => {
                                useridscan.push(el.url);
                            });
                            json.useridscan = useridscan.join(",");

                            this.$api.supplier
                                .postPersonalData(json)
                                .then((res) => {
                                    if (res.success) {
                                        this.$message.success("提交认证成功");
                                        this.isexamine = false;
                                        // this.uditStatus();
                                    } else {
                                        this.$message.error(res.msg);
                                    }
                                }).catch(err=>{
                                        let response = err.response
                                        if(response.status == 400){
                                            this.$message.error(response.data.msg)
                                        }
                                    });
                        } else {
                            this.$message.error(
                                "请分别上传身份证正、反面两张图片！"
                            );
                        }
                    }
                });
            }
        },

        // 重新认证
        recertification: function () {
            this.isexamine = true;
        },

        // 审核状态
        uditStatus(data) {
            //   this.$api.usermassage.udit_status({}).then((res) => {
            //     if (res.code == 200) {
            if (data.auditstatus == 0) {
                this.UditStatus = data;
                this.UditStatus.auditstatus = "审核中";
                this.UditStatus.audittime = "等待审核";
                this.isexamine = false;
            } else if (data.auditstatus == 1) {
                this.UditStatus = data;
                this.UditStatus.auditstatus = "审核通过";
                this.isexamine = false;
                this.UditStatus.audittime = data.audittime.substring(0, 10);
            } else {
                this.UditStatus = data;
                this.UditStatus.auditstatus = "审核未通过";
                this.isrecertification = true;
                this.isexamine = false;
                this.UditStatus.audittime = data.audittime.substring(0, 10);
            }
            // this.isexamine = true;

            //     } else {
            //       this.isexamine = true;
            //     }
            //   });
        },
    },
    mounted() {
        this.enterpriseNature(); //获取企业性质
        this.industryClass(); //获取所属行业
        this.enterpriseQualification(); // 企业资质
        this.enterpriseCategory(); //获取企业类别
        this.getProvince(); //获取省级列表
        // this.uditStatus();
        this.getSupplierInfo();
    },
};
</script>

<style lang="scss" >
.certificat {
    // width: 1200px;
    // margin: 0 auto;
    .el-radio__input.is-checked .el-radio__inner {
        border-color: #0b5ffd;
        background: #0b5ffd;
    }
    .el-radio__input.is-checked + .el-radio__label {
        color: #333333 !important;
    }
    .right {
        // width: 926px;
        // padding: 20px 28px;
        // min-height: 600px;
        // float: right;
        // text-align: left;
        // -webkit-box-shadow: 0px 0px 10px 0px rgba(140,140,140,0.21);
        // box-shadow: 0px 0px 10px 0px rgba(140,140,140,0.21);
        textarea {
            margin-top: 6px;
        }
        h3 {
            line-height: 40px;
            text-indent: 1em;
            font-size: 17px;
            color: #0b5ffd;
            position: relative;
            border-bottom: 1px solid #e5e5e5;
            margin-bottom: 10px;
            span {
                width: 130px;
                height: 2px;
                background-color: #0b5ffd;
                position: absolute;
                bottom: -1px;
                left: 0;
            }
        }
        > p {
            width: 100%;
            height: 40px;
            line-height: 40px;
            background-color: #a7ccf1;
            border: 1px solid #0b5ffd;
            b {
                width: 18px;
                height: 18px;
                line-height: 18px;
                text-align: center;
                color: #0b5ffd;
                float: left;
                margin-top: 10px;
                margin-left: 23px;
                border: 2px solid #0b5ffd;
                border-radius: 50%;
            }
            span {
                float: left;
                margin-left: 15px;
                font-size: 14px;
                color: #343434;
            }
        }
        .massage {
            margin-top: 10px;
            border: 1px solid #eeeeee;
            border-bottom: 0 none;
            h4 {
                line-height: 40px;
                text-indent: 1em;
                font-size: 16px;
                border-bottom: 1px solid #eeeeee;
            }
            .el-form-item {
                margin-bottom: 0;
            }
            .base_mes {
                .choice {
                    background-color: #eeefef;
                    span.color {
                        color: #eb7e08;
                        display: inline-block;
                        margin-left: 25px;
                    }
                }
                table {
                    border-collapse: collapse;
                    border-spacing: 0;
                    .pure-table {
                        border-collapse: collapse;
                        border-spacing: 0;
                        empty-cells: show;
                        border: 1px solid #cbcbcb;
                    }
                    tr {
                        border-bottom: 1px solid #eeeeee;
                        .el-input__inner {
                            height: 30px;
                            line-height: 30px;
                            width: 100% !important;
                        }
                        .el-select {
                            display: block;
                        }
                        td:nth-child(1) {
                            width: 160px;
                        }
                        span {
                            color: #ff1f1f;
                        }
                        .el-icon-date:before {
                            color: #606266;
                        }
                        td:nth-child(1),
                        td:nth-child(3) {
                            text-align: right;
                            background-color: #f6f6f6;
                        }
                        td:nth-child(2),
                        td:nth-child(4) {
                            width: 310px;
                            padding: 5px 10px;
                        }
                        td:nth-child(3) {
                            width: 130px;
                        }
                        .threeSelect {
                            .el-select {
                                margin-right: 5px;
                            }
                        }
                    }
                }
                .mechanism {
                    font-size: 14px;
                    .tab1 {
                        tr:nth-child(5) {
                            .el-select {
                                display: inline-block;
                            }
                        }
                    }
                    .tab3,
                    .tab4 {
                        tr td:nth-child(2) {
                            width: 398px;
                        }
                        tr td:nth-child(3) {
                            width: 364px;
                            background-color: #ffffff;
                            border-left: 1px solid #eeeeee;
                            padding: 4px 10px;
                            text-align: left;
                        }
                    }
                    .tab3,
                    .tab4 {
                        font-size: 12px;
                        .height {
                            height: 80px;
                        }
                        .el-button--primary {
                            background-color: #0b5ffd;
                            color: #000000 !important;
                            font-size: 14px;
                            border-color: #0b5ffd;
                            border-radius: 17px;
                            span {
                                color: #ffffff !important;
                                margin-left: 0;
                            }
                        }
                        span {
                            display: inline-block;
                            margin-left: 10px;
                        }
                        .uploadimgs {
                            span {
                                display: inline-block;
                                margin-left: 0;
                            }
                        }
                        b {
                            font-weight: normal;
                            color: #8a8a8a;
                        }
                    }
                }
                .personal {
                    font-size: 14px;
                    .tab1 {
                        font-size: 12px;
                        .height {
                            height: 80px;
                        }
                        .el-button--primary {
                            background-color: #0b5ffd;
                            color: #ffffff !important;
                            font-size: 14px;
                            border-color: #0b5ffd;
                            border-radius: 17px;
                            span {
                                color: #ffffff !important;
                                margin-left: 0;
                            }
                        }
                        span {
                            display: inline-block;
                        }
                        b {
                            font-weight: normal;
                            color: #8a8a8a;
                        }
                        tr:last-child {
                            td:nth-child(3) {
                                background-color: #ffffff;
                                border-left: 1px solid #eeeeee;
                            }
                        }
                    }
                }
            }
        }

        .butbox {
            text-align: center;
            margin-top: 60px;
            padding-bottom: 30px;
            .el-button {
                width: 180px;
                height: 45px;
                color: #ffffff;
                background-color: #0b5ffd;
            }
        }
        .examinestute {
            .top {
                border: 1px solid #cbcbcb;
                border-bottom: 0 none;
                line-height: 40px;
                text-align: center;
                background-color: #f6f6f6;
                font-size: 14px;
                color: #010101;
            }
            .bottom {
                table {
                    border-collapse: collapse;
                    border-spacing: 0;
                    .pure-table {
                        border-collapse: collapse;
                        border-spacing: 0;
                        empty-cells: show;
                    }
                    tr {
                        line-height: 38px;
                        text-align: center;
                        th {
                            width: 306px;
                            border: 1px solid #cbcbcb;
                            font-size: 14px;
                            font-weight: normal;
                            background-color: #f6f6f6;
                        }
                        td {
                            font-size: 14px;
                            border: 1px solid #cbcbcb;
                        }
                    }
                }
            }
        }
    }
}
</style>
