<template>
    <div class="service-box">
        <!-- <img :src="require('../assets/serve-box/diantou4.png')" alt=""> -->
        <!-- <div><video autoplay muted loop :src="require('../assets/serve-box/diantou.mp4')"></video></div> -->
        <div class="phone">
            <img :src="img1" alt="">
        </div>
        
        <div class="say-box">
            <ol>
                <li v-for="(item,index) in texts" :key="index + 'w'">
                    <img :src="require('../assets/sanjiao.png')" alt="">
                    {{item.title}} <a :href="item.url" target="_blank">{{item.text}}</a>
                </li>
            </ol>
        </div>
        <ul>
            <li>
                <div><a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">
                    <div>
                        <img :src="require('../assets/serve-box/icon-1.png')" alt="">
                        <img :src="require('../assets/serve-box/icon-1-on.png')" alt="">
                    </div>
                    <p>在线咨询</p>
                </a></div>
            </li>
            <li>
                <div>
                    <div>
                        <img :src="require('../assets/serve-box/icon-2.png')" alt="">
                        <img :src="require('../assets/serve-box/icon-2-on.png')" alt="">
                    </div>
                    <p>客服热线</p>
                </div>
                <div>
                    <div><img :src="require('../assets/serve-box/icon-2.png')" alt=""></div>
                    <p>15531757775</p>
                </div>
            </li>
            <li>
                <div>
                    <div>
                        <img :src="require('../assets/serve-box/icon-3.png')" alt="">
                        <img :src="require('../assets/serve-box/icon-3-on.png')" alt="">
                    </div>
                    <p>关注我们</p>
                </div>
                <div>
                    <div><img :src="require('../assets/serve-box/pic_2.png')" alt=""></div>
                </div>
            </li>
            <li @click="scrollToTop">
                <div>
                    <div>
                        <img :src="require('../assets/serve-box/icon-4.png')" alt="">
                        <img :src="require('../assets/serve-box/icon-4-on.png')" alt="">
                    </div>
                    <p>回到顶部</p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
export default{
    data() {
        return{
            img1: "https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/serve-box/out_quality65_loop0_frametime40.webp",
            // img: [
            //     require('../assets/serve-box/1.png'),
            //     require('../assets/serve-box/2.png'),
            //     require('../assets/serve-box/3.png'),
            //     require('../assets/serve-box/4.png'),
            //     require('../assets/serve-box/5.png'),
            //     require('../assets/serve-box/6.png'),
            //     require('../assets/serve-box/7.png'),
            //     require('../assets/serve-box/8.png'),
            //     require('../assets/serve-box/9.png'),
            //     require('../assets/serve-box/10.png'),
            //     require('../assets/serve-box/11.png'),
            //     require('../assets/serve-box/12.png'),
            //     require('../assets/serve-box/13.png'),
            //     require('../assets/serve-box/14.png'),
            //     require('../assets/serve-box/15.png'),
            //     require('../assets/serve-box/16.png'),
            //     require('../assets/serve-box/17.png'),
            //     require('../assets/serve-box/18.png'),
            //     require('../assets/serve-box/19.png'),
            //     require('../assets/serve-box/20.png'),
            //     require('../assets/serve-box/21.png'),
            //     require('../assets/serve-box/22.png'),
            //     require('../assets/serve-box/23.png'),
            //     require('../assets/serve-box/24.png'),
            //     require('../assets/serve-box/25.png'),
            //     require('../assets/serve-box/26.png'),
            //     require('../assets/serve-box/27.png'),
            //     require('../assets/serve-box/28.png'),
            //     require('../assets/serve-box/29.png'),
            //     require('../assets/serve-box/30.png'),
            //     require('../assets/serve-box/31.png'),
            //     require('../assets/serve-box/32.png'),
            //     require('../assets/serve-box/33.png'),
            //     require('../assets/serve-box/34.png'),
            //     require('../assets/serve-box/35.png'),
            //     require('../assets/serve-box/36.png'),
            //     require('../assets/serve-box/37.png'),
            //     require('../assets/serve-box/38.png'),
            //     require('../assets/serve-box/39.png'),
            //     require('../assets/serve-box/40.png'),
            //     require('../assets/serve-box/41.png'),
            //     require('../assets/serve-box/42.png'),
            //     require('../assets/serve-box/43.png'),
            //     require('../assets/serve-box/44.png'),
            //     require('../assets/serve-box/45.png'),
            //     require('../assets/serve-box/46.png'),
            //     require('../assets/serve-box/47.png'),
            //     require('../assets/serve-box/48.png'),
            //     require('../assets/serve-box/49.png'),
            //     require('../assets/serve-box/50.png'),
            //     require('../assets/serve-box/51.png'),
            //     require('../assets/serve-box/52.png'),
            //     require('../assets/serve-box/53.png'),
            //     require('../assets/serve-box/54.png'),
            //     require('../assets/serve-box/55.png'),
            //     require('../assets/serve-box/56.png'),
            //     require('../assets/serve-box/57.png'),
            //     require('../assets/serve-box/58.png'),
            //     require('../assets/serve-box/59.png'),
            //     require('../assets/serve-box/60.png'),
            //     require('../assets/serve-box/61.png'),
            //     require('../assets/serve-box/62.png'),
            //     require('../assets/serve-box/63.png'),
            //     require('../assets/serve-box/64.png'),
            //     require('../assets/serve-box/65.png'),
            //     require('../assets/serve-box/66.png'),
            //     require('../assets/serve-box/67.png'),
            //     require('../assets/serve-box/68.png'),
            //     require('../assets/serve-box/69.png'),
            //     require('../assets/serve-box/70.png'),
            //     require('../assets/serve-box/71.png'),
            //     require('../assets/serve-box/72.png'),
            //     require('../assets/serve-box/73.png'),
            //     require('../assets/serve-box/74.png'),
            //     require('../assets/serve-box/75.png'),
            //     require('../assets/serve-box/76.png'),
            //     require('../assets/serve-box/77.png'),
            //     require('../assets/serve-box/78.png'),
            //     require('../assets/serve-box/79.png'),
            //     require('../assets/serve-box/80.png'),
            //     require('../assets/serve-box/81.png'),
            //     require('../assets/serve-box/82.png'),
            //     require('../assets/serve-box/83.png'),
            //     require('../assets/serve-box/84.png'),
            // ],
            imgUrl: require("../assets/serve-box/1.png"),
            texts: [
                {title: 'Hi~欢迎访问沧州工业云！', text: '', url: ''},
                {title: '抢先申领工业互联网标识！', text: '申领试用', url: '/trial'},  
                {title: '申领成功即送平台2个月免费使用名额，先到先得！', text: '', url: ''}, 
            ],
            timer: null,
            abc: null,
            bcd: null,
            timerNum: 5
        }
    },
    methods: {
        scrollToTop() {
            $('body,html').animate({scrollTop: 0}, 1000)
        },

        start() {
            // this.abc
            // this.bcd
            var _this = this;
            var n = 0;
            var a = -1;
            
            // this.abc = setInterval(say, 50)
            this.bcd = setInterval(content, 30000)
            
            // function say() {
            //     _this.imgUrl = _this.img[n];
            //     n++;
            //     if(n > 83){
            //         n = 0;
            //         this.abc
            //     }
            // }

            function content() {
                a++;
                $('.say-box ol li').eq(a).css({'z-index': 9, 'display': 'block'})
                $('.say-box ol li').eq(a).siblings().css({'z-index': 1, 'display': 'none'})
                if(a > 1){
                    a = -1;
                    this.bcd
                }
            }

            // this.timer = setInterval(() => {
            //     if(this.timerNum === 0) {
            //         this.timer && this.abc && this.bcd && this.clearTimer();
            //     }else{
            //         this.timerNum = 5
            //     }
            // }, 10000)
        },

        // clearTimer() {
        //     clearInterval(this.timer)
        //     clearInterval(this.abc)
        //     clearInterval(this.bcd)
        //     this.timer = null
        //     this.abc = null
        //     this.bcd = null
        // }
    },
    mounted() {
        this.start()
    },
    beforeDestroy() {
        clearInterval(this.timer)
        this.timer = null
    }
}
</script>

<style scoped lang="scss">
.service-box{
    width: 110px;
    height: 416px;
    position: fixed;
    top: 50%;
    right: 10px;
    margin-top: -208px;
    z-index: 999;
    .phone{
        width: 110px;
        height: 110px;
        position: relative;
        >img{
            width: 130px;
            height: 130px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
    .say-box{
        width: 198px;
        height: 66px;
        position: absolute;
        bottom: 416px;
        right: 0px;
        ol{
            width: 198px;
            height: 66px;
            position: absolute;
            bottom: 0;
            right: 0;
            li{
                width: 168px;
                height: auto;
                padding: 10px 15px;
                position: absolute;
                bottom: 0;
                right: 0;
                background-color: #ffffff;
                font-size: 14px;
                line-height: 22px;
                border-radius: 3px;
                box-shadow: 0 0 12px rgba(0,0,0,0.15);
                display: none;
                img{
                    position: absolute;
                    bottom: -12px;
                    right: 46px;
                }
                a{
                    color: #3460f2;
                }
            }
        }
    }
    >img{
        width: 110px;
        height: 130px;
        margin-bottom: 5px;
    }
    ul{
        width: 70px;
        margin: 0 auto;
        box-shadow: 0 0 15px rgba(8,46,174,0.15);
        li{
            width: 70px;
            height: 70px;
            text-align: center;
            background-color: #ffffff;
            padding: 0 5px;
            box-sizing: border-box;
            position: relative;
            cursor: pointer;
            >div:nth-child(1){
                padding: 11px 0;
                border-bottom: 1px solid #bcbcbc;
                div{
                    width: 100%;
                    height: 21px;
                    position: relative;
                    img{
                        width: 22px;
                        height: 21px;
                        position: absolute;
                        top: 0;
                        left: 50%;
                        transform: translateX(-50%);
                    }
                    img:nth-child(2){
                        display: none;
                    }
                }
                p{
                    font-size: 12px;
                    margin-top: 10px;
                    color: #666666;
                }
            }
        }
        li:nth-child(2){
            >div:nth-child(2){
                width: 167px;
                height: 40px;
                background: url('../assets/serve-box/pic_1.png');
                padding: 10px;
                box-sizing: border-box;
                position: absolute;
                top: 50%;
                right: 80px;
                transform: translateY(-50%);
                display: none;
                div{
                    margin-right: 10px;
                    float: left;
                }
                p{
                    line-height: 21px;
                    float: left;
                }
            }
        }
        li:nth-child(3){
            >div:nth-child(2){
                position: absolute;
                top: 50%;
                right: 80px;
                transform: translateY(-50%);
                display: none;
                img{
                    width: 108px;
                    height: 100px;
                }
            }
        }
        li:last-child{
            >div:nth-child(1){
                padding-top: 15px;
                border: 0 none;
                div{
                    height: 12px;
                    img{
                        width: 17px;
                        height: 12px;
                    }
                }
            }
        }
        li:hover{
            >div:nth-child(1){
                p{
                    color: #3460f2;
                }
                div{
                    img:nth-child(1){
                        display: none;
                    }
                    img:nth-child(2){
                        display: block;
                    }
                }
            }
            >div:nth-child(2){
                display: block;
            }
        }
    }
}
</style>
