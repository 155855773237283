<template>
    <div class="collect">
        <div class="query">
            <div>
                <label>信息类型：</label>
                <el-button
                    :type="item.is_selected? 'primary':'text'"
                    size="mini"
                    v-for="(item,index) in infoType"
                    :key="item.title"
                    @click="selectoChange(index,'infoType')"
                >{{ item.title }}</el-button>
            </div>
            <div>
                <label>项目类型：</label>
                <el-button
                    :type="item.is_selected? 'primary':'text'"
                    size="mini"
                    v-for="(item,index) in projectType"
                    :key="index"
                    @click="selectoChange(index,'projectType')"
                >{{ item.typeName }}</el-button>
            </div>
            <div>
                <label>选择时间：</label>
                <el-button
                    :type="item.is_selected? 'primary':'text'"
                    size="mini"
                    v-for="(item,index) in timeChoices"
                    :key="item.title"
                    @click="selectoChange(index,'timeChoices')"
                >{{ item.title }}</el-button>
            </div>
            <div class="btns" align="right">
                <el-button type="primary" size="mini" @click="getMyCollect">查询</el-button>
            </div>
        </div>
        <div class="result">
            <el-table v-loading="loading" :data="tableData" style="width: 100%" @cell-click="clickColumn">
                <el-table-column prop="purchasePublishTime" label="日期" width="120"></el-table-column>
                <el-table-column prop="typeName" label="项目类型" width="120"></el-table-column>
                <el-table-column prop="statusName" label="信息类型" width="120"></el-table-column>
                <el-table-column prop="unit" label="中标单位" width="120"></el-table-column>
                <el-table-column prop="purchaseTitle" label="招标采购标题"></el-table-column>
                <el-table-column label="操作" width="120">
                    <template slot-scope="scope">
                        <!-- <el-link type="primary"  @click="cancelCollect(scope.row.purchaseId)">取消收藏</el-link> -->
                        <!-- <el-link type="primary" @click="addCollect(scope.row.purchaseId)">收藏</el-link> -->
                        <el-tooltip class="item" effect="dark" content="取消收藏" placement="right">
                            <span
                                class="cancelCollect el-icon-star-on"
                                @click.stop="cancelCollect(scope.row.purchaseId)"
                            ></span>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="opts">
            <!-- <div class="check">
                <el-checkbox v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <el-link :underline="false" @click="delAll()">删除</el-link>
                <el-link :underline="false" @click="addTOollect()">收藏</el-link>
            </div> -->
            <div class="pager">
                <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.current"
                    :page-size="page.size"
                    layout="total, prev, pager, next, jumper"
                    :total="page.total"
                ></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            checkAll: false,
            loading: false,
            // 0招标、1中标、4拟建项目、2变更、3废标
            infoType: [
                { title: "全部", value: "", is_selected: true },
                { title: "招标", value: 0 },
                { title: "中标", value: 1 },
                { title: "拟建项目", value: 4 },
                { title: "变更", value: 2 },
                { title: "废标", value: 3 },
            ],
            infoTypeIndex: 0,
            projectType: [{ typeName: "全部招标", id: "", is_selected: true }],
            projectTypeIndex: 0,
            timeChoices: [
                { title: "不限", is_selected: true, value: 0 },
                { title: "近一周", value: 1 },
                { title: "近一月", value: 2 },
                { title: "近三月", value: 3 },
                { title: "近半年", value: 4 },
                { title: "近一年", value: 5 },
            ],
            timeChoicesIndex: 0,
            tableData: [],
            page: {
                current: 1,
                size: 10,
                total: 0,
            },
        };
    },
    mounted() {
        this.getMyCollect();
        this.getSelectTendereeTypeList();
    },
    methods: {
        getSelectTendereeTypeList() {
            let _this = this;
            _this.$api.usercenter.getSelectTendereeTypeList({}).then((res) => {
                if (res.success) {
                    res.data.records.forEach((el) => {
                        _this.projectType.push(el);
                    });
                }
            });
        },
        clickColumn(row, column, cell, event){
            let routeUrl = this.$router.resolve({
                path: "/seeFile",
                query: { 
                    id: row.purchaseId, 
                },
            });
            window.open(routeUrl.href, "_blank");
        },
        addCollect(id) {
            let _this = this;
            _this.$api.usercenter
                .addCollect({ pId: id, collectionValue: true })
                .then((res) => {
                    if (res.success) {
                        _this.$message.success(res.msg);
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {});
        },
        cancelCollect(id) {
            let _this = this;
            _this.$api.usercenter
                .cancelCollect({ pId: id, collectionValue: false })
                .then((res) => {
                    if (res.success) {
                        _this.$message.success(res.msg);
                        _this.getMyCollect();
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {});
        },
        getMyCollect() {
            let _this = this;
            _this.loading = true;
            let data = {
                pageSize: this.page.size,
                pageNum: this.page.current,
                statusId: this.infoType[this.infoTypeIndex].value,
                typeId: this.projectType[this.projectTypeIndex].id,
                time: this.timeChoices[this.timeChoicesIndex].value,
                purchaseTitle: "",
            };
            _this.$api.usercenter
                .getMyCollect(data)
                .then((res) => {
                    _this.loading = false;
                    if (res.success) {
                        this.tableData = res.data.list;
                        this.page.total = res.data.total;
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    _this.loading = false;
                });
        },
        selectoChange(i, type) {
            this[type + "Index"] = i;
            this[type].forEach((item) => {
                if (item.is_selected) {
                    this.$delete(item, "is_selected");
                }
            });
            this.$set(this[type][i], "is_selected", true);
            // this.getMyCollect();
        },
        handleCheckAllChange() {},
        delAll() {
            this.tableData = [];
            this.checkAll = false;
        },
        addTOollect() {
            if (this.checkAll) {
                this.tableData.forEach((item) => {
                    item.is_collected = true;
                });
                this.checkAll = false;
            }
        },
        handleSizeChange(val) {
        },
        handleCurrentChange(val) {
            this.page.current = val;
            this.getMyCollect();
        },
    },
};
</script>


<style lang="sass" scoped>
.collect
.query
    > div
        margin: 16px 0

    .el-button--text
        padding: 7px 12px
.opts
    width: 100%
    margin: 16px 0
    .check
        float: left
    .el-link
        margin: 0 12px
    .pager
        float: right

.el-table
    .cell
        .cancelCollect
            color: #FCB63E
            font-size: 22px
            cursor: pointer
</style>