<template>
<div class="demo_wrapper">
  <h4>应用案例</h4>
  <el-image style="width: 100%; height: 500px" :src="require('../assets/imgs/demo.png')" :fit="'fit'"></el-image>
</div>
</template>

<script>
export default {
  name: "topdemo",
  data() {
    return{
    }
  }
}
</script>

<style scoped lang="sass">
.demo_wrapper
  background: #FFFFFF
  margin-bottom: 30px
  h4
    text-align: center
    font: 400 34px/163px 'Microsoft YaHei'
</style>