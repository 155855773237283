<template>
    <div class="register">
        <div class="register-center">
            <div class="flex">
                <p class="big-size">注册账号</p>
                <router-link :to="{path: '/login'}">登录账号</router-link>
            </div>
            <el-form ref="registerFrom" :rules="register_rules" :model="registerFrom">
                <el-form-item prop="phone">
                    <el-input v-model="registerFrom.phone" placeholder="请输入手机号"></el-input>
                </el-form-item>

                <el-form-item prop="pwd">
                    <el-input type="password" v-model="registerFrom.pwd" placeholder="请输入密码"></el-input>
                </el-form-item>

                <el-form-item prop="pwd_again">
                    <el-input
                        type="password"
                        v-model="registerFrom.pwd_again"
                        placeholder="请再次输入密码"
                    ></el-input>
                </el-form-item>
<!-- <span
                        class="phone-code"
                        @click="sendCode(registerFrom.phone)"
                    >{{text=='发送验证码'? '发送验证码': text }}</span> -->
                <!-- <el-form-item prop="code">
                    <el-input class="code" v-model="registerFrom.code" placeholder="请输入验证码"></el-input>
                    
                    <el-button class="phone-code" :class="disabled?'disabledClass':''" :disabled="disabled" type="primary" @click="sendCode(registerFrom.phone)">{{text>0? text + 's': text}}</el-button>
                </el-form-item> -->
                <el-form-item>
                    <div class="flex" style="margin-bottom: 0;">
                        <p>
                            <el-checkbox v-model="registerFrom.checked"></el-checkbox>我已阅读并同意
                            <a href>《用户协议》</a>
                        </p>
                        <router-link :to="{path: '/forgetPwd'}">忘记密码?</router-link>
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button
                        type="primary"
                        @click="onSubmit('registerFrom')"
                        v-loading.fullscreen.lock="fullscreenLoading"
                    >注册</el-button>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
var self;
import md5 from "js-md5";
export default {
    data() {
        var validatePass2 = (rule, value, callback) => {
            if (value == "") {
                callback(new Error("请再次输入密码"));
            } else if (value != this.registerFrom.pwd) {
                callback(new Error("两次输入密码不一致！"));
            } else {
                callback();
            }
        };
        var checkphone = (rule, value, callback) => {
            if (value == "") {
                callback(new Error("请输入手机号"));
            } else if (!this.common.isCellPhone(value)) {
                callback(new Error("请输入正确的手机号！"));
            } else {
                callback();
            }
        };
        var checkedUser = (rule, value, callback) => {
            console.log(value)
            if (!value) {
                callback(new Error("请勾选用户歇息！"));
            }else {
                callback();
            }
        };
        return {
            fullscreenLoading: false,
            timer: null,
            text: "发送验证码",
            timeCount: 60,
            disabled:false,
            registerFrom: {
                phone: "",
                pwd: "",
                pwd_again: "",
                code: "",
                checked: false,
            },
            register_rules: {
                phone: {
                    required: true,
                    validator: checkphone,
                    trigger: "blur",
                },
                pwd: { required: true, message: "请输入密码", trigger: "blur" },
                pwd_again: {
                    required: true,
                    validator: validatePass2,
                    trigger: "blur",
                },
                // code: {
                //     required: true,
                //     message: "请输入验证码",
                //     trigger: "blur",
                // },
                checked: {
                    required: true,
                    validator: checkedUser,
                    trigger: "blur",
                },
            },
        };
    },
    mounted() {
        self = this;
    },
    methods: {
        // 倒计时
        timeCountdown() {
            const TIME_COUNT = 60;
            this.disabled = true
            if (!this.timer) {
                this.text = TIME_COUNT;
                this.timer = setInterval(() => {
                    if (this.text > 1 && this.text <= TIME_COUNT) {
                        this.text--;
                    } else {
                        clearInterval(this.timer);
                        this.timer = null;
                        this.text = "重新发送"
                        this.disabled = false
                    }
                }, 1000)
            }
        },
        // 发送验证码
        sendCode(phoneid) {
            // if (this.timeCount > 0 && this.timeCount < 60) {
            //     return false;
            // }
            // this.timeCount--;
            if (phoneid) {
                var json = { phone: phoneid };
                this.$api.login.sendPhoneCode(json).then((res) => {
                    if (res.code == 200) {
                        this.timeCountdown();
                        this.$message.success("已发送");
                    } else {
                        this.$message.error(res.msg);
                    }
                });
            } else {
                this.$message.warning("请输入手机号");
            }
        },
        // 注册
        onSubmit(form) {
            if(this.registerFrom.checked){
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        this.fullscreenLoading = true;
                        if (this.registerFrom.checked) {
                            var json = {
                                phone: this.registerFrom.phone,
                                pwd: this.registerFrom.pwd,
                                pwdAgain: this.registerFrom.pwd_again,
                                phoneCode: this.registerFrom.code,
                            };
                            this.$api.login
                                .register(json)
                                .then((res) => {
                                    this.fullscreenLoading = false;
                                    if (res.success) {
                                        this.$message({
                                            type: "success",
                                            message: "注册成功，请登录",
                                            duration: 2000,
                                            onClose: function () {
                                                self.$router.push({
                                                    path: "/login",
                                                });
                                            },
                                        });
                                    } else {
                                        this.$message.error(res.msg);
                                    }
                                })
                                .catch((err) => {
                                    this.fullscreenLoading = false;
                                    let response = err.response;
                                    if (response.data.msg) {
                                        this.$message.error(response.data.msg);
                                    }
                                });
                        } else {
                            this.$message.warning("请勾选用户协议");
                        }
                    } else {
                        return false;
                    }
                });
            }else{
                this.$message.error('请勾选用户协议！')
            }
        },
    },
};
</script>

<style lang="scss">
.register {
    background: url("../../assets/image/login_bg.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    .el-tabs__item.is-active {
        color: #1848fd;
    }
    .el-tabs__active-bar {
        background-color: #1848fd;
    }
    .el-tabs__nav {
        width: 100%;
        height: 65px;
        line-height: 66px;
        border-bottom: 1px solid #eeeeee;
        .el-tabs__item {
            width: 50%;
            padding: 0;
        }
    }
    .el-form-item {
        margin-bottom: 25px;
    }
    .el-input__inner {
        width: 100% !important;
        height: 40px !important;
    }
    .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--bottom .el-tabs__item.is-top:nth-child(2),
    .el-tabs--top .el-tabs__item.is-bottom:nth-child(2),
    .el-tabs--top .el-tabs__item.is-top:nth-child(2) {
        text-align: center;
        padding: 0 !important;
    }
    .el-tabs--bottom .el-tabs__item.is-bottom:last-child,
    .el-tabs--bottom .el-tabs__item.is-top:last-child,
    .el-tabs--top .el-tabs__item.is-bottom:last-child,
    .el-tabs--top .el-tabs__item.is-top:last-child {
        text-align: center;
        padding: 0 !important;
    }
    .el-checkbox__inner {
        margin-right: 4px;
        border: 2px solid #757f92;
        border-radius: 50%;
        width: 14px;
        height: 14px;
    }
    .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #1848fd;
        border-color: #1848fd;
    }
    .el-form-item__content {
        width: 100%;
        .el-button--primary {
            width: 100%;
            background-color: #1848fd;
            color: #ffffff;
        }
    }
}
</style>

<style lang="scss" scoped>
.register {
    background-color: #cccccc;
    height: 100%;
    position: relative;
    .register-center {
        width: 460px;
        height: 521px;
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        right: 300px;
        margin-top: -260px;
        padding: 45px 48px;
        box-sizing: border-box;
        .flex {
            display: flex;
            justify-content: space-between;
            margin-bottom: 40px;
            font-size: 14px;
            a {
                color: #1848fd;
            }
            .big-size {
                font-size: 16px;
            }
        }
        .code {
            width: 245px;
            margin-right: 10px;
        }
        img {
            width: 106px;
            height: 40px;
        }
        .phone-code {
            display: inline-block;
            width: 106px;
            height: 40px;
            color: #ffffff;
            background-color: #1848fd;
            text-align: center;
            cursor: pointer;
        }
        .disabledClass{
            background: #a0cfff;
        }
    }
}
</style>