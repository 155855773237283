<!--
 * @Author: your name
 * @Date: 2021-06-21 08:20:20
 * @LastEditTime: 2021-07-06 15:06:19
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \ruianda\src\components\UploadImgs.vue
-->
<template>
    <div class="uploadimgs">
        <el-upload
        :action="serverUrl"
        :headers= "headers"
        list-type="picture-card"
        :file-list="fileList"
        accept= "image/png,image/jpg,image/jpeg"
        :before-upload= "beforeAvatarUpload"
        :on-preview="handlePictureCardPreview"
        :on-remove="(file,fileList)=>handleContImgRemove(childMore,file,fileList)"
        :on-success="(response,file)=>handleAvatarSuccess(childMore,response, file)"
        >
            <el-button size="small" type="primary">图片上传</el-button>
        </el-upload>
        <span>请上传清晰彩色原件</span>
        <b>上传格式(.jpg/.png)</b>
        <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
import { baseUrl } from '../request/http'
import store from '../store/index'
import Cookies from 'js-cookie';
export default {
    data() {
        return {
            serverUrl: baseUrl + 'oss/ossupload',
            dialogImageUrl: '',
            dialogVisible: false,
            productPicPathList: [],
            headers: {
                'Blade-Auth':Cookies.get('token')
                // authorization: store.state.userInfo.token,
            }
        }
    },
    props: {
        childMore: String,
        fileList:Array
    },
    mounted(){
        this.serverUrl = "//27.128.115.201:8/blade-resource/oss/endpoint/put-file"
    },
    methods: {
        beforeAvatarUpload: function(file){
            const isJPG = file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg'
            const isLt5M = file.size / 1024 / 1024 < 5
            if(!isJPG){
                this.$message.error('图片应为.jpg/.png/jpeg格式！')
            }

            if(!isLt5M){
                this.$message.error('图片大小不能超过1MB！')
            }

            return isJPG && isLt5M;
        },
        handlePictureCardPreview: function(file){
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },
        handleContImgRemove: function(imgSrcMore, file){
            for(var i = 0; i < this.fileList.length; i++){
                if(file.uid == this.fileList[i].uid){
                    this.fileList.splice(i,1)
                    this.$emit('getMoreSonUrl', this.fileList, imgSrcMore)
                    return
                }
            }
            
        },
        handleAvatarSuccess: function(imgSrcMore, response, file){
            let data = response.data
            data.url = data.link
            this.fileList.push(response.data)
            this.$emit('getMoreSonUrl', this.fileList, imgSrcMore)
        }, 
    }
}
</script>

<style lang="scss">

</style>