import * as login from './moudle/login' // 登录
import * as bidding from './moudle/bidding' // 投标
import * as news from './moudle/news' // 新闻
import * as usercenter from './moudle/usercenter' // 个人中心
import * as supplier from './moudle/supplier' // 供应商入驻
import * as product from './moudle/product' // 产品
import * as solution from './moudle/solution' // 解决方案
import * as fixedpage from './moudle/fixedpage' // 单页接口
import * as index from './moudle/index' // 首页接口
export default {
    login,
    bidding,
    news,
    usercenter,
    supplier,
    product,
    solution,
    fixedpage,
    index,
}