<template>
    <div class="new-details">
        <nav-bar></nav-bar>
        <div class="news-detail">
            <div class="news-detail-center">
                <div class="crumb">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item>首页</el-breadcrumb-item>
                        <el-breadcrumb-item><router-link :to="{path: '/govHome'}">政策资讯</router-link></el-breadcrumb-item>
                        <el-breadcrumb-item><router-link :to="{path: '/govNewsList', query: {classId: $route.query.topClass}}">{{govClass}}</router-link></el-breadcrumb-item>
                        <el-breadcrumb-item><router-link :to="{path: '/govNewsList', query: {classId: $route.query.classId}}">{{newsClass}}</router-link></el-breadcrumb-item>
                        <el-breadcrumb-item>{{newsDetails.name}}</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="news-detail-content">
                    <h4>{{newsDetails.name}}</h4>
                    <p>{{newsDetails.createTime}} &nbsp;&nbsp;&nbsp;来源：{{newsDetails.orgin?newsDetails.orgin: '网络'}}</p>
                    <div v-html="newsDetails.content"></div>
                </div>
            </div>
            <div class="article">
                <a @click="RouteJumpPrev(prevNews.policyId)">上一篇：{{prevNews.policyName}}</a>
                <a @click="RouteJumpNext(nextNews.policyId)">下一篇：{{nextNews.policyName}}</a>
            </div>
        </div>
    </div>
</template>

<script>
// import navBar from '@/components/navBar'
export default {
    data() {
        return{
            newsDetails: '',
            newsClass: '',
            govClass: '',
            prevNews: {
                policyName: '',
                policyId: ''
            },
            nextNews: {
                policyName: '',
                policyId: ''
            }
        }
    },
    components: {
       // navBar
    },
    methods: {
        getNewsDetail() {
            var json = {
                id: this.$route.query.id
            }
            this.$api.news.govNewsDetails(json).then(res => {
                if(res.success){
                    this.newsDetails = res.data
                }else{
                    this.$message.error(res.msg)
                }
            })
        },
        // 获取二级分类名称
        getGovClassName() {
            var  json = {
                id: this.$route.query.classId
            }
            this.$api.news.govClassName(json).then(res => {
                if(res.code == 200){
                    this.newsClass = res.data.className
                }
            })
        },

        // 获取一级分类名称
        getGovOneClassName() {
            var  json = {
                id: this.$route.query.topClass
            }
            this.$api.news.govClassName(json).then(res => {
                if(res.code == 200){
                    this.govClass = res.data.className
                }
            })
        },

        // 获取上一篇文章
        getGovNewsPrev() {
            var json = {
                sign: 1,
                classId: this.$route.query.classId,
                id: this.$route.query.id
            }
            this.$api.news.govNewsPrevNext(json).then(res => {
                if(res.success){
                    this.prevNews = res.data
                }else{
                    this.prevNews.policyName = res.msg
                }
            })
        },

        // 获取下一篇文章
        getGovNewsNext() {
            var json = {
                sign: 2,
                classId: this.$route.query.classId,
                id: this.$route.query.id
            }
            this.$api.news.govNewsPrevNext(json).then(res => {
                if(res.success){
                    this.nextNews = res.data
                }else{
                    this.nextNews.policyName = res.msg
                }
            })
        },
        // 跳转上一篇
        RouteJumpPrev(id) {
            if(id != ''){
                this.$router.push({
                    path:'/govNewsDetails',
                    query: {
                        topClass: this.$route.query.topClass,
                        classId: this.$route.query.classId,
                        id: id, 
                    }
                })
                this.getNewsDetail(id)
                this.getGovNewsPrev()
                this.getGovNewsNext()
            }
        },
        // 跳转下一篇
        RouteJumpNext(id) {
            if(id != ''){
                this.$router.push({
                    path:'/govNewsDetails',
                    query: {
                        topClass: this.$route.query.topClass,
                        classId: this.$route.query.classId,
                        id: id, 
                    }
                })
                this.getNewsDetail(id)
                this.getGovNewsPrev()
                this.getGovNewsNext()
            }
        },
    },
    mounted() {
        this.getNewsDetail()
        this.getGovClassName()
        this.getGovOneClassName()
        this.getGovNewsPrev()
        this.getGovNewsNext()
    },
}
</script>

<style lang="scss">
.new-details{
    .crumb{
        .el-breadcrumb__inner a, .el-breadcrumb__inner.is-link{
            font-weight: 500;
            color: #0B1D30;
        }
        .el-breadcrumb__item:last-child .el-breadcrumb__inner, .el-breadcrumb__item:last-child .el-breadcrumb__inner a, .el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover, .el-breadcrumb__item:last-child .el-breadcrumb__inner:hover{
            color: #C7000B;
        }
    }
}
</style>

<style lang="scss" scoped>
.new-details{
    .news-detail{
        width: 100%;
        background-color: #eef0f5;
        .news-detail-center{
            width: 1200px;
            margin: 0 auto;
            padding-bottom: 40px;
            .crumb{
                padding: 30px 0;
            }
            .news-detail-content{
                background-color: #ffffff;
                padding: 80px;
                >h4{
                    font-size: 24px;
                }
                >h4, >p{
                    text-align: center;
                    margin-bottom: 10px;
                }
                >div{
                    font-size: 14px;
                    line-height: 28px;
                }
            }
        }
    }
    .article{
        width: 1200px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        padding-bottom: 60px;
        a{
            cursor: pointer;
        }
    }
}
</style>
