<template>
  <div class="value_wrapper">
    <h4>应用价值</h4>
    <el-row v-for="row in 2" :key="'row' + row" :gutter="20">
      <el-col :span="8" v-for="(item,col) in cardList.slice((row - 1) * 3, row * 3)" :key="'row' + row + 'col' + col">
        <el-card class="box-card" shadow="always">
          {{ item.title }}
          <i class="el-icon-arrow-right"></i>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "value",
  data() {
    return {
      cardList: [
        {title: '自定义线索分配'}, {title: '360°客户视图、标签化管理'}, {title: '销售管理可视化、智能决策'}, {title: '全渠道订单管理'}, {title: '统一支付平台'}, {title: '智能质检‘规范销售平台'}
      ]
    }
  }
}
</script>

<style scoped lang="sass">
.value_wrapper
  margin: 0 360px 30px

  h4
    text-align: center
    font: 400 34px/163px 'Microsoft YaHei'

  /deep/ .el-row
    margin-bottom: 10px

    .box-card
      color: #757F92
      line-height: 37px

      i
        float: right
        line-height: 37px
</style>