<template>
    <div class="solution-details">
        <!-- banner -->
        <div
            class="solution-module module-banner"
            :style="{backgroundImage:'url(' + bannerBg +')'}"
        >
            :
            <!-- <div class="module-center"> -->
            <!-- <div class="banner-title">{{detail.title}}</div>
                <div class="banner-desc">{{detail.description}}</div>
            <div class="banner-button">立即咨询</div>-->
            <!-- </div> -->
            <a style="display:block;width:100%;height:100%;" href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false"></a>
        </div>
        <!-- <div class="solution-module">
            <div class="module-center">
                <div class="module-title">方案价值</div>
                <img src="../../assets/image/solution_detail_pic_1.jpg" alt />
                <div class="module-title">方案架构</div>
                <img src="../../assets/image/solution_detail_pic_2.png" alt />
            </div>
        </div>-->
        <!-- 相关产品 -->

        <div v-html="detail.content" style="padding-top:50px;"></div>
        <div class="solution-module ">
            <div class="module-center">
                <div class="module-title">相关产品</div>
                <div class="module-select">
                    <el-row :gutter="20">
                        <el-col :span="6" v-for="(item,index) in product" :key="index">
                            <div
                                class="select-item"
                                @mouseenter="item.show=true"
                                @mouseleave="item.show=false"
                                @click="toUrl({path:'/SoftwareMarket/details',query:{id:item.id}})"
                                :style="{backgroundImage:'url('+item.recommended+')'}"
                            >
                                <div class="select-item-name">
                                    <!-- {{item.name}} -->
                                    <!-- <span class="el-icon-arrow-right"></span> -->
                                </div>
                                <!-- <div
                                    class="select-item-desc"
                                    v-for="(d,i) in item.desc"
                                    :key="i"
                                >{{d}}</div> -->
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
        
        <!-- 经典案例 -->
        <div class="solution-module solution-anli ">
            <div class="module-center">
                <div class="module-title">经典案例</div>
            </div>
            <div class="module-classic" style="padding-top: 80px;
    box-sizing: border-box;" :style="{backgroundImage:'url(' + detail.caseImage +')'}"> 
    <!-- :src="detail.caseImage" -->
                <!-- <img
                    src="../../assets/image/solution_detail_pic_4.png"
                    style="width:1200px;display:block;margin:auto;"
                    alt
                /> -->
            </div>
        </div>
        
        <!-- 联系我们 -->
        <div class="solution-module bg_FFFFFF">
            <div class="module-center">
                <div class="module-title">联系我们</div>
            </div>
            <div class="module-contact" :style="{backgroundImage:'url(' + contactImage +')'}">
                <!-- <h4>{{cantact.title}}</h4> -->
            </div>
        </div>
        <!-- 其他解决方案 -->
        <div class="solution-module bg_FFFFFF">
            <div class="module-center">
                <div class="module-title">其他解决方案</div>
                <div class="module-select module-other">
                    <el-row :gutter="15">
                        <el-col :span="6" v-for="(item,index) in otherSolution" :key="index">
                            <div
                                class="select-item"
                                @mouseenter="item.show=true"
                                @mouseleave="item.show=false"
                                :style="{backgroundImage:'url('+item.image+')'}"
                                @click="toUrl({path:'/solutionDetail',query:{id:item.id}})"
                            >
                                <div class="select-item-name">
                                    {{item.title}}
                                    <span class="el-icon-arrow-right"></span>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import softwareMarketNav from "@/components/software-market-nav";
export default {
    data() {
        return {
            id: 0,
            banner: null,
            bannerBg: "",
            contactImage: "",
            product: [
                {
                    id: 1,
                    name: "标识解析应用服务",
                    desc: ["集成海量工业应用", "打造应用市场生态"],
                    hot: 1,
                    price: "3000",
                    time: "年",
                    show: false,
                },
                {
                    id: 2,
                    name: "5G边缘计算器",
                    desc: ["集成海量工业应用", "打造应用市场生态"],
                    hot: 1,
                    price: "3000",
                    time: "年",
                    show: false,
                },
                {
                    id: 3,
                    name: "工业知识图谱",
                    desc: ["集成海量工业应用", "打造应用市场生态"],
                    hot: 1,
                    price: "3000",
                    time: "年",
                    show: false,
                },
                {
                    id: 4,
                    name: "工业大数据平台",
                    desc: ["集成海量工业应用", "打造应用市场生态"],
                    hot: 1,
                    price: "3000",
                    time: "年",
                    show: false,
                },
            ],
            detail: {
                title: "",
                content: "",
            },
            otherSolution: [],
        };
    },
    components: {
        softwareMarketNav,
    },
    mounted() {
        let id = this.$route.query.id;
        this.id = id;
        this.getDetail(id);
        this.getOtherProduct(id);
    },
    methods: {
        getDetail(id) {
            let _this = this;
            _this.$api.solution
                .getDetail({
                    id,
                })
                .then((res) => {
                    if (res.success) {
                        _this.bannerBg = res.data.bannerImage;
                        _this.contactImage = res.data.contactImage;
                        _this.detail = res.data;
                        _this.getOtherSolution(res.data.id);
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    let response = err.response;
                    if (response.status == 400) {
                        this.$message.error(response.data.error_description);
                    }
                    if (response.status == 500) {
                        this.$message.error(response.data.msg);
                    }
                });
        },
        getOtherProduct(id) {
            let _this = this;
            _this.$api.solution
                .getOtherProduct({
                    id,
                })
                .then((res) => {
                    if (res.success == true) {
                        _this.product = res.data;
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        toUrl(obj) {
            if (!obj) {
                return;
            }
            let routeUrl = this.$router.resolve(obj);
            window.open(routeUrl.href, "_blank");
            // this.$router.push(obj);
        },
        getOtherSolution(id) {
            let _this = this;
            _this.$api.solution
                .getOtherSolution({
                    id,
                })
                .then((res) => {
                    if (res.success == true) {
                        let list = res.data;
                        _this.otherSolution = list;
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    let response = err.response;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.bg_F5F7FA {
    background-color: #f5f7fa;
}
.bg_FFFFFF {
    background-color: #fff;
}
.solution-details {
    padding-bottom: 90px;
}
.module-classic {
    // background: url("../../assets/image/solution_detail_bg_1.jpg") no-repeat;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}
.solution-module {
    .module-center {
        width: 1200px;
        margin: 0 auto;
        .module-title {
            font-size: 34px;
            font-weight: 400;
            color: #0b1d30;
            line-height: 32px;
            padding: 90px 0 60px;
            text-align: center;
        }
        .c_ffffff {
            color: #fff;
        }
        // 精选解决方案
        .module-select {
            .select-item {
                box-shadow: 0 0 15px #eee;
                padding: 50px 30px;
                cursor: pointer;
                box-sizing: border-box;
                position: relative;
                background-color: #fff;
                background-repeat: no-repeat;
                background-position: center;
                background-size: cover;
                height: 200px;
                box-sizing: border-box;
                .select-item-name {
                    font-size: 18px;
                    color: #fff;
                    margin-bottom: 10px;
                    .el-icon-arrow-right {
                        color: #fff;
                    }
                }
                .select-item-desc {
                    font-size: 14px;
                    color: #757f92;
                    line-height: 2;
                    i {
                        color: #2cb86a;
                    }
                }
            }
        }

        // 其他解决方案
        .module-other {
            .select-item {
                background-repeat: no-repeat;
                height: 190px;
                background-position: center;
                background-size: cover;
            }
        }
    }
    //经典案例
    .module-classic {
        height: 780px;
        width: 100%;
        img {
            width: 100%;
        }
    }
}
// 顶部banner
.module-banner {
    height: 540px;
    width: 100%;
    cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;
    padding-top: 155px;
    .module-center {
        padding-left: 40px;
        .banner-title {
            font-size: 42px;
            font-weight: bold;
            color: #fff;
            margin-bottom: 30px;
        }
        .banner-mtitle {
            font-size: 24px;
            color: #fff;
            margin-bottom: 20px;
        }
        .banner-desc {
            width: 620px;
            font-size: 16px;
            font-family: Microsoft YaHei UI-Regular, Microsoft YaHei UI;
            font-weight: 400;
            color: #ffffff;
            line-height: 26px;
        }
        .banner-button {
            width: 180px;
            height: 60px;
            text-align: center;
            line-height: 60px;
            background: #006fff;
            font-size: 16px;
            color: #ffffff;
            cursor: pointer;
        }
    }
}
// 联系我们
.module-contact {
    padding-top: 72px;
    height: 240px;
    box-sizing: border-box;
    background-color: #eee;
    // background: url("../../assets/image/solution_detail_pic_5.jpg") no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    h4 {
        font-weight: normal;
        text-align: center;
        font-size: 34px;
        font-weight: 400;
        color: #ffffff;
        line-height: 40px;
        letter-spacing: 3px;
    }
}
</style>
