import axios from '../http';
import {an,meng,api} from '../global'
// 发送手机验证码 an
export const sendPhoneCode = (params) => {
    return axios({
        url: api+ '/blade-registerlogin/bladeUser/sendMessage',
        method: 'get',
        params
    })
}

// 图片验证码
export const getPhotoCode = (params) => {
    return axios({
        url: api+ '/blade-auth/oauth/captcha',
        method: 'get',
        params
    })
}

// 注册
export const register = (data) => {
    return axios({
        url: api+ '/blade-registerlogin/bladeUser/saveUser',
        method: 'post',
        data
    })
}

// 登录
export const login = (params,headers) => {
    return axios({
        url: api+ '/blade-auth/oauth/token',
        method: 'post',
        params,
        headers
    })
}

// 忘记密码（找回密码）
export const getForgetPwd = (data) => {
    return axios({
        url: api+ '/blade-registerlogin/bladeUser/findPwd',
        method: 'post',
        data
    })
}

// 退出登录
export const outLogin = (params) => {
    return axios({
        url: api+ '/blade-auth/oauth/logout',
        method: 'get',
        params
    })
}

// 修改登录密码
export const modifyPass = (params) => {
    return axios({
        url: api+ '/blade-personalCenter/account/updatePassword',
        method: 'post',
        params
    })
}

// 修改手机
export const modifyTel = (params) => {
    return axios({
        url: api+ '/blade-personalCenter/account/updatePhone',
        method: 'post',
        params
    })
}
// 修改邮箱
export const modifyEmail = (params) => {
    return axios({
        url: api+ '/blade-personalCenter/account/updateEmail',
        method: 'post',
        params
    })
}

// 查询个人信息
export const queryIngo = (params) => {
    return axios({
        url: api+ '/blade-personalCenter/userDetail/info',
        method: 'get',
        params
    })
}
