<template>
    <!-- 工业供采首页 -->
    <div class="industryforMining">
        <industrial-nav-bar></industrial-nav-bar>

        <div class="industryIndex">
            <div class="one">
                <div class="center">
                    <div class="left">
                        <div class="left-t">
                            <el-carousel height="412px" :autoplay="true">
                                <el-carousel-item v-for="item in 4" :key="item">
                                    <h3 class="small">{{ item }}</h3>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                        <div class="left-b">
                            <ul>
                                <li>通讯行业</li>
                                <li>矿业行业</li>
                                <li>水泥行业</li>
                                <li>电力行业</li>
                                <li>化工行业</li>
                            </ul>
                        </div>
                    </div>
                    <div class="right">
                        <div class="right-t">
                            <div class="user">
                                <img src="" alt="">
                                <div>
                                    <h6>Hi~欢迎您！</h6>
                                    <p>产品经理李纯洁</p>
                                </div>
                            </div>
                            <div class="nav">
                                <el-tabs v-model="activeName" @tab-click="handleClick">
                                    <el-tab-pane label="采购" name="first">
                                        <ul>
                                            <li>
                                                <img src="" alt="">
                                                <p>采购单</p>
                                            </li>
                                            <li>
                                                <img src="" alt="">
                                                <p>订单</p>
                                            </li>
                                            <li>
                                                <img src="" alt="">
                                                <p>购物车</p>
                                            </li>
                                            <li>
                                                <img src="" alt="">
                                                <p>消息管理</p>
                                            </li>
                                            <li>
                                                <img src="" alt="">
                                                <p>我的足迹</p>
                                            </li>
                                            <li>
                                                <img src="" alt="">
                                                <p>我的收藏</p>
                                            </li>
                                        </ul>
                                    </el-tab-pane>
                                    <el-tab-pane label="供需" name="second">
                                        <ul>
                                            <li>
                                                <img src="" alt="">
                                                <p>采购单</p>
                                            </li>
                                            <li>
                                                <img src="" alt="">
                                                <p>订单</p>
                                            </li>
                                            <li>
                                                <img src="" alt="">
                                                <p>购物车</p>
                                            </li>
                                            <li>
                                                <img src="" alt="">
                                                <p>消息管理</p>
                                            </li>
                                            <li>
                                                <img src="" alt="">
                                                <p>我的足迹</p>
                                            </li>
                                            <li>
                                                <img src="" alt="">
                                                <p>我的收藏</p>
                                            </li>
                                        </ul>
                                    </el-tab-pane>
                                </el-tabs>
                            </div>
                            <p class="more">更多功能请前往<router-link :to="{path: 'user'}" target="_blank">个人中心</router-link></p>
                        </div>
                        <div class="right-b">
                            <div class="notice">
                                <h6>公告</h6>
                                <a href="">更多</a>
                            </div>

                            <el-carousel height="48px" direction="vertical" :autoplay="true" indicator-position="none" arrow="never">
                                <el-carousel-item height="24px" v-for="(item,index) in lastNoticeList" :key="index + 'notice'">
                                    <a href="" style="display: block;font-size: 12px; height: 24px; line-height: 24px; text-align: left;color: #757F92;" class="medium" v-for="(itemson, indexson) in item" :key="indexson + 'son'">{{ itemson.title }}</a>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                </div>
            </div>

            <div class="two">
                <div class="center">
                    <div class="top">
                        <h3>热门求购</h3>
                        <ul>
                            <li><a href="">集合竞价</a></li>
                            <li><a href="">定制比价</a></li>
                            <li><a href="">快速求购</a></li>
                            <li><a href="">招标</a></li>
                        </ul>
                    </div>
                    <div class="bottom">
                        <div class="bottom-l">
                            <ul>
                                <li class="show"><span class="iconfont icon-image5">汽车行业</span></li>
                                <li><span class="iconfont icon-image5">汽车行业</span></li>
                                <li><span class="iconfont icon-image5">汽车行业</span></li>
                                <li><span class="iconfont icon-image5">汽车行业</span></li>
                                <li><span class="iconfont icon-image5">汽车行业</span></li>
                                <li><span class="iconfont icon-image5">汽车行业</span></li>
                            </ul>
                        </div>
                        <div class="bottom-r">
                            <ul>
                                <li class="show"><a href="">
                                    <span>招</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>定</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>集</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>招</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>定</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>集</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>招</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>定</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>集</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>招</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>定</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>集</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>招</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>定</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                                <li><a href="">
                                    <span>集</span>
                                    <div>
                                        <p><span>LCD显示屏 等一种</span> <span>报价中</span></p>
                                        <p><span>石家庄昆仑新奥燃气有...</span> <span>3家报价</span></p>
                                    </div>
                                </a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="three">
                <div class="center">
                    <div class="top">
                        <h3>闲置物资</h3>
                        <a href="">查看更多</a>
                    </div>
                    <div class="bottom">
                        <el-carousel height="383px" direction="horizontal" :autoplay="false" indicator-position="outside" arrow="never">
                            <el-carousel-item height="383px" v-for="(item,index) in lastMaterialList" :key="index + 'notice'">
                                <div class="meList" v-for="(itemSon, indexSon) in item" :key="indexSon + 'material'"><a href="">
                                    <img :src="itemSon.imgUrl" alt="">
                                    <p class="first"><span>协商价</span> <i>库存{{itemSon.stock}}台</i></p>
                                    <h6>{{itemSon.name}}</h6>
                                    <p>物料编号：{{itemSon.number}}</p>
                                    <p>品牌：{{itemSon.brandLand}}</p>
                                    <p>区域：{{itemSon.area}}</p>
                                    <div><span>{{itemSon.newDegree}}成新</span></div>
                                </a></div>
                            </el-carousel-item>
                        </el-carousel>
                        <!-- <ul>
                            <template v-for="(item) in lastMaterialList">
                            <li v-for="(itemSon, indexSon) in item" :key="indexSon + 'material'"><a href="">
                                <img :src="itemSon.imgUrl" alt="">
                                <p class="first"><span>协商价</span> <i>库存{{itemSon.stock}}台</i></p>
                                <h6>{{itemSon.name}}</h6>
                                <p>物料编号：{{itemSon.number}}</p>
                                <p>品牌：{{itemSon.brandLand}}</p>
                                <p>区域：{{itemSon.area}}</p>
                                <div><span>{{itemSon.newDegree}}成新</span></div>
                            </a></li>
                            </template>
                        </ul> -->
                    </div>
                </div>
            </div>

            <div class="four">
                <div class="center">
                    <h3>人气企业</h3>
                    <ul>
                        <li><img src="" alt=""></li>
                        <li><img src="" alt=""></li>
                        <li><img src="" alt=""></li>
                        <li><img src="" alt=""></li>
                        <li><img src="" alt=""></li>
                        <li><img src="" alt=""></li>
                        <li><img src="" alt=""></li>
                        <li><img src="" alt=""></li>
                    </ul>
                </div>
            </div>

            <div class="five">
                <div class="center">
                    <h3><img src="" alt="">精选行业分类</h3>
                    <div class="industry-list">
                        <div>
                            <div class="left">
                                <h4>行业名称<img src="" alt=""></h4>
                                <ul>
                                    <li class="show">呼吸防护</li>
                                    <li>眼睑部防护</li>
                                    <li>听力防护</li>
                                    <li>手部防护</li>
                                </ul>
                            </div>
                            <div class="right">
                                <ul>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="left">
                                <h4>行业名称<img src="" alt=""></h4>
                                <ul>
                                    <li class="show">呼吸防护</li>
                                    <li>眼睑部防护</li>
                                    <li>听力防护</li>
                                    <li>手部防护</li>
                                </ul>
                            </div>
                            <div class="right">
                                <ul>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="left">
                                <h4>行业名称<img src="" alt=""></h4>
                                <ul>
                                    <li class="show">呼吸防护</li>
                                    <li>眼睑部防护</li>
                                    <li>听力防护</li>
                                    <li>手部防护</li>
                                </ul>
                            </div>
                            <div class="right">
                                <ul>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                        <div>
                            <div class="left">
                                <h4>行业名称<img src="" alt=""></h4>
                                <ul>
                                    <li class="show">呼吸防护</li>
                                    <li>眼睑部防护</li>
                                    <li>听力防护</li>
                                    <li>手部防护</li>
                                </ul>
                            </div>
                            <div class="right">
                                <ul>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                    <li><a href="">
                                        <img src="" alt="">
                                        <p>3M 9501+折叠式防颗粒物口罩</p>
                                        <h6><span>¥</span>102</h6>
                                    </a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="six">
                <div class="center">
                    <div class="top">
                        <h3>场景采购推荐</h3>
                        <a href="">查看更多</a>
                    </div>
                    <div class="bottom">
                        <h5>汽车制造行业</h5>
                        <img src="" alt="">
                    </div>
                </div>
            </div>

            <div class="seven">
                <div class="center">
                    <div>
                        <img src="" alt="">
                        <div>
                            <el-tabs v-model="activeName1" @tab-click="handleClick">
                                <el-tab-pane label="买家认证" name="first">
                                    <ul>
                                        <li><a href="">采购单</a></li>
                                        <li><a href="">采购单</a></li>
                                        <li><a href="">采购单</a></li>
                                        <li><a href="">采购单</a></li>
                                        <li><a href="">采购单</a></li>
                                    </ul>
                                </el-tab-pane>
                                <el-tab-pane label="交易问题" name="second">
                                    <ul>
                                        <li><a href="">采购单1</a></li>
                                        <li><a href="">采购单1</a></li>
                                        <li><a href="">采购单1</a></li>
                                        <li><a href="">采购单1</a></li>
                                        <li><a href="">采购单1</a></li>
                                    </ul>
                                </el-tab-pane>
                                <el-tab-pane label="采购活动" name="third">
                                    <ul>
                                        <li><a href="">采购单2</a></li>
                                        <li><a href="">采购单2</a></li>
                                        <li><a href="">采购单2</a></li>
                                        <li><a href="">采购单2</a></li>
                                        <li><a href="">采购单2</a></li>
                                    </ul>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                    <div>
                        <img src="" alt="">
                        <div>
                            <el-tabs v-model="activeName1" @tab-click="handleClick">
                                <el-tab-pane label="卖家认证" name="first">
                                    <ul>
                                        <li><a href="">采购单</a></li>
                                        <li><a href="">采购单</a></li>
                                        <li><a href="">采购单</a></li>
                                        <li><a href="">采购单</a></li>
                                        <li><a href="">采购单</a></li>
                                    </ul>
                                </el-tab-pane>
                                <el-tab-pane label="交易问题" name="second">
                                    <ul>
                                        <li><a href="">采购单1</a></li>
                                        <li><a href="">采购单1</a></li>
                                        <li><a href="">采购单1</a></li>
                                        <li><a href="">采购单1</a></li>
                                        <li><a href="">采购单1</a></li>
                                    </ul>
                                </el-tab-pane>
                                <el-tab-pane label="采购活动" name="third">
                                    <ul>
                                        <li><a href="">采购单2</a></li>
                                        <li><a href="">采购单2</a></li>
                                        <li><a href="">采购单2</a></li>
                                        <li><a href="">采购单2</a></li>
                                        <li><a href="">采购单2</a></li>
                                    </ul>
                                </el-tab-pane>
                            </el-tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import industrialNavBar from '../../components/industrial-nav-bar'
export default{
    data() {
        return{
            tabPosition: 'left',
            activeName: 'first',
            activeName1: 'first',
            noticeList: [{   // 获取公告列表
                title: '新奥阳光智采2021年～2022年度户内进口波纹...',
                id: 1
            },{
                title: '燃气企业户内燃气安全产品组合的方案意见...',
                id: 2
            },{
                title: '新奥阳光智采2021年～2022年度户内进口波纹1...',
                id: 3
            },{
                title: '燃气企业户内燃气安全产品组合的方案意见2...',
                id: 4
            },{
                title: '新奥阳光智采2021年～2022年度户内进口波纹...',
                id: 1
            },{
                title: '燃气企业户内燃气安全产品组合的方案意见...',
                id: 2
            },{
                title: '新奥阳光智采2021年～2022年度户内进口波纹1...',
                id: 3
            },{
                title: '燃气企业户内燃气安全产品组合的方案意见2...',
                id: 4
            }], 
            lastNoticeList: [],   // 展示公告列表
            materialList: [{      // 获取闲置物质列表
                imgUrl: '', // 产品图路径
                stock: '30',  // 库存
                name: '控温式增压器_300NM3/h',   // 物料名称
                number: '197022', // 编号
                brandLand: '新地', // 品牌地
                area: '广西壮族自治区北海市', // 区域
                newDegree: '八', // 新的程度
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            },{
                imgUrl: '',
                stock: '30',
                name: '控温式增压器_300NM3/h',
                number: '197022',
                brandLand: '新地',
                area: '广西壮族自治区北海市',
                newDegree: '八', 
            }], 
            lastMaterialList: []  // 展示闲置物质列表
        }
    },
    components: {
        industrialNavBar
    },
    methods: {
      handleClick(tab, event) {
      },
      group(array,subGroup, newarr){
          var index = 0
          while(index < array.length){
            newarr.push(array.slice(index, (index += subGroup)))
          }
          return newarr
      },

    },
    mounted() {
        this.group(this.noticeList, 2, this.lastNoticeList)
        this.group(this.materialList, 6, this.lastMaterialList)
    }
}
</script>

<style lang="scss">
.el-carousel__button{
    width: 8px !important;
    height: 8px !important;
    border-radius: 4px !important;
    background-color: #757f92 !important;
    opacity: 1 !important;
}
.el-carousel__indicator.is-active button{
    width: 14px !important;
    background-color: #006FFF !important;
}
.el-carousel__indicators--outside button{
    width: 8px !important;
    height: 8px !important;
    border-radius: 4px !important;
    background-color: #757f92 !important;
    opacity: 1 !important;
}

.industryIndex{
    .three, .seven{
        .el-carousel--horizontal{
            padding-top: 5px;
            padding-left: 5px;
            margin-left: -5px;
            background-color: #f5f7fa;
        }
        .el-carousel__item{
            overflow: visible !important;
        }
    }
    .seven{
        .el-tabs__header{
            .el-tabs__nav-wrap.is-scrollable{
                padding: 0;
            }
        }
        .el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2), .el-tabs--bottom .el-tabs__item.is-top:nth-child(2), .el-tabs--top .el-tabs__item.is-bottom:nth-child(2), .el-tabs--top .el-tabs__item.is-top:nth-child(2){
            padding-left: 0 !important;
            padding-right: 32px !important;
        }
        .el-tabs--bottom .el-tabs__item.is-bottom:last-child, .el-tabs--bottom .el-tabs__item.is-top:last-child, .el-tabs--top .el-tabs__item.is-bottom:last-child, .el-tabs--top .el-tabs__item.is-top:last-child{
            padding-left: 32px !important;
        }
    }
}
</style>

<style lang="scss" scoped>
.industryIndex{
    text-align: left;
    h3{
        font-weight: 500;
        font-size: 28px;
        color: #0B1D30;
    }
    .center{
        width: 1200px;
        margin: 0 auto;
    }
    .one{
        background-color: #f5f7fa;
        padding: 10px 0;
        .center{
            display: flex;
            justify-content: space-between;
            .left{
                width: 890px;
                text-align: center;
                .left-t{
                    margin-bottom: 13px;
                }
                .left-b{
                    ul{
                        height: 80px;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        background-color: #ffffff;
                        li{
                            width: 178px;
                        }
                    }
                }
            }
            .right{
                width: 300px;
                .right-t{
                    height: 391px;
                    margin-bottom: 10px;
                    background-color: #ffffff;
                    .user{
                        display: flex;
                        padding: 26px 30px;
                        border-bottom: 1px solid #F5F7FA;
                        img{
                            width: 50px;
                            height: 50px;
                            margin-right: 15px;
                            border-radius: 50%;
                        }
                        div{
                            display: flex;
                            flex-wrap: wrap;
                            align-content: space-around;
                            h6{
                                font-size: 14px;
                                color: #0B1D30;
                                font-weight: 500;
                            }
                            p{
                                font-size: 14px;
                                color: #757F92;
                            }
                        }
                    }
                    .nav{
                        padding: 12px 30px;
                        border-bottom: 1px solid #f4f5fa;
                        ul{
                            display: flex;
                            flex-wrap: wrap;
                            li{
                                width: 80px;
                                margin: 12px 0;
                                text-align: center;
                                img{
                                    width: 26px;
                                    height: 26px;
                                }
                                p{
                                    font-size: 14px;
                                    color: #0B1D30;
                                    margin-top: 10px;
                                }
                            }
                        }
                    }
                    .more{
                        font-size: 14px;
                        color: #0b1d30;
                        line-height: 50px;
                        a{
                            color: #0070FF;
                        }
                    }
                }
                .right-b{
                    height: 104px;
                    background-color: #ffffff;
                    padding: 0 15px;
                    .notice{
                        display: flex;
                        justify-content: space-between;
                        line-height: 46px;
                        h6{
                            font-weight: 500;
                            font-size: 14px;
                        }
                        a{
                            font-size: 12px;
                            color: #757F92;
                        }
                    }
                }
            }
        }
    }
    .two{
        padding-top: 35px;
        background-color: #f5f7fa;
        .center{
            .top{
                height: 70px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                ul{
                    display: flex;
                    li{
                        width: 72px;
                        line-height: 22px;
                        margin-left: 10px;
                        border: 1px solid #006FFF;
                        text-align: center;
                        a{
                            color: #006FFF;
                            font-size: 12px;
                        }
                    }
                }
            }
            .bottom{
                display: flex;
                background-color: #ffffff;
                .bottom-l{
                    width: 246px;
                    ul{
                        li{
                            height: 79px;
                            border: 1px solid #f5f7fa;
                            background-color: #fafcff;
                            text-align: center;
                            line-height: 79px;
                            color: #0B1D30;
                        }
                        .show{
                            background-color: #ffffff;
                            border-color: #ffffff;
                            color: #006FFF;
                        }
                    }
                }
                .bottom-r{
                    width: 954px;
                    ul{
                        height: 486px;
                        display: flex;
                        flex-wrap: wrap;
                        padding: 7px 6px;
                        box-sizing: border-box;
                        justify-content: space-around;
                        align-content: space-around;
                        li{
                            width: 304px;
                            height: 84px;
                            background-color: #f9f9f9;
                            a{
                                display: flex;
                                padding: 18px;
                                justify-content: space-between;
                                align-content: space-around;
                                >span{
                                    display: block;
                                    width: 18px;
                                    height: 18px;
                                    font-size: 12px;
                                    text-align: center;
                                    line-height: 18px;
                                    color: #ffffff;
                                    background-color: #0070FF;
                                }
                                div{
                                    p{
                                        width: 236px;
                                        display: flex;
                                        justify-content: space-between;
                                        font-size: 14px;
                                    }
                                    p:nth-child(1){
                                        color: #0B1D30;
                                        margin-bottom: 8px;
                                    }
                                    p:nth-child(2){
                                        color: #757F92;
                                    }
                                }
                            }
                        }
                        li:hover{
                            a{
                                div{
                                    p:nth-child(1){
                                        color: #006FFF;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .three{
        background-color: #f5f7fa;
        padding-top: 70px;
        .center{
            .top{
                height: 72px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                a{
                    color: #757F92;
                    font-size: 14px;
                }
            }
            .bottom{
                .el-carousel__item{
                // ul{
                    display: flex;
                    margin-bottom: 20px;
                    .meList{
                        width: 199px;
                        border-right: 1px solid #eff1f5;
                        background-color: #ffffff;
                        a{
                            display: block;
                            padding: 15px;
                            img{
                                width: 168px;
                                height: 170px;
                            }
                            p{
                                font-size: 12px;
                                color: #757F92;
                                height: 20px;
                                line-height: 20px;
                                display: flex;
                                justify-content: space-between;
                                span{
                                    color: #FF7934;
                                    font-size: 14px;
                                }
                                i{
                                    font-style: normal;
                                }
                            }
                            p.first{
                                margin: 15px 0;
                            }
                            h6{
                                font-size: 14px;
                                color: #0070FF;
                                margin-bottom: 15px;
                                font-weight: 500;
                            }
                            div{
                                margin-top: 15px;
                                span{
                                    width: 61px;
                                    height: 22px;
                                    display: inline-block;
                                    border: 1px solid #006fff;
                                    font-size: 14px;
                                    color: #006FFF;
                                    text-align: center;
                                    line-height: 22px;
                                }
                            }
                        }
                    }
                    .meList:hover a{
                        box-shadow: 0px 0px 20px 0px rgba(59, 30, 30, 0.15); 
                    }
                // }
                }
            }
        }
    }
    .four{
        background-color: #f5f7fa;
        .center{
            h3{
                line-height: 90px;
            }
            ul{
                display: flex;
                border: 1px solid #eeeeee;
                background-color: #ffffff;
                li{
                    width: 149px;
                    height: 90px;
                    border-right: 1px solid #eeeeee;
                    text-align: center;
                    line-height: 148px;
                    img{
                        width: 124px;
                        height: 66px;
                    }
                }
                li:last-child{
                    border-right: 0 none;
                }
            }
        }
    }
    .five{
        background-color: #F5F7FA;
        padding-bottom: 40px;
        .center{
            h3{
                padding-top: 20px;
                line-height: 90px;
            }
            .industry-list{
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                >div{
                    display: flex;
                    margin-bottom: 10px;
                    .left{
                        width: 142px;
                        height: 454px;
                        color: #ffffff;
                        h4{
                            width: 96px;
                            margin: 0 auto;
                            font-size: 16px;
                            font-weight: 500;
                            padding-left: 13px;
                            line-height: 60px;
                        }
                        ul{
                            width: 96px;
                            margin: 0 auto;
                            margin-top: 10px;
                            li{
                                font-size: 14px;
                                height: 20px;
                                padding: 0 13px;
                                border-radius: 10px;
                                margin-bottom: 20px;
                            }
                        }
                    }
                    .right{
                        width: 453px;
                        height: 454px;
                        ul{
                            display: flex;
                            flex-wrap: wrap;
                            li{
                                width: 150px;
                                height: 226px;
                                border-right: 1px solid #eeeeee;
                                border-bottom: 1px solid #eeeeee;
                                background-color: #ffffff;
                                overflow: visible;
                                img{
                                    width: 122px;
                                    height: 122px;
                                    margin-left: 14px;
                                    margin-top: 15px;
                                }
                                p{
                                    width: 120px;
                                    height: 39px;
                                    margin: 7px auto;
                                    font-size: 12px;
                                    color: #0B1D30;
                                    line-height: 20px;
                                    overflow: hidden;
                                }
                                h6{
                                    font-size: 24px;
                                    color: #FF0027;
                                    padding-left: 14px;
                                    margin-top: -5px;
                                    font-weight: 500;
                                    span{
                                        font-size: 16px;
                                    }
                                }
                            }
                            li:hover{
                                box-shadow: 0 0 15px 0 rgba(0,0,0,0.15);
                                p{
                                    color: #0070FF;
                                }
                            }
                        }
                    }
                }
                >div:nth-child(1){
                    .left{
                        background: linear-gradient(166deg,#556280, #243256 100%);
                        ul{
                            li.show{
                                background-color: #757f92;
                            }
                        }
                    }
                }
                >div:nth-child(2){
                    .left{
                        background: linear-gradient(166deg,#626262, #343132 100%);
                        ul{
                            li.show{
                                background-color: #484646;
                            }
                        }
                    }
                }
                >div:nth-child(3){
                    .left{
                        background: linear-gradient(166deg,#466171, #1b3d4b 100%);
                        ul{
                            li.show{
                                background-color: #294957;
                            }
                        }
                    }
                }
                >div:nth-child(4){
                    .left{
                        background: linear-gradient(166deg,#a77d5c, #4f3422 100%);
                        ul{
                            li.show{
                                background-color: #674832;
                            }
                        }
                    }
                }
            }
        }
    }
    .six{
        background-color: #fafbfc;
        padding-bottom: 55px;
        .center{
            .top{
                height: 70px;;
                padding-top: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                a{
                    font-size: 14px;
                    color: #757F92;
                }
            }
            .bottom{
                text-align: center;
                h5{
                    line-height: 50px;
                    font-size: 14px;
                    color: #0b1d30;
                    font-weight: 500;
                    margin-bottom: 5px;
                }
                img{
                    width: 1172px;
                    height: 753px;
                }
            }
        }
    }
    .seven{
        padding-top: 60px;
        padding-bottom: 55px;
        background-color: #f5f7fa;
        .center{
            display: flex;
            justify-content: space-around;
            >div{
                width: 575px;
                display: flex;
                img{
                    width: 222px;
                    height: 260px;
                }
                >div{
                    width: 373px;
                    height: 260px;
                    box-sizing: border-box;
                    padding: 10px 40px;
                    background-color: #ffffff;
                    ul{
                        li{
                            line-height: 35px;
                            a{
                                font-size: 14px;
                                color: #757F92;
                            }
                        }
                        li:hover{
                            a{
                                color: #0070FF;
                            }
                        }
                    }
                }
            }
        }
    }
}

.left-t{
    .el-carousel__item h3 {
        color: #475669;
        font-size: 14px;
        opacity: 0.75;
        margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }
    
    .el-carousel__item:nth-child(2n+1) {
        background-color: #d3dce6;
    }
}


</style>

<style lang="scss" scoped>
.el-tabs__nav-wrap::after{
    background-color: #ffffff !important;
}

.el-tabs--bottom .el-tabs__item.is-bottom:nth-child(2), .el-tabs--bottom .el-tabs__item.is-top:nth-child(2), .el-tabs--top .el-tabs__item.is-bottom:nth-child(2), .el-tabs--top .el-tabs__item.is-top:nth-child(2){
    padding-left: 40px !important;
}

.el-tabs--bottom .el-tabs__item.is-bottom:last-child, .el-tabs--bottom .el-tabs__item.is-top:last-child, .el-tabs--top .el-tabs__item.is-bottom:last-child, .el-tabs--top .el-tabs__item.is-top:last-child{
    padding-left: 80px !important;
}
</style>
