<template>
    <div class="foot">
        <div class="foot_center clearfix">
            <div class="left">
                <div>
                    <h4>网站导航</h4>
                    <div class="link_nav clearfix">
                        <ul>
                            <li>
                                <router-link :to="{path: '/softwareMarket/softwareHome'}" target="_blank">产品家族</router-link>
                            </li>
                            <li>
                                <router-link :to="{path: '/identification'}" target="_blank">标识应用</router-link>
                            </li>
                            <li>
                                <router-link :to="{path: '/mailianZhicai'}" target="_blank">迈链智采</router-link>
                            </li>
                            <li>
                                <router-link :to="{path: '/industIot'}" target="_blank">工业物联网</router-link>
                            </li>
                            <li>
                                <a href="http://27.128.171.75:8081/login.html" target="_blank">快速开发</a>
                            </li>
                            <li>
                                <router-link :to="{path: '/mailianZhicai'}" target="_blank">云工厂</router-link>
                            </li>
                        </ul>
                        <ul>
                            <li>
                                <a href="/finance" target="_blank">金融信息</a>
                            </li>
                            <li>
                                <a href="/solutionList" target="_blank">行业解决方案</a>
                            </li>
                            <li>
                                <a href>走进沧州工业云</a>
                            </li>
                            <li>
                                <router-link :to="{path: '/caseList'}" target="_blank">经典案例</router-link>
                            </li>
                            <li>
                                <router-link :to="{path: '/companyNews'}" target="_blank">新闻资讯</router-link>
                            </li>
                            <li>
                                <router-link :to="{path: '/govhome'}" target="_blank">政策服务</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <h4>产业智造</h4>
                    <ul>
                        <li>
                            <a href="/SoftwareMarket/saasList" target="_blank">Saas应用平台</a>
                        </li>
                        <li>
                            <a href="/identification" target="_blank">工业互联网标识应用平台</a>
                        </li>
                        <li>
                            <a href="/mailianzhicai" target="_blank">迈链智采平台</a>
                        </li>
                        <li>
                            <a href="/industIot" target="_blank">工业物联网平台</a>
                        </li>
                        <li>
                            <a href="/industInternet" target="_blank">企业级互联网平台</a>
                        </li>
                        <li>
                            <a href="http://27.128.171.75:8081/login.html" target="_blank">快速开发</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h4>产品</h4>
                    <ul>
                        <li>
                            <a href="https://www.cz-icloud.com/softwareMarket/softwareHome">产品试用</a>
                        </li>
                        <li>
                            <a href="https://www.cz-icloud.com/softwareMarket/softwareHome">教程演示</a>
                        </li>
                        <li>
                            <a href="https://www.cz-icloud.com/softwareMarket/softwareHome">产品购买</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h4>金融信息</h4>
                    <ul>
                        <li>
                            <a href="https://www.cz-icloud.com/softwareMarket/softwareHome">凭证通</a>
                        </li>
                        <li>
                            <a href="https://www.cz-icloud.com/softwareMarket/softwareHome">商城对接</a>
                        </li>
                        <li>
                            <a href="https://www.cz-icloud.com/softwareMarket/softwareHome">聚宝盆金融</a>
                        </li>
                        <li>
                            <a href="https://www.cz-icloud.com/softwareMarket/softwareHome">SaaS双向集成</a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h4>战略合作</h4>
                    <ul class="clearfix">
                        <li>
                            <a href="https://www.casicloud.com/" target="_blank">航天云网</a>
                        </li>
                        <li>
                            <a href="http://www.chinatelecom.com.cn/" target="_blank">中国电信</a>
                        </li>
                        <li>
                            <a href="https://www.10086.cn/" target="_blank">中国移动</a>
                        </li>
                        <li>
                            <a href="" target="_blank">共享工业云</a>
                        </li>
                        <li>
                            <a href="https://www.sugon.com/" target="_blank">中科曙光</a>
                        </li>
                        <li>
                            <a href="https://www.jepaas.com/" target="_blank">凯特伟业</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="right">
                <div class="logo">
                    <img src="../assets/image/foot_logo.png" alt />
                </div>
                <div class="contact">
                    <ul>
                        <li>联系方式：15531757775</li>
                        <li>邮&emsp;&emsp;箱：czicloud@163.com</li>
                        <li>办公地址：沧州市高新区河工大科技园</li>
                    </ul>
                </div>
                <div class="focus">
                    <h4 class>关注我们</h4>
                    <img src="../assets/image/foot_pic_1.png" alt />
                    <p>微信公众号</p>
                </div>
            </div>
        </div>
        <div class="foot_link">
            <div class="w_1200">
                <span>友情链接：</span>
                <span class="link_text" v-for="(item,index) in linkList" :key="index">
                    <a :href="item.link" target="_blank">{{item.name}}</a>
                </span>
            </div>
        </div>
        <p class="p-40">
            Copyright © 2017-2022 czicloud.cn All Rights Reserved. 河北中科物联信息技术有限公司 版权所有
            <a
                href="https://beian.miit.gov.cn/"
                target="_blank"
            >冀ICP备18021665号</a>
        </p>
    </div>
</template>

<script>
export default {
    data() {
        return {
            linkList: [
                {
                    name: "中国信通院",
                    link: "http://www.caict.ac.cn/",
                },
                {
                    name: "河北省工信厅",
                    link: "http://gxt.hebei.gov.cn/hbgyhxxht/index/index.html",
                },
                {
                    name: "沧州市工信局",
                    link: "https://gxj.cangzhou.gov.cn/",
                },
                {
                    name: "泰尔英福",
                    link: "https://www.teleinfo.cn",
                },
                {
                    name: "沧州金控",
                    link: "https://www.czjrkg.cn/fhc/front/index",
                },
                {
                    name: "中国皮毛信息网",
                    link: "http://www.fur.com.cn/",
                },
                {
                    name: "中国冲压件网",
                    link: "http://www.chongyajian.3.biz/",
                },
                {
                    name: "中国管道商务网",
                    link: "https://www.chinapipe.net/",
                },

            ],
        };
    },
    methods: {},
};
</script>

<style lang="scss" scoped>
.w_1200 {
    width: 1200px;
    margin: 0 auto;
}
.foot {
    min-width: 1286px;
    padding-top: 75px;
    box-sizing: border-box;
    background-color: #1c1c1c;
    color: #999999;
    .foot_center {
        width: 1200px;
        margin: 0 auto;
        padding-bottom: 50px;
        .right {
            float: left;

            padding-left: 40px;
            padding-top: 10px;

            p {
                color: #ffffff;
            }
            .logo {
                height: 38px;
                margin-bottom: 20px;
                // background-color: #f2f2f2;
            }
            .contact {
                font-size: 13px;
                color: #ffffff;
                padding-bottom: 20px;
                ul {
                    li {
                        margin-bottom: 12px;
                    }
                }
            }
            .focus {
                h4 {
                    margin-bottom: 15px;
                    font-size: 16px;
                    color: #ffffff;
                    font-weight: normal;
                }
                img {
                    display: block;
                    width: 90px;
                    height: 90px;
                    margin-bottom: 10px;
                }
                p {
                    width: 90px;
                    text-align: center;
                    font-size: 14px;
                }
            }
        }
        .left {
            float: left;
            text-align: left;
            border-right: 1px solid #ffffff;
            overflow: hidden;
            padding: 10px 0 15px;
            > div {
                float: left;
                margin-right: 60px;
                h4 {
                    color: #ffffff;
                    font-size: 16px;
                    font-weight: normal;
                    margin-bottom: 20px;
                }
                li {
                    font-size: 12px;
                    color: #999999;
                    line-height: 38px;
                    a {
                        color: #999999;
                    }
                    a:hover {
                        color: #fff;
                    }
                }
            }
            .link_nav {
                ul {
                    float: left;
                }
                > ul:nth-child(1) {
                    margin-right: 20px;
                }
            }
            div:nth-child(1) {
            }
            // div:nth-child(2) {
            //     width: 198px;
            //     li {
            //         float: left;
            //         width: 99px;
            //     }
            // }
            // div:nth-child(3) {
            //     width: 60px;
            //     margin-left: 80px;
            //     margin-right: 106px;
            // }
            // div:nth-child(4) {
            //     width: 230px;
            // }
        }
    }
    > p {
        color: #ffffff;
        font-size: 14px;
        text-align: center;
        padding: 40px 0;
        a {
            color: #ffffff;
        }
    }
    .foot_link {
        padding: 20px 0;
        color: #ffffff;
        font-size: 14px;
        font-family: Microsoft YaHei UI-Light, Microsoft YaHei UI;
        color: #ffffff;
        border-top: 1px solid #999999;
        border-bottom: 1px solid #999999;
        .link_text {
            color: #999999;
            padding: 0 10px;
            border-right: 1px solid #999;
            a {
                color: #999;
            }
            a:hover {
                color: #fff;
            }
        }
        .link_text:last-child {
            border: none;
        }
        .link_icon {
            color: #999999;
            i {
                transform: rotate(90deg);
            }
        }
    }
}
</style>
