<template>
    <div class="indust-data">
        <div class="banner">
            <div class="banner-center">
                <h3>沧州工业大数据中心</h3>
                <p>基于云计算的智能化工业大数据平台</p>
                <a href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id=" onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false">立即体验</a>
            </div>
        </div>

        <div class="data-introduce">
            <div class="iot-introduce">
                <div class="left">
                    <h3>沧州工业大数据中心</h3>
                    <div class="pho-introduce">
                        <h4>沧州工业大数据中心</h4>
                        <p>沧州工业大数据中心是面向沧州地区工业制造型企业打造的一站式大数据平台，包含了数据资产管理和 AI 算法建模两部分。内置通用和行业算法库。提供优化调度算法能力，帮助用户高效利用硬件计算资源，提高生产力。为政府决策提供依据，为企业、行业、产业提供有价值的数据分析服务，实现数字“新基建”。</p>
                        <a href="http://27.128.161.128:8081/share-app/bigscreenv3?token=ieeJK3Ej" target="_blank">立即体验</a>
                        <!-- <a class="alert-box">
                            <span @click="alertBox">立即体验</span>
                            <div>
                            <iframe style="width: 100%; height: 100%;" src="http://27.128.161.128:8081/share-app/bigscreenv3?token=ieeJK3Ej" frameborder="0"></iframe>
                            </div>
                        </a> -->
                    </div>
                </div>
                <div class="right">
                    <div class="pho-introduce">
                        <h4>沧州工业互联网标识解析数据中心</h4>
                        <p>涵盖标识注册、申领数据、解析应用数据等工业大数据<br/>打通数据壁垒，实现全要素、全产业链和全价值链的数据交互，推动产业的数字化转型、智能化升级。</p>
                        <a href="http://27.128.161.128:8081/share-app/bigscreenv3?token=ZEoiehuo" target="_blank">立即体验</a>
                    </div>
                    <div class="pho-introduce">
                        <h4>沧州工业云3.0平台大数据</h4>
                        <p>汇聚沧州地区工业企业信息、采购信息、招标投标等工业大数据，打通企业私域流量，数据共享，互通互联。实现企业高效协同，精准营销。</p>
                        <a href="http://27.128.161.128:8081/share-app/bigscreenv3?token=nFHWiIWE" target="_blank">立即体验</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="pro-introduce">
            <div class="pro-introduce-center">
                <h3>产品能力与服务</h3>
                <ul>
                    <li>
                        <p><img src='https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_1.png' alt=""></p>
                        <div>
                            <h4>丰富的算法组件</h4>
                            <p>涵盖多种经典机器学习算法组件和工业大数据算法组件</p>
                        </div>
                    </li>
                    <li>
                        <p><img src='https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_2.png' alt=""></p>
                        <div>
                            <h4>可视化开发体验</h4>
                            <p>无需开发代码，通过拖拉拽形式快速搭建机器学习试验</p>
                        </div>
                    </li>
                    <li>
                        <p><img src='https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_3.png' alt=""></p>
                        <div>
                            <h4>自动化流程控制</h4>
                            <p>拖拽生成算法组件运行依赖关系及工作流，自动化执行</p>
                        </div>
                    </li>
                    <li>
                        <p><img src='https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_4.png' alt=""></p>
                        <div>
                            <h4>支持自定义算法</h4>
                            <p>支持 python，C/C++等多种语言编写的自定义算法包</p>
                        </div>
                    </li>
                </ul>
            </div>
        </div>

        <div class="terrace">
            <div class="terrace-center">
                <h3>平台优势</h3>
                <div class="content-box">
                    <div class="left">
                        <ul>
                            <li v-for="(item, index) in terrace" :key="index">
                                <img :src="item.img" alt="">
                            </li>
                        </ul>
                    </div>
                    <div class="right">
                        <div class="content">
                            <div v-for="(item, index) in terrace" :key="index + 'in'">
                                <h4>{{item.title}}<span></span></h4>
                                <p>{{item.introduce}}</p>
                            </div>
                        </div>
                        <div class="title">
                            <ul>
                                <li v-for="(item, index) in terrace" :key="index + 't'" @click="terTit(index)">{{item.title}}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="scenario">
            <div class="scenario-center">
                <h3>应用场景</h3>

                <div class="top">
                    <h3>缺陷检测</h3>
                    <p>根据工业生产制造过程中AOI 设备拍摄的产品质量的检测图像，识别出产品中存在的质量缺陷，并依照设定的业务规则对缺陷进行编码分类。 通过 AI 技术可替代人工进行的重复劳动，降低人力成本，提升缺陷识别的速度与准确性，从而缩短检测时间，提高产品质量和产能。</p>
                </div>
                <div class="bottom">
                    <ul>
                        <li>
                            <h4><img src='https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_5.png' alt="">能源调度</h4>
                            <p>通过能源预测模型帮助企业优化资源利用效率，提高全企业能效</p>
                        </li>
                        <li>
                            <h4><img src='https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_6.png' alt="">图像检测</h4>
                            <p>通过图像检测算法辅助工人对缺陷定位和分类，有效控制质量异常，减少人力成本</p>
                        </li>
                        <li>
                            <h4><img src='https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_7.png' alt="">预测性维护</h4>
                            <p>通过对关键的设备运行参数进行建模，判断机器的运行状态、预测维护时间</p>
                        </li>
                        <li>
                            <h4><img src='https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_8.png' alt="">质量监控</h4>
                            <p>通过对生产过程全数据建模，迅速识别生产异常点，从源头降低产品缺陷率</p>
                        </li>
                        <li>
                            <h4><img src='https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_9.png' alt="">良率预测</h4>
                            <p>通过对生产制程工艺参数建模来预测产品指标，推动生产优化，提升良品率</p>
                        </li>
                        <li>
                            <h4><img src='https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/icon_10.png' alt="">生产排程</h4>
                            <p>通过对作业、投产、备料等环节建模，最优资源调度以应对复杂的订单计划</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default{
    data() {
        return{
            imgs: [
                {
                    bgImg: '',
                    title: '汽车制造',
                    iconImg: ''
                },
                {
                    bgImg: '',
                    title: '绿色化工',
                    iconImg: ''
                },
                {
                    bgImg: '',
                    title: '管道装备',
                    iconImg: ''
                },
                {
                    bgImg: '',
                    title: '服装服饰',
                    iconImg: ''
                },
                {
                    bgImg: '',
                    title: '清洁能源',
                    iconImg: ''
                },
                {
                    bgImg: '',
                    title: '生物医药',
                    iconImg: ''
                },
                {
                    bgImg: '',
                    title: '先进再制造',
                    iconImg: ''
                },
                {
                    bgImg: '',
                    title: '智能装备',
                    iconImg: ''
                },
                {
                    bgImg: '',
                    title: '激光产业',
                    iconImg: ''
                },
                {
                    bgImg: '',
                    title: '物流',
                    iconImg: ''
                },
                {
                    bgImg: '',
                    title: '文化旅游',
                    iconImg: ''
                },
                {
                    bgImg: '',
                    title: '信息应用',
                    iconImg: ''
                },
            ],
            swiperOptions: {
                loop: true,
                slidesPerView : 4,
                autoplay: {
                    delay: 5000
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            },
            terrace: [{
                title: '平台优势',
                introduce: '沧州工业互联网综合服务平台通过强大的底层能力，结合沧州工业物联网平台对基本生产数据进行实时采集与存储，通过数据挖掘，分析等技术手段，控制生产过程，完善科学管控，达到降本增效的目的，同时对产品功能，性能等进行分析改进和创新，更好的满足工业企业的个性化需求。',
                img: 'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/pic_4.png'
                // img: require('.')
            },{
                title: '功能强大',
                introduce: '兼容异构网络接入、稳健消息通道、可视化监控、安全数据通信、固件升级工具等全套物联网平台层功能。可免去用户大部分自搭建部署、运维、扩容等繁琐工作。用户可根据具体的设备数据通讯连接方式，采用设备直接连接和通过网关设备连接平台，实现对物联网采集层设备的接入。',
                img:  'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/pic_5.png'
            },{
                title: '海量数据处理',
                introduce: '可基于私有云/公有云方式部署，帮助企业打造自己的IOT云平台，在提供设备管理、数据模型服务、生产管理、SaaS搭建等能力模块的同时，可提供定制化的系统部署、业务开发。',
                img:  'https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/pic_6.png'
            }]
        }
    },
    comments: {
        Swiper,
        SwiperSlide
    },
    methods: {
        alertBox: function () {
            $('.alert-box >div').css({'display': 'block'})
        },
        handleScroll: function (e) {
            let _this = this;
            _this.scrollTop = document.documentElement.scrollTop;
            _this.getBottomOfWindow();
        },
        getBottomOfWindow: function () {
            // var winHeight = window.innerHeight;
            if(this.scrollTop > 1090){
                $('.pro-introduce .pro-introduce-center ul li:nth-child(2n+1)').css({'left': 0, 'transition': 'left 1s ease'})
                $('.pro-introduce .pro-introduce-center ul li:nth-child(2n)').css({'right': 0, 'transition': 'right 1s ease'})
            }
        },
        terTit: function(index) {
            $('.terrace-center .content >div').eq(index).show()
            $('.terrace-center .content >div').eq(index).siblings().hide()
            $('.terrace-center .right .title ul li').eq(index).addClass('show')
            $('.terrace-center .right .title ul li').eq(index).siblings().removeClass('show')
            $('.terrace-center .left ul li').eq(index).addClass('show')
            $('.terrace-center .left ul li').eq(index).siblings().removeClass('show')
        }
    },
    mounted() {
         window.addEventListener("scroll", this.handleScroll, true);
         this.terTit(0)
    }
}
</script>

<style scoped lang="scss">
.indust-data{
    .alert-box{
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.8);
        span{
            color: #ffffff;
        }
        >div{
            width: 1800px;
            height: 900px;
            background: #ffffff;
            display: none;
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 999;
        }
    }
    // margin-top: 62px;
    .banner{
        width: 100%;
        height: 540px;
        background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/banner.jpg') center no-repeat;
        background-size: auto 540px;
        .banner-center{
            width: 1200px;
            margin: 0 auto;
            h3{
                font-size: 42px;
                margin-bottom: 20px;
                padding-top: 182px;
                color: #0B1D30;
            }
            p{
                font-size: 20px;
                margin-bottom: 70px;
                color: #757F92;
            }
            a{
                display: block;
                width: 140px;
                height: 40px;
                text-align: center;
                line-height: 40px;
                background-color: #1f51c8;
                font-size: 14px;
                color: #ffffff;
            }
        }
    }

    .data-introduce{
        padding: 90px 0;
        background-color: #F6F8FB;
        .iot-introduce{
            width: 1200px;
            height: 600px;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            position: relative;
            .pho-introduce{
                background-color: #e5e5e5;
                box-sizing: border-box;
                color: #ffffff;
                left: 0;
                transition: left 1s ease;
                h4{
                    position: relative;
                    font-size: 22px;
                    font-weight: 400;
                    margin-bottom: 26px;
                }
                p{
                    font-size: 16px;
                    line-height: 26px;
                    margin-bottom: 40px;
                }
                a{
                    display: block;
                    width: 140px;
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    font-size: 14px;
                    color: #ffffff;
                    border: 1px solid #ffffff;
                }
            }
            .left{
                width: 654px;
                h3{
                    font-size: 34px;
                    margin-bottom: 20px;
                }
                .pho-introduce{
                    width: 654px;
                    height: 416px;
                    padding: 90px 60px;
                    background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/pic_1.jpg') center no-repeat;
                }
                .pho-introduce:hover{
                    background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/pic_1_1.jpg') center no-repeat;
                    a{
                        background-color: #1F51C8;
                        border: 1px solid #1F51C8;
                    }
                }
            }
            .right{
                width: 483px;
                .pho-introduce{
                    width: 483px;
                    height: 285px;
                    padding: 40px 30px 20px 30px;
                    margin-bottom: 20px;
                    h4{
                        font-size: 18px;
                        margin-bottom: 14px;
                    }
                }
                .pho-introduce:nth-child(1){
                    background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/pic_2.jpg') center no-repeat;
                }
                .pho-introduce:nth-child(1):hover{
                    background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/pic_2_1.jpg') center no-repeat;
                    a{
                        background-color: #1F51C8;
                        border: 1px solid #1F51C8;
                    }
                }
                .pho-introduce:nth-child(2){
                    background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/pic_3.jpg') center no-repeat;
                }
                .pho-introduce:nth-child(2):hover{
                    background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/pic_3_1.jpg') center no-repeat;
                    a{
                        background-color: #1F51C8;
                        border: 1px solid #1F51C8;
                    }
                }
            }
        }
    }

    .pro-introduce{
        .pro-introduce-center{
            width: 1200px;
            margin: 0 auto;
            padding: 30px 0;
            color: #181818;
            h3{
                font-size: 36px;
                padding-top: 60px;
                margin-bottom: 40px;
            }
            >p{
                font-size: 18px;
                margin-bottom: 60px;
            }
            ul{
                height: 320px;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;
                position: relative;
                padding-bottom: 50px;
                li{
                    width: 545px;
                    height: 140px;
                    background-color: #ffffff;
                    border: 1px solid #ffffff;
                    display: flex;
                    justify-self: start;
                    margin-bottom: 40px;
                    align-items: center;
                    position: absolute;
                    padding-left: 25px;
                    cursor: pointer;
                    >p{
                        width: 70px;
                        width: 70px;
                        margin-right: 35px;
                        img{
                            width: 70px;
                            height: 70px;
                            -webkit-filter: grayscale(100%);
                            -moz-filter: grayscale(100%);
                            -ms-filter: grayscale(100%);
                            -o-filter: grayscale(100%);
                            filter: grayscale(100%);
                            filter: gray;
                            opacity: 0.5;
                        }
                    }
                    div{
                        width: 370px;
                        h4{
                            margin-bottom: 15px;
                            font-size: 18px;
                            font-weight: 400;
                        }
                        p{
                            font-size: 14px;
                            line-height: 26px;
                            color: #2d2d2d;
                        }
                    }
                }
                li:nth-child(1)， li:nth-child(2){
                    top: 0;
                }
                li:nth-child(3), li:nth-child(4){
                    top: 182px;
                }
                li:nth-child(2n+1){
                    left: -940px;
                }
                li:nth-child(2n){
                    right: -940px;
                }
                li:hover{
                    border: 1px solid #a3b3ef;
                    box-shadow: 0 0 10px rgba(0,0,0,0.1);
                    img{
                        -webkit-filter: none;
                        -moz-filter: none;
                        -ms-filter: none;
                        -o-filter: none;
                        filter: none;
                        opacity: 1;
                        transition: opacity 0.5s ease;
                    }
                }
            }
        }
    }

    .terrace{
        width: 100%;
        padding-top: 45px;
        background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/bg_1.jpg') center no-repeat;
        .terrace-center{
            width: 1180px;
            margin: 0 auto;
            padding-bottom: 90px;
            h3{
                font-size: 36px;
                color: #fefefe;
                margin-bottom: 45px;
            }
            .content-box{
                display: flex;
                .left, .right{
                    width: 590px;
                    height: 470px;
                }
                .left{
                    background-color: #f6f8fc;
                    position: relative;
                    ul{
                        width: 590px;
                        display: flex;
                        li{
                            width: 590px;
                            height: 470px;
                            opacity: 0;
                            position: absolute;
                            top: 0; 
                            left: 0;
                        }
                        li.show{
                            opacity: 1;
                            transition: opacity 1s ease;
                            z-index: 9;
                        }
                    }
                }
                .right{
                    color: #181818;
                    position: relative;
                    background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/bg_2.png') right top no-repeat;
                    background-color: #ffffff;
                    .title{
                        width: 600px;
                        margin: 0 auto;
                        
                        ul{
                            display: flex;
                            justify-content: space-around;
                            border-top: 1px solid #e5e5e5;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            li{
                                width: 196.7px;
                                height: 65px;
                                line-height: 65px;
                                text-align: center;
                                font-size: 14px;
                                box-sizing: border-box;
                                cursor: pointer;
                            }
                            li:nth-child(2){
                                border-left: 1px solid #e5e5e5;
                                border-right: 1px solid #e5e5e5;
                            }
                            li.show{
                                background-color: #1748fd;
                                color: #ffffff;
                                box-shadow: 0 0 10px rgba(0,0,0,0.08);
                            }
                        }
                    }
                    .content{
                        width: 448px;
                        padding-top: 45px;
                        margin: 0 auto;
                        >div{
                            display: none;
                            h4{
                                font-size: 26px;
                                line-height: 88px;
                                position: relative;
                                margin-bottom: 30px;
                                border-bottom: 1px solid #e5e5e5;
                            }
                            p{
                                font-size: 14px;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }
    }

    .scenario{
        padding: 90px 0;
        background-color: #F6F8FB;
        .scenario-center{
            width: 1180px;
            margin: 0 auto;
            >h3{
                font-size: 34px;
                font-weight: 400;
                color: #0B1D30;
                margin-bottom: 60px;
            }
            .top{
                height: 165px;
                padding: 5px 30px 18px 30px;
                background-color: #e5e5e5;
                box-sizing: border-box;
                margin-bottom: 30px;
                background: url('https://oss-zlwl-sy.oss-cn-beijing.aliyuncs.com/zkwl/bagData/bg_3.jpg') 0 0 no-repeat;
                h3{
                    font-size: 20px;
                    color: #ffffff;
                    line-height: 65px;
                    border-bottom: 1px solid #ffffff;
                }
                p{
                    height: 60px;
                    font-size: 16px;
                    color: #ffffff;
                    line-height: 30px;
                    margin-top: 10px;
                }
            }
            .bottom{
                ul{
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    li{
                        width: 380px;
                        height: 140px;
                        background-color: #ffffff;
                        margin-bottom: 20px;
                        box-sizing: border-box;
                        padding: 25px;
                        h4{
                            font-size: 20px;
                            font-weight: 400;
                            color: #0B1D30;
                            line-height: 32px;
                            margin-bottom: 7px;
                            img{
                                width: 32px;
                                height: 32px;
                                margin-right: 10px;
                            }
                        }
                        p{
                            height: 50px;
                            overflow: hidden;
                            font-size: 16px;
                            line-height: 25px;
                            color: #757f92;
                        }
                    }
                }
            }
        }
    }
}
</style>

