<template>
<div class="indust-nav-bar">
    <div class="indust-top">
        <div class="indust-top-center clearfix">
            <div class="left clearfix">
                <p>你好，<a href="">请登录</a></p>
                <a href="">免费注册</a>
            </div>
            <div class="right">
                <ul class="clearfix">
                    <li><a href="">首页</a></li>
                    <li><a href="">买家中心</a></li>
                    <li><a href="">卖家中心</a></li>
                    <li><a href="">常购清单</a></li>
                    <li><a href="">采购单</a></li>
                    <li><a href="">帮助中心</a></li>
                    <li><a href="">App下载</a></li>
                </ul>
            </div>
        </div>
    </div>

    <div class="indust-nav clearfix">
        <div class="left">
            <img src="../assets/img/logo 2.png" alt="">
            <h4>工业供采平台</h4>
        </div>
        <div class="center">
            <el-menu class="el-menu-demo" :default-active="activeIndex" mode="horizontal" @select="handleSelect" router>
                <el-menu-item index="industrial">首页</el-menu-item>
                <el-submenu index="2">
                    <template slot="title">采购</template>
                    <el-menu-item index="/callAuction">集合竞价</el-menu-item>
                    <el-menu-item index="/rapidProcure">快速求购</el-menu-item>
                </el-submenu>
                <el-submenu index="3">
                    <template slot="title">招标</template>
                    <el-menu-item index="3-1">企业招标</el-menu-item>
                </el-submenu>
                <el-submenu index="4">
                    <template slot="title">工业品商城</template>
                    <el-menu-item index="4-1">现货中心</el-menu-item>
                    <el-menu-item index="4-2">工业品商城</el-menu-item>
                </el-submenu>
                <el-submenu index="5">
                    <template slot="title">闲置租赁</template>
                    <el-menu-item index="5-1">闲置物资</el-menu-item>
                    <el-menu-item index="5-2">租赁需求</el-menu-item>
                </el-submenu>
            </el-menu>
        </div>
        <div class="right"></div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            input: '',
            activeIndex: 'industrial',
            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            value: '',
            value1: '',
        }
    },
    methods: {
        handleSelect(key, keyPath) {
            // this.$router.push({path:keyPath})
            this.$nextTick(() => {
                this.activeIndex = this.$route.path
            })
        }
    },
    mounted() {

    }
}
</script>

<style scoped>
.el-menu--horizontal>.el-menu-item{
    height: 35px !important;
    line-height: 35px !important;
}
.el-menu--horizontal>.el-menu-item.is-active{
    border-bottom: 0 none !important;
    margin: 0 10px;
}
</style>

<style lang="scss">
.el-menu.el-menu--horizontal{
    border-bottom: 0 none !important;
}

.el-menu--horizontal>.el-submenu .el-submenu__title{
    color: #0B1D30 !important;
    height: 35px !important;
    line-height: 35px !important;
}
.el-menu--horizontal>.el-submenu{
    margin: 0 10px !important;
}
.el-submenu .el-menu-item{
    min-width: 100px !important;
}
.el-menu--collapse .el-menu .el-submenu, .el-menu--popup{
    width: 100px !important;
    text-align: center;
}
indust-nav-bar{
    .el-input__inner{
        width: 140px !important;
        height: 30px !important;
        box-sizing: border-box;
    }
}
.el-range-separator{
    line-height: 24px !important;
}
.el-date-editor .el-range__icon{
    line-height: 24px !important;
}
.el-range-editor.el-input__inner{
    margin-right: 12px;
}
</style>

<style lang="scss" scoped>
.indust-nav-bar{
    .indust-top{
        width: 100%;
        height: 36px;
        background-color: #333333;
        color: #ffffff;
        font-size: 12px;
        .indust-top-center{
            width: 1200px;
            line-height: 36px;
            margin: 0 auto;
            .left{
                float: left;
                p{
                    float: left;
                    margin-right: 30px;
                    a{
                        color: #ffffff;
                    }
                }
                >a{
                    float: left;
                    color: #ffffff;
                }
            }
            .right{
                float: right;
                ul{
                    li{
                        float: left;
                        margin: 0 10px;
                        a{
                            color: #ffffff;
                        }
                    }
                }
            }
        }
    }
    .indust-nav{
        width: 1200px;
        height: 95px;
        margin: 0 auto;
        background-color: #ffffff;
        .left{
            float: left;
            margin-top: 35px;
            margin-right: 20px;
            img{
                width: 150px;
                height: 35px;
                float: left;
            }
            h4{
                float: left;
                margin-left: 10px;
                padding-left: 10px;
                margin-top: 3px;
                border-left: 1px solid #a2a2a2;
                color: #0B1D30;
                font-size: 18px;
                line-height: 28px;
            }
        }
        .center{
            float: left;
            margin-top: 35px;
        }
        .right{
            float: right;
        }
    }
}
</style>