<template>
    <div class="solution-details">
        <!-- banner -->
        <div
            class="solution-module module-banner"
            :style="{backgroundImage:'url(' + bannerBg +')'}"
        >
            <div class="module-center">
                <div class="banner-title">解决方案中心</div>
                <div class="banner-desc">
                    探究行业共性需求 构造普适实用的解决方案    
                    <!-- <br />探究行业共性需求 构造普适而实用的解决方案 -->
                </div>
                <div class="banner-button">
                    <a
                        style="display:block;width:100%;height:100%;color:#fff;"
                        href="https://www30c1.eiisys.com/webCompany.php?kf_sign=zcxODMTU1NU2MTEwODkzOTM1Njc0MDAyNzIxOTQ2NjM=&arg=10194663&style=1&language=cn&charset=GBK&kflist=off&kf=&zdkf_type=1&lnk_overflow=0&callback_id6ds=&referer=http%3A%2F%2Fwww.yy.com%2Fczgyy%2F&keyword=&tfrom=1&tpl=crystal_blue&uid=aedca1dea9cd16f94e1da2b8b9b43c88&is_group=&is_group=&timeStamp=1557718667185&ucust_id="
                        onclick="window.open(this.href, '_blank','width=820,height=610,top=200,left=550');return false"
                    >了解更多</a>
                </div>
            </div>
        </div>
        <!-- 热门行业解决方案 -->
        <div class="solution-module">
            <div class="module-center">
                <div class="module-title">热门行业解决方案</div>
                <div class="module-select">
                    <div
                        class="select-item"
                        :class="index==0 || index==5?'class2':'class1'"
                        @click="toDetail(item.id)"
                        v-for="(item,index) in selectSolution"
                        :key="index"
                    >
                        <div
                            class="select-item-text"
                            :style="{backgroundImage:'url(' + item.hotImage +')'}"
                        >
                            <p class="select-item-name">{{item.title}}</p>
                            <p class="select-item-desc">{{item.description}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 企业场景解决方案 -->
        <div class="solution-module bg_F6F8FB">
            <div class="module-center">
                <div class="module-title">全部解决方案</div>
                <div class="module-all">
                    <div class="all-navbar">
                        <div
                            :class="navbarIndex==index?'active':''"
                            @click="changeNavBar(index)"
                            v-for="(item,index) in navbar"
                            :key="index"
                        >
                            <span>{{item.className}}</span>
                        </div>
                    </div>
                    <div class="all-content">
                        <div class="all-left">
                            <ul>
                                <li
                                    v-for="(item,index) in navbar[navbarIndex].children"
                                    :key="index"
                                    :class="tabbarIndex==index?'active':''"
                                    @click="changeTabBar(index,item)"
                                >
                                    <p>{{item.className}}</p>
                                </li>
                            </ul>
                        </div>
                        <div class="all-right" v-if="solutionList.length > 0 && navbarIndex != 2">
                            <ul :class="solutionList > showcount ? 'h922' : 'hauto'">
                                <li
                                    v-for="(item,index) in solutionList"
                                    :key="index"
                                    v-show="index<showcount"
                                    @click="toSolutionDetail({path:'/solutionDetail',id:item.id})"
                                >
                                    <div class="all-item">
                                        <div class="all-icon">
                                            <img :src="item.matchImage" alt />
                                        </div>
                                        <div class="all-text">
                                            <h4>{{item.title}}</h4>
                                            <p>{{item.description}}</p>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div
                                class="all-more"
                                v-if="solutionList.length > 6"
                                @click="showMoreChange"
                            >
                                {{moretext}}
                                <span :class="moreicon"></span>
                            </div>
                        </div>
                        
                        <div class="all-right" v-else-if="solutionList.length < 1 && navbarIndex != 2">
                            <el-empty description="暂无更多方案"></el-empty>
                        </div>

                        <div class="all-right" v-else>
                            <div class="solutionVideo">
                                <video :src="solutionVideoSrc" controls :poster="solutionVideoPosterSrc"></video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 精选案例 -->
        <div class="solution-module" style="padding-bottom:0;">
            <div class="module-center">
                <div class="module-title">精选案例</div>
                <div class="success-banner">
                    <div class="hangye-content-warp">
                        <div
                            class="hangye-content-item"
                            v-for="(item,index) in selectList"
                            :key="index"
                            :style="{backgroundImage:'url('+item.matchImage+')'}"
                            @mouseover="hangyeAddAnimate"
                            @mouseout="hangyeRemoveAnimate"
                            @click="toUrl({path:'/caseDetail',query:{id:item.id}})"
                        >
                            <div class="drawer">
                                <h3>{{item.name}}</h3>
                                <h4
                                    class="caseTitle"
                                >{{item.abilityTagArr[0]}}/{{item.abilityTagArr[1]}}</h4>
                                <div class="caseIntro">{{item.description}}</div>
                                <div class="ripple-btn myBtn">了解更多</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="moreplan" @click="toUrl('/caseList')">
                <span>点击进入案例库</span>
                <span class="el-icon-arrow-right"></span>
            </div>
        </div>
        <!-- 合作伙伴 -->
        <div class="solution-module">
            <div class="module-center">
                <div class="module-title">合作伙伴</div>
                <div class="success-banner">
                    <div class="swiper">
                        <swiper ref="pSwiper" :options="pswiperOptions">
                            <swiper-slide v-for="(item, index) in partner" :key="index">
                                <div class="partner-item">
                                    <img :src="item.src" alt />
                                </div>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
            </div>
        </div>
        <!-- 咨询 -->
        <div class="solution-module solution-consult">
            <!-- <div class="module-center"> -->
            <!-- <div class="module-consult"> -->
            <!-- <h2>方案与架构咨询</h2> -->
            <!-- <h4>关于使用场景和技术架构的更多咨询， 请联系我们的销售和技术支持团队。</h4> -->
            <!-- </div> -->
            <!-- </div> -->
        </div>
        <!-- 提交需求 -->
        <div class="solution-module" style="padding:50px 0;position: relative;height: 140px;">
            <!-- <div class="module-center">
                <div class="module-servive">
                    <ul>
                        <li>
                            <img src alt />
                            <p>7*24小时服务支持</p>
                        </li>
                        <li>
                            <img src alt />
                            <p>工业生态全链路服务</p>
                        </li>
                        <li>
                            <img src alt />
                            <p>专业人员 专属顾问</p>
                        </li>
                        <li>
                            <img src alt />
                            <p>方案与架构咨询</p>
                        </li>
                    </ul>
                </div>
            </div>-->
            <serve></serve>
        </div>
    </div>
</template>

<script>
import softwareMarketNav from "@/components/software-market-nav";
import serve from "@/components/serve";
export default {
    data() {
        return {
            navbarIndex: 0,
            tabbarIndex: 0,
            bannerBg: require("../../assets/image/solution_banner.jpg"),
            showcount: 6,
            solutionVideoSrc:"",
            solutionVideoPosterSrc:"",
            selectSolution: [
                // {
                //     id: 1,
                //     name: "药包材行业",
                //     desc: "基于标识解析技术的药包材行业产品信息不统一、数据共享难、可追溯性难解决方案",
                //     bgimg: require("../../assets/image/select_03.png"),
                // },
                // {
                //     id: 2,
                //     name: "线缆行业",
                //     desc: "基于5G时代，物联网万物互联，智能制造数字化行业多系统孤岛问题解决方案",
                //     bgimg: require("../../assets/image/select_05.png"),
                // },
                // {
                //     id: 3,
                //     name: "模具行业",
                //     desc: "基于智能排产、模具设备使用情况实时监管工业痛点解决方案",
                //     bgimg: require("../../assets/image/select_07.png"),
                // },
                // {
                //     id: 3,
                //     name: "食品行业",
                //     desc: "基于食品追溯管理、物料管理、品质监管、智慧营销等解决方案",
                //     bgimg: require("../../assets/image/select_07.png"),
                // },
                // {
                //     id: 3,
                //     name: "汽车及零部件行业",
                //     desc: "基于汽车及零部件行业设备管理散乱、生产组织和派工难度大解决方案",
                //     bgimg: require("../../assets/image/select_07.png"),
                // },
                // {
                //     id: 3,
                //     name: "小程序 · 云开发",
                //     desc: "为小程序提供完整的云端支持，无需服务器即可快速开发和上线",
                //     bgimg: require("../../assets/image/select_07.png"),
                // },
            ],
            navbar: [
                {
                    name: "行业解决方案",
                    children: [
                        {
                            id: 0,
                            name: "",
                            tabbarChild: [
                                {
                                    id: 0,
                                    name: "",
                                    desc: "",
                                }
                            ],
                        },
                    ],
                },
                {
                    name: "场景解决方案",
                    children: [
                        {
                            id: 0,
                            name: "",
                            tabbarChild: [
                                {
                                    id: 0,
                                    name: "",
                                    desc: "",
                                }
                            ],
                        },
                    ],
                },
                {
                    name: "数字孪生解决方案",
                    children: [
                        {
                            id: 0,
                            name: "",
                            tabbarChild: [
                                {
                                    id: 0,
                                    name: "",
                                    desc: "",
                                }
                            ],
                        },
                    ],
                }
            ],
            solutionList: [],
            showMore: false,
            moretext: "展开查看更多",
            moreicon: "el-icon-arrow-down",

            industrySolution: [
                {
                    id: 1,
                    name: "数字化营销解决方案",
                    desc: "精细化运营助力零售行业提高销售转化",
                    icon: require("../../assets/image/icon_03.png"),
                    tagstext: "银行 I 证券 I 保险 I 互联网金融",
                    show: false,

                    tags: [
                        {
                            id: 1,
                            name: "商标注册",
                        },
                        {
                            id: 2,
                            name: "PC站+手机站，超大大哒哒哒大",
                        },
                        {
                            id: 3,
                            name: "微盟 微商城",
                        },
                        {
                            id: 4,
                            name: "域名备案指导",
                        },
                    ],
                },
                {
                    id: 2,
                    name: "数字化供应链",
                    desc: "打破传统供应链模式“连锁反应”限制，数字化手段重新打造可控、灵活的“柔性”供应链",
                    icon: require("../../assets/image/icon_06.png"),
                    tagstext: "银行 I 证券 I 保险 I 互联网金融",
                    show: false,

                    tags: [
                        {
                            id: 1,
                            name: "商标注册",
                        },
                        {
                            id: 2,
                            name: "PC站+手机站，超大大哒哒哒大",
                        },
                        {
                            id: 3,
                            name: "微盟 微商城",
                        },
                        {
                            id: 4,
                            name: "域名备案指导",
                        },
                    ],
                },
                {
                    id: 3,
                    name: "资产管理解决方案",
                    desc: "提升不动产运营管理能力，着力构建资产运营管控平台，实现资产数据可查、可控",
                    icon: require("../../assets/image/icon_10.png"),
                    tagstext: "银行 I 证券 I 保险 I 互联网金融",
                    show: false,

                    tags: [
                        {
                            id: 1,
                            name: "商标注册",
                        },
                        {
                            id: 2,
                            name: "PC站+手机站，超大大哒哒哒大",
                        },
                        {
                            id: 3,
                            name: "微盟 微商城",
                        },
                        {
                            id: 4,
                            name: "域名备案指导",
                        },
                    ],
                },
                {
                    id: 4,
                    name: "资产管理解决方案",
                    desc: "提升不动产运营管理能力，着力构建资产运营管控平台，实现资产数据可查、可控",
                    icon: require("../../assets/image/icon_12.png"),
                    tagstext: "银行 I 证券 I 保险 I 互联网金融",
                    show: false,

                    tags: [
                        {
                            id: 1,
                            name: "商标注册",
                        },
                        {
                            id: 2,
                            name: "PC站+手机站，超大大哒哒哒大",
                        },
                        {
                            id: 3,
                            name: "微盟 微商城",
                        },
                        {
                            id: 4,
                            name: "域名备案指导",
                        },
                    ],
                },
                {
                    id: 5,
                    name: "资产管理解决方案",
                    desc: "提升不动产运营管理能力，着力构建资产运营管控平台，实现资产数据可查、可控",
                    icon: require("../../assets/image/icon_16.png"),
                    tagstext: "银行 I 证券 I 保险 I 互联网金融",
                    show: false,

                    tags: [
                        {
                            id: 1,
                            name: "商标注册",
                        },
                        {
                            id: 2,
                            name: "PC站+手机站，超大大哒哒哒大",
                        },
                        {
                            id: 3,
                            name: "微盟 微商城",
                        },
                        {
                            id: 4,
                            name: "域名备案指导",
                        },
                    ],
                },
                {
                    id: 6,
                    name: "资产管理解决方案",
                    desc: "提升不动产运营管理能力，着力构建资产运营管控平台，实现资产数据可查、可控",
                    icon: require("../../assets/image/icon_17.png"),
                    tagstext: "银行 I 证券 I 保险 I 互联网金融",
                    show: false,

                    tags: [
                        {
                            id: 1,
                            name: "商标注册",
                        },
                        {
                            id: 2,
                            name: "PC站+手机站，超大大哒哒哒大",
                        },
                        {
                            id: 3,
                            name: "微盟 微商城",
                        },
                        {
                            id: 4,
                            name: "域名备案指导",
                        },
                    ],
                },
                {
                    id: 7,
                    name: "资产管理解决方案",
                    desc: "提升不动产运营管理能力，着力构建资产运营管控平台，实现资产数据可查、可控",
                    icon: require("../../assets/image/icon_18.png"),
                    tagstext: "银行 I 证券 I 保险 I 互联网金融",
                    show: false,

                    tags: [
                        {
                            id: 1,
                            name: "商标注册",
                        },
                        {
                            id: 2,
                            name: "PC站+手机站，超大大哒哒哒大",
                        },
                        {
                            id: 3,
                            name: "微盟 微商城",
                        },
                        {
                            id: 4,
                            name: "域名备案指导",
                        },
                    ],
                },
                {
                    id: 8,
                    name: "资产管理解决方案",
                    desc: "提升不动产运营管理能力，着力构建资产运营管控平台，实现资产数据可查、可控",
                    icon: require("../../assets/image/icon_18.png"),
                    tagstext: "银行 I 证券 I 保险 I 互联网金融",
                    show: false,

                    tags: [
                        {
                            id: 1,
                            name: "商标注册",
                        },
                        {
                            id: 2,
                            name: "PC站+手机站，超大大哒哒哒大",
                        },
                        {
                            id: 3,
                            name: "微盟 微商城",
                        },
                        {
                            id: 4,
                            name: "域名备案指导",
                        },
                    ],
                },
                {
                    id: 8,
                    name: "资产管理解决方案",
                    desc: "提升不动产运营管理能力，着力构建资产运营管控平台，实现资产数据可查、可控",
                    icon: require("../../assets/image/icon_18.png"),
                    tagstext: "银行 I 证券 I 保险 I 互联网金融",
                    show: false,

                    tags: [
                        {
                            id: 1,
                            name: "商标注册",
                        },
                        {
                            id: 2,
                            name: "PC站+手机站，超大大哒哒哒大",
                        },
                        {
                            id: 3,
                            name: "微盟 微商城",
                        },
                        {
                            id: 4,
                            name: "域名备案指导",
                        },
                    ],
                },
            ],

            //精选案例
            selectTabIndex: 0,
            selectList: [
                // {
                //     name: "汽车装备行业",
                //     thumb: require("../../assets/image/index_03.png"),
                //     title: "长安汽车百科平台/工业知识图谱建设应用案例",
                //     desc: "建设长安百科平台，定位车主移动端高质量的问答百科社区和用户、KOL、工程师聚集的内容同平台。平台汇聚丰富的用车知识和相关内容，新车主可以随时与老车主进行沟通，互帮互助解决所遇到的问题。并且长安专家也会定期登录长安百科，针对热点问题出具百科回答，真正实现集思广益，让长安百科成为学习汽车知识，交流用车经验的“大本营”，满足用户对高品质、高价值信息的需求。",
                // },
                // {
                //     name: "药包材行业",
                //     thumb: require("../../assets/image/index_08.png"),
                //     title: "长安汽车百科平台/工业知识图谱建设应用案例",
                //     desc: "建设长安百科平台，定位车主移动端高质量的问答百科社区和用户、KOL、工程师聚集的内容同平台。平台汇聚丰富的用车知识和相关内容，新车主可以随时与老车主进行沟通，互帮互助解决所遇到的问题。并且长安专家也会定期登录长安百科，针对热点问题出具百科回答，真正实现集思广益，让长安百科成为学习汽车知识，交流用车经验的“大本营”，满足用户对高品质、高价值信息的需求。",
                // },
                // {
                //     name: "食品行业",
                //     thumb: require("../../assets/image/index_05.png"),
                //     title: "长安汽车百科平台/工业知识图谱建设应用案例",
                //     desc: "建设长安百科平台，定位车主移动端高质量的问答百科社区和用户、KOL、工程师聚集的内容同平台。平台汇聚丰富的用车知识和相关内容，新车主可以随时与老车主进行沟通，互帮互助解决所遇到的问题。并且长安专家也会定期登录长安百科，针对热点问题出具百科回答，真正实现集思广益，让长安百科成为学习汽车知识，交流用车经验的“大本营”，满足用户对高品质、高价值信息的需求。",
                // },
                // {
                //     name: "模具行业",
                //     thumb: require("../../assets/image/index_08.png"),
                //     title: "长安汽车百科平台/工业知识图谱建设应用案例",
                //     desc: "建设长安百科平台，定位车主移动端高质量的问答百科社区和用户、KOL、工程师聚集的内容同平台。平台汇聚丰富的用车知识和相关内容，新车主可以随时与老车主进行沟通，互帮互助解决所遇到的问题。并且长安专家也会定期登录长安百科，针对热点问题出具百科回答，真正实现集思广益，让长安百科成为学习汽车知识，交流用车经验的“大本营”，满足用户对高品质、高价值信息的需求。",
                // },
            ],

            // 合作伙伴 轮播图设置
            pswiperOptions: {
                loop: true,
                // loopFillGroupWithBlank: true,
                speed: 300,
                // 改变swiper样式时，自动初始化swiper
                observer: true,
                // 监测swiper父元素，如果有变化则初始化swiper
                observeParents: true,
                autoplay: {
                    delay: 2000,
                    disableOnInteraction: false,
                },
                slidesPerView: 6,
                slidesPerGroup: 2,
            },
            // 合作伙伴
            partner: [
                {
                    id: "",
                    src: require("../../assets/image/partner01.png"),
                    name: "",
                    link: "",
                },
                {
                    id: "",
                    src: require("../../assets/image/partner02.png"),
                    name: "",
                    link: "",
                },
                {
                    id: "",
                    src: require("../../assets/image/partner03.png"),
                    name: "",
                    link: "",
                },
                {
                    id: "",
                    src: require("../../assets/image/partner04.png"),
                    name: "",
                    link: "",
                },
                {
                    id: "",
                    src: require("../../assets/image/partner05.png"),
                    name: "",
                    link: "",
                },
                {
                    id: "",
                    src: require("../../assets/image/partner06.png"),
                    name: "",
                    link: "",
                },
                {
                    id: "",
                    src: require("../../assets/image/partner07.png"),
                    name: "",
                    link: "",
                },
                {
                    id: "",
                    src: require("../../assets/image/partner03.png"),
                    name: "",
                    link: "",
                },
                {
                    id: "",
                    src: require("../../assets/image/partner04.png"),
                    name: "",
                    link: "",
                },
                {
                    id: "",
                    src: require("../../assets/image/partner05.png"),
                    name: "",
                    link: "",
                },
            ],
        };
    },
    components: {
        softwareMarketNav,
        serve,
    },
    mounted() {
        this.getHotList();
        this.getSolutionCategory();
        this.getSelectSolution();
    },
    methods: {
        // 获取热门行业解决方案
        getHotList() {
            let _this = this;
            _this.$api.solution
                .getHotSolution()
                .then((res) => {
                    if (res.success == true) {
                        _this.selectSolution = res.data;
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    let response = err.response;
                    if (response.status == 400) {
                        this.$message.error(response.data.error_description);
                    }
                    if (response.status == 500) {
                        this.$message.error(response.data.msg);
                    }
                });
        },
        // 获取行业解决方案分类
        getSolutionCategory() {
            let _this = this;
            _this.$api.solution
                .getSolutionCategory()
                .then((res) => {
                    if (res.success == true) {
                        let navbar = res.data
                        let nav = {
                            className: "数字孪生解决方案",
                            hasChildren: true,
                            ajax:false,
                            children:[
                                {
                                    className: "备件库",
                                    ext: "基于汽车及零部件行业设备管理散乱，生产组织和派工难度...",
                                    video: "https://oss-zkwl-bs.oss-cn-beijing.aliyuncs.com/upload/20230510/1.mp4  ",
                                    ajax:false,
                                    poster: require("@/assets/video/cangku.png")
                                },
                                {
                                    className: "立体仓库",
                                    ext: "基于汽车及零部件行业设备管理散乱，生产组织和派工难度...",
                                    video: "https://oss-zkwl-bs.oss-cn-beijing.aliyuncs.com/upload/20230510/2.mp4",
                                    ajax:false,
                                    poster: require("@/assets/video/liku.png")
                                },
                                {
                                    className: "生产产线",
                                    ext: "基于汽车及零部件行业设备管理散乱，生产组织和派工难度...",
                                    video: "https://oss-zkwl-bs.oss-cn-beijing.aliyuncs.com/upload/20230510/3.mp4",
                                    ajax:false,
                                    poster: require("@/assets/video/chanxian.png")
                                },
                            ]
                        }
                        navbar.push(nav)

                        console.log(navbar)
                        _this.navbar = navbar;
                        _this.getSolutionList(navbar[0].children[0].id);
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    let response = err.response;
                    if (response.status == 400) {
                        this.$message.error(response.data.error_description);
                    }
                    if (response.status == 500) {
                        this.$message.error(response.data.msg);
                    }
                });
        },
        toUrl(link) {
            if (!link) {
                return;
            }
            this.$router.push(link);
        },
        // 根据分类id  获取行业解决方案
        getSolutionList(classId) {
            let _this = this;
            _this.$api.solution
                .getSolutionList({
                    classId,
                    size: 99,
                })
                .then((res) => {
                    if (res.success == true) {
                        _this.solutionList = res.data.records;
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    let response = err.response;
                    if (response.status == 400) {
                        this.$message.error(response.data.error_description);
                    }
                    if (response.status == 500) {
                        this.$message.error(response.data.msg);
                    }
                });
        },
        // 获取精选案例
        getSelectSolution() {
            let _this = this;
            _this.$api.solution
                .getSelectSolution()
                .then((res) => {
                    if (res.success == true) {
                        _this.selectList = res.data;
                    } else {
                        _this.$message.error(res.msg);
                    }
                })
                .catch((err) => {
                    let response = err.response;
                    if (response.status == 400) {
                        this.$message.error(response.data.error_description);
                    }
                    if (response.status == 500) {
                        this.$message.error(response.data.msg);
                    }
                });
        },
        toDetail(e) {
            if (!e) {
                return;
            }
            // this.$router.push({
            //     path: "/solutionDetail",
            //     query: {
            //         id: e,
            //     },
            // });

            let routeUrl = this.$router.resolve({
                path: "/solutionDetail",
                query: { 
                    id: e,
                },
            });
            window.open(routeUrl.href, "_blank");

        },
        toSolutionDetail(e) {
            window.open(e.path + "?id=" + e.id, "_blank");
            return;
        },
        hangyeAddAnimate() {
            $(".hangye-content-item").css({ width: "230px" });
        },
        hangyeRemoveAnimate() {
            $(".hangye-content-item").css({ width: "285px" });
        },
        changeNavBar(i) {
            this.navbarIndex = i;
            this.showMore = false;
            this.showcount = 6;
            this.moretext = "展开查看更多";
            this.moreicon = "el-icon-arrow-down";
            this.tabbarIndex = 0;
            if(i == 2){
                this.solutionList = []
                this.solutionVideoSrc = this.navbar[2].children[0].video
                this.solutionVideoPosterSrc = this.navbar[2].children[0].poster
            }else{
                this.getSolutionList(this.navbar[i].children[0].id);
            }
        },
        changeTabBar(i, item) {
            this.tabbarIndex = i;
            this.showMore = false;
            this.showcount = 6;
            this.moretext = "展开查看更多";
            this.moreicon = "el-icon-arrow-down";
            if(this.navbarIndex == 2){
                console.log(item)
                this.solutionVideoSrc = item.video
                this.solutionVideoPosterSrc = item.poster
            }else{
                this.getSolutionList(item.id);
            }
        },
        showMoreChange() {
            if (this.showMore) {
                this.showMore = false;
                this.showcount = 6;
                this.moretext = "展开查看更多";
                this.moreicon = "el-icon-arrow-down";
            } else {
                this.showMore = true;
                this.showcount = 99999;
                $(".all-right").css({ height: "auto" });
                this.moretext = "收起更多内容";
                this.moreicon = "el-icon-arrow-up";
            }
        },
    },
};
</script>

<style lang="scss" scoped>
div,
p,
ul,
li {
    box-sizing: border-box;
}
.bg_30374A {
    background-color: #30374a;
}
.bg_F6F8FB {
    background-color: #f6f8fb;
}
.bg_34353F {
    background-color: #34353f;
}
.c_ffffff {
    color: #fff;
}

.solution-module {
    // margin-top: 60px;
    padding-top: 90px;
    padding-bottom: 60px;
    .moreplan {
        text-align: center;
        cursor: pointer;
        font-size: 14px;
        color: #1f51c8;
        line-height: 16px;
        margin-top: 40px;
    }
    .module-center {
        width: 1180px;
        margin: 0 auto;
        .module-title {
            font-size: 34px;
            font-weight: 400;
            color: #0b1d30;
            line-height: 40px;
            margin-bottom: 50px;
            text-align: center;
            letter-spacing: 3px;
        }
        .c_ffffff {
            color: #fff;
        }
        // 热门行业解决方案
        .module-select {
            overflow: hidden;
            margin: 0 -10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            .class2 {
                width: 572px;
                height: 164px;
                .select-item-desc {
                    width: 338px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
            }
            .class1 {
                width: 276px;
                height: 164px;
                .select-item-desc {
                    width: 236px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 2;
                }
            }
            .select-item {
                box-shadow: 0 0 15px #eee;
                margin: 0 10px 20px;
                transition: all 0.3s;
                cursor: pointer;

                .select-item-text {
                    // background-color: #0934c4;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 100% 100%;
                    width: 100%;
                    height: 100%;
                    padding: 60px 0 0 20px;
                    color: #fff;
                    .select-item-name {
                        font-size: 20px;
                        line-height: 23px;
                        letter-spacing: 2px;
                        margin-bottom: 10px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                    .select-item-desc {
                        font-size: 14px;
                        color: #ffffff;
                        line-height: 24px;
                        letter-spacing: 1px;
                    }
                }
                // .select-item-tags {
                //     padding: 1px 20px 20px;
                //     .select-item-tags-item {
                //         display: inline-block;
                //         background-color: #e4ecf6;
                //         font-size: 14px;
                //         color: #0b1d30;
                //         padding: 3px 10px;
                //         margin-right: 10px;
                //         margin-top: 20px;
                //         cursor: pointer;
                //         overflow: hidden;
                //         p {
                //             max-width: 160px;
                //             white-space: nowrap;
                //             overflow: hidden;
                //             text-overflow: ellipsis;
                //             float: left;
                //         }
                //         i {
                //             display: inline-block;
                //             float: left;
                //             margin-top: 3px;
                //         }
                //     }
                //     .select-item-tags-item:hover {
                //         background-color: #006eff;
                //         color: #fff;
                //     }
                // }
            }
            .select-item:hover {
                box-shadow: 0 3px 10px 1px rgba(0, 32, 94, 0.16);
            }
        }

        // 企业解决方案
        .module-all {
            .all-navbar {
                width: 100%;
                display: flex;
                justify-content: center;
                div {
                    padding: 0 40px;
                    font-size: 16px;
                    color: #757f92;
                    line-height: 19px;
                    letter-spacing: 1px;
                    user-select: none;
                    cursor: pointer;
                    span {
                        display: inline-block;
                        border-bottom: 5px solid transparent;
                        padding-bottom: 25px;
                    }
                }
                div.active {
                    span {
                        border-bottom: 5px solid #1f51c8;
                    }
                }
            }
            .all-content {
                display: flex;
                .all-left {
                    width: 218px;
                    padding: 25px 0;
                    background-image: linear-gradient(0deg, #fff, #f3f5f8);
                    border: 2px solid #fff;
                    box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, 0.05);
                    padding: 20px 0;
                    height: 1020px;
                    overflow-y: scroll;
                    li {
                        padding: 18px 0 18px;

                        user-select: none;
                        cursor: pointer;
                        p {
                            padding-left: 28px;
                            border-left: 3px solid transparent;
                        }
                    }
                    li:hover {
                        color: #1f51c8;
                    }
                    li.active {
                        background: #f3f5f8;
                        p {
                            color: #1f51c8;
                            border-left: 3px solid #1f51c8;
                        }
                    }
                }
                .all-right {
                    width: calc(100% - 218px);
                    background: #f3f5f8;
                    padding: 45px 40px 0 40px;
                    min-height: 1020px;
                    height: 1020px;
                    overflow: hidden;

                    background-image: linear-gradient(180deg, #f3f5f8, #fff);
                    box-shadow: inset 8px 8px 20px 0 rgba(55, 99, 170, 0.11);
                    border: none;

                    .solutionVideo{
                        width: 100%;
                        video{
                            width: 100%;
                        }
                    }

                    .h1020 {
                        height: 922px;
                    }
                    .hauto {
                        height: auto;
                    }
                    ul {
                        li {
                            width: 100%;
                            padding: 25px 0 24px 30px;
                            border: 1px solid transparent;
                            transition: all 0.5s;
                            margin-bottom: 20px;
                            cursor: pointer;
                            .all-item {
                                display: flex;
                                .all-icon {
                                    width: 24px;
                                    img {
                                        // background: #ccc;
                                        width: 24px;
                                        height: 24px;
                                        display: block;
                                    }
                                }
                                .all-text {
                                    padding-left: 15px;
                                    h4 {
                                        font-weight: normal;
                                        font-size: 20px;
                                        color: #252d3d;
                                        line-height: 23px;
                                        letter-spacing: 2px;
                                        margin-bottom: 12px;
                                    }
                                    p {
                                        width: 750px;
                                        font-size: 14px;
                                        color: #757f92;
                                        line-height: 22px;
                                        letter-spacing: 1px;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        display: -webkit-box;
                                        -webkit-box-orient: vertical;
                                        -webkit-line-clamp: 2;
                                    }
                                }
                            }
                        }
                        li:hover {
                            // border: 1px solid #ffffff;
                            // background: #f8f9fb;
                            // box-shadow: 0px 3px 10px rgba(255, 255, 255, 0.8);
                            // box-shadow: inset 0px 0px 20px 1px rgba(223, 230, 240, 0.2);

                            background-image: linear-gradient(
                                0deg,
                                #fff,
                                #f3f5f8
                            );
                            box-shadow: 8px 8px 20px 0 rgba(55, 99, 170, 0.1),
                                -8px -8px 20px 0 #fff;
                            border-radius: 4px;
                            border-color: #fff;
                        }
                    }
                    .all-more {
                        text-align: center;
                        font-size: 14px;
                        color: #1f51c8;
                        line-height: 50px;
                        letter-spacing: 1px;
                        cursor: pointer;
                        width: 200px;
                        height: 50px;
                        margin: 0 auto;
                        span {
                            font-weight: 600;
                        }
                    }
                }
            }
        }

        // 成功案例
        .success-banner {
            width: 100%;
            background: #fff;
            .hangye-content-warp {
                overflow: hidden;
                // margin-left: -20px;
                position: relative;
                display: flex;
                justify-content: center;
                // top: -25px;
                // opacity: 0;
                .hangye-content-item {
                    width: 285px;
                    height: 294px;
                    background-color: #ccc;
                    // float: left;
                    // margin-left: 20px;
                    margin: 0 10px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;
                    transition: all ease-in-out 0.3s;
                    position: relative;
                    .drawer {
                        position: absolute;
                        bottom: 0;
                        transition: all ease-in-out 0.3s;
                        overflow: hidden;
                        height: 85px;
                        width: 100%;
                        padding: 20px 9px;
                        h3 {
                            margin-top: 10px;
                            height: 20px;
                            font-size: 14px;
                            line-height: 20px;
                            font-weight: 700;
                            color: #fff;
                            margin-bottom: 36px;
                            position: relative;
                        }
                        h3::before {
                            width: 32px;
                            height: 3px;
                            background: #fff;
                            bottom: -18px;
                            position: absolute;
                            content: "";
                            left: 0;
                        }
                        h4 {
                            text-align: left;
                        }
                        .caseTitle {
                            font-size: 18px;
                            font-weight: 400;
                            color: #fff;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin-bottom: 15px;
                        }
                        .caseIntro {
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            font-size: 14px;
                            color: #fff;
                            line-height: 22px;
                            font-weight: 400;
                            text-align: left;
                            margin-bottom: 15px;
                            min-height: 44px;
                        }
                        .myBtn {
                            background-color: #3460f2;
                            box-shadow: 0px 3px 11px 0px rgba(8, 46, 174, 0.47);
                            text-align: center;
                            font-size: 14px;
                            font-weight: 700;
                            color: #ffffff;
                            letter-spacing: 1px;
                        }
                        .ripple-btn {
                            position: relative;
                            display: inline-block;
                            width: 100px;
                            line-height: 38px;
                            height: 40px;
                            border: 1px solid #3460f2;
                            text-align: center;
                            overflow: hidden;
                            cursor: pointer;
                        }
                    }
                }
                .hangye-content-item:hover {
                    width: 450px !important;
                    .drawer {
                        height: 100%;
                        background: rgba(0, 12, 58, 0.6);
                    }
                    // 55px
                }
            }
            .swiper {
                padding-left: 26px;
                .partner-item {
                    width: 170px;
                    height: 100px;
                    height: 100px;
                    padding: 5px;
                    box-sizing: border-box;
                    img {
                        width: 100%;
                        display: block;
                        height: auto;
                        box-shadow: 0px 0px 5px 0px rgba(#ccc, 0.5);
                    }
                }
            }
        }

        .module-servive {
            ul {
                display: flex;
                li {
                    display: flex;
                    flex: 1;
                    padding-left: 70px;
                    img {
                        width: 36px;
                        height: 36px;
                        background: #757f92;
                        margin-right: 10px;
                    }
                    p {
                        font-size: 16px;
                        color: #757f92;
                        line-height: 36px;
                    }
                }
            }
        }

        // 提交需求
        .submit-demand {
            font-size: 22px;
            color: #ffffff;
            text-align: center;
        }
        .submit-button {
            margin: 0 auto;
            width: 180px;
            height: 60px;
            background: #006fff;
            text-align: center;
            color: #fff;
            line-height: 60px;
            font-size: 16px;
            margin-top: 32px;
        }
    }
}
.solution-consult {
    background: #1e232c;
    padding: 0;
    background: url("../../assets/image/soluton_pic_7.png") no-repeat;
    background-position: center;
    background-size: cover;
    height: 206px;
    // 咨询
    .module-consult {
        padding: 48px 0;
        h2 {
            font-weight: normal;
            font-size: 20px;
            color: #ffffff;
            line-height: 23px;
            letter-spacing: 2px;
            margin-bottom: 20px;
        }
        h4 {
            font-weight: normal;
            font-size: 14px;
            color: #ffffff;
            line-height: 16px;
            letter-spacing: 1px;
        }
    }
}
/deep/ .el-empty{
    padding: 80px 0 0 !important;
}
// 顶部banner
.module-banner {
    height: 540px;
    width: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 135px;
    .module-center {
        padding-left: 40px;
        .banner-title {
            font-size: 42px;
            font-weight: bold;
            color: #0B1D30;
            margin-bottom: 15px;
        }
        .banner-desc {
            font-size: 20px;
            color: #757F92;
            margin-bottom: 35px;
            line-height: 35px;
        }
        .banner-button {
            width: 140px;
            height: 40px;
            text-align: center;
            line-height: 40px;
            background: #006fff;
            font-size: 14px;
            color: #ffffff;
        }
    }
}
</style>
