<template>
<div>
    <el-upload
    class="avatar-uploader"
    :action= "serverUrl"
    :headers= "headers"
    accept= "image/png,image/jpg,image/jpeg"
    :show-file-list= "false"
    :on-success="(response,file)=>handleUpSuccess(child,response, file)"
    :before-upload= "beforeAvatarUpload"
    >
        <el-button size="small" type="primary">图片上传</el-button>
        <span>请上传清晰彩色原件</span>
        <b>上传格式(.jpg/.png)</b>
    </el-upload>
</div>
</template>

<script>
import { baseUrl } from '../request/http'
import store from '../store/index'
import Cookies from 'js-cookie';
export default {
    data() {
        return {
            serverUrl: baseUrl + '/blade-resource/oss/endpoint/put-file',
            headers: {
                'Blade-Auth':Cookies.get('token')
                // authorization: store.state.userInfo.token,
            }
        }
    },
    props: {
        child: String,
    },

    mounted(){
        this.serverUrl = "//27.128.115.201:82/blade-resource/oss/endpoint/put-file"
    },
    methods: {
        beforeAvatarUpload: function (file) {
            const isJPG = file.type == 'image/jpeg' || file.type == 'image/png' || file.type == 'image/jpg'
            const isLt5M = file.size / 1024 / 1024 < 5
            if(!isJPG){
                this.$message.error('图片应为.jpg/.png/jpeg格式！')
            }

            if(!isLt5M){
                this.$message.error('图片大小不能超过5MB！')
            }

            return isJPG && isLt5M;
        },
        handleUpSuccess: function(imgSrc, response, file){
            this.$emit('getSonUrl', response.data, imgSrc)
        }
    }
}
</script>